import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { Menu, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import {
  REPORT_NAME,
} from "../constant/reportType";
import { Formik } from "formik";
import { useGetAllObjectTypesMutation, useGetAllObjectsMutation, useGetAllResponsibilityCenterMutation, useGetAllResponsibilityGroupMutation } from "../redux-toolkit/services/responsibilityService";
import { alertMessage } from "../utils/AlertService";
import { getAllObjectDetails, getAllObjectTypeDetails, getAllResponsibilityCenterDetails, getAllResponsibilityGroupDetails, setObjectData, setObjectTypeData, setResponsibilityCenter, setResponsibilityGroup } from "../redux-toolkit/slices/responsibilitySlice";
import { getAllCompaniesDetails, getAllProjectDetails, getDataLineageDetails, setCompany, setDataLineage, setProjects } from "../redux-toolkit/slices/companySlice";
import { useGetAllCompaniesMutation, useGetAllProjectsMutation, useGetAssessmentMutation, useGetDataLineageMutation } from "../redux-toolkit/services/companyService";
import Loading from "./Loading";
import Tree from 'react-d3-tree';
import { DATA_LINEAGE_COLUMN } from "../constant/AppConstant";

type Props = {};

interface MenuItem {
  label: string;
  key: number | string;
  children?: MenuItem[];
  onClick?: (e: any) => void;
}

const DataGovernance = (props: Props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedReportNameL: any = localStorage?.getItem('selectedReportName');

  // States
  const [showButtons, setShowButtons] = useState(false);
  const [showTraceabilty, setShowTraceabilty] = useState(false);
  const [showTree, setShowTree] = useState(false);
  const [selectedCompany, setSelectedCompany]: any = useState<number>(0);
  const [selectedResGroup, setSelectedResGroup]: any = useState();
  const [selectedRespCenter, setSelectedRespCenter]: any = useState();
  const [selectedProject, setSelectedProject] = useState<number>(0);
  const [objectType, setObjectType]: any = useState();
  const [object, setObject] = useState();
  const [showError, setShowError] = useState(false);
  const [reportName, setReportName]: any = useState();
  const [datasource, setDatasource]: any = useState([]);
  const [clickedNode, setClickedNode] = useState(null);

  const [data, setData]: any = useState();
  const [treeData, setTreeData]: any = useState();
  const [showDObject, setShowDObject] = useState(false);
  const [dependentObject, setDependentObject]: any = useState();
  const [dependentObjectOpt, setDependentObjectOpt]: any = useState();

  //get mutation
  const [getAllResponsibilityGroup, allResponsibilityGroupResponse] = useGetAllResponsibilityGroupMutation();
  const [getAllResponsibilityCenter, allResponsibilityCenterResponse] = useGetAllResponsibilityCenterMutation();
  const [getAllCompanies, allCompaniesResponse] = useGetAllCompaniesMutation();
  const [getAllObjects, allObjectsResponse] = useGetAllObjectsMutation();
  const [getAllProjects, allProjectsResponse] = useGetAllProjectsMutation();
  // const [getEvidences, evidencesResponse] = useGetEvidencesMutation();
  const [getAssessment, assessmentResponse] = useGetAssessmentMutation();
  const [getAllObjectTypes, objectTypesResponse] = useGetAllObjectTypesMutation();
  const [getDataLineage, dataLineageResponse] = useGetDataLineageMutation();

  //selector
  const allResponsibilityGroup: any = useAppSelector(getAllResponsibilityGroupDetails);
  const allResponsibilityCenter: any = useAppSelector(getAllResponsibilityCenterDetails);
  const allCompanies: any = useAppSelector(getAllCompaniesDetails);
  const getAllObjectsData: any = useAppSelector(getAllObjectDetails);
  const getAllProjectData: any = useAppSelector(getAllProjectDetails);
  const getAllObjectTypeData: any = useAppSelector(getAllObjectTypeDetails);
  const dataLineageData: any = useAppSelector(getDataLineageDetails);

  useEffect(() => {

    switch (params.reportType) {
      case REPORT_NAME.DATA_LINEAGE:
        setReportName(REPORT_NAME.DATA_LINEAGE);
        break;
      default:
    }
  }, []);

  useEffect(() => {
    getAllResponsibilityGroup({});
    getAllResponsibilityCenter({});
    getAllProjects({});
    getAllObjectTypes({});
    getAllCompanies({});
  }, []);

  useEffect(() => {
    if (allResponsibilityGroupResponse.isSuccess) {
      dispatch(setResponsibilityGroup(allResponsibilityGroupResponse));
    } else if (allResponsibilityGroupResponse.isError) {
      alertMessage("Error fetching responsibility group", "error");
    }
  }, [allResponsibilityGroupResponse.isSuccess, allResponsibilityGroupResponse.isError]);

  useEffect(() => {
    if (allResponsibilityCenterResponse.isSuccess) {
      dispatch(setResponsibilityCenter(allResponsibilityCenterResponse));
    } else if (allResponsibilityCenterResponse.isError) {
      alertMessage("Error fetching responsibility center", "error");
    }
  }, [allResponsibilityCenterResponse.isSuccess, allResponsibilityCenterResponse.isError]);

  useEffect(() => {
    if (allCompaniesResponse.isSuccess) {
      dispatch(setCompany(allCompaniesResponse));
    } else if (allCompaniesResponse.isError) {
      alertMessage("Error fetching companies", "error");
    }
  }, [allCompaniesResponse.isSuccess, allCompaniesResponse.isError]);

  useEffect(() => {
    if (allProjectsResponse.isSuccess) {
      dispatch(setProjects(allProjectsResponse));
    } else if (allProjectsResponse.isError) {
      alertMessage("Error fetching projects", "error");
    }
  }, [allProjectsResponse.isSuccess, allProjectsResponse.isError]);

  useEffect(() => {
    if (objectTypesResponse.isSuccess) {
      dispatch(setObjectTypeData(objectTypesResponse));
    } else if (objectTypesResponse.isError) {
      alertMessage("Error fetching projects", "error");
    }
  }, [objectTypesResponse.isSuccess, objectTypesResponse.isError]);

  useEffect(() => {
    if (dataLineageResponse.isSuccess) {
      dispatch(setDataLineage(dataLineageResponse));
    } else if (dataLineageResponse.isError) {
      alertMessage("Error fetching data lineage", "error");
    }
  }, [dataLineageResponse.isSuccess, dataLineageResponse.isError]);

  useEffect(() => {
    if (dataLineageData) {
      setDatasource(dataLineageData);
    }
  }, [dataLineageData])

  // navigate............
  const handleBack = () => {
    navigate("/aiGovernanceReports");
  };

  // handleSubmit...........
  const handleSubmit = (values: any) => {
  };

  const handleCompany = (event: any) => {
    setSelectedCompany(parseInt(event.target.value));
  };

  const handleRespGroup = (event: any) => {
    setSelectedResGroup(event.target.value);
  };

  const handleRespCenter = (event: any) => {
    setSelectedRespCenter(event.target.value);
  };

  const handleProject = (event: any) => {
    setSelectedProject(parseInt(event.target.value));
  };

  const handleObjectType = (event: any) => {
    setObjectType(event.target.value)
  }

  const handleObjectClick = (event: any) => {

    setObject(event.target.value);

    const temp: any = [];
    const elementFound = data && data.find((ele: any) => ele.label === event.target.value);
    setDependentObjectOpt(elementFound.children);
    setDependentObject("");
    setShowDObject(true);
    setShowButtons(true);

  }

  const handleDependentObjectClick = (event: any) => {
    setDependentObject(event.target.value);
    setShowError(false);
  }

  useEffect(() => {
    getAllObjects({});
  }, []);

  useEffect(() => {
    if (allObjectsResponse.isSuccess) {
      dispatch(setObjectData(allObjectsResponse));

      let selectedReportName = [];
      if (selectedReportNameL !== null) {
        selectedReportName = JSON.parse(selectedReportNameL);
      }
      selectedReportName.push(REPORT_NAME.DATA_LINEAGE);
      const jsonString = JSON.stringify(selectedReportName);
      localStorage.setItem('selectedReportName', jsonString);

    } else if (allObjectsResponse.isError) {
      let response: any = allObjectsResponse.error;
      alertMessage(response, "error");
    }
  }, [allObjectsResponse.isSuccess, allObjectsResponse.isError]);

  useEffect(() => {
    if (getAllObjectsData) {
      const MenuItemsData1: MenuItem[] = buildMenuItemsData(getAllObjectsData);
      setData(MenuItemsData1)
    }
  }, [getAllObjectsData, selectedProject, selectedCompany, object]);

  const buildMenuItemsData = (input: any[]): MenuItem[] => {
    const result: MenuItem[] = [];

    const groupedItems: Record<string, MenuItem | any> = {};

    input.forEach(item => {
      const parentLabel = item.objectcode;

      if (!groupedItems[parentLabel]) {
        groupedItems[parentLabel] = {
          label: parentLabel,
          key: parentLabel,
          id: parseInt(item.nameid),
          children: [],
        };
      }

      groupedItems[parentLabel].children.push({
        label: item.dependentobjectcode,
        key: item.dependentobjectcode,
        children: [
          {
            label: 'Evidence',
            key: 'Evidence',
            onClick: (e: any) => {
              // console.log(e, 'e');
              handleEvidenceClick(e);
              // Do something when the Evidence is clicked.
            },
          },
          // {
          //   label: 'Assessment',
          //   key: 'Assessment',
          //   onClick: (e: any) => {
          //     console.log(e, 'e');
          //     handleAssessmentClick(e.keyPath[0], e.keyPath[1], e.keyPath[2]);
          //     // Do something when the Parameter item is clicked.
          //   },
          // },
          // {
          // label: 'Audit Plan',
          // key: 'auditPlan',
          // onClick: (e: any) => {
          // console.log(e, 'e');
          // // Do something when the Parameter item is clicked.
          // },
          // },
          // {
          // label: 'Audit',
          // key: 'audit',
          // onClick: (e: any) => {
          // console.log(e, 'e');
          // // Do something when the Parameter item is clicked.
          // },
          // },
        ],
      });
    });

    // Convert the groupedItems object into an array
    result.push(...Object.values(groupedItems));

    return result;
  };

  const buildTreeData = (input: any) => {

    //find matching(object i.e input[2]) label element from data array
    const foundElement = data.find((element: any) => {
      if (element.label === input[2])
        return element;
    });

    //this logic is to extract the element from data when it is matched with dependanct object
    let extractedElement: any;

    const matchingElement = data.find((mainElement: any) => mainElement.label === input[1]);

    if (matchingElement) {
      extractedElement = matchingElement;
      // Do something with the extracted element
    } else {
      // Handle the case when no matching element is found
    }

    if (extractedElement !== undefined) {

      if (foundElement.children) {
        // Find the index of the element with a matching label in object2's children
        const indexToUpdate = foundElement.children.findIndex((child: any) => child.label === extractedElement.label);

        // If a matching element is found, update its children
        if (indexToUpdate !== -1) {
          foundElement.children[indexToUpdate].children = extractedElement.children;
        }
      }
    }

    // const myTreeData1 = convertObject(foundElementCpy !== undefined ? foundElementCpy : foundElement);
    const myTreeData = convertObject(foundElement);

    // Remove nodes with name "Evidence"
    removeEvidenceNodes(myTreeData);

    setTreeData(myTreeData);
  }

  function removeEvidenceNodes(node: any) {
    // Remove children with name "Evidence"
    node.children = node.children.filter((child: any) => child.name !== 'Evidence');

    // Recursively call on each child
    node.children.forEach((child: any) => removeEvidenceNodes(child));
  }

  function convertObject(original: any) {
    return {
      name: original.label,
      children: original.children
        ? original.children.map((child: any) => convertObject(child))
        : []
    };
  }

  const handleEvidenceClick = (e: any) => {
    if (selectedCompany === 0) {
      alertMessage("Please select company", "error");
    }
    // else if (selectedCompany === 0 || selectedCompany === undefined || selectedCompany === null) {
    //   alertMessage("Please select company", "error");
    // } else if (object === 0 || object === undefined || object === null) {
    //   alertMessage("Please select object", "error");
    // } 
    else {
      buildTreeData(e.keyPath);
      // window.open(`${process.env.REACT_APP_EVIDENCE_NAVIGATE_URL}/${selectedProject}/${selectedCompany}`, "_blank")

      let company;
      let project;
      let respGroup;
      let respCenter;

      if (selectedCompany !== 0) {
        const selectedCompanyObject = allCompanies && allCompanies.find((obj: any) => obj.companyid === selectedCompany.toString());
        company = selectedCompanyObject ? selectedCompanyObject.companyname : null;
      }

      if (selectedProject !== 0 && selectedProject !== undefined && selectedProject !== null) {
        const selectedProjectObject = getAllProjectData && getAllProjectData.find((obj: any) => obj.projectid === selectedProject.toString());
        project = selectedProjectObject ? selectedProjectObject.projectname : null;
      }

      if (selectedResGroup !== 0 && selectedResGroup !== undefined && selectedResGroup !== null) {
        const selectedRespGroupObject = allResponsibilityGroup && allResponsibilityGroup.find((obj: any) => obj.responsibilityid === selectedResGroup.toString());
        respGroup = selectedRespGroupObject ? selectedRespGroupObject.responsibilitytype : null;
      }

      if (selectedRespCenter !== 0 && selectedRespCenter !== undefined && selectedRespCenter !== null) {
        const selectedRespCenterObject = allResponsibilityCenter && allResponsibilityCenter.find((obj: any) => obj.responsibilitynameid === selectedRespCenter.toString());
        respCenter = selectedRespCenterObject ? selectedRespCenterObject.responsibilitytype : null;
      }
      const payload = {
        companyName: company,
        projectName: project,
        responsibilityGroup: respGroup,
        responsibilityCenter: respCenter,
        objectType: objectType,
        objectCode: e.keyPath[1],
      }
      getDataLineage(payload);

    }

  }

  const handleAssessmentClick = (object: any, dependentObject: any, link: any) => {
    if (selectedProject === 0 || selectedProject === undefined || selectedProject === null) {
      alertMessage("Please select project", "error");
    } else if (selectedCompany === 0 || selectedCompany === undefined || selectedCompany === null) {
      alertMessage("Please select company", "error");
    } else {
      getAssessment({
        projectId: selectedProject
      })
    }

  }
  const handleNodeClick = (nodeData: any) => {
    setClickedNode(nodeData.nodeDatum);
    // if (nodeData.nodeDatum.children.length === 0) {
    if (selectedCompany === 0) {
      alertMessage("Please select company", "error");
    }
    // else if (selectedProject === 0 && selectedProject === undefined && selectedProject === null) {
    //   alertMessage("Please select project", "error");
    // } else if (selectedResGroup === 0 && selectedResGroup === undefined && selectedResGroup === null) {
    //   alertMessage("Please select responsibility group", "error");
    // } else if (selectedRespCenter === 0 && selectedRespCenter === undefined && selectedRespCenter === null) {
    //   alertMessage("Please select responsibility center", "error");
    // } else if (object === 0 && object === undefined && object === null) {
    //   alertMessage("Please select object", "error");
    // } 
    else {

      let company;
      let project;
      let respGroup;
      let respCenter;

      if (selectedCompany !== 0 && selectedCompany !== undefined && selectedCompany !== null) {
        const selectedCompanyObject = allCompanies && allCompanies.find((obj: any) => obj.companyid === selectedCompany.toString());
        company = selectedCompanyObject ? selectedCompanyObject.companyname : null;
      }

      if (selectedProject !== 0 && selectedProject !== undefined && selectedProject !== null) {
        const selectedProjectObject = getAllProjectData && getAllProjectData.find((obj: any) => obj.projectid === selectedProject.toString());
        project = selectedProjectObject ? selectedProjectObject.projectname : null;
      }

      if (selectedResGroup !== 0 && selectedResGroup !== undefined && selectedResGroup !== null) {
        const selectedRespGroupObject = allResponsibilityGroup && allResponsibilityGroup.find((obj: any) => obj.responsibilityid === selectedResGroup.toString());
        respGroup = selectedRespGroupObject ? selectedRespGroupObject.responsibilitytype : null;
      }

      if (selectedRespCenter !== 0 && selectedRespCenter !== undefined && selectedRespCenter !== null) {
        const selectedRespCenterObject = allResponsibilityCenter && allResponsibilityCenter.find((obj: any) => obj.responsibilitynameid === selectedRespCenter.toString());
        respCenter = selectedRespCenterObject ? selectedRespCenterObject.responsibilitytype : null;
      }

      const payload = {
        companyName: company,
        projectName: project,
        responsibilityGroup: respGroup,
        responsibilityCenter: respCenter,
        objectType: objectType,
        objectCode: nodeData.hierarchyPointNode.depth === 2 ? nodeData.hierarchyPointNode.parent.data.name : nodeData.nodeDatum.name,
      }
      getDataLineage(payload);
      // window.open(`${process.env.REACT_APP_EVIDENCE_NAVIGATE_URL}/${selectedProject}/${selectedCompany}`, "_blank")
    }
    // else if (objectType === 0 || objectType === undefined || objectType === null) {
    //   alertMessage("Please select object type", "error");
    // }
    // } 
  };

  const getEvidenceClick = () => {

    if (selectedCompany === 0) {
      alertMessage("Please select company", "error");
    }
    // else if (selectedProject === 0 && selectedProject === undefined && selectedProject === null) {
    //   alertMessage("Please select project", "error");
    // } else if (selectedResGroup === 0 && selectedResGroup === undefined && selectedResGroup === null) {
    //   alertMessage("Please select responsibility group", "error");
    // } else if (selectedRespCenter === 0 && selectedRespCenter === undefined && selectedRespCenter === null) {
    //   alertMessage("Please select responsibility center", "error");
    // } else if (object === 0 && object === undefined && object === null) {
    //   alertMessage("Please select object", "error");
    // } 
    else {

      let company;
      let project;
      let respGroup;
      let respCenter;

      if (selectedCompany !== 0 && selectedCompany !== undefined && selectedCompany !== null) {
        const selectedCompanyObject = allCompanies && allCompanies.find((obj: any) => obj.companyid === selectedCompany.toString());
        company = selectedCompanyObject ? selectedCompanyObject.companyname : null;
      }

      if (selectedProject !== 0 && selectedProject !== undefined && selectedProject !== null) {
        const selectedProjectObject = getAllProjectData && getAllProjectData.find((obj: any) => obj.projectid === selectedProject.toString());
        project = selectedProjectObject ? selectedProjectObject.projectname : null;
      }

      if (selectedResGroup !== 0 && selectedResGroup !== undefined && selectedResGroup !== null) {
        const selectedRespGroupObject = allResponsibilityGroup && allResponsibilityGroup.find((obj: any) => obj.responsibilityid === selectedResGroup.toString());
        respGroup = selectedRespGroupObject ? selectedRespGroupObject.responsibilitytype : null;
      }

      if (selectedRespCenter !== 0 && selectedRespCenter !== undefined && selectedRespCenter !== null) {
        const selectedRespCenterObject = allResponsibilityCenter && allResponsibilityCenter.find((obj: any) => obj.responsibilitynameid === selectedRespCenter.toString());
        respCenter = selectedRespCenterObject ? selectedRespCenterObject.responsibilitytype : null;
      }
      const payload = {
        companyName: company,
        projectName: project,
        responsibilityGroup: respGroup,
        responsibilityCenter: respCenter,
        objectType: objectType,
        objectCode: object,
      }
      getDataLineage(payload);
    }
    // else if (objectType === 0 || objectType === undefined || objectType === null) {
    //   alertMessage("Please select object type", "error");
    // }
  }
  const renderCustomNodeElement = (rd3tProps: any) => {
    const { nodeData } = rd3tProps;
    const isClicked = clickedNode === rd3tProps.nodeDatum;

    const xValue = object === rd3tProps.nodeDatum.name ? -140 : 25
    const circleColor = isClicked ? '#5cb85c' : '#3385ffbf'; // Change color if clicked
    const fillColor = isClicked ? '#5cb85c' : '#ada4a4'; // Change color if clicked

    return (
      <g>
        <circle
          r={20}
          style={{
            cursor: 'pointer',
            fill: '#0000008a',        // Filling the circle with  color
            stroke: '#0000008a',      // Adding a border
            strokeWidth: 1      // Setting border width
          }}
        />
        {/* #212529 */}
        <text
          style={{
            fontWeight: isClicked ? '700' : '100',
            marginLeft: "10px",
            fontSize: isClicked ? "18px" : "16px",
            letterSpacing: object === rd3tProps.nodeDatum.name || isClicked ? "0.8px" : "0px",
            stroke: circleColor,// "#3385ffbf",
            fill: fillColor,// "#ada4a4"
          }} x={xValue} y={10} onClick={() => handleNodeClick(rd3tProps)}>{rd3tProps.nodeDatum.name}</text>
      </g>
    );
  };

  return (
    // overflow-y-auto
    <div className="mt-2 ">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
      </div>

      <div className="d-flex ">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {allResponsibilityGroupResponse.isLoading ||
          allResponsibilityCenterResponse.isLoading ||
          allProjectsResponse.isLoading ||
          objectTypesResponse.isLoading ||
          allCompaniesResponse.isLoading ||
          allObjectsResponse.isLoading ||
          dataLineageResponse.isLoading ? (
          <Loading />
        ) : (
          ""
        )}
      </div>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            selectedCompany: "",
            selectedResGroup: "",
            selectedRespCenter: "",
            selectedProject: ""
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="mt-5 d-flex">
                <div>
                  <div className="d-flex">
                    <div>Company</div>
                    <div style={{ color: "red" }}>*</div>
                  </div>
                  <div className="pe-2">
                    <select
                      className="form-select form-select-sm w-100"
                      aria-label=".form-select-sm example"
                      onChange={handleCompany}
                      name="selectedCompany"
                      value={selectedCompany}
                      required
                    >
                      <option value="">Select Comapny</option>
                      {allCompanies &&
                        allCompanies.map((itemType: any, index: any) => {
                          console.log(itemType)
                          return (
                            <option
                              key={index.toString()}
                              // value={itemType.id}
                              value={parseInt(itemType.companyid)}
                            >
                              {itemType.organization}
                              {/* {itemType.companyname} */}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <div>Responsibilty Group</div>
                    {/* <div style={{ color: "red" }}>*</div> */}
                  </div>
                  <div className="pe-2">
                    <select
                      className="form-select form-select-sm w-100"
                      aria-label=".form-select-sm example"
                      onChange={handleRespGroup}
                      name="selectedResGroup"
                      value={selectedResGroup}
                      required
                    >
                      <option value="">Select Responsibility Group</option>
                      {allResponsibilityGroup &&
                        allResponsibilityGroup.map((itemType: any, index: any) => {
                          return (
                            <option
                              key={index.toString()}
                              // value={itemType.group_code_id}
                              value={itemType.responsibilityid}
                            >
                              {/* {itemType.groupName} */}
                              {itemType.responsibilitytype}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <div>Responsibilty Center</div>
                    {/* <div style={{ color: "red" }}>*</div> */}
                  </div>
                  <div className="pe-2">
                    <select
                      className="form-select form-select-sm w-100"
                      aria-label=".form-select-sm example"
                      onChange={handleRespCenter}
                      name="responsibiltyCenter"
                      value={selectedRespCenter}
                      required
                    >
                      <option value="">Select Responsibility Center</option>
                      {allResponsibilityCenter &&
                        allResponsibilityCenter.map((itemType: any, index: any) => {
                          return (
                            <option
                              key={index.toString()}
                              // value={itemType.responsibility_center_code_id}
                              value={itemType.responsibilitynameid}
                            >
                              {/* {itemType.respCenterCodeDesc} */}
                              {itemType.responsibilitytype}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <div>Project</div>
                    {/* <div style={{ color: "red" }}>*</div> */}
                  </div>
                  <div className="pe-2">
                    <select
                      className="form-select form-select-sm w-100"
                      aria-label=".form-select-sm example"
                      onChange={handleProject}
                      name="selectedProject"
                      value={selectedProject}
                    // required
                    >
                      <option value="">Select Project</option>
                      {getAllProjectData &&
                        getAllProjectData.map((itemType: any, index: any) => {
                          return (
                            <option
                              key={index.toString()}
                              value={itemType.projectid}
                            >
                              {itemType.projectname}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex">
                <div>
                  <div className="d-flex">
                    <div>Object Type</div>
                    {/* <div style={{ color: "red" }}>*</div> */}
                  </div>
                  <div className="pe-2">
                    <select
                      className="form-select form-select-sm w-100"
                      aria-label=".form-select-sm example"
                      onChange={handleObjectType}
                      name="objectType"
                      value={objectType}
                      required
                    >
                      <option value="">Select Object type</option>
                      {getAllObjectTypeData &&
                        getAllObjectTypeData.map((itemType: any, index: any) => {
                          return (
                            <option
                              key={index.toString()}
                              value={itemType.objecttype}
                            >
                              {itemType.objecttype}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <div>Object</div>
                    {/* <div style={{ color: "red" }}>*</div> */}
                  </div>
                  <div className="pe-2">
                    <select
                      className="form-select form-select-sm w-100"
                      aria-label=".form-select-sm example"
                      onChange={handleObjectClick}
                      name="object"
                      value={object}
                      required
                    >
                      <option value="">Select Object</option>
                      {data &&
                        data.map((itemType: any, index: any) => {
                          return (
                            <option
                              key={index.toString()}
                              value={itemType.label}
                            >
                              {itemType.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                {showDObject && (
                  <div>
                    <div className="d-flex">
                      <div>Dependant Object</div>
                      {/* <div style={{ color: "red" }}>*</div> */}
                    </div>
                    <div className="pe-2">
                      <select
                        className="form-select form-select-sm w-100"
                        aria-label=".form-select-sm example"
                        onChange={handleDependentObjectClick}
                        name="dependantObject"
                        value={dependentObject}
                        required
                      >
                        <option value="">Select</option>
                        {dependentObjectOpt &&
                          dependentObjectOpt.map((itemType: any, index: any) => {
                            return (
                              <option
                                key={index.toString()}
                                value={itemType.label}
                              >
                                {itemType.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="d-flex justify-content-md-start w-100 mt-3">
        {showButtons && (
          <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start mx-2">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={() => {
                setShowTree(true);
                setShowTraceabilty(false);
              }}
            >
              Show Tree
            </Button>
            <Button
              variant="primary"
              className="d-flex align-items-center me-0 ms-2"
              onClick={() => {
                if (dependentObject !== undefined) {
                  let temp: any = [];
                  temp.splice(0, 0, "Evidence")
                  temp.splice(1, 0, dependentObject);
                  temp.splice(2, 0, object);

                  buildTreeData(temp);
                  setShowTraceabilty(true);
                  setShowTree(false);
                  setShowError(false);
                } else {
                  setShowError(true);
                }
              }}
            >
              Show Traceabilty
            </Button>
            {showError && (
              <div className="is-invalid text-cm-danger d-flex align-items-center ms-2">Please select dependent object</div>
            )}
          </div>
        )}

        <Button
          variant="primary"
          className="d-flex align-items-center me-0 ms-2"
          onClick={getEvidenceClick}
        >
          Get evidence
        </Button>
      </div>

      {showTraceabilty && (
        <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
          <Tree
            data={treeData}
            orientation="horizontal"
            separation={{ siblings: 1, nonSiblings: 1.6 }}
            initialDepth={4}
            depthFactor={400}
            translate={{ x: 200, y: 300 }}
            renderCustomNodeElement={renderCustomNodeElement}
          />
        </div>
      )}
      {showTree && (
        <div className="my-2 w-100 overflow-x-auto rounded-3 mt-5 " style={{ height: "50vh" }}>
          <Menu className="bg-cm-light-primary1" triggerSubMenuAction="click" items={data && data} style={{ width: "fit-content" }} />
        </div>
      )}

      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          rowKey={"id"}
          columns={
            reportName === REPORT_NAME.DATA_LINEAGE
              ? DATA_LINEAGE_COLUMN
              : undefined
          }
          dataSource={
            reportName === REPORT_NAME.DATA_LINEAGE
              ? datasource
              : ""
          }
        />
      </div>
    </div>
  );
};
export default DataGovernance;
