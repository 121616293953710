import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManagement, userManagementBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
export const moduleService = createApi({
    reducerPath: 'moduleService',
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ['Module'],
    endpoints: (builder) => ({
        getAllModules: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.moduleController.getAllModules}/${body.page}/${body.size}?orderBy=${body.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getModuleById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.moduleController.getByModuleId}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        saveModule: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.moduleController.saveModule}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateModule: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.moduleController.updateModule}/${body.payload.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteModule: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.moduleController.deleteModule}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllModulesWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.moduleController.getAllModuleWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        allModules: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.moduleController.allModules}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
    })
});
export const {
    useGetAllModulesMutation,
    useSaveModuleMutation,
    useUpdateModuleMutation,
    useDeleteModuleMutation,
    useGetModuleByIdMutation,
    useGetAllModulesWithSearchMutation,
    useAllModulesMutation,
} = moduleService;