import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface FAQsState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}
const initialState: FAQsState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    setFAQs: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllFAQsBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllMemberShipDetail: (state, action) => {
      state.data = action.payload.data;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setFAQs, setAllFAQsBySearch, setAllMemberShipDetail, reset } = faqsSlice.actions;
export const getAllFAQsDetails = (state: RootState) => state.faqs.data;
export const getFAQsTotalRecords = (state: RootState) => state.faqs.totalRecords;
export const getAllFAQsBySearch = (state: RootState) => state.faqs.data;
export const getAllMemberShipDetails = (state: RootState) => state.faqs.data;
export default faqsSlice.reducer;