import { useAppSelector } from "../redux-toolkit/hooks";
import { allPackagesDetails } from "../redux-toolkit/slices/packageMasterSlice";
import { PERMISSION_CODE } from "./userPermission";

export const features = [
    {
        id: 0,
        name: "Dashboard",
        data: [
            {
                id: 1,
                name: "Regulatory Insights Dashboard",
                checks: [
                    {
                        package: "Bronze Package",
                        check: true
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            }
        ]
    },
    {
        id: 1,
        name: "Privacy Guard Pro",
        data: [
            {
                id: 1,
                name: "Data Privacy Compliance Test",
                checks: [
                    {
                        package: "Bronze Package",
                        check: true
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 2,
                name: "AI Regulation Compliance Test",
                checks: [
                    {
                        package: "Bronze Package",
                        check: true
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 3,
                name: "Data Privacy Penalty Prediction",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 4,
                name: "Sensitive and Bias Data Identification",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 5,
                name: "Employment Bias",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: false
                    },
                    {
                        package: "Gold Package",
                        check: false
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
        ],
    },
    {
        id: 2,
        name: "Bias Mitigate Pro",
        data: [
            {
                id: 1,
                name: "Data Set Bias Test",
                checks: [
                    {
                        package: "Bronze Package",
                        check: true
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 2,
                name: "Fairness Metrics for Machine Learning Prediction",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 3,
                name: "Project Code Bias and Vulnerability Summary",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 4,
                name: "Unstructured Data Bias Test",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: false
                    },
                    {
                        package: "Gold Package",
                        check: false
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 5,
                name: "Term Comparison Analysis",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: false
                    },
                    {
                        package: "Gold Package",
                        check: false
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
        ],
    },
    {
        id: 3,
        name: "CyberSecAI Shield:",
        data: [
            {
                id: 1,
                name: "Code Cyber Security Vulnerability Analysis",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 2,
                name: "AI Algorithm Accuracy and Reliability Test",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
        ],
    },
    {
        id: 3,
        name: "Prediction Models",
        data: [
            {
                id: 1,
                name: "Regulation Prediction",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
        ],
    },
    {
        id: 4,
        name: "AI Governance",
        data: [
            {
                id: 1,
                name: "Governance Control Creation, Evidence, Assessment, and Audits",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: false
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 2,
                name: "Responsibility Groups and Centers",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: true
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
        ],
    },
    {
        id: 5,
        name: "Risk Management",
        data: [
            {
                id: 1,
                name: "Asset/Non-Asset Based Risk Entries",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: false
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 2,
                name: "Risk Exposure Tracking Before and After Control",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: false
                    },
                    {
                        package: "Gold Package",
                        check: true
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 2,
                name: "Risk Assessment PDF reports",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: false
                    },
                    {
                        package: "Gold Package",
                        check: false
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
            {
                id: 2,
                name: "Data Lineage",
                checks: [
                    {
                        package: "Bronze Package",
                        check: false
                    },
                    {
                        package: "Silver Package",
                        check: false
                    },
                    {
                        package: "Gold Package",
                        check: false
                    },
                    {
                        package: "Platinum Package",
                        check: true
                    }
                ],
            },
        ],
    },
]

export const pricingStrategiesData = [
    {
        id: 1,
        name: "1. Price for Base Package",
        data: [
            {
                package: "Bronze Package",
                info: 'Free or very low'
            },
            {
                package: "Silver Package",
                info: 'Affordable'
            },
            {
                package: "Gold Package",
                info: 'Moderately priced'
            },
            {
                package: "Platinum Package",
                info: 'Competitively priced'
            }
        ],
    },
    {
        id: 2,
        name: "2. Additional Features",
        data: [
            {
                package: "Bronze Package",
                info: 'Essential'
            },
            {
                package: "Silver Package",
                info: 'Expanded'
            },
            {
                package: "Gold Package",
                info: 'Robust'
            },
            {
                package: "Platinum Package",
                info: 'All-inclusive'
            }
        ],
    },
    {
        id: 3,
        name: "3. Educational Discount",
        data: [
            {
                package: "Bronze Package",
                info: '-'
            },
            {
                package: "Silver Package",
                info: 'Yes'
            },
            {
                package: "Gold Package",
                info: 'Yes'
            },
            {
                package: "Platinum Package",
                info: 'Significant discount'
            }
        ],
    },
    {
        id: 4,
        name: "4. Startup Accelerator Programs",
        data: [
            {
                package: "Bronze Package",
                info: '-'
            },
            {
                package: "Silver Package",
                info: 'Yes'
            },
            {
                package: "Gold Package",
                info: 'Yes'
            },
            {
                package: "Platinum Package",
                info: 'Limited free access'
            }
        ],
    },
    {
        id: 5,
        name: "5. Open Source Community Engagement",
        data: [
            {
                package: "Bronze Package",
                info: '-'
            },
            {
                package: "Silver Package",
                info: '-'
            },
            {
                package: "Gold Package",
                info: '-'
            },
            {
                package: "Platinum Package",
                info: 'Certain components'
            }
        ],
    },
    {
        id: 6,
        name: "6. Transparent Pricing",
        data: [
            {
                package: "Bronze Package",
                info: 'Yes'
            },
            {
                package: "Silver Package",
                info: 'Yes'
            },
            {
                package: "Gold Package",
                info: 'Yes'
            },
            {
                package: "Platinum Package",
                info: 'Yes'
            }
        ],
    },
    {
        id: 7,
        name: "7. Flexible Payment Plans",
        data: [
            {
                package: "Bronze Package",
                info: 'Monthly (Optional)'
            },
            {
                package: "Silver Package",
                info: 'Monthly or Annual'
            },
            {
                package: "Gold Package",
                info: 'Monthly or Annual'
            },
            {
                package: "Platinum Package",
                info: 'Monthly or Annual'
            }
        ],
    },
    {
        id: 8,
        name: "8. Data sets",
        data: [
            {
                package: "Bronze Package",
                info: '25'
            },
            {
                package: "Silver Package",
                info: '100'
            },
            {
                package: "Gold Package",
                info: '200'
            },
            {
                package: "Platinum Package",
                info: 'Unlimited'
            }
        ],
    },
    {
        id: 9,
        name: "9. Policy Documents",
        data: [
            {
                package: "Bronze Package",
                info: '25'
            },
            {
                package: "Silver Package",
                info: '100'
            },
            {
                package: "Gold Package",
                info: '200'
            },
            {
                package: "Platinum Package",
                info: ''
            }
        ],
    },
    {
        id: 10,
        name: "10. Free Trial for Gold and Platinum Packages",
        data: [
            {
                package: "Bronze Package",
                info: 'Free or very low'
            },
            {
                package: "Silver Package",
                info: 'Affordable'
            },
            {
                package: "Gold Package",
                info: 'Moderately priced'
            },
            {
                package: "Platinum Package",
                info: 'Competitively priced'
            }
        ],
    },
    {
        id: 11,
        name: "Upgrade Prerequisites",
        data: [
            {
                package: "Bronze Package",
                info: '-'
            },
            {
                package: "Silver Package",
                info: 'Purchase Bronze after 5 workdays'
            },
            {
                package: "Gold Package",
                info: 'Purchase Silver after 10 workdays'
            },
            {
                package: "Platinum Package",
                info: 'Purchase Gold after 20 workdays'
            }
        ],
    },
    {
        id: 12,
        name: "Remote  consulting support ",
        data: [
            {
                package: "Bronze Package",
                info: 'Mutually agreed terms'
            },
            {
                package: "Silver Package",
                info: 'Mutually agreed terms'
            },
            {
                package: "Gold Package",
                info: 'Mutually agreed terms'
            },
            {
                package: "Platinum Package",
                info: 'Mutually agreed terms'
            }
        ],
    },

]

export const corporateBigObject = [
    {
        id: 1,
        title: "Global AI Legislation, Data Privacy, Cyber Security Latest Updates",
        link: "https://lookerstudio.google.com/reporting/e7a4e097-9e3a-42e8-890b-11cbf62dcf64/page/jgwaD",
        image: "GlobalAILegislationPrivacy",
        titleCode: PERMISSION_CODE.GLOBAL_AI_LEGISLATION_DATA_PRIVACY_CYBER_SECURITY_LATEST_UPDATES,
    },
    {
        id: 2,
        title: "Global Legislative AI, Data Privacy and Cyber Security PDF Data Library",
        link: "https://lookerstudio.google.com/reporting/84c91894-8623-4137-9deb-de76249cb717/page/vIwaD",
        image: "GlobalLegislativePDFDataLibrary",
        titleCode: PERMISSION_CODE.GLOBAL_LEGISLATIVE_AI_DATA_PRIVACY_AND_CYBER_SECURITY_PDF_DATA_LIBRARY,
    },
    {
        id: 3,
        title: "USA - AI, Cyber Security and Data Privacy Legislative Insights",
        link: "https://lookerstudio.google.com/reporting/65a85092-ab87-408a-9149-5f0c8a3a3a2b/page/3QyZD",
        image: "USAAICyberSecurityDataPrivacy",
        titleCode: PERMISSION_CODE.USA_AI_CYBER_SECURITY_AND_DATA_PRIVACY_LEGISLATIVE_INSIGHTS,
    },
    {
        id: 4,
        title: "GDPR Penalty Dashboard",
        link: "https://lookerstudio.google.com/reporting/e0d78101-9b3b-4f09-88c6-aa9858de321a/page/tnmZD",
        image: "GDPRPenalty",
        titleCode: PERMISSION_CODE.GDPR_PENALTY_DASHBOARD,
    },
    {
        id: 5,
        title: "Global AI Legislation Focus Area",
        link: "https://lookerstudio.google.com/u/0/reporting/53f75a39-1379-4dc9-b643-6034ddf8c8b0/page/qxObD",
        image: "globalAILFoucusAreas",
        titleCode: PERMISSION_CODE.GLOBAL_AI_LEGISLATION_FOCUS_AREA,
    },
    {
        id: 6,
        title: "A.I. Legislation Bill Status Updates",
        link: "https://lookerstudio.google.com/reporting/5d4b1a7d-9300-42e0-939b-aee7829f6ad9/page/JCTbD",
        image: "aiLegislationBillStatusUpdates",
        titleCode: PERMISSION_CODE.AI_LEGISLATION_BILL_STATUS_UPDATES,
    },
]

export const govBigObject = [
    {
        id: 1,
        title: "Global AI Legislation, Data Privacy, Cyber Security Latest Updates",
        link: "https://lookerstudio.google.com/reporting/e7a4e097-9e3a-42e8-890b-11cbf62dcf64/page/jgwaD",
        image: "GlobalAILegislationPrivacy",
        titleCode: PERMISSION_CODE.GLOBAL_AI_LEGISLATION_DATA_PRIVACY_CYBER_SECURITY_LATEST_UPDATES,
    },
    {
        id: 2,
        title: "Global Legislative AI, Data Privacy and Cyber Security PDF Data Library",
        link: "https://lookerstudio.google.com/reporting/84c91894-8623-4137-9deb-de76249cb717/page/vIwaD",
        image: "GlobalLegislativePDFDataLibrary",
        titleCode: PERMISSION_CODE.GLOBAL_LEGISLATIVE_AI_DATA_PRIVACY_AND_CYBER_SECURITY_PDF_DATA_LIBRARY
    }
]

export const corporateSmallObject = [
    {
        id: 1,
        title: "Web Scrap Data Analysis",
        link: "https://lookerstudio.google.com/reporting/98f4d7e9-d907-4116-adbe-0d4ee53b5468/page/EGXaD",
        image: "webScrap",
        titleCode: PERMISSION_CODE.WEB_SCRAP_DATA_ANALYSIS,
    },
    {
        id: 2,
        title: "Committees Insights",
        link: "https://lookerstudio.google.com/reporting/4141930e-19a7-4099-b1b4-ef26261dc415/page/VZCaD",
        image: "committes",
        titleCode: PERMISSION_CODE.COMMITTEES_INSIGHTS,
    },
    {
        id: 3,
        title: "Congress",
        link: "https://lookerstudio.google.com/reporting/9ab6dc96-1580-400b-b4ef-aa072defd348/page/C2AaD",
        image: "congress",
        titleCode: PERMISSION_CODE.CONGRESS,
    },
    {
        id: 4,
        title: "EU Legislative Title Insights",
        link: "https://lookerstudio.google.com/reporting/e01056aa-db2b-4bf5-ab24-cf405fb6d145/page/EoJaD",
        image: "EuLegislative",
        titleCode: PERMISSION_CODE.EU_LEGISLATIVE_TITLE_INSIGHTS,
    },
    {
        id: 5,
        title: "EU Legislative Dashboard",
        link: "https://lookerstudio.google.com/reporting/34f0b3f6-d351-44ae-a0de-72fe5a2c8d34/page/suJaD",
        image: "EuLegislativeDashboard",
        titleCode: PERMISSION_CODE.EU_LEGISLATIVE_DASHBOARD,
    },
    {
        id: 6,
        title: "Top 10 Sponsors Dashboard",
        link: "https://lookerstudio.google.com/reporting/a223b9aa-2d62-4b04-9941-4ca74e056ed8/page/nDWaD",
        image: "Top10Sponsors",
        titleCode: PERMISSION_CODE.TOP_10_SPONSORS_DASHBOARD,
    },
    {
        id: 7,
        title: "USA Legislative Data Wordcloud Analytics",
        link: "https://lookerstudio.google.com/reporting/27b60a49-e59c-4f35-9fcd-ae074de2f0fd/page/sHhaD",
        image: "USADataWordcloud",
        titleCode: PERMISSION_CODE.USA_LEGISLATIVE_DATA_WORDCLOUD_ANALYTICS,
    },

]

export const govSmallObject = [
    {
        id: 1,
        title: "Web Scrap Data Analysis",
        link: "https://lookerstudio.google.com/reporting/98f4d7e9-d907-4116-adbe-0d4ee53b5468/page/EGXaD",
        image: "webScrap",
        titleCode: PERMISSION_CODE.WEB_SCRAP_DATA_ANALYSIS,
    },
    {
        id: 2,
        title: "Committees Insights",
        link: "https://lookerstudio.google.com/reporting/4141930e-19a7-4099-b1b4-ef26261dc415/page/VZCaD",
        image: "committes",
        titleCode: PERMISSION_CODE.COMMITTEES_INSIGHTS,
    },
    {
        id: 3,
        title: "Congress",
        link: "https://lookerstudio.google.com/reporting/9ab6dc96-1580-400b-b4ef-aa072defd348/page/C2AaD",
        image: "congress",
        titleCode: PERMISSION_CODE.CONGRESS,
    },
    {
        id: 4,
        title: "EU Legislative Title Insights",
        link: "https://lookerstudio.google.com/reporting/e01056aa-db2b-4bf5-ab24-cf405fb6d145/page/EoJaD",
        image: "EuLegislative",
        titleCode: PERMISSION_CODE.EU_LEGISLATIVE_TITLE_INSIGHTS,
    },
    {
        id: 5,
        title: "EU Legislative Dashboard",
        link: "https://lookerstudio.google.com/reporting/34f0b3f6-d351-44ae-a0de-72fe5a2c8d34/page/suJaD",
        image: "EuLegislativeDashboard",
        titleCode: PERMISSION_CODE.EU_LEGISLATIVE_DASHBOARD,
    },
]


export const MenuItemsData = [
    {
        label: 'Organization',
        key: 'Organization',
        children: [
            {
                label: 'Group Details',
                key: 'GroupDetails',
                children: [
                    {
                        label: 'Parameter',
                        key: 'parameter',
                        onClick: (e: any) => {
                            // console.log(e, 'e')
                            // Do something when the Parameter item is clicked.
                        },
                    },
                    {
                        label: 'Evidence governance control',
                        key: 'evidence',
                    },
                    {
                        label: 'Risk governance control',
                        key: 'risk',
                    },
                    {
                        label: 'Governance Control',
                        key: 'governanceControl',
                    },
                    {
                        label: 'Assessment',
                        key: 'assessment',
                    },
                    {
                        label: 'Audit Plan',
                        key: 'auditPlan',
                    },
                    {
                        label: 'Audit',
                        key: 'audit',
                    },
                ],
            },
            {
                label: 'Group Members',
                key: 'GroupMembers',
            },
            {
                label: 'Group Policies',
                key: 'GroupPolicies'
            },
            {
                label: 'Group Projects',
                key: 'GroupProjects'
            },
        ],
    },
    {
        label: 'Alphabet Group',
        key: 'AlphabetGroup',
        children: [
            {
                label: 'Group Details',
                key: 'GroupDetails'
            },
            {
                label: 'Group Membership',
                key: 'GroupMembership'
            },
        ],
    },
    {
        label: 'Responsibility Group',
        key: 'ResponsibilityGroup',
        children: [
            {
                label: 'Group Details',
                key: 'GroupDetails'
            },
            {
                label: 'Group Members',
                key: 'GroupMembers'
            },
            {
                label: 'Group Policies',
                key: 'GroupPolicies'
            },
            {
                label: 'Group Projects',
                key: 'GroupProjects'
            },
            {
                label: 'Center Details',
                key: 'CenterDetails'
            },
            {
                label: 'CenterPolicies',
                key: 'CenterPolicies'
            },
            {
                label: 'Center Projects',
                key: 'CenterProjects'
            },
        ],
    },
    {
        label: 'GDPR Compliance',
        key: 'GDPRCompliance',
        children: [
            {
                label: 'Compliance Details',
                key: 'ComplianceDetails'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Compliance Projects',
                key: 'ComplianceProjects'
            },
            {
                label: 'Data Destruction',
                key: 'Data Destruction'
            }
        ],
    },


    {
        label: 'Data Collection',
        key: 'DataCollection',
        children: [
            {
                label: 'Collection Details',
                key: 'CollectionDetails'
            },
            {
                label: 'Data Collectors',
                key: 'DataCollectors'
            },
            {
                label: 'Collection Policies',
                key: 'CollectionPolicies'
            },
            {
                label: 'Data Sources',
                key: 'DataSources'
            },
        ],
    },
    {
        label: 'Data Sources',
        key: 'DataSources',
        children: [
            {
                label: 'Source Details',
                key: 'SourceDetails'
            },
            {
                label: 'Source Contact Informaion',
                key: 'SourceContactInformaion'
            },
            {
                label: 'Data Types',
                key: 'DataTypes'
            },
        ],
    },
    {
        label: 'Data Types',
        key: 'DataTypes',
        children: [
            {
                label: 'Type Details',
                key: 'TypeDetails'
            },
            {
                label: 'Data Consent',
                key: 'DataConsent'
            },
        ],
    },
    {
        label: 'Data Processing',
        key: 'DataProcessing',
        children: [
            {
                label: 'Processing Activity',
                key: 'ProcessingActivity'
            },
            {
                label: 'Data Categories',
                key: 'DataCategories'
            },
            {
                label: 'Data Retention',
                key: 'DataRetention'
            },
            {
                label: 'Data Storage',
                key: 'DataStorage'
            },
            {
                label: 'Processing Activity',
                key: 'ProcessingActivity'
            },
            {
                label: 'Data Categories',
                key: 'DataCategories'
            },
            {
                label: 'Data Encryption',
                key: 'DataEncryption'
            },
            {
                label: 'Encryption Key',
                key: 'EncryptionKey'
            },
            {
                label: 'Encryption Controls',
                key: 'EncryptionControls'
            },
            {
                label: 'Encryption Method',
                key: 'EncryptionMethod'
            },
            {
                label: 'Access Controls',
                key: 'AccessControls'
            },
            {
                label: 'Data Anonymization',
                key: 'DataAnonymization'
            },
        ],
    },
    {
        label: 'Data Access',
        key: 'DataAccess',
        children: [
            {
                label: 'Access Control Type',
                key: 'AccessControlType'
            },
            {
                label: 'Access Control Policy',
                key: 'AccessControlPolicy'
            },
            {
                label: 'Authorization Model',
                key: 'AuthorizationModel'
            },
            {
                label: 'Permissions',
                key: 'Permissions'
            },
            {
                label: 'Role-Based Access',
                key: 'RoleBasedAccess'
            },
            {
                label: 'User Access',
                key: 'UserAccess'
            },
            {
                label: 'Access Control Type',
                key: 'AccessControlType'
            },
            {
                label: 'Authorization Model',
                key: 'AuthorizationModel'
            },
            {
                label: 'Data Requests',
                key: 'DataRequests'
            },
            {
                label: 'Request Status',
                key: 'RequestStatus'
            },
            {
                label: 'Requester Information',
                key: 'RequesterInformation'
            },
        ],
    },
    {
        label: 'Data Requests',
        key: 'DataRequests',
        children: [
            {
                label: 'Request Type',
                key: 'RequestType'
            },
            {
                label: 'Request Status',
                key: 'RequestStatus'
            },
            {
                label: 'Requester Informationl',
                key: 'RequesterInformation'
            },
            {
                label: 'Data Subject Info',
                key: 'DataSubjectInfo'
            },
            {
                label: 'Request Details',
                key: 'RequestDetails'
            },
            {
                label: 'User Consent',
                key: 'UserConsent'
            },
            {
                label: 'Consent Status',
                key: 'ConsentStatus'
            },
            {
                label: 'Consent Records',
                key: 'ConsentRecords'
            },
        ],
    },
    {
        label: 'Data Retention',
        key: 'DataRetention',
        children: [
            {
                label: 'Request Type',
                key: 'RequestType'
            },
            {
                label: 'Request Status',
                key: 'RequestStatus'
            },
            {
                label: 'Requester Informationl',
                key: 'RequesterInformation'
            },
            {
                label: 'Data Subject Info',
                key: 'DataSubjectInfo'
            },
            {
                label: 'Request Details',
                key: 'RequestDetails'
            },
            {
                label: 'User Consent',
                key: 'UserConsent'
            },
            {
                label: 'Consent Status',
                key: 'ConsentStatus'
            },
            {
                label: 'Consent Records',
                key: 'ConsentRecords'
            },
            {
                label: 'Retention Period',
                key: 'RetentionPeriod'
            },
            {
                label: 'Data Destruction',
                key: 'DataDestruction'
            },
            {
                label: 'Data Transfer',
                key: 'DataTransfer'
            },
            {
                label: 'Transfer Recipients',
                key: 'TransferRecipients'
            },
            {
                label: 'Data Encryption',
                key: 'DataEncryption'
            },
            {
                label: 'Data Export',
                key: 'DataExport'
            },
            {
                label: 'Export Recipients',
                key: 'ExportRecipients'
            },
        ],
    },
    {
        label: 'Data Deletion',
        key: 'Data Deletion',
        children: [
            {
                label: 'Deletion Requests',
                key: 'DeletionRequests'
            },
            {
                label: 'Request Status',
                key: 'RequestStatus'
            },
            {
                label: 'Requester Information',
                key: 'RequesterInformation'
            },
            {
                label: 'Data Subject Info',
                key: 'DataSubjectInfo'
            },
            {
                label: 'Data Deletion',
                key: 'DataDeletion'
            },
            {
                label: 'Archiving Requirements',
                key: 'ArchivingRequirements'
            },
            {
                label: 'Archiving Strategy',
                key: 'ArchivingStrategy'
            },
            {
                label: 'Data Retrieval',
                key: 'DataRetrieval'
            },
            {
                label: 'Retention Period',
                key: 'RetentionPeriod'
            },
            {
                label: 'Data Destruction',
                key: 'DataDestruction'
            },
            {
                label: 'Data Transfer',
                key: 'DataTransfer'
            },
            {
                label: 'Transfer Recipients',
                key: 'TransferRecipients'
            },
            {
                label: 'Data Encryption',
                key: 'DataEncryption'
            },
            {
                label: 'Retention Period',
                key: 'RetentionPeriod'
            },
            {
                label: 'Data Export',
                key: 'DataExport'
            },
            {
                label: 'Export Recipients',
                key: 'ExportRecipients'
            },
            {
                label: 'Data Encryption',
                key: 'DataEncryption'
            }
        ],
    },
    {
        label: 'Data Export',
        key: 'DataExport',
        children: [
            {
                label: 'Export Methods',
                key: 'ExportMethods'
            },
            {
                label: 'Export Recipients',
                key: 'ExportRecipients'
            },
            {
                label: 'Data Encryption',
                key: 'DataEncryption'
            },
            {
                label: 'Sharing Agreements',
                key: 'SharingAgreements'
            },
            {
                label: 'Parties Involved',
                key: 'PartiesInvolved'
            },
            {
                label: 'Data Categories',
                key: 'DataCategories'
            },
            {
                label: 'Data Security',
                key: 'DataSecurity'
            },
        ],
    },
    {
        label: 'Responsibility Center',
        key: 'ResponsibilityCenter',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'AI Regulation Standards',
                key: 'AIRegulationStandards'
            },
            {
                label: 'Compliance Reporting',
                key: 'ComplianceReporting'
            },
            {
                label: 'Regulatory Audits',
                key: 'RegulatoryAudits'
            },
            {
                label: 'Compliance Records',
                key: 'ComplianceRecords'
            },
            {
                label: 'Incident Response',
                key: 'IncidentResponse'
            },
        ],
    },
    {
        label: 'AI Regulation Compliance(NIST)',
        key: 'AIRegulationCompliance(NIST)',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Training Standards',
                key: 'TrainingStandards'
            },
            {
                label: 'Training Process',
                key: 'TrainingProcess'
            },
            {
                label: 'Data Sources',
                key: 'DataSources'
            },
            {
                label: 'Data Preprocessing',
                key: 'DataPreprocessing'
            },
        ],
    },
    {
        label: 'AI Model Training',
        key: 'AIModelTraining',
        children: [
            {
                label: 'Training Data Sources ',
                key: 'TrainingDataSources '
            },
            {
                label: 'Data Quality Checks',
                key: 'DataQualityChecks'
            },
            {
                label: 'Data Preprocessing',
                key: 'DataPreprocessing'
            },
            {
                label: 'Data Augmentation',
                key: 'DataAugmentation'
            },
            {
                label: 'Data Privacy',
                key: 'DataPrivacy'
            },
            {
                label: 'Training Data Sources',
                key: 'TrainingDataSources '
            },
            {
                label: 'Data Quality Checks',
                key: 'DataQualityChecks'
            },
            {
                label: 'Data Augmentation',
                key: 'DataAugmentation'
            },
            {
                label: 'Model Validation',
                key: 'ModelValidation'
            },
            {
                label: 'Evaluation Metrics',
                key: 'EvaluationMetrics'
            },
        ],
    },
    {
        label: 'Model Validation',
        key: 'ModelValidation',
        children: [
            {
                label: 'Validation Techniques',
                key: 'ValidationTechniques'
            },
            {
                label: 'Evaluation Metrics',
                key: 'EvaluationMetrics'
            },
            {
                label: 'Validation Reports',
                key: 'ValidationReports'
            },
            {
                label: 'Model Testing',
                key: 'ModelTesting'
            },
            {
                label: 'Test Data',
                key: 'TestData'
            },
            {
                label: 'Test Scenarios',
                key: 'TestScenarios'
            },
            {
                label: 'Test Reports',
                key: 'TestReports'
            },
        ],
    },
    {
        label: 'AI Regulation Compliance(NIST)',
        key: 'AIRegulationCompliance(NIST)',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Deployment Standards',
                key: 'DeploymentStandards'
            },
            {
                label: 'Deployment Processes',
                key: 'DeploymentProcesses'
            },
            {
                label: 'Deployment Environment',
                key: 'DeploymentEnvironment'
            },
            {
                label: 'Model Monitoring',
                key: 'ModelMonitoring'
            },
            {
                label: 'Security Controls',
                key: 'SecurityControls'
            },
            {
                label: 'Reporting Mechanisms',
                key: 'ReportingMechanisms'
            },
            {
                label: 'Incident Response',
                key: 'IncidentResponse'
            },
            {
                label: 'Bias Assessment',
                key: 'BiasAssessment'
            },
            {
                label: 'Fairness Metrics',
                key: 'FairnessMetrics'
            },
            {
                label: 'Bias Mitigation',
                key: 'BiasMitigation'
            },
            {
                label: 'Mitigation Evaluation',
                key: 'MitigationEvaluation'
            },
            {
                label: 'Bias Reports',
                key: 'BiasReports'
            },
        ],
    },
    {
        label: 'Model Monitoring',
        key: 'ModelMonitoring',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Monitoring Procedures',
                key: 'MonitoringProcedures'
            },
            {
                label: 'Explainability Metrics',
                key: 'ExplainabilityMetrics'
            },
            {
                label: 'Interpretation Reports',
                key: 'InterpretationReports'
            },
            {
                label: 'Model Adjustments',
                key: 'ModelAdjustments'
            },
            {
                label: 'Compliance Logs',
                key: 'ComplianceLogs'
            },
        ],
    },
    {
        label: 'AI Bias Mitigation',
        key: 'AIBiasMitigation',
        children: [
            {
                label: 'Bias Assessment',
                key: 'BiasAssessment'
            },
            {
                label: 'Fairness Metrics',
                key: 'FairnessMetrics'
            },
            {
                label: 'Bias Mitigation',
                key: 'BiasMitigation'
            },
            {
                label: 'Mitigation Evaluation',
                key: 'MitigationEvaluation'
            },
            {
                label: 'Ethical Principles',
                key: 'EthicalPrinciples'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Ethical Reviews',
                key: 'EthicalReviews'
            },
        ],
    },
    {
        label: 'Responsibility Center',
        key: 'ResponsibilityCenter',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Security Policies',
                key: 'SecurityPolicies'
            },
            {
                label: 'Security Controls',
                key: 'SecurityControls'
            },
            {
                label: 'Security Testing',
                key: 'SecurityTesting'
            },
            {
                label: 'Incident Response',
                key: 'IncidentResponse'
            },
            {
                label: 'Security Auditing',
                key: 'SecurityAuditing'
            },
            {
                label: 'Responsibility Area',
                key: 'ResponsibilityArea'
            },
            {
                label: 'Team Members',
                key: 'TeamMembers'
            },
            {
                label: 'Audit Planning',
                key: 'AuditPlanning'
            },
            {
                label: 'Audit Execution',
                key: 'AuditExecution'
            },
            {
                label: 'Audit Findings',
                key: 'AuditFindings'
            },
            {
                label: 'GDPR Non-Compliance',
                key: 'GDPRNonCompliance'
            },
            {
                label: 'AI Regulation Violations',
                key: 'AIRegulationViolations'
            },
            {
                label: 'Security Vulnerabilities',
                key: 'SecurityVulnerabilities'
            },
            {
                label: 'Audit Action Plans',
                key: 'AuditActionPlans'
            },
            {
                label: 'Remediation Plans',
                key: 'RemediationPlans'
            },
            {
                label: 'Compliance Improvements',
                key: 'ComplianceImprovements'
            },
            {
                label: 'Review Elements',
                key: 'ReviewElements'
            },
            {
                label: 'Policies and Procedures',
                key: 'PoliciesAndProcedures'
            },
            {
                label: 'GDPR Policies',
                key: 'GDPRPolicies'
            },
            {
                label: 'AI Regulation Procedure',
                key: 'AIRegulationProcedure'
            },
            {
                label: 'Cybersecurity Policies',
                key: 'CybersecurityPolicies'
            },
            {
                label: 'Security Procedures',
                key: 'SecurityProcedures'
            },
            {
                label: 'Asset Reviews',
                key: 'AssetReviews'
            },
            {
                label: 'Data Assets',
                key: 'DataAssets'
            },
            {
                label: 'AIModels',
                key: 'AIModels'
            },
            {
                label: 'Test Logs',
                key: 'TestLogs'
            },
            {
                label: 'Data Sets',
                key: 'DataSets'
            },
            {
                label: 'Bias Elimination',
                key: 'BiasElimination'
            },
            {
                label: 'Codebase',
                key: 'Codebase'
            },
            {
                label: 'Cloud Infrastructure',
                key: 'CloudInfrastructure'
            },
            {
                label: 'Risk Assessment',
                key: 'RiskAssessment'
            },
            {
                label: 'CIA Risk Assessment',
                key: 'CIARiskAssessment'
            },
            {
                label: 'Risk Mitigation Strategies',
                key: 'RiskMitigationStrategies'
            },
            {
                label: 'Risk Reduction Measures',
                key: 'RiskReductionMeasures'
            },
            {
                label: 'SDLC and Documentation',
                key: 'SDLCAndDocumentation'
            },
            {
                label: 'Code Reviews',
                key: 'CodeReviews'
            },
            {
                label: 'Design Documents',
                key: 'DesignDocuments'
            },
            {
                label: 'DeploymentPlans',
                key: 'DeploymentPlans'
            },
            {
                label: 'Logs and Monitoring',
                key: 'LogsAndMonitoring'
            },
            {
                label: 'Change Management',
                key: 'ChangeManagement'
            },
            {
                label: 'Documentation Reviews',
                key: 'DocumentationReviews'
            },
            {
                label: 'Requirements',
                key: 'Requirements'
            },
            {
                label: 'Requirement Documents',
                key: 'RequirementDocuments'
            },
            {
                label: 'Requirement Reviews',
                key: 'RequirementReviews'
            },
            {
                label: 'Requirement Version Tracking',
                key: 'RequirementVersionTracking'
            },
            {
                label: 'Requirement Traceability',
                key: 'RequirementTraceability'
            },
            {
                label: 'Test Cases',
                key: 'TestCases'
            },
            {
                label: 'Test Case Documents',
                key: 'TestCaseDocuments'
            }, {
                label: 'Test Case Reviews',
                key: 'TestCaseReviews'
            },
            {
                label: 'Test Case Version Tracking',
                key: 'TestCaseVersionTracking'
            },
            {
                label: 'Test Case Execution Reports',
                key: 'TestCaseExecutionReports'
            },
            {
                label: 'Test Automation',
                key: 'TestAutomation'
            },
            {
                label: 'IT Infrastructure',
                key: 'ITInfrastructure'
            },
            {
                label: 'Infrastructure Documentation',
                key: 'InfrastructureDocumentation'
            },
            {
                label: 'Infrastructure Reviews',
                key: 'InfrastructureReviews'
            },
            {
                label: 'Infrastructure Version Tracking',
                key: 'InfrastructureVersionTracking'
            },
            {
                label: 'Infrastructure Updates',
                key: 'InfrastructureUpdates'
            }, {
                label: 'Cloud Infrastructure',
                key: 'CloudInfrastructure'
            },
            {
                label: 'Tools',
                key: 'Tools'
            }, {
                label: 'Tool Reviews',
                key: 'ToolReviews'
            },
            {
                label: 'Tool Version Tracking',
                key: 'ToolVersionTracking'
            }, {
                label: 'Tool Updates',
                key: 'ToolUpdates'
            },
            {
                label: 'Data Lineage',
                key: 'DataLineage'
            }, {
                label: 'Data Sources',
                key: 'Data Sources'
            },
            {
                label: 'Data Transformations',
                key: 'DataTransformations'
            }, {
                label: 'Data Destinations',
                key: 'DataDestinations'
            },
            {
                label: 'Data Quality',
                key: 'DataQuality'
            },
            {
                label: 'Data Quality Rules',
                key: 'Data Quality Rules'
            },
            {
                label: 'Data Quality Assessments',
                key: 'DataQualityAssessments'
            },
            {
                label: 'Data Quality Reports',
                key: 'DataQualityReports'
            },
            {
                label: 'Governance Frameworks',
                key: 'GovernanceFrameworks'
            },
            {
                label: 'Framework A',
                key: 'FrameworkA'
            },
            {
                label: 'Control Areas',
                key: 'ControlAreas'
            },
            {
                label: 'Control X',
                key: 'ControlX'
            },
            {
                label: 'Subcontrols',
                key: 'Subcontrols'
            },
            {
                label: 'Subcontrol P',
                key: 'SubcontrolP'
            },
            {
                label: 'Evidences',
                key: 'Evidences'
            },
            {
                label: 'Evidence 1',
                key: 'Evidence1'
            },
            {
                label: 'Evidence 2',
                key: 'Evidence2'
            },
            {
                label: 'Audit Action Plans',
                key: 'Audit Action Plans'
            },
            {
                label: 'Audit Action Plans',
                key: 'Audit Action Plans'
            },
        ],
    },
    {
        label: 'Cybersecurity Compliance',
        key: 'CybersecurityCompliance',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Security Policies',
                key: 'SecurityPolicies'
            },
            {
                label: 'Policy Categories',
                key: 'PolicyCategories'
            },
            {
                label: 'Policy Elements',
                key: 'PolicyElements'
            },
            {
                label: 'Policy Implementation',
                key: 'PolicyImplementation'
            },
            {
                label: 'Policy Reviews',
                key: 'PolicyReviews'
            },
            {
                label: 'Security Controls',
                key: 'SecurityControls'
            },
            {
                label: 'Control Categories',
                key: 'ControlCategories'
            },
            {
                label: 'Control Measures',
                key: 'ControlMeasures'
            },
            {
                label: 'Control Implementation',
                key: 'ControlImplementation'
            },
            {
                label: 'Control Assessments',
                key: 'ControlAssessments'
            },
            {
                label: 'Testing Schedule',
                key: 'TestingSchedule'
            },
            {
                label: 'Testing Results',
                key: 'TestingResults'
            },
            {
                label: 'Remediation Plans',
                key: 'RemediationPlans'
            },
            {
                label: 'Security Auditing',
                key: 'SecurityAuditing'
            },
            {
                label: 'Incident Response',
                key: 'IncidentResponse'
            },
            {
                label: 'Incident Reporting',
                key: 'IncidentReporting'
            },
            {
                label: 'Investigation Process',
                key: 'InvestigationProcess'
            },
            {
                label: 'Resolution Measures',
                key: 'ResolutionMeasures'
            },
        ],
    },
    {
        label: 'IncidentResponse',
        key: 'IncidentResponse',
        children: [
            {
                label: 'Incident Categories',
                key: 'IncidentCategories'
            },
            {
                label: 'Incident Reporting',
                key: 'IncidentReporting'
            },
            {
                label: 'Investigation',
                key: 'Investigation'
            },
            {
                label: 'Incident Resolution',
                key: 'IncidentResolution'
            },
            {
                label: 'Communication',
                key: 'Communication'
            },
            {
                label: 'Security Auditing',
                key: 'SecurityAuditing'
            },
            {
                label: 'Auditing Schedule',
                key: 'AuditingSchedule'
            },
            {
                label: 'Audit Remediation',
                key: 'AuditRemediation'
            },
        ],
    },
    {
        label: 'Audit Elements',
        key: 'AuditElements',
        children: [
            {
                label: 'Audit Planning',
                key: 'AuditPlanning'
            },
            {
                label: 'Audit Execution',
                key: 'AuditExecution'
            },
            {
                label: 'Audit Findings',
                key: 'AuditFindings'
            },
            {
                label: 'GDPR Non-Compliance',
                key: 'GDPRNonCompliance'
            },
            {
                label: 'AI Regulation Violations',
                key: 'AIRegulationViolations'
            },
            {
                label: 'Audit Action Plans',
                key: 'AuditActionPlans'
            },
            {
                label: 'Review Elements',
                key: 'ReviewElements'
            },
            {
                label: 'Policies and Procedures',
                key: 'PoliciesAndProcedures'
            },
            {
                label: 'Asset Reviews',
                key: 'AssetReviews'
            },
            {
                label: 'Risk Assessment',
                key: 'RiskAssessment'
            },
            {
                label: 'SDLC and Documentation',
                key: 'SDLCAndDocumentation'
            },
            {
                label: 'Requirements',
                key: 'Requirements'
            },
            {
                label: 'Test Cases',
                key: 'TestCases'
            },
            {
                label: 'IT Infrastructure',
                key: 'ITInfrastructure'
            },
            {
                label: 'Tools',
                key: 'Tools'
            },
            {
                label: 'Data Lineage',
                key: 'DataLineage'
            },
            {
                label: 'Governance Frameworks',
                key: 'GovernanceFrameworks'
            },
            {
                label: 'Control ID',
                key: 'ControlID'
            },
            {
                label: 'Element ID',
                key: 'ElementID'
            },
            {
                label: 'Control Name',
                key: 'ControlName'
            },
            {
                label: 'Description',
                key: 'Description'
            },
            {
                label: 'Owner Name',
                key: 'OwnerName'
            },
            {
                label: 'Control Type',
                key: 'ControlType'
            },
            {
                label: 'General Information',
                key: 'General Information'
            },
            {
                label: 'Compliance Details',
                key: 'Compliance Details'
            },
            {
                label: 'Supporting Documents',
                key: 'Supporting Documents'
            },
        ],
    },
    {
        label: 'AI Regulation Violations',
        key: 'AIRegulationViolations',
        children: [
            {
                label: 'Security Vulnerabilities',
                key: 'SecurityVulnerabilities'
            }
        ],
    },
    {
        label: 'Audit Findings',
        key: 'AuditFindings',
        children: [
            {
                label: 'Remediation Plans',
                key: 'RemediationPlans'
            }
        ],
    },
    {
        label: 'Remediation Plans',
        key: 'RemediationPlans',
        children: [
            {
                label: 'Compliance Improvements',
                key: 'ComplianceImprovements'
            }
        ],
    },
    {
        label: 'Policies and Procedures',
        key: 'PoliciesAndProcedures',
        children: [
            {
                label: 'GDPR Policies',
                key: 'GDPRPolicies'
            },
            {
                label: 'AI Regulation Procedures',
                key: 'AIRegulationProcedures'
            },
            {
                label: 'Cybersecurity Policies',
                key: 'CybersecurityPolicies'
            },
        ],
    },
    {
        label: 'Cybersecurity Policies',
        key: 'CybersecurityPolicies',
        children: [
            {
                label: 'Security Procedures',
                key: 'SecurityProcedures'
            },
        ],
    },
    {
        label: 'Asset Reviews',
        key: 'AssetReviews',
        children: [
            {
                label: 'Data Assets',
                key: 'DataAssets'
            },
            {
                label: 'AI Models',
                key: 'AIModels'
            },
        ],
    },
    {
        label: 'AI Models',
        key: 'AIModels',
        children: [
            {
                label: 'Test Logs',
                key: 'TestLogs'
            },
            {
                label: 'Data sets',
                key: 'DataSets'
            },
        ],
    },
    {
        label: 'Data sets',
        key: 'DataSets',
        children: [
            {
                label: 'Bias Elimination',
                key: 'BiasElimination'
            },
        ],
    },
    {
        label: 'Asset Reviews',
        key: 'AssetReviews',
        children: [
            {
                label: 'Codebase',
                key: 'Codebase'
            },
        ],
    },
    {
        label: 'Codebase',
        key: 'Codebase',
        children: [
            {
                label: 'Cloud',
                key: 'Cloud'
            },
        ],
    },
    {
        label: 'Risk Assessment',
        key: 'RiskAssessment',
        children: [
            {
                label: 'CIA Risk Assessment',
                key: 'CIARiskAssessment'
            },
            {
                label: 'Risk Mitigation Strategies',
                key: 'RiskMitigationStrategies'
            },
        ],
    },
    {
        label: 'Risk Mitigation Strategies',
        key: 'RiskMitigationStrategies',
        children: [
            {
                label: 'Risk Reduction Measures',
                key: 'RiskReductionMeasures'
            },
            {
                label: 'Risk Mitigation Strategies',
                key: 'RiskMitigationStrategies'
            },
        ],
    },
    {
        label: 'SDLC and Documentation',
        key: 'SDLCAndDocumentation',
        children: [
            {
                label: 'SDLC Process',
                key: 'SDLCProcess'
            },
            {
                label: 'Code Reviews',
                key: 'CodeReviews'
            },
            {
                label: 'Design Documents',
                key: 'DesignDocuments'
            },
            {
                label: 'Deployment Plans',
                key: 'Deployment Plans'
            },
            {
                label: 'Logs and Monitoring',
                key: 'LogsAndMonitoring'
            },
            {
                label: 'Change Management',
                key: 'ChangeManagement'
            },
        ],
    },
    {
        label: 'Change Management',
        key: 'ChangeManagement',
        children: [
            {
                label: 'Documentation Reviews',
                key: 'DocumentationReviews'
            },
        ],
    },
    {
        label: 'Requirements',
        key: 'Requirements',
        children: [
            {
                label: 'Requirement Documents',
                key: 'RequirementDocuments'
            },
            {
                label: 'Requirement Reviews',
                key: 'RequirementReviews'
            },
            {
                label: 'Requirement Version Tracking',
                key: 'RequirementVersionTracking'
            },
            {
                label: 'Requirement Traceability',
                key: 'RequirementTraceability'
            },
        ],
    },
    {
        label: 'Test Cases',
        key: 'TestCases',
        children: [
            {
                label: 'Test Case Documents',
                key: 'TestCaseDocuments'
            },
            {
                label: 'Test Case Reviews ',
                key: 'TestCaseReviews '
            },
            {
                label: 'Test Case Version',
                key: 'TestCaseVersion'
            },
            {
                label: 'Test Case Execution Report',
                key: 'TestCaseExecutionReport'
            },
        ],
    },
    {
        label: 'Test Case Execution Reports',
        key: 'TestCaseExecutionReports',
        children: [
            {
                label: 'Test Automation',
                key: 'TestAutomation'
            },
        ],
    },
    {
        label: 'IT Infrastucture',
        key: 'ITInfrastucture',
        children: [
            {
                label: 'Infrastructure Documentation',
                key: 'InfrastructureDocumentation'
            },
            {
                label: 'Infrastructure Reviews',
                key: 'InfrastructureReviews'
            },
            {
                label: 'Infrastructure Version Tracking',
                key: 'InfrastructureVersionTracking'
            },
            {
                label: 'Infrastructure Updates',
                key: 'Infrastructure Updates'
            },
        ],
    },
    {
        label: 'Infrastructure Updates',
        key: 'InfrastructureUpdates',
        children: [
            {
                label: 'Cloud Infrastructure',
                key: 'CloudInfrastructure'
            },
        ],
    },
    {
        label: 'Tools',
        key: 'Tools',
        children: [
            {
                label: 'Tool Documentation',
                key: 'ToolDocumentation'
            },
            {
                label: 'Tool Reviews',
                key: 'ToolReviews'
            },
            {
                label: 'Tool Version Tracking',
                key: 'ToolVersionTracking'
            },
        ],
    },
    {
        label: 'Tool Version Tracking',
        key: 'ToolVersionTracking',
        children: [
            {
                label: 'Tool Updates',
                key: 'ToolUpdates'
            },
        ],
    },
    {
        label: 'Data Lineage',
        key: 'DataLineage',
        children: [
            {
                label: 'Data Sources',
                key: 'DataSources'
            },
            {
                label: 'Data Transformations',
                key: 'DataTransformations'
            },
            {
                label: 'Data Destinations',
                key: 'DataDestinations'
            },
        ],
    },
    {
        label: 'Data Destinations',
        key: 'DataDestinations',
        children: [
            {
                label: 'Data Quality',
                key: 'DataQuality'
            },
            {
                label: 'Data Quality Rules',
                key: 'DataQualityRules '
            },
            {
                label: 'Data Quality Assessments',
                key: 'DataQualityAssessments '
            },
        ],
    },
    {
        label: 'Data Quality Assessments',
        key: 'DataQualityAssessments',
        children: [
            {
                label: 'Data Quality Reports',
                key: 'DataQualityReports'
            },
        ],
    },
    {
        label: 'Governance Frameworks',
        key: 'Governance Frameworks',
        children: [
            {
                label: 'Framework A',
                key: 'FrameworkA'
            },
        ],
    },
    // 1107
    {
        label: 'Frameworks A',
        key: 'FrameworksA1',
        children: [
            {
                label: 'Control Areas',
                key: 'ControlAreas'
            },
        ],
    },
    {
        label: 'Control Areas',
        key: 'ControlAreas1',
        children: [
            {
                label: 'Control X',
                key: 'ControlX'
            },
        ],
    },
    {
        label: 'Control X',
        key: 'ControlX1',
        children: [
            {
                label: 'Subcontrols',
                key: 'Subcontrols'
            },
        ],
    },
    {
        label: 'Subcontrols',
        key: 'Subcontrols1',
        children: [
            {
                label: 'Subcontrol P',
                key: 'SubcontrolP'
            },
        ],
    },
    {
        label: 'Subcontrol P',
        key: 'SubcontrolP1',
        children: [
            {
                label: 'Evidence Type',
                key: 'EvidenceType'
            },
            {
                label: 'Evidence Details',
                key: 'EvidenceDetails'
            },
            {
                label: 'Evidence Source',
                key: 'EvidenceSource'
            },
            {
                label: 'Evidence Date',
                key: 'EvidenceDate'
            },
            {
                label: 'Evidence File',
                key: 'EvidenceFile'
            },
        ],
    },
    {
        label: 'Evidences',
        key: 'Evidences',
        children: [
            {
                label: 'Item Number',
                key: 'Item Number'
            },
            {
                label: 'Item Description',
                key: 'Item Description'
            },
            {
                label: 'Item Location',
                key: 'Item Location'
            },
            {
                label: 'Item Custodian',
                key: 'Item Custodian'
            },
            {
                label: 'Item Storage',
                key: 'Item Storage'
            },
        ],
    },
    {
        label: 'Control Y',
        key: 'ControlY',
        children: [
            {
                label: 'Control ID',
                key: 'ControlID1'
            },
            {
                label: 'Element ID',
                key: 'ElementID1'
            },
            {
                label: 'Control Name',
                key: 'ControlName1'
            },
            {
                label: 'Description',
                key: 'Description1'
            },
            {
                label: 'Owner Name',
                key: 'OwnerName1'
            },
            {
                label: 'Control Type',
                key: 'ControlType1'
            },
            {
                label: 'Control Status',
                key: 'Control Status'
            },
            {
                label: 'Control Documentation',
                key: 'Control Documentation'
            },
            {
                label: 'Control Testing Results',
                key: 'Control Testing Results'
            },
            {
                label: 'Control Compliance',
                key: 'Control Compliance'
            },
            {
                label: 'Control Effectiveness',
                key: 'Control Effectiveness'
            },
        ],
    },
    {
        label: 'Compliance Scores',
        key: 'ComplianceScores',
        children: [
            {
                label: 'Framework Information',
                key: 'FrameworkInformation'
            },
            {
                label: 'Compliance Information',
                key: 'ComplianceInformation'
            },
            {
                label: 'Supporting Documents',
                key: 'SupportingDocuments'
            },
        ],
    },
    {
        label: 'Control X Scores',
        key: 'ControlXScores',
        children: [
            {
                label: 'Subcontrol Information',
                key: 'SubcontrolInformation'
            },
            {
                label: 'Compliance Information',
                key: 'ComplianceInformation'
            },
            {
                label: 'Supporting Documents',
                key: 'SupportingDocuments'
            },
        ],
    },
]

export const dataPrivacyActsData = [
    {
        id: 1,
        title: "Singapore Personal Data Protection Act",
        link: "/singapore",
    },
    {
        id: 2,
        title: "Europe Data Privacy Act",
        link: "/europe",
    },
    {
        id: 3,
        title: "UK Data Privacy Act",
        link: "/uk",
    },
    {
        id: 4,
        title: "Canada Data Privacy Act",
        link: "/canada",
    },
    {
        id: 5,
        title: "USA Data Privacy Act",
        link: "/usa",
    },
    {
        id: 6,
        title: "EU GDPR Terms",
        link: "/gdpr",
    },
]

export const aiRegulationActsData = [
    {
        id: 1,
        title: "EU AI Act",
        link: "/eu-ai-act",
    },
    {
        id: 2,
        title: "Singapore's AI Governance Framework Model",
        link: "/singapore-ai-act",
    },
    {
        id: 3,
        title: "Trusted AI",
        link: "/trusted-ai-act",
    },
    {
        id: 4,
        title: "NIST AI Risk Management Framework Playbook",
        link: "/nist-ai-risk",
    },
]

export const aiRegulationsData = [
    {
        id: 1,
        title: "Companion of AI Governance Framework",
        link: "/companion-ai-gov-framework",
    },
    {
        id: 2,
        title: "Microsoft Responsible AI Impact Assessment Guide",
        link: "/microsoft-res-ai-guide",
    },
    {
        id: 3,
        title: "Microsoft Responsible AI Impact Assessment Template 2",
        link: "/microsoft-res-ai-template",
    },
    {
        id: 4,
        title: "Microsoft Responsible AI Standard",
        link: "/microsoft-res-ai-standard",
    },
    {
        id: 5,
        title: "MODEL ARTIFICIAL INTELLIGENCE GOVERNANCE FRAMEWORK SECOND EDITION",
        link: "/model-ai-gov-framework",
    },
    {
        id: 6,
        title: "Trustworthy AI (TAI) Playbook",
        link: "/tai-playbook",
    },
]

export const AIGovernanceFrameworkData = [
    {
        id: 1,
        title: "Singapore AI Govenrance Framework",
        link: "/singapore-ai-gov-framework",
    },
    {
        id: 2,
        title: "USA AI Govenrance Framework",
        link: "/usa-ai-gov-framework",
    },
    {
        id: 3,
        title: "Europe AI Regulations Act",
        link: "/europe-ai-regu-act",
    },
]

export const AI_REGULATIONS = [
    {
        id: 1,
        title: "Singapore AI Regulations",
        link: "/singapore-ai-regulations",
    },
    {
        id: 2,
        title: "Singapore PDPC Act",
        link: "/singapore-pdpc-act",
    },
]

export const CANADA_DATA_PRIVACY_ACT = [
    {
        dataPrivacyAct: "PIPEDA",
        clauseName: "Consent",
        link: "https://www.priv.gc.ca/en/privacy-topics/collecting-personal-information/consent/gl_omc_201805/",
        description: "Requires organizations to obtain consent for the collection, use, and disclosure of personal information.",
        expectedEvidence: "Records of consent, privacy policies, and processes for obtaining and managing consent.",
        implications: "Non-compliance may result in investigations, orders, and reputational damage.",
        recommendations: "Develop and implement clear and easily understandable privacy policies, establish mechanisms for obtaining and managing consent, and regularly review and update policies to ensure compliance with PIPEDA."
    },
    {
        dataPrivacyAct: "PIPEDA",
        clauseName: "Accountability",
        link: "https://www.dataguidance.com/notes/canada-data-protection-overview#:~:text=PIPEDA%20and%20provincial%20laws%20hold,organization's%20compliance%20with%20the%20law.",
        description: "Mandates organizations to be accountable for the protection of personal information under their control.",
        expectedEvidence: "Documentation of privacy policies, procedures, and mechanisms for ensuring accountability.",
        implications: "Failure to demonstrate accountability may lead to regulatory action and penalties.",
        recommendations: "Establish and document comprehensive privacy policies, implement robust privacy management practices, and conduct regular assessments to ensure accountability and compliance with PIPEDA."
    },
    {
        dataPrivacyAct: "PIPEDA",
        clauseName: "Individual Access",
        link: "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/p_principle/principles/p_access/#:~:text=Generally%20speaking%2C%20individuals%20have%20a,that%20information%20amended%20as%20appropriate.",
        description: "Grants individuals the right to access their personal information held by organizations and challenge its accuracy.",
        expectedEvidence: "Records of access requests, responses, and mechanisms for handling access requests.",
        implications: "Non-compliance may result in investigations and orders to provide access.",
        recommendations: "Develop and implement procedures for handling access requests, train staff on responding to such requests, and establish mechanisms to ensure timely and accurate responses in accordance with PIPEDA."
    },
    {
        dataPrivacyAct: "PIPEDA",
        clauseName: "Security Safeguards",
        link: "https://segev.ca/canada-pipeda-principle-7-safeguarding-personal-information/",
        description: "Requires organizations to implement safeguards to protect personal information against loss, theft, and unauthorized access.",
        expectedEvidence: "Security policies, risk assessments, and evidence of compliance with security measures.",
        implications: "Failure to implement adequate security safeguards may result in data breaches and penalties.",
        recommendations: "Establish and document comprehensive security policies, conduct regular risk assessments, and implement robust security measures to protect personal information in accordance with PIPEDA requirements."
    },
    {
        dataPrivacyAct: "PIPEDA",
        clauseName: "Breach Notification and Reporting",
        link: "https://www.priv.gc.ca/en/privacy-topics/business-privacy/safeguards-and-breaches/privacy-breaches/respond-to-a-privacy-breach-at-your-business/gd_pb_201810/#:~:text=Organizations%20subject%20to%20the%20Personal,of%20significant%20harm%20to%20individuals",
        description: "Mandates organizations to report data breaches to the Privacy Commissioner and affected individuals when it poses a risk of harm.",
        expectedEvidence: "Records of data breaches, evidence of notifications, and documentation of actions taken.",
        implications: "Failure to report breaches promptly may result in penalties and reputational damage.",
        recommendations: "Develop and implement a data breach response plan, including procedures for timely notification, and regularly test and update the plan to address emerging threats in compliance with PIPEDA breach notification requirements."
    },
    {
        dataPrivacyAct: "PIPEDA",
        clauseName: "Consent Withdrawal and Deletion",
        link: "https://laws-lois.justice.gc.ca/eng/acts/p-8.6/page-7.html",
        description: "Requires organizations to allow individuals to withdraw consent and have their personal information deleted, subject to legal and contractual restrictions.",
        expectedEvidence: "Mechanisms for individuals to withdraw consent, records of consent withdrawals, and evidence of deletion processes",
        implications: "Failure to comply with withdrawal and deletion requests may lead to regulatory action.",
        recommendations: "Implement procedures for individuals to withdraw consent and request deletion of their information, ensure compliance with legal and contractual obligations, and maintain records of such processes in accordance with PIPEDA."
    }
];

export const EUROPE_DATA_PRIVACY_ACT = [
    {
        key: '1',
        dataPrivacyAct: 'GDPR',
        clauseName: 'Lawful Processing',
        link: 'https://gdpr-info.eu/art-6-gdpr/#:~:text=processing%20is%20necessary%20for%20the%20purposes%20of%20the%20legitimate%20interests,the%20data%20subject%20is%20a',
        description: 'Outlines principles for the lawful processing of personal data, emphasizing transparency, fairness, and the rights of individuals.',
        expectedEvidence: 'Records of processing activities, data protection impact assessments, and compliance with GDPR principles',
        implications: 'Non-compliance can result in substantial fines.',
        recommendations: 'Implement and document data protection policies, conduct regular impact assessments, appoint a Data Protection Officer (if required), and ensure staff training on data protection principles.'
    },
    {
        key: '2',
        dataPrivacyAct: 'GDPR',
        clauseName: 'Right to Erasure (Right to be Forgotten)',
        link: 'http://www.dataprotection.ie/en/individuals/know-your-rights/right-erasure-articles-17-19-gdpr#:~:text=This%20is%20also%20known%20as,it%20was%20collected%20or%20processed.',
        description: 'Provides individuals with the right to request the deletion or removal of personal data when there\'s no compelling reason for its continued processing.',
        expectedEvidence: 'Records of consent, processes for responding to data erasure requests, and evidence of compliance with GDPR principles.',
        implications: 'Non-compliance can result in substantial fines.',
        recommendations: 'Establish mechanisms for handling data erasure requests, update privacy policies, and implement robust security measures to comply with GDPR.'
    },
    {
        key: '3',
        dataPrivacyAct: 'GDPR',
        clauseName: 'Data Protection Impact Assessments',
        link: 'https://gdpr.eu/data-protection-impact-assessment-template/#:~:text=A%20Data%20Protection%20Impact%20Assessment%20(DPIA)%20is%20required%20under%20the,help%20you%20execute%20the%20assessment.',
        description: 'Requires organizations to assess the impact of data processing activities on individuals\' privacy and take measures to mitigate risks.',
        expectedEvidence: 'Documented data protection impact assessments for high-risk processing activities.',
        implications: 'Failure to conduct impact assessments may result in non-compliance.',
        recommendations: 'Conduct thorough impact assessments for high-risk processing activities, involve relevant stakeholders, and implement necessary safeguards to protect individuals\' privacy.'
    },
    {
        key: '4',
        dataPrivacyAct: 'GDPR',
        clauseName: 'Data Breach Notification',
        link: 'https://www.cynet.com/cynet-for-compliance/gdpr-data-breach-notifications-everything-you-need-to-know/',
        description: 'Mandates the notification of data breaches to the relevant supervisory authority and, in certain cases, to affected individuals.',
        expectedEvidence: 'Records of data breaches, evidence of notifications, and documentation of actions taken to address breaches.',
        implications: 'Failure to report breaches promptly may lead to fines and reputational damage.',
        recommendations: 'Develop and implement a data breach response plan, including procedures for timely notification, and regularly test and update the plan to address emerging threats.'
    },
    {
        key: '5',
        dataPrivacyAct: 'ePrivacy Regulation',
        clauseName: 'Data Protection Officer (DPO)',
        link: 'https://www.edps.europa.eu/data-protection/data-protection/reference-library/data-protection-officer-dpo_en',
        description: 'Requires the appointment of a Data Protection Officer for certain organizations, responsible for monitoring compliance and advising on data protection.',
        expectedEvidence: 'Evidence of the DPO\'s appointment, qualifications, and involvement in data protection matters.',
        implications: 'Failure to appoint a DPO when required may result in non-compliance.',
        recommendations: 'Appoint a qualified Data Protection Officer if required, ensure their independence and expertise, and provide adequate resources and support for them to carry out their responsibilities effectively.'
    },
    {
        key: '6',
        dataPrivacyAct: 'PSD2',
        clauseName: 'Consent for Electronic Communications',
        link: 'https://www.european-eprivacy-regulation.com/#:~:text=This%20means%20that%20any%20interference,communicating%20parties%20should%20be%20prohibited.',
        description: 'Focuses on the privacy of electronic communications and the need for user consent for processing electronic communications data.',
        expectedEvidence: 'Records of user consent for electronic communications, privacy policies, and compliance with ePrivacy Regulation.',
        implications: 'Non-compliance may result in fines and restrictions on processing electronic communications data.',
        recommendations: 'Ensure clear and explicit user consent for electronic communications, update privacy policies, and implement measures to comply with the ePrivacy Regulation.'
    },
    {
        key: '7',
        dataPrivacyAct: 'CCPA',
        clauseName: 'Strong Customer Authentication (SCA)',
        link: 'https://stripe.com/guides/strong-customer-authentication#:~:text=products%20from%20Stripe.-,What%20is%20Strong%20Customer%20Authentication%3F,contactless%20offline%20payments%20more%20secure.',
        description: 'Regulates payment services and mandates strong customer authentication for electronic payments to enhance security.',
        expectedEvidence: 'Documentation of strong customer authentication processes, compliance measures, and records of electronic payment transactions.',
        implications: 'Non-compliance may result in fines and restrictions on providing payment services.',
        recommendations: 'Implement and document strong customer authentication processes, conduct regular compliance checks, and ensure secure electronic payment transactions to comply with PSD2.'
    },
    {
        key: '8',
        dataPrivacyAct: 'Privacy Shield (No Longer Valid)',
        clauseName: 'California Consumer Privacy Act (Applies to European Businesses with California Customers)',
        link: 'https://www.cookieyes.com/blog/ccpa-vs-gdpr/#:~:text=The%20CCPA%20is%20actually%20much,where%20that%20data%20is%20processed',
        description: 'Grants California residents the right to know, delete, and opt-out of the sale of their personal information.',
        expectedEvidence: 'Records of compliance with CCPA, responses to consumer requests, and updated privacy policies.',
        implications: 'Non-compliance may result in fines and legal actions.',
        recommendations: 'Extend data protection policies, establish mechanisms for handling CCPA requests, and update privacy practices to comply with CCPA if dealing with California customers.'
    },
    {
        key: '9',
        dataPrivacyAct: 'EU-U.S. Privacy Shield',
        clauseName: '',
        link: 'https://www.commerce.gov/tags/eu-us-privacy-shield#:~:text=The%20EU%2DU.S.%20Privacy%20Shield,United%20States%20in%20support%20of',
        description: 'Established a framework for the transfer of personal data between the EU and the U.S., ensuring adequate protection.',
        expectedEvidence: 'Records of Privacy Shield certification, compliance measures, and adherence to Privacy Shield principles.',
        implications: 'No longer a valid mechanism for EU-U.S. data transfers after the Schrems II decision.',
        recommendations: 'If previously certified under Privacy Shield, consider alternative mechanisms for EU-U.S. data transfers, such as Standard Contractual Clauses or Binding Corporate Rules.'
    },
];

export const SINGAPORE_DATA_PRIVACY_ACT = [
    {
        key: '1',
        dataPrivacyAct: 'Personal Data Protection Act (PDPA)',
        clauseName: 'Consent Obligation',
        link: 'https://www.pdpc.gov.sg/overview-of-pdpa/the-legislation/personal-data-protection-act/data-protection-obligations#:~:text=Consent%20Obligation,the%20likely%20consequences%20of%20withdrawal.',
        description: 'Individuals must give their consent for the collection, use, or disclosure of their personal data unless an exception applies.',
        expectedEvidence: 'Consent records, opt-in mechanisms, and documentation of consent processes.',
        implications: 'Non-compliance may lead to financial penalties, reputation damage, and loss of customer trust.',
        recommendations: 'Implement clear and transparent consent processes. Provide opt-in mechanisms and maintain records of consent for audit purposes.'
    },
    {
        key: '2',
        dataPrivacyAct: 'Personal Data Protection Act (PDPA)',
        clauseName: 'Purpose Limitation',
        link: 'https://www.dataguidance.com/notes/singapore-data-protection-overview',
        description: 'Organizations can only collect, use, or disclose personal data for the purposes they have informed the individual or as permitted by law.',
        expectedEvidence: 'Policies and procedures outlining the purposes for data collection, use, and disclosure.',
        implications: 'Unauthorized use of personal data may result in penalties and reputational harm.',
        recommendations: 'Clearly communicate the purposes of data collection, establish robust policies, and conduct regular audits to ensure adherence to stated purposes.'
    },
    {
        key: '3',
        dataPrivacyAct: 'Personal Data Protection Act (PDPA)',
        clauseName: 'Notification Obligation',
        link: 'https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Advisory-Guidelines/the-notification-obligation---ch-14-(270717).pdf',
        description: 'Organizations must inform individuals of the purposes for collecting, using, or disclosing their personal data, and obtain consent if required.',
        expectedEvidence: 'Notification records, communication materials, and evidence of informed consent.',
        implications: 'Failure to notify individuals may lead to penalties and erode trust.',
        recommendations: 'Develop and implement effective notification procedures. Ensure individuals are informed about the processing of their personal data and obtain explicit consent when necessary.'
    },
    {
        key: '4',
        dataPrivacyAct: 'Personal Data Protection Act (PDPA)',
        clauseName: 'Access and Correction',
        link: 'https://www.ipc.on.ca/access-individuals/access-and-correction/#:~:text=For%20information%20held%20by%20child,in%20your%20file%20is%20free.',
        description: 'Individuals have the right to access and correct their personal data held by organizations.',
        expectedEvidence: 'Access and correction request logs, procedures for handling requests, and response records',
        implications: 'Failure to provide access or correct inaccuracies may result in penalties and loss of trust.',
        recommendations: 'Establish processes to handle access and correction requests promptly. Train staff to ensure compliance with individuals\' rights.'
    },
    {
        key: '5',
        dataPrivacyAct: 'Personal Data Protection Act (PDPA)',
        clauseName: 'Protection Obligation',
        link: 'https://www.assuresafety.com.sg/what-are-the-11-obligations-of-pdpa/',
        description: 'Organizations are required to protect personal data in their possession or control by making reasonable security arrangements to prevent unauthorized access, collection, use, disclosure, copying, modification, disposal, or similar risks.',
        expectedEvidence: 'Data protection policies, security measures documentation, and records of security incidents.',
        implications: 'Data breaches and inadequate security may lead to financial penalties and damage to reputation.',
        recommendations: 'Implement robust data protection measures, conduct regular security assessments, and train staff on data security best practices.'
    },
    {
        key: '6',
        dataPrivacyAct: 'Personal Data Protection Act (PDPA)',
        clauseName: 'Retention Limitation',
        link: 'https://www.ismartcom.com/blog/data-retention-and-disposal-pdpa-compliance-practices-in-singapore#:~:text=Data%20Retention%20in%20PDPA%20Singapore,of%20in%20a%20secure%20manner.',
        description: 'Personal data should not be kept longer than necessary for the purpose for which it was collected.',
        expectedEvidence: 'Data retention policies and procedures, documentation outlining retention periods, and records of data disposal.',
        implications: 'Unnecessary retention of personal data may result in penalties and non-compliance.',
        recommendations: 'Develop and adhere to clear data retention policies. Regularly review and dispose of personal data that is no longer necessary for the intended purposes.'
    },
    {
        key: '7',
        dataPrivacyAct: 'Personal Data Protection Act (PDPA)',
        clauseName: 'Transfer Limitation',
        link: 'https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Advisory-Guidelines/the-transfer-limitation-obligation---ch-19-(270717).pdf',
        description: 'Organizations are required to ensure that personal data transferred outside Singapore is adequately protected',
        expectedEvidence: 'Data transfer agreements, documentation of security measures, and compliance with transfer requirements.',
        implications: 'Inadequate protection during international transfers may lead to regulatory action and reputational harm.',
        recommendations: 'Ensure compliance with data transfer restrictions, implement safeguards for international data transfers, and use legally recognized mechanisms for cross-border data transfers.'
    },
    {
        key: '8',
        dataPrivacyAct: 'Personal Data Protection Act (PDPA)',
        clauseName: 'Openness Obligation',
        link: 'https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Advisory-Guidelines/the-openness-obligation---ch-20-(270717).pdf',
        description: 'Organizations must be open about their data protection policies and practices and make information about them readily available.',
        expectedEvidence: 'Publicly available privacy policies, communication materials, and documentation of outreach efforts.',
        implications: 'Lack of transparency may result in penalties and erode trust.',
        recommendations: 'Develop and maintain clear and accessible privacy policies. Communicate data protection practices openly to individuals and the public.'
    },
];

export const UK_DATA_PRIVACY_ACT = [
    {
        key: '1',
        dataPrivacyAct: 'Data Protection Act 2018 (DPA)',
        clauseName: 'Data Protection Principles',
        link: 'https://www.parliament.uk/site-information/data-protection/commons-data-protection-information/data-protection-policy/#:~:text=Data%20Protection%20Principles&text=These%20principles%20state%20that%20personal,adequate%2C%20relevant%20and%20not%20excessive',
        description: 'Enforces the General Data Protection Regulation (GDPR) in the UK, outlining principles for lawful processing of personal data.',
        expectedEvidence: 'Records of processing activities, data protection impact assessments, and compliance with principles.',
        implications: 'Non-compliance can lead to fines, legal action, and reputational damage.',
        recommendations: 'Implement and document data protection policies, conduct regular impact assessments, appoint a Data Protection Officer (if required), and ensure staff training on data protection principles.'
    },
    {
        key: '2',
        dataPrivacyAct: 'GDPR',
        clauseName: 'Right to Erasure',
        link: 'https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/individual-rights/individual-rights/right-to-erasure/#:~:text=The%20right%20is%20not%20absolute,whether%20to%20delete%20personal%20data.',
        description: "Provides individuals with the right to request the deletion or removal of personal data when there's no compelling reason for its continued processing.",
        expectedEvidence: 'Records of consent, processes for responding to data erasure requests, and evidence of compliance with GDPR principles.',
        implications: 'Non-compliance can result in substantial fines.',
        recommendations: 'Establish mechanisms for handling data erasure requests, update privacy policies, and implement robust security measures to comply with GDPR.'
    },
    {
        key: '3',
        dataPrivacyAct: 'Privacy and Electronic Communications Regulations (PECR)',
        clauseName: 'Consent Requirements',
        link: 'https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/lawful-basis/a-guide-to-lawful-basis/lawful-basis-for-processing/consent/',
        description: 'Focuses on privacy rights related to electronic communications, including email marketing and the use of cookies.',
        expectedEvidence: 'Records of consent for electronic communications and evidence of compliance with cookie regulations.',
        implications: 'Non-compliance may lead to fines and legal consequences.',
        recommendations: 'Obtain clear and informed consent for electronic communications, provide mechanisms for users to control cookie preferences, and regularly review and update practices to ensure compliance with PECR.'
    },
    {
        key: '4',
        dataPrivacyAct: 'Freedom of Information Act (FOIA)',
        clauseName: 'Access to Information',
        link: 'https://www.gov.uk/make-a-freedom-of-information-request#:~:text=You%20have%20the%20right%20to,the%20right%20to%20see%20information.',
        description: 'Allows individuals to request information held by public authorities, promoting transparency and openness.',
        expectedEvidence: 'Documentation of responses to information requests and compliance with FOIA requirements.',
        implications: 'Failure to comply may result in legal action and reputational damage.',
        recommendations: 'Establish clear procedures for handling information requests, train staff on FOIA requirements, and maintain records of responses to ensure compliance with transparency obligations.'
    },
    {
        key: '5',
        dataPrivacyAct: 'The Investigatory Powers Act 2016',
        clauseName: 'Surveillance and Data Collection',
        link: 'https://www.comparitech.com/blog/vpn-privacy/uk-government-mass-surveillance/#:~:text=The%20UK%20government%20operates%20one,Security%20Service%20(MI5)',
        description: 'Governs the powers of public bodies to conduct surveillance and collect data for national security purposes.',
        expectedEvidence: 'Records of authorized surveillance activities, compliance with safeguards, and legal justifications for data collection',
        implications: 'Non-compliance can lead to legal consequences and challenges to surveillance practices.',
        recommendations: 'Adhere to legal requirements for surveillance, implement safeguards, and ensure transparency in the collection and use of data for national security purposes.'
    },
    {
        key: '6',
        dataPrivacyAct: 'NIS Regulations (Network and Information Systems Regulations 2018)',
        clauseName: 'Security Measures',
        link: 'https://www.gov.uk/government/publications/electronic-communications-security-measures-regulations-and-draft-telecommunications-security-code-of-practice',
        description: 'Applies to operators of essential services and digital service providers, requiring measures to ensure the security of network and information systems.',
        expectedEvidence: 'Security policies, risk assessments, and evidence of compliance with NIS security requirements.',
        implications: 'Non-compliance may result in fines and enforcement actions.',
        recommendations: 'Develop and implement robust security measures, conduct regular risk assessments, and adhere to NIS security requirements to protect essential services and digital systems from cyber threats.'
    }
];

export const USA_DATA_PRIVACY_ACT = [
    {
        key: '1',
        dataPrivacyAct: 'HIPAA',
        clauseName: 'Privacy Rule',
        link: 'https://www.hhs.gov/hipaa/for-professionals/privacy/index.html#:~:text=The%20HIPAA%20Privacy%20Rule%20establishes,care%20providers%20that%20conduct%20certain',
        description: 'Protects the privacy and security of certain health information.',
        expectedEvidence: 'Compliance with safeguarding and controlling access to protected health information (PHI).',
        implications: 'Non-compliance may result in civil and criminal penalties.',
        recommendations: 'Implement and maintain policies and procedures for safeguarding PHI, conduct regular audits, and ensure staff training on HIPAA regulations.',
        states: 'Nationwide'
    },
    {
        key: '2',
        dataPrivacyAct: 'Gramm-Leach-Bliley Act',
        clauseName: 'Financial Privacy Rule',
        link: 'https://www.ftc.gov/news-events/topics/protecting-consumer-privacy-security/financial-privacy',
        description: 'Requires financial institutions to explain their information-sharing practices to customers and to safeguard sensitive data.',
        expectedEvidence: 'Documentation of privacy policies, risk assessments, and compliance measures.',
        implications: 'Non-compliance can lead to fines and reputational damage.',
        recommendations: 'Develop and implement comprehensive privacy policies, conduct regular risk assessments, and establish a privacy program to comply with GLBA.',
        states: 'Nationwide'
    },
    {
        key: '3',
        dataPrivacyAct: 'COPPA',
        clauseName: "Children's Online Privacy Rule",
        link: 'https://www.ftc.gov/legal-library/browse/rules/childrens-online-privacy-protection-rule-coppa',
        description: 'Regulates the online collection of personal information from children under 13.',
        expectedEvidence: 'Verifiable parental consent for data collection from children, privacy policy disclosures.',
        implications: 'Non-compliance may result in significant fines and legal action.',
        recommendations: 'Clearly disclose data practices in privacy policies, obtain parental consent, and implement robust mechanisms for parental control over children\'s data.',
        states: 'Nationwide'
    },
    {
        key: '4',
        dataPrivacyAct: 'CCPA',
        clauseName: 'Right to know',
        link: 'https://oag.ca.gov/privacy/ccpa#:~:text=Right%20to%20know%3A%20You%20can,the%20categories%20of%20third%20parties',
        description: 'Grants California residents the right to know what personal information is collected and how it\'s used.',
        expectedEvidence: 'Records of personal information collected, responses to consumer requests for information.',
        implications: 'Non-compliance may result in fines and private lawsuits.',
        recommendations: 'Establish processes for handling consumer data requests, update privacy policies, and ensure data security measures to comply with CCPA.',
        states: 'California'
    },
    {
        key: '5',
        dataPrivacyAct: 'GDPR',
        clauseName: 'Right to Erasure',
        link: 'https://www.reuters.com/legal/legalindustry/us-data-privacy-laws-enter-new-era-2023-2023-01-12/#:~:text=Erasure%20%E2%80%94%20individuals%20have%20the%20right,their%20personal%20information%20be%20deleted.&text=Consent%20%E2%80%94%20individuals%20have%20the%20right,purposes%20of%20receiving%20targeted%20advertising.',
        description: 'Gives EU residents control over their personal data and requires businesses to protect and secure this data.',
        expectedEvidence: 'Records of consent, data processing activities, and measures for data protection.',
        implications: 'Non-compliance can lead to substantial fines.',
        recommendations: 'Implement data protection policies, appoint a Data Protection Officer (if applicable), and conduct regular assessments of data processing activities to ensure GDPR compliance.',
        states: 'Nationwide'
    },
    {
        key: '6',
        dataPrivacyAct: 'FISMA',
        clauseName: 'Security Standards',
        link: 'https://security.cms.gov/learn/federal-information-security-management-act-fisma#:~:text=The%20Federal%20Information%20Security%20Modernization,agency%2Dwide%20information%20security%20programs.',
        description: 'Establishes security standards for federal information systems and data.',
        expectedEvidence: 'Security plans, risk assessments, and evidence of compliance with security controls.',
        implications: 'Non-compliance may result in funding reductions and loss of public trust.',
        recommendations: 'Develop and maintain security plans, conduct regular risk assessments, and adhere to security controls to meet FISMA requirements.',
        states: 'Nationwide'
    }
];

export const GDPR_CLAUSE_TERMS = [
    {
        key: 1,
        clauseTerm: 'Article 7, Recital 32',
        descriptionContext: 'Consent must be freely given, specific, informed and unambiguous. In order to obtain freely given consent, it must be given on a voluntary basis. Opt-in mechanism, data collection',
        implications: 'Consent must be clear, specific, and explicit.',
        recommendations: 'Ensure clear, specific, and understandable consent language. Provide opt-in mechanisms. Implement opt-in mechanisms for data collection'
    },
    {
        key: 2,
        clauseTerm: 'Article 7(3)',
        descriptionContext: 'The data subject shall have the right to withdraw his or her consent at any time. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal. Prior to giving consent, the data subject shall be informed thereof. It shall be as easy to withdraw as to give consent.',
        implications: 'Individuals can withdraw consent at any time.',
        recommendations: 'Enable easy consent withdrawal and prompt data removal upon request.'
    },
    {
        key: 3,
        clauseTerm: 'Article 5(1)(b)',
        descriptionContext: 'Personal data should only be collected and processed for a legitimate specific purpose. Data must be used for legitimate purposes.',
        implications: 'Data must be used for legitimate purposes.',
        recommendations: 'Collect data only for specific, legitimate purposes and obtain proper consent'
    },
    {
        key: 4,
        clauseTerm: 'Article 13-14',
        descriptionContext: 'The controller shall take appropriate measures to provide any information referred to in Articles 13 and 14 and any communication under Articles 15 to 22 and 34 relating to processing to the data subject in a concise, transparent, intelligible and easily accessible form, using clear and plain language, in particular for any information addressed specifically to a child. Individuals must be informed about data processing.',
        implications: 'Individuals must be informed about data processing.',
        recommendations: 'Communicate privacy information in plain language, informing individuals about rights and risks.'
    },
    {
        key: 5,
        clauseTerm: 'Article 32',
        descriptionContext: 'Encryption is the best way to protect data during transfer and one way to secure stored personal data. It also reduces the risk of abuse within a company, as access is limited only to authorised people with the right key. Data must be protected from unauthorized access.',
        implications: 'Data must be protected from unauthorized access.',
        recommendations: 'Implement security measures, including data encryption and access controls.'
    },
    {
        key: 6,
        clauseTerm: 'Recital 71, AI ACT',
        descriptionContext: 'Bias detection and mitigation in AI. AI must be free from biases.',
        implications: 'AI must be free from biases.',
        recommendations: 'Implement measures to detect and mitigate biases in AI algorithms.'
    },
    {
        key: 7,
        clauseTerm: 'Recital 71, AI ACT',
        descriptionContext: 'Explainability and transparency in AI. AI decision must be transparent.',
        implications: 'AI decision must be Transperent',
        recommendations: 'Provide explanations for AI-driven decisions to enhance transparency.'
    },
    {
        key: 8,
        clauseTerm: 'Recital 71, AI ACT',
        descriptionContext: 'Regular testing and validation of AI models. AI models must be accurate and fair.',
        implications: 'AI models must be accurate and fair',
        recommendations: 'Continuously test and validate AI models for accuracy and fairness.'
    },
    {
        key: 9,
        clauseTerm: 'Article 32',
        descriptionContext: 'Ensure privacy and security with data being at rest and in transit. Ensure the recovery of files or the keys used to encrypt the data in the event of a security breach. Ensure the encrypted data do not affect the business functionality, accessibility, or performance.',
        implications: 'Data must be protected from unauthorized access.',
        recommendations: 'Encrypt data in transit and at rest.'
    },
    {
        key: 10,
        clauseTerm: 'Article 32',
        descriptionContext: 'Encryption key management and access controls. Access to sensitive data must be controlled.',
        implications: 'Access to sensitive data must be controlled.',
        recommendations: 'Implement proper encryption key management and access controls.'
    },
    {
        key: 11,
        clauseTerm: 'Article 32',
        descriptionContext: 'Secure communication channels and message encryption. Data Transmission must be secure.',
        implications: 'Data Transmission must be secure',
        recommendations: 'Use secure communication channels and message encryption for data transmission.'
    },
    {
        key: 12,
        clauseTerm: 'Recital 30',
        descriptionContext: 'Clear and transparent cookie policies. Users must be informed about cookie usage.',
        implications: 'Users must be informed about cookie usage',
        recommendations: 'Provide clear and transparent cookie policies.'
    },
    {
        key: 13,
        clauseTerm: 'Recital 32',
        descriptionContext: 'Mechanisms for obtaining and managing cookie consent. Users must have options to accept or decline cookies.',
        implications: 'Users must have options to accept or decline cookies',
        recommendations: 'Implement mechanisms for obtaining and managing user consent for cookies.'
    },
    {
        key: 14,
        clauseTerm: 'Recital 39',
        descriptionContext: 'Secure session handling and authentication. User data and sessions must be secure.',
        implications: 'User data and sessions must be secure.',
        recommendations: 'Ensure secure session handling and robust authentication mechanisms.'
    },
    {
        key: 15,
        clauseTerm: 'Recital 39',
        descriptionContext: 'Session timeout and logout mechanisms. User session must be protected.',
        implications: 'User session must be protected.',
        recommendations: 'Implement session timeout and proper logout mechanisms.',
    },
    {
        key: 16,
        clauseTerm: 'Recital 39',
        descriptionContext: 'Encryption of data during transfer. Data must remain confidential during transfer.',
        implications: 'Data must remain confidential during transfer',
        recommendations: 'Encrypt data during transfer.',
    },
    {
        key: 17,
        clauseTerm: 'Article 5(1)(c)',
        descriptionContext: 'Personal data shall be adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed (data minimization). According to the GDPR, organizations should only retain the smallest amount of data needed for their requirements.',
        implications: 'Collect only necessary data.',
        recommendations: 'Minimize data collection to what is necessary for specified purposes.',
    },
    {
        key: 18,
        clauseTerm: 'Article 12-14',
        descriptionContext: 'The controller shall take appropriate measures to provide any information referred to in Articles 13 and 14 The information shall be provided in writing, or by other means, including, where appropriate, by electronic means. When requested by the data subject, the information may be provided orally, provided that the identity of the data subject is proven by other means.',
        implications: 'Individuals must be informed about data processing.',
        recommendations: 'Provide clear information about how data is processed, including rights and safeguards.',
    },
    {
        key: 19,
        clauseTerm: 'Article 5(2)',
        descriptionContext: 'Accountability through implementing measures for compliance. Demonstrating GDPR compliance is required.',
        implications: 'Demonstrating GDPR compliance is required.',
        recommendations: 'Implement measures to demonstrate compliance with GDPR requirements.',
    },
    {
        key: 20,
        clauseTerm: 'Article 6(1)(b)',
        descriptionContext: 'Data processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract. Data processing must comply with laws.',
        implications: 'Data processing must comply with laws.',
        recommendations: 'Ensure data is processed in accordance with applicable laws and regulations.',
    },
    {
        key: 21,
        clauseTerm: 'Article 5(1)(d)',
        descriptionContext: 'Personal data shall be accurate and, where necessary, kept up to date; every reasonable step must be taken to ensure that personal data that are inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay (‘accuracy’).',
        implications: 'Data must be accurate and up-to-date.',
        recommendations: 'Take steps to ensure data accuracy and allow individuals to correct inaccurate data.',
    },
    {
        key: 22,
        clauseTerm: 'Article 5(1)(€)',
        descriptionContext: 'Personal data shall be kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed; personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes in accordance with Article 89(1) subject to implementation of the appropriate technical and organisational measures required by this Regulation in order to safeguard the rights and freedoms of the data subject.',
        implications: 'Data must not be kept indefinitely.',
        recommendations: 'Determine appropriate data retention periods based on legal requirements and data usage.',
    },
    {
        key: 23,
        clauseTerm: 'Article 8',
        descriptionContext: "Organizations may show they respect people's privacy and autonomy by upholding these rights and ensuring that their personal data is handled.",
        implications: 'Individual privacy and autonomy must be respected.',
        recommendations: 'Respect individual privacy and autonomy, especially concerning personal data processing.',
    },
    {
        key: 24,
        clauseTerm: 'Article 32',
        descriptionContext: 'Article 32 of the General Data Protection Regulation (GDPR) requires Data Controllers and Data Processors to implement technical and organizational measures that ensure a level of data security appropriate for the level of risk presented by processing personal data.',
        implications: 'Data must be protected from breaches.',
        recommendations: 'Implement measures to protect personal data from breaches, including encryption and access controls.',
    },
    {
        key: 25,
        clauseTerm: 'Article 9',
        descriptionContext: 'Handling sensitive data properly. Sensitive data must be handled with care.',
        implications: 'Sensitive data must be handled with care.',
        recommendations: 'Handle sensitive data with care and adhere to specific regulations regarding its processing.',
    },
    {
        key: 26,
        clauseTerm: 'Article 32',
        descriptionContext: 'Implementing secure mechanisms for photo and video sharing. Secure mechanisms for sharing personal data in photos and videos.',
        implications: 'Secure mechanisms for sharing personal data in photos and videos',
        recommendations: 'Implement secure mechanisms for photo and video sharing containing personal data.',
    },
    {
        key: 27,
        clauseTerm: 'Article 22, AI ACT',
        descriptionContext: 'Ensuring data privacy in behavior analysis. Data privacy must be maintained in behavior analysis and profiling in AI.',
        implications: 'Data privacy must be maintained in behavior analysis.',
        recommendations: 'Ensure data privacy in behavior analysis and profiling in AI.',
    },
    {
        key: 28,
        clauseTerm: 'Recital 75, 83, 87, 88, AI Act',
        descriptionContext: 'Security measures, incident response, vulnerability monitoring. Data security and incident response are crucial.',
        implications: 'Data security and incident response are crucial.',
        recommendations: 'Implement various security measures, conduct regular security assessments, and address vulnerabilities in AI systems.',
    },
    {
        key: 29,
        clauseTerm: 'Recital 83',
        descriptionContext: 'Use of encryption for data transfer. Data transfer must be secure.',
        implications: 'Data transfer must be secure.',
        recommendations: 'Use encryption for data transfer to safeguard data during transit.',
    },
];

export const EU_AI_ACT_DATA = [
    {
        euAIActClauses: "Article 2(2)",
        terms: "Exemptions",
        description: "Specifies exemptions for certain AI systems, such as those used as safety components.",
        expectedEvidence: "Compliance documentation, safety assessments",
        implications: "Clarifies regulatory scope and exceptions."
    },
    {
        euAIActClauses: "Article 4a",
        terms: "General principles",
        description: "Establishes general principles for AI system development including transparency, privacy, fairness, etc.",
        expectedEvidence: "Development guidelines, compliance reports",
        implications: "Ensures ethical and legal standards in AI development."
    },
    {
        euAIActClauses: "Article 5(1)",
        terms: "High-risk AI categories",
        description: "Defines specific activities or AI systems categorized as high-risk due to potential harm.",
        expectedEvidence: "Legal documentation, activity reports",
        implications: "Identifies activities requiring stringent regulation and oversight."
    },
    {
        euAIActClauses: "Article 6",
        terms: "Classification rules for high-risk AI systems",
        description: "Identifies criteria for high-risk AI systems and imposes specific obligations on providers.",
        expectedEvidence: "Risk assessment reports, compliance documentation",
        implications: "Ensures rigorous oversight and management of high-risk AI."
    },
    {
        euAIActClauses: "Article 8",
        terms: "Compliance requirements",
        description: "Outlines requirements for compliance with AI Act obligations.",
        expectedEvidence: "Compliance documentation, audit reports",
        implications: "Ensures adherence to regulatory standards."
    },
    {
        euAIActClauses: "Article 10",
        terms: "Data and data governance",
        description: "Establishes data governance requirements for AI systems.",
        expectedEvidence: "Data management policies, data protection measures",
        implications: "Safeguards data privacy and security."
    },
    {
        euAIActClauses: "Article 14",
        terms: "Human oversight",
        description: "Specifies obligations for human oversight and intervention in high-risk AI systems.",
        expectedEvidence: "Human oversight protocols, intervention mechanisms",
        implications: "Ensures safety and responsible operation of AI systems."
    },
    {
        euAIActClauses: "Article 15",
        terms: "Accuracy, robustness, and cybersecurity",
        description: "Imposes cybersecurity obligations on AI system providers.",
        expectedEvidence: "Cybersecurity protocols, vulnerability assessments",
        implications: "Enhances system security and resilience."
    },
    {
        euAIActClauses: "Article 16",
        terms: "Obligations of providers of high-risk AI systems",
        description: "Specifies obligations for providers of high-risk AI systems, including transparency, data access, etc.",
        expectedEvidence: "Compliance reports, transparency documentation",
        implications: "Ensures responsible development and operation of high-risk AI."
    },
    {
        euAIActClauses: "Article 28b",
        terms: "Provider obligations for foundation models",
        description: "Specifies obligations for providers of foundation models, including compliance and oversight.",
        expectedEvidence: "Compliance reports, documentation on model development",
        implications: "Ensures foundation model providers adhere to regulatory standards."
    },
    {
        euAIActClauses: "Article 41(1)",
        terms: "Common specifications",
        description: "Authorizes the establishment of common specifications for AI systems.",
        expectedEvidence: "Published specifications, conformity assessments",
        implications: "Promotes interoperability and conformity in AI systems."
    },
    {
        euAIActClauses: "Article 42",
        terms: "Additional obligations for cybersecurity",
        description: "Imposes further obligations related to cybersecurity for AI systems.",
        expectedEvidence: "Cybersecurity protocols, compliance documentation",
        implications: "Strengthens cybersecurity measures for AI systems."
    },
    {
        euAIActClauses: "Article 52",
        terms: "Transparency obligations for certain AI systems",
        description: "Requires AI systems to clearly inform users of their AI nature unless obvious.",
        expectedEvidence: "User interface designs, system documentation",
        implications: "Ensures users are aware of interacting with AI systems."
    },
    {
        euAIActClauses: "Article 61",
        terms: "Post-market monitoring by providers and post-market monitoring plan",
        description: "Mandates post-market monitoring by providers and the development of monitoring plans.",
        expectedEvidence: "Post-market monitoring reports, monitoring plans",
        implications: "Enhances accountability and responsiveness to AI system performance in real-world scenarios."
    },
    {
        euAIActClauses: "Article 69",
        terms: "Code of conduct",
        description: "Encourages the development of voluntary codes of conduct to promote adherence to high-risk AI obligations.",
        expectedEvidence: "Published codes of conduct, adherence reports",
        implications: "Fosters industry self-regulation and compliance."
    },
    {
        euAIActClauses: "Article 71",
        terms: "Fines for non-compliance",
        description: "Specifies penalties for providers misclassifying high-risk AI systems and non-compliance.",
        expectedEvidence: "Penalty records, legal proceedings",
        implications: "Encourages adherence to regulatory requirements through deterrence."
    },
    {
        euAIActClauses: "Article 83",
        terms: "Misclassification penalties",
        description: "Imposes penalties on providers misclassifying high-risk AI systems.",
        expectedEvidence: "Misclassification reports, penalty records",
        implications: "Encourages accurate classification and compliance."
    },
    {
        euAIActClauses: "Article 84",
        terms: "Commission assessment of AI Act annually",
        description: "Requires the Commission to assess the implementation and impact of the AI Act annually.",
        expectedEvidence: "Commission assessment reports, impact analyses",
        implications: "Ensures ongoing evaluation and improvement of AI regulation."
    },
    {
        euAIActClauses: "Article 28",
        terms: "Responsibilities along the AI value chain",
        description: "Specifies obligations for different entities in the AI value chain.",
        expectedEvidence: "Contracts, agreements, compliance reports",
        implications: "Ensures accountability and collaboration throughout the chain."
    }
]

export const SINGAPORE_AI_REG_ACT_DATA = [
    {
        clauseSection: 2.7,
        sectionDescription: "This section outlines the principle of transparency in AI, emphasizing the importance of clarity and openness regarding AI systems' functionality, decision-making processes, and data usage.",
        expectedEvidences: " Documentation detailing the AI system's architecture, algorithms, and data sources.\n Explanations of how the AI system reaches its decisions.\n Transparency reports highlighting model performance, biases, and limitations."
    },
    {
        clauseSection: 2.7,
        sectionDescription: "This section addresses the principle of accountability, stressing the need for clear responsibility and oversight in AI development, deployment, and use.",
        expectedEvidences: "Policies or guidelines defining roles and responsibilities for AI development and deployment. Mechanisms for tracking and auditing AI system performance and outcomes.\nRecords of accountability measures taken in response to issues or failures."
    },
    {
        clauseSection: 2.8,
        sectionDescription: "Rationale for Using the Model Framework",
        expectedEvidences: "Explanation of the need to deploy AI technologies responsibly, considering their potential impact on individuals, society, markets, or economies.\n Identification of specific challenges or risks associated with AI decision-making and predictions"
    },
    {
        clauseSection: 2.8,
        sectionDescription: "Application of the Model Framework",
        expectedEvidences: " Documentation outlining how the Model Framework is applied throughout the AI deployment process.\n Examples or case studies demonstrating the integration of the Model Framework into AI projects or initiatives."
    },
    {
        clauseSection: 2.8,
        sectionDescription: "Assessment of AI Predictions\/Decisions",
        expectedEvidences: "Procedures for evaluating the reliability and accuracy of AI predictions and decisions.\n Records of assessments conducted on AI outputs, including any discrepancies or uncertainties identified."
    },
    {
        clauseSection: 2.8,
        sectionDescription: "Mitigation of Unexplainable Aspects in AI Predictions",
        expectedEvidences: "Strategies or techniques employed to address the lack of explainability in certain AI predictions or decisions. Documentation of efforts to enhance interpretability or transparency in AI systems."
    },
    {
        clauseSection: 2.8,
        sectionDescription: "Consideration of Impact on Individuals and Society",
        expectedEvidences: "Documentation of measures taken to assess and mitigate potential negative impacts on individuals or societal groups affected by AI decisions. \nReports on the ethical considerations and societal implications of deploying AI technologies."
    },
    {
        clauseSection: 2.8,
        sectionDescription: "Monitoring and Continuous Improvement of AI Deployments",
        expectedEvidences: " Processes for ongoing monitoring and evaluation of AI deployments to ensure compliance with ethical standards and regulatory requirements.\n Evidence of feedback loops and mechanisms for continuous improvement in AI systems."
    },
    {
        clauseSection: 2.8,
        sectionDescription: "Compliance with Regulatory and Ethical Standards",
        expectedEvidences: " Evidence of compliance with relevant laws, regulations, and industry standards governing the use of AI technologies. Documentation of ethical frameworks or guidelines adopted to guide AI deployment practices."
    },
    {
        clauseSection: 2.8,
        sectionDescription: "Stakeholder Engagement and Communication",
        expectedEvidences: " Records of stakeholder engagement activities, including consultations with impacted individuals, communities, or organizations. Communication strategies for informing stakeholders about the deployment of AI technologies and addressing concerns."
    },
    {
        clauseSection: 2.9,
        sectionDescription: "Identification and Compilation of Ethical Principles",
        expectedEvidences: "Documentation outlining the process of identifying and compiling ethical principles relevant to AI deployment. Annex A containing a compilation of AI ethical principles, if referenced."
    },
    {
        clauseSection: 2.9,
        sectionDescription: "Alignment with Corporate Values",
        expectedEvidences: "Review of existing corporate values and their alignment with the identified ethical principles for AI deployment.\n Documentation highlighting the incorporation of ethical principles into corporate value statements."
    },
    {
        clauseSection: 2.9,
        sectionDescription: "Integration with Corporate Risk Management Framework",
        expectedEvidences: "Review of existing corporate values and their alignment with the identified ethical principles for AI deployment.\nDocumentation highlighting the incorporation of ethical principles into corporate value statements."
    },
    {
        clauseSection: 2.9,
        sectionDescription: "Incorporation into Corporate Governance Structures",
        expectedEvidences: "Documentation detailing how ethical principles for AI deployment are integrated into existing corporate governance structures.\nEvidence of updates or revisions made to corporate governance policies to include AI ethics."
    },
    {
        clauseSection: 2.9,
        sectionDescription: "Guidance for Adoption of AI within the Organization",
        expectedEvidences: "Procedures or guidelines provided to employees for adopting AI technologies in alignment with the incorporated ethical principles. Training programs or resources on ethical AI deployment made available to employees."
    },
    {
        clauseSection: 2.9,
        sectionDescription: "Monitoring and Enforcement Mechanisms for Ethical Compliance",
        expectedEvidences: "Establishment of mechanisms for monitoring and enforcing compliance with ethical principles in AI deployment.\n Records of audits or assessments conducted to ensure adherence to ethical guidelines."
    },
    {
        clauseSection: 2.9,
        sectionDescription: "Continuous Review and Improvement of Ethical Framework",
        expectedEvidences: "Processes for ongoing review and improvement of the ethical framework for AI deployment within the organization.\nEvidence of feedback loops and mechanisms for incorporating lessons learned into the ethical framework."
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Importance of Good Data Management Practices",
        expectedEvidences: " Explanation of the significance of good data management practices in ensuring the reliability, accuracy, and fairness of machine learning models. Identification of potential risks associated with poor data management."
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Data Collection and Acquisition",
        expectedEvidences: "Documentation outlining procedures for collecting and acquiring data for training machine learning models. Records of data sources, collection methods, and data quality assessments"
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Data Preprocessing and Cleaning",
        expectedEvidences: " Steps taken to preprocess and clean raw data to ensure consistency, completeness, and accuracy. Documentation of preprocessing techniques applied (e.g., normalization, feature scaling)."
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Data Annotation and Labeling",
        expectedEvidences: " Processes for annotating and labeling data for supervised learning tasks. Examples of annotated datasets used for training machine learning models."
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Data Storage and Security",
        expectedEvidences: "Systems and protocols in place for storing and securing sensitive or proprietary data used in model training.\nDocumentation of data storage mechanisms and security measures implemented."
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Data Governance and Compliance",
        expectedEvidences: "Policies and procedures governing data usage, access, and sharing to ensure compliance with regulatory requirements.\n Records of data governance practices and compliance audits conducted."
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Data Versioning and Tracking",
        expectedEvidences: " Mechanisms for versioning and tracking changes made to datasets used for training machine learning models.\n Evidence of version control systems or tools implemented for managing data."
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Data Privacy and Confidentiality",
        expectedEvidences: "Measures taken to protect individuals' privacy and ensure confidentiality when handling personal or sensitive data.\nDocumentation of privacy policies, anonymization techniques, and data access controls."
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Data Quality Assessment and Monitoring",
        expectedEvidences: "Methods for assessing and monitoring the quality of data used in machine learning models.\n Records of data quality assessments and corrective actions taken to address issues identified"
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Documentation and Metadata Management",
        expectedEvidences: "Documentation of datasets used for training machine learning models, including metadata describing dataset characteristics.\nExamples of metadata schemas or standards adopted for dataset documentation."
    },
    {
        clauseSection: "2.1O",
        sectionDescription: "Data Retention and Disposal Policies",
        expectedEvidences: "Policies and procedures governing the retention and disposal of data after model training or deployment.<br>- Records of data retention periods and procedures for data disposal in compliance with regulations."
    },
    {
        clauseSection: 2.11,
        sectionDescription: "Identification of Cybersecurity Risks",
        expectedEvidences: "Assessment of potential cybersecurity risks associated with AI-dependent systems and services.\n Documentation outlining the types of cyber-attacks that could lead to catastrophic failures."
    },
    {
        clauseSection: 2.11,
        sectionDescription: "Integration of Cybersecurity Measures",
        expectedEvidences: "Implementation of cybersecurity measures to mitigate risks and safeguard AI-dependent systems. Documentation of cybersecurity protocols, firewalls, encryption methods, and intrusion detection systems deployed."
    },
    {
        clauseSection: 2.11,
        sectionDescription: "Continuous Monitoring and Threat Detection",
        expectedEvidences: "Processes for continuous monitoring and threat detection to identify and respond to cyber threats in real-time.\nRecords of cybersecurity monitoring activities and incident response procedures."
    },
    {
        clauseSection: 2.11,
        sectionDescription: "Resilience and Disaster Recovery Planning",
        expectedEvidences: "Development of resilience and disaster recovery plans to ensure business continuity in the event of a cyber-attack.\n Documentation of contingency plans, backup systems, and recovery procedures for AI-dependent systems."
    },
    {
        clauseSection: 2.11,
        sectionDescription: "Employee Training and Awareness",
        expectedEvidences: "Training programs and awareness campaigns to educate employees about cybersecurity best practices. Evidence of employee pArticleicipation in cybersecurity training sessions and drills."
    },
    {
        clauseSection: 2.11,
        sectionDescription: "Third-PArticley Risk Management",
        expectedEvidences: "Assessment and management of cybersecurity risks associated with third-party vendors or partners involved in AI systems. Documentation of third-party security assessments and compliance checks."
    },
    {
        clauseSection: 2.11,
        sectionDescription: "Compliance with Regulatory Standards and Industry Guidelines",
        expectedEvidences: " Adherence to regulatory standards and industry guidelines related to cybersecurity in AI-dependent systems.\n Records of compliance audits and certifications obtained for cybersecurity measures implemented."
    },
    {
        clauseSection: 2.11,
        sectionDescription: "Incident Response and Post-Incident Analysis",
        expectedEvidences: " Established procedures for incident response and post-incident analysis to assess the impact of cyber-attacks and prevent future occurrences. Documentation of incident response plans and post-mortem analyses conducted"
    },
    {
        clauseSection: 2.11,
        sectionDescription: "Continuous Improvement and Adaptation",
        expectedEvidences: " Established procedures for incident response and post-incident analysis to assess the impact of cyber-attacks and prevent future occurrences. Documentation of incident response plans and post-mortem analyses conducted"
    },
    {
        clauseSection: 2.12,
        sectionDescription: "Compliance with Current Laws and Regulations",
        expectedEvidences: "Acknowledgment that adopting the Model Framework does not exempt organizations from complying with existing laws and regulations. Documentation of relevant laws and regulations applicable to data management and protection (e.g., PDPA, OECD Privacy Principles)."
    },
    {
        clauseSection: 2.12,
        sectionDescription: "Alignment with Accountability-Based Practices",
        expectedEvidences: "Explanation of how the Model Framework is accountability-based, emphasizing its focus on responsible data management and protection. Identification of accountability-based practices outlined in the Model Framework."
    },
    {
        clauseSection: 2.12,
        sectionDescription: "Integration with Data Management and Protection Laws",
        expectedEvidences: "Demonstration of how adopting the Model Framework assists organizations in implementing accountability-based practices aligned with data management and protection laws.<br>- Documentation of specific provisions within the Model Framework that align with requirements under the PDPA, OECD Privacy Principles, or other relevant regulations."
    },
    {
        clauseSection: 2.12,
        sectionDescription: "Documentation of Implemented Accountability Practices",
        expectedEvidences: "Records of accountability-based practices implemented by the organization in data management and protection.\n Evidence of policies, procedures, or controls established to ensure compliance with legal and regulatory requirements."
    },
    {
        clauseSection: 2.12,
        sectionDescription: "Audit Trails and Compliance Reports",
        expectedEvidences: " Audit trails or logs demonstrating adherence to accountability-based practices outlined in the Model Framework.\nCompliance reports or assessments documenting alignment with data management and protection laws."
    },
    {
        clauseSection: 2.12,
        sectionDescription: "Training and Awareness Programs",
        expectedEvidences: "Training programs or awareness campaigns conducted to educate employees about accountability-based practices and legal requirements.\n Evidence of employee participation in training sessions related to data management and protection."
    },
    {
        clauseSection: 2.12,
        sectionDescription: "Governance Structures Supporting Compliance",
        expectedEvidences: "Documentation of governance structures established to oversee compliance with data management and protection laws.\n Evidence of roles and responsibilities assigned for ensuring adherence to legal and regulatory requirements."
    },
    {
        clauseSection: 2.12,
        sectionDescription: "Continuous Improvement Mechanisms",
        expectedEvidences: "Processes for continuous improvement in data management and protection practices to adapt to evolving legal and regulatory landscapes.\n Evidence of updates or revisions made to policies and procedures in response to changes in laws or regulations."
    },
    {
        clauseSection: 2.12,
        sectionDescription: "External Validation and Certification",
        expectedEvidences: "Participation in external validation or certification programs to verify compliance with accountability-based practices and legal requirements.\nCertificates or attestations obtained confirming alignment with data management and protection laws"
    },
    {
        clauseSection: 2.13,
        sectionDescription: "This section highlights the importance of considering sector-specific laws, regulations, and guidelines in industries such as finance, healthcare, and legal sectors when implementing artificial intelligence (AI) and data analytics. It specifically mentions the example of the \"FEAT Principles\" published by the Monetary Authority of Singapore for the financial sector as guidance for firms utilizing AI and data analytics.",
        expectedEvidences: "Reference to industry-specific laws, regulations, or guidelines relevant to the sector, such as those governing finance, healthcare, and legal sectors.\nMention of the \"FEAT Principles\" published by the Monetary Authority of Singapore as an example of sector-specific guidance for firms using AI and data analytics in the financial sector.\nAdvising organizations to be mindful of such laws, regulations, and guidelines, emphasizing that adopting a model framework for AI implementation does not automatically ensure compliance with sector-specific regulations."
    },
    {
        clauseSection: 2.14,
        sectionDescription: "This section introduces a simplified diagram illustrating the key stakeholders involved in the adoption process of artificial intelligence (AI) as discussed in the Model Framework. It clarifies that the adoption process does not differentiate between different types of business relationships, such as business-to-consumer (B2C), business-to-business (B2B), and business-to-business-to-consumer (B2B2C).",
        expectedEvidences: "Presentation of a simplified diagram depicting the key stakeholders involved in the AI adoption process.\nExplanation or description of the roles of various stakeholders represented in the diagram.\nClarification that the adoption process described in the model framework applies universally and does not categorize between different types of business relationships such as B2C, B2B, or B2B2C."
    },
    {
        clauseSection: 2.15,
        sectionDescription: "This section acknowledges the variability in definitions of terms used in artificial intelligence (AI) depending on the context and usage. It highlights the importance of understanding the specific meanings of terms within the context they are being used.",
        expectedEvidences: "Recognition of the fact that certain terms used in the field of AI may have different definitions depending on the specific context or application.\nExamples of terms that may have varied definitions in different contexts, illustrating the potential for ambiguity or misunderstanding.\nEmphasis on the importance of clarity and precision in defining terms within the specific context of AI adoption, to ensure effective communication and understanding among stakeholders.\n\n\n\n"
    },
    {
        clauseSection: 3.1,
        sectionDescription: "\n\na. Internal governance structures and measures: Guidance on adapting or establishing internal governance structures and measures to incorporate values, risks, and responsibilities related to algorithmic decision-making.\n\nb. Determining the level of human involvement in AI-augmented decision-making: Methodologies to assist organizations in determining their risk appetite for AI usage, defining acceptable risks, and identifying the appropriate level of human involvement in decision-making processes augmented by AI.\n\nc. Operations management: Considerations for developing, selecting, and maintaining AI models, including aspects related to data management.\n\nd. Stakeholder interaction and communication: Strategies for effectively communicating with an organization's stakeholders and managing relationships with them.",
        expectedEvidences: "Explanation of each key area covered by the Model Framework, including internal governance, human involvement, operations management, and stakeholder interaction.\nDescription of the specific guidance provided within each area, such as adapting governance structures, determining risk appetite, managing operations, and communicating with stakeholders.\nClarity on the objectives of each area and how they contribute to promoting responsible AI use within organizations."
    },
    {
        clauseSection: 3.2,
        sectionDescription: "This section emphasizes the flexibility of the Model Framework, acknowledging that not all elements may be relevant to every organization. It states that organizations can adapt the framework to suit their specific needs by adopting only the elements that are relevant to their context.",
        expectedEvidences: "ssertion that the Model Framework is designed to be flexible, allowing organizations to customize it according to their unique requirements and circumstances.\nRecognition that not all elements of the framework may be applicable to every organization, indicating the need for selective adoption based on relevance.\nEncouragement for organizations to tailor the framework to their specific needs by adopting only those elements that align with their goals, challenges, and operational context."
    },
    {
        clauseSection: 3.3,
        sectionDescription: "This section describes the inclusion of illustrations within each section of the Model Framework to aid organizations in understanding how certain practices outlined in that specific section have been implemented by real-world companies. Additionally, it mentions the release of a Compendium of Use Cases by the PDPC (Preservation of Personal Data Commission) that showcases how both local and international organizations have implemented AI governance practices aligned with all sections of the Model Framework.",
        expectedEvidences: "Mention of the inclusion of illustrations within each section of the Model Framework to provide real-world examples of how practices have been implemented by companies.\nDescription of the purpose of these illustrations, which is to help organizations better understand how to apply the guidance provided in each section to their own contexts."
    },
    {
        clauseSection: 3.4,
        sectionDescription: "This section aims to provide guidance to organizations on developing suitable internal governance structures that enable them to maintain adequate oversight over the integration of AI technologies into their operations, products, and services.",
        expectedEvidences: "Clarification of the purpose of the section, which is to assist organizations in establishing internal governance structures specifically tailored to effectively oversee the incorporation of AI technologies into their operations.\nExplanation of the importance of such governance structures in ensuring that AI implementation aligns with organizational goals, values, and ethical standards."
    },
    {
        clauseSection: 3.5,
        sectionDescription: "The significance of internal governance structures and measures in providing strong oversight over an organization's utilization of artificial intelligence (AI). It suggests that existing internal governance structures can be modified, or new ones can be established if required. For instance, risks linked with AI usage can be handled within the enterprise risk management structure, while ethical considerations can be embedded as core corporate values and supervised through ethics review boards or analogous mechanisms.",
        expectedEvidences: "Affirmation of the crucial role played by internal governance structures and measures in ensuring effective oversight of an organization's AI utilization.\nRecommendation for adapting or establishing internal governance frameworks to address AI-related challenges, emphasizing flexibility to accommodate specific organizational needs.\nIllustrative example provided to demonstrate how risks associated with AI can be integrated into the existing enterprise risk management structure."
    },
    {
        clauseSection: 3.6,
        sectionDescription: "This section suggests that organizations should carefully consider the features of their internal governance structures. For instance, if relying solely on a centralized governance mechanism is deemed suboptimal, a decentralized approach may be more appropriate, allowing ethical considerations to be integrated into day-to-day decision-making at the operational level as needed. Additionally, it highlights the importance of securing sponsorship, support, and participation from the organization's top management and board of directors in its AI governance efforts.",
        expectedEvidences: "Recommendation for organizations to assess and determine the appropriate features of their internal governance structures to effectively manage AI-related challenges and ethical considerations.\nRecognition that a decentralized governance approach may be preferable in certain situations, enabling ethical considerations to be addressed in daily operational decision-making processes."
    },
    {
        clauseSection: 3.7,
        sectionDescription: "Suggests that organizations should contemplate integrating features into their internal governance structures that are pertinent to their development. It encourages organizations to tailor their governance frameworks to suit their specific needs and circumstances.",
        expectedEvidences: "Recommendation for organizations to consider incorporating features into their internal governance structures that are pertinent to their development and operational context.\nEncouragement for organizations to customize their governance frameworks to align with their unique requirements, challenges, and objectives."
    },
    {
        clauseSection: 3.8,
        sectionDescription: "Designed to assist organizations in determining the suitable level of human oversight in decision-making processes that are augmented by artificial intelligence (AI). It aims to provide guidance on striking the right balance between automated AI-driven decision-making and human involvement to ensure ethical, responsible, and effective outcomes.",
        expectedEvidences: "Statement indicating the purpose of the section, which is to aid organizations in assessing and determining the appropriate degree of human oversight in decision-making processes enhanced by AI technology.\nExplanation of the importance of establishing the right level of human involvement to ensure accountability, transparency, and fairness in AI-augmented decision-making."
    },
    {
        clauseSection: 3.9,
        sectionDescription: "Emphasizes the importance of having a clear understanding of the objectives behind using artificial intelligence (AI) as a crucial initial step in determining the level of human oversight required. It suggests that organizations should define their commercial objectives for utilizing AI, such as ensuring consistency in decision-making, improving operational efficiency, reducing costs, or introducing new product features to enhance consumer choice. ",
        expectedEvidences: "Assertion of the importance of clarity regarding the objectives of AI usage as a foundational step in determining the appropriate extent of human oversight.\nRecommendation for organizations to define their commercial objectives for AI deployment, including objectives related to decision-making consistency, operational efficiency, cost reduction, and product innovation."
    },
    {
        clauseSection: "3.1O",
        sectionDescription: "This section underscores the importance for organizations operating across multiple countries to consider differences in societal norms, values, and expectations whenever feasible. It suggests that such considerations are crucial due to variations in acceptability and risk perception across different regions. For instance, what might be acceptable in terms of gaming advertisements in one country may not be acceptable in another. ",
        expectedEvidences: "Emphasis on the importance of organizations operating internationally to take into account the differences in societal norms, values, and expectations across various regions.\nExample provided to illustrate how acceptability and risk perceptions can vary between countries, such as differences in attitudes towards gaming advertisements."
    },
    {
        clauseSection: 3.11,
        sectionDescription: "Highlights that certain risks to individuals stemming from AI deployment may only become apparent at the group level. For instance, the widespread adoption of a stock recommendation algorithm could lead to herding behavior, potentially increasing overall market volatility if a significant number of individuals make similar decisions simultaneously. Additionally, besides risks to individuals, organizations may also identify other types of risks, such as risks to their commercial reputation.",
        expectedEvidences: "Explanation of how certain risks associated with AI implementation may only manifest when considered at the group level, rather than at an individual level.\nExample provided to illustrate this concept, such as the potential for herding behavior in financial markets due to the widespread adoption of a stock recommendation algorithm."
    },
    {
        clauseSection: 3.12,
        sectionDescription: "This section emphasizes that organizations should ideally align their evaluation of commercial objectives against the risks associated with AI usage with their corporate values. It suggests that organizations should assess whether their intended AI deployment and the chosen algorithmic decision-making model align with their core values. Any inconsistencies or deviations from these values should be deliberate decisions made by organizations, supported by a clearly defined and documented rationale.",
        expectedEvidences: "Statement emphasizing the importance of organizations aligning their assessment of AI deployment with their corporate values.\nRecommendation for organizations to evaluate whether their AI initiatives and decision-making models adhere to their core values.\nSuggestion that any inconsistencies or deviations from corporate values should be consciously made by organizations, rather than accidental or overlooked."
    },
    {
        clauseSection: 3.13,
        sectionDescription: " Underscores the iterative and ongoing nature of identifying commercial objectives, risks, and determining the appropriate level of human involvement in AI-augmented decision-making. It suggests that organizations should continually identify and review risks associated with their technology solutions, mitigate those risks, and maintain response plans in case mitigation efforts fail.",
        expectedEvidences: "Statement emphasizing the iterative and ongoing nature of the process for identifying commercial objectives, risks, and determining the appropriate level of human involvement in AI deployment.\nRecommendation for organizations to continuously identify, review, and mitigate risks associated with their AI solutions."
    },
    {
        clauseSection: 3.14,
        sectionDescription: "Automated Decision-Making (ADM)",
        expectedEvidences: "Lack of human involvement in decision-making processes.\nClear documentation or system logs indicating automated processes.\nConsistency in decision outcomes across similar scenarios.\nMinimal to no variation in decisions over time."
    },
    {
        clauseSection: 3.14,
        sectionDescription: "Human-in-the-Loop (HITL)",
        expectedEvidences: "Instances where human intervention or oversight is recorded in decision-making logs.\nVariation in decisions based on human review or intervention.\nFeedback mechanisms where humans provide corrections or adjustments to automated decisions"
    },
    {
        clauseSection: 3.15,
        sectionDescription: "Probability of Harm",
        expectedEvidences: "Historical data or predictive models indicating the likelihood of harmful outcomes.\nRisk assessments or probability estimates generated by the AI system."
    },
    {
        clauseSection: 3.15,
        sectionDescription: "Severity of Harm",
        expectedEvidences: "Impact assessments detailing the potential consequences of different decision outcomes.\nLegal or regulatory standards defining thresholds for severe harm."
    },
    {
        clauseSection: 3.16,
        sectionDescription: "This section will delve into the nuanced differences in how \"harm\" is defined and how probability and severity are computed across different sectors. It will use the example of a hospital and a clothing store to illustrate these variations, emphasizing the distinct considerations each sector has regarding harm",
        expectedEvidences: "Hospital Perspective:\nDefinition of Harm: In a hospital setting, harm typically refers to any adverse outcome resulting from medical errors or incorrect diagnoses. This could include physical harm to the patient, such as worsening of the medical condition, unnecessary treatments, or even death.\nComputation of Probability and Severity: Hospitals assess the probability of harm occurring due to a wrong diagnosis by considering factors such as the complexity of the medical condition, the expertise of the medical staff involved, and the availability of diagnostic resources. Severity is determined by the potential impact on the patient's health, including the likelihood of further complications or adverse events."
    },
    {
        clauseSection: 3.17,
        sectionDescription: "Emphasize that while probability of harm and severity of harm are significant factors in determining the level of human oversight in decision-making processes involving AI, they are not the sole considerations",
        expectedEvidences: "Organizations must comply with laws and regulations governing AI usage, which may dictate specific levels of human oversight. For instance, data protection laws such as GDPR require transparency and accountability in automated decision-making processes, necessitating human involvement to ensure compliance."
    },
    {
        clauseSection: 3.18,
        sectionDescription: "This section will elaborate on the necessity for organizations to allow human intervention in safety-critical systems, particularly in instances where the AI system cannot ensure safe operation or meaningful decision-making. ",
        expectedEvidences: "Aviation Industry: In aviation, safety-critical systems often involve complex AI algorithms for autopilot and navigation.\nEvidence: Reference to aviation safety protocols and regulations mandating human oversight in critical phases of flight."
    },
    {
        clauseSection: 3.19,
        sectionDescription: "Implementing responsible measures during the operational phase of their AI adoption process. It outlines a reference AI adoption process to contextualize recommendations for good governance concerning the organization's data, algorithms, and AI models.",
        expectedEvidences: "Structured Framework: The reference AI adoption process provides a structured framework guiding organizations through the various stages of adopting AI technology, including planning, development, deployment, and operation.\nEvidence: Presentation of the key stages of the AI adoption process, along with descriptions of the activities and considerations involved in each stage."
    },
    {
        clauseSection: "3.2O",
        sectionDescription: "This section introduces the generalized AI model development and deployment process proposed by the Model Framework. It emphasizes that the process is not strictly linear but rather a continuous cycle of learning and improvement for organizations implementing AI solutions.",
        expectedEvidences: "Continuous Learning Cycle: The Model Framework outlines a multi-phase process for developing and deploying AI solutions within organizations.\nEvidence: Description of the key phases in the AI model development and deployment process as outlined by the Model Framework. "
    },
    {
        clauseSection: 3.21,
        sectionDescription: "\n\n\nThis part of the Model Framework centers on the deployment phase of AI solutions, emphasizing the close interaction between data and algorithms\/models. During deployment, various algorithms such as linear regression, decision trees, or neural networks are applied to analyze training datasets. ",
        expectedEvidences: "Iterative Algorithm Application: The deployment phase of the Model Framework highlights the iterative application of algorithms such as linear regression, decision trees, or neural networks on training datasets. Evidence: Description of the iterative nature of algorithm application during deployment, emphasizing the continuous refinement of models to achieve optimal results. "
    },
    {
        clauseSection: 3.22,
        sectionDescription: " Highlights the importance of data quality and selection in building AI models, emphasizing that datasets may originate from multiple sources and include both personal and non-personal data. The success of an AI solution heavily relies on the quality and selection of data from each of these sources.",
        expectedEvidences: "Critical Decision-Making: Individuals involved in training and selecting models for deployment play a crucial role in ensuring the quality and fairness of AI solutions. Evidence: Description of the responsibilities and decision-making authority of individuals involved in model training and selection. "
    },
    {
        clauseSection: 3.23,
        sectionDescription: "This section emphasizes the importance of defining clear ownership and accountability for AI models within organizations. It notes that individuals involved in training and selecting models for deployment can be internal staff or external service providers.",
        expectedEvidences: "Internal Departmental Owner: Each deployed AI model should have an internal departmental owner designated to oversee its deployment and decision-making processes.\nEvidence: Description of the role of internal departmental owners in overseeing the deployment of AI models, including their responsibilities in decision-making, monitoring model performance, and addressing issues of bias or fairness. "
    },
    {
        clauseSection: 3.24,
        sectionDescription: "This section underscores the enduring relevance of good data accountability practices even when only non-personal data are utilized for training AI models, including instances where personal data has been anonymized.",
        expectedEvidences: "Consistent Application: Good data accountability practices, such as clear ownership, transparency, and collaboration among relevant departments, remain pertinent irrespective of whether the training data comprises personal or non-personal information, including anonymized personal data.\nEvidence: Explanation of how data accountability practices ensure the reliability and ethical use of data in AI model training, regardless of data type. "
    },
    {
        clauseSection: 3.25,
        sectionDescription: "This section outlines various measures that can enhance the transparency of algorithms in AI models, such as explainability, repeatability, robustness, regular tuning, reproducibility, traceability, and auditability.",
        expectedEvidences: "Identification of Relevant Measures: Organizations are advised to identify the subset of features or functionalities in AI models that have the greatest impact on stakeholders and determine \nPrioritization of Measures: Once the relevant features or functionalities are identified, organizations should prioritize transparency measures based on their effectiveness in building trust with stakeholders."
    },
    {
        clauseSection: 3.26,
        sectionDescription: "AI models, emphasizing the importance of understanding and trust-building. Explainability refers to the ability to elucidate how deployed AI models' algorithms function and how they arrive at specific predictions. The purpose of explainability is to foster understanding and trust among stakeholders.",
        expectedEvidences: "Explanation of AI Model Functioning: Explainability in AI models entails providing insights into how the algorithms operate and generate predictions.\nTrust-Building: The primary goal of explainability is to establish trust among stakeholders by fostering understanding of AI model predictions and decision-making processes."
    },
    {
        clauseSection: 3.27,
        sectionDescription: "This section underscores the importance of documenting the model training and selection processes in developing an intelligent system containing AI technologies. It emphasizes that documenting these processes, along with reasons for decisions made and measures taken to address identified risks, enables organizations to provide accountability for their decisions. ",
        expectedEvidences: "Accountability and Transparency: Documenting how model training and selection processes are conducted, along with reasons for decisions made and measures taken to address risks, enables organizations to provide accountability for their AI systems.\nEvidence: Examples of organizations documenting their model training and selection processes, including decision-making rationale and risk mitigation strategies, in technical reports, documentation, or audit trails\n"
    },
    {
        clauseSection: 3.28,
        sectionDescription: "This section highlights the importance of providing explanations for AI models that are understandable and meaningful to non-technical users, such as the general public. ",
        expectedEvidences: "Enhanced Understanding: Providing explanations for AI model decisions in non-technical terms, such as through counterfactuals and comparisons, can enhance understanding and trust among non-technical users.\nEvidence: Examples of organizations implementing implicit explanation techniques, such as counterfactuals and comparisons, in their AI systems to improve user understanding and acceptance."
    },
    {
        clauseSection: 3.29,
        sectionDescription: "This section acknowledges that there are scenarios where it may not be practical or reasonable to provide detailed information about algorithms deployed in certain contexts. For example, disclosing algorithms used for anti-money laundering detection, information security, and fraud prevention could potentially enable bad actors to evade detection. ",
        expectedEvidences: "Balancing Transparency and Security: In certain contexts, such as anti-money laundering detection and information security, there may be legal or security concerns that outweigh the benefits of algorithmic transparency.\nEvidence: Examples of industries or scenarios where algorithmic transparency poses significant risks to security, privacy, or proprietary information. "
    },
    {
        clauseSection: "3.3O",
        sectionDescription: "suggests that in cases where achieving explainability for AI models is not practical due to technological limitations, organizations can instead focus on documenting the repeatability of results produced by the AI model.",
        expectedEvidences: "Consistent Performance Assurance: Documenting the repeatability of AI model results demonstrates the model's ability to consistently produce the same outcomes when presented with identical scenarios.\nEvidence: Examples of organizations documenting the repeatability of AI model results in their deployment processes, along with explanations of how this provides users with confidence in the model's reliability. "
    },
    {
        clauseSection: 3.31,
        sectionDescription: " It highlights that robustness is evaluated based on the system's capability to function correctly in the presence of invalid input or adverse environmental conditions. Furthermore, it suggests that ensuring deployed AI models are sufficiently robust is crucial for building trust in the AI system",
        expectedEvidences: "Evaluation of Error Handling: Documentation demonstrating the evaluation of the AI system's ability to handle errors during execution and cope with erroneous input.\nTesting Under Stressful Conditions: Evidence of testing conducted to assess the system's performance under stressful environmental conditions or with invalid input."
    },
    {
        clauseSection: 3.32,
        sectionDescription: "AI models, highlighting the challenge of enumerating all preconditions and consequences for an action. Due to this limitation, models may produce nonsensical or unexpected results even with minor modifications to input data, which may go unnoticed by humans. ",
        expectedEvidences: "Documentation of Testing Procedures: Evidence of scenario-based testing methodologies used to assess the robustness of AI models against foreseeable erroneous input.\nAdversarial Testing Results: Records indicating the outcomes of adversarial testing conducted on AI models to evaluate their performance under unexpected input variables."
    },
    {
        clauseSection: 3.33,
        sectionDescription: "AI models, as it is impossible to detect all possible modifications to a set of input data. It highlights the potential risks associated with implementing continual learning, where machine learning models continue to adapt and change their learned parameters after deployment.",
        expectedEvidences: "Documentation of Risk Assessment: Evidence of risk assessments conducted by organizations considering the implementation of continual learning in their AI models.\nIdentification of Potential Risks: Records indicating the recognition of risks associated with continual learning, particularly regarding the potential for unpredictable model behavior.\nMitigation Strategies: Documentation of strategies developed to mitigate the identified risks, such as implementing safeguards"
    },
    {
        clauseSection: 3.34,
        sectionDescription: "This section suggests that establishing an internal policy and process for regular model tuning is effective in ensuring that deployed models can adapt to changes in customer behavior over time. It emphasizes the importance of refreshing models based on updated training datasets that incorporate new input data.",
        expectedEvidences: "Policy Development: Evidence of the development of an internal policy for regular model tuning, outlining the frequency, methodologies, and responsibilities involved.\nProcess Implementation: Documentation demonstrating the implementation of the model tuning process within the organization, including procedures for data collection, model retraining, and deployment.\nRecords of Model Refreshment: Evidence of regular model updates based on updated training datasets and changes in customer behavior, commercial objectives, risks, or corporate values."
    },
    {
        clauseSection: 3.35,
        sectionDescription: "AI systems in a manner that reflects the dynamic nature of the planned production environment. It suggests that testing should assess how well an AI solution generalizes and handles failures gracefully.",
        expectedEvidences: "Testing Methodologies: Documentation outlining the testing methodologies used to assess AI system performance, including scenarios reflecting the dynamism of the planned production environment.\nTest Cases: Records of test cases designed to evaluate the AI solution's ability to generalize well and handle failures gracefully, such as scenarios involving diverse obstacles and environmental variations.\nResults Analysis: Evidence of analyzing test results to identify areas for improvement and ensure alignment with actual production environment conditions."
    },
    {
        clauseSection: 3.36,
        sectionDescription: "This section defines the concept of traceability in the context of AI models. It states that an AI model is considered traceable if two aspects are documented in an easily understandable manner",
        expectedEvidences: "Decision Traceability: Records documenting the decisions made by the AI model, including the rationale behind each decision and the factors influencing them.\nProcess Traceability: Documentation outlining the datasets and processes involved in the AI model's decision-making, such as data gathering methods, data labeling procedures, and algorithms used in model training.\nAccessibility and Understandability: Evidence indicating that the traceability information is easily accessible and understandable by relevant stakeholders, such as decision-makers, auditors, and data scientists."
    },
    {
        clauseSection: 3.37,
        sectionDescription: "a. Building an audit trail to document both the model training process and the decisions made by the AI system.\nb. Implementing a black box recorder that captures all input data streams. For example, in a self-driving car, a black box recorder tracks the vehicle's position and records events such as when the self-driving system takes control, experiences technical issues, or requests the driver to intervene.\nc. Ensuring that data relevant to traceability are stored appropriately to prevent degradation or alteration and retained for durations relevant to the industry standards.",
        expectedEvidences: "Audit Trail Documentation: Records demonstrating the establishment of an audit trail to document both model training and AI-augmented decisions.\nImplementation of Black Box Recorder: Evidence of implementing a black box recorder system, including its functionality and the data streams it captures.\nData Storage and Retention Policies: Documentation outlining policies and procedures for storing and retaining data relevant to traceability, ensuring data integrity and compliance with industry standards."
    },
    {
        clauseSection: 3.38,
        sectionDescription: "a. The organization's assessment of the probability and\/or severity of harm resulting from the use of the AI system.\nb. The extent to which the AI model has been trialed or used previously.\nc. The regulatory requirements of their industry.",
        expectedEvidences: "Assessment Criteria: Documentation outlining the criteria used by the organization to assess the need for traceability measures in their AI systems.\nEvaluation Results: Records of the evaluation results, indicating which product features require traceability and the selected traceability measures.\nResource Allocation: Evidence of resource allocation considerations, such as the balance between the potential harm, trial or usage history of the AI model, and regulatory requirements."
    },
    {
        clauseSection: 3.39,
        sectionDescription: "This section distinguishes between repeatability and reproducibility in the context of AI models. While repeatability refers to the internal repetition of results within an organization, reproducibility refers to the ability of an independent verification team to produce the same results using the same AI method based on the documentation provided by the organization",
        expectedEvidences: "Definition and Differentiation: Clear documentation explaining the distinction between repeatability and reproducibility in the organization's context.\nRisk Assessment: Records of the risk assessment process conducted by the organization to identify AI-powered features requiring external reproducibility testing.\nReproducibility Testing Plan: Outline of the plan for external reproducibility testing, including the selected features, methodologies, and involvement of external verification teams."
    },
    {
        clauseSection: "3.4O",
        sectionDescription: " These practices include testing specific contexts or conditions, implementing verification methods, providing replication files, checking with original AI solution providers, and adopting certain points from the repeatability section.",
        expectedEvidences: "Testing Procedures: Records detailing the specific contexts or conditions tested to ensure reproducibility.\nVerification Methods: Documentation outlining the verification methods implemented to assess different aspects of the AI model's reliability and reproducibility.\nReplication Files: Availability of replication files that replicate each step of the AI model's developmental process, facilitating testing and reproduction of behaviors"
    },
    {
        clauseSection: 3.41,
        sectionDescription: "Auditability refers to the readiness of an AI system to undergo assessment of its algorithms, data, and design processes by internal or external auditors. This assessment contributes to the trustworthiness of the AI system by demonstrating responsible design and practices, as well as justifiable outcomes.",
        expectedEvidences: "Audit Readiness Documentation: Records indicating the readiness of the AI system to undergo assessment by internal or external auditors.\nEvaluation Reports: Availability of evaluation reports generated as a result of internal or external audits.\nResponsibility of Design and Practices: Documentation demonstrating responsible design and practices adopted by the organization in the development and deployment of the AI system.\nJustifiability of Outcomes: Evidence showcasing the justifiability of outcomes produced by the AI system, possibly through reports or documentation highlighting the alignment of outcomes with organizational goals and ethical standards."
    },
    {
        clauseSection: 3.42,
        sectionDescription: "Implementing auditability in AI systems involves not only the involvement of external parties but also the disclosure of commercially sensitive information to external auditors. Organizations can adopt a risk-based approach to identify the subset of AI-powered features in their products or services where implementing auditability is necessary",
        expectedEvidences: "Risk Assessment Reports: Records of risk assessments conducted to identify the subset of AI-powered features requiring auditability.\nCommercially Sensitive Information Disclosure: Documentation indicating the disclosure of commercially sensitive information to external auditors for the purpose of auditability.\nAlignment with Regulatory Requirements: Evidence demonstrating how the implementation of auditability aligns with relevant regulatory requirements or industry standards.\nAudit Reports: Reports generated as a result of audits conducted on the AI system, showcasing compliance with auditability measures."
    },
    {
        clauseSection: 3.43,
        sectionDescription: "To facilitate auditability, organizations can maintain a comprehensive record encompassing various aspects of data management, including data provenance, procurement, preprocessing, lineage, storage, and security.",
        expectedEvidences: "Data Provenance Documentation: Detailed records outlining the origin and history of data, including how it was collected, sourced, and processed.\nProcurement Records: Documentation of the methods and sources used to obtain data, along with any agreements or contracts related to data acquisition.\nPreprocessing Logs: Logs detailing the preprocessing steps applied to the data, including any transformations, cleaning, or normalization processes."
    },
    {
        clauseSection: 3.44,
        sectionDescription: "Organizations may choose to centralize the aforementioned information digitally within a process log. This centralized approach allows the organization to compile relevant information in one accessible location. ",
        expectedEvidences: "Comprehensive Data Compilation: A centralized repository containing detailed information on data provenance, procurement, preprocessing, lineage, storage, security measures, and qualitative insights.\nDemonstration of Responsibility: Documentation showcasing the organization's commitment to responsible design and practices, including justifications for processing behaviors and decisions.\nAccessibility and Presentation: Strategies and tools for organizing and presenting information in a clear, accessible manner, catering to different stakeholder needs and expertise levels."
    },
    {
        clauseSection: 3.45,
        sectionDescription: "This section aims to guide organizations in implementing effective strategies to build trust in stakeholder relationships during the deployment of AI technologies.",
        expectedEvidences: "Comprehensive Strategy: Documentation outlining the organization's approach to engaging with stakeholders throughout the AI deployment process.\nIdentification of Stakeholders: Clear identification of key stakeholders, including internal teams, external partners, customers, regulators, and the general public.\nCommunication Channels: Description of communication channels and methods used to interact with stakeholders, such as meetings, workshops, surveys, and online platforms."
    },
    {
        clauseSection: 3.46,
        sectionDescription: "\n\n\nOrganizations are encouraged to provide general information on whether AI is used in their products and\/or services. Where appropriate, this could include information on what AI is, how AI is used in decision-making in relation to consumers, what are its benefits, why your organization has decided to use AI, how your organization has taken steps to mitigate risks, and the role and extent that AI plays in the decision-making process.",
        expectedEvidences: "Clear statements or disclosures on the organization's website, product packaging, or promotional materials indicating the use of AI in products\/services.\nDocumentation or reports outlining the organization's AI usage, including descriptions of AI technologies employed and their purposes.\nCase studies or examples demonstrating the application of AI in decision-making processes related to consumer interactions or service delivery."
    },
    {
        clauseSection: 3.47,
        sectionDescription: "AI decision may affect an individual consumer, and whether the decision is reversible. For example, an organization may inform individuals that their credit ratings may lead to a loan refusal not only from this organization but also from other similar organizations, while also informing them that such a decision is reversible if individuals can provide more evidence of their creditworthiness.",
        expectedEvidences: "AI decision may affect an individual consumer, and whether the decision is reversible. For example, an organization may inform individuals that their credit ratings may lead to a loan refusal not only from this organization but also from other similar organizations, while also informing them that such a decision is reversible if individuals can provide more evidence of their creditworthiness."
    },
    {
        clauseSection: 3.48,
        sectionDescription: "A policy on what explanations to provide to individuals and when to provide them. Such policies help ensure consistency in communication and clearly set out roles and responsibilities of different members of the organization. These explanations can include details on how AI works in an AI-augmented decision-making process, how a specific decision was made and the reasons behind that decision, and the impact and consequence of the decision. The explanation can be provided as part of general communication or as information in respect of a specific decision upon request. ",
        expectedEvidences: "Documentation of a formal policy or guideline outlining the organization's approach to providing explanations to individuals regarding AI-augmented decision-making processes.\nClear delineation of roles and responsibilities within the organization for communicating explanations, including designated points of contact or departments responsible for handling inquiries"
    },
    {
        clauseSection: 3.49,
        sectionDescription: "Appropriate interaction and communication inspire trust and confidence as they build and maintain open relationships between organizations and individuals (including employees). Stakeholder relationship strategies should also not remain static. Companies are encouraged to test, evaluate, and review their strategies for effectiveness. Further, the extent and mode of implementation of these factors could vary from scenario to scenario.",
        expectedEvidences: "ocumentation of stakeholder relationship strategies, including communication plans, engagement initiatives, and feedback mechanisms.\nRecords of communication efforts, such as newsletters, emails, social media posts, or internal memos, demonstrating transparency and openness in interactions with stakeholders.\nFeedback surveys or assessments conducted to evaluate the effectiveness of communication and relationship-building strategies."
    },
    {
        clauseSection: "3.5O",
        sectionDescription: "\nAs different stakeholders have different information needs, an organization can start by first identifying its audience (i.e., its external and internal stakeholders). An organization’s external stakeholders may include consumers, regulators, other organizations it does business with, and society at large. Its internal stakeholders may include the organization’s board, management, and employees. ",
        expectedEvidences: "Stakeholder mapping diagrams or charts illustrating the relationships between the organization and its various stakeholders, highlighting key interactions and communication channels.\nStakeholder engagement plans or strategies outlining approaches for effectively communicating with different stakeholder groups, considering their unique information needs and preferences."
    },
    {
        clauseSection: 3.51,
        sectionDescription: "consider the information needs of consumers as they go through the journey of interacting with AI, from considering whether to use an AI solution, to understanding how the AI solution works as they use it, to requesting reviews on the decisions made by the AI solution.",
        expectedEvidences: "Analysis of consumer feedback and inquiries related to AI usage, highlighting common information needs and areas of confusion or concern.\nDevelopment of informational resources or guides addressing consumer questions and concerns at each stage of the AI interaction journey."
    },
    {
        clauseSection: 3.52,
        sectionDescription: "Organizations may wish to consider carefully when deciding whether to provide individuals with the option to opt out from the use of the AI product or service, and whether this option should be offered by default or only upon request",
        expectedEvidences: "Internal policy documents or guidelines outlining the organization's approach to providing opt-out options for individuals using AI products\/services.\nRisk assessments or impact analyses evaluating the potential harm or risk to individuals associated with the use of AI and the necessity of providing opt-out options."
    },
    {
        clauseSection: 3.53,
        sectionDescription: "Where an organization has weighed the factors above and decided not to provide an option to opt out, it is prudent for the organization to consider providing modes of recourse to the consumer, such as providing a channel for reviewing the decision. Where appropriate, organizations may also wish to keep a history of chatbot conversations when facing complaints or seeking recourse from consumers.",
        expectedEvidences: "Internal policies or guidelines outlining the organization's approach to providing recourse options for consumers who are unable to opt out of AI usage.\nDocumentation of considerations and rationale for not providing an opt-out option, including assessments of risk, feasibility, and alternative recourse mechanisms.\nEstablishment of consumer support channels or processes specifically designed for reviewing AI-driven decisions and addressing consumer concerns or complaints."
    },
    {
        clauseSection: 3.54,
        sectionDescription: "Feedback and Query Channel: This channel could be used for customers to raise feedback or queries. It could be managed by an organization’s Data Protection Officer (“DPO”) if this is appropriate.\nReview Request Avenue for AI Decisions: Apart from existing review obligations, organizations can consider providing an avenue for individuals (such as an aggrieved consumer) to request a review of material AI decisions that have affected them.",
        expectedEvidences: "Records of customer interactions and inquiries received through the Feedback and Query Channel, including transcripts or summaries of communication exchanges and any actions taken in response to customer feedback or queries.\nProcedures or protocols for managing data accuracy requests and corrections submitted through the Feedback and Query Channel, ensuring compliance with data protection regulations and organizational data governance policies."
    },
    {
        clauseSection: 3.55,
        sectionDescription: " If applicable, organizations are also encouraged to inform individuals that their responses would be used to train the AI system (e.g., a chatbot). Organizations should be aware of the risks of using such responses as some individuals may intentionally use “bad language” or “random replies” which would affect the training of the AI system.",
        expectedEvidences: "Reports or summaries of usability testing results, highlighting areas of improvement and actions taken to address identified issues before deployment.\nExamples of communication materials or disclosures provided to individuals regarding the use of their responses to train the AI system, including explanations of how their inputs contribute to improving system performance."
    },
    {
        clauseSection: 3.56,
        sectionDescription: "There are existing tools to measure readability, such as the Fry readability graph, the Gunning Fog Index, the Flesch-Kincaid readability tests, etc. It would be helpful for decisions with higher impact to be communicated in an easy-to-understand manner, with the need to be transparent about the technology being used.",
        expectedEvidences: "Documentation outlining the organization's approach to communicating in an easy-to-understand manner, including the use of readability measurement tools and strategies for transparency.\nReports or analyses using readability measurement tools to assess the clarity and comprehension level of organizational communications, highlighting areas for improvement."
    },
    {
        clauseSection: 3.57,
        sectionDescription: "In certain cases, organizations may be implementing AI-powered solutions that are also trained on real-life input data (i.e., active learning). These organizations may wish to consider setting out certain acceptable user policies (“AUPs”) to ensure that users do not maliciously introduce input data that unacceptably manipulates the performance and\/or results of the solution’s model.",
        expectedEvidences: "AUP documents detailing acceptable user behavior, including guidelines, restrictions, and consequences for violating the policy.\nCommunication materials or training resources provided to users, educating them on the importance of adhering to AUPs and the potential consequences of malicious input data manipulation."
    },
    {
        clauseSection: 3.58,
        sectionDescription: "In this regard, Acceptable User Policies (AUPs) serve to set broad boundaries for the interactions that individuals can perform with the AI system, such as restrictions with regard to intentional actions or attempts to reverse engineer, disable, interfere, or disrupt the functionality, integrity, or performance of the AI-powered service",
        expectedEvidences: "Comprehensive documentation outlining the establishment and enforcement of Acceptable User Policies (AUPs) for the AI-powered service.\nAUP documents detailing specific restrictions and guidelines for user interactions with the AI system, including prohibitions on intentional actions that may compromise functionality, integrity, or performance.\n"
    },
    {
        clauseSection: 3.59,
        sectionDescription: "Some of the approaches and methodologies described in the preceding section are also relevant when organizations interact with AI solution providers (such as procuring AI solutions and obtaining regulatory approval), or other organizations (such as facilitating industry collaboration, enabling interoperability of systems).",
        expectedEvidences: "Documentation outlining the organization's interactions with AI solution providers, including procurement processes, regulatory approval procedures, and collaboration efforts.\nContracts or agreements between the organization and AI solution providers, specifying information exchange requirements, feature development expectations, and alignment with industry frameworks or standards."
    },
    {
        clauseSection: "3.6O",
        sectionDescription: "Organizations may have to consider the level of support and detailed information that they may need to obtain from AI solution ",
        expectedEvidences: "Documentation outlining the organization's requirements and expectations regarding the level of support and detailed information needed from AI solution providers in each of the specified areas.\nContracts, agreements, or service level agreements (SLAs) with AI solution providers, specifying the information disclosure requirements and support commitments."
    },
    {
        clauseSection: 3.61,
        sectionDescription: "Depending on the purpose and context, the type and level of detail of information required may be different. For example, a regulator may require a regulated entity to demonstrate that its model development and selection process is sufficiently rigorous, and the AI solution provider may be required to provide more information and be involved in the clarification process with the regulator.",
        expectedEvidences: "Communication records or correspondence with regulators, demonstrating discussions and clarifications regarding the level of detail and information needed to satisfy regulatory requirements.\nContracts, agreements, or memoranda of understanding (MOUs) with industry collaborators, outlining the information exchange and interoperability requirements for collaborative projects or initiatives."
    },
    {
        clauseSection: 3.62,
        sectionDescription: "Development and use of AI evolve, organizations are encouraged to evaluate whether their AI governance practices and processes are in line with evolving AI standards, and make available the outcome of such evaluations to relevant stakeholders.",
        expectedEvidences: "Documentation outlining the organization's commitment to evaluating AI governance practices in alignment with evolving ethical standards.\nReports or assessments conducted to evaluate the organization's AI governance practices and processes, comparing them against evolving AI standards and identifying areas for improvement."
    }
]

export const TRUSTED_AI_DATA = [
    // {
    //     thrustArea: "Control Group Name\n(Function)",
    //     controlID: "Control Group ID\n(Category ID)",
    //     controlName: "Control Area\n(Category Objective)",
    //     subControlID: "Control ID\n(Subcategory ID)",
    //     subControlName: "Control Specification\n(Subcategory)",
    //     evidence: "Control Question",
    //     evidence1: "Recommendation",
    //     column8: "Top 20 \nControl",
    //     column9: "PEOPLE",
    //     column10: "PROCESS",
    //     column11: "DATA",
    //     column12: "TECHNOLOGY",
    //     column13: "ISO\/IEC TR 24028:2020 Information technology - Artificial intelligence - Overview of trustworthiness in artificial intelligence",
    //     column14: "BS ISO\/IEC 23053:2022 Framework for Artificial Intelligence (AI) Systems Using Machine Learning (ML) (British Standard)",
    //     column15: "ISO\/IEC 23894:2023 Information technology - Artificial intelligence - Guidance on risk management",
    //     column16: "ISO\/IEC DIS 5338:2022 Information technology - Artificial intelligence - AI system life cycle processes",
    //     column17: "ISO\/IEC 38507:2022 Information technology - Governance of IT - Governance implications of the use of artificial intelligence by organizations",
    //     column18: "ISO\/IEC 22989:2022 Information technology - Artificial intelligence - Artificial intelligence concepts and terminology"
    // },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 1",
        controlName: "Policies, processes, procedures, and practices across the organization related to the mapping, measuring, and managing of AI risks are in place, transparent, and implemented effectively.",
        subControlID: "GOVERN 1.1",
        subControlName: "Legal and regulatory requirements involving AI are understood, managed, and documented.",
        evidence: "Are legal and regulatory requirements involving AI understood, managed, and documented?",
        evidence1: "Ensure that legal and regulatory requirements involving AI are understood, managed, and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column13: "7.1\n8.7\n9.11.1",
        column15: "5.4.1 - ISO 31000:2018\n5.4.2 - ISO 31000:2018",
        column16: "6.1.1.3\n6.3.4.3\n6.3.8.3\n6.4.1.3\n6.4.2.3\n6.4.3.3\n6.4.8.2\n6.4.8.3\n6.4.17\n6.4.3",
        column17: "5.1\n5.5\n6.1\n6.4\n6.6.1\n6.7.3\nA.1.2\nA.1.3\nA.1.4",
        column18: "3.5.2\n5.15.1\n5.15.5\n5.17\n5.18\n5.19.5.4\n5.19.7.3\n6.2.2\n8.1\n"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 1",
        controlName: "Policies, processes, procedures, and practices across the organization related to the mapping, measuring, and managing of AI risks are in place, transparent, and implemented effectively.",
        subControlID: "GOVERN 1.2",
        subControlName: "The characteristics of trustworthy AI are integrated into organizational policies, processes, procedures, and practices.",
        evidence: "Are the characteristics of trustworthy AI integrated into organizational policies, processes, procedures, and practices?",
        evidence1: "Ensure that the characteristics of trustworthy AI are integrated into organizational policies, processes, procedures, and practices.",
        column9: "X",
        column10: "X",
        column11: "X",
        column13: "9.2\n9.3\n9.4",
        column14: "3.3.3\n6.5.5.1\n6.5.5.5\n8.7",
        column15: "5.2 - ISO 31000:2018\n6.7 - ISO 31000:2018",
        column16: "5.1\n5.2\n6.3.8.3\n6.4.2.3\n6.4.3.1\n6.4.11.1\nA.2.3\n",
        column17: 6,
        clumn18: 5.15
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 1",
        controlName: "Policies, processes, procedures, and practices across the organization related to the mapping, measuring, and managing of AI risks are in place, transparent, and implemented effectively.",
        subControlID: "GOVERN 1.3",
        subControlName: "Processes, procedures, and practices are in place to determine the needed level of risk management activities based on the organization’s risk tolerance.",
        evidence: "Are processes, procedures, and practices  in place to determine the needed level of risk management activities based on the organization’s risk tolerance?",
        evidence1: "Ensure that processes, procedures, and practices are in place to determine the needed level of risk management activities based on the organization’s risk tolerance.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 5.4,
        column14: 8.1,
        column15: "6.4.3.2 - ISO 31000:2018",
        column16: "5.3\n6.3.4\n6.4.3.1",
        column17: "5.3\n6.7",
        column18: "6.1\n6.2.2\n6.2.5\n6.2.6\n6.2.7\n9.4\nAnnex A"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 1",
        controlName: "Policies, processes, procedures, and practices across the organization related to the mapping, measuring, and managing of AI risks are in place, transparent, and implemented effectively.",
        subControlID: "GOVERN 1.4",
        subControlName: "The risk management process and its outcomes are established through transparent policies, procedures, and other controls based on organizational risk priorities.",
        evidence: "Are the risk management process and its outcomes established through transparent policies, procedures, and other controls based on organizational risk priorities?",
        evidence1: "Ensure that the risk management process and its outcomes are established through transparent policies, procedures, and other controls based on organizational risk priorities.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 5.4,
        column14: 8.5,
        column15: "6.4.2.6",
        column16: "5.3\n6.3.4\n6.3.5.3\n6.4.3.1",
        column17: 6.7,
        column18: 6.1
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 1",
        controlName: "Policies, processes, procedures, and practices across the organization related to the mapping, measuring, and managing of AI risks are in place, transparent, and implemented effectively.",
        subControlID: "GOVERN 1.5",
        subControlName: "Ongoing monitoring and periodic review of the risk management process and its outcomes are planned and organizational roles and responsibilities clearly defined, including determining the frequency of periodic review.",
        evidence: "Are ongoing monitoring and periodic review of the risk management process and its outcomes planned and organizational roles and responsibilities clearly defined, including determining the frequency of periodic review?",
        evidence1: "Ensure that ongoing monitoring and periodic review of the risk management process and its outcomes are planned and organizational roles and responsibilities clearly defined, including determining the frequency of periodic review.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 5.4,
        column14: "6.3\n8.7",
        column15: "6.6 - ISO 31000:2018\n5.4.3 - ISO 31000:2018",
        column16: "5.4.1\n5.4.3\n6.1.1.2\n6.1.2\n6.2.1\n6.2.2\n6.2.3.2\n6.2.4.2\n6.2.5.2\n6.2.6.2\n6.3.1.2\n6.3.2.2\n6.3.3.2\n6.3.4.2\n6.3.5.2\n6.3.6.2\n6.3.7\n6.3.8.2\n6.4.1.2\n6.4.2.2\n6.4.3.2\n6.4.4\n6.4.5\n6.4.6\n6.4.7.2\n6.4.8.2\n6.4.9.2\n6.4.10\n6.4.11.2\n6.4.12.2\n6.4.14.2\n6.4.15.2\n6.4.16.2\n6.4.17.2\n",
        column17: "6.2\n6.3\n6.5\n6.7.1\n6.7.2\n6.7.3\nA.1.3\nA.1.4\nA.2",
        column18: 5.19
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 1",
        controlName: "Policies, processes, procedures, and practices across the organization related to the mapping, measuring, and managing of AI risks are in place, transparent, and implemented effectively.",
        subControlID: "GOVERN 1.6",
        subControlName: "Mechanisms are in place to inventory AI systems and are resourced according to organizational risk priorities.",
        evidence: "Are mechanisms in place to inventory AI systems and resourced according to organizational risk priorities?",
        evidence1: "Ensure that mechanisms are in place to inventory AI systems and are resourced according to organizational risk priorities.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 6.3,
        column15: "6.3.1",
        column16: "5.1\n6.2.3.3\n6.2.4.1\n6.2.4.3\n6.2.6.3\n6.3.1.1\n",
        column17: "4.3\n5.3\n6.3\n6.7.3",
        column18: "6.2.2"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 1",
        controlName: "Policies, processes, procedures, and practices across the organization related to the mapping, measuring, and managing of AI risks are in place, transparent, and implemented effectively.",
        subControlID: "GOVERN 1.7",
        subControlName: "Processes and procedures are in place for decommissioning and phasing out AI systems safely and in a manner that does not increase risks or decrease the organization’s trustworthiness.",
        evidence: "Are processes and procedures in place for decommissioning and phasing out AI systems safely and in a manner that does not increase risks or decrease the organization’s trustworthiness?",
        evidence1: "Ensure that processes and procedures are in place for decommissioning and phasing out AI systems safely and in a manner that does not increase risks or decrease the organization’s trustworthiness.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: 8.1,
        column16: "5.3\n6.3.3.3\n6.4.8.2\n6.4.8.3\n6.4.17.1\n",
        column17: "4.3\n5.3",
        column18: "6.1\n6.2.9"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 2",
        controlName: "Accountability structures are in place so that the appropriate teams and individuals are empowered, responsible, and trained for mapping, measuring, and managing AI risks.",
        subControlID: "GOVERN 2.1",
        subControlName: "Roles and responsibilities and lines of communication related to mapping, measuring, and managing AI risks are documented and are clear to individuals and teams throughout the organization.",
        evidence: "Are roles and responsibilities and lines of communication related to mapping, measuring, and managing AI risks documented and are clear to individuals and teams throughout the organization?",
        evidence1: "Ensure that roles and responsibilities and lines of communication related to mapping, measuring, and managing AI risks are documented and are clear to individuals and teams throughout the organization.",
        column9: "X",
        column10: "X",
        column11: "X",
        column13: 6.1,
        column14: 6.1,
        column15: "5.4.5 - ISO 3100:2018\n6.2 ISO 3100:2015",
        column16: "6.2.4.3\n6.4.2.3\n6.4.7.3\n6.4.9.4",
        column17: "4.1\n6.2\n6.3\n6.7.1\n6.7.3\nA2",
        column18: 5.19
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 2",
        controlName: "Accountability structures are in place so that the appropriate teams and individuals are empowered, responsible, and trained for mapping, measuring, and managing AI risks.",
        subControlID: "GOVERN 2.2",
        subControlName: "The organization’s personnel and partners receive AI risk management training to enable them to perform their duties and responsibilities consistent with related policies, procedures, and agreements.",
        evidence: "Do the organization’s personnel and partners receive AI risk management training to enable them to perform their duties and responsibilities consistent with related policies, procedures, and agreements?",
        evidence1: "Ensure that the organization’s personnel and partners receive AI risk management training to enable them to perform their duties and responsibilities consistent with related policies, procedures, and agreements.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 5.4,
        column15: "5.4.1 - ISO 31000:2018",
        column16: "6.4.12.1",
        column17: "4.1\n4.3\n5.1\n6.7.2\n6.7.5\nA.1.3",
        column18: "6.2.2"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 2",
        controlName: "Accountability structures are in place so that the appropriate teams and individuals are empowered, responsible, and trained for mapping, measuring, and managing AI risks.",
        subControlID: "GOVERN 2.3",
        subControlName: "Executive leadership of the organization takes responsibility for decisions about risks associated with AI system development and deployment.",
        evidence: "Does executive leadership of the organization take responsibility for decisions about risks associated with AI system development and deployment?",
        evidence1: "Ensure that executive leadership of the organization takes responsibility for decisions about risks associated with AI system development and deployment.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: "A.2.2.3",
        column15: 5.5,
        column16: "6.3.4.3\n6.4.3.3",
        column17: "6.6.1",
        column18: "6.2.2"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 3",
        controlName: "Workforce diversity, equity, inclusion, and accessibility processes are prioritized in the mapping, measuring, and managing of AI risks throughout the lifecycle.",
        subControlID: "GOVERN 3.1",
        subControlName: "Decision-making related to mapping, measuring, and managing AI risks throughout the lifecycle is informed by a diverse team (e.g., diversity of demographics, disciplines, experience, expertise, and backgrounds).",
        evidence: "Are decision-making related to mapping, measuring, and managing AI risks throughout the lifecycle informed by a diverse team (e.g., diversity of demographics, disciplines, experience, expertise, and backgrounds)?",
        evidence1: "Ensure that decision-making related to mapping, measuring, and managing AI risks throughout the lifecycle is informed by a diverse team (e.g., diversity of demographics, disciplines, experience, expertise, and backgrounds).",
        column9: "X",
        column10: "X",
        column11: "X",
        column13: 7.1,
        column14: 7.2,
        column15: "6.2 - ISO 31000:2018",
        column16: "6.3.3",
        column17: "6.1\n6.3\n6.5\n6.7.1\n6.7.2\n6.7.3\nAnnex A",
        column18: "6.2.2"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 3",
        controlName: "Workforce diversity, equity, inclusion, and accessibility processes are prioritized in the mapping, measuring, and managing of AI risks throughout the lifecycle.",
        subControlID: "GOVERN 3.2",
        subControlName: "Policies and procedures are in place to define and differentiate roles and responsibilities for human-AI configurations and oversight of AI systems.",
        evidence: "Are policies and procedures in place to define and differentiate roles and responsibilities for human-AI configurations and oversight of AI systems?",
        evidence1: "Ensure that policies and procedures are in place to define and differentiate roles and responsibilities for human-AI configurations and oversight of AI systems.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 7.1,
        column14: 8.1,
        column15: "5.4.3 - ISO 31000:2018",
        column16: 5.3,
        column17: "4.3\n6.2\n6.6.2\n6.7.4\n",
        column18: "3.1.5\n3.1.16\n3.3.3\n5.1\n5.13\n7.1\n7.4.3"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 4",
        controlName: "Organizational teams are committed to a culture that considers and communicates AI risk.",
        subControlID: "GOVERN 4.1",
        subControlName: "Organizational policies and practices are in place to foster a critical thinking and safety-first mindset in the design, development, deployment, and uses of AI systems to minimize potential negative impacts.",
        evidence: "Are organizational policies and practices in place to foster a critical thinking and safety-first mindset in the design, development, deployment, and uses of AI systems to minimize potential negative impacts?",
        evidence1: "Ensure that organizational policies and practices are in place to foster a critical thinking and safety-first mindset in the design, development, deployment, and uses of AI systems to minimize potential negative impacts.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 5.3,
        column14: 8.1,
        column15: "6.3.3\n6.4.2.6\n6.4.3.2",
        column16: "6.3.4.3\n6.4.3.3\n6.4.17.1",
        column17: "4.3\n6.7.3",
        column18: "3.5.16\n5.15.2\n5.15.3\nAnnex A"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 4",
        controlName: "Organizational teams are committed to a culture that considers and communicates AI risk.",
        subControlID: "GOVERN 4.2",
        subControlName: "Organizational teams document the risks and potential impacts of the AI technology they design, develop, deploy, evaluate, and use, and they communicate about the impacts more broadly.",
        evidence: "Do organizational teams document the risks and potential impacts of the AI technology they design, develop, deploy, evaluate, and use, and do they communicate about the impacts more broadly?",
        evidence1: "Ensure that organizational teams document the risks and potential impacts of the AI technology they design, develop, deploy, evaluate, and use, and they communicate about the impacts more broadly.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 7.2,
        column14: "3.1.4",
        column15: 5.2,
        column16: "6.3.8.3\n6.4.2.3",
        column17: "4.2\n4.3\n5.5\n6.1\n6.3\n6.5\n6.6.1\n6.7.2\n6.7.4\n6.7.5",
        column18: 5.18
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 4",
        controlName: "Organizational teams are committed to a culture that considers and communicates AI risk.",
        subControlID: "GOVERN 4.3",
        subControlName: "Organizational practices are in place to enable AI testing, identification of incidents, and information sharing.",
        evidence: "Are organizational practices in place to enable AI testing, identification of incidents, and information sharing?",
        evidence1: "Ensure that organizational practices are in place to enable AI testing, identification of incidents, and information sharing.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.10.2.5",
        column14: "3.1.4\n6.4\n7.2\n8.2\n8.4\n8.5",
        column15: "6.4.2.4",
        column16: "6.4.15.3\n6.4.16.1",
        column17: "4.3\n6.7.4",
        column18: "3.5.10\n5.15.4\n6.2.6"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 5",
        controlName: "Processes are in place for robust engagement with relevant AI actors.",
        subControlID: "GOVERN 5.1",
        subControlName: "Organizational policies and practices are in place to collect, consider, prioritize, and integrate feedback from those external to the team that developed or deployed the AI system regarding the potential individual and societal impacts related to AI risks.",
        evidence: "Are organizational policies and practices in place to collect, consider, prioritize, and integrate feedback from those external to the team that developed or deployed the AI system regarding the potential individual and societal impacts related to AI risks?",
        evidence1: "Ensure that organizational policies and practices are in place to collect, consider, prioritize, and integrate feedback from those external to the team that developed or deployed the AI system regarding the potential individual and societal impacts related to AI risks.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 9.2,
        column14: "7.6\n",
        column15: "5.4.5 - ISO 3100:2018\n6.2 - ISO 3100:2015",
        column16: "5.1\n6.4.3.3",
        column17: "4.3\n5.1\n5.2.3\n5.4\n5.5\n6.5\n6.7.2\nA.1.3\nA.1.4\nA.3",
        column18: "5.11.4\n5.18\n8.6.2"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 5",
        controlName: "Processes are in place for robust engagement with relevant AI actors.",
        subControlID: "GOVERN 5.2",
        subControlName: "Mechanisms are established to enable the team that developed or deployed AI systems to regularly incorporate adjudicated feedback from relevant AI actors into system design and implementation.",
        evidence: "Are mechanisms established to enable the team that developed or deployed AI systems to regularly incorporate adjudicated feedback from relevant AI actors into system design and implementation?",
        evidence1: "Ensure that mechanisms are established to enable the team that developed or deployed AI systems to regularly incorporate adjudicated feedback from relevant AI actors into system design and implementation.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.4.2\n9.10.5",
        column14: "8.1\n8.4\n8.5",
        column15: "5.4.5 - ISO 3100:2018\n6.2 - ISO 3100:2015",
        column16: "5.1\n5.3\n6.3.5.3\n6.4.5\n6.4.9.1\n6.4.12.3\n6.4.15.3\n6.4.16.3",
        column17: "5.2.3\n5.4",
        column18: "5.11.4\n5.18\n8.6.2"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 6",
        controlName: "Policies and procedures are in place to address AI risks and benefits arising from third-party software and data and other supply chain issues.",
        subControlID: "GOVERN 6.1",
        subControlName: "Policies and procedures are in place that address AI risks associated with third-party entities, including risks of infringement of a third-party’s intellectual property or other rights.",
        evidence: "Are policies and procedures in place that address AI risks associated with third-party entities, including risks of infringement of a third-party’s intellectual property or other rights?",
        evidence1: "Ensure that policies and procedures are in place that address AI risks associated with third-party entities, including risks of infringement of a third-party’s intellectual property or other rights.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 5.5,
        column15: "5.4.1 - ISO 31000:2018",
        column16: "6.4.3.3\n",
        column17: "5.5\n6.4\nA.3",
        column18: "8.6.1"
    },
    {
        thrustArea: "GOVERN",
        controlID: "GOVERN 6",
        controlName: "Policies and procedures are in place to address AI risks and benefits arising from third-party software and data and other supply chain issues.",
        subControlID: "GOVERN 6.2",
        subControlName: "Contingency processes are in place to handle failures or incidents in third-party data or AI systems deemed to be high-risk.",
        evidence: "Are contingency processes in place to handle failures or incidents in third-party data or AI systems deemed to be high-risk?",
        evidence1: "Ensure that contingency processes are in place to handle failures or incidents in third-party data or AI systems deemed to be high-risk.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 7.1,
        column14: 8.3,
        column15: "5.4.1 - ISO 31000:2018",
        column16: "6.4.15.3\n",
        column17: "6.7.5",
        column18: "5.15.4"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 1",
        controlName: "Context is established and understood.",
        subControlID: "MAP 1.1",
        subControlName: "Intended purposes, potentially beneficial uses, context specific laws, norms and expectations, and prospective settings in which the AI system will be deployed are understood and documented.\n\nConsiderations include: the specific set or types of users along with their expectations; potential positive and negative impacts of system uses to individuals, communities, organizations, society, and the planet; assumptions and related limitations about AI system purposes, uses, and risks across the development or product AI lifecycle; and related Test & Evaluation, Validation & Verification (TEVV) and system metrics.",
        evidence: "Are intended purposes, potentially beneficial uses, context specific laws, norms and expectations, and prospective settings in which the AI system will be deployed understood and documented?\n\nDo considerations include: the specific set or types of users along with their expectations; potential positive and negative impacts of system uses to individuals, communities, organizations, society, and the planet; assumptions and related limitations about AI system purposes, uses, and risks across the development or product AI lifecycle; and related Test & Evaluation, Validation & Verification (TEVV) and system metrics?",
        evidence1: "Ensure that intended purposes, potentially beneficial uses, context specific laws, norms and expectations, and prospective settings in which the AI system will be deployed are understood and documented.\n\nEnsure that considerations include: the specific set or types of users along with their expectations; potential positive and negative impacts of system uses to individuals, communities, organizations, society, and the planet; assumptions and related limitations about AI system purposes, uses, and risks across the development or product AI lifecycle; and related Test & Evaluation, Validation & Verification (TEVV) and system metrics.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.10.2.6",
        column14: "6.3\n6.5.1\n6.5.5\n8.2\n8.5\n8.8\n",
        column15: "6.4.2.6\n6.5.2 - ISO 31000:2018\n",
        column16: "5.2\n6.3.4.3\n6.4.2.1\n6.4.13.1\n6.4.15.3\n6.4.17.1\nA.2.5",
        column17: "4.2\n4.3\n5.3\n6.4\n6.7.4\n6.7.5",
        column18: "5.13\n5.15.2\n5.16\n5.17\n6.2.2\n7.1"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 1",
        controlName: "Context is established and understood.",
        subControlID: "MAP 1.2",
        subControlName: "Interdisciplinary AI actors, competencies, skills, and capacities for establishing context reflect demographic diversity and broad domain and user experience expertise, and their participation is documented. Opportunities for interdisciplinary collaboration are prioritized.",
        evidence: "Are interdisciplinary AI actors, competencies, skills, and capacities for establishing context reflect demographic diversity and broad domain and user experience expertise, and their participation documented? \n\nAre opportunities for interdisciplinary collaboration prioritized?",
        evidence1: "Ensure that interdisciplinary AI actors, competencies, skills, and capacities for establishing context reflect demographic diversity and broad domain and user experience expertise, and their participation is documented. \n\nEnsure that opportunities for interdisciplinary collaboration are prioritized.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column13: 9.8,
        column16: "5.1\n6.2.3.3\n6.2.4.1\n6.2.4.3\n6.2.6.1\n6.2.6.3\n6.4.9.4\n",
        column17: "A.1.3",
        column18: "5.1\n6.2.2"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 1",
        controlName: "Context is established and understood.",
        subControlID: "MAP 1.3",
        subControlName: "The organization’s mission and relevant goals for AI technology are understood and documented.",
        evidence: "Are the organization’s mission and relevant goals for AI technology understood and documented?",
        evidence1: "Ensure that the organization’s mission and relevant goals for AI technology are understood and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.10.2.1",
        column14: "6.2.1\n6.3\n7.6\n8.1\n8.8",
        column16: "6.4.1\n6.4.13.1\nA.2.5",
        column17: "4.1\nA.1.1\nA.1.4\nA.2",
        column18: "6.2.2"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 1",
        controlName: "Context is established and understood.",
        subControlID: "MAP 1.4",
        subControlName: "The business value or context of business use has been clearly defined or – in the case of assessing existing AI systems – re-evaluated.",
        evidence: "Have the business value or context of business use been clearly defined or – in the case of assessing existing AI systems – re-evaluated?",
        evidence1: "Ensure that the business value or context of business use has been clearly defined or – in the case of assessing existing AI systems – re-evaluated.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "6.2\n8.8.2.5",
        column14: "8.2\n",
        column15: "5.4.1\n5.4.2",
        column16: "6.1.1.3\n6.2.3.3\n6.2.4.1\n6.3.2.1\n6.4.1",
        column17: "5.4\nA.1.2\nA.3",
        column18: "5.10\n6.2.2\n7.1\n7.4.1"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 1",
        controlName: "Context is established and understood.",
        subControlID: "MAP 1.5",
        subControlName: "Organizational risk tolerances are determined and documented.",
        evidence: "Are organizational risk tolerances determined and documented?",
        evidence1: "Ensure that organizational risk tolerances are determined and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 5.4,
        column15: "5.2 - ISO 31000:2018\n6.7 - ISO 31000:2018",
        column16: "6.3.4",
        column17: "4.2\n4.3\n5.5\n6.6.1\n6.7.1\n6.7.2\n6.7.3\n6.7.5\nA.1.4\nA.2",
        column18: "5.18\n6.1\n6.2.2\n6.2.3\n6.2.4\n6.2.5\n6.2.6\n6.2.7\n6.2.8\n9.4\nAnnex A"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 1",
        controlName: "Context is established and understood.",
        subControlID: "MAP 1.6",
        subControlName: "System requirements (e.g., “the system shall respect the privacy of its users”) are elicited from and understood by relevant AI actors. \n\nDesign decisions take socio-technical implications into account to address AI risks.",
        evidence: "Are system requirements (e.g., “the system shall respect the privacy of its users”) elicited from and understood by relevant AI actors?\n\nAre design decisions take socio-technical implications into account to address AI risks?",
        evidence1: "Ensure that system requirements (e.g., “the system shall respect the privacy of its users”) are elicited from and understood by relevant AI actors. \n\nEnsure that design decisions take socio-technical implications into account to address AI risks.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 9.5,
        column14: 8.3,
        column15: "5.4.1 - ISO 31000:2018",
        column16: "6.3.4.3\n6.4.1.3\n6.4.3.3\n6.4.8.3\n6.4.17.3",
        column17: "5.3\n6.4\n6.6.2\n6.7.2\n6.7.3\n6.7.5\nA.3",
        column18: "3.2.11\n3.5.14\n3.5.15\n3.5.16\n5.15.8\n5.19\n6.2\n6.1\n6.2.2\n8.6.2\nAnnex A"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 2",
        controlName: "Categorization of the AI system is performed.",
        subControlID: "MAP 2.1",
        subControlName: "The specific tasks and methods used to implement the tasks that the AI system will support are defined (e.g., classifiers, generative models, recommenders).",
        evidence: "Are the specific tasks and methods used to implement the tasks that the AI system will support defined (e.g., classifiers, generative models, recommenders)?",
        evidence1: "Ensure that the specific tasks and methods used to implement the tasks that the AI system will support are defined (e.g., classifiers, generative models, recommenders).",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "5.3\n9.7",
        column14: "6.1\n6.2\n6.5.3.2.8\n6.5.5.1\n6.5.5.6\n7.3",
        column16: "5.1\n5.4.2\n6.1.1.3\n6.1.2.3\n6.2.3.3\n6.2.4.3\n6.2.5.3\n6.2.6.3\n6.3.1.3\n6.3.2.3\n6.3.3.3\n6.4.8.3",
        column17: "3.2.1\n5.2.1\n5.4\n6.3\nA.1.4",
        column18: "3.1.14\n3.1.24\n3.1.29\n3.1.35\n5.1\n5.2\n5.5\n5.11.1\n5.19.5.1\n7.2\n8.2\n8.6.2\n8.7.2\n9.1\n9.2.2.1\n10.1"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 2",
        controlName: "Categorization of the AI system is performed.",
        subControlID: "MAP 2.2",
        subControlName: "Information about the AI system’s knowledge limits and how system output may be utilized and overseen by humans is documented. \n\nDocumentation provides sufficient information to assist relevant AI actors when making decisions and taking subsequent actions.",
        evidence: "Is information about the AI system’s knowledge limits and how system output may be utilized and overseen by humans documented?\n\nDoes documentation provides sufficient information to assist relevant AI actors when making decisions and taking subsequent actions?",
        evidence1: "Ensure that information about the AI system’s knowledge limits and how system output may be utilized and overseen by humans is documented. \n\nEnsure that documentation provides sufficient information to assist relevant AI actors when making decisions and taking subsequent actions.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.11.3",
        column14: "6.5.3.2.7",
        column16: 5.1,
        column17: "5.1\n5.2.1\n5.2.3\n6.3\n6.5\n6.6.1",
        column18: "5.4\n5.15.8\n7.3\n8.1\n8.5.1\n8.5.2"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 2",
        controlName: "Categorization of the AI system is performed.",
        subControlID: "MAP 2.3",
        subControlName: "Scientific integrity and Test & Evaluation, Validation & Verification (TEVV) considerations are identified and documented, including those related to experimental design, data collection and selection (e.g., availability, representativeness, suitability), system trustworthiness, and construct validation.",
        evidence: "Are scientific integrity and Test & Evaluation, Validation & Verification (TEVV) considerations identified and documented, including those related to experimental design, data collection and selection (e.g., availability, representativeness, suitability), system trustworthiness, and construct validation?",
        evidence1: "Ensure that scientific integrity and Test & Evaluation, Validation & Verification (TEVV) considerations are identified and documented, including those related to experimental design, data collection and selection (e.g., availability, representativeness, suitability), system trustworthiness, and construct validation.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 5.3,
        column14: "6.4\n6.5.3.1\n8.2\n8.4\n8.5\n8.6\n8.8\n",
        column16: "5.3\n6.3.1.3\n6.4.13\n6.4.14\nA.2.5\nA.3",
        column17: "4.3\n6.6.1\n6.6.2",
        column18: "3.5.17\n5.10\n5.11.9.2\n5.16\n6.1\n6.2.3\n6.2.4"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 3",
        controlName: "AI capabilities, targeted usage, goals, and expected benefits and costs compared with appropriate benchmarks are understood.",
        subControlID: "MAP 3.1",
        subControlName: "Potential benefits of intended AI system functionality and performance are examined and documented.",
        evidence: "Are potential benefits of intended AI system functionality and performance  examined and documented?",
        evidence1: "Ensure that potential benefits of intended AI system functionality and performance are examined and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: "3.1.4\n6.3\n6.4\n6.5.1\n6.5.3.1\n6.5.3.2\n6.5.5.6\n6.5.5.7\n6.5.5.8\n7.2\n7.4\n7.6\n8.2\n8.3\n8.4\n8.5\n8.7\n8.8",
        column16: 5.3,
        column17: "5.2.3\n5.3",
        column18: "3.1.29\n3.5.9\n3.5.18\n5.13\n5.15.1\n5.15.2\n5.16\n5.17\n6.2.2\n7.1"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 3",
        controlName: "AI capabilities, targeted usage, goals, and expected benefits and costs compared with appropriate benchmarks are understood.",
        subControlID: "MAP 3.2",
        subControlName: "Potential costs, including non-monetary costs, which result from expected or realized AI errors or system functionality and trustworthiness – as connected to organizational risk tolerance\n– are examined and documented.",
        evidence: "Are potential costs, including non-monetary costs, which result from expected or realized AI errors or system functionality and trustworthiness – as connected to organizational risk tolerance – examined and documented?",
        evidence1: "Ensure that potential costs, including non-monetary costs, which result from expected or realized AI errors or system functionality and trustworthiness – as connected to organizational risk tolerance – are examined and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "8.10\n9.8\n",
        column14: "6.5.4.1\n6.5.4.2\n7.4",
        column15: "6.4.2.6",
        column16: "6.4.3.3\n6.4.12.3\n6.4.13.3\n6.4.15.3\n6.4.16.3",
        column17: "4.2\n6.4\n",
        column18: "6.2.2\n6.2.6\n7.4.1"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 3",
        controlName: "AI capabilities, targeted usage, goals, and expected benefits and costs compared with appropriate benchmarks are understood.",
        subControlID: "MAP 3.3",
        subControlName: "Targeted application scope is specified and documented based on the system’s capability, established context, and AI system categorization.",
        evidence: "Is targeted application scope specified and documented based on the system’s capability, established context, and AI system categorization?",
        evidence1: "Ensure that targeted application scope is specified and documented based on the system’s capability, established context, and AI system categorization.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.3.4",
        column14: "6.5.3.2.1\n6.5.3.2.3.2\n8.4",
        column15: "6.3.2 - ISO 31000:2018\n6.3.3 - ISO 31000:2018",
        column16: "5.3\n6.2.3.3\n6.4.2.1\n6.4.12.1\n6.4.15.3\n6.4.16.1\nA.2.4\nA.2.7\n",
        column17: "5.5\n6.5\n6.6.2\n6.7.4",
        column18: "3.7.1\n8.7.2\n9.1"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 3",
        controlName: "AI capabilities, targeted usage, goals, and expected benefits and costs compared with appropriate benchmarks are understood.",
        subControlID: "MAP 3.4",
        subControlName: "Processes for operator and practitioner proficiency with AI system performance and trustworthiness – and relevant technical standards and certifications – are defined, assessed, and documented.",
        evidence: "Are processes for operator and practitioner proficiency with AI system performance and trustworthiness – and relevant technical standards and certifications – defined, assessed, and documented?",
        evidence1: "Ensure that processes for operator and practitioner proficiency with AI system performance and trustworthiness – and relevant technical standards and certifications – are defined, assessed, and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 6.3,
        column14: 8.1,
        column16: "5.3\n6.3.4.3\n6.4.11.1\n6.4.15.1",
        column17: "6.6.2",
        column18: "5.15.1\n5.15.2\n5.19.5.4\n5.19.5.5"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 3",
        controlName: "AI capabilities, targeted usage, goals, and expected benefits and costs compared with appropriate benchmarks are understood.",
        subControlID: "MAP 3.5",
        subControlName: "Processes for human oversight are defined, assessed, and documented in accordance with organizational policies from the GOVERN function.",
        evidence: "Are processes for human oversight defined, assessed, and documented in accordance with organizational policies from the GOVERN function?",
        evidence1: "Ensure that processes for human oversight are defined, assessed, and documented in accordance with organizational policies from the GOVERN function.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: 8.1,
        column15: "5.4.3 - ISO 31000:2018",
        column16: "6.3.4.3\n6.4.3.3\n6.4.14.1",
        column17: "3.2.1\n4.3\n5.1\n5.3\n5.4\n5.5\n6.2\n6.3\n6.5\n6.7.3\nA.1.1\nA.1.3\nA.1.4",
        column18: 5.1
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 4",
        controlName: "Risks and benefits are mapped for all components of the AI system including third-party software and data.",
        subControlID: "MAP 4.1",
        subControlName: "Approaches for mapping AI technology and legal risks of its components – including the use of third-party data or software – are in place, followed, and documented, as are risks of infringement of a third party’s intellectual property or other rights.",
        evidence: "Are approaches for mapping AI technology and legal risks of its components – including the use of third-party data or software – in place, followed, and documented, as are risks of infringement of a third party’s intellectual property or other rights?",
        evidence1: "Ensure that approaches for mapping AI technology and legal risks of its components – including the use of third-party data or software – are in place, followed, and documented, as are risks of infringement of a third party’s intellectual property or other rights.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 7.1,
        column15: "5.4.1 - ISO 31000:2018\n5.6 - ISO 31000:2018",
        column16: "6.1.1.3\n6.4.1.3\n6.4.3.3\n6.4.8.2\n6.4.8.3\n6.4.17.1",
        column17: "5.5\n6.4\nA.3",
        column18: "5.15.1\n5.17"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 4",
        controlName: "Risks and benefits are mapped for all components of the AI system including third-party software and data.",
        subControlID: "MAP 4.2",
        subControlName: "Internal risk controls for components of the AI system, including third-party AI technologies, are identified and documented.",
        evidence: "Are internal risk controls for components of the AI system, including third-party AI technologies, identified and documented?",
        evidence1: "Ensure that internal risk controls for components of the AI system, including third-party AI technologies, are identified and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 5.4,
        column15: "5.4.1 - ISO 31000:2018",
        column16: "6.4.8.3",
        column17: "6.7.2",
        column18: 6.1
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 5",
        controlName: "Impacts to individuals, groups, communities, organizations, and society are characterized.",
        subControlID: "MAP 5.1",
        subControlName: "Likelihood and magnitude of each identified impact (both potentially beneficial and harmful) based on expected use, past uses of AI systems in similar contexts, public incident reports, feedback from those external to the team that developed or deployed the AI system, or other data are identified and documented.",
        evidence: "Are likelihood and magnitude of each identified impact (both potentially beneficial and harmful) based on expected use, past uses of AI systems in similar contexts, public incident reports, feedback from those external to the team that developed or deployed the AI system, or other data identified and documented?",
        evidence1: "Ensure that likelihood and magnitude of each identified impact (both potentially beneficial and harmful) based on expected use, past uses of AI systems in similar contexts, public incident reports, feedback from those external to the team that developed or deployed the AI system, or other data are identified and documented.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.4.2\n9.10.5\n",
        column15: "6.3.4 - ISO 31000:2018\n6.4.2.6\n6.4.3.2\n6.4.3.3",
        column16: "6.3.8.3\n6.4.2.3",
        column17: "3.2.2\n6.7.3",
        column18: "5.15.7\n5.18"
    },
    {
        thrustArea: "MAP",
        controlID: "MAP 5",
        controlName: "Impacts to individuals, groups, communities, organizations, and society are characterized.",
        subControlID: "MAP 5.2",
        subControlName: "Practices and personnel for supporting regular engagement with relevant AI actors and integrating feedback about positive, negative, and unanticipated impacts are in place and documented.",
        evidence: "Are practices and personnel for supporting regular engagement with relevant AI actors and integrating feedback about positive, negative, and unanticipated impacts in place and documented?",
        evidence1: "Ensure that practices and personnel for supporting regular engagement with relevant AI actors and integrating feedback about positive, negative, and unanticipated impacts are in place and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.4.2\n9.10.5\n",
        column15: "ISO 31000:2018, Clause 4",
        column16: "6.3.8.3\n6.4.1.3",
        column17: "4.3\n5.1\n5.5\n6.7.2",
        column18: "5.18\n5.19"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 1",
        controlName: "Appropriate methods and metrics are identified and applied.",
        subControlID: "MEASURE 1.1",
        subControlName: "Approaches and metrics for measurement of AI risks enumerated during the MAP function are selected for implementation starting with the most significant AI risks. \n\nThe risks or trustworthiness characteristics that will not – or cannot – be measured are properly documented.",
        evidence: "Are approaches and metrics for measurement of AI risks enumerated during the MAP function selected for implementation starting with the most significant AI risks? \n\nAre the risks or trustworthiness characteristics that will not – or cannot – be measured properly documented?",
        evidence1: "Ensure that approaches and metrics for measurement of AI risks enumerated during the MAP function are selected for implementation starting with the most significant AI risks. \n\nEnsure that the risks or trustworthiness characteristics that will not – or cannot – be measured are properly documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "5.3\n5.4\n6.4\n",
        column14: 8.1,
        column16: "6.4.3.3\n6.4.13.3\n6.4.15.3\n6.4.16.3",
        column17: "4.3\n5.5\n6.4\n6.6.2\n6.7.2",
        column18: "5.15.2\n6.2.2\n8.1"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 1",
        controlName: "Appropriate methods and metrics are identified and applied.",
        subControlID: "MEASURE 1.2",
        subControlName: "Appropriateness of AI metrics and effectiveness of existing controls are regularly assessed and updated, including reports of errors and potential impacts on affected communities.",
        evidence: "Are appropriateness of AI metrics and effectiveness of existing controls regularly assessed and updated, including reports of errors and potential impacts on affected communities?",
        evidence1: "Ensure that appropriateness of AI metrics and effectiveness of existing controls are regularly assessed and updated, including reports of errors and potential impacts on affected communities.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 5.4,
        column14: "6.5.5",
        column16: "6.4.12.3\n6.4.13.3\n6.4.15.3\n6.4.16.3",
        column17: "4.3\n6.1\n6.4\n6.7.3",
        column18: "5.15.2\n6.2.2\n8.1"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 1",
        controlName: "Appropriate methods and metrics are identified and applied.",
        subControlID: "MEASURE 1.3",
        subControlName: "Internal experts who did not serve as front-line developers for the system and\/or independent assessors are involved in regular assessments and updates. \n\nDomain experts, users, AI actors external to the team that developed or deployed the AI system, and affected communities are consulted in support of assessments as necessary per organizational risk tolerance.",
        evidence: "Are internal experts who did not serve as front-line developers for the system and\/or independent assessors involved in regular assessments and updates?\n\nAre domain experts, users, AI actors external to the team that developed or deployed the AI system, and affected communities consulted in support of assessments as necessary per organizational risk tolerance?",
        evidence1: "Ensure that internal experts who did not serve as front-line developers for the system and\/or independent assessors are involved in regular assessments and updates. \n\nEnsure that domain experts, users, AI actors external to the team that developed or deployed the AI system, and affected communities are consulted in support of assessments as necessary per organizational risk tolerance.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 9.2,
        column15: "5.4.1 - ISO 31000:2018\n6.4.1 - ISO 31000:2018\n6.7",
        column16: "6.4.7.1\n6.4.7.3\n6.4.9.4",
        column17: "4.3\n5.2.3\n6.4",
        column18: "5.19.1\n5.19.5.1\n5.19.5.4\n6.2.8"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.1",
        subControlName: "Test sets, metrics, and details about the tools used during Test & Evaluation, Validation & Verification (TEVV) are documented.",
        evidence: "Are test sets, metrics, and details about the tools used during Test & Evaluation, Validation & Verification (TEVV) documented?",
        evidence1: "Ensure that test sets, metrics, and details about the tools used during Test & Evaluation, Validation & Verification (TEVV) are documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.10.2.5",
        column14: "6.5\n8.3\n8.8\nA.3.2",
        column15: "5.4.3",
        column16: "6.4.11.3",
        column17: "4.3\n6.6.1\n6.6.2",
        column18: "3.2.15\n5.11.8"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.2",
        subControlName: "Evaluations involving human subjects meet applicable requirements (including human subject protection) and are representative of the relevant population.",
        evidence: "Do evaluations involving human subjects meet applicable requirements (including human subject protection) and  representative of the relevant population?",
        evidence1: "Ensure that evaluations involving human subjects meet applicable requirements (including human subject protection) and are representative of the relevant population.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.10.2.6",
        column14: 8.2,
        column15: 6.6,
        column16: "6.4.2.3",
        column17: 6.5,
        column18: "5.19.6.2\n6.2.2"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.3",
        subControlName: "AI system performance or assurance criteria are measured qualitatively or quantitatively and demonstrated for conditions similar to deployment setting(s). \n\nMeasures are documented.",
        evidence: "Are AI system performance or assurance criteria measured qualitatively or quantitatively and demonstrated for conditions similar to deployment setting(s)? \n\nAre measures documented?",
        evidence1: "Ensure that AI system performance or assurance criteria are measured qualitatively or quantitatively and demonstrated for conditions similar to deployment setting(s). \n\nEnsure that measures are documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.10.2.6",
        column16: "6.3.8",
        column17: "6.6.2",
        column18: "3.1.25"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.4",
        subControlName: "The functionality and behavior of the AI system and its components – as identified in the MAP function – are monitored when in production.",
        evidence: "Are the functionality and behavior of the AI system and its components – as identified in the MAP function –  monitored when in production?",
        evidence1: "Ensure that the functionality and behavior of the AI system and its components – as identified in the MAP function – are monitored when in production.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "7.1\n8.8.3\n9.9",
        column14: 5,
        column16: "6.4.3.1",
        column17: "5.2.3",
        column18: "5.15.5\n5.16"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.5",
        subControlName: "The AI system to be deployed is demonstrated to be valid and reliable. \n\nLimitations of the generalizability beyond the conditions under which the technology was developed are documented.",
        evidence: "Is the AI system to be deployed demonstrated to be valid and reliable?\n\nAre limitations of the generalizability beyond the conditions under which the technology was developed documented?",
        evidence1: "Ensure that the AI system to be deployed is demonstrated to be valid and reliable. \n\nEnsure that limitations of the generalizability beyond the conditions under which the technology was developed are documented.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.7\n9.11.2",
        column16: "5.1\n6.3.4.3\n6.4.11\n6.4.13\n6.4.14\nA.2.5\nA.3\n\n",
        column17: "6.6.1",
        column18: "3.5.9\n3.5.16\n5.15.2\n5.15.3\n5.15.4"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.6",
        subControlName: "The AI system is evaluated regularly for safety risks – as identified in the MAP function. The AI system to be deployed is demonstrated to be safe, its residual negative risk does not exceed the risk tolerance, and it can fail safely, particularly if made to operate beyond its knowledge limits. Safety metrics reflect system reliability and robustness, real-time monitoring, and response times for AI system failures.",
        evidence: "Is the AI system evaluated regularly for safety risks – as identified in the MAP function? \n\nIs the AI system to be deployed demonstrated to be safe, its residual negative risk does not exceed the risk tolerance, and it can fail safely, particularly if made to operate beyond its knowledge limits. \n\nDoes safety metrics reflect system reliability and robustness, real-time monitoring, and response times for AI system failures\/",
        evidence1: "Ensure that the AI system is evaluated regularly for safety risks – as identified in the MAP function. \n\nEnsure that the AI system to be deployed is demonstrated to be safe, its residual negative risk does not exceed the risk tolerance, and it can fail safely, particularly if made to operate beyond its knowledge limits. \n\nEnsure that safety metrics reflect system reliability and robustness, real-time monitoring, and response times for AI system failures.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "7.2\n9.9\n9.11.3",
        column14: 8.1,
        column15: "5.6 - ISO 31000:2018",
        column16: "6.3.4.3",
        column17: "6.7.2\n6.7.3",
        column18: "Annex A"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.7",
        subControlName: "AI system security and resilience – as identified in the MAP function – are evaluated and documented.",
        evidence: "Are AI system security and resilience – as identified in the MAP function – evaluated and documented?",
        evidence1: "Ensure that AI system security and resilience – as identified in the MAP function – are evaluated and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "8.2\n9.7\n5.4",
        column14: 8.1,
        column15: "5.4.1 - ISO 31000:2018",
        column16: "6.4.3.3",
        column17: "4.3\n5.3\n6.4\n6.6.2\n6.7.2\n6.7.3\n6.7.5",
        column18: "3.5.10\n3.5.16\n5.15.2\n5.15.3\n5.15.4"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.8",
        subControlName: "Risks associated with transparency and accountability – as identified in the MAP function – are examined and documented.",
        evidence: "Are risks associated with transparency and accountability – as identified in the MAP function – examined and documented?",
        evidence1: "Ensure that risks associated with transparency and accountability – as identified in the MAP function – are examined and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "7.1\n9.2",
        column14: 8.1,
        column15: "5.4.1 - ISO 31000:2018",
        column16: "5.3\n6.3.4.3\n6.3.8.3\n6.4.3.3\n6.4.9.3\n6.4.14.3",
        column17: "4.1\n4.2\n4.3\n5.3\n6.2\n6.3\n6.6.1\n6.7.2\n6.7.3\n6.7.4\nA.1.3",
        column18: "3.5.2\n3.5.14\n3.5.15\n3.5.16\n5.15.8\n5.18\n6.1\n6.2.2\n7.4.1\n"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.9",
        subControlName: "The AI model is explained, validated, and documented, and AI system output is interpreted within its context – as identified in the MAP function – to inform responsible use and governance.",
        evidence: "Is the AI model explained, validated, and documented, and AI system output interpreted within its context – as identified in the MAP function – to inform responsible use and governance?",
        evidence1: "Ensure that the AI model is explained, validated, and documented, and AI system output is interpreted within its context – as identified in the MAP function – to inform responsible use and governance.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.3.4\n9.3.6\n9.10.2",
        column14: 8.1,
        column16: "6.4.3.3\n6.4.9.3",
        column17: "4.2\n5.5\n6.4\n6.7.1\n6.7.3\n6.7.4\n",
        column18: "3.5.7\n5.15.6\n6.1"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.10",
        subControlName: "Privacy risk of the AI system – as identified in the MAP function – is examined and documented.",
        evidence: "Is privacy risk of the AI system – as identified in the MAP function – examined and documented?",
        evidence1: "Ensure that privacy risk of the AI system – as identified in the MAP function – is examined and documented.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.6\n9.10.4",
        column14: 8.1,
        column15: "5.4.1 - ISO 31000:2018",
        column16: "5.1\n6.3.4.3\n6.4.1.3\n6.4.3.3\n6.4.8.3\n6.4.17.3\n",
        column17: "5.3\n6.4\n6.6.2\n6.7.2\n6.7.3\n6.7.5\nA.3",
        column18: "5.19.6.2\n6.1\n6.2.2\n8.6.2\nAnnex A"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.11",
        subControlName: "Fairness and bias – as identified in the MAP function – are evaluated and results are documented.",
        evidence: "Are fairness and bias – as identified in the MAP function – evaluated and results are documented?",
        evidence1: "Ensure that fairness and bias – as identified in the MAP function – are evaluated and results are documented.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: 8.4,
        column14: "6.3\n8.1\n8.3\nA.2.2.2",
        column15: "5.4.1 - ISO 31000:2018",
        column16: "5.1\n6.3.4.3\n6.3.8.3\n6.4.3.3\n6.4.14.3\n",
        column17: "4.2\n5.4\n6.4\n6.5\n6.7.2\n6.7.4\n",
        column18: "3.2.2\n3.5.4\n5.10\n5.15.9\n7.4.1\nAnnex A\n"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.12",
        subControlName: "Environmental impact and sustainability of AI model training and management activities – as identified in the MAP function – are assessed and documented.",
        evidence: "Are environmental impact and sustainability of AI model training and management activities – as identified in the MAP function – assessed and documented?",
        evidence1: "Ensure that environmental impact and sustainability of AI model training and management activities – as identified in the MAP function – are assessed and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "5.5\n8.7\n9.10.5",
        column14: "6.2.7\n",
        column15: "6.4.2\n6.4.3",
        column16: "6.4.2.3\n6.4.17.1",
        column17: "6.5\n6.7.4",
        column18: "5.15.2\n6.2.2"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 2",
        controlName: "AI systems are evaluated for trustworthy characteristics.",
        subControlID: "MEASURE 2.13",
        subControlName: "Effectiveness of the employed Test & Evaluation, Validation & Verification (TEVV) metrics and processes in the MEASURE function are evaluated and documented.",
        evidence: "Is effectiveness of the employed Test & Evaluation, Validation & Verification (TEVV) metrics and processes in the MEASURE function evaluated and documented?",
        evidence1: "Ensure that effectiveness of the employed Test & Evaluation, Validation & Verification (TEVV) metrics and processes in the MEASURE function are evaluated and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.10.2.3\n",
        column14: 8.2,
        column15: "5.4.4",
        column16: "6.3.8",
        column17: "4.3\n6.6.1\n6.6.2",
        column18: "6.2.6"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 3",
        controlName: "Mechanisms for tracking identified AI risks over time are in place.",
        subControlID: "MEASURE 3.1",
        subControlName: "Approaches, personnel, and documentation are in place to regularly identify and track existing, unanticipated, and emergent AI risks based on factors such as intended and actual performance in deployed contexts.",
        evidence: "Are approaches, personnel, and documentation in place to regularly identify and track existing, unanticipated, and emergent AI risks based on factors such as intended and actual performance in deployed contexts?",
        evidence1: "Ensure that approaches, personnel, and documentation are in place to regularly identify and track existing, unanticipated, and emergent AI risks based on factors such as intended and actual performance in deployed contexts.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.10.2.5",
        column14: 8.7,
        column16: "6.4.3.3\n6.4.12.3\n6.4.13.3\n6.4.15.3\n6.4.16.1",
        column17: 6.7,
        column18: "3.5.11\n5.18\n6.1\n6.2.2\n6.2.3\n6.2.4\n6.2.5\n6.2.6\n6.2.7\n6.2.8\n7.4.3\nAnnex A"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 3",
        controlName: "Mechanisms for tracking identified AI risks over time are in place.",
        subControlID: "MEASURE 3.2",
        subControlName: "Risk tracking approaches are considered for settings where AI risks are difficult to assess using currently available measurement techniques or where metrics are not yet available.",
        evidence: "Are risk tracking approaches considered for settings where AI risks are difficult to assess using currently available measurement techniques or where metrics are not yet available?",
        evidence1: "Ensure that risk tracking approaches are considered for settings where AI risks are difficult to assess using currently available measurement techniques or where metrics are not yet available.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: 8.1,
        column16: "6.4.3.3\n6.4.11.3\n6.4.13.3\n6.4.15.3\n6.4.16.3",
        column17: "5.3\n6.7",
        column18: "6.1\n6.2.4\n6.2.6\n6.2.8"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 3",
        controlName: "Mechanisms for tracking identified AI risks over time are in place.",
        subControlID: "MEASURE 3.3",
        subControlName: "Feedback processes for end users and impacted communities to report problems and appeal system outcomes are established and integrated into AI system evaluation metrics.",
        evidence: "Are feedback processes for end users and impacted communities to report problems and appeal system outcomes established and integrated into AI system evaluation metrics?",
        evidence1: "Ensure that feedback processes for end users and impacted communities to report problems and appeal system outcomes are established and integrated into AI system evaluation metrics.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.4.2",
        column14: 7.6,
        column16: "6.4.2.3\n6.4.15.3",
        column17: "5.2.3\n5.4",
        column18: "5.19.6.3"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 4",
        controlName: "Feedback about efficacy of measurement is gathered and assessed.",
        subControlID: "MEASURE 4.1",
        subControlName: "Measurement approaches for identifying AI risks are connected to deployment context(s) and informed through consultation with domain experts and other end users. Approaches are documented.",
        evidence: "Are measurement approaches for identifying AI risks connected to deployment context(s) and informed through consultation with domain experts and other end users?\n\nAre approaches documented?",
        evidence1: "Ensure that measurement approaches for identifying AI risks are connected to deployment context(s) and informed through consultation with domain experts and other end users. \n\nEnsure that approaches are documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.4.2",
        column15: "5.4.5",
        column16: "6.4.2.1",
        column17: "4.1\n4.3\n5.5\n6.1\n6.2\n6.4\n6.5\n6.6.1\n6.7.1\nA.1.3\nA.3",
        column18: "6.2.6"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 4",
        controlName: "Feedback about efficacy of measurement is gathered and assessed.",
        subControlID: "MEASURE 4.2",
        subControlName: "Measurement results regarding AI system trustworthiness in deployment context(s) and across the AI lifecycle are informed by input from domain experts and relevant AI actors to validate whether the system is performing consistently as intended. \n\nResults are documented.",
        evidence: "Are measurement results regarding AI system trustworthiness in deployment context(s) and across the AI lifecycle  informed by input from domain experts and relevant AI actors to validate whether the system is performing consistently as intended? \n\nAre results documented?",
        evidence1: "Ensure that measurement results regarding AI system trustworthiness in deployment context(s) and across the AI lifecycle are informed by input from domain experts and relevant AI actors to validate whether the system is performing consistently as intended. \n\nEnsure that results are documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column13: "9.10.2",
        column14: 7.7,
        column16: "6.4.7.1\n6.4.7.3\n6.4.9.4",
        column17: "4.1\n4.3\n5.5\n6.1\n6.2\n6.4\n6.5\n6.6.1\n6.7.1\nA.1.3\nA.3",
        column18: "6.2.6"
    },
    {
        thrustArea: "MEASURE",
        controlID: "MEASURE 4",
        controlName: "Feedback about efficacy of measurement is gathered and assessed.",
        subControlID: "MEASURE 4.3",
        subControlName: "Measurable performance improvements or declines based on consultations with relevant AI actors, including affected communities, and field data about context relevant risks and trustworthiness characteristics are identified and documented.",
        evidence: "Are measurable performance improvements or declines based on consultations with relevant AI actors, including affected communities, and field data about context relevant risks and trustworthiness characteristics identified and documented?",
        evidence1: "Ensure that measurable performance improvements or declines based on consultations with relevant AI actors, including affected communities, and field data about context relevant risks and trustworthiness characteristics are identified and documented.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: 7.2,
        column15: "5.4.5 - ISO 31000:2018\n6.2 - ISO 31000:2018",
        column16: "6.2.5.3\n6.3.1.3\n6.3.5.3\n6.4.3.1\n6.4.3.3\n6.4.9.3\n6.4.12.3\n6.4.13.3\n6.4.14.1\n6.4.14.3\n6.4.15.1\n",
        column17: 6.1,
        column18: "6.1\n6.2.7"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 1",
        controlName: "AI risks based on assessments and other analytical output from the MAP and MEASURE functions are prioritized, responded to, and managed.",
        subControlID: "MANAGE 1.1",
        subControlName: "A determination is made as to whether the AI system achieves its intended purposes and stated objectives and whether its development or deployment should proceed.",
        evidence: "Is a determination made as to whether the AI system achieves its intended purposes and stated objectives and whether its development or deployment should proceed?",
        evidence1: "Ensure that a determination is made as to whether the AI system achieves its intended purposes and stated objectives and whether its development or deployment should proceed.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: "6.5.3.5\n7.6\n8.5",
        column15: "6.4.3.2\n6.7",
        column16: "6.4.13.1\n6.4.17.1\nA.2.5",
        column17: "4.2\n4.3\n5.3\n6.4\n6.7.4\n6.7.5",
        column18: "3.1.35\n5.3\n5.11.4\n5.14.3\n6.1\n6.2.8\n7.1\n8.2\n8.3"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 1",
        controlName: "AI risks based on assessments and other analytical output from the MAP and MEASURE functions are prioritized, responded to, and managed.",
        subControlID: "MANAGE 1.2",
        subControlName: "Treatment of documented AI risks is prioritized based on impact, likelihood, and available resources or methods.",
        evidence: "Is treatment of documented AI risks prioritized based on impact, likelihood, and available resources or methods?",
        evidence1: "Ensure that treatment of documented AI risks is prioritized based on impact, likelihood, and available resources or methods.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: 8.1,
        column15: "6.1 - ISO 31000:2018\n6.4.3.3\n6.5.1 - ISO 31000:2018\n6.5.2 - ISO 310008:2018\n6.5.3 - ISO 31000:2018",
        column16: "6.3.4.3",
        column17: "6.4\n6.7",
        column18: "6.1\n6.2.2\n6.2.3\n6.2.4\n6.2.5\n6.2.8"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 1",
        controlName: "AI risks based on assessments and other analytical output from the MAP and MEASURE functions are prioritized, responded to, and managed.",
        subControlID: "MANAGE 1.3",
        subControlName: "Responses to the AI risks deemed high priority, as identified by the MAP function, are developed, planned, and documented. \n\nRisk response options can include mitigating, transferring, avoiding, or accepting.",
        evidence: "Are responses to the AI risks deemed high priority, as identified by the MAP function, developed, planned, and documented?\n\nDoes risk response options include mitigating, transferring, avoiding, or accepting?",
        evidence1: "Ensure that responses to the AI risks deemed high priority, as identified by the MAP function, are developed, planned, and documented. \n\nEnsure that risk response options include mitigating, transferring, avoiding, or accepting.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: 8.1,
        column15: "6.5.2 - ISO 31000:2018\n6.7 - ISO 31000:2018",
        column16: "6.3.4.3",
        column17: "6.5\n6.7.5",
        column18: "Annex A"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 1",
        controlName: "AI risks based on assessments and other analytical output from the MAP and MEASURE functions are prioritized, responded to, and managed.",
        subControlID: "MANAGE 1.4",
        subControlName: "Negative residual risks (defined as the sum of all unmitigated risks) to both downstream acquirers of AI systems and end users are documented.",
        evidence: "Are negative residual risks (defined as the sum of all unmitigated risks) to both downstream acquirers of AI systems and end users documented?",
        evidence1: "Ensure that negative residual risks (defined as the sum of all unmitigated risks) to both downstream acquirers of AI systems and end users are documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: 8.1,
        column15: "6.5.2 - ISO 31000:2018\n6.7 - ISO 31000:2018",
        column16: "6.3.4.3\n6.4.1.3",
        column17: "3.2.2\n5.5",
        column18: 5.18
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 2",
        controlName: "Strategies to maximize AI benefits and minimize negative impacts are planned, prepared, implemented, documented, and informed by input from relevant AI actors.",
        subControlID: "MANAGE 2.1",
        subControlName: "Resources required to manage AI risks are taken into account – along with viable non-AI alternative systems, approaches, or methods – to reduce the magnitude or likelihood of potential impacts.",
        evidence: "Are resources required to manage AI risks taken into account – along with viable non-AI alternative systems, approaches, or methods – to reduce the magnitude or likelihood of potential impacts?",
        evidence1: "Ensure that resources required to manage AI risks are taken into account – along with viable non-AI alternative systems, approaches, or methods – to reduce the magnitude or likelihood of potential impacts.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: 8.1,
        column15: "5.4.3 - ISO 31000:2018\n5.4.4 - ISO 31000:2018",
        column16: "6.3.4",
        column17: "6.7.3",
        column18: 5.18
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 2",
        controlName: "Strategies to maximize AI benefits and minimize negative impacts are planned, prepared, implemented, documented, and informed by input from relevant AI actors.",
        subControlID: "MANAGE 2.2",
        subControlName: "Mechanisms are in place and applied to sustain the value of deployed AI systems.",
        evidence: "Are mechanisms in place and applied to sustain the value of deployed AI systems?",
        evidence1: "Ensure that mechanisms are in place and applied to sustain the value of deployed AI systems.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column15: "5.4.1 - ISO 31000:2018\n6.4.2.2",
        column16: "6.4.2.3\n6.4.13.3",
        column17: 4.1,
        column18: "5.15.1"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 2",
        controlName: "Strategies to maximize AI benefits and minimize negative impacts are planned, prepared, implemented, documented, and informed by input from relevant AI actors.",
        subControlID: "MANAGE 2.3",
        subControlName: "Procedures are followed to respond to and recover from a previously unknown risk when it is identified.",
        evidence: "Are procedures followed to respond to and recover from a previously unknown risk when it is identified?",
        evidence1: "Ensure that procedures are followed to respond to and recover from a previously unknown risk when it is identified.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column15: "5.2\n5.3",
        column16: "6.4.15.3\n6.4.16.1\n",
        column17: 4.2,
        column18: "3.5.10\n5.15.4"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 2",
        controlName: "Strategies to maximize AI benefits and minimize negative impacts are planned, prepared, implemented, documented, and informed by input from relevant AI actors.",
        subControlID: "MANAGE 2.4",
        subControlName: "Mechanisms are in place and applied, and responsibilities are assigned and understood, to supersede, disengage, or deactivate AI systems that demonstrate performance or outcomes inconsistent with intended use.",
        evidence: "Are mechanisms in place and applied, and responsibilities assigned and understood, to supersede, disengage, or deactivate AI systems that demonstrate performance or outcomes inconsistent with intended use?",
        evidence1: "Ensure that mechanisms are in place and applied, and responsibilities are assigned and understood, to supersede, disengage, or deactivate AI systems that demonstrate performance or outcomes inconsistent with intended use.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column15: "6.3.4 - ISO 31000:2018\n6.4.2.4\n6.4.2.5\n6.4.2.6\n6.5.2 - ISO 31000:2018",
        column16: "6.4.17.1",
        column17: "3.2.3\n6.7\n",
        column18: "3.1.22\n5.17\n6.1\n6.2.9\nAnnex A"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 3",
        controlName: "AI risks and benefits from third-party entities are managed.",
        subControlID: "MANAGE 3.1",
        subControlName: "AI risks and benefits from third-party resources are regularly monitored, and risk controls are applied and documented.",
        evidence: "Are AI risks and benefits from third-party resources regularly monitored, and risk controls applied and documented?",
        evidence1: "Ensure that AI risks and benefits from third-party resources are regularly monitored, and risk controls are applied and documented.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: 8.1,
        column15: "5.4.1",
        column16: "6.3.5.3\n6.3.8.3",
        column17: "6.7.2",
        column18: "6.1\n6.2.4\n6.2.6\n6.2.8"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 3",
        controlName: "AI risks and benefits from third-party entities are managed.",
        subControlID: "MANAGE 3.2",
        subControlName: "Pre-trained models which are used for development are monitored as part of AI system regular monitoring and maintenance.",
        evidence: "Are pre-trained models which used for development monitored as part of AI system regular monitoring and maintenance?",
        evidence1: "Ensure that pre-trained models which are used for development are monitored as part of AI system regular monitoring and maintenance.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: "3.1.3\n3.1.4\n6.2.1\n6.2.2\n6.3\n6.5.1\n6.5.3\n6.5.5\n7.2\n7.3\n7.4\n7.7\n8.4\n8.5\n8.6\n8.7\nA.2.2.3",
        column16: "5.3\n6.1.1.3\n6.3.4",
        column17: "A.1.3",
        column18: "3.3.10\n3.3.14\n5.11.6\n5.11.7\n5.11.8\n5.11.9.1\n8.6.2\n10.4"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 4",
        controlName: "Risk treatments, including response and recovery, and communication plans for the identified and measured AI risks are documented and monitored regularly.",
        subControlID: "MANAGE 4.1",
        subControlName: "Post-deployment AI system monitoring plans are implemented, including mechanisms for capturing and evaluating input from users and other relevant AI actors, appeal and override, decommissioning, incident response, recovery, and change management.",
        evidence: "Are post-deployment AI system monitoring plans implemented, including mechanisms for capturing and evaluating input from users and other relevant AI actors, appeal and override, decommissioning, incident response, recovery, and change management?",
        evidence1: "Ensure that post-deployment AI system monitoring plans are implemented, including mechanisms for capturing and evaluating input from users and other relevant AI actors, appeal and override, decommissioning, incident response, recovery, and change management.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: "8.2\n8.6",
        column15: "5.4.2\n6.6 - ISO 31000:2018",
        column16: "6.4.15\n6.4.16",
        column17: "5.2.3",
        column18: "6.1\n6.2.9\nAnnex A"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 4",
        controlName: "Risk treatments, including response and recovery, and communication plans for the identified and measured AI risks are documented and monitored regularly.",
        subControlID: "MANAGE 4.2",
        subControlName: "Measurable activities for continual improvements are integrated into AI system updates and include regular engagement with interested parties, including relevant AI actors.",
        evidence: "Are measurable activities for continual improvements integrated into AI system updates and include regular engagement with interested parties, including relevant AI actors?",
        evidence1: "Ensure that measurable activities for continual improvements are integrated into AI system updates and include regular engagement with interested parties, including relevant AI actors.",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: "6.5.4.1\n7.4\nA.3.2\nA.3.4",
        column15: "5.7.1 ISO 31000:2018\n5.7.2 - ISO 31000:2018",
        column16: "6.1.2.3\n6.4.14",
        column17: "6.2\n6.7.4",
        column18: "6.1\n6.2.7"
    },
    {
        thrustArea: "MANAGE",
        controlID: "MANAGE 4",
        controlName: "Risk treatments, including response and recovery, and communication plans for the identified and measured AI risks are documented and monitored regularly.",
        subControlID: "MANAGE 4.3",
        subControlName: "Incidents and errors are communicated to relevant AI actors, including affected communities. \n\nProcesses for tracking, responding to, and recovering from incidents and errors are followed and documented.",
        evidence: "Are incidents and errors communicated to relevant AI actors, including affected communities?\n\nAre processes for tracking, responding to, and recovering from incidents and errors followed and documented?",
        evidence1: "Ensure that incidents and errors are communicated to relevant AI actors, including affected communities. \n\nEnsure that processes for tracking, responding to, and recovering from incidents and errors are followed and documented.",
        column8: "X",
        column9: "X",
        column10: "X",
        column11: "X",
        column12: "X",
        column14: "3.1.4\nA.2.2.2",
        column15: "6.5.2 - ISO 31000:2018\n6.7 - ISO 31000:2018",
        column16: "6.4.3.3\n6.4.15.3",
        column17: "4.3\n6.1\n6.4\n6.7.3",
        column18: "6.2.6"
    }
]

export const NIST_AI_RISK_DATA = [
    // {
    //  "NIST AI Risk Management Framework Playbook": " Activity Group  ",
    //  "Column2": "Activity",
    //  "Column3": "Expected Evidence"
    // },
    {
        activityGroup: "GOVERN-1: Policies, processes, procedures and practices across the organization\nrelated to the mapping, measuring and managing of AI risks are in place,\ntransparent, and implemented effectively.",
        activity: "GOVERN 1.1\nLegal and regulatory requirements involving AI are understood, managed, and documented.",
        expectedEvidence: "1.Documentation of relevant laws and regulations.\n2.Records of compliance measures, such as legal      reviews and audits.\n3.Documentation demonstrating understanding and management of legal and regulatory requirements."
    },
    {
        activityGroup: "GOVERN-1: Policies, processes, procedures and practices across the organization\nrelated to the mapping, measuring and managing of AI risks are in place,\ntransparent, and implemented effectively.",
        activity: "GOVERN 1.2\nThe characteristics of trustworthy AI are integrated into organizational policies, processes, and procedures.",
        expectedEvidence: "1.Documentation of policies reflecting trustworthy AI principles.\n2.Evidence of integration efforts, such as policy updates and training materials.\n3.Examples of trustworthy AI principles applied in decision-making processes."
    },
    {
        activityGroup: "GOVERN-1: Policies, processes, procedures and practices across the organization\nrelated to the mapping, measuring and managing of AI risks are in place,\ntransparent, and implemented effectively.",
        activity: "GOVERN 1.3\nProcesses and procedures are in place to determine the needed level of risk management activities based on\nthe organization’s risk tolerance.",
        expectedEvidence: "1.Documentation outlining risk tolerance levels.\n2.Procedures for assessing and determining risk management activities.\n3.Records of risk assessments and decisions based on risk tolerance."
    },
    {
        activityGroup: "GOVERN-1: Policies, processes, procedures and practices across the organization\nrelated to the mapping, measuring and managing of AI risks are in place,\ntransparent, and implemented effectively.",
        activity: "GOVERN 1.4\nThe risk management process and its outcomes are established through transparent policies, procedures, and\nother controls based on organizational risk priorities.",
        expectedEvidence: "1.Transparent policies and procedures related to risk management.\n2.Evidence of risk management controls aligned with organizational priorities.\n3.Documentation of risk assessments, mitigation plans, and outcomes."
    },
    {
        activityGroup: "GOVERN-1: Policies, processes, procedures and practices across the organization\nrelated to the mapping, measuring and managing of AI risks are in place,\ntransparent, and implemented effectively.",
        activity: "GOVERN 1.5\nOngoing monitoring and periodic review of the risk management process and its outcomes are planned,\norganizational roles and responsibilities are clearly defined, including determining the frequency of periodic\nreview.",
        expectedEvidence: "1.Monitoring plans and schedules.\n2.Clear roles and responsibilities for monitoring and review.\n3.Records of periodic reviews, evaluations, and adjustments to the risk management process."
    },
    {
        activityGroup: "GOVERN-1: Policies, processes, procedures and practices across the organization\nrelated to the mapping, measuring and managing of AI risks are in place,\ntransparent, and implemented effectively.",
        activity: "GOVERN 1.6\nMechanisms are in place to inventory AI systems and are resourced according to organizational risk priorities.",
        expectedEvidence: "1.Inventory of AI systems.\n2.Resource allocation plans based on risk priorities.\n3.Documentation of mechanisms for maintaining and updating the AI system inventory."
    },
    {
        activityGroup: "GOVERN-1: Policies, processes, procedures and practices across the organization\nrelated to the mapping, measuring and managing of AI risks are in place,\ntransparent, and implemented effectively.",
        activity: "GOVERN 1.7\nProcesses and procedures are in place for decommissioning and phasing out of AI systems safely and in a\nmanner that does not increase risks or decrease the organization’s trustworthiness.",
        expectedEvidence: "1.Decommissioning procedures for AI systems.\n2.Evidence of safe decommissioning practices, such as data handling protocols.\n3.Records demonstrating adherence to decommissioning processes without compromising trustworthiness or increasing risks."
    },
    {
        activityGroup: "GOVERN-2: Accountability structures are in place so that the appropriate teams\nand individuals are empowered, responsible, and trained for mapping, measuring,\nand managing AI risks.",
        activity: "GOVERN 2.1\nRoles and responsibilities and lines of communication related to mapping, measuring, and managing AI risks\nare documented and are clear to individuals and teams throughout the organization.",
        expectedEvidence: "1.Documentation of roles, responsibilities, and communication lines.\n2.Training materials or documentation demonstrating clarity."
    },
    {
        activityGroup: "GOVERN-2: Accountability structures are in place so that the appropriate teams\nand individuals are empowered, responsible, and trained for mapping, measuring,\nand managing AI risks.",
        activity: "GOVERN 2.2\nThe organization’s personnel and partners receive AI risk management training to enable them to perform\ntheir duties and responsibilities consistent with related policies, procedures, and agreements.",
        expectedEvidence: "1.Training records or documentation.\n2.vidence of training programs aligned with policies and procedures."
    },
    {
        activityGroup: "GOVERN-2: Accountability structures are in place so that the appropriate teams\nand individuals are empowered, responsible, and trained for mapping, measuring,\nand managing AI risks.",
        activity: "GOVERN 2.3\nExecutive leadership of the organization takes responsibility for decisions about risks associated with AI\nsystem development and deployment.",
        expectedEvidence: "1.Records demonstrating executive involvement in risk decisions.\n2.Documentation of executive responsibilities regarding AI risk."
    },
    {
        activityGroup: "GOVERN-3: Workforce diversity, equity, inclusion, and accessibility processes\nare prioritized in the mapping, measuring, and managing of AI risks throughout\nthe lifecycle.",
        activity: "GOVERN 3.1\nDecision-makings related to mapping, measuring, and managing AI risks throughout the lifecycle is informed\nby a diverse team (e.g., diversity of demographics, disciplines, experience, expertise, and backgrounds).",
        expectedEvidence: "1.Documentation showing diversity within decision-making teams.\n2.Records demonstrating diverse perspectives considered in risk management."
    },
    {
        activityGroup: "GOVERN-3: Workforce diversity, equity, inclusion, and accessibility processes\nare prioritized in the mapping, measuring, and managing of AI risks throughout\nthe lifecycle.",
        activity: "GOVERN 3.2\nPolicies and procedures are in place to define and differentiate roles and responsibilities for human-AI\nconfigurations and oversight of AI systems.",
        expectedEvidence: "1.Documentation of policies and procedures related to human-AI configurations and oversight.\n2.Evidence of role definitions and responsibilities."
    },
    {
        activityGroup: "GOVERN-4: Organizational teams are committed to a culture that considers\nand communicates AI risk.",
        activity: "GOVERN 4.1\nOrganizational policies, and practices are in place to foster a critical thinking and safety-first mindset in the\ndesign, development, deployment, and uses of AI systems to minimize negative impacts.",
        expectedEvidence: "1.Documentation of policies promoting critical thinking and safety-first mindset.\n2.Examples of practices reflecting these policies."
    },
    {
        activityGroup: "GOVERN-4:Organizational teams are committed to a culture that considers\nand communicates AI risk.",
        activity: "GOVERN 4.2\nOrganizational teams document the risks and potential impacts of the AI technology they design, develop,\ndeploy, evaluate and use, and communicate about the impacts more broadly.",
        expectedEvidence: "1.Records of risk documentation.\n2.Evidence of communication about AI risks and impacts."
    },
    {
        activityGroup: "GOVERN-4: Organizational teams are committed to a culture that considers\nand communicates AI risk.",
        activity: "GOVERN 4.3\nOrganizational practices are in place to enable AI testing, identification of incidents, and information sharing.",
        expectedEvidence: "1.Documentation of testing procedures and incident identification mechanisms.\n2.Records of information sharing practices related to AI incidents."
    },
    {
        activityGroup: "GOVERN-5: Processes are in place for robust engagement with relevant AI actors.",
        activity: "GOVERN 5.1\nOrganizational policies and practices are in place to collect, consider, prioritize, and integrate feedback from\nthose external to the team that developed or deployed the AI system regarding the potential individual and\nsocietal impacts related to AI risks.",
        expectedEvidence: "Documentation of feedback collection and integration processes.\nRecords of feedback received and actions taken."
    },
    {
        activityGroup: "GOVERN-5: Processes are in place for robust engagement with relevant AI actors.",
        activity: "GOVERN 5.2\nMechanisms are established to enable AI actors to regularly incorporate adjudicated feedback from relevant\nAI actors into system design and implementation.",
        expectedEvidence: "1.Documentation of mechanisms for incorporating feedback.\n2.Evidence of feedback incorporation into system design and implementation."
    },
    {
        activityGroup: "GOVERN-6: Policies and procedures are in place to address AI risks and benefits\narising from third-party software and data and other supply chain issues.",
        activity: "GOVERN 6.1\nPolicies and procedures are in place that address AI risks associated with third-party entities, including risks\nof infringement of a third party’s intellectual property or other rights.",
        expectedEvidence: "1.Documentation of policies and procedures related to third-party AI risks.\n2.Evidence of risk assessments and mitigation strategies for third-party risks."
    },
    {
        activityGroup: "GOVERN-6: Policies and procedures are in place to address AI risks and benefits\narising from third-party software and data and other supply chain issues.",
        activity: "GOVERN 6.2\nContingency processes are in place to handle failures or incidents in third-party data or AI systems deemed\nto be high-risk.",
        expectedEvidence: "1.Documentation of contingency processes for third-party failures or incidents.\n2.Records of incident responses and resolutions."
    }
]

export const COMPANION_OF_AI_GOV_FRAMEWORK_DATA = [
    {
        activityGroup: "Section 1",
        activity: "1.1 Objectives of deploying AI",
        expectedEvidence: "1.Documentation of the defined objectives and goals for deploying AI within the organization or project.\n2.Strategic plans or project charters outlining the intended outcomes and benefits of AI deployment.\n3.Alignment of AI objectives with the broader organizational goals and mission statements."
    },
    {
        activityGroup: "Section 2",
        activity: "2.1 Internal governance structures and measures",
        expectedEvidence: "1.Organizational charts or descriptions of internal governance structures related to AI deployment.\n2.Policies, procedures, or guidelines governing the use of AI within the organization.\n3.Records of training programs or educational materials provided to staff regarding AI governance."
    },
    {
        activityGroup: "Section 3",
        activity: "3.1 Determining the level of human involvement in AI-augmented\ndecision-making",
        expectedEvidence: "1.Documentation outlining the decision-making processes where AI is involved and the level of human oversight or intervention required.\n2.Risk assessments or impact analyses assessing the potential consequences of AI-augmented decision-making.\n3.Reports or studies evaluating the effectiveness of human-in-the-loop or human-on-the-loop approaches in AI systems."
    },
    {
        activityGroup: "Section 4",
        activity: "4.1 Operations management",
        expectedEvidence: "1.Operational plans or workflows detailing how AI systems are integrated into daily operations.\n2.Records of system monitoring, maintenance, and performance evaluation procedures.\n3.Incident reports or logs documenting any issues, failures, or unexpected outcomes related to AI operations."
    },
    {
        activityGroup: "Section 5",
        activity: "5.1 Stakeholder Interaction and Communication",
        expectedEvidence: "1.Stakeholder engagement plans outlining how stakeholders are involved in AI governance processes.\n2.Records of stakeholder consultations, meetings, or feedback sessions regarding AI deployment.\n3.Communication materials such as reports, newsletters, or presentations explaining AI initiatives and their impacts to stakeholders."
    }
]

export const MICRO_RES_AI_IMAPCT_ASS_GUIDE_DATA = [
    {
        activityGroup: "Collaborative Group ",
        activity: "1.1 Defining intended uses",
        expectedEvidence: "1.Documentation of clearly defined intended uses for the AI system.\n2.Records of discussions, meetings, or workshops where intended uses were defined."
    },
    {
        activityGroup: "Collaborative Group ",
        activity: "1.2 Creating a stakeholder list, identifying potential benefits and harms",
        expectedEvidence: "1.Stakeholder list identifying individuals or groups impacted by the AI system.\n2.Documentation outlining potential benefits and harms associated with system use."
    },
    {
        activityGroup: "Collaborative Group ",
        activity: "1.3 Fairness considerations",
        expectedEvidence: "1.Documentation of fairness considerations addressed during stakeholder engagement.\n2.Records of fairness assessments or impact analyses conducted."
    },
    {
        activityGroup: "Collaborative Group ",
        activity: "1.4 Potential harms and preliminary mitigations",
        expectedEvidence: "1.Identification of potential harms resulting from system use.\n2.Documentation of preliminary mitigation strategies to address identified harms."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.1 System profile and system ifecycle stage",
        expectedEvidence: "1.Documentation outlining the system profile, including its name, version, and relevant identifiers.\n2.Records detailing the current lifecycle stage of the system, such as development, testing, deployment, or maintenance."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.2 System description, purpose and features",
        expectedEvidence: "1.Detailed description of the system, including its purpose, objectives, and target audience.\n2.Documentation of the key features and functionalities of the system."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.3 Geographic areas, languages and deployment mode",
        expectedEvidence: "1.Identification of the geographic areas where the system will be deployed or accessed.\n2.Documentation of supported languages and localization efforts.\n3.Description of the deployment mode, such as cloud-based, on-premises, or hybrid."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.4 Intended uses",
        expectedEvidence: "1.Clear articulation of the intended uses and applications of the system.\n2.Documentation of specific use cases and scenarios where the system is expected to be applied."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.5 System Profile",
        expectedEvidence: "1.Detailed profile of the system, including technical specifications, hardware and software requirements, and configurations.\n2.Records of system architecture and design documents.\n"
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.6 System lifestyle stage",
        expectedEvidence: "1.Description of the current lifecycle stage of the system, including any milestones or checkpoints.\n2.Records of progress reports, version releases, or updates related to the system lifecycle."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.7 System description",
        expectedEvidence: "1.Comprehensive description of the system, its components, and how they interact.\n2.Documentation of system documentation, user manuals, or technical specifications."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.8 System purpose",
        expectedEvidence: "1. the end user or primary customer of the system,\n2. how they complete this task today, or their current situation,\n3. the value that the system is intended to deliver,\n4. how it improves on today’s situation."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.9 System features",
        expectedEvidence: "1.Documentation detailing the specific features and functionalities of the system.\n2.Descriptions of how each feature contributes to the overall capabilities of the system."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.10 Geographic areas and languages",
        expectedEvidence: "1.Identification of the geographic regions where the system will be deployed or used.\n2.Documentation of language support and localization efforts for different user populations."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.11 Deployment mode",
        expectedEvidence: "1.Description of the deployment mode(s) for the system, such as cloud-based, on-premises, or hybrid.\n2.Records of deployment configurations and infrastructure requirements."
    },
    {
        activityGroup: "Project  overview Group",
        activity: "2.12 Identifying system uses",
        expectedEvidence: "1.Clear articulation of the various ways in which the system will be used or applied.\n2.Documentation of specific use cases and scenarios where the system is expected to provide value."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3.1 Assessment of fitness for purpose",
        expectedEvidence: "1.Performance metrics: Data showing how well the technology performs its intended functions.\n2.User feedback: Surveys, interviews, or user testing results indicating user satisfaction and whether the technology meets their needs.\n3.Comparative analysis: Comparison with existing solutions or benchmarks to demonstrate superiority or parity."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3.2 Stakeholders, potential benefits & potential harms",
        expectedEvidence: "1.Stakeholder analysis: Documentation identifying all stakeholders involved and their respective interests.\n2.Benefits assessment: Data or studies showing potential positive impacts on stakeholders or society.\n3.Harm assessment: Risk analysis, ethical considerations, and potential negative impacts on stakeholders or society."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3.3 Stakeholders for goal-driven requirements",
        expectedEvidence: "1.Stakeholder requirements: Documentation outlining specific requirements and preferences from relevant stakeholders.\n2.Alignment with goals: Evidence showing how the technology addresses the needs and goals of stakeholders."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3.4 Fairness considerations",
        expectedEvidence: "1.Fairness assessment: Documentation of fairness evaluations, including bias assessments, fairness metrics, and mitigation strategies.\n2.Equity analysis: Evidence demonstrating efforts to ensure equitable outcomes across different demographic groups."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3.5 Technology readiness assessment, task complexity, role of \nhumans, and deployment environment complexity",
        expectedEvidence: "1.Technology readiness levels: Documentation indicating the readiness of the technology for deployment, including prototypes, testing results, and validation studies.\n2.Task complexity analysis: Evaluation of the complexity of tasks the technology is designed to perform and how well it addresses them.\n3.Human factors analysis: Consideration of human roles, capabilities, and interactions within the technology's deployment environment."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3. 6Assessment of fitness for purpose",
        expectedEvidence: "Performance metrics, user feedback, and comparative analysis to assess fitness for purpose."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3.7 Stakeholders, potential benefits, and potential harms",
        expectedEvidence: "1.Stakeholder identification: Documented analysis identifying all stakeholders involved, including end-users, developers, regulatory bodies, etc.\n2.Benefit analysis: Studies, reports, or data showing potential positive impacts of the technology on stakeholders or society, such as increased efficiency, cost savings, or improved quality of life."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3.8 Brainstorming potential benefits and harms ",
        expectedEvidence: "1.Brainstorming sessions: Records of brainstorming sessions or workshops where potential benefits and harms of the technology were discussed and documented.\n2.Stakeholder input: Input from various stakeholders regarding their perceptions of potential benefits and harms."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3.9 Stakeholders for Goal-driven requirements from the Responsible AI \nStandard",
        expectedEvidence: "1.Stakeholder requirements documentation: Detailed documentation outlining specific requirements and preferences from relevant stakeholders based on Responsible AI principles.\n2.Alignment with Responsible AI standards: Evidence demonstrating how the technology addresses the needs and goals of stakeholders while adhering to Responsible AI principles."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3.10 Fairness considerations",
        expectedEvidence: "1.Fairness assessment: Documentation of fairness evaluations, including bias assessments, fairness metrics, and mitigation strategies, with a focus on ensuring equitable outcomes for all stakeholders.\n2.Equity analysis: Evidence demonstrating efforts to mitigate potential biases and ensure fairness across different demographic groups or affected parties."
    },
    {
        activityGroup: "Intended  uses Group ",
        activity: "3.11Technology readiness assessment, task complexity, role of humans, \nand deployment environment complexity",
        expectedEvidence: "1.Technology readiness levels: Documentation indicating the readiness of the technology for deployment, including prototypes, testing results, and validation studies.\n2.Task complexity analysis: Evaluation of the complexity of tasks the technology is designed to perform and how well it addresses them."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.1 Restricted Uses",
        expectedEvidence: "1.Documentation outlining specific use cases or scenarios where the technology should not be applied due to potential adverse effects or risks.\n2.Regulatory compliance records indicating restrictions on the use of the technology in certain contexts."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.2 Unsupported uses",
        expectedEvidence: "1.Analysis or reports identifying use cases where the technology is not suitable or where its effectiveness is unproven.\n2.Documentation from experts or industry standards indicating lack of support for specific uses."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.3 Known limitations",
        expectedEvidence: "1.Comprehensive documentation of known limitations or weaknesses of the technology, including technical constraints, performance issues, or failure modes.\n2.Reports or studies detailing past incidents or failures related to the technology's limitations."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.4 Potential impact of failure on stakeholders",
        expectedEvidence: "1.Risk assessments outlining potential consequences of technology failures on stakeholders, including financial, safety, or reputational impacts.\n2.Incident reports or case studies demonstrating the real-world impact of previous failures on stakeholders."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.5 Potential impact of misuse on stakeholders",
        expectedEvidence: "1.Risk analysis highlighting potential consequences of misuse or malicious use of the technology on stakeholders, such as privacy breaches, security vulnerabilities, or ethical concerns.\n2.Documentation of safeguards or measures in place to mitigate the risk of misuse."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.6 Sensitive uses",
        expectedEvidence: "1.Documentation identifying sensitive or high-risk use cases where the technology may have significant implications for stakeholders' privacy, security, or well-being.\n2.Ethical assessments or impact analyses focusing on the potential consequences of deploying the technology in sensitive contexts."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.7 Restricted uses",
        expectedEvidence: "Documentation outlining specific use cases or scenarios where the technology should not be applied due to potential adverse effects or risks."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.8 Unsupported uses",
        expectedEvidence: "Analysis or reports identifying use cases where the technology is not suitable or where its effectiveness is unproven."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.9 Known limitations",
        expectedEvidence: "Comprehensive documentation of known limitations or weaknesses of the technology."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.10 Potential impact of failure on stakeholders",
        expectedEvidence: "Risk assessments outlining potential consequences of technology failures on stakeholders."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.11 Potential impact of misuse on stakeholders",
        expectedEvidence: "Risk analysis highlighting potential consequences of misuse or malicious use of the technology on stakeholders."
    },
    {
        activityGroup: "Adverse  impact Group ",
        activity: "4.12 Sensitive uses",
        expectedEvidence: "Documentation identifying sensitive or high-risk use cases where the technology may have significant implications for stakeholders."
    },
    {
        activityGroup: "Data requirements Group ",
        activity: "5.1 Data requirements",
        expectedEvidence: "1.Documentation outlining the specific data needed for the technology to function properly.\n2.Data schema or data dictionary detailing the structure and format of required data inputs.\n3.Justification for why each data type is necessary for the intended purpose."
    },
    {
        activityGroup: "Data requirements Group ",
        activity: "5.2 Pre-defined data sets",
        expectedEvidence: "1.Records or documentation of pre-existing datasets that have been identified and selected for use with the technology.\n2.Descriptions of how these datasets ere sourced, validated, and curated."
    },
    {
        activityGroup: "Data requirements Group ",
        activity: "5.3 Existing data sets",
        expectedEvidence: "1.Documentation or reports indicating the availability and accessibility of relevant existing datasets.\n2.Assessment of the quality, completeness, and suitability of these datasets for the intended purpose."
    },
    {
        activityGroup: "Summary of impact Group",
        activity: "6.1 Potential harm and preliminary mitigations",
        expectedEvidence: "1.Risk assessment reports identifying potential harms associated with the technology's deployment.\n2.Documentation of preliminary mitigation strategies or risk management plans to address identified risks."
    },
    {
        activityGroup: "Summary of impact Group",
        activity: "6.2 Goal applicability",
        expectedEvidence: "Analysis demonstrating how the technology aligns with stated goals and objectives.\nDocumentation of the technology's potential impact on achieving desired outcomes."
    },
    {
        activityGroup: "Summary of impact Group",
        activity: "6.3 Signing off on the Impact Assessment",
        expectedEvidence: "Records of stakeholders' sign-off on the impact assessment, indicating agreement with the assessment findings and proposed mitigation strategies."
    },
    {
        activityGroup: "Summary of impact Group",
        activity: "6.4 Potential harms and preliminary mitigations",
        expectedEvidence: "Risk assessment reports identifying potential harms and preliminary mitigation strategies."
    },
    {
        activityGroup: "Summary of impact Group",
        activity: "6.5 Goal Applicability",
        expectedEvidence: "Analysis demonstrating how the technology aligns with stated goals and objectives."
    },
    {
        activityGroup: "Summary of impact Group",
        activity: "6.7 Signing off on the Impact Assessment",
        expectedEvidence: "Records of stakeholders' sign-off on the impact assessment."
    },
]

export const MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2_DATA = [
    {
        activityGroup: "System information",
        activity: "1.1 System profile",
        expectedEvidence: "1.Description of the system's profile, including its purpose, features, and deployment mode.\n2.Documentation of system specifications and configurations."
    },
    {
        activityGroup: "System information",
        activity: "1.2 System lifecycle stage",
        expectedEvidence: "1.Identification of the current lifecycle stage of the system.\n2.Records indicating the system's development, deployment, and maintenance phases."
    },
    {
        activityGroup: "System information",
        activity: "1.3 System description",
        expectedEvidence: "1.Detailed description of the system, its components, and functionalities.\n2.Documentation of system architecture and design."
    },
    {
        activityGroup: "System information",
        activity: "1.4 System purpose",
        expectedEvidence: "1.Clear statement of the system's purpose and objectives.\n2.Alignment of the system's purpose with organizational goals and requirements."
    },
    {
        activityGroup: "System information",
        activity: "1.5 System features",
        expectedEvidence: "1.List of system features and capabilities.\n2.Documentation of how these features contribute to achieving the system's purpose."
    },
    {
        activityGroup: "System information",
        activity: "1.6 Geographic areas and         languages",
        expectedEvidence: "1.Identification of geographic areas where the system operates.\n2.Documentation of supported languages and localization efforts."
    },
    {
        activityGroup: "System information",
        activity: "1.7 Deployment mode",
        expectedEvidence: "1.Description of how the system is deployed (e.g., cloud-based, on-premises).\n2.Records of deployment configurations and environments."
    },
    {
        activityGroup: "System information",
        activity: "1.8 Intended uses",
        expectedEvidence: "1.Clear articulation of the intended uses of the system.\n2.Documentation of use cases and scenarios where the system is expected to be applied."
    },
    {
        activityGroup: "Intended uses",
        activity: "2.1 Assessment of fitness for purpose",
        expectedEvidence: "1.Evaluation of how well the system meets its intended purpose.\n2.Documentation of assessments conducted to ensure fitness for purpose."
    },
    {
        activityGroup: "Intended uses",
        activity: "2.2 Stakeholders, potential benefits, and potential harms",
        expectedEvidence: "1.Identification of stakeholders impacted by the system.\n2.Documentation of potential benefits and harms associated with system use."
    },
    {
        activityGroup: "Intended uses",
        activity: "2.3 Stakeholders for Goal-driven requirements from the Responsible AI Standard\n",
        expectedEvidence: "1.Identification of stakeholders responsible for addressing goal-driven requirements from the Responsible AI Standard.\n2.Records of stakeholder involvement in the assessment process."
    },
    {
        activityGroup: "Intended uses",
        activity: "2.4 Fairness considerations",
        expectedEvidence: "1.Assessment of fairness considerations related to system use.\n2.Documentation of fairness metrics and evaluation methodologies."
    },
    {
        activityGroup: "Intended uses",
        activity: "2.5 Technology readiness assessment",
        expectedEvidence: "1.Evaluation of the system's technology readiness level.\n2.Records of technology assessments and readiness criteria."
    },
    {
        activityGroup: "Intended uses",
        activity: "2.6 Task complexity",
        expectedEvidence: "1.Assessment of task complexity associated with system use.\n2.Documentation of task complexity analyses and findings."
    },
    {
        activityGroup: "Intended uses",
        activity: "2.7 Role of humans",
        expectedEvidence: "1.Identification of the role of humans in system operation and oversight.\n2.Records of human involvement in decision-making processes."
    },
    {
        activityGroup: "Intended uses",
        activity: "2.8 Deployment environment complexity",
        expectedEvidence: "1.Assessment of the complexity of the deployment environment.\n2.Documentation of environmental factors impacting system performance."
    },
    {
        activityGroup: "Adverse impact",
        activity: "3.1 Restricted Uses",
        expectedEvidence: "1.Identification of restricted uses or applications of the system.\n2.Documentation of limitations and restrictions placed on system use."
    },
    {
        activityGroup: "Adverse impact",
        activity: "3.2 Unsupported uses",
        expectedEvidence: "1.Identification of uses not supported by the system.\n2.Records of unsupported use cases and reasons for lack of support."
    },
    {
        activityGroup: "Adverse impact",
        activity: "3.3 Known limitations",
        expectedEvidence: "1.Documentation of known limitations and constraints of the system.\n2.Records of limitations communicated to stakeholders.",
        "Column6": "Documentation of known limitations and constraints of the system."
    },
    {
        activityGroup: "Adverse impact",
        activity: "3.4 Potential impact of failure on stakeholders",
        expectedEvidence: "1.Assessment of potential impacts of system failures on stakeholders.\n2.Documentation of mitigation strategies to address failure impacts.",
        "Column6": "Records of limitations communicated to stakeholders."
    },
    {
        activityGroup: "Adverse impact",
        activity: "3.5 Potential impact of misuse on stakeholders",
        expectedEvidence: "1.Assessment of potential impacts of system misuse on stakeholders.\n2.Documentation of measures to prevent and address misuse."
    },
    {
        activityGroup: "Adverse impact",
        activity: "3.6 Sensitive Uses",
        expectedEvidence: "1.Identification of sensitive uses or applications of the system.\n2.Records of safeguards and controls implemented for sensitive use cases."
    },
    {
        activityGroup: "Data requirements",
        activity: "4.1 Data requirements",
        expectedEvidence: "1.Specification of data requirements for system operation.\n2.Documentation of data sources, formats, and quality standards."
    },
    {
        activityGroup: "Data requirements",
        activity: "4.2 Existing data sets",
        expectedEvidence: "1.Identification of existing data sets used by the system.\n2.Documentation of data collection, processing, and storage practices"
    },
    {
        activityGroup: "Summary of impact",
        activity: "5.1 Potential harms and preliminary mitigations",
        expectedEvidence: "1.Identification of potential harms associated with system use.\n2.Documentation of preliminary mitigation strategies to address identified harms."
    },
    {
        activityGroup: "Summary of impact",
        activity: "5.2 Goal Applicability",
        expectedEvidence: "1.Assessment of how well the system aligns with the goals of the Responsible AI Standard.\n2.Documentation of goal applicability assessments and findings."
    },
    {
        activityGroup: "Summary of impact",
        activity: "5.3 Signing off on the Impact Assessment",
        expectedEvidence: "1.Confirmation of approval or sign-off on the completed impact assessment.\n2.Records of stakeholders' acknowledgment and acceptance of assessment outcomes."
    }
]

export const MICRO_RES_AI_STD_DATA = [
    {
        activityGroup: "Accountability Goals",
        activity: "1.1 Impact assessment",
        expectedEvidence: "1.Documentation of AI impact assessments conducted.\n2.Reports detailing the methodology, findings, and implications of the impact assessment.\n3.Evidence of stakeholder engagement in the assessment process."
    },
    {
        activityGroup: "Accountability Goals",
        activity: "1.2 Oversight of significant adverse impacts",
        expectedEvidence: "1.Records of oversight mechanisms implemented to address significant adverse impacts.\n2.Documentation of actions taken in response to identified adverse impacts.\n3.Evidence of review processes and decision-making related to adverse impacts."
    },
    {
        activityGroup: "Accountability Goals",
        activity: "1.3 Fit for purpose",
        expectedEvidence: "1.Documentation demonstrating alignment of AI systems with intended purposes.\n2.Records of evaluations assessing the effectiveness and suitability of AI systems for their intended uses.\n3.Evidence of adjustments made to improve the fit-for-purpose of AI systems based on evaluations."
    },
    {
        activityGroup: "Accountability Goals",
        activity: "1.4 Data governance and management",
        expectedEvidence: "1.Documentation of data governance policies and procedures.\n2.Records of data management practices, including data collection, storage, processing, and sharing.\n3.Evidence of compliance with data protection regulations and standards."
    },
    {
        activityGroup: "Accountability Goals",
        activity: "1.5 Human oversight and control",
        expectedEvidence: "1.Documentation of mechanisms for human oversight of AI systems.\nRecords demonstrating human intervention in decision-making processes when necessary.\n2.Evidence of controls implemented to ensure human control over AI systems."
    },
    {
        activityGroup: "Transparency Goals",
        activity: "2.1 System intelligibility for decision making",
        expectedEvidence: "1.Documentation of efforts to ensure transparency and explainability of AI systems.\n2.Records of methodologies used to enhance system intelligibility.\nEvidence of communication strategies for making AI decisions understandable to stakeholders."
    },
    {
        activityGroup: "Transparency Goals",
        activity: "2.2 Communication to stakeholders",
        expectedEvidence: "1.Records of communication strategies employed to inform stakeholders about AI systems and their impacts.\n2.Documentation of stakeholder engagement activities.\n3.Evidence of feedback received from stakeholders regarding transparency and communication."
    },
    {
        activityGroup: "Transparency Goals",
        activity: "2.3 Disclosure of AI interaction",
        expectedEvidence: "1.Documentation of policies and procedures for disclosing AI interactions.\n2.Records of instances where AI interactions were disclosed to users or affected parties.\n3.Evidence of transparency in disclosing AI involvement in decision-making processes."
    },
    {
        activityGroup: "Fairness Goals",
        activity: "3.1 Quality of service",
        expectedEvidence: "1.Documentation of measures taken to ensure fairness in the delivery of AI services.\n2.Records of evaluations assessing the quality and fairness of AI services.\n3.Evidence of adjustments made to improve service quality and fairness."
    },
    {
        activityGroup: "Fairness Goals",
        activity: "3.2 Allocation of resources and opportunities",
        expectedEvidence: "1.Documentation of policies and procedures for resource allocation and opportunity distribution.\n2.Records of assessments evaluating the fairness of resource allocation and opportunity distribution.\n3.Evidence of efforts to address disparities and biases in resource allocation and opportunity distribution."
    },
    {
        activityGroup: "Fairness Goals",
        activity: "3.4 Minimization of stereotyping, demeaning, and erasing outputs",
        expectedEvidence: "1.Documentation of strategies to minimize stereotypes, demeaning outputs, and erasure in AI systems.\n2.Records of assessments identifying and addressing instances of stereotyping and demeaning outputs.\n3.Evidence of ongoing efforts to improve the fairness and inclusivity of AI outputs."
    },
    {
        activityGroup: "Reliability & Safety Goals",
        activity: "4.1 Reliability and safety guidance",
        expectedEvidence: "1.Documentation of guidelines and standards followed to ensure the reliability and safety of AI systems.\n2.Records of risk assessments and mitigation strategies implemented to address reliability and safety concerns.\n3.Evidence of adherence to industry best practices for reliability and safety."
    },
    {
        activityGroup: "Reliability & Safety Goals",
        activity: "4.2 Failures and remediations",
        expectedEvidence: "1.Records of AI system failures and incidents.\n2.Documentation of remediation actions taken to address failures and mitigate their impacts.\n3.Evidence of lessons learned and improvements made to prevent similar failures in the future."
    },
    {
        activityGroup: "Reliability & Safety Goals",
        activity: "4.3 Ongoing monitoring, feedback, and evaluation",
        expectedEvidence: "1.Documentation of processes for ongoing monitoring, feedback collection, and evaluation of AI systems.\n2.Records of monitoring activities, feedback received, and evaluation results.\n3.Evidence of continuous improvement efforts based on monitoring and evaluation findings."
    },
    {
        activityGroup: "Privacy & Security Goals",
        activity: "5.1 Privacy Standard compliance",
        expectedEvidence: "1.Documentation demonstrating compliance with privacy standards and regulations.\n2.Records of privacy assessments and audits conducted.\n3.Evidence of measures implemented to protect user privacy in AI systems."
    },
    {
        activityGroup: "Privacy & Security Goals",
        activity: "5.2 Security Policy compliance",
        expectedEvidence: "1.Documentation of compliance with security policies and standards.\n2.Records of security assessments and audits performed.\n3.Evidence of security controls implemented to safeguard AI systems against threats and breaches."
    },
    {
        activityGroup: "Inclusiveness Goal",
        activity: "6.1 Accessibility Standards compliance",
        expectedEvidence: "1.Documentation of compliance with accessibility standards and guidelines.\n2.Records of accessibility assessments conducted.\nEvidence of efforts to ensure AI 3.systems are accessible to users with diverse abilities and needs.\n\n\n\n"
    }
]

export const MODEL_AI_GOV_FRAMEWORK_SEC_EDITION_DATA = [
    {
        activityGroup: "Introduction",
        activity: "1.1 Objectives",
        expectetdEvidence: "1.Statement outlining the objectives of the AI governance framework, such as ensuring ethical AI deployment, promoting transparency, and managing risks.\n2.Explanation of the goals and desired outcomes of implementing the framework."
    },
    {
        activityGroup: "Introduction",
        activity: "1.2 Guiding Principles",
        expectetdEvidence: "1.List of guiding principles guiding the development and implementation of the AI governance framework, such as fairness, transparency, accountability, and privacy.\n2.Description of each principle and its importance in governing AI systems."
    },
    {
        activityGroup: "Introduction",
        activity: "1.3 Assumptions",
        expectetdEvidence: "1.Identification of key assumptions underlying the AI governance framework, such as the availability of relevant data, stakeholder cooperation, and regulatory compliance.\n2.Explanation of how these assumptions influence the design and implementation of the framework."
    },
    {
        activityGroup: "Introduction",
        activity: "1.4 Definitions",
        expectetdEvidence: "1.Definitions of key terms and concepts used in the AI governance framework, such as AI, governance, stakeholders, and decision-making processes.\n2.Clarification of terminology to ensure common understanding among stakeholders."
    },
    {
        activityGroup: "Model AI Governance Framework",
        activity: "2.1 Model AI Governace Framework",
        expectetdEvidence: "1.Description of the overarching structure and components of the AI governance framework, including its purpose, scope, and key elements.\n2.Overview of how the framework is organized and how it addresses various aspects of AI governance."
    },
    {
        activityGroup: "Model AI Governance Framework",
        activity: "2.2 Internal Governance AI Governace Framework",
        expectetdEvidence: "1.Explanation of the internal governance structures and processes established within organizations to govern AI systems.\n2.Description of roles, responsibilities, and decision-making mechanisms related to AI governance."
    },
    {
        activityGroup: "Model AI Governance Framework",
        activity: "2.3 Determining The Level Of Human Involvement In Aiaugmented Decision-Making",
        expectetdEvidence: "1.Methodology for determining the appropriate level of human involvement in decision-making processes augmented by AI systems.\n2.Factors considered in assessing the need for human intervention and oversight."
    },
    {
        activityGroup: "Model AI Governance Framework",
        activity: "2.4 What Are The Three Broad Approches Of Human Involvement In AI-Augmented Decision-Making",
        expectetdEvidence: "1.Explanation of three broad approaches to human involvement in decision-making processes augmented by 2.AI: direct control, oversight, and validation.\nDescription of each approach and its implications for AI governance."
    },
    {
        activityGroup: "Model AI Governance Framework",
        activity: "2.5 Operatuons Management ",
        expectetdEvidence: "1.Strategies and practices for managing AI operations effectively, including data management, model monitoring, and performance evaluation.\n2.Documentation of operational procedures and protocols for maintaining AI systems."
    },
    {
        activityGroup: "Model AI Governance Framework",
        activity: "2.6 Stakeholder Interaction And Communication",
        expectetdEvidence: "1.Plan for stakeholder engagement and communication throughout the AI governance process, including regular updates, feedback mechanisms, and conflict resolution procedures.\n2.Examples of stakeholder engagement activities and communication channels."
    },
    {
        activityGroup: "Model AI Governance Framework",
        activity: "2.7 Conclusion",
        expectetdEvidence: "1.Summary of key points discussed in the Model AI Governance Framework section.\n2.Reflection on the importance of effective AI governance for achieving ethical and responsible AI deployment."
    },
    {
        activityGroup: "Annex A",
        activity: "3.1 Compilation Of Existing AI Ethical Principles",
        expectetdEvidence: "1.Compilation of existing AI ethical principles from various sources, such as international organizations, industry bodies, and academic institutions.\n2.Analysis of common themes and differences among different sets of ethical principles."
    },
    {
        activityGroup: "Annex B",
        activity: "3.2 Algorithm Audits",
        expectetdEvidence: "1.Description of algorithm audit processes and methodologies used to assess the fairness, transparency, and accountability of AI algorithms.\n2.Examples of algorithm audit frameworks and case studies illustrating their application in practice."
    }
]

export const TRUSTWORTHY_AI_PLAYBOOK_DATA = [
    {
        activityGroup: "Introduction",
        activity: "1.1 Background  Why is Trustworthy AI (TAI) Important",
        expectedEvidence: " Reports, articles, or studies ighlighting the importance of Trustworthy AI.\nCase studies demonstrating the impact of untrustworthy AI on organizations or society."
    },
    {
        activityGroup: "Introduction",
        activity: "1.2 Background  Executive Order 13960 ",
        expectedEvidence: "Executive Order documentation or official government publications.\nAnalysis or interpretations of the Executive Order within the context of AI governance."
    },
    {
        activityGroup: "Introduction",
        activity: "1.3 HHS Trustworthy AI (TAI) Playbook Overview",
        expectedEvidence: "Overview documentation or presentations explaining the structure and purpose of the TAI Playbook.\nCommunication materials used to introduce the TAI Playbook within the organization."
    },
    {
        activityGroup: "Introduction",
        activity: "1.4 How to Use This Playbook",
        expectedEvidence: "User guides or instructions detailing how stakeholders should utilize the TAI Playbook.\nTraining materials or workshops conducted to educate stakeholders on using the Playbook effectively."
    },
    {
        activityGroup: "AI Building Blocks",
        activity: "2.1 AI Definition",
        expectedEvidence: "Definitions of AI from authoritative sources such as government agencies or industry standards organizations.\nInternal documents or policies defining AI within the organization."
    },
    {
        activityGroup: "AI Building Blocks",
        activity: "2.2 AI Building Blocks & TAI  Overview",
        expectedEvidence: "Documentation explaining the relationship between AI building blocks and Trustworthy AI principles.\nPresentations or training materials illustrating the integration of TAI principles into AI development processes."
    },
    {
        activityGroup: "AI Building Blocks",
        activity: "2.3 AI Building Blocks & TAI  AI Methods",
        expectedEvidence: "Overview of AI methods and techniques used within the organization.\nAssessment reports detailing how AI methods align with TAI principles."
    },
    {
        activityGroup: "AI Building Blocks",
        activity: "2.4 AI Building Blocks & TAI            Benefits Claims Center Bot Use Case",
        expectedEvidence: "Case study or scenario illustrating the application of TAI principles within a specific AI use case.\nDocumentation demonstrating the benefits of integrating TAI principles into AI development."
    },
    {
        activityGroup: "Principles For Use Of Trustworty AI In Goverment",
        activity: "3.1 Overview of TAI Principles ",
        expectedEvidence: "Documentation providing an overview of the Trustworthy AI (TAI) principles adopted by the government entity or organization.\nExplanation of the rationale behind the selection of these principles and their significance in promoting ethical and responsible AI use."
    },
    {
        activityGroup: "Principles For Use Of Trustworty AI In Goverment",
        activity: "3.2 Alignment to Federal Guidelines",
        expectedEvidence: "Evidence demonstrating how the TAI principles align with existing federal guidelines, regulations, or policies related to AI governance and ethics.\nComparative analysis highlighting the correspondence between TAI principles and federal mandates."
    },
    {
        activityGroup: "Principles For Use Of Trustworty AI In Goverment",
        activity: "3.3 How to Use This Section",
        expectedEvidence: "User guide or instructions explaining how stakeholders should interpret and apply the TAI principles outlined in the document.\nExamples or case studies illustrating the practical implementation of these principles in AI projects or initiatives."
    },
    {
        activityGroup: "Principles For Use Of Trustworty AI In Goverment",
        activity: "3.4 Fair \/ Impartial",
        expectedEvidence: "Documentation outlining specific measures or practices implemented to ensure fairness and impartiality in AI systems.\nReports or studies assessing the fairness of AI algorithms and decision-making processes."
    },
    {
        activityGroup: "Principles For Use Of Trustworty AI In Goverment",
        activity: "3.5 Transparent \/ Explainable",
        expectedEvidence: "Documentation of transparency measures adopted to make AI systems more understandable and explainable to stakeholders.\nExplanations of how AI decisions are communicated to users and affected parties."
    },
    {
        activityGroup: "Principles For Use Of Trustworty AI In Goverment",
        activity: "3.6 Responsible \/ Accountable ",
        expectedEvidence: "Records of accountability mechanisms established to ensure responsible AI development, deployment, and usage.\nDocumentation of roles and responsibilities assigned to stakeholders for overseeing AI initiatives."
    },
    {
        activityGroup: "Principles For Use Of Trustworty AI In Goverment",
        activity: "3.7 Safe \/ Secure",
        expectedEvidence: "Evidence demonstrating the implementation of safety and security measures to mitigate risks associated with AI systems.\nReports or assessments verifying the robustness of AI systems against potential threats or vulnerabilities."
    },
    {
        activityGroup: "Principles For Use Of Trustworty AI In Goverment",
        activity: "3.8 Privacy",
        expectedEvidence: "Documentation outlining privacy protection measures integrated into AI systems to safeguard personal data.\nCompliance records demonstrating adherence to relevant privacy regulations and guidelines.\n"
    },
    {
        activityGroup: "Principles For Use Of Trustworty AI In Goverment",
        activity: "3.9 Robust \/ Reliable",
        expectedEvidence: "Documentation of measures taken to enhance the robustness and reliability of AI systems, such as rigorous testing and validation procedures.\nReports or evaluations assessing the performance and reliability of AI algorithms in various scenarios."
    },
    {
        activityGroup: "Internal AI Deployment Considerations",
        activity: "4.1 AI LIFECYCLE OVERVIEW",
        expectedEvidence: "Documented overview of the AI lifecycle within the organization, outlining the stages from concept to deployment and beyond.\nDescription of the key activities and decision points within each stage of the AI lifecycle."
    },
    {
        activityGroup: "Internal AI Deployment Considerations",
        activity: "4.2 AI Lifecycle",
        expectedEvidence: "Detailed documentation or workflow diagrams illustrating the specific phases and steps involved in the AI lifecycle.\nExplanation of how different teams or stakeholders are involved at various stages of the AI lifecycle."
    },
    {
        activityGroup: "Internal AI Deployment Considerations",
        activity: "4.3 Application of TAI Principles Across the AI Lifecycle",
        expectedEvidence: "Documentation showing how each of the TAI principles (fairness, transparency, responsibility, safety, privacy, robustness) is applied throughout the AI lifecycle.\nExamples or case studies demonstrating the integration of TAI principles into different stages of AI development and deployment."
    },
    {
        activityGroup: "Initiation & Concept Overview Lifecycle      Phase 1",
        activity: "5.1 How to Use This Section (Lifecycle Phase I)",
        expectedEvidence: "User guide or instructions explaining how stakeholders should navigate and utilize the information provided in the Initiation & Concept Overview Lifecycle Phase 1 section.\nClarification of terminology and concepts used in this phase of the AI lifecycle."
    },
    {
        activityGroup: "Initiation & Concept Overview Lifecycle      Phase 1",
        activity: "5.2 Initiation and Concept | TAI Considerations",
        expectedEvidence: "Analysis or guidelines outlining the specific TAI considerations that should be addressed during the initiation and concept phases of the AI lifecycle.\nIdentification of potential ethical or societal implications associated with AI projects at the early stages."
    },
    {
        activityGroup: "Initiation & Concept Overview Lifecycle      Phase 1",
        activity: "5.3 Initiation and Concept  Acquisition Approaches",
        expectedEvidence: "Documentation detailing different approaches to acquiring AI technologies or solutions during the initiation and concept phases.\nConsideration of factors such as build vs. buy decisions, vendor selection criteria, and procurement processes."
    },
    {
        activityGroup: "Initiation & Concept Overview Lifecycle      Phase 1",
        activity: "5.4 Initiation and Concept |Risk Review Checklist",
        expectedEvidence: "Checklist or framework for conducting risk assessments specifically tailored to the initiation and concept phases of the AI lifecycle.\nDocumentation of identified risks and potential mitigation strategies during project initiation and concept development."
    },
    {
        activityGroup: "How To Use The Deployment Lifecycle Phase II-IV",
        activity: "6.1 How to Use This Section (Lifecycle Phases II-IV)",
        expectedEvidence: "User guide or instructions detailing how stakeholders should utilize the information provided in the Deployment Lifecycle Phase II-IV section.\nInstructions on navigating the section, accessing relevant documents or resources, and understanding the purpose of each subsection."
    },
    {
        activityGroup: "How To Use The Deployment Lifecycle Phase II-IV",
        activity: "6.2 Section Overview: A) Considerations for Applying TAI Principles",
        expectedEvidence: "Documented considerations or guidelines for applying Trustworthy AI (TAI) principles during deployment phases II-IV.\nExplanation of how each TAI principle (fairness, transparency, responsibility, safety, privacy, robustness) should be considered and integrated into the deployment process."
    },
    {
        activityGroup: "How To Use The Deployment Lifecycle Phase II-IV",
        activity: "6.3 Section Overview: B) Sample AI Use Case",
        expectedEvidence: "Description of a sample AI use case relevant to deployment phases II-IV, including its objectives, stakeholders, and context.\nDetailed overview of how TAI principles or other considerations are applied within the sample use case.\nAnalysis of potential benefits, risks, and lessons learned from the sample use case."
    },
    {
        activityGroup: "How To Use The Deployment Lifecycle Phase II-IV",
        activity: "6.4 Section Overview: C) Risk Review Checklist",
        expectedEvidence: "Risk review checklist or framework designed to assess potential risks associated with AI deployment during phases II-IV.\nCriteria or questions included in the checklist for evaluating risks related to TAI principles, technical aspects, operational considerations, and stakeholder impacts."
    },
    {
        activityGroup: "Research & Design Lifecycle                        Phase II",
        activity: "7.1 Research and Design Overview of How TAI Principles Are Applied",
        expectedEvidence: "Documentation providing an overview of how Trustworthy AI (TAI) principles are integrated into the research and design phase of the AI lifecycle.\nExplanation of the significance of each TAI principle (fairness, transparency, responsibility, safety, privacy, robustness) in the research and design context."
    },
    {
        activityGroup: "Research & Design Lifecycle                        Phase II",
        activity: "7.2 Research and Design | Fair  Impartial Principle",
        expectedEvidence: "Documentation demonstrating how the fair and impartial principle is applied during the research and design phase.\nExamples or case studies illustrating measures taken to ensure fairness and impartiality in AI algorithms and decision-making processes."
    },
    {
        activityGroup: "Research & Design Lifecycle                        Phase II",
        activity: "7.3 Research and Design  Transparent \/ Explainable Principle",
        expectedEvidence: "Documentation outlining strategies for making AI systems transparent and explainable during the research and design phase.\nDescription of methods used to provide explanations for AI decisions and behaviors to stakeholders."
    },
    {
        activityGroup: "Research & Design Lifecycle                        Phase II",
        activity: "7.4 Research and Design  Responsible \/ Accountable Principle",
        expectedEvidence: "Evidence demonstrating how responsibility and accountability are addressed in the research and design phase.\nDocumentation of roles and processes established to ensure responsible AI development and deployment."
    },
    {
        activityGroup: "Research & Design Lifecycle                        Phase II",
        activity: "7.5 Research and Design | Safe  Secure Principle",
        expectedEvidence: "Documentation of safety and security measures integrated into AI systems during the research and design phase.\nReports or assessments verifying the safety and security of AI algorithms and architectures."
    },
    {
        activityGroup: "Research & Design Lifecycle                        Phase II",
        activity: "7.6 Research and Design Privacy Principle",
        expectedEvidence: "Documentation outlining privacy protection measures implemented during the research and design phase.\nCompliance records demonstrating adherence to privacy regulations and guidelines."
    },
    {
        activityGroup: "Research & Design Lifecycle                        Phase II",
        activity: "7.8 Research and Design  Robust Reliable Principle",
        expectedEvidence: "Documentation demonstrating how robustness and reliability are ensured during the research and design phase.\nReports or evaluations assessing the performance and reliability of AI algorithms in various scenarios."
    },
    {
        activityGroup: "Research & Design Lifecycle                        Phase II",
        activity: "7.9 Research and Design Sample Use Case",
        expectedEvidence: "Description of a sample AI use case relevant to the research and design phase, including its objectives, stakeholders, and context.\nDetailed overview of how TAI principles or other considerations are applied within the sample use case."
    },
    {
        activityGroup: "Research & Design Lifecycle                        Phase II",
        activity: "7.10 Research and Design | Risk Review Checklist",
        expectedEvidence: "Risk review checklist or framework designed to assess potential risks associated with AI research and design activities.\nCriteria or questions included in the checklist for evaluating risks related to TAI principles, technical aspects, and stakeholder impacts."
    },
    {
        activityGroup: "Develop,Train & Deploy Lifecycle                     Phase III",
        activity: "8.1 Develop, Train, & Deploy  Overview of How TAI Principles Are Applied",
        expectedEvidence: "Documentation providing an overview of how Trustworthy AI (TAI) principles are integrated into the development, training, and deployment phase of the AI lifecycle.\nExplanation of the significance of each TAI principle (fairness, transparency, responsibility, safety, privacy, robustness) in this phase."
    },
    {
        activityGroup: "Develop,Train & Deploy Lifecycle                     Phase III",
        activity: "8.2 Develop, Train, & Deploy  Fair \/ Impartial Principle",
        expectedEvidence: "Documentation demonstrating how the fair and impartial principle is applied during the development, training, and deployment phase.\nExamples or case studies illustrating measures taken to ensure fairness and impartiality in AI algorithms and deployment processes."
    },
    {
        activityGroup: "Develop,Train & Deploy Lifecycle                     Phase III",
        activity: "8.3 Develop, Train, & Deploy  Transparent \/ Explainable Principle",
        expectedEvidence: "Documentation outlining strategies for making AI systems transparent and explainable during the development, training, and deployment phase.\nDescription of methods used to provide explanations for AI decisions and behaviors to stakeholders."
    },
    {
        activityGroup: "Develop,Train & Deploy Lifecycle                     Phase III",
        activity: "8.4 Develop, Train, & Deploy  Responsible \/ Accountable Principle",
        expectedEvidence: "Evidence demonstrating how responsibility and accountability are addressed in the development, training, and deployment phase.\nDocumentation of roles and processes established to ensure responsible AI development, training, and deployment."
    },
    {
        activityGroup: "Develop,Train & Deploy Lifecycle                     Phase III",
        activity: "8.5 Develop, Train, & Deploy  Safe \/ Secure Principle",
        expectedEvidence: "Evidence demonstrating how responsibility and accountability are addressed in the development, training, and deployment phase.\nDocumentation of roles and processes established to ensure responsible AI development, training, and deployment."
    },
    {
        activityGroup: "Develop,Train & Deploy Lifecycle                     Phase III",
        activity: "8.6 Develop, Train, & Deploy  Privacy Principle",
        expectedEvidence: "Documentation outlining privacy protection measures implemented during the development, training, and deployment phase.\nCompliance records demonstrating adherence to privacy regulations and guidelines."
    },
    {
        activityGroup: "Develop,Train & Deploy Lifecycle                     Phase III",
        activity: "8.7 Develop, Train, & Deploy  Robust \/ Reliable Principle",
        expectedEvidence: "Documentation demonstrating how robustness and reliability are ensured during the development, training, and deployment phase.\nReports or evaluations assessing the performance and reliability of AI algorithms during deployment."
    },
    {
        activityGroup: "Develop,Train & Deploy Lifecycle                     Phase III",
        activity: "8.9 Develop, Train, & Deploy  Sample Use Case",
        expectedEvidence: "Description of a sample AI use case relevant to the development, training, and deployment phase, including its objectives, stakeholders, and context.\nDetailed overview of how TAI principles or other considerations are applied within the sample use case."
    },
    {
        activityGroup: "Develop,Train & Deploy Lifecycle                     Phase III",
        activity: "8.10 Develop, Train, & Deploy  Risk Review Checklist",
        expectedEvidence: "Risk review checklist or framework designed to assess potential risks associated with AI development, training, and deployment activities.\nCriteria or questions included in the checklist for evaluating risks related to TAI principles, technical aspects, and stakeholder impacts during this phase."
    },
    {
        activityGroup: "Operate & Maintain Lifecycle                       Phase IV",
        activity: "9.1 Operate & Maintain  Overview of How TAI Principles Are Applied",
        expectedEvidence: "Documentation providing an overview of how Trustworthy AI (TAI) principles are integrated into the operation and maintenance phase of the AI lifecycle.\nExplanation of the significance of each TAI principle (fairness, transparency, responsibility, safety, privacy, robustness) in this phase."
    },
    {
        activityGroup: "Operate & Maintain Lifecycle                       Phase IV",
        activity: "9.2 Operate & Maintain | Fair  Impartial Principle",
        expectedEvidence: "Documentation demonstrating how the fair and impartial principle is applied during the operation and maintenance phase.\nExamples or case studies illustrating measures taken to ensure fairness and impartiality in ongoing AI operations and maintenance activities."
    },
    {
        activityGroup: "Operate & Maintain Lifecycle                       Phase IV",
        activity: "9.3 Operate & Maintain  Transparent \/ Explainable Principle",
        expectedEvidence: "Documentation outlining strategies for maintaining transparency and explainability in AI systems during the operation and maintenance phase.\nDescription of methods used to provide explanations for AI decisions and behaviors to stakeholders during ongoing operations."
    },
    {
        activityGroup: "Operate & Maintain Lifecycle                       Phase IV",
        activity: "9.4 Operate & Maintain  Responsible \/ Accountable Principle",
        expectedEvidence: "Evidence demonstrating how responsibility and accountability are maintained during the operation and maintenance phase.\nDocumentation of roles and processes established to ensure ongoing responsible AI use and maintenance."
    },
    {
        activityGroup: "Operate & Maintain Lifecycle                       Phase IV",
        activity: "9.5 Operate & Maintain | Safe  Secure Principle",
        expectedEvidence: "Documentation of ongoing safety and security measures implemented in AI systems during the operation and maintenance phase.\nReports or assessments verifying the continued safety and security of AI systems during operations."
    },
    {
        activityGroup: "Operate & Maintain Lifecycle                       Phase IV",
        activity: "9.6 Operate &Maintain| Privacy Principle",
        expectedEvidence: "Documentation outlining ongoing privacy protection measures maintained during the operation and maintenance phase.\nCompliance records demonstrating continued adherence to privacy regulations and guidelines."
    },
    {
        activityGroup: "Operate & Maintain Lifecycle                       Phase IV",
        activity: "9.7 Operate & Maintain |Robust \/ Reliable Principle",
        expectedEvidence: "Documentation demonstrating how robustness and reliability are maintained during the operation and maintenance phase.\nReports or evaluations assessing the continued performance and reliability of AI systems during ongoing operations."
    },
    {
        activityGroup: "Operate & Maintain Lifecycle                       Phase IV",
        activity: "9.8 Operate & Maintain  Sample Use Case",
        expectedEvidence: "Description of a sample AI use case relevant to the operation and maintenance phase, including ongoing objectives, stakeholders, and context.\nDetailed overview of how TAI principles or other considerations are maintained in the sample use case during ongoing operations and maintenance."
    },
    {
        activityGroup: "Operate & Maintain Lifecycle                       Phase IV",
        activity: "9.9 Operate & Maintain | Risk Review Checklist",
        expectedEvidence: "Ongoing risk review checklist or framework designed to assess potential risks associated with ongoing AI operation and maintenance activities.\nCriteria or questions included in the checklist for evaluating ongoing risks related to TAI principles, technical aspects, and stakeholder impacts during this phase."
    },
    {
        activityGroup: "External AI Considerations",
        activity: "10.1 How to Use This Section ",
        expectedEvidence: "User guide or instructions detailing how stakeholders should navigate and utilize the information provided in the External AI Considerations section.\nInstructions on how to apply regulatory and non-regulatory considerations to AI projects, including compliance requirements and best practices."
    },
    {
        activityGroup: "External AI Considerations",
        activity: "10.2 Regulatory Considerations",
        expectedEvidence: "Documentation outlining relevant regulatory frameworks, laws, and guidelines that govern AI deployment in the organization's jurisdiction or industry.\nAnalysis of how regulatory requirements impact AI projects, including compliance obligations, reporting requirements, and potential legal risks."
    },
    {
        activityGroup: "External AI Considerations",
        activity: "10.3 Non-Regulatory Considerations",
        expectedEvidence: "Analysis of how non-regulatory considerations influence decision-making and risk management in AI projects.\nStrategies for addressing non-regulatory challenges, including stakeholder engagement, ethical guidelines, and reputation management"
    },
    {
        activityGroup: "Appendix I                 Background           Information ",
        activity: "11.1 Federal Actions Accelerating Trustworthy AI Adoption",
        expectedEvidence: "Analysis of the impact of federal actions on promoting TAI adoption, including case studies or examples illustrating successful implementations.\nReports or studies evaluating the effectiveness of federal initiatives in advancing TAI adoption and addressing associated challenges."
    },
    {
        activityGroup: "Appendix I                 Background           Information ",
        activity: "11.2 Non-AI Building Block | Blockchain",
        expectedEvidence: "Information outlining the relevance and potential applications of blockchain technology in the context of AI development and deployment.\nAnalysis of how blockchain technology can complement AI solutions, enhance transparency, security, and data integrity."
    },
    {
        activityGroup: "Appendix I                 Background           Information ",
        activity: "11.3 EO 13960 | Principles for Use of AI in Government ",
        expectedEvidence: "Analysis of the principles set forth in EO 13960 and their implications for government agencies and AI projects.\nGuidance on how government agencies can align their AI initiatives with the principles outlined in EO 13960"
    },
    {
        activityGroup: "Appendix I                 Background           Information ",
        activity: "11.4 OMB M-21-06 | Principles for the Stewardship of AI Applications",
        expectedEvidence: "\nAnalysis of the principles set forth in OMB M-21-06 and their relevance to AI governance, risk management, and compliance.\nGuidance on how organizations can implement the principles outlined in OMB M-21-06 to ensure responsible AI development, deployment, and management."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.1 Cost-Benefit Analysis",
        expectedEvidence: "Documentation outlining the methodology and results of the cost-benefit analysis conducted for the AI deployment project.\nAnalysis of the costs associated with AI implementation versus the expected benefits, including financial, operational, and societal impacts."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.2 AI Project Team Roles",
        expectedEvidence: "Description of the roles and responsibilities of team members involved in the AI deployment project.\nOrganizational chart or team structure outlining the hierarchy and reporting lines for the AI project team."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.3 Unintended Bias in Algorithms",
        expectedEvidence: "Documentation highlighting potential sources of bias in AI algorithms and models.\nStrategies for identifying, mitigating, and preventing unintended biases throughout the AI lifecycle."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.4 Bias Review Metrics          (1 of 2)",
        expectedEvidence: "Metrics and criteria used to evaluate and measure bias in AI algorithms and models.\nResults of bias review assessments conducted during the AI deployment project."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.5 Bias Review Metrics           (2 of 2) ",
        expectedEvidence: "Description of algorithms and techniques utilized to review and address bias in AI systems.\nEvaluation of the effectiveness of different bias review methods in reducing bias in AI algorithms."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.6 Bias Review Algorithms and Techniques",
        expectedEvidence: "Description of algorithms and techniques utilized to review and address bias in AI systems.\nEvaluation of the effectiveness of different bias review methods in reducing bias in AI algorithms."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.7 Explainability-Accuracy Tradeoff",
        expectedEvidence: "Explanation of the tradeoff between model explainability and accuracy in AI systems.\nStrategies for balancing explainability and accuracy to ensure transparency and performance in AI models."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.8 Interpretation Methods",
        expectedEvidence: "Methods and techniques used to interpret the decisions and outputs of AI models.\nDocumentation of interpretation results and their implications for stakeholders."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.9 Human Supervision of AI Solutions",
        expectedEvidence: "Description of human oversight and supervision mechanisms integrated into AI solutions.\nProcedures for human intervention in cases of AI errors or uncertainties."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.10 Key Roles for Managing AI Solutions",
        expectedEvidence: "Identification of key roles and responsibilities for managing AI solutions within the organization.\nDescription of the skills and expertise required for each role."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.11 Digital Worker Impact Evaluation Matrix (1 of 2)",
        expectedEvidence: "Matrix outlining the potential impact of AI deployment on digital workers, including job roles, skills, and employment patterns.\nEvaluation of the risks and opportunities associated with AI adoption for digital workers."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.12 Digital Worker Impact Evaluation Matrix (2 of 2) ",
        expectedEvidence: "Matrix outlining the potential impact of AI deployment on digital workers, including job roles, skills, and employment patterns.\nEvaluation of the risks and opportunities associated with AI adoption for digital workers."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.13 Potential Adverse Impact Levels",
        expectedEvidence: "Assessment of potential adverse impacts associated with AI deployment across different dimensions (e.g., societal, ethical, economic).\nClassification of adverse impact levels based on severity and likelihood."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.14 Types of Security Risks and Defenses",
        expectedEvidence: "Identification of common security risks associated with AI deployment and data handling.\nStrategies and defenses to mitigate security risks and protect AI systems from threats."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.15 Privacy Protection Methods",
        expectedEvidence: "Documentation of methods and techniques employed to protect user privacy in AI systems.\nCompliance with privacy regulations and standards, along with evidence of privacy-preserving measures implemented."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.16 Model Performance Testing Approaches",
        expectedEvidence: "Description of testing methodologies used to evaluate the performance of AI models.\nResults of model performance tests conducted during the AI deployment project."
    },
    {
        activityGroup: "Appendix II               Internal AI Deployment Considerations Supplementary Materials",
        activity: "12.17 Model Performance Metrics",
        expectedEvidence: "Metrics used to measure and assess the performance of AI models, such as accuracy, precision, recall, and F1 score.\nAnalysis of model performance metrics and their implications for AI system effectiveness."
    },
    {
        activityGroup: "Appendix III Statutory Authorities",
        activity: "13.1 Statutory Authorities          ",
        expectedEvidence: "Documentation of relevant statutory authorities governing AI deployment, data privacy, security, and other related areas.\nAnalysis of how statutory authorities impact AI projects and compliance requirements.\nEvidence of compliance with statutory authorities through legal opinions, certifications, or audit reports."
    }
]

export const SINGPORE_AI_GOV_FRAMEWORK_DATA = [
    {
        thrustArea: "Explainability",
        subControl: "a. Building an audit trail",
        recommendedEvidences: "Documentation of model training, AI-augmented decisions, and audit trails.",
        control: "3.36"
    },
    {
        thrustArea: "Explainability",
        subControl: "b. Implementing a black box recorder",
        recommendedEvidences: "Implementation of a black box recorder capturing input data streams.",
        control: "3.36"
    },
    {
        thrustArea: "Explainability",
        subControl: "c. Ensuring data relevant to traceability are stored...",
        recommendedEvidences: "Proper storage of traceability-relevant data, adherence to industry standards.",
        control: ""
    },
    {
        thrustArea: "Explainability",
        subControl: "-",
        recommendedEvidences: "Risk-based approach to assess traceability needs, considering harm probability and regulatory requirements.",
        control: "3.38"
    },
    {
        thrustArea: "Reproducibility",
        subControl: "a. Testing whether specific contexts...",
        recommendedEvidences: "Testing for specific contexts and conditions affecting reproducibility.",
        control: "3.39"
    },
    {
        thrustArea: "Reproducibility",
        subControl: "b. Putting in place verification methods...",
        recommendedEvidences: "Availability of replication files, verification methods, and checking with AI solution provider for reproducibility.",
        control: "3.4"
    },
    {
        thrustArea: "Reproducibility",
        subControl: "c. Making available replication files...",
        recommendedEvidences: "Adoption of repeatability measures, addressing exceptions and changes over time.",
        control: "3.41"
    },
    {
        thrustArea: "Auditability",
        subControl: "-",
        recommendedEvidences: "Readiness for AI system assessment, disclosure of information, and centralization of digital process logs.",
        control: "3.41"
    },
    {
        thrustArea: "Auditability",
        subControl: "-",
        recommendedEvidences: "Comprehensive record of data provenance, procurement, lineage, storage, and security for facilitating auditability.",
        control: "3.43"
    },
    {
        thrustArea: "Stakeholder Interaction",
        subControl: "-",
        recommendedEvidences: "General disclosure of AI usage, providing information on benefits, risk mitigation steps, and transparency in decision-making.",
        control: "3.46"
    },
    {
        thrustArea: "Stakeholder Interaction",
        subControl: "-",
        recommendedEvidences: "Development of a policy on explanations, ensuring consistency and clarity in communication with stakeholders.",
        control: "3.48"
    },
    {
        thrustArea: "Stakeholder Interaction",
        subControl: "-",
        recommendedEvidences: "Consideration of consumer information needs at different stages, transparency in AI-enabled features and decision-making.",
        control: "3.51"
    },
    {
        thrustArea: "Stakeholder Interaction",
        subControl: "-",
        recommendedEvidences: "Consideration of providing opt-out options based on risk, reversibility, cost, and technical feasibility.",
        control: "3.52"
    },
    {
        thrustArea: "Stakeholder Interaction",
        subControl: "-",
        recommendedEvidences: "Establishment of feedback channels, decision review channels, testing user interfaces, and easy-to-understand communication.",
        control: "3.54"
    },
    {
        thrustArea: "Stakeholder Interaction",
        subControl: "-",
        recommendedEvidences: "Implementation of acceptable user policies to prevent malicious manipulation of AI systems.",
        control: "3.57"
    },
    {
        thrustArea: "Interacting with Orgs",
        subControl: "-",
        recommendedEvidences: "Consideration of approaches when interacting with AI solution providers or other organizations, ensuring support and information flow.",
        control: "3.59"
    },
    {
        thrustArea: "Interacting with Orgs",
        subControl: "-",
        recommendedEvidences: "Evaluation of the level of support and information needed from AI solution providers for business objectives.",
        control: "3.6"
    },
    {
        thrustArea: "Ethical Evaluation",
        subControl: "-",
        recommendedEvidences: "Evaluation of AI governance practices against evolving AI standards, sharing outcomes with relevant stakeholders.",
        control: "3.62"
    },
    {
        thrustArea: "Readability",
        subControl: "-",
        recommendedEvidences: "Communication in an easy-to-understand manner, using tools like readability tests, visualizations, and graphical representations",
        control: "3.56"
    },
    {
        thrustArea: "Privacy",
        subControl: "a. Establishing data governance policies...",
        recommendedEvidences: "Documentation of data governance policies, privacy impact assessments, and compliance with relevant privacy regulations.",
        control: "3.25"
    },
    {
        thrustArea: "Privacy",
        subControl: "b. Implementing data access controls...",
        recommendedEvidences: "Implementation of robust data access controls, encryption, and mechanisms for user consent and data deletion.",
        control: "3.26"
    },
    {
        thrustArea: "Privacy",
        subControl: "c. Minimizing data collected and stored...",
        recommendedEvidences: "Documentation of data minimization practices, ensuring only necessary data is collected and stored.",
        control: "3.27"
    },
    {
        thrustArea: "Bias",
        subControl: "a. Identifying potential biases in data...",
        recommendedEvidences: "Documentation of bias identification methods, measures to minimize biases, and ongoing monitoring for bias.",
        control: "3.32"
    },
    {
        thrustArea: "Bias",
        subControl: "b. Addressing biases in the model development process...",
        recommendedEvidences: "Strategies for addressing biases during model development, including diverse training data and bias-aware algorithms.",
        control: "3.32"
    },
    {
        thrustArea: "Bias",
        subControl: "c. Continuous monitoring for biases...",
        recommendedEvidences: "Ongoing monitoring and reporting mechanisms for biases in AI models, with corrective actions implemented as needed.",
        control: "3.33"
    },
    {
        thrustArea: "Security",
        subControl: "-",
        recommendedEvidences: "Implementation of robust security measures, encryption, and mechanisms to protect AI models from tampering or data compromise.",
        control: "3.59"
    },
    {
        thrustArea: "Performance",
        subControl: "-",
        recommendedEvidences: "Documentation of performance benchmarks, testing, and optimization measures to ensure AI systems meet desired performance standards.",
        control: "3.15"
    },
    {
        thrustArea: "Robustness",
        subControl: "-",
        recommendedEvidences: "Implementation of measures to ensure AI systems are safe, secure, and not vulnerable to tampering, compromising data, or other threats.",
        control: "3.11"
    },
    {
        thrustArea: "Transparency",
        subControl: "-",
        recommendedEvidences: "Comprehensive record-keeping of design processes, decision-making, and disclosure of information to facilitate transparency.",
        control: "3.41"
    },
    {
        thrustArea: "Fairness",
        subControl: "-",
        recommendedEvidences: "Ensuring algorithmic decisions do not create discriminatory impacts, monitoring and accounting mechanisms for unintentional discrimination.",
        control: "3.37"
    },
    {
        thrustArea: "Accountability",
        subControl: "-",
        recommendedEvidences: "Building trust through responsibility and accountability for AI systems, providing external avenues of redress, and keeping detailed records.",
        control: "3.48"
    },
    {
        thrustArea: "Ethics",
        subControl: "-",
        recommendedEvidences: "Periodic evaluation of AI governance practices against evolving ethical standards, sharing outcomes with relevant stakeholders.",
        control: "3.62"
    }
]

export const USA_AI_GOV_FRAMEWORK_DATA = [
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Data Collection and Usage Consent",
        subControl: "Define Consent Mechanisms",
        evidence: "Document explicit consent mechanisms for data collection, especially for AI training and decision-making processes.",
        control: "Obtain Explicit Consent"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Data Minimization and Purpose Limitation",
        subControl: "Justify Data Collection",
        evidence: "Justify data collection based on the intended purpose, avoiding excessive data collection.",
        control: "Collect Necessary Data"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Transparency and Data Disclosure",
        subControl: "Clarify AI Processes",
        evidence: "Clearly inform users about how their data will be used, including AI-driven decision-making processes.",
        control: "Inform Users About Data Use"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Right to Access and Erasure",
        subControl: "Facilitate Data Access",
        evidence: "Allow individuals to access and request deletion of their data, with provisions for AI system compliance.",
        control: "Enable Data Access and Deletion"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Ethical and Fair Use",
        subControl: "Identify and Mitigate Biases",
        evidence: "Implement measures to identify and mitigate biases, especially for sensitive attributes.",
        control: "Mitigate Bias and Ensure Fairness"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Ethical and Fair Use",
        subControl: "Explain AI Decisions",
        evidence: "Provide explanations for AI-driven decisions to enhance transparency and accountability.",
        control: "Provide Transparency in Decision-Making"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Ethical and Fair Use",
        subControl: "Develop AI Ethics Guidelines",
        evidence: "Develop and adhere to ethical guidelines for AI use, ensuring responsible behavior and accountability.",
        control: "Establish Ethical Guidelines"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "High-Risk AI Systems",
        subControl: "Assess High-Risk AI Systems",
        evidence: "Identify and classify high-risk AI systems, assess potential negative impacts, and implement risk mitigation measures.",
        control: "Identify and Mitigate Risks"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "High-Risk AI Systems",
        subControl: "Implement Oversight Mechanisms",
        evidence: "Incorporate mechanisms for human intervention in high-risk AI systems to prevent erroneous or biased decisions.",
        control: "Incorporate Human Oversight"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "High-Risk AI Systems",
        subControl: "Comply with Regulations",
        evidence: "Ensure compliance with sector-specific regulations for AI systems.",
        control: "Ensure Sector-Specific Compliance"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Data Security and Storage",
        subControl: "Implement Data Security",
        evidence: "Implement robust data security measures, including encryption, to protect sensitive user data.",
        control: "Secure Data Storage and Encryption"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Data Security and Storage",
        subControl: "Perform Security Audits",
        evidence: "Conduct routine security audits and updates to address potential AI system vulnerabilities.",
        control: "Conduct Regular Security Audits"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Accountability and Governance",
        subControl: "Create AI Policies",
        evidence: "Develop and implement policies for responsible AI development, covering aspects like bias mitigation and transparency.",
        control: "Develop Responsible AI Policies"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Accountability and Governance",
        subControl: "Define Oversight Procedures",
        evidence: "Establish a clear governance framework for AI development and deployment.",
        control: "Establish Governance Framework"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Accountability and Governance",
        subControl: "Assess Societal Implications",
        evidence: "Conduct impact assessments, especially for high-risk AI systems, to evaluate societal, economic, and ethical implications.",
        control: "Conduct Impact Assessments"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "User Rights and Consent",
        subControl: "Inform Users about AI Impact",
        evidence: "Educate users about AI systems' impact and obtain informed consent before deploying AI-driven services.",
        control: "Educate Users and Obtain Consent"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "",
        subControl: "Offer Explanation Mechanisms",
        evidence: "Allow users to understand AI decisions through explanations and mechanisms for appeal.",
        control: "Provide Right to Explanation"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Regulatory Compliance",
        subControl: "Ensure Legal Compliance",
        evidence: "Ensure compliance with relevant national AI regulations and laws.",
        control: "Comply with National AI Regulations"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "",
        subControl: "Adhere to Data Transfer Regulations",
        evidence: "Comply with international data transfer regulations, considering data localization requirements.",
        control: "Ensure International Data Transfer Compliance"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Auditing and Documentation",
        subControl: "Document AI Systems",
        evidence: "Maintain comprehensive documentation of AI systems, including design, data, algorithms, and decision-making processes.",
        control: "Maintain AI System Documentation"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "",
        subControl: "Perform Audits",
        evidence: "Conduct internal and external audits to assess AI system compliance, security, and ethical practices.",
        control: "Conduct Internal and External Audits"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Continuous Improvement",
        subControl: "Monitor AI System Performance",
        evidence: "Implement mechanisms for continuous monitoring, collect user feedback, and address emerging issues or biases.",
        control: "Implement Monitoring and Feedback"
    },
    {
        regulatoryFramework: "US AI Congress Blue Print",
        controlArea: "Continuous Improvement",
        subControl: "Stay Updated with Regulations",
        evidence: "Stay updated with evolving AI regulations and adapt AI systems to ensure continuous compliance.",
        control: "Adapt to Regulatory Changes"
    }
]

export const EUROPE_AI_REGULATION_ACT_DATA = [
    {
        controlArea: "Classification of AI Systems as High-Risk",
        controlName: "Classification Rules for High-Risk AI Systems",
        subcontrolName: "Conditions for High-Risk Classification",
        articles: "Article 6",
        expectedEvidences: "Documentation of Risk Assessment Methodology"
    },
    {
        controlArea: "Classification of AI Systems as High-Risk",
        controlName: "Classification Rules for High-Risk AI Systems",
        subcontrolName: "Additional High-Risk AI Systems",
        articles: "Article 7",
        expectedEvidences: "List of Additional High-Risk AI Systems"
    },
    {
        controlArea: "Requirements for High-Risk AI Systems",
        controlName: "Compliance with the Requirements",
        subcontrolName: "Risk Management System",
        articles: "Article 8",
        expectedEvidences: "Documentation of Risk Management Plan"
    },
    {
        controlArea: "Requirements for High-Risk AI Systems",
        controlName: "Compliance with the Requirements",
        subcontrolName: "Testing and Evaluation",
        articles: "Article 8",
        expectedEvidences: "Records of Testing and Evaluation"
    },
    {
        controlArea: "Data and Data Governance",
        controlName: "Data and Data Governance for High-Risk AI Systems",
        subcontrolName: "Quality Criteria for Training Data",
        articles: "Article 10",
        expectedEvidences: "Documentation of Data Quality Criteria for Training Data"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Technical Documentation",
        articles: "Article 11",
        expectedEvidences: "Technical Documentation for High-Risk AI Systems"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Record-keeping",
        articles: "Article 12",
        expectedEvidences: "Documentation of Compliance Records"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Transparency and Provision of Information to Users",
        articles: "Article 13",
        expectedEvidences: "User Documentation"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Human Oversight",
        articles: "Article 14",
        expectedEvidences: "Documentation of Human Oversight Mechanisms"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Accuracy, Robustness, and Cybersecurity",
        articles: "Article 15",
        expectedEvidences: "Technical Documentation"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Obligations of Providers",
        articles: "Article 16",
        expectedEvidences: "Provider Obligations Documentation"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Obligations of Users",
        articles: "Article 17",
        expectedEvidences: "User Obligations Documentation"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Conformity Assessment Procedures",
        articles: "Article 18",
        expectedEvidences: "Documentation of Conformity Assessmen"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Corrective Actions",
        articles: "Article 20",
        expectedEvidences: "Documentation of Corrective Action Plans"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Notification of Non-compliance",
        articles: "Article 21",
        expectedEvidences: "Documentation of Non-compliance Notifications"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Communication of Information to Users",
        articles: "Article 22",
        expectedEvidences: "User Communication Documentation"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Cooperation with Notified Bodies",
        articles: "Article 23",
        expectedEvidences: "Documentation of Cooperation Agreements"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Post-market Monitoring System",
        articles: "Article 24",
        expectedEvidences: "Documentation of Post-market Monitoring Procedures"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Transparency in the Use of AI Systems",
        articles: "Article 25",
        expectedEvidences: "Transparency Documentation"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Protection of Trade Secrets",
        articles: "Article 26",
        expectedEvidences: "Trade Secrets Protection Documentation"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Provisions Regarding Data",
        articles: "Article 27",
        expectedEvidences: "Documentation of Data Provisions"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Compliance of Documentation with Data Protection Legislation",
        articles: "Article 28",
        expectedEvidences: "Documentation of Compliance with Data Protection Legislation"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Assessment of High-Risk AI Systems by Notified Bodies",
        articles: "Article 29",
        expectedEvidences: "Notified Body Assessment Documentation"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Safeguards for Confidential Information",
        articles: "Article 30",
        expectedEvidences: "Documentation of Confidential Information Safeguards"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Competent Authorities' Powers",
        articles: "Article 31",
        expectedEvidences: "Documentation of Competent Authorities' Powers"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Penalties for Non-Compliance",
        articles: "Article 32",
        expectedEvidences: "Documentation of Penalties for Non-Compliance"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Union Mechanism for Monitoring and Enforcement",
        articles: "Article 33",
        expectedEvidences: "Documentation of Monitoring and Enforcement Mechanisms"
    },
    {
        controlArea: "Title III",
        controlName: "High-Risk AI Systems",
        subcontrolName: "Classification Rules",
        articles: "Article 6",
        expectedEvidences: "Documentation of Classification Criteria"
    },
    {
        controlArea: "Title III",
        controlName: "High-Risk AI Systems",
        subcontrolName: "Amendments to Annex III",
        articles: "Article 7",
        expectedEvidences: "Records of legislative or regulatory updates that led to amendments to Annex III"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Compliance with Requirements",
        articles: "Article 8",
        expectedEvidences: "Documentation of Compliance Procedures"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Risk Management System",
        articles: "Article 9",
        expectedEvidences: "Documentation of Risk Management Procedures"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Data and Data Governance",
        articles: "Article 10",
        expectedEvidences: "Documentation of Data Governance Framework"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Technical Documentation",
        articles: "Article 11",
        expectedEvidences: "Documentation of Technical Specifications"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Record-Keeping",
        articles: "Article 12",
        expectedEvidences: "Documentation of Record-Keeping Procedures"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Transparency and Provision of Information",
        articles: "Article 13",
        expectedEvidences: "Documentation of Transparency Measures"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Human Oversight",
        articles: "Article 14",
        expectedEvidences: "Provide documentation outlining the mechanisms"
    },
    {
        controlArea: "Title III",
        controlName: "Requirements for High-Risk AI Systems",
        subcontrolName: "Accuracy, Robustness, and Cybersecurity",
        articles: "Article 15",
        expectedEvidences: "Documentation of Technical Measures"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Obligations of Providers",
        articles: "Article 16",
        expectedEvidences: "Documentation of providers Obligations"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Quality Management System",
        articles: "Article 17",
        expectedEvidences: "Documentation of corrective and preventive actions taken"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Technical Documentation",
        articles: "Article 11",
        expectedEvidences: "Documentation of Technical Specifications"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Record-Keeping",
        articles: "Article 12",
        expectedEvidences: "Policies and procedures detailing the types of records to be maintained"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Conformity Assessment",
        articles: "Article 19",
        expectedEvidences: "Records of conformity assessments conducted by notified bodies or competent authorities to verify compliance of AI systems with regulatory requirements."
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Registration Obligations",
        articles: "Article 51",
        expectedEvidences: "Evidence of communication with regulatory authorities"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Corrective Actions",
        articles: "Article 16(g)",
        expectedEvidences: "Documentation of Corrective Action Plans"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Notification of Non-Compliance",
        articles: "Article 16(h)",
        expectedEvidences: "Evidence of communication channels established for reporting non-compliance incidents"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "CE Marking",
        articles: "Article 16(i)",
        expectedEvidences: "Documentation of CE Marking Compliance"
    },
    {
        controlArea: "Title III",
        controlName: "Obligations of Providers and Users",
        subcontrolName: "Demonstrate Conformity",
        articles: "Article 16(j)",
        expectedEvidences: "Technical documentation detailing"
    },
    {
        controlArea: "Title III",
        controlName: "Market Surveillance",
        subcontrolName: "General Obligations",
        articles: "Article 50",
        expectedEvidences: "Documentation of Market Surveillance Activities"
    },
    {
        controlArea: "Title III",
        controlName: "Market Surveillance",
        subcontrolName: "Surveillance by Member States",
        articles: "Article 51",
        expectedEvidences: "Documentation of Member State Surveillance Activities"
    },
    {
        controlArea: "Title III",
        controlName: "Market Surveillance",
        subcontrolName: "Safeguard Procedure",
        articles: "Article 52",
        expectedEvidences: "Provide documentation outlining the safeguard procedures"
    },
    {
        controlArea: "Title III",
        controlName: "Penalties",
        subcontrolName: "Penalties for Non-Compliance",
        articles: "Article 71",
        expectedEvidences: "Evidence of communication efforts aimed at informing stakeholders"
    },
    {
        controlArea: "Title III",
        controlName: "Penalties",
        subcontrolName: "Penalties for Incorrect Use of CE Marking",
        articles: "Article 72",
        expectedEvidences: "Records of penalties, fines, sanctions, or other enforcement actions imposed on manufacturers, distributors, or users found to be incorrectly using the CE marking"
    },
    {
        controlArea: "Title III",
        controlName: "Penalties",
        subcontrolName: "Penalties for Obstruction of Market Surveillance Authorities",
        articles: "Article 73",
        expectedEvidences: "Provide documentation outlining penalties for obstruction of market surveillance authorities"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Board",
        subcontrolName: "Establishment and Tasks",
        articles: "Article 74",
        expectedEvidences: "Legal documents or regulations establishing the EAIB, including its composition, structure, and mandate."
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Board",
        subcontrolName: "Powers",
        articles: "Article 75",
        expectedEvidences: "Documentation of EAIB Powers"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Board",
        subcontrolName: "Independence",
        articles: "Article 76",
        expectedEvidences: "Documentation of EAIB Independence"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Board",
        subcontrolName: "Decisions",
        articles: "Article 77",
        expectedEvidences: "Documentation of EAIB Decisions"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Board",
        subcontrolName: "Appeals",
        articles: "Article 78",
        expectedEvidences: "Documentation of Appeal Procedures"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Board",
        subcontrolName: "Cooperation with National Competent Authorities",
        articles: "Documentation of Cooperation Activities",
        expectedEvidences: ""
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Board",
        subcontrolName: "Exchange of Information",
        articles: "Article 80",
        expectedEvidences: "Policies and procedures detailing the mechanisms for sharing information, data, and insights among EAIB members and relevant stakeholders"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Board",
        subcontrolName: "Confidentiality",
        articles: "Article 81",
        expectedEvidences: "Provide documentation demonstrating measures taken to ensure confidentiality"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Board",
        subcontrolName: "Work Program and Reports",
        articles: "Article 82",
        expectedEvidences: "Annual work programs outlining the EAIB's strategic objectives"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Registry",
        subcontrolName: "Establishment",
        articles: "Article 83",
        expectedEvidences: "Documentation of Registry Establishment"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Registry",
        subcontrolName: "Access to the Registry",
        articles: "Article 84",
        expectedEvidences: "Policies and procedures detailing who can access the registry, under what conditions, and for what purposes, including provisions for data protection and privacy"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Registry",
        subcontrolName: "Information in the Registry",
        articles: "Article 85",
        expectedEvidences: "Evidence of the registry's role in providing transparency, accountability, and public access to information on AI systems deployed within the EU market."
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Registry",
        subcontrolName: "Update of Information",
        articles: "Article 86",
        expectedEvidences: "Documentation of Information Update Procedures"
    },
    {
        controlArea: "Title III",
        controlName: "European Artificial Intelligence Registry",
        subcontrolName: "Availability of Information",
        articles: "Article 87",
        expectedEvidences: "Evidence of compliance with data protection regulations, privacy requirements, and security measures"
    },
    {
        controlArea: "Title III",
        controlName: "Delegated Acts and Implementing Acts",
        subcontrolName: "Adoption of Delegated Acts",
        articles: "Article 88",
        expectedEvidences: "Documentation of Delegated Act Adoption"
    },
    {
        controlArea: "Title III",
        controlName: "Delegated Acts and Implementing Acts",
        subcontrolName: "Adoption of Implementing Acts",
        articles: "Article 89",
        expectedEvidences: "Evidence of coordination with relevant stakeholders, including EU institutions, Member States, industry associations, and civil society organizations, in developing implementing acts."
    },
    {
        controlArea: "Title III",
        controlName: "Amendments to Existing Legislation",
        subcontrolName: "Amendments to Existing Legislation",
        articles: "Article 90",
        expectedEvidences: "Evidence of coordination with relevant stakeholders, including EU institutions, Member States, industry associations, and civil society organizations, in proposing and evaluating legislative amendments"
    },
    {
        controlArea: "Title III",
        controlName: "Transitional Provisions",
        subcontrolName: "Transitional Provisions",
        articles: "Article 91",
        expectedEvidences: "Evidence of compliance monitoring, enforcement actions, or support services provided to stakeholders during the transitional period to facilitate compliance with new AI regulations"
    },
    {
        controlArea: "Title III",
        controlName: "Repeals",
        subcontrolName: "Repeals",
        articles: "Article 92",
        expectedEvidences: "Evidence of communication with relevant stakeholders, including EU institutions, Member States, industry associations, and civil society organizations, regarding the repeal of existing legislation and its implications for compliance."
    },
    {
        controlArea: "Title III",
        controlName: "Entry into Force and Application",
        subcontrolName: "Entry into Force",
        articles: "Article 93",
        expectedEvidences: "Evidence of coordination efforts with relevant stakeholders, including EU institutions, Member States, industry associations, and civil society organizations, to ensure awareness and preparedness for the entry into force of the new regulations."
    },
    {
        controlArea: "Title III",
        controlName: "Entry into Force and Application",
        subcontrolName: "Application",
        articles: "Article 94",
        expectedEvidences: "Evidence of coordination efforts with relevant stakeholders, including EU institutions, Member States, industry associations, and civil society organizations, to ensure understanding and compliance with the application of the new regulations"
    }
]

export const SINGAPORE_AI_REGULATIONS_DATA = [
    {
      clauseSection: "2.7",
      sectionDescription: "This section outlines the principle of transparency in AI, emphasizing the importance of clarity and openness regarding AI systems' functionality, decision-making processes, and data usage.",
      expectedEvidences: "Documentation detailing the AI system's architecture, algorithms, and data sources. Explanations of how the AI system reaches its decisions. Transparency reports highlighting model performance, biases, and limitations."
    },
    {
      clauseSection: "2.8",
      sectionDescription: "Rationale for Using the Model Framework",
      expectedEvidences: "Explanation of the need to deploy AI technologies responsibly, considering their potential impact on individuals, society, markets, or economies. Identification of specific challenges or risks associated with AI decision-making and predictions"
    },
    {
      clauseSection: "2.8",
      sectionDescription: "Application of the Model Framework",
      expectedEvidences: "Documentation outlining how the Model Framework is applied throughout the AI deployment process. Examples or case studies demonstrating the integration of the Model Framework into AI projects or initiatives."
    },
    {
      clauseSection: "2.8",
      sectionDescription: "Assessment of AI Predictions/Decisions",
      expectedEvidences: "Procedures for evaluating the reliability and accuracy of AI predictions and decisions. Records of assessments conducted on AI outputs, including any discrepancies or uncertainties identified."
    },
    {
      clauseSection: "2.8",
      sectionDescription: "Mitigation of Unexplainable Aspects in AI Predictions",
      expectedEvidences: "Strategies or techniques employed to address the lack of explainability in certain AI predictions or decisions. Documentation of efforts to enhance interpretability or transparency in AI systems."
    },
    {
      clauseSection: "2.8",
      sectionDescription: "Consideration of Impact on Individuals and Society",
      expectedEvidences: "Documentation of measures taken to assess and mitigate potential negative impacts on individuals or societal groups affected by AI decisions. Reports on the ethical considerations and societal implications of deploying AI technologies."
    },
    {
      clauseSection: "2.8",
      sectionDescription: "Monitoring and Continuous Improvement of AI Deployments",
      expectedEvidences: "Processes for ongoing monitoring and evaluation of AI deployments to ensure compliance with ethical standards and regulatory requirements. Evidence of feedback loops and mechanisms for continuous improvement in AI systems."
    },
    {
      clauseSection: "2.8",
      sectionDescription: "Compliance with Regulatory and Ethical Standards",
      expectedEvidences: "Evidence of compliance with relevant laws, regulations, and industry standards governing the use of AI technologies. Documentation of ethical frameworks or guidelines adopted to guide AI deployment practices."
    },
    {
      clauseSection: "2.8",
      sectionDescription: "Stakeholder Engagement and Communication",
      expectedEvidences: "Records of stakeholder engagement activities, including consultations with impacted individuals, communities, or organizations. Communication strategies for informing stakeholders about the deployment of AI technologies and addressing concerns."
    },
    {
      clauseSection: "2.9",
      sectionDescription: "Identification and Compilation of Ethical Principles",
      expectedEvidences: "Documentation outlining the process of identifying and compiling ethical principles relevant to AI deployment. Annex A containing a compilation of AI ethical principles, if referenced."
    },
    {
      clauseSection: "2.9",
      sectionDescription: "Alignment with Corporate Values",
      expectedEvidences: "Review of existing corporate values and their alignment with the identified ethical principles for AI deployment. Documentation highlighting the incorporation of ethical principles into corporate value statements."
    },
    {
      clauseSection: "2.9",
      sectionDescription: "Integration with Corporate Risk Management Framework",
      expectedEvidences: "Review of existing corporate values and their alignment with the identified ethical principles for AI deployment. Documentation highlighting the incorporation of ethical principles into corporate value statements."
    },
    {
      clauseSection: "2.9",
      sectionDescription: "Incorporation into Corporate Governance Structures",
      expectedEvidences: "Documentation detailing how ethical principles for AI deployment are integrated into existing corporate governance structures. Evidence of updates or revisions made to corporate governance policies to include AI ethics."
    },
    {
      clauseSection: "2.9",
      sectionDescription: "Guidance for Adoption of AI within the Organization",
      expectedEvidences: "Procedures or guidelines provided to employees for adopting AI technologies in alignment with the incorporated ethical principles. Training programs or resources on ethical AI deployment made available to employees."
    },
    {
      clauseSection: "2.9",
      sectionDescription: "Monitoring and Enforcement Mechanisms for Ethical Compliance",
      expectedEvidences: "Establishment of mechanisms for monitoring and enforcing compliance with ethical principles in AI deployment. Records of audits or assessments conducted to ensure adherence to ethical guidelines."
    },
    {
      clauseSection: "2.9",
      sectionDescription: "Continuous Review and Improvement of Ethical Framework",
      expectedEvidences: "Processes for ongoing review and improvement of the ethical framework for AI deployment within the organization. Evidence of feedback loops and mechanisms for incorporating lessons learned into the ethical framework."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Importance of Good Data Management Practices",
      expectedEvidences: "Explanation of the significance of good data management practices in ensuring the reliability, accuracy, and fairness of machine learning models. Identification of potential risks associated with poor data management."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Data Collection and Acquisition",
      expectedEvidences: "Documentation outlining procedures for collecting and acquiring data for training machine learning models. Records of data sources, collection methods, and data quality assessments."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Data Preprocessing and Cleaning",
      expectedEvidences: "Steps taken to preprocess and clean raw data to ensure consistency, completeness, and accuracy. Documentation of preprocessing techniques applied (e.g., normalization, feature scaling)."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Data Annotation and Labeling",
      expectedEvidences: "Processes for annotating and labeling data for supervised learning tasks. Examples of annotated datasets used for training machine learning models."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Data Storage and Security",
      expectedEvidences: "Systems and protocols in place for storing and securing sensitive or proprietary data used in model training. Documentation of data storage mechanisms and security measures implemented."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Data Governance and Compliance",
      expectedEvidences: "Policies and procedures governing data usage, access, and sharing to ensure compliance with regulatory requirements. Records of data governance practices and compliance audits conducted."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Data Versioning and Tracking",
      expectedEvidences: "Mechanisms for versioning and tracking changes made to datasets used for training machine learning models. Evidence of version control systems or tools implemented for managing data."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Data Privacy and Confidentiality",
      expectedEvidences: "Measures taken to protect individuals' privacy and ensure confidentiality when handling personal or sensitive data. Documentation of privacy policies, anonymization techniques, and data access controls."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Data Quality Assessment and Monitoring",
      expectedEvidences: "Methods for assessing and monitoring the quality of data used in machine learning models. Records of data quality assessments and corrective actions taken to address issues identified."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Documentation and Metadata Management",
      expectedEvidences: "Documentation of datasets used for training machine learning models, including metadata describing dataset characteristics. Examples of metadata schemas or standards adopted for dataset documentation."
    },
    {
      clauseSection: "2.1O",
      sectionDescription: "Data Retention and Disposal Policies",
      expectedEvidences: "Policies and procedures governing the retention and disposal of data after model training or deployment. Records of data retention periods and procedures for data disposal in compliance with regulations."
    },
    {
      clauseSection: "2.11",
      sectionDescription: "Identification of Cybersecurity Risks",
      expectedEvidences: "Assessment of potential cybersecurity risks associated with AI-dependent systems and services. Documentation outlining the types of cyber-attacks that could lead to catastrophic failures."
    },
    {
      clauseSection: "2.11",
      sectionDescription: "Integration of Cybersecurity Measures",
      expectedEvidences: "Implementation of cybersecurity measures to mitigate risks and safeguard AI-dependent systems. Documentation of cybersecurity protocols, firewalls, encryption methods, and intrusion detection systems deployed."
    },
    {
      clauseSection: "2.11",
      sectionDescription: "Continuous Monitoring and Threat Detection",
      expectedEvidences: "Processes for continuous monitoring and threat detection to identify and respond to cyber threats in real-time. Records of cybersecurity monitoring activities and incident response procedures."
    },
    {
      clauseSection: "2.11",
      sectionDescription: "Resilience and Disaster Recovery Planning",
      expectedEvidences: "Development of resilience and disaster recovery plans to ensure business continuity in the event of a cyber-attack. Documentation of contingency plans, backup systems, and recovery procedures for AI-dependent systems."
    },
    {
      clauseSection: "2.11",
      sectionDescription: "Employee Training and Awareness",
      expectedEvidences: "Training programs and awareness campaigns to educate employees about cybersecurity best practices. Evidence of employee participation in cybersecurity training sessions and drills."
    },
    {
      clauseSection: "2.11",
      sectionDescription: "Third-Party Risk Management",
      expectedEvidences: "Assessment and management of cybersecurity risks associated with third-party vendors or partners involved in AI systems. Documentation of third-party security assessments and compliance checks."
    },
    {
      clauseSection: "2.11",
      sectionDescription: "Compliance with Regulatory Standards and Industry Guidelines",
      expectedEvidences: "Adherence to regulatory standards and industry guidelines related to cybersecurity in AI-dependent systems. Records of compliance audits and certifications obtained for cybersecurity measures implemented."
    },
    {
      clauseSection: "2.11",
      sectionDescription: "Incident Response and Post-Incident Analysis",
      expectedEvidences: "Established procedures for incident response and post-incident analysis to assess the impact of cyber-attacks and prevent future occurrences. Documentation of incident response plans and post-mortem analyses conducted."
    },
    {
      clauseSection: "2.11",
      sectionDescription: "Continuous Improvement and Adaptation",
      expectedEvidences: "Established procedures for incident response and post-incident analysis to assess the impact of cyber-attacks and prevent future occurrences. Documentation of incident response plans and post-mortem analyses conducted."
    },
    {
      clauseSection: "2.12",
      sectionDescription: "Compliance with Current Laws and Regulations",
      expectedEvidences: "Acknowledgment that adopting the Model Framework does not exempt organizations from complying with existing laws and regulations. Documentation of relevant laws and regulations applicable to data management and protection (e.g., PDPA, OECD Privacy Principles)."
    },
    {
      clauseSection: "2.12",
      sectionDescription: "Alignment with Accountability-Based Practices",
      expectedEvidences: "Explanation of how the Model Framework is accountability-based, emphasizing its focus on responsible data management and protection. Identification of accountability-based practices outlined in the Model Framework."
    },
    {
      clauseSection: "2.12",
      sectionDescription: "Integration with Data Management and Protection Laws",
      expectedEvidences: "Demonstration of how adopting the Model Framework assists organizations in implementing accountability-based practices aligned with data management and protection laws. Documentation of specific provisions within the Model Framework that align with requirements under the PDPA, OECD Privacy Principles, or other relevant regulations."
    },
    {
      clauseSection: "2.12",
      sectionDescription: "Documentation of Implemented Accountability Practices",
      expectedEvidences: "Records of accountability-based practices implemented by the organization in data management and protection. Evidence of policies, procedures, or controls established to ensure compliance with legal and regulatory requirements."
    },
    {
      clauseSection: "2.12",
      sectionDescription: "Audit Trails and Compliance Reports",
      expectedEvidences: "Audit trails or logs demonstrating adherence to accountability-based practices outlined in the Model Framework. Compliance reports or assessments documenting alignment with data management and protection laws."
    },
    {
      clauseSection: "2.12",
      sectionDescription: "Training and Awareness Programs",
      expectedEvidences: "Training programs or awareness campaigns conducted to educate employees about accountability-based practices and legal requirements. Evidence of employee participation in training sessions related to data management and protection."
    },
    {
      clauseSection: "2.12",
      sectionDescription: "Governance Structures Supporting Compliance",
      expectedEvidences: "Documentation of governance structures established to oversee compliance with data management and protection laws. Evidence of roles and responsibilities assigned for ensuring adherence to legal and regulatory requirements."
    },
    {
      clauseSection: "2.12",
      sectionDescription: "Continuous Improvement Mechanisms",
      expectedEvidences: "Processes for continuous improvement in data management and protection practices to adapt to evolving legal and regulatory landscapes. Evidence of updates or revisions made to policies and procedures in response to changes in laws or regulations."
    },
    {
      clauseSection: "2.12",
      sectionDescription: "External Validation and Certification",
      expectedEvidences: "Participation in external validation or certification programs to verify compliance with accountability-based practices and legal requirements. Certificates or attestations obtained confirming alignment with data management and protection laws."
    },
    {
      clauseSection: "2.13",
      sectionDescription: "Importance of Sector-Specific Laws and Guidelines",
      expectedEvidences: "Reference to industry-specific laws, regulations, or guidelines relevant to the sector. Mention of the \"FEAT Principles\" by the Monetary Authority of Singapore. Advising organizations to be mindful of such laws, regulations, and guidelines."
    },
    {
      clauseSection: "2.14",
      sectionDescription: "Illustration of Key Stakeholders",
      expectedEvidences: "Simplified diagram depicting key stakeholders involved in the AI adoption process. Explanation of stakeholder roles. Clarification that the adoption process applies universally and doesn't differentiate between business relationships."
    },
    {
      clauseSection: "2.15",
      sectionDescription: "Clarification of AI Terminology",
      expectedEvidences: "Recognition of variable definitions of terms in AI. Examples of terms with varied definitions. Emphasis on clarity and precision in defining terms within AI context."
    },
    {
      clauseSection: "3.1",
      sectionDescription: "Explanation of Key Areas in Model Framework",
      expectedEvidences: "Internal governance, human involvement, operations management, stakeholder interaction. Description of guidance provided in each area."
    },
    {
      clauseSection: "3.2",
      sectionDescription: "Flexibility of Model Framework",
      expectedEvidences: "Assertion of framework's flexibility. Recognition that not all elements may be applicable. Encouragement for selective adoption based on relevance."
    },
    {
      clauseSection: "3.3",
      sectionDescription: "Inclusion of Illustrations",
      expectedEvidences: "Inclusion of illustrations in each section to provide real-world examples. Purpose is to aid organizations in understanding how to apply practices."
    },
    {
      clauseSection: "3.4",
      sectionDescription: "Guidance on Internal Governance Structures",
      expectedEvidences: "Purpose: Assist organizations in establishing internal governance structures for overseeing AI integration. Importance: Ensure AI aligns with organizational goals and ethical standards."
    },
    {
      clauseSection: "3.5",
      sectionDescription: "Significance of Internal Governance",
      expectedEvidences: "Emphasis: Role of internal governance in overseeing AI utilization. Flexibility: Adapt or establish governance frameworks as needed. Example: Integration of AI risks into enterprise risk management."
    },
    {
      clauseSection: "3.6",
      sectionDescription: "Consideration of Governance Features",
      expectedEvidences: "Assessment: Determine suitable features for governance structures. Decentralization: Consider decentralized approach for addressing ethical considerations. Leadership Support: Secure sponsorship from top management and board."
    },
    {
      clauseSection: "3.7",
      sectionDescription: "Customization of Governance Structures",
      expectedEvidences: "Integration: Incorporate relevant features into governance structures. Tailoring: Customize frameworks to fit organizational needs and context."
    },
    {
      clauseSection: "3.8",
      sectionDescription: "Guidance on Human Oversight",
      expectedEvidences: "Purpose: Aid organizations in determining suitable human oversight levels for AI-augmented decision-making. Importance: Ensure accountability, transparency, and fairness."
    },
    {
      clauseSection: "3.9",
      sectionDescription: "Understanding Objectives for Human Oversight",
      expectedEvidences: "Importance: Clarity on AI usage objectives crucial for determining human oversight extent. Recommendation: Define commercial objectives for AI deployment."
    },
    {
      clauseSection: "3.10",
      sectionDescription: "Consideration of Societal Norms and Values",
      expectedEvidences: "Emphasis: Organizations operating internationally should consider regional differences in societal norms and values. Example: Varied acceptability of gaming advertisements."
    },
    {
      clauseSection: "3.11",
      sectionDescription: "Risks at Group Level",
      expectedEvidences: "Explanation: Some risks manifest only at group level in AI deployment. Example: Herding behavior due to widespread adoption of algorithms."
    },
    {
      clauseSection: "3.12",
      sectionDescription: "Alignment with Corporate Values",
      expectedEvidences: "Importance: Evaluate AI initiatives against corporate values. Recommendation: Assess alignment of AI deployment with core values."
    },
    {
      clauseSection: "3.13",
      sectionDescription: "Iterative Risk Management",
      expectedEvidences: "Emphasis: Iterative nature of identifying commercial objectives, risks, and human involvement in AI decision-making. Recommendation: Continuously identify, review, and mitigate AI-related risks."
    },
    {
      clauseSection: "3.14",
      sectionDescription: "Automated Decision-Making (ADM)",
      expectedEvidences: "Indicators: Lack of human involvement, clear documentation of automated processes, consistent decision outcomes."
    },
    {
      clauseSection: "3.14",
      sectionDescription: "Human-in-the-Loop (HITL)",
      expectedEvidences: "Indicators: Human intervention recorded, variation in decisions, feedback mechanisms for corrections."
    },
    {
      clauseSection: "3.15",
      sectionDescription: "Probability of Harm",
      expectedEvidences: "Indicators: Historical data, predictive models, risk assessments."
    },
    {
      clauseSection: "3.15",
      sectionDescription: "Severity of Harm",
      expectedEvidences: "Indicators: Impact assessments, legal/regulatory standards."
    },
    {
      clauseSection: "3.16",
      sectionDescription: "Definition of Harm across Sectors",
      expectedEvidences: "Examples: Hospital perspective (adverse medical outcomes) and clothing store perspective (customer dissatisfaction)."
    },
    {
      clauseSection: "3.17",
      sectionDescription: "Considerations Beyond Harm",
      expectedEvidences: "Emphasis: Compliance with laws and regulations, such as GDPR, dictating human oversight. Not solely reliant on harm factors."
    },
    {
      clauseSection: "3.18",
      sectionDescription: "Human Intervention in Safety-Critical Systems",
      expectedEvidences: "Example: Aviation industry mandates human oversight in critical flight phases despite AI involvement in autopilot systems."
    },
    {
      clauseSection: "3.19",
      sectionDescription: "Responsible Measures in Operational Phase",
      expectedEvidences: "Framework: Structured AI adoption process guiding planning, development, deployment, and operation stages."
    },
    {
      clauseSection: "3.20",
      sectionDescription: "Continuous Learning Cycle in AI Development",
      expectedEvidences: "Process: Non-linear, continuous cycle of learning and improvement in AI model development and deployment."
    },
    {
      clauseSection: "3.21",
      sectionDescription: "Iterative Algorithm Application",
      expectedEvidences: "Emphasis: Iterative application of algorithms like linear regression, decision trees, or neural networks on training datasets during deployment. Evidence: Description of iterative nature, continuous refinement of models."
    },
    {
      clauseSection: "3.22",
      sectionDescription: "Data Quality and Selection",
      expectedEvidences: "Emphasis: Importance of quality and selection of data from multiple sources for building AI models. Evidence: Responsibilities and decision-making authority of individuals involved in model training and selection."
    },
    {
      clauseSection: "3.23",
      sectionDescription: "Ownership and Accountability",
      expectedEvidences: "Requirement: Designation of internal departmental owner for each deployed AI model to oversee deployment and decision-making processes. Evidence: Role of internal owners in monitoring model performance and addressing bias or fairness issues."
    },
    {
      clauseSection: "3.24",
      sectionDescription: "Relevance of Data Accountability",
      expectedEvidences: "Consistency: Data accountability practices remain relevant regardless of data type (personal or non-personal). Evidence: Explanation of how these practices ensure reliability and ethical use of data in AI model training."
    },
    {
      clauseSection: "3.25",
      sectionDescription: "Enhancing Transparency of Algorithms",
      expectedEvidences: "Identification: Advice to identify impactful features or functionalities in AI models. Prioritization: Organizations should prioritize transparency measures based on effectiveness in building trust."
    },
    {
      clauseSection: "3.26",
      sectionDescription: "Explainability of AI Models",
      expectedEvidences: "Explanation: AI model explainability involves elucidating how algorithms function and arrive at predictions. Trust-Building: Explainability fosters understanding and trust among stakeholders."
    },
    {
      clauseSection: "3.27",
      sectionDescription: "Documentation of Model Training and Selection",
      expectedEvidences: "Accountability and Transparency: Documenting processes, decision rationale, and risk mitigation strategies enables organizations to provide accountability for AI systems. Evidence: Examples of documented processes in technical reports or audit trails."
    },
    {
      clauseSection: "3.28",
      sectionDescription: "Explanations for Non-Technical Users",
      expectedEvidences: "Enhanced Understanding: Providing explanations in non-technical terms, such as through counterfactuals, improves understanding and trust among non-technical users. Evidence: Examples of implicit explanation techniques implemented in AI systems."
    },
    {
      clauseSection: "3.29",
      sectionDescription: "Transparency vs. Security Balance",
      expectedEvidences: "Balancing Transparency and Security: In certain contexts, legal or security concerns may outweigh the benefits of algorithmic transparency. Evidence: Examples of industries or scenarios where transparency poses risks to security or privacy."
    },
    {
      clauseSection: "3.30",
      sectionDescription: "Focus on Repeatability",
      expectedEvidences: "Consistent Performance Assurance: Documenting repeatability demonstrates the model's ability to produce consistent outcomes. Evidence: Examples of repeatability documentation in deployment processes."
    },
    {
      clauseSection: "3.31",
      sectionDescription: "Evaluation of Robustness",
      expectedEvidences: "Evaluation of Error Handling: Documentation of system's ability to handle errors and cope with adverse conditions. Testing Under Stress: Evidence of testing under stressful conditions to assess performance."
    },
    {
      clauseSection: "3.32",
      sectionDescription: "Challenges in Enumerating Preconditions",
      expectedEvidences: "Documentation of Testing Procedures: Evidence of scenario-based testing to assess robustness against erroneous input. Adversarial Testing Results: Records of adversarial testing outcomes."
    },
    {
      clauseSection: "3.33",
      sectionDescription: "Risk Assessment for Continual Learning",
      expectedEvidences: "Risk Assessment Documentation: Evidence of assessments considering risks of continual learning. Identification of Potential Risks: Recognition of risks associated with continual learning, with a focus on unpredictable model behavior. Mitigation Strategies: Documentation of strategies to mitigate identified risks, such as implementing safeguards."
    },
    {
      clauseSection: "3.34",
      sectionDescription: "Regular Model Tuning Policy",
      expectedEvidences: "Policy Development: Evidence of internal policy development for regular model tuning. Process Implementation: Documentation of implemented model tuning processes, including data collection, retraining, and deployment. Model Refreshment Records: Evidence of regular model updates based on updated training datasets and changes in customer behavior or objectives."
    },
    {
      clauseSection: "3.35",
      sectionDescription: "Dynamic Testing for AI Systems",
      expectedEvidences: "Testing Methodologies: Documentation of testing methodologies assessing AI system performance in dynamic environments. Test Cases: Records of test cases designed to evaluate generalization and failure handling. Results Analysis: Evidence of test result analysis to identify areas for improvement and ensure alignment with production environment conditions."
    },
    {
      clauseSection: "3.36",
      sectionDescription: "Traceability Definition",
      expectedEvidences: "Decision and Process Traceability: Documentation of decisions and processes involved in AI model decision-making. Accessibility and Understandability: Evidence indicating accessible and understandable traceability information for relevant stakeholders."
    },
    {
      clauseSection: "3.37",
      sectionDescription: "Audit Trail Establishment",
      expectedEvidences: "Audit Trail Documentation: Records demonstrating the establishment of an audit trail for model training and AI-augmented decisions. Black Box Recorder Implementation: Evidence of black box recorder implementation and captured data streams. Data Storage and Retention Policies: Documentation outlining data storage and retention policies for traceability."
    },
    {
      clauseSection: "3.38",
      sectionDescription: "Traceability Assessment Criteria",
      expectedEvidences: "Assessment Criteria Documentation: Records outlining criteria for assessing traceability need in AI systems. Evaluation Results: Evidence of evaluation results indicating features requiring traceability and selected measures. Resource Allocation: Documentation of resource allocation considerations based on harm assessment, model usage history, and regulatory requirements."
    },
    {
      clauseSection: "3.39",
      sectionDescription: "Repeatability vs. Reproducibility",
      expectedEvidences: "Definition and Differentiation: Clear documentation explaining the difference between repeatability and reproducibility. Risk Assessment: Records of risk assessments identifying AI features requiring external reproducibility testing. Reproducibility Testing Plan: Outline of external reproducibility testing plan."
    },
    {
      clauseSection: "3.40",
      sectionDescription: "Reproducibility Testing Practices",
      expectedEvidences: "Testing Procedures: Documentation of reproducibility testing procedures. Verification Methods: Records of implemented verification methods. Replication Files: Availability of replication files facilitating testing and behavior reproduction."
    },
    {
      clauseSection: "3.41",
      sectionDescription: "Auditability Concept",
      expectedEvidences: "Audit Readiness Documentation: Records indicating readiness for internal or external audit assessment. Evaluation Reports: Availability of evaluation reports from internal or external audits. Responsibility of Design and Practices: Documentation demonstrating responsible design and practices. Justifiability of Outcomes: Evidence showcasing justifiability of AI outcomes."
    },
    {
      clauseSection: "3.42",
      sectionDescription: "Implementation of Auditability",
      expectedEvidences: "Risk Assessment Reports: Records of risk assessments for identifying AI features requiring auditability. Information Disclosure: Documentation of commercially sensitive information disclosure to external auditors. Alignment with Regulatory Requirements: Evidence of auditability implementation alignment with regulations or standards. Audit Reports: Reports generated from audits showcasing compliance."
    },
    {
      clauseSection: "3.43",
      sectionDescription: "Comprehensive Data Documentation",
      expectedEvidences: "Data Provenance Documentation: Detailed records outlining data origin and history. Procurement Records: Documentation of data procurement methods and sources. Preprocessing Logs: Logs detailing preprocessing steps applied to data."
    },
    {
      clauseSection: "3.44",
      sectionDescription: "Centralized Information Repository",
      expectedEvidences: "Comprehensive Data Compilation: Centralized repository containing detailed data information. Demonstration of Responsibility: Documentation showcasing responsible design and practices. Accessibility and Presentation: Strategies and tools for organizing and presenting data."
    },
    {
      clauseSection: "3.45",
      sectionDescription: "Stakeholder Relationship Strategies",
      expectedEvidences: "Comprehensive Strategy: Documentation of organization's approach to engaging stakeholders. Identification of Stakeholders: Clear identification of key stakeholders. Communication Channels: Description of communication channels and methods."
    },
    {
      clauseSection: "3.46",
      sectionDescription: "AI Usage Disclosure",
      expectedEvidences: "Clear Statements or Disclosures: Documentation indicating AI usage in products/services. AI Usage Descriptions: Reports or documentation outlining AI technologies used and their purposes. Case Studies: Examples demonstrating AI application in decision-making processes."
    },
    {
      clauseSection: "3.47",
      sectionDescription: "Explanation of AI Decision Impact",
      expectedEvidences: "Documentation of Policy: Formal policy or guideline outlining explanations for AI-augmented decisions. Roles and Responsibilities: Clear delineation of roles for providing explanations."
    },
    {
      clauseSection: "3.48",
      sectionDescription: "Stakeholder Trust Building",
      expectedEvidences: "Documentation of Stakeholder Relationship Strategies: Plans for engaging with stakeholders. Communication Records: Evidence of transparent and open communication. Feedback Surveys: Surveys assessing effectiveness of communication strategies."
    },
    {
      clauseSection: "3.49",
      sectionDescription: "Stakeholder Information Needs",
      expectedEvidences: "Stakeholder Mapping Diagrams: Illustrations of stakeholder relationships and interactions. Engagement Plans: Strategies for communicating with different stakeholder groups."
    },
    {
      clauseSection: "3.50",
      sectionDescription: "Stakeholder Information Needs",
      expectedEvidences: "Stakeholder Mapping Diagrams: Illustrations of relationships with various stakeholders. Engagement Plans: Strategies for communicating with different stakeholder groups."
    },
    {
      clauseSection: "3.51",
      sectionDescription: "Consumer Information Journey",
      expectedEvidences: "Analysis of Feedback: Identification of common information needs and concerns. Development of Resources: Creation of guides addressing consumer questions and concerns."
    },
    {
      clauseSection: "3.52",
      sectionDescription: "Option to Opt Out",
      expectedEvidences: "Internal Policy Documents: Guidelines for providing opt-out options. Risk Assessments: Evaluation of potential harm or risk to individuals and necessity of opt-out."
    },
    {
      clauseSection: "3.53",
      sectionDescription: "Recourse Options",
      expectedEvidences: "Internal Policies: Approach to providing recourse options. Documentation of Considerations: Rationale for not providing opt-out and alternative recourse mechanisms."
    },
    {
      clauseSection: "3.54",
      sectionDescription: "Feedback and Query Channel",
      expectedEvidences: "Records of Interactions: Customer feedback and inquiry transcripts. Procedures for Data Accuracy: Managing accuracy requests and corrections."
    },
    {
      clauseSection: "3.55",
      sectionDescription: "Informing Individuals about AI Training",
      expectedEvidences: "Usability Testing Reports: Usability testing results and actions taken. Communication Materials: Disclosures explaining the use of responses in AI training."
    },
    {
      clauseSection: "3.56",
      sectionDescription: "Communication Readability",
      expectedEvidences: "Documentation of Readability Approach: Tools and strategies for communication clarity. Reports on Readability: Analyses using readability measurement tools."
    },
    {
      clauseSection: "3.57",
      sectionDescription: "Acceptable User Policies (AUPs)",
      expectedEvidences: "AUP Documentation: Guidelines and restrictions for user interactions. Communication Materials: Training resources on adhering to AUPs."
    },
    {
      clauseSection: "3.58",
      sectionDescription: "Implementation of AUPs",
      expectedEvidences: "Documentation of AUP Establishment: Policies for user interactions. Specific Restrictions: Guidelines to prevent compromising AI functionality."
    },
    {
      clauseSection: "3.59",
      sectionDescription: "Interactions with AI Solution Providers",
      expectedEvidences: "Documentation of Interactions: Procurement processes, agreements, and collaborations with solution providers."
    },
    {
      clauseSection: "3.60",
      sectionDescription: "Level of Support from AI Solution Providers",
      expectedEvidences: "Documentation of Requirements: Expectations regarding support and information from solution providers. Contracts or SLAs: Information disclosure and support commitments."
    },
    {
      clauseSection: "3.61",
      sectionDescription: "Varying Information Needs",
      expectedEvidences: "Communication Records with Regulators: Discussions on information detail for regulatory compliance. Contracts or MOUs with Collaborators: Information exchange requirements for collaborative projects."
    },
    {
      clauseSection: "3.62",
      sectionDescription: "Evaluation of AI Governance Practices",
      expectedEvidences: "Documentation of Evaluation: Commitment to evaluating AI governance in line with standards. Reports on Assessments: Comparisons against evolving AI standards and improvement areas."
    }
  ]

export const SINGAPORE_PDPC_ACT_DATA = [
    {
        clauseSection: "1.1",
        sectionDescription: "Explanation of the PDPA",
        expectedEvidences: "Establishment of PDPC under PDPA and its key functions."
    },
    {
        clauseSection: "1.2",
        sectionDescription: "Need to Read Guidelines with \"Introduction to the Guidelines\"",
        expectedEvidences: "Reference or excerpt from guidelines stating the requirement. Statement reiterating the disclaimer."
    },
    {
        clauseSection: "1.3",
        sectionDescription: "Purpose of Examples in Guidelines",
        expectedEvidences: "Quote stating examples illustrate specific aspects of PDPA. Reference to guidelines mentioning examples not exhaustive."
    },
    {
        clauseSection: "2.1",
        sectionDescription: "Key Provisions of PDPA",
        expectedEvidences: "Excerpts or summaries emphasizing dual focus. References to data protection and DNC provisions."
    },
    {
        clauseSection: "2.2",
        sectionDescription: "Scope of Data Protection Provisions",
        expectedEvidences: "References/excerpts from Parts 3 to 6A. Official guidelines from PDPC."
    },
    {
        clauseSection: "2.3",
        sectionDescription: "Exceptions within PDPA",
        expectedEvidences: "Excerpts/references outlining exceptions. Guidelines explaining rationale and application."
    },
    {
        clauseSection: "2.4",
        sectionDescription: "Overview of Do Not Call Registry Provisions",
        expectedEvidences: "Documentation/guidelines explaining provisions. Descriptions/diagrams of registry structure."
    },
    {
        clauseSection: "2.5",
        sectionDescription: "Obligations of Organizations",
        expectedEvidences: "Direct excerpts from relevant sections. Official guidelines from PDPC."
    },
    {
        clauseSection: "2.6",
        sectionDescription: "Exceptions to Do Not Call Registers",
        expectedEvidences: "Direct references/excerpts from PDPA outlining exceptions."
    },
    {
        clauseSection: "2.7",
        sectionDescription: "Additional Prohibitions on Sending Messages",
        expectedEvidences: "Official guidelines from PDPC providing detailed information."
    },
    {
        clauseSection: "2.8",
        sectionDescription: "Complementary Relationship of Provisions",
        expectedEvidences: "Reference to specific PDPA sections requiring compliance."
    },
    {
        clauseSection: "2.9",
        sectionDescription: "Description of Offences in Part 9B",
        expectedEvidences: "Direct references/excerpts from Part 9B of PDPA text."
    },
    {
        clauseSection: "2.10",
        sectionDescription: "Acknowledgment of Other PDPA Sections",
        expectedEvidences: "Reference to other parts/sections without details."
    },
    {
        clauseSection: "3.1",
        sectionDescription: "Understanding Key Terms in PDPA",
        expectedEvidences: "Reference to relevant PDPA section. List of key terms."
    },
    {
        clauseSection: "3.2",
        sectionDescription: "Objective of PDPA",
        expectedEvidences: "Quotation of purpose statement. Emphasis on key elements."
    },
    {
        clauseSection: "3.3",
        sectionDescription: "Identification of Important Terms",
        expectedEvidences: "Quotation of relevant portion. Definition of terms."
    },
    {
        clauseSection: "3.4",
        sectionDescription: "Guidance on Interpretation of Key Terms",
        expectedEvidences: "Detailed explanations or interpretations of terms."
    },
    {
        clauseSection: "4.1",
        sectionDescription: "Definition of \"individual\"",
        expectedEvidences: "Quotation of definition from PDPA."
    },
    {
        clauseSection: "4.2",
        sectionDescription: "Clarification on \"natural person\"",
        expectedEvidences: "Quotation or reference to explanation."
    },
    {
        clauseSection: "4.3",
        sectionDescription: "Scope of protection under PDPA",
        expectedEvidences: "Quotation or reference to statement."
    },
    {
        clauseSection: "4.4",
        sectionDescription: "Application of PDPA to deceased individuals",
        expectedEvidences: "Quotation or reference to statement."
    },
    {
        clauseSection: "5.1",
        sectionDescription: "Definition of \"personal data\"",
        expectedEvidences: "Clarification and relevant guidelines."
    },
    {
        clauseSection: "5.2",
        sectionDescription: "Interpretation of \"personal data\"",
        expectedEvidences: "Guidelines and examples."
    },
    {
        clauseSection: "5.3",
        sectionDescription: "Exclusions from PDPA",
        expectedEvidences: "Reference to exclusions and guidance."
    },
    {
        clauseSection: "5.4",
        sectionDescription: "Requirement for data to qualify as personal data",
        expectedEvidences: "Quotation or reference to requirement."
    },
    {
        clauseSection: "5.5",
        sectionDescription: "Identifiability of individuals from data",
        expectedEvidences: "Quotation or reference to requirement."
    },
    {
        clauseSection: "5.6",
        sectionDescription: "Practical approach to determine personal data",
        expectedEvidences: "Guidelines, references, and examples."
    },
    {
        clauseSection: "5.7",
        sectionDescription: "Rule of thumb for determining personal data",
        expectedEvidences: "Quotation, explanation, and examples."
    },
    {
        clauseSection: "5.8",
        sectionDescription: "PDPC's approach to determining access to other data",
        expectedEvidences: "Quotation or reference to approach."
    },
    {
        clauseSection: "5.9",
        sectionDescription: "Data types likely to identify individuals",
        expectedEvidences: "Quotation, explanation, and examples."
    },
    {
        clauseSection: "5.10",
        sectionDescription: "Importance of collection purpose in determining personal data",
        expectedEvidences: "Documentation of collection purpose."
    },
    {
        clauseSection: "5.11",
        sectionDescription: "Independence of personal data from accuracy",
        expectedEvidences: "PDPA documentation and organizational policies."
    },
    {
        clauseSection: "5.12",
        sectionDescription: "Accuracy Obligation for organizations",
        expectedEvidences: "Documentation of Data Protection Provisions."
    },
    {
        clauseSection: "5.13",
        sectionDescription: "Scenario of inadvertent inclusion of data about another individual",
        expectedEvidences: "Data examples and mapping."
    },
    {
        clauseSection: "5.14",
        sectionDescription: "Scope exemptions under PDPA",
        expectedEvidences: "PDPA exemptions and regulatory guidelines."
    },
    {
        clauseSection: "5.15",
        sectionDescription: "Exemptions for certain categories of personal data",
        expectedEvidences: "PDPA legislation and regulatory guidelines."
    },
    {
        clauseSection: "5.17",
        sectionDescription: "Exemption for business contact information",
        expectedEvidences: "Regulatory guidelines and legal interpretations."
    },
    {
        clauseSection: "5.18",
        sectionDescription: "No consent requirement for business contact information",
        expectedEvidences: "Quotation of exemption and relief."
    },
    {
        clauseSection: "5.19",
        sectionDescription: "Nuanced definition of business contact information",
        expectedEvidences: "PDPA legislation and regulatory guidelines."
    },
    {
        clauseSection: "5.20",
        sectionDescription: "Application to sole proprietors and partners",
        expectedEvidences: "PDPA legislation and regulatory guidelines."
    },
    {
        clauseSection: "5.21",
        sectionDescription: "Definition of derived personal data",
        expectedEvidences: "Legal documentation and policy documentation."
    },
    {
        clauseSection: "5.22",
        sectionDescription: "Inclusion of deceased individuals under PDPA",
        expectedEvidences: "Legal references and policy documentation."
    },
    {
        clauseSection: "5.23",
        sectionDescription: "Applicability of obligations regarding deceased individuals",
        expectedEvidences: "Compliance reports and legal opinions."
    },
    {
        clauseSection: "5.24",
        sectionDescription: "Temporal scope of obligations regarding deceased individuals",
        expectedEvidences: "Legal references and policy documentation."
    },
    {
        clauseSection: "5.25",
        sectionDescription: "Representation concerning deceased individual's estate",
        expectedEvidences: "Regulatory compliance documentation."
    },
    {
        clauseSection: "5.26",
        sectionDescription: "Additional obligations beyond PDPA regarding deceased individuals",
        expectedEvidences: "Legal research and references."
    },
    {
        clauseSection: "5.27",
        sectionDescription: "Definition of personal data and data subject rights",
        expectedEvidences: "Legal references providing definition and rights of data subjects."
    },
    {
        clauseSection: "5.28",
        sectionDescription: "Ownership rights concerning personal data",
        expectedEvidences: "Reference to PDPA provisions and explanation of ownership distinction."
    },
    {
        clauseSection: "5.29",
        sectionDescription: "Ownership rights regarding photographs under PDPA",
        expectedEvidences: "Citation of relevant PDPA sections, property law, and copyright law."
    },
    {
        clauseSection: "6.1",
        sectionDescription: "Definition of organization and its broad scope",
        expectedEvidences: "Citation of PDPA definition and explanation of inclusiveness."
    },
    {
        clauseSection: "6.2",
        sectionDescription: "Definition and inclusiveness of \"organization\" under PDPA",
        expectedEvidences: "Direct citation of relevant PDPA definition and explanation of inclusiveness."
    },
    {
        clauseSection: "6.3",
        sectionDescription: "General requirement for organizations to comply with PDPA",
        expectedEvidences: "Reference to PDPA provisions, explanation of scope, and identification of exclusions."
    },
    {
        clauseSection: "6.4",
        sectionDescription: "Exemption of individuals from PDPA compliance",
        expectedEvidences: "Reference to PDPA provisions and explanation of exemption criteria."
    },
    {
        clauseSection: "6.5",
        sectionDescription: "Entities exempted from obligations under Data Protection Provisions",
        expectedEvidences: "Citation of relevant PDPA sections, explanation of exemptions, and clarification of rationale."
    },
    {
        clauseSection: "6.6",
        sectionDescription: "Partial exclusion of organizations acting as data intermediaries",
        expectedEvidences: "Analysis of implications, comparative analysis, legal interpretations, and relevant case law."
    },
    {
        clauseSection: "6.7",
        sectionDescription: "Obligation of organizations to comply with PDPA",
        expectedEvidences: "Reference to PDPA provisions and explanation of the rationale."
    },
    {
        clauseSection: "6.8",
        sectionDescription: "Exclusions for individuals acting in personal or domestic capacity",
        expectedEvidences: "Citation of relevant PDPA sections and explanation of exemption criteria."
    },
    {
        clauseSection: "6.9",
        sectionDescription: "Elaboration on acting in a personal capacity under PDPA",
        expectedEvidences: "Citation of relevant PDPA sections and explanation of personal capacity."
    },
    {
        clauseSection: "6.10",
        sectionDescription: "Definition of \"domestic\" under PDPA",
        expectedEvidences: "Citation of relevant PDPA sections and explanation of domestic activities."
    },
    {
        clauseSection: "6.11",
        sectionDescription: "Exemption for employees under PDPA",
        expectedEvidences: "Clarification of criteria, examples, and documentation from regulatory authorities."
    },
    {
        clauseSection: "6.12",
        sectionDescription: "Organizational responsibility for employee actions under PDPA",
        expectedEvidences: "Citation of relevant PDPA sections and explanation of organizational responsibility."
    },
    {
        clauseSection: "6.13",
        sectionDescription: "Definition of \"public agency\" under PDPA",
        expectedEvidences: "Citation of relevant PDPA sections and explanation of entities included."
    },
    {
        clauseSection: "6.14",
        sectionDescription: "Obligations of organizations providing services to public agencies",
        expectedEvidences: "Citation of relevant PDPA sections and explanation of implications."
    },
    {
        clauseSection: "6.15",
        sectionDescription: "Definition of \"data intermediary\" under PDPA",
        expectedEvidences: "Citation of relevant PDPA sections, explanation of characteristics, and clarification of exclusion."
    },
    {
        clauseSection: "6.16",
        sectionDescription: "Data Protection Provisions for data intermediaries",
        expectedEvidences: "Citation of relevant PDPA section specifying obligations of data intermediaries."
    },
    {
        clauseSection: "6.17",
        sectionDescription: "Broader responsibilities of data intermediaries",
        expectedEvidences: "Legal interpretations, recommendations, or best practices for comprehensive compliance."
    },
    {
        clauseSection: "6.18",
        sectionDescription: "Definition of \"processing\" under PDPA",
        expectedEvidences: "Explanation of operations covered, significance, and examples."
    },
    {
        clauseSection: "6.19",
        sectionDescription: "Interconnected nature of processing activities",
        expectedEvidences: "Documentation or legal interpretations supporting understanding of obligations."
    },
    {
        clauseSection: "6.20",
        sectionDescription: "Responsibility of organizations for data processed by data intermediaries",
        expectedEvidences: "Emphasis on due diligence and examples of organizational responsibility."
    },
    {
        clauseSection: "6.21",
        sectionDescription: "Clarity in contractual agreements with data intermediaries",
        expectedEvidences: "Citation of relevant PDPA sections, explanation of importance, and examples of contractual clauses."
    },
    {
        clauseSection: "6.22",
        sectionDescription: "Organization's responsibility for Transfer Limitation Obligation",
        expectedEvidences: "Citation of relevant PDPA sections, explanation of obligation, and clarification of responsibility."
    },
    {
        clauseSection: "6.23",
        sectionDescription: "Due diligence and assurances for compliance with Transfer Limitation Obligation",
        expectedEvidences: "Recommendations, legal interpretations, or opinions supporting understanding of due diligence requirements."
    },
    {
        clauseSection: "6.24",
        sectionDescription: "Organizations acting as data intermediaries",
        expectedEvidences: "Citation of relevant PDPA sections and examples of organizational roles as data intermediaries."
    },
    {
        clauseSection: "6.25",
        sectionDescription: "Clear specification of obligations in contracts",
        expectedEvidences: "Existence of written contract with explicit clauses outlining obligations."
    },
    {
        clauseSection: "6.26",
        sectionDescription: "Shared responsibility for compliance with data protection provisions",
        expectedEvidences: "Documentation of engagement of Organization C by both Organizations A and B."
    },
    {
        clauseSection: "6.27",
        sectionDescription: "Responsibility of Organization A for data processed by Organization B",
        expectedEvidences: "Documentation illustrating accountability of Organization A for data processed by Organization B."
    },
    {
        clauseSection: "6.28",
        sectionDescription: "Organization within corporate group acting as data intermediary",
        expectedEvidences: "Documentation illustrating organizational structure and roles within corporate group."
    },
    {
        clauseSection: "6.29",
        sectionDescription: "Data intermediary for specific sets of personal data",
        expectedEvidences: "Documentation demonstrating role as data intermediary and adherence to Data Protection Provisions."
    },
    {
        clauseSection: "6.30",
        sectionDescription: "Liabilities of network service providers acting as conduits",
        expectedEvidences: "Documentation of PDPA and ETA provisions, industry feedback, and relevant legal interpretations."
    },
    {
        clauseSection: "6.31",
        sectionDescription: "Legal relationship of agency",
        expectedEvidences: "Contracts or agreements between agents and principals, legal precedents illustrating agency law."
    },
    {
        clauseSection: "6.32",
        sectionDescription: "Classification of individuals as \"agents\"",
        expectedEvidences: "Legal analyses, case law, statutory definitions."
    },
    {
        clauseSection: "6.33",
        sectionDescription: "Obligations of agents under PDPA",
        expectedEvidences: "PDPA compliance documents, contractual agreements."
    },
    {
        clauseSection: "6.34",
        sectionDescription: "Agent awareness and adherence to data protection",
        expectedEvidences: "Training programs for agents on data protection practices."
    },
    {
        clauseSection: "7.1",
        sectionDescription: "Responsibilities of organizations regarding personal data",
        expectedEvidences: "Copy of Part 4 of the PDPA."
    },
    {
        clauseSection: "7.2",
        sectionDescription: "Definition of collection, use, and disclosure",
        expectedEvidences: "Documentation referencing these definitions, legal opinions."
    },
    {
        clauseSection: "7.3",
        sectionDescription: "Obligations for active and passive data handling",
        expectedEvidences: "Documentation outlining policies and procedures."
    },
    {
        clauseSection: "8.1",
        sectionDescription: "Definition of \"purpose\" under PDPA",
        expectedEvidences: "Documentation outlining purposes for data processing."
    },
    {
        clauseSection: "8.2",
        sectionDescription: "Broad interpretation of \"purpose\"",
        expectedEvidences: "Documentation outlining objectives or reasons for processing."
    },
    {
        clauseSection: "9.1",
        sectionDescription: "Role of \"reasonableness\"",
        expectedEvidences: "Documentation demonstrating organizational efforts."
    },
    {
        clauseSection: "9.2",
        sectionDescription: "Assessment against \"reasonableness\" standard",
        expectedEvidences: "Regular assessments and reviews."
    },
    {
        clauseSection: "9.3",
        sectionDescription: "Balance between data protection and organizational needs",
        expectedEvidences: "Documentation emphasizing importance of data protection."
    },
    {
        clauseSection: "9.4",
        sectionDescription: "Consideration of specific circumstances",
        expectedEvidences: "Documentation demonstrating consideration of circumstances."
    },
    {
        clauseSection: "9.5",
        sectionDescription: "Definition of \"reasonable person\"",
        expectedEvidences: "Documentation demonstrating evaluation and adaptation of practices."
    },
    {
        clauseSection: "10.1",
        sectionDescription: "Organizational responsibility for personal data",
        expectedEvidences: "Compliance reports, records of communication with data intermediaries."
    },
    {
        clauseSection: "10.2",
        sectionDescription: "Consent requirement for data collection, use, and disclosure",
        expectedEvidences: "Documentation of consent mechanisms, compliance with data protection laws."
    },
    {
        clauseSection: "10.3",
        sectionDescription: "Guidelines' examination of PDPA obligations",
        expectedEvidences: "Documentation demonstrating understanding of responsibilities and compliance efforts."
    },
    {
        clauseSection: "11.1",
        sectionDescription: "Scope of Data Protection Provisions",
        expectedEvidences: "Documentation of relevant legal documents or regulations."
    },
    {
        clauseSection: "11.2",
        sectionDescription: "Requirements for collecting personal data from outside Singapore",
        expectedEvidences: "Consent mechanisms, compliance with data protection laws."
    },
    {
        clauseSection: "11.3",
        sectionDescription: "Implications of transferring personal data to Singapore",
        expectedEvidences: "Documentation of Transfer Limitation Obligation."
    },
    {
        clauseSection: "12.1",
        sectionDescription: "Provisions for collecting, using, and disclosing personal data",
        expectedEvidences: "Organizational policies and procedures for obtaining consent."
    },
    {
        clauseSection: "12.2",
        sectionDescription: "Exceptions to consent requirement",
        expectedEvidences: "Compliance assessments, Annex A guidance."
    },
    {
        clauseSection: "12.3",
        sectionDescription: "Requirements for valid consent",
        expectedEvidences: "Documentation of PDPA provisions."
    },
    {
        clauseSection: "12.4",
        sectionDescription: "Methods of obtaining consent",
        expectedEvidences: "Consent mechanisms, documentation of consent processes."
    },
    {
        clauseSection: "12.5",
        sectionDescription: "Documentation of verbal consent",
        expectedEvidences: "Written records or electronic recordings of verbal consent."
    },
    {
        clauseSection: "12.6",
        sectionDescription: "Inferred or implied consent",
        expectedEvidences: "References to relevant regulations or guidelines."
    },
    {
        clauseSection: "12.7",
        sectionDescription: "Consent requirements for specified messages",
        expectedEvidences: "Documentation of consent requirements."
    },
    {
        clauseSection: "12.8",
        sectionDescription: "Consent by authorized representatives",
        expectedEvidences: "Organizational policies and procedures for obtaining consent."
    },
    {
        clauseSection: "12.9",
        sectionDescription: "Consent by representatives",
        expectedEvidences: "Documentation of consent requirements."
    },
    {
        clauseSection: "12.10",
        sectionDescription: "Additional obligations for obtaining consent",
        expectedEvidences: "Documentation of PDPA provisions, organizational policies and procedures."
    },
    {
        clauseSection: "12.11",
        sectionDescription: "Implications of invalid consent",
        expectedEvidences: "Documentation of PDPA provisions."
    },
    {
        clauseSection: "12.12",
        sectionDescription: "Collection, use, or disclosure beyond necessary purposes",
        expectedEvidences: "References to relevant PDPA sections or guidance."
    },
    {
        clauseSection: "12.13",
        sectionDescription: "Exceptions to consent requirement for incentives",
        expectedEvidences: "Documentation of PDPA provisions, organizational policies and procedures."
    },
    {
        clauseSection: "12.14",
        sectionDescription: "Best practices for form design",
        expectedEvidences: "Form examples, organizational policies and procedures."
    },
    {
        clauseSection: "12.15",
        sectionDescription: "Consent requirements for providing products or services",
        expectedEvidences: "Organizational policies and procedures for obtaining consent."
    },
    {
        clauseSection: "12.16",
        sectionDescription: "Necessity of personal data for providing products or services",
        expectedEvidences: "Data minimization practices, data impact assessments."
    },
    {
        clauseSection: "12.17",
        sectionDescription: "Prohibition of false or misleading consent practices",
        expectedEvidences: "Consent practices review."
    },
    {
        clauseSection: "12.18",
        sectionDescription: "Deemed consent under PDPA",
        expectedEvidences: "Organizational policies and procedures for deemed consent."
    },
    {
        clauseSection: "12.19",
        sectionDescription: "Relationship between consent for disclosure and collection",
        expectedEvidences: "Documentation of PDPA provisions."
    },
    {
        clauseSection: "12.20",
        sectionDescription: "Deemed consent by conduct",
        expectedEvidences: "Documentation of PDPA provisions."
    },
    {
        clauseSection: "12.21",
        sectionDescription: "This section clarifies that an individual may be considered to have voluntarily provided personal data even without explicitly giving consent. It states that consent is deemed to be given to the extent that the individual intended to provide their personal data and took actions required for the data to be collected by the organization.",
        expectedEvidences: "Training and Awareness Initiatives: Provide evidence of training programs or awareness initiatives implemented within the organization to educate staff on complying with the requirements for voluntary provision of personal data under the PDPA. This may include training materials, guidelines, or documentation of staff training sessions focusing on ethical data practices and compliance with provisions related to data collection."
    },
    {
        clauseSection: "12.22",
        sectionDescription: "This section elaborates on the concept of deemed consent by contractual necessity under Section 15(3) of the Personal Data Protection Act (PDPA) in Singapore. It explains that deemed consent by contractual necessity applies when an individual provides their personal data to one organization (\"A\") for a transaction, and it is reasonably necessary for organization A to disclose the personal data to another organization (\"B\") for the necessary conclusion or performance of the transaction.",
        expectedEvidences: "Transactional Processes Review: Detail the practices implemented by the organization to review and validate instances where deemed consent by contractual necessity applies to data sharing practices. This could involve regular audits or assessments of transactional processes, verification of compliance with contractual necessity requirements, and documentation of corrective actions taken to address any instances of non-compliance.\nDocumentation of PDPA Provisions: Provide references or excerpts from Section 15(3) of the PDPA that define and outline the concept of deemed consent by contractual necessity. This could include sections from the PDPA itself or supplementary guidance issued by the Personal Data Protection Commission (PDPC) in Singapore."
    },
    {
        clauseSection: "12.23",
        sectionDescription: "Section 15A of the Personal Data Protection Act (PDPA) in Singapore introduces the concept of deemed consent by notification. It specifies that an individual may be deemed to have consented to the collection, use, or disclosure of personal data for a purpose if they have been notified of that purpose and have not taken any action to opt out of such data processing. Deemed consent by notification is particularly relevant when organizations seek to use or disclose existing data for secondary purposes that differ from the primary purposes for which the data was originally collected, and when no exceptions to consent (such as business improvement or research) can be relied upon for the intended secondary use.",
        expectedEvidences: "Documentation of PDPA Provisions: Provide references or excerpts from Section 15A of the PDPA that define and outline the concept of deemed consent by notification. This could include sections from the PDPA itself or supplementary guidance issued by the Personal Data Protection Commission (PDPC) in Singapore.\nOrganizational Policies and Procedures: Describe the policies and procedures established by the organization to ensure compliance with the requirements outlined in this section regarding deemed consent by notification."
    },
    {
        clauseSection: "12.24",
        sectionDescription: "This section explains that after the opt-out period specified by the organization has passed, an individual still has the right to withdraw their consent for the collection, use, or disclosure of their personal data. This withdrawal of consent allows individuals to revoke their previous agreement to data processing activities and signifies their desire to no longer permit the organization to use their personal data for the specified purpose.",
        expectedEvidences: "Organizational Policies and Procedures: Describe the policies and procedures established by the organization to facilitate the withdrawal of consent by individuals for the collection, use, or disclosure of their personal data. This could include guidelines for individuals to follow when initiating the withdrawal process, mechanisms for processing withdrawal requests, and procedures for updating data processing activities accordingly."
    },
    {
        clauseSection: "12.25",
        sectionDescription: "Under the Personal Data Protection Regulations 2021, organizations are mandated to retain a copy of their assessment whenever they collect, use, or disclose personal data based on deemed consent by notification. When requested by the Personal Data Protection Commission, the organization must furnish this assessment to the Commission. However, the organization is not obligated to provide this assessment to individuals upon request, as it may contain commercially sensitive information.",
        expectedEvidences: "Recordkeeping Practices: Detail the practices implemented by the organization to retain and manage copies of assessments for collecting, using, or disclosing personal data based on deemed consent by notification. This could involve maintaining electronic or physical records, implementing access controls to ensure the confidentiality of assessments, and establishing retention periods in accordance with regulatory requirements.\nCompliance Documentation: Present any reports or audits conducted to assess the organization's compliance with the requirements outlined in the Personal Data Protection Regulations 2021 regarding the retention and provision of assessments for deemed consent by notification."
    },
    {
        clauseSection: "12.26",
        sectionDescription: "The Personal Data Protection Commission acknowledges that there are multiple methods for implementing the opt-out process. The Commission emphasizes that it will evaluate each case individually, taking into account the specific circumstances and facts, to determine whether the conditions for relying on deemed consent by notification have been fulfilled.",
        expectedEvidences: "Continuous Improvement Efforts: Explain how the organization continuously reviews and improves its opt-out methods and overall compliance with deemed consent by notification. Provide evidence of ongoing monitoring, feedback mechanisms, and initiatives aimed at enhancing transparency, effectiveness, and user-friendliness of the opt-out process."
    },
    {
        clauseSection: "12.27",
        sectionDescription: "According to the Personal Data Protection Regulations 2021, deemed consent by notification is not applicable to the purpose of sending direct marketing messages. This regulation specifies that organizations cannot rely on deemed consent by notification to justify the collection, use, or disclosure of personal data for direct marketing purposes.",
        expectedEvidences: "Compliance Documentation: Present any reports or audits conducted to assess the organization's compliance with the regulations regarding deemed consent by notification and direct marketing activities. This could include documentation of compliance assessments, records of consent management practices for direct marketing purposes, and evidence of compliance with data protection regulations."
    },
    {
        clauseSection: "12.28",
        sectionDescription: "Organizations are advised to obtain express consent for sending direct marketing messages to individuals, particularly through the opt-in method.",
        expectedEvidences: "Describe the policies and procedures established by the organization to comply with the PDPC guidelines regarding consent for direct marketing messages. This could include guidelines for obtaining express consent through the opt-in method, mechanisms for managing consent preferences, and procedures for ensuring compliance with the Do Not Call Provisions."
    },
    {
        clauseSection: "12.29",
        sectionDescription: "Organizations may obtain personal data about an individual with the individual's consent but from a source other than the individual, referred to as a \"third-party source.\" There are two situations in which this may occur.",
        expectedEvidences: "Present any reports or audits conducted to assess the organization's compliance with the PDPA requirements regarding obtaining personal data from third-party sources with the individual's consent. This could include documentation of compliance assessments, records of consent management practices for third-party data sources, and evidence of compliance with data protection regulations."
    },
    {
        clauseSection: "12.30",
        sectionDescription: "There are two scenarios in which organizations may obtain personal data about an individual from a third-party source with the individual's consent.",
        expectedEvidences: "Documentation of Third-Party Transactions: Provide documentation or records of transactions involving the acquisition of personal data from third-party sources with the individual's consent. This could include contracts or agreements with database resellers, records of referrals from existing customers, or other forms of documentation demonstrating compliance with consent requirements."
    },
    {
        clauseSection: "12.31",
        sectionDescription: "In organizations that operate within a group structure, it's not uncommon for one entity within the group to legitimately acquire consent for the collection, utilization, and sharing of an individual's personal data with other entities within the corporate group.",
        expectedEvidences: "This practice streamlines data processing activities across the corporate group, facilitating coordinated marketing efforts while ensuring compliance with data protection regulations. Additionally, it allows for a more seamless and personalized experience for individuals interacting with various entities within the corporate group."
    },
    {
        clauseSection: "12.32",
        sectionDescription: "It is legally obligated to inform the source about the specific purposes for which the data will be collected, used, and disclosed, as applicable.",
        expectedEvidences: "Provide references or excerpts from applicable laws, regulations, or official guidelines stipulating the organization's obligation to notify third-party sources about the purposes for collecting, using, and disclosing personal data obtained from them. This may include relevant sections of the Personal Data Protection Act (PDPA) or supplementary guidance issued by the relevant data protection authority."
    },
    {
        clauseSection: "12.33",
        sectionDescription: "Organizations that obtain personal data from third-party sources are required to conduct appropriate due diligence to ensure that the third-party source has the authority to provide consent for the collection, use, and disclosure of personal data on behalf of the individual.",
        expectedEvidences: "Due Diligence Procedures: Describe the due diligence procedures established by the organization to verify that third-party sources have the authority to provide consent or have obtained consent for the disclosure of personal data. This may include protocols for assessing the legitimacy of consent obtained by third parties, mechanisms for documenting due diligence efforts, and procedures for verifying the authenticity of consent."
    },
    {
        clauseSection: "12.34",
        sectionDescription: "Organizations can review contracts or agreements between themselves and the third-party source (B) to ascertain whether there are provisions outlining the source's authority to provide consent or the requirement for obtaining consent from individuals before disclosing their personal data.",
        expectedEvidences: "A may request documentation from B demonstrating that they have obtained valid consent from the individual concerned for the collection, use, and disclosure of personal data. This documentation may include consent forms, agreements, or any other relevant records."
    },
    {
        clauseSection: "12.35",
        sectionDescription: "Collection of personal data is required or authorized by law, regulation, or any other legal obligation imposed on organization A.",
        expectedEvidences: "In cases where the collection of personal data is necessary for the purpose of conducting investigations, legal proceedings, or potential legal disputes. This could involve gathering evidence or information relevant to legal matters where obtaining consent may not be practical or feasible."
    },
    {
        clauseSection: "12.36",
        sectionDescription: "Personal data is required or authorized by law, regulation, or any other legal obligation imposed on organization B.",
        expectedEvidences: "In cases where the disclosure of personal data is necessary for the purpose of conducting investigations, legal proceedings, or potential legal disputes. This could involve providing evidence or information relevant to legal matters where obtaining consent may not be practical or feasible."
    },
    {
        clauseSection: "12.37",
        sectionDescription: "In scenarios where the consent of the individual is not required for the collection, use, or disclosure of personal data by organization A (as outlined in previous sections).",
        expectedEvidences: "Compliance Documentation: Present documentation or records illustrating organization A's compliance with section 20(2) of the PDPA, which requires providing sufficient information regarding the purposes for collecting personal data to enable organization B to determine whether disclosure would be in accordance with the PDPA."
    },
    {
        clauseSection: "12.38",
        sectionDescription: "Section 16 of the Personal Data Protection Act (PDPA) stipulates that individuals have the right to withdraw any consent given or deemed to have been given under the PDPA concerning the collection, use, or disclosure of their personal data for any purpose by an organization.",
        expectedEvidences: "Consent Withdrawal Mechanisms: Describe the mechanisms established by the organization to facilitate the withdrawal of consent by individuals. This may include online portals, forms, or contact details provided to individuals for submitting consent withdrawal requests."
    },
    {
        clauseSection: "12.39",
        sectionDescription: "Section 16 of the Personal Data Protection Act (PDPA) outlines several requirements that must be complied with by either the individual or the organization in relation to the withdrawal of consent for the collection, use, or disclosure of personal data.",
        expectedEvidences: "Notification of Withdrawal: Provide evidence demonstrating that the organization has mechanisms in place to promptly notify relevant parties within the organization of any withdrawal of consent by an individual. This may include documented procedures for internal notification and communication channels used to inform relevant stakeholders."
    },
    {
        clauseSection: "12.40",
        sectionDescription: "In general, organizations are required to allow individuals who have previously given (or are deemed to have given) their consent to the organization for the collection, use, or disclosure of their personal data for a specific purpose to withdraw such consent by providing reasonable notice.",
        expectedEvidences: "Describe the mechanisms established by the organization to facilitate the withdrawal of consent by individuals. This may include providing clear instructions on how individuals can submit withdrawal requests through online portals, forms, email, or other channels."
    },
    {
        clauseSection: "12.41",
        sectionDescription: "The Commission recognizes the complexity of establishing a uniform timeframe for providing reasonable notice for the withdrawal of consent and refrains from prescribing a specific timeframe.",
        expectedEvidences: "The Commission recognizes the complexity of establishing a uniform timeframe for providing reasonable notice for the withdrawal of consent and refrains from prescribing a specific timeframe. However, as a general guideline, the Commission suggests that a withdrawal notice of at least ten (10) business days from the day the organization receives the withdrawal notice is considered reasonable. If the organization requires additional time to process the withdrawal notice, it is advisable for the organization to communicate the timeframe within which the withdrawal of consent will be implemented to the individual."
    },
    {
        clauseSection: "12.42",
        sectionDescription: "To enable and facilitate the withdrawal of consent effectively, organizations are advised to develop a clear and easily accessible consent withdrawal policy for individuals.",
        expectedEvidences: "Policy Development: Describe the process by which the organization developed its consent withdrawal policy. This may involve consultation with legal experts, data protection officers, or relevant stakeholders to ensure compliance with regulatory requirements and best practices.\nPolicy Content: Provide details of the organization's consent withdrawal policy, including key elements such as the procedure for submitting withdrawal requests, contact information for submitting requests."
    },
    {
        clauseSection: "12.43",
        sectionDescription: "Organizations should avoid implementing inflexible consent withdrawal policies that restrict or prevent individuals from withdrawing consent in accordance with the Personal Data Protection Act (PDPA).",
        expectedEvidences: "Policy Review: Describe the organization's process for reviewing and updating its consent withdrawal policies to ensure compliance with the PDPA and other relevant regulations. This may involve periodic reviews conducted by legal experts, data protection officers, or compliance teams to identify and address any potential issues or inconsistencies.\nFlexibility Measures: Provide details of the organization's approach to ensuring flexibility in its consent withdrawal policies."
    },
    {
        clauseSection: "12.44",
        sectionDescription: "Organizations are prohibited from imposing restrictions that prevent individuals from withdrawing their consent to the collection, use, or disclosure of their personal data.",
        expectedEvidences: "Contract Review: Describe the organization's process for reviewing contracts to ensure compliance with data protection regulations, including provisions related to consent withdrawal. This may involve legal review by experts familiar with data protection laws and regulations.\nProhibition Policies: Provide documentation demonstrating that the organization does not impose terms in contracts that prohibit individuals from withdrawing consent to the processing of their personal data."
    },
    {
        clauseSection: "12.45",
        sectionDescription: "If an individual withdraws their consent to the collection, use, or disclosure of their personal data by an organization but later provides fresh consent, the organization may proceed to collect, use, or disclose the individual's personal data within the scope of the new consent provided.",
        expectedEvidences: "Consent Management Procedures: Describe the organization's procedures for managing consent, including mechanisms for recording and tracking consent withdrawal and subsequent fresh consent provided by individuals. This may involve implementing consent management systems or databases to effectively track and manage consent preferences.\nDocumentation of Withdrawal and Fresh Consent: Provide documentation demonstrating the individual's withdrawal of consent and subsequent provision of fresh consent."
    },
    {
        clauseSection: "12.46",
        sectionDescription: "When assessing the impact of any notice to withdraw consent, the Commission will take into account all pertinent facts of the situation",
        expectedEvidences: "Withdrawal Notice Handling: Describe the organization's process for handling notices to withdraw consent, including procedures for receiving, acknowledging, and processing withdrawal requests. This may involve establishing clear protocols for documenting and tracking withdrawal notices"
    },
    {
        clauseSection: "12.47",
        sectionDescription: "It should clearly indicate the scope of such withdrawal. Additionally, the organization is encouraged to inform individuals of how they may withdraw consent for matters beyond the specified scope. In facilitating any withdrawal notice, organizations should act reasonably and in good faith.",
        expectedEvidences: "Mechanisms for Withdrawal: Describe the organization's mechanisms for enabling individuals to withdraw consent, such as unsubscribe links in emails, opt-out options on websites, or dedicated withdrawal request forms.\nScope of Withdrawal: Provide documentation indicating how the organization communicates the scope of withdrawal when individuals use the withdrawal mechanisms."
    },
    {
        clauseSection: "12.48",
        sectionDescription: "When a withdrawal notice for marketing purposes contains a general message without specifying the channel through which consent is withdrawn, the Commission typically considers that withdrawal of consent applies only to marketing messages sent via the particular channel through which the withdrawal notice was received.",
        expectedEvidences: "Withdrawal Notice Content: Provide examples or descriptions of withdrawal notices used by the organization for marketing purposes. This may include email unsubscribe messages, opt-out forms on websites, or other withdrawal mechanisms.\nClarity of Channel Withdrawal: Explain whether the organization's withdrawal notices clearly specify the channel through which consent is withdrawn for receiving marketing messages"
    },
    {
        clauseSection: "12.49",
        sectionDescription: "Organizations should consider how withdrawal notices impact both consents obtained under the Data Protection Provisions and the Do Not Call Provisions. Further details on withdrawal of consent under the Do Not Call Provisions can be found in Chapter 8 of the Advisory Guidelines.",
        expectedEvidences: "Integration of Withdrawal Mechanisms: Describe how the organization integrates withdrawal mechanisms to ensure compliance with both the Data Protection Provisions and the Do Not Call Provisions. This may involve aligning withdrawal processes to cover both sets of provisions or implementing separate mechanisms for each.\nCompliance Documentation: Present any reports or audits conducted to assess the organization's compliance with regulations related to withdrawal of consent under both sets of provisions."
    },
    {
        clauseSection: "12.50",
        sectionDescription: "After receiving a withdrawal notice from an individual, the organization should inform the individual of the likely consequences of withdrawing consent, even if these consequences are outlined elsewhere, such as in the service contract between the organization and the individual.",
        expectedEvidences: "Communication of Consequences: Describe the organization's process for informing individuals of the likely consequences of withdrawing consent. This may involve sending personalized messages to individuals outlining the potential impacts on their services or relationships with the organization.\nContent of Information: Provide examples or descriptions of the information communicated to individuals regarding the likely consequences of withdrawing consent."
    },
    {
        clauseSection: "12.51",
        sectionDescription: "The consequences of withdrawing consent may vary depending on the organization and the context.",
        expectedEvidences: "Identification of Consequences: Describe the potential consequences of withdrawing consent in various scenarios, such as cessation of data processing activities, termination of service provision, or legal implications.\nCommunication to Individuals: Provide examples or descriptions of how the organization communicates the consequences of withdrawing consent to individuals."
    },
    {
        clauseSection: "12.52",
        sectionDescription: "Withdrawal of consent applies to an organization's continued use or future disclosure of personal data already in its possession.",
        expectedEvidences: "Compliance Documentation: Provide evidence of compliance with relevant legal requirements and regulatory guidelines regarding the handling of withdrawal of consent. This may include references to specific provisions of data protection laws or guidelines issued by regulatory authorities.\nReview of Data Practices: Explain how the organization reviews its data practices to ensure compliance with withdrawal of consent requirements."
    },
    {
        clauseSection: "12.53",
        sectionDescription: "An organization is not obligated to inform other organizations to which it has disclosed an individual's personal data of the individual's withdrawal of consent.",
        expectedEvidences: "Access Requests Handling: Explain how the organization handles access requests from individuals regarding their personal data and disclosures. This may involve processes for providing access to personal data and information about disclosures within a specified timeframe.\nCompliance Documentation: Provide evidence of compliance with relevant legal requirements and regulatory guidelines regarding individuals' rights to access their personal data and information about disclosures."
    },
    {
        clauseSection: "12.54",
        sectionDescription: "While an individual has the right to withdraw consent for the collection, use, or disclosure of their personal data, the Personal Data Protection Act (PDPA) does not mandate organizations to delete or destroy the individual's personal data upon request.",
        expectedEvidences: "Data Retention Policy: Describe the organization's data retention policy, outlining the criteria and procedures for retaining personal data in accordance with the Data Protection Provisions. This may include retention periods, purposes for retention, and mechanisms for ensuring data security during retention.\nCompliance with Data Protection Provisions: Provide evidence of compliance with relevant legal requirements and regulatory guidelines regarding data retention."
    },
    {
        clauseSection: "12.55",
        sectionDescription: "Section 17 of the Personal Data Protection Act (PDPA) permits the collection, use, and disclosure of personal data without consent, including data collected from a source other than the individual.",
        expectedEvidences: "Compliance Documentation: Provide evidence of compliance with Section 17 of the PDPA and other relevant legal obligations. This may include references to specific provisions of the PDPA, as well as other laws or regulations applicable to the organization's operations.\nPolicies and Procedures: Describe the organization's policies and procedures for ensuring compliance with both the PDPA and other legal obligations."
    },
    {
        clauseSection: "12.56",
        sectionDescription: "\"Legitimate interests\" encompass any lawful interests of an organization or other individuals, including other organizations. Paragraphs 2 to 10 under Part 3 of the First Schedule to the Personal Data Protection Act (PDPA) outline specific purposes typically considered as \"legitimate interests,\" such as for evaluative purposes, investigations, proceedings, or debt recovery",
        expectedEvidences: "\"Purpose Alignment: Demonstrate how the organization's data processing activities align with legitimate interests as defined under the PDPA. This may involve outlining specific purposes for data collection, use, or disclosure and how they relate to the organization's lawful interests.\nCompliance Documentation: Provide evidence of compliance with Paragraphs 2 to 10 under Part 3 of the First Schedule and other relevant provisions of the PDPA.\""
    },
    {
        clauseSection: "12.57",
        sectionDescription: "The legitimate interests exception under the Personal Data Protection Act (PDPA) permits organizations to collect, use, or disclose personal data without explicit consent for various circumstances and purposes. However, organizations relying on this exception bear the responsibility of implementing additional safeguards to protect individuals' interests.",
        expectedEvidences: "\"Safeguard Implementation: Document the additional safeguards implemented by the organization to protect individuals' interests when relying on the legitimate interests exception. This may include measures such as data minimization, pseudonymization, encryption, access controls, and regular data security assessments.\nPrivacy by Design: Demonstrate how privacy principles are embedded into the organization's systems, processes, and practices from the outset, ensuring that data processing activities under the legitimate interests exception prioritize privacy and data protection.\""
    },
    {
        clauseSection: "12.58",
        sectionDescription: "The legitimate interests exception allows organizations to collect, use, or disclose personal data without consent for various circumstances and purposes. However, organizations must bear the responsibility of identifying and articulating these legitimate interests to ensure the protection of individuals' interests.",
        expectedEvidences: "\"Legitimate Interest Assessment: Conduct a thorough assessment to identify and document the legitimate interests pursued by the organization. This assessment should outline the specific purposes for data collection, use, or disclosure and how they align with the organization's lawful interests.\nJustification Documentation: Provide documentation that justifies the necessity and proportionality of the data processing activities based on legitimate interests.\""
    },
    {
        clauseSection: "12.59",
        sectionDescription: "The legitimate interests exception cannot be utilized by organizations for sending direct marketing messages. Instead, organizations must obtain express consent from individuals for such activities. Additionally, compliance with the Do Not Call Provisions of the PDPA is mandatory when sending direct marketing messages to Singapore telephone numbers through voice calls, text messages, or fax",
        expectedEvidences: "\"Consent Mechanisms: Demonstrate the implementation of mechanisms to obtain express consent from individuals specifically for sending direct marketing messages. This may include opt-in forms, checkboxes, or other explicit consent methods clearly indicating individuals' consent for marketing communications.\""
    },
    {
        clauseSection: "12.6O",
        sectionDescription: "Organizations relying on the legitimate interests exception to collect, use, or disclose personal data must transparently communicate this reliance to individuals. This can be achieved through public data protection policies or similar channels, where organizations explicitly state their reliance on the legitimate interests exception for specific purposes, such as security or prevention of service misuse.",
        expectedEvidences: "\"Public Data Protection Policy: Provide evidence of a publicly available data protection policy or similar documentation where the organization discloses its reliance on the legitimate interests exception for specific data processing activities. This disclosure should be clear and easily accessible to individuals.\nTransparent Communication: Ensure that the disclosure of reliance on the legitimate interests exception is communicated in a transparent manner\""
    },
    {
        clauseSection: "12.61",
        sectionDescription: "In addition to transparently communicating their reliance on the legitimate interests exception, organizations must also provide individuals with business contact information for addressing queries related to this reliance.",
        expectedEvidences: "\"Designation of Contact Person: Provide evidence of the designation of a specific individual, such as the DPO or another responsible staff member, to address queries from individuals regarding the organization's reliance on the legitimate interests exception. This may include job descriptions, appointment letters, or organizational charts indicating the responsible individual.\""
    },
    {
        clauseSection: "12.62",
        sectionDescription: "Organizations that rely on the legitimate interests exception to collect, use, or disclose personal data are obligated to document their assessments and steps taken to mitigate any residual risks associated with this reliance.",
        expectedEvidences: "\"Mitigation Measures: Outline the steps taken by the organization to mitigate any residual risks associated with relying on the legitimate interests exception. This could involve implementing technical, organizational, or procedural measures to minimize potential negative impacts on individuals' privacy rights.\""
    },
    {
        clauseSection: "12.63",
        sectionDescription: "Organizations may collect, use, or disclose personal data for purposes such as detecting and preventing fraud, money laundering, or other illegal activities.",
        expectedEvidences: "This could involve analyzing patterns of behavior or transactions to identify suspicious activities."
    },
    {
        clauseSection: "12.64",
        sectionDescription: "In the case of relying on the legitimate interests exception, the organization evaluates whether its interests in processing the data outweigh any potential risks to individuals' privacy rights.",
        expectedEvidences: "The organization identifies and assesses any potential risks or adverse effects that may arise from the collection, use, or disclosure of personal data without explicit consent."
    },
    {
        clauseSection: "12.65",
        sectionDescription: "Data processing will necessarily constitute adverse effects.",
        expectedEvidences: "The Commission evaluates whether there are likely adverse effects by considering prevailing social norms and practices that a reasonable person would deem appropriate."
    },
    {
        clauseSection: "12.66",
        sectionDescription: "As part of the assessment process, organizations must identify and implement reasonable measures to eliminate, reduce the likelihood of, or mitigate any adverse effects on individuals resulting from data processing activities. The Commission evaluates the appropriateness of these measures based on a commercially reasonable standard.",
        expectedEvidences: "\"Minimizing the amount of personal data collected: Organizations should only collect the minimum amount of personal data necessary to achieve their legitimate interests or fulfill their legal obligations.\nEncryption: Personal data should be encrypted during transmission and storage to prevent unauthorized access or disclosure.\""
    },
    {
        clauseSection: "12.67",
        sectionDescription: "If an organization determines that there are likely residual adverse effects on individuals despite implementing mitigation measures, it cannot rely on deemed consent by notification to collect, use, or disclose personal data for the purpose.",
        expectedEvidences: "\"During the balancing test, organizations weigh the legitimate interests of the organization or other involved parties against any likely residual adverse effects on the individual. If the legitimate interests outweigh the potential adverse effects, organizations may rely on the legitimate interests exception to collect, use, or disclose personal data for the intended purpose\""
    },
    {
        clauseSection: "12.68",
        sectionDescription: "Conducting joint assessments between disclosing and receiving organizations can be beneficial when relying on deemed consent by notification or the legitimate interests exception to collect and disclose personal data.",
        expectedEvidences: "\"Whether conducted jointly or separately, these assessments should comprehensively address the factors outlined by data protection regulations and include measures to mitigate any adverse effects on individuals. By conducting thorough assessments, organizations can ensure responsible data handling practices and maintain trust with individuals whose data they process.\""
    },
    {
        clauseSection: "12.69",
        sectionDescription: "The sensitivity and nature of the personal data being collected, used, or disclosed, including whether it involves sensitive information such as health data, financial information, or biometric data.",
        expectedEvidences: "The purpose for which the personal data is being collected, used, or disclosed, and whether it aligns with the expectations and interests of the individual."
    },
    {
        clauseSection: "12.71",
        sectionDescription: "Part 5 of the First Schedule and Division 2 under Part 2 of the Second Schedule of the Personal Data Protection Act (PDPA) pertain to the \"business improvement exception.\"",
        expectedEvidences: "\"Documentation demonstrating that the organization collected the personal data in accordance with the Data Protection Provisions of the PDPA, along with records indicating how the use of the personal data corresponds to the criteria outlined in Part 5 of the First Schedule and Division 2 under Part 2 of the Second Schedule of the PDPA.\""
    },
    {
        clauseSection: "12.72",
        sectionDescription: "In order to rely on the business improvement exception, organizations will need to ensure compliance with the Data Protection Provisions of the PDPA during the collection, storage, and processing of personal data.",
        expectedEvidences: "N/A"
    },
    {
        clauseSection: "12.73",
        sectionDescription: "Enhancing customer service by providing more personalized and tailored experiences based on aggregated data from multiple entities within the group.",
        expectedEvidences: "Conducting data analytics and market research to identify trends and improve products or services offered by the group of companies."
    },
    {
        clauseSection: "12.74",
        sectionDescription: "According to the Commission, business insights pertaining to individuals will be classified as personal data if they can lead to the identification of a specific individual, especially when combined with other accessible information held or likely to be accessed by the organization.",
        expectedEvidences: "Documentation illustrating the Commission's acknowledgment of the classification of business insights as personal data when they can identify individuals and the recognition of the need for organizations to share customer behavior data for product improvement purposes, citing the applicability of the business improvement exception under such circumstances."
    },
    {
        clauseSection: "12.75",
        sectionDescription: "The term \"existing customers\" pertains to individuals who have a documented history of purchasing, hiring, or utilizing any goods or services provided by the organization.",
        expectedEvidences: "\"Documentation or policy statements defining \"\"existing customers\"\" and \"\"prospective customers\"\" according to the provided criteria, demonstrating the organization's understanding and application of these terms in its operations and interactions with customers.\""
    },
    {
        clauseSection: "12.76",
        sectionDescription: "The business improvement purpose cannot reasonably be achieved without sharing the personal data in an individually identifiable form.",
        expectedEvidences: "The organization's sharing of personal data for the business improvement purpose is one that a reasonable person would consider appropriate in the circumstances."
    },
    {
        clauseSection: "12.78",
        sectionDescription: "Organizations are prohibited from relying on the business improvement exception to send direct marketing messages. Generally, organizations must obtain express consent from individuals before sending direct marketing messages. Moreover, when direct marketing messages are transmitted to Singapore telephone numbers via voice call, text, or fax, organizations must adhere to the Do Not Call Provisions outlined in the PDPA.",
        expectedEvidences: "\"Documentation indicating the prohibition on utilizing the business improvement exception for sending direct marketing messages, along with policies or procedures demonstrating the requirement for organizations to obtain express consent for sending such messages. Additionally, records illustrating compliance efforts with the Do Not Call Provisions of the PDPA when sending direct marketing messages to Singapore telephone numbers.\""
    },
    {
        clauseSection: "12.79",
        sectionDescription: "Despite the general prohibition on using the business improvement exception for direct marketing messages, organizations are permitted to utilize the exception for data analytics and market research involving existing customers' personal data. These activities are aimed at deriving insights and understanding existing customers before engaging in business marketing activities.",
        expectedEvidences: "Documentation or guidelines outlining the Commission's recognition of the distinction between preparatory activities such as data analytics and market research and the direct sending of marketing messages. Additionally, records demonstrating how organizations utilize the business improvement exception for preparatory activities and comply with regulations regarding direct marketing under the PDPA."
    },
    {
        clauseSection: "12.8O",
        sectionDescription: "The business improvement exception is designed to allow organizations to utilize personal data for the purpose of enhancing their products, services, business operations, and customer experience. In contrast, the research exception enables organizations to conduct broader research and development activities that may not have immediate applications to their products, services, business operations, or market. Examples of organizations that can continue to rely on the research exception include commercial laboratories engaged in scientific research, institutes of higher learning conducting research in arts and social sciences, and organizations conducting market research.",
        expectedEvidences: "\"Documentation or policy statements defining the business improvement exception and the research exception, along with examples illustrating the types of organizations that can utilize each exception. Additionally, records demonstrating how organizations comply with regulations and guidelines while leveraging these exceptions for their respective purposes.\""
    },
    {
        clauseSection: "12.81",
        sectionDescription: "Organizations have the option to rely on the research exception to disclose personal data for research purposes, encompassing historical and statistical research.",
        expectedEvidences: "Documentation outlining the conditions for utilizing the research exception for disclosing personal data for research purposes, including the impracticability of obtaining individual consent"
    },
    {
        clauseSection: "12.82",
        sectionDescription: "When determining whether it would be \"impracticable\" for an organization to seek consent from individuals, the specific circumstances of the case must be thoroughly assessed.",
        expectedEvidences: "Documentation illustrating the Commission's guidance on assessing impracticability when seeking consent for research purposes, including specific factors to consider. Additionally, records demonstrating the organization's efforts to contact potential research subjects, assessments of resource demands, and considerations of research integrity when determining impracticability."
    },
    {
        clauseSection: "12.83",
        sectionDescription: "The Commission emphasizes the importance of assessing the degree of practicability when determining the impracticability of seeking consent.",
        expectedEvidences: "Documentation highlighting the Commission's guidance on assessing practicability and the threshold for impracticability when seeking consent for research purposes. Additionally, records demonstrating the organization's efforts to utilize convenient and practical means for individuals to provide consent, such as online forms, emails, or other electronic communication methods."
    },
    {
        clauseSection: "12.84",
        sectionDescription: "Another significant exception, outlined in paragraph 1 under Part 2 of the First Schedule to the PDPA, pertains to personal data that is publicly available.",
        expectedEvidences: "Documentation referencing the significant exception outlined in paragraph 1 under Part 2 of the First Schedule to the PDPA, along with the definition of \"publicly available\" as provided in section 2(1) of the PDPA. Additionally, records or policies demonstrating the organization's understanding and compliance with this exception regarding publicly available personal data."
    },
    {
        clauseSection: "12.85",
        sectionDescription: "The term \"generally available to the public\" within the context of the PDPA refers to its commonly understood meaning.",
        expectedEvidences: "Documentation or policy statements explaining the interpretation of the term \"generally available to the public\" within the PDPA framework. Additionally, records demonstrating the organization's understanding of this concept and how it applies when determining the status of personal data as publicly available."
    },
    {
        clauseSection: "12.86",
        sectionDescription: "The interpretation of whether personal data is publicly available depends on the circumstances surrounding its disclosure.",
        expectedEvidences: "Documentation outlining examples and scenarios to illustrate the interpretation of when personal data is considered publicly available."
    },
    {
        clauseSection: "12.87",
        sectionDescription: "The Commission acknowledges that personal data which is publicly available at one point in time may cease to be publicly available for various reasons thereafter.",
        expectedEvidences: "Documentation or guidelines demonstrating the Commission's recognition of the dynamic nature of personal data availability and its sensitivity to changes in privacy settings, particularly in the context of social networking sites. Additionally, records illustrating the organization's awareness of these considerations and their adherence to PDPA regulations regarding the handling of publicly available personal data, including any changes in availability over time."
    },
    {
        clauseSection: "12.88",
        sectionDescription: "The Commission acknowledges the impracticality of requiring organizations to continuously verify the public availability of personal data, particularly when its use or disclosure occurs some time after collection. As a result, the Commission takes the position that as long as the personal data was publicly available at the point of collection, organizations are permitted to use and disclose it without consent under the corresponding exceptions.",
        expectedEvidences: "Documentation or guidelines outlining the Commission's recognition of the burden associated with constantly verifying the public availability of personal data and its stance regarding the use and disclosure of such data without consent under specific exceptions. Additionally, records demonstrating the organization's understanding of this position and their compliance with PDPA regulations regarding the use and disclosure of publicly available personal data."
    },
    {
        clauseSection: "12.89",
        sectionDescription: "Publicly available personal data encompasses a category defined within the PDPA, namely personal data observed in public. For this definition to apply, two requirements regarding how and where the personal data",
        expectedEvidences: "Documentation outlining the specific definition of publicly available personal data, including the category of personal data observed in public and the requirements for its application. Additionally, records demonstrating the organization's compliance with these requirements when handling personal data observed in public, as mandated by the PDPA."
    },
    {
        clauseSection: "12.9O",
        sectionDescription: "Personal data is considered to be observed by reasonably expected means if individuals should reasonably anticipate their personal data being collected in that specific manner at the given location or event. It's crucial to note that this assessment is objective, focusing on what individuals should reasonably expect rather than on individual preferences, which may vary from person to person.",
        expectedEvidences: "Documentation or guidelines outlining the objective nature of the test for determining whether personal data is observed by reasonably expected means. Additionally, records demonstrating the organization's adherence to this objective test when collecting personal data observed in public, as required by the PDPA."
    },
    {
        clauseSection: "12.91",
        sectionDescription: "A location or event is deemed \"open to the public\" if members of the public can enter or access it with minimal or no restrictions.",
        expectedEvidences: "Documentation outlining the criteria for determining whether a location or event is considered \"open to the public,\" including relevant factors affecting public access. Additionally, records demonstrating the organization's assessment of these factors when determining the status of locations or events in relation to the PDPA's provisions regarding publicly available personal data."
    },
    {
        clauseSection: "12.92",
        sectionDescription: "The presence of some restrictions alone does not necessarily disqualify a location or event from being considered \"open to the public\" for the purposes of the PDPA.",
        expectedEvidences: "Documentation outlining the exceptions to the presence of restrictions when determining whether a location or event is considered \"open to the public\" under the PDPA."
    },
    {
        clauseSection: "12.93",
        sectionDescription: "The Commission acknowledges the existence of private spaces within public areas.",
        expectedEvidences: "Documentation detailing the Commission's recognition of private spaces within public areas and its criteria for determining whether a location or event is open to the public. Additionally, records illustrating the organization's understanding and compliance with these criteria when handling personal data observed in public spaces."
    },
    {
        clauseSection: "12.94",
        sectionDescription: "The PDPA includes an exception allowing news organizations to collect, use, and disclose personal data without consent solely for their news activities, irrespective of whether the personal data is publicly available. For comprehensive definitions of \"news organization\" and \"news activity,\" it's recommended to refer directly to the PDPA.",
        expectedEvidences: "Documentation referencing the specific exception provided in the PDPA for news organizations regarding the collection, use, and disclosure of personal data for news activities. Additionally, records demonstrating the organization's adherence to the definitions and requirements outlined within the PDPA for news-related data handling."
    },
    {
        clauseSection: "13.1",
        sectionDescription: "Section 18 of the PDPA outlines limitations on the purposes and extent to which an organization can collect, use, or disclose personal data.",
        expectedEvidences: "Documentation referencing Section 18 of the PDPA, which specifies the permissible purposes for collecting, using, or disclosing personal data, along with the requirement for informing individuals of these purposes as part of the Notification Obligation."
    },
    {
        clauseSection: "13.2",
        sectionDescription: "The obligation of organizations to restrict the collection, use, and disclosure of personal data to the limited purposes specified in Section 18 of the PDPA is termed the Purpose Limitation Obligation in these guidelines.",
        expectedEvidences: "Documentation or guidelines referencing the term \"Purpose Limitation Obligation\" and its definition within the context of the PDPA guidelines. Additionally, records illustrating the organization's adherence to this obligation by ensuring that personal data is collected."
    },
    {
        clauseSection: "13.3",
        sectionDescription: "The primary objective of the Purpose Limitation Obligation is to ensure that organizations collect, use, and disclose only personal data that are relevant to the specified purposes and deemed reasonable. This obligation aligns with the Notification Obligation, further restricting the collection, use, or disclosure of personal data to purposes that have been communicated to the individuals concerned as required by the Notification Obligation, where applicable.",
        expectedEvidences: "Documentation or guidelines outlining the main objective of the Purpose Limitation Obligation within the PDPA framework, emphasizing the relevance and reasonableness of data collection, use, and disclosure. Additionally, records demonstrating the organization's compliance with the Purpose Limitation Obligation by ensuring that personal data handling aligns with the specified purposes and notification to individuals, as required by the PDPA."
    },
    {
        clauseSection: "13.4",
        sectionDescription: "According to section 18 of the PDPA, the reasonableness of a purpose for collecting, using, or disclosing personal data is contingent upon whether a reasonable person would deem it appropriate in the given circumstances. Therefore, the specific circumstances surrounding the data handling must be considered when evaluating the reasonableness of the purpose. For instance, a purpose that violates the law or poses harm to the individual concerned is unlikely to be deemed appropriate by a reasonable person.",
        expectedEvidences: "Documentation or guidelines referencing section 18 of the PDPA and its stipulation regarding the reasonableness of purposes for personal data handling. Additionally, records demonstrating the organization's consideration of specific circumstances when evaluating the reasonableness of purposes for collecting, using, or disclosing personal data."
    },
    {
        clauseSection: "14.1",
        sectionDescription: "In accordance with the Consent Obligation and the Purpose Limitation Obligation outlined in previous chapters, organizations are required to inform individuals of the purposes for which their personal data will be collected, used, and disclosed in order to obtain their consent.",
        expectedEvidences: "Documentation or guidelines referencing the requirement for organizations to inform individuals of the purposes for personal data handling as part of obtaining consent."
    },
    {
        clauseSection: "14.2",
        sectionDescription: "Section 20 of the PDPA establishes the obligation of organizations to inform individuals of the purposes for the collection, use, and disclosure of their personal data.",
        expectedEvidences: "Documentation or guidelines referencing Section 20 of the PDPA and its stipulations regarding informing individuals of the purposes for personal data collection, use, and disclosure. Additionally, records demonstrating the organization's compliance with Section 20 by providing individuals with appropriate notifications regarding the purposes for handling their personal data."
    },
    {
        clauseSection: "14.3",
        sectionDescription: "The obligation for organizations to inform individuals about the purposes for which their personal data will be collected, used, and disclosed is termed the Notification Obligation in these guidelines.",
        expectedEvidences: "Documentation or guidelines referencing the term \"Notification Obligation\" and its definition within the context of the PDPA guidelines. Additionally, records illustrating the organization's adherence to this obligation by providing individuals with appropriate notifications regarding the purposes for handling their personal data."
    },
    {
        clauseSection: "14.4",
        sectionDescription: "The Notification Obligation, which mandates organizations to inform individuals about the purposes for collecting, using, and disclosing their personal data, does not apply in specific circumstances outlined in section 20(3) of the PDPA.",
        expectedEvidences: "Documentation or guidelines referencing section 20(3) of the PDPA and its exemptions from the Notification Obligation. Additionally, records demonstrating the organization's understanding of these exemptions and their adherence to PDPA regulations when handling personal data in the specified circumstances."
    },
    {
        clauseSection: "14.5",
        sectionDescription: "It's crucial for organizations to identify the purposes for collecting, using, or disclosing personal data by establishing appropriate policies and procedures. These measures enable organizations to determine the necessary personal data required for their business purposes and ensure that the collected data aligns with the identified purposes. Additionally, such policies and procedures help minimize the risk of contravening the Data Protection Provisions by ensuring lawful and compliant handling of personal data.",
        expectedEvidences: "Documentation or guidelines outlining the importance of establishing policies and procedures to identify purposes for personal data handling. Additionally, records demonstrating the organization's implementation of such policies and procedures to ensure compliance with Data Protection Provisions and minimize risks associated with personal data handling."
    },
    {
        clauseSection: "14.6",
        sectionDescription: "Documentation or guidelines detailing the considerations regarding the Notification Obligation, including the timing, manner, and content of informing individuals about the organization's purposes for collecting, using, and disclosing their personal data.",
        expectedEvidences: "Documentation or guidelines detailing the considerations regarding the Notification Obligation, including the timing, manner, and content of informing individuals about the organization's purposes for collecting, using, and disclosing their personal data."
    },
    {
        clauseSection: "14.7",
        sectionDescription: "Under sections 20(1), (4), and (5) of the PDPA, organizations are required to inform individuals about the purposes for which their personal data will be collected, used, or disclosed on or before such collection, use, or disclosure.",
        expectedEvidences: "Documentation or guidelines referencing sections 20(1), (4), and (5) of the PDPA and their requirements regarding informing individuals about the purposes for personal data handling. Additionally, records demonstrating the organization's compliance with these requirements by providing timely notification to individuals when collecting, using, or disclosing their personal data."
    },
    {
        clauseSection: "14.8",
        sectionDescription: "In certain scenarios, organizations may be required to inform individuals before entering into contracts or before the first collection of personal data for periodic use.",
        expectedEvidences: "Documentation or guidelines outlining situations where organizations must inform individuals before entering into contracts or before the first collection of personal data for periodic use. Additionally, records demonstrating the organization's compliance with these requirements by providing timely notification to individuals in such situations."
    },
    {
        clauseSection: "14.9",
        sectionDescription: "The PDPA does not prescribe a specific manner or form in which organizations must inform individuals about the purposes for which their personal data is collected, used, or disclosed. Instead, organizations are encouraged to determine the most effective way of providing this information to individuals, ensuring that they understand the purposes for which their personal data is being handled.",
        expectedEvidences: "Documentation or guidelines acknowledging the absence of specific requirements regarding the manner or form of informing individuals about personal data purposes under the PDPA. Additionally, records demonstrating the organization's efforts to develop effective communication strategies to inform individuals about personal data handling purposes in a clear and understandable manner."
    },
    {
        clauseSection: "14.10",
        sectionDescription: "The circumstances and method of collecting personal data: Considering how and under what circumstances personal data will be collected can help determine the most suitable way to inform individuals about the purposes.",
        expectedEvidences: "Documentation or guidelines detailing the factors organizations should consider when determining the appropriate manner and form of notifying individuals about personal data purposes. Additionally, records demonstrating the organization's consideration of these factors in developing effective notification strategies."
    },
    {
        clauseSection: "14.11",
        sectionDescription: "Organizations are required to document their purposes in written form, which can be in electronic or other documentary evidence.",
        expectedEvidences: "Documentation or guidelines advocating for the documentation of purposes in written form and specifying potential formats such as service agreements or separate data protection notices. Additionally, records demonstrating the organization's adherence to this practice by providing written documentation of purposes to individuals as appropriate."
    },
    {
        clauseSection: "14.12",
        sectionDescription: "The PDPA mandates organizations to establish and implement policies and procedures essential for meeting their obligations under the PDPA.",
        expectedEvidences: "Documentation or guidelines emphasizing the necessity for organizations to develop policies and procedures for PDPA compliance and to make information available on such policies. Additionally, records demonstrating the organization's choice to develop a Data Protection Policy and use it as a means to notify individuals about personal data handling purposes."
    },
    {
        clauseSection: "14.13",
        sectionDescription: "The Data Protection Policy can be provided to individuals in various formats, such as a physical document, on the organization's website, or through other means.",
        expectedEvidences: "Documentation or guidelines outlining considerations for providing the Data Protection Policy to individuals and ensuring their understanding of personal data handling purposes."
    },
    {
        clauseSection: "14.14",
        sectionDescription: "Organizations are not obligated to provide individuals with information regarding internal corporate governance matters, such as expense policies or corporate rules, which are unrelated to the organization's data protection policies and practices as part of their Data Protection Policy.",
        expectedEvidences: "Documentation or guidelines clarifying that organizations are not required to include information on internal corporate governance matters in their Data Protection Policy if unrelated to data protection policies and practices."
    },
    {
        clauseSection: "14.15",
        sectionDescription: "An organization should articulate its purposes with an appropriate level of detail to enable individuals to understand the reasons and manner in which the organization will collect, use, or disclose their personal data.",
        expectedEvidences: "Documentation or guidelines explaining the level of detail required when stating purposes to individuals regarding personal data handling. Additionally, records demonstrating the organization's adherence to this principle by appropriately articulating purposes without specifying every single activity related to personal data handling."
    },
    {
        clauseSection: "14.16",
        sectionDescription: "Necessity of the purpose for providing products or services: Distinguishing between purposes that are essential for product or service provision and those that are optional can help prioritize and appropriately detail purposes.",
        expectedEvidences: "Documentation or guidelines outlining considerations for determining the level of specificity when stating purposes regarding personal data handling. Additionally, records demonstrating the organization's application of these factors in articulating purposes to individuals."
    },
    {
        clauseSection: "14.17",
        sectionDescription: "Clear and easily comprehensible: Ensuring that the information provided is easy to understand, avoiding jargon or overly technical language. Providing appropriate information: Including relevant details about the purposes of personal data processing, such as the intended uses, potential disclosures, and any associated risks.",
        expectedEvidences: "Documentation or guidelines emphasizing the importance of clear, comprehensive, and accessible notifications about personal data processing purposes. Additionally, records demonstrating the organization's efforts to provide such notifications to individuals in accordance with regulatory requirements."
    },
    {
        clauseSection: "14.18",
        sectionDescription: "Implementing a layered notice approach: Providing key or essential information more prominently, such as in summaries or on the first page of agreements, while offering more detailed information elsewhere, such as on the organization's website. This approach is particularly useful when individuals may not wish to read all the information immediately or when the transaction medium may not facilitate detailed disclosures.",
        expectedEvidences: "Documentation or guidelines outlining the considerations taken into account when determining how to notify individuals of their personal data processing purposes. Additionally, records demonstrating the implementation of these considerations in the organization's notification policies and practices."
    },
    {
        clauseSection: "14.19",
        sectionDescription: "The Data Protection Provisions acknowledge that organizations may encounter situations where they need to utilize or disclose an individual's personal data for purposes that haven't been explicitly communicated to the individual or for which consent hasn't been obtained yet.",
        expectedEvidences: "Documentation or guidelines outlining the provisions within the Data Protection Provisions that address scenarios where organizations may need to use or disclose personal data without prior notification or consent, along with any procedures or safeguards put in place to ensure compliance with relevant regulations in such circumstances."
    },
    {
        clauseSection: "14.20",
        sectionDescription: "In cases where an organization intends to utilize or disclose personal data for purposes that have not been previously communicated to the individual or for which consent has not been obtained, the Data Protection Provisions mandate that organizations must inform individuals of these purposes and obtain their consent.",
        expectedEvidences: "Documentation outlining the Notification and Consent Obligation as stipulated within the Data Protection Provisions, along with internal policies or procedures detailing how organizations ensure compliance with these obligations. This may include consent forms, communication strategies for informing individuals of new purposes, and records demonstrating the consent obtained from individuals for the additional data processing activities."
    },
    {
        clauseSection: "14.21",
        sectionDescription: "Determining if the intended purpose aligns with those initially communicated to the individual, particularly within the context of existing business relationships. Evaluating if consent can be implied from the individual based on provisions outlined in Sections 15 or 15A of the PDPA.",
        expectedEvidences: "Internal guidelines or protocols outlining the criteria for assessing the permissibility of using or disclosing personal data for new purposes without obtaining fresh consent. This may include documentation demonstrating how organizations interpret and apply the relevant sections of the PDPA to determine compliance with consent requirements."
    },
    {
        clauseSection: "14.22",
        sectionDescription: "If the intended purpose for using or disclosing personal data does not meet the criteria outlined in sub-paragraphs (a) to (c), organizations are obligated to obtain fresh consent from the individual for such use and disclosure.",
        expectedEvidences: "Internal policies or procedures delineating the steps to be taken when the intended purpose for using or disclosing personal data does not align with the criteria specified in sub-paragraphs (a) to (c). This documentation should illustrate how organizations facilitate the process of obtaining fresh consent from individuals for new purposes not covered by existing consent or exceptions under the PDPA."
    },
    {
        clauseSection: "15.1",
        sectionDescription: "Sections 21, 22, and 22A of the PDPA outline the rights of individuals to request access to and correction of their personal data held by organizations.",
        expectedEvidences: "Documentation illustrating organizational procedures and protocols for handling requests from individuals for access to and correction of their personal data in accordance with Sections 21, 22, and 22A of the PDPA. This may include internal policies, guidelines, or manuals outlining the process for receiving, reviewing, and responding to such requests, as well as records of individual requests and corresponding actions taken by the organization to fulfill these obligations."
    },
    {
        clauseSection: "15.2",
        sectionDescription: "The Access and Correction Obligations pertain not only to personal data directly held by an organization but also to personal data under its control, even if not in its possession.",
        expectedEvidences: "Documentation demonstrating organizational awareness of its responsibilities regarding personal data held by data intermediaries, including policies or contracts outlining the allocation of Access and Correction Obligations. This evidence may include agreements or contracts with data intermediaries outlining their roles and responsibilities regarding access and correction requests, as well as internal procedures for overseeing compliance with these obligations."
    },
    {
        clauseSection: "15.3",
        sectionDescription: "Section 21(1) of the PDPA stipulates that an organization must, upon request by an individual, furnish the individual with their personal data held by the organization, along with information regarding how that data has been or may have been used or disclosed by the organization within a year prior to the request.",
        expectedEvidences: "Documentation demonstrating procedures or systems in place for responding to access requests, including records of past responses to such requests and any relevant communication with individuals regarding their personal data. This may include access request forms, internal guidelines or policies for handling access requests, and records of data accessed or disclosed in response to previous requests."
    },
    {
        clauseSection: "15.4",
        sectionDescription: "Section 21(1) of the PDPA grants individuals the right to submit requests to organizations for access to their personal data held by the organization.",
        expectedEvidences: "Documentation illustrating procedures or mechanisms for managing access requests, including any templates or forms used for submission, internal guidelines outlining the process for handling such requests, and records of past requests and corresponding responses. Additionally, evidence may include communication logs or records detailing interactions with individuals regarding their access requests and the provision of relevant information."
    },
    {
        clauseSection: "15.5",
        sectionDescription: "When an individual submits an access request, an organization is obligated to facilitate access to the requested personal data within its possession or control.",
        expectedEvidences: "Documentation outlining the organization's procedures for handling access requests, including criteria for determining whether exceptions apply and protocols for responding to requests. Additionally, records of access requests received, actions taken to fulfill these requests, and any correspondence with individuals regarding access to their personal data would serve as evidence of compliance with this obligation."
    },
    {
        clauseSection: "15.6",
        sectionDescription: "An organization is obligated to provide access to the personal data requested by an individual under Section 21 of the PDPA.",
        expectedEvidences: "Documentation outlining the organization's procedures for responding to access requests, including protocols for providing access to personal data contained within documents or systems. Records of access requests received and actions taken to fulfill these requests, along with any communication with individuals regarding access to their personal data, would serve as evidence of compliance with this obligation."
    },
    {
        clauseSection: "15.7",
        sectionDescription: "When an organization no longer possesses or controls certain requested personal data at the time of an access request, it is not obliged to provide access to that data.",
        expectedEvidences: "Documentation or policies outlining the organization's procedures for responding to access requests, including protocols for handling situations where requested data is no longer within its possession or control. Records of communications with individuals regarding access requests, particularly instances where the organization informs individuals of its inability to provide access due to the unavailability of the requested data, would serve as evidence of compliance with this obligation."
    },
    {
        clauseSection: "15.8",
        sectionDescription: "In specific cases, individuals making access requests may request a copy of their personal data in documentary form.",
        expectedEvidences: "Documentation outlining the organization's policy regarding the provision of copies of personal data in response to access requests. Records of instances where individuals requested copies of their personal data and the organization's responses, including any fees charged or explanations provided for the inability to produce the data in documentary form, would serve as evidence of compliance with this obligation. Additionally, financial records or fee schedules related to charges for producing copies of personal data may be provided as evidence."
    },
    {
        clauseSection: "15.9",
        sectionDescription: "Organizations are obligated to provide access to personal data regardless of its form, including unstructured data like that embedded in emails.",
        expectedEvidences: "Documentation demonstrating the implementation of processes to track and manage unstructured personal data, such as policies, procedures, or system records. Records of instances where access requests were denied due to reasons such as unreasonable burden or expense, disproportionality to the individual's interest, or frivolous or vexatious nature, along with explanations provided to the individuals, would serve as evidence of compliance with this obligation."
    },
    {
        clauseSection: "15.10",
        sectionDescription: "If the personal data requested by an individual can be accessed by the individual directly, the organization may inform the individual about how to retrieve the requested data themselves.",
        expectedEvidences: "Documentation or communication demonstrating instances where individuals were informed about how to access their personal data directly through online portals or other means provided by the organization. This may include user guides, instructions, or communication records detailing how individuals were directed to access their data."
    },
    {
        clauseSection: "15.11",
        sectionDescription: "The PDPA does not mandate that an access request must include a reason for making the request. However, organizations are encouraged to ask requesters for specifics regarding the type of personal data needed and the time of collection to facilitate processing.",
        expectedEvidences: "Documentation demonstrating procedures or communications where individuals are asked for specifics regarding their access requests, such as forms or templates used for requesting access to personal data. Additionally, records showing how organizations assess access requests based on their purpose and determine the most suitable method of providing access to personal data."
    },
    {
        clauseSection: "15.12",
        sectionDescription: "Organizations are advised to verify an individual's identity before responding to an access request to ensure compliance with the PDPA and minimize disputes.",
        expectedEvidences: "Documentation illustrating organizational policies or procedures for verifying the identity of individuals making access requests, such as guidelines or manuals outlining the verification process. Additionally, records demonstrating the implementation of these procedures, such as logs of identity verification activities conducted during access request processing."
    },
    {
        clauseSection: "15.13",
        sectionDescription: "Organizations must verify the legal authority of third parties acting on behalf of individuals when making access requests.",
        expectedEvidences: "Documentation demonstrating the verification process for third-party requests, such as authorization letters or legal documents confirming the third party's authority to act on behalf of the individual. Additionally, records showing communication or correspondence with the third party regarding their authorization to make the access request."
    },
    {
        clauseSection: "15.14",
        sectionDescription: "Organizations may encounter situations where multiple individuals, such as spouses, make simultaneous access requests for their respective personal data contained in the same records.",
        expectedEvidences: "Documentation demonstrating the consent obtained from multiple individuals for disclosure of their personal data to each other, if applicable. Records of communication with individuals regarding access requests and any actions taken to ensure compliance with privacy regulations in cases where consent cannot be obtained."
    },
    {
        clauseSection: "15.15",
        sectionDescription: "Section 21(1) of the PDPA mandates that organizations must provide information about how an individual's personal data has been or may have been used or disclosed within the past year upon request.",
        expectedEvidences: "Documentation demonstrating the development, updating, and accuracy verification process of the standard list of possible third parties to whom personal data may be disclosed. Records of responses to access requests including the provision of information about how personal data has been or may have been disclosed, whether by disclosing specific entities or providing the standard list."
    },
    {
        clauseSection: "15.16",
        sectionDescription: "Organizations responding to access requests can specify how personal data has been or may have been used or disclosed within the past year by providing information on the purposes rather than detailing specific activities.",
        expectedEvidences: "Records or templates used by organizations to respond to access requests, demonstrating the approach of providing information on purposes rather than specific activities. Examples of responses provided to individuals where the organization outlines the purposes for which personal data has been or may have been used or disclosed within the past year."
    },
    {
        clauseSection: "15.17",
        sectionDescription: "This section emphasizes the need for organizations to tailor their responses to requests under the Personal Data Protection Act (PDPA) according to the specific nature of each request.",
        expectedEvidences: "Documentation of Requests: Records demonstrating the nature of requests received by the organization under the PDPA, including the type of data involved, the purpose of the request, and any relevant details provided by the data subject. Response Protocols: Written procedures outlining how the organization handles different types of PDPA requests, including criteria for assessing appropriateness and determining the scope of the response."
    },
    {
        clauseSection: "15.18",
        sectionDescription: "This section outlines the obligations of organizations under the Personal Data Protection Act (PDPA) and the accompanying Personal Data Protection Regulations 2021.",
        expectedEvidences: "Access Request Logs: Records documenting the receipt of access requests, including the date of submission, the nature of the request, and any accompanying details provided by the individual. Response Timelines: Internal guidelines or policies outlining the organization's procedures for responding to access requests and the expected timeline for fulfilling such requests in accordance with Section 21(1) of the PDPA."
    },
    {
        clauseSection: "15.19",
        sectionDescription: "This section delineates the obligations of an organization when responding to an access request made by an individual regarding their personal data.",
        expectedEvidences: "Access Request Handling Procedures: Documented procedures outlining the steps followed by the organization when processing access requests, including criteria for determining whether access should be granted or denied. Access Request Logs: Records of access requests received by the organization, indicating whether access was granted or denied, along with the rationale for any rejections."
    },
    {
        clauseSection: "15.20",
        sectionDescription: "This section clarifies that organizations are not obligated to fulfill an access request if an exception from the access requirement applies.",
        expectedEvidences: "Exception Criteria Documentation: Documentation outlining the specific exceptions recognized by the organization, including references to relevant provisions within the PDPA or other applicable regulations. Access Request Handling Procedures: Procedures detailing how the organization assesses access requests against the exceptions."
    },
    {
        clauseSection: "15.21",
        sectionDescription: "This section outlines restrictions on organizations regarding the disclosure of personal data to prescribed law enforcement agencies for investigative or procedural purposes.",
        expectedEvidences: "Disclosure Procedures: Documented procedures outlining the circumstances under which the organization may disclose personal data to law enforcement agencies for investigative or procedural purposes, including requirements for authorization and confidentiality. Authorization Records: Records demonstrating the authorization granted to the organization or its officers to disclose personal data to prescribed law enforcement agencies, including any legal or regulatory documentation supporting such disclosures."
    },
    {
        clauseSection: "15.22",
        sectionDescription: "This section outlines the conditions under which an organization is not obliged to respond to a request for personal data access unless the requester agrees to pay the fee for the services provided by the organization to facilitate the response.",
        expectedEvidences: "Fee Assessment Procedures: Documented procedures outlining how the organization determines the fee for services provided to facilitate responses to access requests, including factors considered in estimating the fee. Fee Estimate Templates: Standardized templates or forms used by the organization to provide written estimates of fees to requesters, detailing the anticipated costs associated with processing their access requests."
    },
    {
        clauseSection: "15.23",
        sectionDescription: "Section 21(3) of the relevant legislation outlines grounds under which an organization is not obligated to fulfill an access request for personal data or other information.",
        expectedEvidences: "Expected evidence to support this point may include documentation or records indicating potential threats to safety or health, expert opinions or assessments regarding the potential consequences of disclosing the information, or any relevant legal precedents or guidelines supporting the decision not to fulfill the access request based on these grounds."
    },
    {
        clauseSection: "15.24",
        sectionDescription: "If an organization is unable to respond to an access request within the 30-day timeframe due to technical processing constraints, it is required to inform the applicant within the initial 30 days about the anticipated date for response and must still fulfill the request as soon as reasonably possible.",
        expectedEvidences: "Documentation demonstrating communication with the applicant within the initial 30-day period, specifying the reasons for the delay and providing an estimated timeline for response, along with subsequent evidence showcasing the organization's efforts to fulfill the request promptly once technical processing hurdles are resolved."
    },
    {
        clauseSection: "15.25",
        sectionDescription: "An organization is permitted to charge an individual a reasonable fee for processing an access request made by that individual.",
        expectedEvidences: "Documentation outlining the fee structure for processing access requests, including details on what constitutes a reasonable fee and how it aligns with the incremental costs incurred by the organization. Additionally, records demonstrating the breakdown of costs associated with responding to access requests, such as expenses related to personnel time, search efforts, and any direct expenses incurred in providing access, along with policies or guidelines emphasizing the prohibition of transferring capital purchase costs to individuals."
    },
    {
        clauseSection: "15.26",
        sectionDescription: "The commission acknowledges the challenge in establishing a standard fee or range of fees applicable across all industries or types of access requests.",
        expectedEvidences: "Documentation highlighting the commission's acknowledgment of the difficulty in prescribing standard fees and encouraging organizations to exercise judgment in determining fees based on incremental costs. This could include official statements, guidelines, or directives issued by the commission. Additionally, evidence demonstrating the process and criteria for individuals to apply for a review of fees charged by organizations under section 48H of the PDPA, indicating avenues for recourse if individuals believe the fees are unreasonable."
    },
    {
        clauseSection: "15.27",
        sectionDescription: "If an organization intends to charge an individual a fee for processing an access request, it is required to provide the individual with a written estimate of the fee.",
        expectedEvidences: "Documentation outlining the requirement for organizations to furnish individuals with a written estimate of the fee for processing access requests. This may include excerpts from relevant legislation, regulations, or guidelines stipulating this requirement. Additionally, evidence demonstrating the organization's adherence to this provision, such as records of written estimates provided to individuals and documentation of any communications regarding adjustments to fees exceeding the original estimate."
    },
    {
        clauseSection: "15.28",
        sectionDescription: "The requirement stated in section 21(1) to provide access to personal data is subject to various exceptions detailed in sections 21(2) to 21(4).",
        expectedEvidences: "Documentation highlighting the specific mandatory exceptions listed in sections 21(2) to 21(4) that dictate situations where an organization must refrain from providing access to personal data. This evidence could be sourced from the relevant legislation, regulations, or official guidelines governing data protection and privacy practices."
    },
    {
        clauseSection: "15.29",
        sectionDescription: "Section 21(2) of the PDPA stipulates that an organization is exempted from the obligation to furnish individuals with the specified personal data or other information outlined in section 21(1) concerning matters listed in the Fifth Schedule of the PDPA.",
        expectedEvidences: "Documentation demonstrating the provision within the PDPA highlighting section 21(2) and its exemption clause regarding the specified matters listed in the Fifth Schedule. This evidence could be sourced from the official text of the PDPA or relevant regulatory guidelines, indicating the scope of exemption granted to organizations in providing certain personal data or information to individuals."
    },
    {
        clauseSection: "15.3O",
        sectionDescription: "Opinion data kept solely for an evaluative purpose. Examination-related data such as examination conducted by an educational institution, examination scripts, and examination results before their release. Personal data of beneficiaries of a private trust kept solely for trust administration. Personal data held by arbitral institutions or mediation centers solely for arbitration or mediation proceedings they administer.",
        expectedEvidences: "Documentation elucidating the types of data exempt from access requests as per the provisions of Section 21(2), 21(3), and 21(4) of the PDPA. This could include internal policies, guidelines, or legal references specifying the categories of data excluded from access requests."
    },
    {
        clauseSection: "15.31",
        sectionDescription: "Section 21(3) of the PDPA outlines specific scenarios where an organization is mandated to refrain from providing the personal data or other information as specified in section 21(1), extending beyond the matters listed in the Fifth Schedule to the PDPA.",
        expectedEvidences: "Documentation highlighting the provisions of section 21(3) of the PDPA within the relevant legal framework, specifying the situations in which an organization must withhold the provision of personal data or other information as outlined in section 21(1). This evidence could be sourced from the official text of the PDPA or regulatory guidelines, indicating the circumstances under which organizations are legally obligated to deny access to certain personal data or information."
    },
    {
        clauseSection: "15.32",
        sectionDescription: "Section 21(3) of the PDPA specifies scenarios where an organization must refrain from providing the personal data or other information as outlined in section 21(1).",
        expectedEvidences: "Documentation detailing the provisions of section 21(3) of the PDPA within the legal framework, enumerating the situations in which organizations are mandated to withhold the provision of personal data or other information specified in section 21(1). This evidence could be sourced from the official text of the PDPA or regulatory guidelines, emphasizing the necessity for organizations to exercise caution in disclosing certain personal data or information to uphold individual privacy rights and broader societal interests."
    },
    {
        clauseSection: "15.33",
        sectionDescription: "Section 21(5) of the PDPA mandates that if an organization can furnish the individual with their requested personal data and other information specified in section 21(1) without including any data excluded under sections 21(2), 21(3), and 21(4), it must provide access to the requested personal data and information without excluding any relevant data",
        expectedEvidences: "Documentation elucidating the provisions of section 21(5) of the PDPA within the legal framework, emphasizing the obligation of organizations to provide access to requested personal data and information without excluding any relevant data if feasible."
    },
    {
        clauseSection: "15.34",
        sectionDescription: "Section 21(3)(c) prohibits organizations from providing access to personal data or other information under section 21(1) if such provision could reasonably be expected to disclose personal data about another individual.",
        expectedEvidences: "Documentation elucidating the provisions of section 21(3)(c) of the PDPA within the legal framework, detailing the prohibition on providing access to personal data that could reveal information about another individual. This evidence could be sourced from the official text of the PDPA or regulatory guidelines, highlighting exceptions to this prohibition and outlining circumstances where disclosure is permitted or required by law."
    },
    {
        clauseSection: "15.35",
        sectionDescription: "Section 21(4) of the PDPA imposes an additional obligation on organizations concerning the Access Obligation.",
        expectedEvidences: "Documentation outlining the provisions of section 21(4) of the PDPA within the legal framework, emphasizing the obligation of organizations regarding the disclosure of personal data to law enforcement agencies without the individual's consent. This evidence could be sourced from the official text of the PDPA or relevant regulatory guidelines, highlighting the restrictions imposed on organizations regarding informing individuals about such disclosures."
    },
    {
        clauseSection: "15.36",
        sectionDescription: "Paragraph 1(h) of the Fifth Schedule of the PDPA provides an exemption for personal data collected for the purpose of prosecution, investigation, civil proceedings, and associated proceedings and appeals from access requests.",
        expectedEvidences: "Documentation highlighting the provision of paragraph 1(h) of the Fifth Schedule within the PDPA, outlining the exemption for personal data collected for legal proceedings. This evidence could be sourced from the official text of the PDPA or relevant regulatory guidelines, emphasizing the exemption granted to organizations regarding access requests for personal data collected in the context of legal proceedings."
    },
    {
        clauseSection: "15.37",
        sectionDescription: "In cases where personal data collected prior to the commencement of prosecution and investigations remains relevant to legal proceedings, individuals are advised to seek access through criminal and civil discovery avenues instead of relying on the Access Obligation under the PDPA.",
        expectedEvidences: "Documentation highlighting the guidance provided regarding access to personal data in the context of legal proceedings, emphasizing the recommendation to utilize criminal and civil discovery avenues rather than the Access Obligation under the PDPA. This evidence could be sourced from official guidance documents, legal opinions, or regulatory statements, indicating the framework for accessing personal data relevant to legal proceedings and the relationship between the PDPA and discovery obligations under law."
    },
    {
        clauseSection: "15.38",
        sectionDescription: "According to the PDPA and the Personal Data Protection Regulations 2021, an organization is required to provide a response to the individual, even if access to the requested personal data or other information is not granted.",
        expectedEvidences: "Documentation outlining the requirement for organizations to provide a response to individuals, even if access to requested personal data is denied, as stipulated by the PDPA and the Personal Data Protection Regulations 2021. This evidence could be sourced from the official text of the PDPA and the regulations, highlighting the obligation for organizations to communicate reasons for denying access and ensuring transparency in their decision-making process."
    },
    {
        clauseSection: "15.39",
        sectionDescription: "Section 22A of the PDPA and the Personal Data Protection Regulations 2021 mandate organizations to retain a complete and accurate copy of the personal data if they decline to provide it to the individual upon request.",
        expectedEvidences: "Documentation citing the provision of section 22A of the PDPA and the corresponding regulations, highlighting the obligation for organizations to preserve a complete and accurate copy of personal data if access is denied. This evidence could be sourced from the official text of the PDPA and the regulations, emphasizing the importance of data retention even in cases where access requests are refused."
    },
    {
        clauseSection: "15.4O",
        sectionDescription: "If an organization has scheduled periodic disposal or deletion of personal data, such as CCTV footage deleted every X days or physical documents containing personal data shredded every X days, the organization must promptly identify the requested personal data upon receiving the access request.",
        expectedEvidences: "Documentation highlighting the obligation of organizations to identify and preserve requested personal data when scheduled disposal or deletion processes are in place. This evidence could be sourced from official guidance documents, internal policies, or regulatory directives, emphasizing the importance of data preservation during the processing of access requests to uphold individuals' rights to access their personal data."
    },
    {
        clauseSection: "15.41",
        sectionDescription: "This section focuses on the importance of mindful data management within organizations, particularly concerning personal data. It emphasizes the need to avoid the unnecessary preservation of personal data solely for potential future access requests. Furthermore, it stresses the importance of not retaining personal data indefinitely when there is no valid business or legal justification.",
        expectedEvidences: "Data Retention Policies: Provide documented evidence of organizational policies outlining data retention guidelines, particularly concerning personal data. This should include specifics on the duration for which personal data is retained and the criteria for its disposal.\n      Compliance Documentation: Present evidence of compliance with relevant data protection regulations such as GDPR (General Data Protection Regulation), CCPA (California Consumer Privacy Act), or other applicable laws."
    },
    {
        clauseSection: "15.42",
        sectionDescription: "This section outlines the obligations of organizations under Section 21 of the Personal Data Protection Act (PDPA) and Part 2 of the Personal Data Protection Regulations 2021 regarding access requests for personal data. It specifies that if an organization decides not to provide some or all of the personal data requested by an individual (\"withheld personal data\"), it must preserve a complete and accurate copy of this withheld personal data for a specified duration.",
        expectedEvidences: "Access Request Handling Procedures: Provide documented procedures outlining how the organization handles access requests for personal data, including the process for assessing and responding to such requests in accordance with Section 21 of the PDPA and relevant regulations.\n      Records of Access Requests: Maintain records of access requests received from individuals, including details such as the date of the request, the information requested, and the organization's response, including any withheld personal data and the justification for withholding it."
    },
    {
        clauseSection: "15.43",
        sectionDescription: "This section highlights the potential consequences for organizations if it is determined by the Commission or any appellate body that they did not have appropriate grounds under the Personal Data Protection Act (PDPA) to refuse access to requested personal data. It emphasizes the risk of facing enforcement action under sections 48I and 48J of the PDPA for contravening obligations outlined in the legislation.",
        expectedEvidences: "Compliance Documentation: Provide evidence demonstrating the organization's adherence to the PDPA, including records of policies, procedures, and training materials related to handling access requests for personal data.\n      Records of Access Requests: Maintain documentation of all access requests received, along with the organization's responses, including any justifications for refusal to provide access to certain personal data."
    },
    {
        clauseSection: "15.44",
        sectionDescription: "The footage from its records or mark it for deletion in accordance with its data retention policies once Mary has received the requested data. ABC should also maintain a record of Mary's access request, including details such as the date of the request, the specific data requested, and confirmation of Mary's receipt of the data.",
        expectedEvidences: "If ABC assesses the access request but determines that it cannot provide Mary with access to the requested CCTV footage (e.g., due to technical limitations or legal restrictions), ABC must document the reasons for rejecting the request. This documentation should include an explanation of why access cannot be granted, citing relevant legal provisions or technical constraints."
    },
    {
        clauseSection: "15.45",
        sectionDescription: "This section clarifies that organizations cannot impose a charge for correcting personal data as required under Section 22 of the relevant data protection legislation.",
        expectedEvidences: "Policy Documentation: Provide documentation of organizational policies or procedures that outline the handling of requests for correcting personal data, including any statements explicitly stating that no charges will be imposed for such corrections."
    },
    {
        clauseSection: "15.46",
        sectionDescription: "This section states explicitly that organizations are prohibited from imposing charges for the correction of personal data as mandated by Section 22 of the relevant data protection legislation.",
        expectedEvidences: "Legal Compliance Documentation: Provide documentation showcasing the relevant sections of the data protection legislation that explicitly prohibit charging for the correction of personal data. This may include excerpts from the legislation itself or official guidance from regulatory authorities interpreting this provision."
    },
    {
        clauseSection: "15.47",
        sectionDescription: "This section outlines the obligation stipulated in Section 22(1) regarding the correction of personal data, noting that this obligation is subject to exceptions outlined in Section 22(6) and (7) of the relevant data protection legislation.",
        expectedEvidences: "Legal Documentation: Provide documentation of the relevant sections of the data protection legislation, including Section 22(1) and the exceptions specified in Section 22(6) and (7). This documentation should highlight the specific circumstances under which the obligation to correct personal data may not apply."
    },
    {
        clauseSection: "15.48",
        sectionDescription: "This section addresses the obligation stipulated in Section 22(3) of the Personal Data Protection Act (PDPA), which allows an organization, excluding credit bureaus, to send corrected personal data to specific organizations to which the data was disclosed within one year before the correction date, provided that the individual concerned has given consent.",
        expectedEvidences: "Provide documentation of organizational policies and procedures that outline the process for sending corrected personal data to specific organizations as allowed under Section 22(3) of the PDPA. This documentation should detail the criteria for determining the organizations to which corrected data may be sent and the steps involved in obtaining individual consent."
    },
    {
        clauseSection: "15.49",
        sectionDescription: "This section outlines the obligation imposed by Section 22(4) of the Personal Data Protection Act (PDPA), which mandates that organizations notified of a correction made by another organization in response to a correction request must similarly correct the personal data in their possession or under their control",
        expectedEvidences: "Policy Documentation: Provide documentation of organizational policies and procedures that outline the process for responding to correction notifications received from other organizations under Section 22(4) of the PDPA. This documentation should detail the steps involved in reviewing and making corrections to personal data based on notifications received."
    },
    {
        clauseSection: "15.5O",
        sectionDescription: "Section 22(5) of the Personal Data Protection Act (PDPA) mandates that if an organization, whether responding to a correction request or notified of a correction made by another organization, is satisfied upon reasonable grounds that a correction should not be made, it is required to annotate the personal data in its possession or control to indicate the requested correction that was not made.",
        expectedEvidences: "Maintain records of annotations made to personal data indicating requested corrections that were not made, along with the reasons for not making the corrections. This may include documentation of the date of annotation, the specific data annotated, and the rationale provided for withholding the correction."
    },
    {
        clauseSection: "15.51",
        sectionDescription: "Sections 22(6) and 22(7) of the Personal Data Protection Act (PDPA) outline exceptions to the requirement for organizations to correct or alter personal data. Section 22(6) states that organizations are not obligated to correct or alter opinions, including professional or expert opinions. Section 22(7) specifies that organizations are not required to make corrections for matters listed in the Sixth Schedule to the PDPA.",
        expectedEvidences: "Provide evidence of legal reviews or opinions obtained regarding the interpretation and application of Sections 22(6) and 22(7) of the PDPA. This may include documentation of legal advice received, memoranda, or correspondence with legal counsel confirming compliance with legislative requirements."
    },
    {
        clauseSection: "15.52",
        sectionDescription: "The requirement for organizations to correct personal data promptly upon receiving a correction request is subject to exceptions, as detailed in previous sections. However, generally, organizations are obligated to correct personal data as soon as practicable after receiving a correction request.",
        expectedEvidences: "Keep records of written notifications sent to individuals in cases where the organization is unable to correct the personal data within 30 days from the time the request is made. These notifications should include details of the estimated timeframe by which the correction will be made and any additional information relevant to the delay."
    },
    {
        clauseSection: "15.53",
        sectionDescription: "This section emphasizes that while organizations may offer standard forms or procedures for individuals to submit access and/or correction requests, they should accept all requests made in writing and sent to the designated business contact information of its Data Protection Officer (DPO). In the case of a body corporate, requests can be left at or sent by pre-paid post to the registered office or principal office of the body corporate in Singapore",
        expectedEvidences: "Maintain records of the designated business contact information of the DPO and ensure that it is readily accessible to individuals seeking to submit access and correction requests. This may include contact details provided on the organization's website, privacy notices, or other communication channels."
    },
    {
        clauseSection: "15.54",
        sectionDescription: "This section underscores that despite the provisions mentioned earlier, organizations still bear the responsibility outlined in Section 21(1) of the Personal Data Protection Act (PDPA) to provide access to personal data as soon as reasonably possible. Additionally, under Section 22(2) of the PDPA, organizations are obligated to correct personal data as soon as practicable.",
        expectedEvidences: "Keep records of communications with individuals regarding the status of their access and correction requests, including updates on the progress of the requests and any delays encountered. This documentation should demonstrate the organization's efforts to fulfill its responsibilities under Sections 21(1) and 22(2) of the PDPA in a timely manner."
    },
    {
        clauseSection: "16.1",
        sectionDescription: "Section 23 of the Personal Data Protection Act (PDPA) mandates that organizations must exert reasonable effort to ensure that personal data collected by or on behalf of the organization is accurate and complete in two specific scenarios",
        expectedEvidences: "Outline the criteria used by the organization to determine when personal data is likely to be used to make decisions affecting individuals or likely to be disclosed to other organizations. This documentation should provide clarity on the circumstances triggering the requirement for ensuring data accuracy and completeness."
    },
    {
        clauseSection: "16.2",
        sectionDescription: "Guidelines often refer to the obligation to ensure the accuracy and completeness of personal data as the \"Accuracy Obligation.\" The purpose of the Accuracy Obligation is to guarantee that when personal data is used to make decisions affecting individuals, the data is reasonably correct and complete. This ensures that decisions are made based on all relevant and accurate personal data available.",
        expectedEvidences: "Provide documentation that outlines the organization's adherence to the Accuracy Obligation as described in the guidelines. This may include references to the guidelines themselves and evidence of how the organization has implemented procedures to comply with this obligation."
    },
    {
        clauseSection: "16.3",
        sectionDescription: "The organization must accurately record personal data it collects, whether directly from the individual or through another organization. This involves ensuring that the information is recorded without errors or omissions.",
        expectedEvidences: "Provide documentation outlining the organization's processes for collecting personal data. This should include procedures for recording information accurately and ensuring completeness during the data collection phase."
    },
    {
        clauseSection: "16.4",
        sectionDescription: "Assess the currency of the data by considering whether it is recent or was collected some time ago. Recent data may be more relevant and accurate, while older data may require verification or updates to ensure its accuracy.",
        expectedEvidences: "Document the organization's data management practices, including procedures for verifying data accuracy, updating outdated information, and ensuring completeness. This documentation should demonstrate how the organization addresses the factors identified in the assessment."
    },
    {
        clauseSection: "16.5",
        sectionDescription: "This excerpt clarifies that while organizations are obligated to make reasonable efforts to ensure the accuracy and completeness of personal data, they are not required to conduct exhaustive checks or reviews every time a decision is made about an individual. Instead, organizations should perform risk assessments and focus their efforts on ensuring the accuracy and completeness of personal data likely to be used in decision-making processes affecting the individual.",
        expectedEvidences: "While organizations are not required to review all personal data in their possession for accuracy and completeness on every occasion, they should implement processes for ongoing data quality management and improvement. This may include periodic reviews, data validation checks, and corrective actions as necessary."
    },
    {
        clauseSection: "16.6",
        sectionDescription: "When there is doubt about the accuracy or completeness of personal data provided by the individual, organizations can consider requiring the individual to make a verbal or written declaration affirming the accuracy and completeness of the data. This declaration serves as additional assurance regarding the reliability of the information.",
        expectedEvidences: "In situations where the currency of personal data is important, organizations should take steps to verify that the information provided by the individual is up to date. This may involve requesting a more updated copy of the personal data before making decisions significantly impacting the individual. By verifying the currency of the data, organizations can ensure that decisions are based on the most current and relevant information available."
    },
    {
        clauseSection: "16.7",
        sectionDescription: "This passage underscores the importance of exercising caution when collecting personal data about an individual from sources other than the individual themselves. It highlights that organizations can adopt different approaches to ensure the accuracy and completeness of such data depending on the reliability of the data source.",
        expectedEvidences: "Depending on the circumstances and the level of trust in the data source, organizations may conduct further independent verification to ensure the accuracy and completeness of the data. This additional verification may involve cross-referencing information with other reliable sources or conducting checks to validate the authenticity of the data."
    },
    {
        clauseSection: "16.8",
        sectionDescription: "This passage underscores that similar considerations should be applied when deciding whether personal data should be updated. While not all types of personal data require updates, it's crucial for organizations to assess the potential impact of using outdated data in decision-making processes.",
        expectedEvidences: "Organizations should evaluate whether the use of outdated personal data in decision-making processes could adversely affect the individual. Factors such as the significance of the decision, the potential impact on the individual, and the criticality of the data to the decision should be taken into account."
    },
    {
        clauseSection: "16.9",
        sectionDescription: "This statement from the Commission emphasizes the importance of ensuring the accuracy of both raw personal data and derived data obtained from various sources.",
        expectedEvidences: "Organizations should verify the accuracy of raw personal data collected directly from individuals or from third-party sources before further processing. This verification ensures that the initial data is materially accurate and reliable, laying a solid foundation for subsequent processing activities."
    },
    {
        clauseSection: "17.1",
        sectionDescription: "Section 24 of the Personal Data Protection Act (PDPA) mandates organizations to establish reasonable security arrangements to safeguard personal data under their possession or control. This is essential to mitigate various risks, including unauthorized access, collection, use, disclosure, copying, modification, disposal, or similar threats. Additionally, organizations must implement measures to prevent the loss of storage media or devices containing personal data. These obligations are collectively referred to as the \"Protection Obligation\" in the guidelines",
        expectedEvidences: "Provide documentation outlining the organization's security policies and procedures for protecting personal data. This documentation should detail measures implemented to prevent unauthorized access, data breaches, and loss of storage media or devices."
    },
    {
        clauseSection: "17.2",
        sectionDescription: "This excerpt emphasizes that there is no one-size-fits-all solution for organizations to comply with the Protection Obligation. Instead, organizations should assess their unique circumstances and adopt security arrangements that are reasonable and appropriate based on several factors",
        expectedEvidences: "Organizations should evaluate the potential impact on individuals if unauthorized access, modification, or disposal of personal data were to occur. Highly confidential or sensitive information may pose greater risks to individuals if accessed by unauthorized parties, necessitating enhanced security measures."
    },
    {
        clauseSection: "17.3",
        sectionDescription: "Design and organize security arrangements to align with the nature of the personal data held by the organization and the potential harm that could result from a security breach. This involves conducting risk assessments to identify vulnerabilities and implementing appropriate safeguards to mitigate risks.",
        expectedEvidences: "Identify reliable and well-trained personnel responsible for ensuring information security within the organization. These individuals should possess the necessary expertise and skills to effectively manage and oversee security measures."
    },
    {
        clauseSection: "17.4",
        sectionDescription: "Organizations should consider who within the organization has access to the personal data. This includes employees, contractors, third-party service providers, and any other parties who may have access privileges. Understanding and controlling access to personal data is crucial for preventing unauthorized access and mitigating the risk of data breaches.",
        expectedEvidences: "Evaluate the nature of data processing activities conducted by the organization. This includes assessing the scope and complexity of data processing operations, such as data collection, storage, transmission, and disposal. Different types of data processing activities may pose varying levels of security risks, requiring tailored security measures."
    },
    {
        clauseSection: "17.5",
        sectionDescription: "Establishing policies governing access to personal data, including role-based access control and least privilege principles",
        expectedEvidences: "Implementing access control systems such as key cards, biometric scanners, or access codes to restrict physical access to areas containing personal data"
    },
    {
        clauseSection: "18.1",
        sectionDescription: "Section 25 of the Personal Data Protection Act (PDPA) mandates organizations to cease retaining documents containing personal data or remove the means by which the personal data can be associated with particular individuals when it is reasonable to assume that the purpose for which the data was collected is no longer being served, and retention is no longer necessary for legal or business purposes. This obligation is termed the \"Retention Limitation Obligation\" in the guidelines.",
        expectedEvidences: "Documentation supporting the legal or business justifications for retaining personal data beyond its initial purpose, including regulatory requirements, contractual obligations, or ongoing business needs."
    },
    {
        clauseSection: "18.2",
        sectionDescription: "The Retention Limitation Obligation, as outlined in the PDPA, serves to prevent organizations from retaining personal data indefinitely without legal or business justification. Holding personal data for an indefinite period increases the risk of violating data protection provisions. However, recognizing that each organization has unique business needs, the obligation does not prescribe a fixed duration for data retention. Instead, the duration for which an organization can legitimately retain personal data is evaluated based on reasonableness, considering the purposes for which the data was collected and other legal or business justifications for retention.",
        expectedEvidences: "Organizations should have clear legal or business justifications for retaining personal data beyond its initial purpose. These justifications may include compliance with legal obligations, contractual requirements, or ongoing business needs."
    },
    {
        clauseSection: "18.3",
        sectionDescription: "Indeed, while the PDPA doesn't mandate a specific retention period for personal data, organizations must adhere to any legal obligations or industry standards that may dictate data retention practices.",
        expectedEvidences: "Organizations must comply with any legal obligations regarding data retention imposed by other statutes or regulations. For example, certain laws may specify retention periods for financial records, healthcare information, or tax-related documents."
    },
    {
        clauseSection: "18.4",
        sectionDescription: "Personal data may be retained as long as one or more of the purposes for which it was collected remains valid. Organizations should assess whether the original purpose(s) for collecting the data are still relevant and legitimate. If the purposes remain valid, data retention may be justified.",
        expectedEvidences: "Personal data must not be retained by an organization \"just in case\" it may be needed for other purposes that have not been notified to the individual concerned. This means that organizations cannot retain personal data for undisclosed or unauthorized purposes. Data retention should be limited to the purposes that have been communicated to the individual at the time of collection or subsequently authorized by the individual."
    },
    {
        clauseSection: "18.5",
        sectionDescription: "Regular reviews of the personal data held by an organization are crucial to ensure compliance with data protection regulations, including the PDPA. These reviews serve to assess whether the personal data is still necessary for the purposes for which it was collected and whether its retention remains justified.",
        expectedEvidences: "Assess whether the personal data held by the organization is still relevant and necessary for the purposes for which it was collected. If the data is no longer needed or relevant, it should be securely disposed of or anonymized to minimize privacy risks."
    },
    {
        clauseSection: "18.6",
        sectionDescription: "Many organizations already have established policies and procedures regarding the retention of documents, which may include specific durations for retaining various types of records. However, it's essential for these policies to align with the requirements of the Retention Limitation Obligation under the PDPA",
        expectedEvidences: "If any discrepancies are identified between existing retention policies and the requirements of the PDPA, organizations should update their policies accordingly. This may involve revising retention periods, specifying criteria for determining data retention, or clarifying procedures for data disposal or anonymization."
    },
    {
        clauseSection: "18.7",
        sectionDescription: "Organizations are advised to develop or adjust relevant processes to ensure that personal data is recorded and stored in a manner that facilitates compliance with the Retention Limitation Obligation. The Commission acknowledges that organizations may have retention policies that are applied to groups or batches of personal data, recognizing the practical challenges associated with managing large volumes of data while ensuring compliance with retention requirements.",
        expectedEvidences: "Organizations should provide documentation outlining the processes developed or adjusted to ensure compliance with the Retention Limitation Obligation. This documentation may include policies, procedures, guidelines, or workflows related to data recording, storage, and retention"
    },
    {
        clauseSection: "18.8",
        sectionDescription: "As good practice, organizations are encouraged to prepare an appropriate personal data retention policy outlining their approach to retention periods for personal data. This policy should provide clear guidance on how long different types of personal data will be retained and the rationale behind these retention periods, particularly for data retained over a relatively long period. Organizations should provide a copy of their personal data retention policy, which sets out their approach to retention periods for different types of personal data. This policy should be comprehensive, clearly written, and easily accessible to relevant stakeholders within the organization.",
        expectedEvidences: "Provide a copy of the personal data retention policy."
    },
    {
        clauseSection: "18.9",
        sectionDescription: "When there is no longer a legitimate need for an organization to retain personal data, it is required to promptly take action to ensure it does not continue holding such data. This obligation is outlined in the PDPA and provides two main methods for organizations to comply: ceasing to retain documents containing personal data or removing the means by which the personal data can be associated with specific individuals, such as anonymizing the data. Provide documentation outlining the procedures and protocols for disposing of documents containing personal data when retention is no longer necessary. This may include shredding physical documents or securely deleting digital files to ensure irretrievable removal of personal data.",
        expectedEvidences: "Documentation of procedures and protocols for disposing of personal data."
    },
    {
        clauseSection: "18.10",
        sectionDescription: "An organization is considered to have ceased retaining documents containing personal data when neither the organization nor its agents and data intermediaries have access to those documents and the personal data they contain. This section outlines examples of actions that demonstrate the cessation of data retention, including returning documents to the individual concerned, transferring documents to another person as instructed by the individual, destroying documents through shredding or appropriate disposal methods, and anonymizing the personal data. If documents were destroyed or personal data was anonymized, provide confirmation of these actions. This could include receipts or certificates from destruction services, documentation of anonymization processes, or compliance reports.",
        expectedEvidences: "Confirmation of actions such as destruction receipts, anonymization processes documentation, or compliance reports."
    },
    {
        clauseSection: "18.11",
        sectionDescription: "Merely storing documents containing personal data in physical or electronic form, such as filing them in a locked cabinet, warehousing them, or transferring them to a party under the organization's control, does not constitute cessation of data retention. This section clarifies that such actions are considered retention of documents, even if access to the documents is limited. The organization is still responsible for complying with the Retention Limitation Obligation in these cases. Provide documentation demonstrating how documents containing personal data are stored, whether physically or electronically. This may include records of filing systems, storage inventories, or electronic archive structures.",
        expectedEvidences: "Documentation of how personal data documents are stored, physically or electronically."
    },
    {
        clauseSection: "18.12",
        sectionDescription: "Organizations are encouraged to cease retaining documents containing personal data in a manner that renders those documents completely irretrievable or inaccessible. However, the Commission acknowledges that there may be circumstances where personal data remains within reach of the organization or within its systems in some form, such as shredded documents in a bin or deleted personal data in an unemptied recycling bin on a computer. Demonstrate efforts made to render personal data irretrievable or inaccessible to the organization. This may include records of shredding or destruction services, confirmation of secure deletion methods, or evidence of data sanitization processes.",
        expectedEvidences: "Documentation of efforts to render personal data irretrievable or inaccessible."
    },
    {
        clauseSection: "18.13",
        sectionDescription: "In determining whether an organization has effectively ceased to retain personal data, the Commission will consider several factors related to the specific data in question. These factors include the organization's intention to use or access the data, the effort and resources required to access the data again, whether third parties have been granted access to the data, and the organization's efforts to destroy or render the data irretrievable. Provide documentation or statements indicating the organization's intentions regarding the use or access of the personal data in question. This may include internal policies, communications, or data management plans outlining the organization's stance on data retention",
        expectedEvidences: "Documentation or statements regarding the organization's intentions regarding the use or access of personal data."
    },
    {
        clauseSection: "18.14",
        sectionDescription: "An organization is deemed to have ceased retaining personal data when it no longer possesses the means to associate the personal data with specific individuals, typically achieved through the process of anonymization. Anonymization involves removing identifying information from the data, rendering it incapable of identifying any particular individual. For further guidance on anonymization processes and best practices, refer to the chapter on Anonymization in the Advisory Guidelines on the PDPA for Selected Topics. Detail the organization's anonymization processes and techniques used to remove identifying information from personal data. This may include descriptions of data masking, aggregation, or other anonymization methods employed. Provide documentation or records of anonymization activities conducted by the organization. This could include anonymization protocols, anonymized datasets, or reports detailing the anonymization process.",
        expectedEvidences: "Detailing anonymization processes and techniques, along with documentation of anonymization activities."
    },
    {
        clauseSection: "19.1",
        sectionDescription: "Section 26 of the PDPA imposes restrictions on organizations regarding the transfer of personal data to entities outside Singapore when they relinquish possession or direct control over the data. This includes scenarios such as transferring data to another company within the same group for centralized corporate functions or to a data intermediary for processing. However, organizations must comply with all Data Protection Provisions when personal data transferred overseas remains under their possession or control. Maintain records documenting instances of transferring personal data to entities outside Singapore. This includes agreements, contracts, or other documentation detailing the purpose and scope of data transfers. Provide evidence of agreements with data intermediaries or entities within the same group regarding the processing or transfer of personal data. Ensure that these agreements comply with the requirements of the PDPA and outline the responsibilities of each party regarding data protection.",
        expectedEvidences: "Records of transferring personal data, agreements with data intermediaries, and evidence of compliance with PDPA requirements."
    },
    {
        clauseSection: "19.2",
        sectionDescription: "Section 26 of the PDPA imposes limitations on organizations regarding the transfer of personal data to entities located outside Singapore. These limitations apply when an organization relinquishes possession or direct control over the personal data, such as when transferring data to another company within the same group for centralized corporate functions or to a data intermediary for processing. Provide records or documentation detailing instances of transferring personal data to entities outside Singapore. This could include contracts, agreements, or communication exchanges related to data transfers. Identify any overseas data repositories where personal data is stored or processed. This may involve conducting assessments or audits to determine the locations of data processing activities.",
        expectedEvidences: "Records or documentation of transferring personal data and identification of overseas data repositories."
    },
    {
        clauseSection: "19.3",
        sectionDescription: "Section 26(1) of the PDPA prohibits organizations from transferring personal data to countries or territories outside Singapore unless they comply with the requirements prescribed under the PDPA. This ensures that organizations maintain a standard of protection for transferred personal data that is comparable to the protection provided under the PDPA. This requirement is referred to as the Transfer Limitation Obligation in these guidelines. Documentation of Data Transfer Compliance: Provide documentation demonstrating compliance with the Transfer Limitation Obligation when transferring personal data outside Singapore. This may include records of assessments, agreements, or policies related to international data transfers. Data Protection Measures for Transferred Data: Outline the measures implemented to ensure that transferred personal data receives a standard of protection comparable to that provided under the PDPA. This could involve encryption, contractual obligations, or data transfer impact assessments.",
        expectedEvidences: "Documentation of compliance with the Transfer Limitation Obligation, and outline of data protection measures for transferred data."
    },
    {
        clauseSection: "19.4",
        sectionDescription: "The Personal Data Protection Regulations 2021 outline the conditions under which an organization may transfer personal data overseas. Essentially, an organization is permitted to transfer personal data overseas if it has implemented appropriate measures to ensure that the overseas recipient is obligated by legally enforceable agreements or certifications to provide the transferred personal data with a level of protection equivalent to that under the PDPA. Provide evidence demonstrating compliance with the Personal Data Protection Regulations 2021 regarding overseas data transfers. This may include references to specific sections of the regulations, summaries of key requirements, or documentation outlining the organization's approach to compliance. Detail the measures taken to ensure that overseas recipients are bound by legally enforceable obligations or certifications to protect transferred personal data. This could involve contractual agreements, certifications, or other mechanisms to establish binding commitments to data protection standards.",
        expectedEvidences: "Evidence of compliance with Personal Data Protection Regulations 2021, and detail of measures taken to ensure overseas recipients' compliance."
    },
    {
        clauseSection: "19.5",
        sectionDescription: "\"Contracts that specify a standard of protection comparable to that under the PDPA and identify the countries and territories to which the personal data may be transferred;\nBinding corporate rules (BCRs) that mandate all recipients of transferred personal data to adhere to a standard of protection comparable to that under the PDPA. BCRs must specify the recipients covered, the countries and territories for data transfer, and the rights and obligations provided by the BCRs\"",
        expectedEvidences: "\"Documentation of Legal Compliance: Provide evidence demonstrating compliance with relevant laws governing international data transfers. This may include references to specific legal provisions, summaries of legal requirements, or legal opinions confirming compliance.\nContractual Agreements: Present contracts or agreements that impose obligations on the recipient organization to protect transferred personal data to a standard comparable to that under the PDPA. These contracts should specify the countries and territories to which the data may be transferred.\""
    },
    {
        clauseSection: "19.6",
        sectionDescription: "Under the Personal Data Protection Regulations 2021, recipient organizations may be bound by legally enforceable obligations if they hold a \"specified certification\" recognized under the law of the country or territory to which the personal data is transferred. \"Specified certification\" includes certifications under the Asia Pacific Economic Cooperation Cross Border Privacy Rules (APEC CBPR) System and the Asia Pacific Economic Cooperation Privacy Recognition for Processors (APEC PRP) System.",
        expectedEvidences: "\"Certification Documentation: Provide documentation demonstrating that the recipient organization holds certifications under the APEC CBPR or APEC PRP Systems. This may include certificates, official notifications, or confirmation letters from certifying bodies.\nVerification of Certification Validity: Verify the validity and recognition of the certifications under the laws of the respective countries or territories to which the personal data is transferred. This may involve obtaining official recognition statements or legal opinions confirming the recognition of the certifications.\""
    },
    {
        clauseSection: "19.7",
        sectionDescription: "Organizations are encouraged to prioritize the use of legally enforceable obligations or specified certifications, as outlined in paragraphs 19.5 and 19.6, particularly when they maintain an ongoing relationship with the recipient organization.",
        expectedEvidences: "\"Documentation of Reliance on Legally Enforceable Obligations or Certifications: Provide evidence demonstrating the organization's reliance on legally enforceable obligations or specified certifications for international data transfers. This may include copies of contracts, agreements, or certifications established with recipient organizations.\nExplanation of Ongoing Relationships: Explain the nature of ongoing relationships with recipient organizations and how these relationships contribute to the reliance on legally enforceable obligations or specified certifications.\""
    },
    {
        clauseSection: "19.8",
        sectionDescription: "An organization based in Singapore engages a cloud service provider located overseas to process personal data on its behalf.",
        expectedEvidences: "An organization transfers personal data overseas to a recipient organization in a country outside Singapore."
    },
    {
        clauseSection: "19.9",
        sectionDescription: "Clauses to ensure compliance with data protection standards comparable to those under the PDPA, organizations typically include provisions that outline specific obligations and requirements for the recipient of the personal data. These clauses aim to safeguard the transferred data and maintain its confidentiality, integrity, and security.",
        expectedEvidences: "\"Data Protection Commitments: The contract should include explicit commitments from the recipient to comply with applicable data protection laws and regulations, including requirements equivalent to those under the PDPA.\nPurpose Limitation: The recipient should agree to use the personal data only for the purposes specified by the transferring organization and not for any other purposes without prior consent.\""
    },
    {
        clauseSection: "19.10",
        sectionDescription: "Data Protection Provisions under the PDPA are not directly imposed on data intermediaries when they process personal data on behalf of another organization under a written contract.",
        expectedEvidences: "Not applicable"
    },
    {
        clauseSection: "19.11",
        sectionDescription: "Data in transit refers to personal data that is being transferred through Singapore to a destination outside of Singapore, without being accessed, used, or disclosed by any organization other than the transferring organization or its employees acting within the scope of their employment.",
        expectedEvidences: "Not applicable"
    },
    {
        clauseSection: "20.1",
        sectionDescription: "PDPA outlines the obligations for organizations to assess and determine if a data breach is notifiable, and subsequently notify the affected individuals and/or the Commission if it meets the criteria for notification.",
        expectedEvidences: "Not applicable"
    },
    {
        clauseSection: "20.2",
        sectionDescription: "Once an organization becomes aware of credible grounds to believe that a data breach has occurred, whether through self-discovery, alert from the public, or notification by its data intermediary, the organization is mandated to undertake a prompt and thorough assessment to determine if the breach is notifiable under the Personal Data Protection Act (PDPA).",
        expectedEvidences: "\"Documentation detailing the process followed by the organization to assess the suspected data breach.\nRecords indicating the source of information about the potential breach (e.g., internal audit, reports from employees, alerts from the public, notifications from data intermediaries).\nReports or logs documenting the timeline of events leading to the discovery or notification of the suspected breach.\""
    },
    {
        clauseSection: "20.3",
        sectionDescription: "Assessments of potential data breaches should be conducted promptly and efficiently due to the increased risk of significant harm to affected individuals over time. Delays in assessing a data breach can exacerbate the impact on affected individuals and may constitute a breach of the Data Breach Notification (DBN) Obligation under the Personal Data Protection Act (PDPA).",
        expectedEvidences: "\"Documentation outlining the organization's procedures and timelines for conducting assessments of potential data breaches.\nRecords demonstrating the prompt initiation of assessment procedures upon awareness of a suspected breach.\""
    },
    {
        clauseSection: "20.4",
        sectionDescription: "Organizations are generally expected to establish the facts of a data breach and determine its notifiability within 30 calendar days from the time the breach was discovered or reported. While there may be varying circumstances that could affect the duration of this assessment period, adherence to the 30-day timeframe is encouraged to ensure timely notification and mitigation of risks to affected individuals",
        expectedEvidences: "\"Documentation outlining the organization's internal policies or guidelines regarding the timeframe for assessing potential data breaches.\nRecords indicating the date when the suspected breach was discovered or reported.\nLogs or reports tracking the progress of the assessment, including key milestones and dates of completion for each stage.\""
    },
    {
        clauseSection: "20.5",
        sectionDescription: "In order to demonstrate compliance with the obligation to promptly assess whether a data breach is notifiable under the Personal Data Protection Act (PDPA), organizations are required to document all steps taken during the assessment process. This documentation serves as evidence of the organization's reasonable and expeditious efforts to evaluate the breach and determine its notifiability.",
        expectedEvidences: "\"Comprehensive documentation detailing each step undertaken during the assessment of the data breach.\nRecords indicating the initiation of the assessment process, including the date and time when the breach was discovered or reported.\nLogs or reports outlining the procedures followed and methodologies employed to investigate the breach and gather relevant information.\""
    },
    {
        clauseSection: "20.6",
        sectionDescription: "A data breach that involves the unauthorized access, collection, use, disclosure, copying, or modification of personal data within an organization is not considered a notifiable data breach under the Personal Data Protection Act (PDPA).",
        expectedEvidences: "\"Documentation outlining the specific incident or breach that occurred within the organization.\nRecords detailing the nature and scope of the breach, including how it occurred and the type of personal data involved.\nEvidence demonstrating that the breach occurred internally within the organization's systems or infrastructure.\""
    },
    {
        clauseSection: "20.7",
        sectionDescription: "Data intermediary tasked with processing personal data on behalf and for the purposes of another organization or public agency, the data intermediary must promptly inform the respective organization or public agency without undue delay once it has credible grounds to believe that a data breach has occurred.",
        expectedEvidences: "Organizations can assess whether the data breach meets the criteria for notification to affected individuals or regulatory authorities, such as determining if it constitutes a notifiable data breach under applicable laws and regulations."
    },
    {
        clauseSection: "20.8",
        sectionDescription: "The Data Breach Notification (DBN) Obligation does not place the responsibility on the data intermediary to evaluate whether a data breach is notifiable or to notify affected individuals and/or regulatory authorities such as the Commission.",
        expectedEvidences: "The organization that contracted the data intermediary retains this responsibility, even if it delegates the task of assessing the data breach or notifying affected parties and/or regulatory authorities to the data intermediary."
    },
    {
        clauseSection: "20.9",
        sectionDescription: "Establishing clear procedures for compliance with the Data Breach Notification (DBN) Obligation is considered a good practice for organizations when they enter into service agreements or contractual arrangements with their data intermediaries. These agreements should encompass various factors related to data processing.",
        expectedEvidences: "\"Volume and Types of Personal Data: Understanding the quantity and categories of personal data involved in the processing activities.\nType and Extent of Data Processing: Identifying the nature and scope of data processing activities performed by the data intermediary.\nPotential Risks: Assessing potential risks associated with data processing, such as security breaches or unauthorized access.\""
    },
    {
        clauseSection: "20.10",
        sectionDescription: "In cases where a data breach affects personal data held by multiple organizations, each organization involved is individually responsible for adhering to the Data Breach Notification (DBN) Obligation regarding that specific breach.",
        expectedEvidences: "This means that each organization must independently assess whether the breach is notifiable and take appropriate steps to notify affected individuals and/or the relevant authorities, as required by data protection regulations."
    },
    {
        clauseSection: "20.11",
        sectionDescription: "In situations where a data breach involves multiple organizations, they may opt to designate one organization to lead the assessment process to determine whether the breach is notifiable. Each organization remains responsible for drawing its own conclusions from the assessment and must document and record the agreements, breach assessments, and decisions accordingly.",
        expectedEvidences: "\"Agreements Documentation: Provide written agreements or correspondence between the involved organizations outlining which organization will lead the assessment process.\nBreach Assessment Records: Maintain records detailing how the assessment of the breach was conducted, including methodologies used, data analyzed, and findings.\""
    },
    {
        clauseSection: "20.12",
        sectionDescription: "In cases where a data breach is deemed notifiable to the Commission, each organization involved must individually notify the Commission. For administrative ease, organizations may utilize the same relevant information when submitting their individual notifications. If the data breach is also notifiable to affected individuals, the Commission may offer additional guidance to ensure that notifications to affected individuals are managed in a coordinated manner.",
        expectedEvidences: "\"Individual Notifications: Provide documentation of each organization's notification to the Commission, including copies of notification letters or forms submitted.\nInformation Sharing: Demonstrate the sharing of relevant information among the involved organizations to facilitate consistent reporting to the Commission.\""
    },
    {
        clauseSection: "20.13",
        sectionDescription: "Organizations must assess whether a data breach is notifiable if it is expected to result in significant harm to the affected individuals. This assessment is crucial due to the potential harm that can arise from a data breach. Notification of such breaches ensures that affected individuals are informed and can take necessary steps to protect themselves, such as changing passwords, canceling credit cards, or monitoring their accounts for unusual activities.",
        expectedEvidences: "\"Risk Assessment: Document the organization's assessment of the data breach to determine the likelihood of significant harm to affected individuals.\nCriteria Evaluation: Outline the criteria used by the organization to assess whether the breach meets the threshold for notification based on potential harm.\nNotification Decision: Provide records or documentation detailing the organization's decision on whether to notify affected individuals based on the assessment of harm.\""
    },
    {
        clauseSection: "20.14",
        sectionDescription: "The Personal Data Protection (Notification of Data Breaches) Regulations 2021 establish clear criteria for determining which data breaches are considered notifiable. These regulations identify specific types of personal data (or classes of personal data) that are deemed to result in significant harm to affected individuals if compromised in a data breach. If a data breach involves any of the prescribed personal data outlined in the regulations, the organization is obligated to notify both the affected individuals and the Commission of the data breach.",
        expectedEvidences: "\"Familiarity with Regulations: Demonstrate awareness and understanding of the Personal Data Protection (Notification of Data Breaches) Regulations 2021.\nData Classification Framework: Implement a system for classifying personal data to identify the types or classes specified in the regulations.\nData Breach Response Plan: Develop and maintain a comprehensive plan for responding to data breaches, including procedures for assessing whether a breach is notifiable.\""
    },
    {
        clauseSection: "20.15",
        sectionDescription: "Personal data that are deemed to result in significant harm to affected individuals if compromised in a data breach. These categories encompass various financial and identifying information, such as an individual's full name or alias, full national identification number, income, credit card numbers, bank account details, and transaction history. If a data breach involves any of these prescribed categories of personal data, the organization is required to notify both the affected individuals and the Commission of the breach.",
        expectedEvidences: "\"Data Classification Framework: Implement a system for classifying personal data to identify the categories or classes specified in the regulations.\nData Breach Response Plan: Ensure that the organization's response plan addresses breaches involving the prescribed personal data and includes procedures for notifying affected individuals and the Commission.\nCompliance Documentation: Maintain records documenting the inclusion of prescribed personal data categories in the organization's data breach response procedures.\""
    },
    {
        clauseSection: "20.16",
        sectionDescription: "The Personal Data Protection (Notification of Data Breaches) Regulations 2021 specify exclusions regarding certain categories of personal data or circumstances from the requirement of notification in the event of a data breach. Specifically, personal data that is publicly available and personal data disclosed under any written law are excluded from the prescribed categories that trigger notification obligations. For example, pay information issued by employers under the Employment Act falls under the exclusion.",
        expectedEvidences: "\"Documentation of Excluded Personal Data: Maintain records documenting the exclusion of certain categories of personal data from the notification requirements.\nData Classification Framework: Implement a system for identifying and classifying personal data to differentiate between data subject to notification obligations and data excluded under the regulations.\nCompliance Documentation: Keep records demonstrating compliance with the exclusion criteria, including any assessments or determinations made regarding the applicability of the exclusion to specific categories of personal data.\""
    },
    {
        clauseSection: "20.17",
        sectionDescription: "When different categories of personal data are compromised or lost at different times, the affected organization must assess whether these separate incidents are likely to be linked. If the organization determines that there is a connection between the data breaches, such as involving the same perpetrator or similar circumstances, it must notify the Commission and/or affected individuals accordingly.",
        expectedEvidences: "\"Incident Reports: Maintain documentation of each data breach incident, including the nature of the breach, the types of personal data compromised, and the timeline of events.\nLinkage Assessment: Conduct an assessment to determine whether the separate data breach incidents are likely to be linked based on common factors such as timing, modus operandi, or perpetrator involvement.\nDecision-Making Records: Document the organization's decision-making process regarding whether to notify the Commission and/or affected individuals based on the assessment of linkage between data breaches.\""
    },
    {
        clauseSection: "20.18",
        sectionDescription: "In cases where separate data breaches occur at different times but are later found to be linked due to the discovery of a combined database containing both sets of compromised personal data, the affected organization must take appropriate action. If the organization becomes aware that a third party, whether implicated in the original data breaches or not, has combined the separate sets of compromised personal data and disclosed or used the combined dataset, it must notify the Commission and/or affected individuals accordingly.",
        expectedEvidences: "\"Discovery Documentation: Maintain records documenting the discovery of the combined database containing both sets of compromised personal data.\nThird-Party Involvement Assessment: Assess whether a third party, whether implicated in the original data breaches or not, has combined the separate sets of compromised personal data.\nNotification Decision Records: Document the organization's decision-making process regarding whether to notify the Commission and/or affected individuals based on the discovery of the combined dataset and the involvement of third parties.\""
    },
    {
        clauseSection: "20.19",
        sectionDescription: "Data breaches of a significant scale may signal systemic issues within the organization, necessitating notification to the Commission. Doing so enables the Commission to offer guidance to organizations regarding remedial actions to address the data breach and implement systemic changes to prevent future occurrences. By notifying the Commission of large-scale data breaches, organizations demonstrate transparency and commitment to resolving underlying issues and improving data protection practices.",
        expectedEvidences: "\"Documentation of Data Breach Scale: Maintain records detailing the scale and severity of the data breach, including the number of affected individuals, types of compromised personal data, and extent of unauthorized access or disclosure.\nAssessment of Systemic Issues: Conduct an assessment to identify any systemic issues within the organization that may have contributed to the data breach, such as inadequate security measures, flawed data handling processes, or insufficient staff training.\""
    },
    {
        clauseSection: "20.20",
        sectionDescription: "Data breaches meeting the significant scale criteria involve the personal data of 500 or more individuals. Organizations experiencing such breaches are obligated to notify the Commission, irrespective of whether the breach involves prescribed personal data. This notification requirement ensures transparency and enables regulatory intervention to mitigate the impact of large-scale data breaches.",
        expectedEvidences: "\"Data Breach Assessment: Conduct an assessment to determine the scale of the data breach, including the number of affected individuals and types of compromised personal data.\nCalculation of Affected Individuals: Document the process used to calculate the number of affected individuals, considering factors such as unique records compromised.\nNotification to Commission: Provide evidence of notifying the Commission about the data breach, including copies of notification letters, emails, or other correspondence\""
    },
    {
        clauseSection: "20.21",
        sectionDescription: "In cases where an organization cannot ascertain the precise number of affected individuals in a data breach, it should notify the Commission when there is a reasonable belief that the number exceeds 500. This notification should be based on an estimated count derived from an initial assessment of the breach. Subsequently, the organization should provide updates to the Commission as soon as the actual number of affected individuals is determined.",
        expectedEvidences: "\"Preliminary Assessment: Document the preliminary assessment conducted to estimate the number of affected individuals in the data breach.\nNotification to Commission: Provide evidence of notifying the Commission based on the estimated number of affected individuals exceeding 500.\nReasonable Belief Justification: Explain the rationale behind the organization's belief that the number of affected individuals surpasses 500, supported by relevant data and analysis.\""
    },
    {
        clauseSection: "20.22",
        sectionDescription: "Notification Requirements for Data Breaches",
        expectedEvidences: "\"Documentation demonstrating notification sent to the Commission within three (3) calendar days of determining a notifiable data breach.\""
    },
    {
        clauseSection: "20.23",
        sectionDescription: "Commencement of Notification Timeframes",
        expectedEvidences: "Documentation indicating the determination of a notifiable data breach and the initiation of notification timeframes, with measures in place to prevent unreasonable delays in notifying the Commission and/or affected individuals."
    },
    {
        clauseSection: "20.24",
        sectionDescription: "Clarity in Notification Timeframe",
        expectedEvidences: "Documentation highlighting the prescribed cap of three (3) calendar days for notification to the Commission, ensuring clarity for organizations regarding the definitive deadline for notification."
    },
    {
        clauseSection: "20.25",
        sectionDescription: "Notification Sequence for Affected Individuals",
        expectedEvidences: "Documentation demonstrating that affected individuals were notified either simultaneously with or after notifying the Commission about the data breach, in accordance with the prescribed procedure."
    },
    {
        clauseSection: "20.26",
        sectionDescription: "Exceptions to Notification Requirement for Affected Individuals",
        expectedEvidences: "Documentation detailing the circumstances specified in Section 26D of the PDPA where exceptions to the requirement to notify affected individuals of a notifiable data breach are applicable."
    },
    {
        clauseSection: "20.27",
        sectionDescription: "Exception Handling for Data Breaches with Significant Harm",
        expectedEvidences: "\"Documentation outlining the procedure for handling data breaches where exceptions apply, specifying that affected individuals need not be notified initially but the Commission must be informed. Additionally, documentation indicating the requirement to notify affected individuals if the Commission determines that the exception does not apply.\""
    },
    {
        clauseSection: "20.28",
        sectionDescription: "Remedial Action Exception for Data Breaches",
        expectedEvidences: "Documentation outlining the conditions under which the remedial action exception can be invoked, specifically indicating that timely remedial actions have been taken by the organization or its data intermediary, in accordance with any prescribed requirements, to mitigate the likelihood of significant harm to the affected individual resulting from the data breach."
    },
    {
        clauseSection: "20.29",
        sectionDescription: "Timing of Remedial Actions for Data Breaches",
        expectedEvidences: "Documentation stating that remedial actions can be taken either before or after notifying the Commission, including the possibility of implementing further remedial actions after receiving guidance from the Commission."
    },
    {
        clauseSection: "20.3O",
        sectionDescription: "Exception for Technological Protection",
        expectedEvidences: "Documentation specifying that appropriate technological measures (such as encryption, password-protection, etc.) were applied to the personal data before the data breach, rendering the personal data inaccessible or unintelligible to an unauthorized party"
    },
    {
        clauseSection: "20.31",
        sectionDescription: "Assessment Criteria for Technological Protection Exception",
        expectedEvidences: "Documentation outlining the assessment criteria for determining the sufficiency of technological protection measures, including considerations of whether the measures are of a commercially reasonable standard and align with prevailing industry practices in the sector."
    },
    {
        clauseSection: "20.32",
        sectionDescription: "Prohibition on Notifying Affected Individuals as per Law Enforcement or Commission Directives",
        expectedEvidences: "\"Documentation demonstrating compliance with instructions or directives from prescribed law enforcement agencies or the Commission regarding the prohibition of notifying affected individuals in situations where notification may compromise ongoing investigations or enforcement efforts under the law.\""
    },
    {
        clauseSection: "20.33",
        sectionDescription: "Commission Waiver for Notification Requirement",
        expectedEvidences: "Documentation demonstrating a written application from the organization to the Commission requesting a waiver of the requirement to notify affected individuals in exceptional circumstances."
    },
    {
        clauseSection: "20.34",
        sectionDescription: "Commission Consideration for Waiver Decision",
        expectedEvidences: "Documentation demonstrating that the Commission considers advice from relevant law enforcement or public agencies when deciding whether to grant a waiver for notification requirements."
    },
    {
        clauseSection: "20.35",
        sectionDescription: "Mode of Notification for Data Breach",
        expectedEvidences: "Documentation outlining the organization's obligation to notify affected individuals of a data breach and ensure that the mode of notification is appropriate and effective for timely communication."
    },
    {
        clauseSection: "20.36",
        sectionDescription: "Determining Mode of Notification for Data Breach",
        expectedEvidences: "Documentation specifying that in cases where there is no regular mode of communication with affected individuals, the organization is responsible for determining the most appropriate mode of notification"
    },
    {
        clauseSection: "20.37",
        sectionDescription: "Details Required in Data Breach Notification",
        expectedEvidences: "Documentation stipulating that when notifying affected individuals and/or the Commission of a notifiable data breach, the organization must provide relevant details of the breach to the best of its knowledge and belief."
    },
    {
        clauseSection: "20.38",
        sectionDescription: "Information Required in Organization's Notification to the Commission for Data Breach Management and Remediation",
        expectedEvidences: "Documentation outlining the specific information that must be provided in the organization's notification to the Commission to ensure proactive steps are taken for managing and remediating the data breach."
    },
    {
        clauseSection: "20.39",
        sectionDescription: "Late Notification to the Commission",
        expectedEvidences: "Documentation specifying that if the data breach notification to the Commission is not made within three (3) calendar days of ascertaining that it is a notifiable breach, the organization must provide reasons for the delay along with supporting evidence."
    },
    {
        clauseSection: "20.4O",
        sectionDescription: "Notification to Commission for Non-Notification to Affected Individuals",
        expectedEvidences: "\"Documentation indicating that if the organization does not intend to notify any affected individual, the notification to the Commission must include specific grounds, citing provisions under the Personal Data Protection Act (PDPA) or other relevant written laws, for not notifying the affected individuals.\""
    },
    {
        clauseSection: "20.41",
        sectionDescription: "Application for Waiver Submission Process",
        expectedEvidences: "Documentation indicating that any application to the Commission to waive the requirement to notify an affected individual under section 26D(7) of the PDPA can be submitted together with the notification to the Commission"
    },
    {
        clauseSection: "20.42",
        sectionDescription: "Clarity and Guidance in Notification to Affected Individuals",
        expectedEvidences: "Documentation outlining the requirement for notifications to affected individuals to be clear and easily understood, providing guidance on steps individuals can take to protect themselves from potential harm resulting from the data breach."
    },
    {
        clauseSection: "20.43",
        sectionDescription: "Notification Protocol for Data Breaches Involving Sensitive Information",
        expectedEvidences: "\"Documentation stipulating that in cases where the data breach involves information related to adoption matters or the identification of vulnerable individuals, organizations are required to first notify the Commission for guidance on notifying affected individuals.\""
    },
    {
        clauseSection: "20.44",
        sectionDescription: "Information Included in Notifications to Affected Individuals",
        expectedEvidences: "Documentation confirming that organizations are not obligated to provide the notification intended for affected individuals to the Commission."
    },
    {
        clauseSection: "20.45",
        sectionDescription: "Customization of Notifications to Affected Individuals",
        expectedEvidences: "Documentation affirming that organizations have the flexibility to customize their notifications to affected individuals, provided that the required content is included. Additionally, the documentation should clarify that decisions on appropriate actions for individuals to take depend on the circumstances of the data breach"
    },
    {
        clauseSection: "20.46",
        sectionDescription: "Fulfillment of Data Breach Notification Requirements under Other Written Laws",
        expectedEvidences: "Documentation stating that if an organization is obligated to notify a sectoral regulator or law enforcement agency of a data breach under other written laws, it must do so accordingly. Additionally, the documentation should emphasize that notification to the Commission and affected individuals (if required) must adhere to the timeframes specified under the PDPA"
    },
    {
        clauseSection: "21.1",
        sectionDescription: "Concept of Accountability in Data Protection",
        expectedEvidences: "Documentation defining accountability in the context of data protection as the organization's responsibility for personal data in its possession or under its control, including determining purposes for data collection, use, or disclosure, as well as the manner and means of data processing."
    },
    {
        clauseSection: "21.2",
        sectionDescription: "Measures for Accountability under the PDPA",
        expectedEvidences: "Documentation outlining the measures organizations must undertake to ensure compliance with their obligations under the PDPA and to demonstrate their ability to do so when required. This includes designating individuals responsible for compliance, developing and implementing data protection policies and practices, and making information about these policies and practices available."
    },
    {
        clauseSection: "21.3",
        sectionDescription: "Designation of Data Protection Officer (DPO) under the PDPA",
        expectedEvidences: "Documentation explaining the requirements outlined in Section 11(3) of the PDPA, mandating organizations to designate one or more individuals, typically referred to as a Data Protection Officer (DPO), responsible for ensuring compliance with the PDPA. Furthermore, Section 11(4) clarifies that the designated individual may delegate responsibilities to others but doesn't absolve the organization of its obligations"
    },
    {
        clauseSection: "21.4",
        sectionDescription: "Role and Responsibilities of the Data Protection Officer (DPO)",
        expectedEvidences: "Documentation outlining the pivotal role of the Data Protection Officer (DPO) in ensuring the organization's compliance with the PDPA. This should include responsibilities such as collaborating with senior management and business units to develop and implement data protection policies and practices, conducting activities like personal data inventory management, data protection impact assessments, risk monitoring and reporting, internal training on compliance, stakeholder engagement, and serving as the primary internal expert on data protection matters."
    },
    {
        clauseSection: "21.5",
        sectionDescription: "Qualifications and Position of Designated Data Protection Officers (DPOs)",
        expectedEvidences: "Documentation stating that individuals designated as Data Protection Officers (DPOs) under section 11(3) should possess sufficient skills and knowledge and be adequately empowered to fulfill their duties, even if they are not employees of the organization. Furthermore, organizations are urged to ensure that appointed DPOs undergo training and certification."
    },
    {
        clauseSection: "21.6",
        sectionDescription: "Primary Contact Point for Data Protection Matters",
        expectedEvidences: "Documentation affirming that the Data Protection Officer (DPO) or someone working closely with them may serve as the primary contact point for the organization's data protection matters. Section 11(5) of the PDPA mandates organizations to provide the business contact information of at least one designated individual under section 11(3)."
    },
    {
        clauseSection: "21.7",
        sectionDescription: "Accessibility of Business Contact Information for Data Protection Matters",
        expectedEvidences: "Documentation stipulating that the business contact information of the relevant person, designated under section 11(3) of the PDPA, should be provided on BizFile+ for companies registered with ACRA or made readily accessible on the organization's official website."
    },
    {
        clauseSection: "21.8",
        sectionDescription: "Key Requirements of Section 12 PDPA - Accountability Obligation",
        expectedEvidences: "Documentation outlining the four additional key requirements specified in Section 12 of the Personal Data Protection Act (PDPA), which contribute to fulfilling the Accountability Obligation"
    },
    {
        clauseSection: "21.9",
        sectionDescription: "Development and Implementation of Data Protection Policies and Practices",
        expectedEvidences: "Documentation highlighting the requirement for organizations to develop and implement data protection policies and practices to fulfill their obligations under the PDPA. These policies and practices can be internal or external facing and may include establishing governance structures and designing processes to operationalize policies."
    },
    {
        clauseSection: "21.1O",
        sectionDescription: "Process for Receiving and Responding to Complaints",
        expectedEvidences: "Documentation emphasizing the requirement for organizations to develop a process to receive and respond to complaints related to the application of the PDPA."
    },
    {
        clauseSection: "21.11",
        sectionDescription: "Staff Training and Communication on Data Protection Policies and Practices",
        expectedEvidences: "Documentation highlighting the requirement for organizations to provide staff training and communicate information about their data protection policies and practices to their employees."
    },
    {
        clauseSection: "21.12",
        sectionDescription: "Availability of Information on Data Protection Policies, Practices, and Complaint Process",
        expectedEvidences: "Documentation underscoring the requirement for organizations to make information available upon request regarding their data protection policies, practices, and complaint process."
    },
    {
        clauseSection: "21.13",
        sectionDescription: "Importance of Personal Data Protection Policies and Practices",
        expectedEvidences: "Documentation highlighting the significance of an organization's personal data protection policies and practices in setting the tone for its treatment of personal data and providing clarity on the management of personal data protection risks."
    },
    {
        clauseSection: "21.14",
        sectionDescription: "Accountability to Individuals and the Commission under Data Protection Provisions",
        expectedEvidences: "Documentation indicating that the Data Protection Provisions outline specific circumstances where organizations are required to be accountable to individuals and the Commission, ensuring that they are prepared to address these parties in an accountable manner."
    },
    {
        clauseSection: "21.15",
        sectionDescription: "Demonstrating Organizational Accountability through Additional Measures",
        expectedEvidences: "Documentation suggesting that organizations may choose to demonstrate organizational accountability by adopting measures such as conducting Data Protection Impact Assessments (DPIA) in appropriate circumstances, implementing a Data Protection by Design (DPbD) approach, or establishing a Data Protection Management Programme (DPMP)"
    },
    {
        clauseSection: "22.1",
        sectionDescription: "Individual Accountability for Offences under Part 9B of the PDPA",
        expectedEvidences: "Documentation explaining that Part 9B of the PDPA holds individuals accountable for egregious mishandling of personal data within organizations, including public agencies."
    },
    {
        clauseSection: "23.1",
        sectionDescription: "Organizational Accountability and Individual Offences under Part 9B of the PDPA",
        expectedEvidences: "Documentation reaffirming that while Part 9B of the PDPA establishes offences for egregious mishandling of personal data by individuals, organizations remain primarily accountable for data protection."
    },
    {
        clauseSection: "23.2",
        sectionDescription: "Scope of Offences Regarding Authorized Data Disclosure, Use, or Re-identification",
        expectedEvidences: "Documentation clarifying that the offences under Part 9B of the PDPA are not intended to cover instances where individuals are authorized to disclose, use, or re-identify data. The evidence should specify that authorization can be found in an organization's written policies, manuals, or handbooks, or provided ad-hoc for specific actions or activities, either verbally or in writing."
    },
    {
        clauseSection: "23.3",
        sectionDescription: "Exception for Information Publicly Available Due to Contravention",
        expectedEvidences: "Documentation outlining an exception where individuals accused of offences under Part 9B of the PDPA are not liable if the information in question was publicly available solely due to an applicable contravention, and the accused neither knew nor was reckless as to whether this was the case."
    },
    {
        clauseSection: "23.4",
        sectionDescription: "Defenses Provided in Part 4A of the Personal Data Protection Regulations",
        expectedEvidences: "Documentation explaining that Part 4A of the Personal Data Protection Regulations 2021 provides defenses for the offences related to the knowing or reckless unauthorized disclosure and use of personal data. These defenses apply when consent has been provided by the individual to whom the personal data relates."
    },
    {
        clauseSection: "23.5",
        sectionDescription: "Additional Defenses for Offence of Unauthorised Re-identification of Anonymised Information",
        expectedEvidences: "Documentation specifying additional defenses provided under paragraph 22.1(c) for knowing or reckless unauthorized re-identification of anonymized information. These defenses apply in circumstances such as testing the effectiveness of anonymization of personal data or testing the integrity and confidentiality of anonymized information."
    },
    {
        clauseSection: "23.6",
        sectionDescription: "Applicability of Additional Defenses to Data Professionals",
        expectedEvidences: "Documentation indicating that the additional defenses provided may be applicable to data professionals such as cybersecurity specialists, data scientists, AI engineers, and statisticians in the information security and encryption industry."
    },
    {
        clauseSection: "24.1",
        sectionDescription: "Implementation Date and Pre-existing Data Handling Context",
        expectedEvidences: "Documentation stating that the Data Protection Provisions came into operation on July 2, 2014, as specified by the Minister, referred to as the \"appointed day.\" Before this date, organizations might have collected, used, and disclosed personal data, and there may be existing contracts involving personal data."
    },
    {
        clauseSection: "24.2",
        sectionDescription: "Application of Data Protection Provisions to Existing Rights and Obligations",
        expectedEvidences: "Documentation explaining that since the implementation of the Data Protection Provisions, organizations are obligated to comply with these provisions, which have impacted some existing rights, obligations, and legal relationships."
    },
    {
        clauseSection: "24.3",
        sectionDescription: "Detailed Consideration of Impacts on Existing Rights and Obligations",
        expectedEvidences: "Documentation indicating that each aspect mentioned above, including compliance with the Data Protection Provisions and the impact on existing rights, obligations, and legal relationships, is further elaborated and examined in subsequent sections of the provided documentation."
    },
    {
        clauseSection: "25.1",
        sectionDescription: "Preservation of Legal Rights and Obligations under the PDPA",
        expectedEvidences: "Documentation referencing Section 4(6)(a) of the PDPA, which stipulates that the Data Protection Provisions do not affect any authority, right, privilege, immunity, obligation, or limitation conferred by or under the law."
    },
    {
        clauseSection: "25.2",
        sectionDescription: "Exclusion of Contractual Performance from PDPA Exemptions",
        expectedEvidences: "Documentation clarifying that while Section 4(6)(a) of the PDPA preserves rights and obligations under the law, it does not apply to contractual rights and obligations. Performance of contractual obligations cannot exempt organizations from complying with the PDPA."
    },
    {
        clauseSection: "25.3",
        sectionDescription: "Primacy of Other Written Laws over PDPA",
        expectedEvidences: "Documentation referring to Section 4(6)(b) of the PDPA, which states that provisions of other written laws take precedence over the Data Protection Provisions if any inconsistency arises. \"Other written law\" encompasses the Constitution of Singapore, Acts of Parliament, and subsidiary legislation like regulations."
    },
    {
        clauseSection: "25.4",
        sectionDescription: "Resolution of Inconsistencies between PDPA and Other Written Laws",
        expectedEvidences: "Documentation explaining that according to Section 4(6)(b) of the PDPA, if a provision in the PDPA conflicts with a provision in any other written law, the provision in the other written law will prevail to the extent of the inconsistency."
    },
    {
        clauseSection: "26.1",
        sectionDescription: "Transitional Provision for Use of Personal Data Collected Before Appointed Day",
        expectedEvidences: "Documentation referencing Section 19 of the PDPA, which allows organizations to use personal data collected before the appointed day for the purposes it was originally collected, unless consent for such use is withdrawn or the individual indicates otherwise."
    },
    {
        clauseSection: "26.2",
        sectionDescription: "Application of PDPA to Personal Data Collected Before Appointed Day",
        expectedEvidences: "Documentation outlining the application of the PDPA to personal data collected before the appointed day, specifically regarding collection."
    },
    {
        clauseSection: "26.3",
        sectionDescription: "Effect of Section 19 on Use of Personal Data Collected Before Appointed Day",
        expectedEvidences: "Documentation explaining that under Section 19 of the PDPA, organizations are permitted to continue using personal data collected before the appointed day for the same purposes without requiring fresh consent, unless the individual has withdrawn consent."
    },
    {
        clauseSection: "26.4",
        sectionDescription: "Telemarketing Considerations under Section 19 of the PDPA",
        expectedEvidences: "Documentation clarifying that the purpose of telemarketing, such as sending specified messages to Singapore telephone numbers, could constitute a reasonable existing use under Section 19 of the PDPA."
    },
    {
        clauseSection: "26.5",
        sectionDescription: "Documentation of Purposes for Using Personal Data Collected Before Appointed Day",
        expectedEvidences: "Documentation advising organizations that while it's not mandatory for such purposes to be specified or notified to individuals, it's advisable to document these purposes."
    },
    {
        clauseSection: "26.6",
        sectionDescription: "Impact of Individual Consent or Withdrawal on Use of Personal Data",
        expectedEvidences: "Documentation highlighting that regardless of when an individual indicates their consent or withdrawal of consent, organizations must adhere to these preferences. For instance, if an individual previously indicated non-consent to a specific use of their personal data before the appointed day, the organization cannot use the data in that manner."
    }
]

export const TRUSTED_AI_DIMENSION_ANALYSIS_DATA = {
    "fairness_metrics": {
      "Demographic Parity": 0.09999999999999998
    },
    "transparency": false,
    "auditability": true,
    "openness": true,
    "performance_metrics": {
      "Accuracy": 1
    },
    "thresholds": {
      "Demographic Parity": 0.05,
      "Equalized Odds": 0.1,
      "Individual Fairness": 0.05,
      "Transparency (Explainability)": 0.9,
      "Auditability": 0.95,
      "Openness": 0.8
    }
  }
