// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";


// ----------------------------------------------------------------------

type Props = {
    setShow: any;
    show: any;
    reportName?: string;
    fileName?: string;
    keyGroup?: string;
    chartSeries: any;
    chartLables: any;
    series?: any;
};

const MultipleBarGraph = (props: Props) => {


    const [color, setColor] = useState("");
    const [splittedFileName, setsplittedFileName] = useState("");

    const chartLabels = props.chartLables && props.chartLables.map((i) => i);

    const chartSeries = props.chartSerires && props.chartSerires.map((i) => Number(i));

    useEffect(() => {
        if (props.fileName) {
            const parts = (props.fileName).split("/");
            const splittedfileName = parts[parts.length - 1];
            setsplittedFileName(splittedfileName)
        }

    }, [])

    const series = [
        {
            name: "frequency",
            data:
                props.chartSeries &&
                props.chartSeries.map((item: any) => {
                    return Number(item);
                })
            // valueChainCategoryCountDetails &&
            // valueChainCategoryCountDetails.map((item: any) => {
            //    return Number(item.count);
            // })
        }
    ];

    const chartOptions = merge({
        colors: ["#06b8b2", "#bd248f", "#315bbd", "#5e5d5c", "#b8544d", "#2f91b5", "#72b869", "#e6534c", "#ffa50a", "#fc6938"],
        labels: chartLabels,
        stroke: { colors: ["#c0c0c0"] },
        legend: { floating: true, horizontalAlign: "center" },
        dataLabels: { enabled: true, dropShadow: { enabled: false } },
        plotOptions: {
            pie: { pie: { labels: { show: true } } }
        },
        title: {
            text: `${splittedFileName}  (${props.keyGroup})`,
            style: {
                align: 'center',
                color: '#030303'
            }
        },
        selection: {
            enabled: true
        },
        chart: {
            toolbar: {
                show: true,
                // position: 'left',
                offsetX: 10,
                offsetY: 10,
                tools: {
                    download: true,
                },
            },
        },
    });


    return (
        <div className="mt-5">

            <ReactApexChart
                type="bar"
                series={series}
                options={chartOptions}
                height={700}
                width="100%"
            />
            {/* <p className="chart-title text-near-black-accent text-center mt-2">({props.keyGroup})</p>
            <p className="chart-title text-near-black-accent text-center">{splittedFileName}</p> */}

        </div>
    );
};
export default MultipleBarGraph;
