import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataServiceBaseUrl, dataService, riskManagementBaseUrl, riskManagement } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const responsibilityService = createApi({
  reducerPath: "responsibilityService",
  // baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
  baseQuery: fetchBaseQuery({ baseUrl: riskManagementBaseUrl }),
  tagTypes: ["Responsibility"],
  endpoints: (builder) => ({
    getAllResponsibilityGroup: builder.mutation({
      query(body) {
        return {
          // url: `${dataService.responsibilityGroupController.getAllResponsibilityGroup}`,
          url: `${riskManagement.getAllResponsibilityGroup}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    getAllResponsibilityCenter: builder.mutation({
      query(body) {
        return {
          // url: `${dataService.responsibilityCenterController.getAllResponsibilityCenter}`,
          url: `${riskManagement.getAllResponsibilityCenter}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    getAllObjects: builder.mutation({
      query(body) {
        return {
          // url: `${dataService.responsibilityCenterController.getAllResponsibilityCenter}`,
          url: `${riskManagement.getAllObjects}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          // body: body.payload,
        };
      },
    }),
    getAllObjectTypes: builder.mutation({
      query(body) {
        return {
          url: `${riskManagement.getAllObjectType}`,
          method: "GET",
        };
      },
    }),
    getAllParameterCategory: builder.mutation({
      query(body) {
        return {
          url: `${riskManagement.getAllParameterCategory}`,
          method: "GET",
        };
      },
    }),
    getAllParameter: builder.mutation({
      query(body) {
        return {
          url: `${riskManagement.getAllParameter}`,
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useGetAllResponsibilityCenterMutation,
  useGetAllResponsibilityGroupMutation,
  useGetAllObjectsMutation,
  useGetAllObjectTypesMutation,
  useGetAllParameterCategoryMutation,
  useGetAllParameterMutation
} = responsibilityService;
