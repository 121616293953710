import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { Select, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import {
  ACTION_TYPE, REPORT_NAME,
} from "../constant/reportType";
import { DATA_SET_BIAS_TEST_COLUMN, FAIRNESS_METRICS_FOR_MACHINE_LEARNING_COLUMN, PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_COLUMN, PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_DATSOURCE, SENSITIVE_BIAS_DATA_IDENTIFICATION_COLUMN, TERM_COMPARISON_ANALYSIS_COLUMN, TRUSTED_AI_DIMENSION_ANALYSIS_COLUMN } from "../constant/AppConstant";
import {
  getAllStatesTotalRecords,
} from "../redux-toolkit/slices/statesSlice";
import { getLocalStorageData } from "../utils/LocalStorageService";
import BiasMitigateProData from "../pages/reports/BiasMitigateProData";
import { getDataSetBiasTestDetails, getFairnessMetricsDetails, getTrustedAIDimensionAnalysisDetails } from "../redux-toolkit/slices/fastApiSlice";
import BiasAndVulnerabiltySummary from "./BiasAndVulnerabiltySummary";
import { Excel } from "antd-table-saveas-excel";
import { getTermComaprisonAnalysisDetails, getTermComaprisonAnalysisWithTermFileDetails } from "../redux-toolkit/slices/textMatcherSlice";
import MultipleBarGraph from "./MultipleBarGraph";
import { BsDownload, BsEnvelope, BsSearch } from "react-icons/bs";
import TermComparisonSummaryReport from "../pages/reports/TermComparisonSummaryReport";
import { alertMessage } from "../utils/AlertService";
import Loading from "./Loading";
import { useSendEmailforExportMutation } from "../redux-toolkit/services/sendEmailService";
import { TRUSTED_AI_DIMENSION_ANALYSIS_DATA } from "../constant/mockData";
import { AnyMessageParams } from "yup/lib/types";

const { Option } = Select;
type Props = {};

const BiasMitigatePro = (props: Props) => {
  const params = useParams();
  const localUserObj: any = getLocalStorageData("localUser");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const frequencyInputRef: any = useRef(null);

  // States
  const [show, setShow] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [reportName, setReportName]: any = useState();
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [fileName, setFileName]: any = useState();
  const [showGraph, setShowGraph] = useState(false);

  const [dataForGraph, setDataForGraph]: any = useState();
  const [termPieGraphData, setTermPieGraphData]: any = useState();

  const [filteredTermComparison, setFilteredTermComparison]: any = useState([]);
  const [showKeyWordGroupingBtn, setShowKeyWordGroupingBtn]: any = useState(false);
  const [showConsolidatedKeywrdGrp, setShowConsolidatedKeywrdGrp]: any = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [summReportName, setSummReportName]: any = useState("");

  // datasource
  const [dataSetBiasTestDataSource, setDataSetBiasTestDataSource]: any = useState([]);
  const [fairnessMetricsDataSource, setFairnessMetricsDataSource]: any = useState([]);
  const [projectCodeBiasAndVulSummaryDataSource, setProjectCodeBiasAndVulSummaryDataSource]: any = useState(PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_DATSOURCE);
  const [termComparisonAnalysisDataSource, setTermComparisonAnalysisDataSource]: any = useState([]);
  const [trustedAIDimenstioAnalysisDataSource, setTrustedAIDimenstioAnalysisDataSource]: any = useState([]);

  const [pieData, setPieData]: any = useState([]);

  //columns
  const [fairnessMetricsColumn, setFairnessMetricsColumn]: any = useState(FAIRNESS_METRICS_FOR_MACHINE_LEARNING_COLUMN);
  const [sensitiveBiasDataIdnColumn, setSensitiveBiasDataIdnColumn]: any = useState(SENSITIVE_BIAS_DATA_IDENTIFICATION_COLUMN);
  const [projectCodeBiasAndVulSummarycolumn, setProjectCodeBiasAndVulSummarycolumn]: any = useState(PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_COLUMN);

  //selector
  const allStatesTotalRecords: any = useAppSelector(getAllStatesTotalRecords);
  const getDataSetBiasTestData: any = useAppSelector(getDataSetBiasTestDetails);
  const getFairnessMetricsData: any = useAppSelector(getFairnessMetricsDetails);
  const getTermComaprisonAnalysisData: any = useAppSelector(getTermComaprisonAnalysisDetails);
  const getTermComaprisonAnalysisDatawithTermFile: any = useAppSelector(getTermComaprisonAnalysisWithTermFileDetails);
  const getTrustedAIDimensionAnalysis: any = useAppSelector(getTrustedAIDimensionAnalysisDetails);

  //mutation
  const [sendEmail, sendEmailResponse] = useSendEmailforExportMutation();


  // useEffect(() => {
  //   const dataDetails: any = data;
  //   let tempPie: any = [];
  //   let tempTableData: any = [];
  //   dataDetails[1].map((element: any) => {
  //     tempPie.push(element)
  //   })
  //   setPieData(tempPie);
  // }, [])

  // //chart
  // const chartLabels = projectCodeBiasAndVulSummaryDataSource &&
  //   projectCodeBiasAndVulSummaryDataSource.map((i: any) => i.type);
  // const chartSeries = projectCodeBiasAndVulSummaryDataSource &&
  //   projectCodeBiasAndVulSummaryDataSource.map((i: any) => Number(i.count));

  useEffect(() => {
    switch (params.reportType) {
      case REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION:
        setReportName(REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION)
        break;
      case REPORT_NAME.DATA_SET_BIAS_TEST:
        setReportName(REPORT_NAME.DATA_SET_BIAS_TEST)
        // setReportName("state");
        // setIsSelect(true);
        // getAllCountry({});
        // getAllState({ page: currentpage - 1, offset: offset, orderBy: "asc" });
        break;
      case REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING:
        setReportName(REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING)
        break;
      case REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY:
        setReportName(REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY)
        break;
      case REPORT_NAME.TERM_COMPARISON_ANALYSIS:
        setReportName(REPORT_NAME.TERM_COMPARISON_ANALYSIS)
        break;
      case REPORT_NAME.TRUSTED_AI_DIMENSION_ANALYSIS:
        setReportName(REPORT_NAME.TRUSTED_AI_DIMENSION_ANALYSIS)
        break;
      default:
    }
  }, []);

  const paginationChange = (page: number, offset: number) => {
    if (reportName === "state") {

    }
    setCurrentPage(page);
    setOffset(offset);
  };

  const handleShow = () => setShow(true);

  // navigate............
  const handleBack = () => {
    navigate("/complianceReports");
  };

  useEffect(() => {
    if (getDataSetBiasTestData) {
      let dataSetBiasTestArr = Object.entries(getDataSetBiasTestData);
      let temp: any = [];

      dataSetBiasTestArr?.forEach((element: any, i: any) => {
        let obj: any = {};
        Object.keys(element).map(key => {
          obj.column = element[0];
          obj.biasAnalysis = element[1]["Bias Analysis"];
          obj.maxPercentage = element[1]["Max Percentage"];
          obj.maxValue = element[1]["Max Value"];
          obj.fairnessScore = element[1]["Fairness Score"];
          obj.explicitFairnessFormula = element[1]["Explicit Fairness Formula"];
        });
        temp.push(obj)
      });

      setDataSetBiasTestDataSource(temp);
    }
  }, [getDataSetBiasTestData]);

  function replaceNaN(str: any) {
    return str.replace(/NaN/g, 'null'.toString());
  }


  useEffect(() => {
    if (getFairnessMetricsData) {

      const json = JSON.parse(replaceNaN(getFairnessMetricsData));


      let fairnessMetrics = Object.entries(json);

      let temp: any = [];

      fairnessMetrics?.forEach((element: any, i: any) => {

        // let obj: any = {};
        // Object.keys(element).map(key => {
        //   obj.metric = element[0];
        //   obj.value = element[1];
        // });
        // temp.push(obj)
        let obj: any = {};
        Object.keys(element).map(key => {
          obj.metric = element[0];
          obj.FDR = element[1]["False Discovery Rate (FDR)"] === null ? "-" : element[1]["False Discovery Rate (FDR)"];
          obj.FDRAnalysis = element[1]["False Discovery Rate Analysis"] === "" ? "-" : element[1]["False Discovery Rate Analysis"];
          obj.FNR = element[1]["False Negative Rate (FNR)"] === null ? "-" : element[1]["False Negative Rate (FNR)"];
          obj.FNRAnalysis = element[1]["False Negative Rate Analysis"] === "" ? "-" : element[1]["False Negative Rate Analysis"];
          obj.FPR = element[1]["False Positive Rate (FPR)"] === null ? "-" : element[1]["False Positive Rate (FPR)"];
          obj.FPRAnalysis = element[1]["False Positive Rate Analysis"] === "" ? "-" : element[1]["False Positive Rate Analysis"];
          obj.NPV = element[1]["Negative Predictive Value (NPV)"] === null ? "-" : element[1]["Negative Predictive Value (NPV)"];
          obj.NPVAnalysis = element[1]["Negative Predictive Value Analysis"] === "" ? "-" : element[1]["Negative Predictive Value Analysis"];
          obj.PPV = element[1]["Positive Predictive Value (PPV)"] === null ? "-" : element[1]["Positive Predictive Value (PPV)"];
          obj.PPVAnalysis = element[1]["Positive Predictive Value Analysis"] === "" ? "-" : element[1]["Positive Predictive Value Analysis"];
          obj.TPR = element[1]["True Positive Rate (TPR)"] === null ? "-" : element[1]["True Positive Rate (TPR)"];
          obj.TPRAnalysis = element[1]["True Positive Rate Analysis"] === "" ? "-" : element[1]["True Positive Rate Analysis"];
        });
        temp.push(obj)
      });

      setFairnessMetricsDataSource(temp);
    }
  }, [getFairnessMetricsData]);

  useEffect(() => {
    if (getTermComaprisonAnalysisData) {
      let termComparisonAnalysisArr = Object.entries(getTermComaprisonAnalysisData);

      const resultArray = [];

      for (const item of termComparisonAnalysisArr) {
        const fileName = item[0];
        const topicsObject: any = item[1];

        for (const [keyTerm, frequency] of Object.entries(topicsObject)) {
          resultArray.push({
            fileName,
            keyTerm,
            frequency
          });
        }
      }

      let sortedArray: any = [];
      sortedArray = [...resultArray].sort(
        (a, b) => a.keyTerm > b.keyTerm ? 1 : -1
      );

      setTermComparisonAnalysisDataSource(sortedArray);
      setFilteredTermComparison(sortedArray)
    }
  }, [getTermComaprisonAnalysisData]);

  useEffect(() => {
    if (getTermComaprisonAnalysisDatawithTermFile) {
      const outputList = [];

      for (const fileName in getTermComaprisonAnalysisDatawithTermFile) {
        const fileData = getTermComaprisonAnalysisDatawithTermFile[fileName];

        for (const keyGroup in fileData) {
          const keyTerms = fileData[keyGroup];

          for (const keyTerm in keyTerms) {
            const frequency = keyTerms[keyTerm];

            const outputItem = {
              fileName,
              keyGroup,
              keyTerm,
              frequency
            };

            outputList.push(outputItem);
          }
        }
      }

      let sortedArray = [...outputList].sort((a, b) => {
        if (a.keyGroup !== b.keyGroup) {
          return a.keyGroup.localeCompare(b.keyGroup); // Sort by keyGroup first
        } else {
          return a.keyTerm.localeCompare(b.keyTerm); // If keyGroup is the same, then sort by keyTerm
        }
      });

      setTermComparisonAnalysisDataSource(sortedArray);
      setFilteredTermComparison(sortedArray);

      let termComparisonAnalysisArr = Object.entries(getTermComaprisonAnalysisDatawithTermFile);

      const resultArray = [];

      for (const item of termComparisonAnalysisArr) {
        const fileName = item[0];
        const topicsObject: any = item[1];

        for (const [keyGroup, frequency] of Object.entries(topicsObject)) {
          resultArray.push({
            fileName,
            keyGroup,
            frequency
          });
        }
      }

      // For Pie graph ----

      const pirGraphData = sortedArray.reduce((result: any, item: any) => {
        const existingItem = result.find((entry: any) => entry.fileName === item.fileName);

        if (existingItem) {
          const keyGroupIndex = existingItem.keyGroup.indexOf(item.keyGroup);
          if (keyGroupIndex !== -1) {
            existingItem.count[keyGroupIndex] += item.frequency;
          } else {
            existingItem.keyGroup.push(item.keyGroup);
            existingItem.count.push(item.frequency);
          }
        } else {
          result.push({
            fileName: item.fileName,
            keyGroup: [item.keyGroup],
            count: [item.frequency]
          });
        }

        return result;
      }, []);

      setTermPieGraphData(pirGraphData);
      // For Bar graph

      const dataForGraph: any = [];

      resultArray.forEach((entry: any) => {
        const existingEntry = dataForGraph.find((item: any) => item.fileName === entry.fileName && item.keyGroup === entry.keyGroup);

        if (existingEntry) {
          // Entry already exists, add new chartData
          existingEntry.chartData.push({
            name: "frequency", // You can customize this name based on your needs
            value: entry.frequency.frequency, // Assuming the property name is "frequency"
          });
        } else {
          // Entry doesn't exist, create a new entry
          const newEntry = {
            fileName: entry.fileName,
            keyGroup: entry.keyGroup,
            chartData: Object.entries(entry.frequency).map(([name, value]) => ({ name, value })),
          };

          dataForGraph.push(newEntry);
        }
      });

      setDataForGraph(dataForGraph);
      setShowGraph(true);

    }
  }, [getTermComaprisonAnalysisDatawithTermFile]);


  useEffect(() => {
    if (projectCodeBiasAndVulSummaryDataSource) {

      const desiredFormat: any = {};

      // this is for taking out the piegraph data
      for (const object of projectCodeBiasAndVulSummaryDataSource) {
        const fileName = object.FileName;
        const type = object.Type;
        const count = object.Count;

        if (!desiredFormat[fileName]) {
          desiredFormat[fileName] = {
            fileName,
            types: [],
            count: []
          };
        }

        desiredFormat[fileName].types.push(type);
        desiredFormat[fileName].count.push(count);
      }

      let desiredFormatArr = Object.entries(desiredFormat);

      let tempPieData: any = [];
      desiredFormatArr.forEach((element: any) => {
        tempPieData.push(element[1])
      });
      setPieData(tempPieData);

      let tempDataSource: any = [];

      projectCodeBiasAndVulSummaryDataSource.forEach((element: any) => {
        let obj: any = {};
        Object.keys(element).map(key => {
          obj.type = element["Type"];
          obj.sensitivity_score = element["Sensitivity Score"];
          obj.fileName = element["FileName"];
          obj.count = element["Count"];
        });
        tempDataSource.push(obj);
      });

      setProjectCodeBiasAndVulSummaryDataSource(tempDataSource);
    }
  }, [])

  // useEffect(() => {
  //   if(getTrustedAIDimensionAnalysis) {
  //     // let apiRes: any = TRUSTED_AI_DIMENSION_ANALYSIS_DATA;
  //     // Convert API response to data source
  //     const dataSource = Object.entries(getTrustedAIDimensionAnalysis).map(([key, value]: any) => {
  //       let passFail;
  //       if (typeof value === 'boolean') {
  //         passFail = value ? 'Pass' : 'Fail';
  //       } else {
  //         passFail = value > 0.05 ? 'Pass' : 'Fail'; // Example condition for pass/fail
  //       }
  //       return {
  //         dimension: key,
  //         dimensionExplanation: "", // You can populate this if needed
  //         thresholdLimit: key in getTrustedAIDimensionAnalysis.thresholds ? getTrustedAIDimensionAnalysis.thresholds[key] : "",
  //         actualValue: typeof value === 'object' ? JSON.stringify(value) : value,
  //         passFail: passFail,
  //         inferences: "", // You can populate this if needed
  //         mitigationRecommendation: "" // You can populate this if needed
  //       };
  //     });
  //     setTrustedAIDimenstioAnalysisDataSource(dataSource)

  //     console.log(dataSource);
  //   }

  // }, []);

  useEffect(() => {
    const thresholdValues: any = {
      "Demographic Parity": 0.05,
      "Equalized Odds": 0.1,
      "Individual Fairness": 0.05,
      "Transparency (Explainability)": 0.9,
      "Auditability": 0.95,
      "Openness": 0.8
    };
    if (getTrustedAIDimensionAnalysis) {
      const { thresholds } = getTrustedAIDimensionAnalysis;

      // Mapping for dimensions and their explanations
      const dimensionMapping: any = {
        fairness_metrics: 'Fairness',
        transparency: 'Transparency (Explainability)',
        auditability: 'Auditability',
        openness: 'Openness',
        performance_metrics: 'Performance'
      };

      const dimensionExplanationMapping: any = {
        fairness_metrics: 'Demographic Parity',
        transparency: "Degree to which model's decisions can be understood by humans",
        auditability: "Ability to trace and understand the decision-making process of the model",
        openness: "Degree to which model and its processes are accessible and transparent",
        performance_metrics: "Model's predictive performance"
      };

      const trustedAIDimAnalysisDataSource = Object.entries(getTrustedAIDimensionAnalysis).flatMap(([dimension, subData]: any) => {
        if (dimension === 'thresholds') {
          return []; // Skip thresholds entry as it will be processed separately
        }

        const dimensionName = dimensionMapping[dimension] || dimension;
        const dimensionExplanation = dimensionExplanationMapping[dimension] || "";
        const thresholdKey = dimensionName;
        const thresholdLimit = thresholds[thresholdKey] || "N/A";

        if (typeof subData === "boolean") {
          return [{
            key: dimension,
            dimension: dimensionName,
            dimensionExplanation: dimensionExplanation,
            thresholdLimit: thresholdLimit.toString(),
            actualValue: subData.toString(),
            passFail: subData ? "Pass" : "Fail",
            inferences: subData ? `${dimensionName} requirement met.` : `${dimensionName} requirement not met.`,
            mitigationRecommendation: subData ? "No action required." : `Improve model ${dimensionName.toLowerCase()} through feature importance analysis, model visualization, and providing clear explanations for model decisions. Enhance documentation to facilitate understanding of model inputs, outputs, and underlying assumptions.`
          }];
        }

        if (typeof subData === "object") {
          return Object.entries(subData).map(([subDimension, actualValue]) => {
            const innerDimensionExplanation = dimensionExplanationMapping[subDimension] || dimensionExplanation;
            const innerThresholdLimit = thresholds[subDimension] || thresholdLimit;

            if (typeof actualValue === "boolean") {
              return {
                key: `${dimension}-${subDimension}`,
                dimension: dimensionName,
                dimensionExplanation: innerDimensionExplanation,
                thresholdLimit: innerThresholdLimit.toString(),
                actualValue: actualValue.toString(),
                passFail: actualValue ? "Pass" : "Fail",
                inferences: actualValue ? `${subDimension} requirement met.` : `${subDimension} requirement not met.`,
                mitigationRecommendation: actualValue ? "No action required." : `Improve model ${subDimension.toLowerCase()} through feature importance analysis, model visualization, and providing clear explanations for model decisions. Enhance documentation to facilitate understanding of model inputs, outputs, and underlying assumptions.`
              };
            } else if (typeof actualValue === "number") {
              const passFail = actualValue > innerThresholdLimit ? "Fail" : "Pass";
              const inferences = actualValue > innerThresholdLimit ? "Actual value exceeds threshold." : actualValue < innerThresholdLimit ? "Actual value is less than threshold." : "Actual value meets threshold.";
              const mitigationRecommendation = actualValue > innerThresholdLimit ? "Investigate the cause of the disparity in outcomes. Consider collecting additional data to ensure representativeness of all groups. Implement bias mitigation techniques such as fairness-aware algorithms or reweighting of samples to reduce disparate impact." : "No significant disparity observed. Continue monitoring.";

              return {
                key: `${dimension}-${subDimension}`,
                dimension: dimensionName,
                dimensionExplanation: innerDimensionExplanation,
                thresholdLimit: innerThresholdLimit.toString(),
                actualValue: actualValue.toFixed(2),
                passFail,
                inferences,
                mitigationRecommendation
              };
            }
          });
        }
      });

      setTrustedAIDimenstioAnalysisDataSource(trustedAIDimAnalysisDataSource);
    }
  }, [getTrustedAIDimensionAnalysis]);

  const handleExportClick = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === REPORT_NAME.DATA_SET_BIAS_TEST) {
      fileName = REPORT_NAME.DATA_SET_BIAS_TEST;
      columns = DATA_SET_BIAS_TEST_COLUMN;
      dataSource = dataSetBiasTestDataSource;
    } else if (reportName === REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING) {
      fileName = REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING
      columns = fairnessMetricsColumn;
      dataSource = fairnessMetricsDataSource;
    } else if (reportName === REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY) {
      fileName = REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY
      columns = projectCodeBiasAndVulSummarycolumn;
      dataSource = projectCodeBiasAndVulSummaryDataSource;
    } else if (reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
      fileName = REPORT_NAME.TERM_COMPARISON_ANALYSIS;
      columns = TERM_COMPARISON_ANALYSIS_COLUMN;
      dataSource = termComparisonAnalysisDataSource;
    } else if (reportName === REPORT_NAME.TRUSTED_AI_DIMENSION_ANALYSIS) {
      fileName = REPORT_NAME.TRUSTED_AI_DIMENSION_ANALYSIS;
      columns = TRUSTED_AI_DIMENSION_ANALYSIS_COLUMN;
      dataSource = trustedAIDimenstioAnalysisDataSource;
    }
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true
      })
      .saveAs(`${fileName}.xlsx`);
  };

  const handleSendEmail = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === REPORT_NAME.DATA_SET_BIAS_TEST) {
      fileName = REPORT_NAME.DATA_SET_BIAS_TEST;
      columns = DATA_SET_BIAS_TEST_COLUMN;
      dataSource = dataSetBiasTestDataSource;
    } else if (reportName === REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING) {
      fileName = REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING
      columns = fairnessMetricsColumn;
      dataSource = fairnessMetricsDataSource;
    } else if (reportName === REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY) {
      fileName = REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY
      columns = projectCodeBiasAndVulSummarycolumn;
      dataSource = projectCodeBiasAndVulSummaryDataSource;
    } else if (reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
      fileName = REPORT_NAME.TERM_COMPARISON_ANALYSIS;
      columns = TERM_COMPARISON_ANALYSIS_COLUMN;
      dataSource = termComparisonAnalysisDataSource;
    } else if (reportName === REPORT_NAME.TRUSTED_AI_DIMENSION_ANALYSIS) {
      fileName = REPORT_NAME.TRUSTED_AI_DIMENSION_ANALYSIS;
      columns = TRUSTED_AI_DIMENSION_ANALYSIS_COLUMN;
      dataSource = trustedAIDimenstioAnalysisDataSource;
    }

    if (dataSource.length > 0) {
      sendEmail({
        payload: {
          columns: columns,
          dataSource: dataSource,
          fileName: fileName,
          to: localUserObj.username
        },
        token: localUserObj.token
      })
    } else {
      alertMessage("Please make new request first!", "error");
    }
  };

  useEffect(() => {
    if (sendEmailResponse.isSuccess) {
      alertMessage("Emaile sent successfully", "success");
    } else if (sendEmailResponse.isError) {
      alertMessage("Error while sending email", "error");
    }
  }, [sendEmailResponse.isSuccess, sendEmailResponse.isError]);

  const handleSearch = (event: any) => {
    if (event.target.value !== "") {
      if (termComparisonAnalysisDataSource.length > 0) {
        const filteredArray = termComparisonAnalysisDataSource.filter((item: any) => item.frequency >= event.target.value);
        if (filteredArray.length > 0) {
          setShowKeyWordGroupingBtn(true);
          setShowConsolidatedKeywrdGrp(true);
        }
        setFilteredTermComparison(filteredArray)
      } else {
        alertMessage("Please make new request first!", "error");
      }
    } else {
      setFilteredTermComparison(termComparisonAnalysisDataSource);
      setShowKeyWordGroupingBtn(false);
      setShowConsolidatedKeywrdGrp(false);
    }
  }

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div onClick={handleShow} className="d-flex px-2 align-items-center mt-1">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0 text-nowrap"
              onClick={() => {
                setActionType(ACTION_TYPE.ADD_ACTION);
                // Clear frequency input field and make btns invisible
                if (frequencyInputRef.current) {
                  frequencyInputRef.current.value = "";
                  setShowKeyWordGroupingBtn(false);
                  setShowConsolidatedKeywrdGrp(false);
                  setFilteredTermComparison(termComparisonAnalysisDataSource)
                }
              }}
            >
              {/* <BiPlus /> */}
              New Request
            </Button>
          </div>
          {show && (
            <BiasMitigateProData
              show={show}
              setShow={setShow}
              reportName={params.reportType}
              reportNameCode={reportName}
              setDataSetBiasTestDataSource={setDataSetBiasTestDataSource}
              setFairnessMetricsDataSource={setFairnessMetricsDataSource}
              setFileName={setFileName}
            />
          )}
          {showReportModal && (
            <TermComparisonSummaryReport
              showReportModal={showReportModal}
              setShowReportModal={setShowReportModal}
              showKeyWordGroupingBtn={showKeyWordGroupingBtn}
              filteredTermComparison={filteredTermComparison}
              summReportName={summReportName}
              reportName={reportName}

            />
          )}
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={() => {
              handleBack();
              setFilteredTermComparison([]);
            }}
          >
            Back
          </Button>
          <div className="primary mt-2 mt-md-0 d-flex justify-content-end w-100 mx-2">
            {reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS && (
              <div className="input-group px-2 mt-1 w-25">
                <Form.Control
                  type="search"
                  placeholder="filter frequency"
                  className="border-0 bg-cm-light-primary1"
                  aria-label="Search"
                  onChange={handleSearch}
                  ref={frequencyInputRef}
                />
                <div className="input-group-append bg-cm-light-primary1 p-1 rounded-end">
                  <span className="input-group-text bg-cm-light-primary1 border-0">
                    {/* <img src={gear} alt="" /> */}
                    <BsSearch className=" bg-cm-light-primary1 border-cm-light-primary1" />
                  </span>
                </div>
              </div>
            )}
            {showKeyWordGroupingBtn && (
              <Button
                variant="primary"
                className="d-flex align-items-center me-2"
                onClick={() => {
                  setShowReportModal(true);
                  setSummReportName(REPORT_NAME.KEYWORD_GROUPING);
                }}
              >
                {REPORT_NAME.KEYWORD_GROUPING}
              </Button>
            )}
            {showConsolidatedKeywrdGrp && (
              <Button
                variant="primary"
                className="d-flex align-items-center me-2"
                onClick={() => {
                  setShowReportModal(true);
                  setSummReportName(REPORT_NAME.CONSOLIDATED_KEYWORD_GROUPING)
                }}
              >
                {REPORT_NAME.CONSOLIDATED_KEYWORD_GROUPING}
              </Button>
            )}
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleExportClick}
            >
              <BsDownload size={16} className="me-2" />
              Export
            </Button>
            <Button
              variant="primary"
              className="d-flex align-items-center mx-2"
              onClick={handleSendEmail}
            >
              <BsEnvelope size={16} className="me-2" />
              Email
            </Button>
          </div>
        </div>
        {sendEmailResponse.isLoading ?
          (
            <Loading />
          ) : (
            ""
          )}
        {fileName && (
          <p className="mt-2 mx-2 fw-bold">{fileName}</p>
        )
        }
      </div>
      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          rowKey={"id"}
          pagination={
            reportName === "state"
              ? {
                total: allStatesTotalRecords && allStatesTotalRecords,
                responsive: true,
                current: currentpage,
                showSizeChanger: true,
                pageSizeOptions: [15, 20, 30],
                onChange: (page, offset) => {
                  paginationChange(page, offset);
                },
                pageSize: offset,
              }
              : {
                responsive: true,
              }
          }
          columns={
            reportName === REPORT_NAME.DATA_SET_BIAS_TEST
              ? DATA_SET_BIAS_TEST_COLUMN
              : reportName === REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING
                ? fairnessMetricsColumn
                : reportName === REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION
                  ? sensitiveBiasDataIdnColumn
                  : reportName === REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY
                    ? projectCodeBiasAndVulSummarycolumn
                    : reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS
                      ? TERM_COMPARISON_ANALYSIS_COLUMN
                      : reportName === REPORT_NAME.TRUSTED_AI_DIMENSION_ANALYSIS
                        ? TRUSTED_AI_DIMENSION_ANALYSIS_COLUMN
                        : ""
          }
          dataSource={
            reportName === REPORT_NAME.DATA_SET_BIAS_TEST
              ? dataSetBiasTestDataSource
              : reportName === REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING
                ? fairnessMetricsDataSource
                : reportName === REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY
                  ? projectCodeBiasAndVulSummaryDataSource
                  : reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS
                    // ? filteredTermComparison.length !== 0 ? filteredTermComparison : termComparisonAnalysisDataSource
                    ? filteredTermComparison
                    : reportName === REPORT_NAME.TRUSTED_AI_DIMENSION_ANALYSIS
                      ? trustedAIDimenstioAnalysisDataSource
                      : ""
          }
        />
      </div>
      {reportName === REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY ? (
        (pieData.map((element: any) => {
          return (
            <div className="">
              {/* <p className="fw-bolder text-cm-active-primary1">{REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY}</p> */}
              <div className="shadow-lg p-3 mb-5 bg-body rounded overflow-scroll">
                <BiasAndVulnerabiltySummary title={element.fileName} chartSerires={element.count} chartLables={element.types} />
              </div>
            </div>
          )
        }))
      ) : null}

      {showGraph && (
        termPieGraphData.map((element: any) => {
          const parts = (element.fileName).split("/");
          const splittedfileName = parts[parts.length - 1];
          return (
            <div className="">
              {/* <p className="fw-bolder text-cm-active-primary1">{REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY}</p> */}
              <div className="shadow-lg p-3 mb-5 bg-body rounded overflow-scroll">
                <BiasAndVulnerabiltySummary title={splittedfileName} chartSerires={element.count} chartLables={element.keyGroup} />
              </div>
            </div>
          )
        })
      )}

      {showGraph && (
        (dataForGraph?.map((element: any) => {

          let tempLables: any = [];
          let tempSeries: any = [];

          element?.chartData?.map((item: any) => {
            tempLables.push(item.name);
            tempSeries.push(item.value);
          })

          return (
            <div className="">
              <div className="shadow-lg p-3 mb-5 bg-body rounded">
                <MultipleBarGraph
                  show={showGraph}
                  setShow={setShowGraph}
                  fileName={element.fileName}
                  keyGroup={element.keyGroup}
                  // data={element.chartData}
                  chartSeries={tempSeries}
                  chartLables={tempLables}
                />
              </div>
            </div>
          )

        })
        )
      )}
    </div>
  );
};
export default BiasMitigatePro;
