import { Table } from "antd";
import { AI_GOVERNANCE_EXPECTATIONS_COLUMN, AI_GOVERNANCE_EXPECTATIONS_DATA } from "../constant/AppConstant";
import { useEffect } from "react";

type Props = {};

const AIGovernanceExpectations = (props: Props) => {
   const terms = localStorage.getItem('terms')

   useEffect(() => {

      const table: any = document.querySelector(".ant-table-tbody");
      const row = table.querySelector(".my-active-row");

      row.scrollIntoView({
         block: "start",
         behavior: "smooth",
         inlineOffset: "center"
      });
   }, [])

   return (
      <>
         <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2 mt-3">
            <div className="d-flex align-items-center w-100 px-2">
               <h5
                  className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
                  style={{ fontWeight: "700" }}
               >
                  AI Governance Expectations
               </h5>
            </div>

         </div>
         <div className="mt-2 overflow-y-auto mx-3">
            <div className="my-2 w-100 overflow-x-auto rounded-3">
               <Table
                  rowKey={"keyControls"}
                  pagination={false}
                  columns={AI_GOVERNANCE_EXPECTATIONS_COLUMN}
                  dataSource={AI_GOVERNANCE_EXPECTATIONS_DATA}
                  rowClassName={(record, index) => record.mappedGovernanceSections === terms ? 'data-row my-active-row' : 'data-row'}
                  scroll={{ y: 'calc(100vh - 145px)' }}  // Set the height of the table body to enable vertical scrolling
                  sticky // Enable sticky headers
               />
            </div>
         </div>
         <footer className="text-align-center">
            <p className="text-cm-dark fs-6">
               Capitol Tunnels.ai &#169; 2023
            </p>
         </footer>
      </>
   );
};
export default AIGovernanceExpectations;
