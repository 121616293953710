import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import { ACTION_TYPE, REPORT_NAME } from "../../constant/reportType";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { alertMessage } from "../../utils/AlertService";
import * as Yup from "yup";
import Loading from "../../component/Loading";
import { Checkbox, Select } from "antd";
import { useFindAllPackagesMutation, useGetAllPackagesMutation, useGetPackageByIdMutation, useSavePackageMutation, useUpdatePackageMutation } from "../../redux-toolkit/services/packageMasterService";
import { useGetAllModulesMutation, useGetModuleByIdMutation, useSaveModuleMutation, useUpdateModuleMutation } from "../../redux-toolkit/services/moduleService";
import { useGetAllSubModulesMutation, useGetSubModuleByIdMutation, useSaveSubModuleMutation, useUpdateSubModuleMutation } from "../../redux-toolkit/services/subModuleService";
import { useGetAllFeaturesMutation, useGetFeatureByIdMutation, useSaveFeatureMutation, useUpdateFeatureMutation } from "../../redux-toolkit/services/featureService";
import { getAllModuleDetails, setModule } from "../../redux-toolkit/slices/moduleSlice";
import { useAppSelector } from "../../redux-toolkit/hooks";
import { getAllSubModuleDetails, setSubModule } from "../../redux-toolkit/slices/subModuleSlice";
import { useGetAllPackageFeatureMappingMutation, useGetByPackageFeatureMappingIdMutation, useSavePackageFeatureMappingMutation, useUpdatePackageFeatureMappingMutation } from "../../redux-toolkit/services/packageFeatureMappingService";
import { allPackagesDetails, getAllPackagesDetails, setAllPackages, setPackageMaster } from "../../redux-toolkit/slices/packageMasterSlice";
import { getAllFeatureDetails, setFeature } from "../../redux-toolkit/slices/featureSlice";


const { Option } = Select;

type Props = {
  setShow: any;
  show: any;
  data?: any;
  reportName?: string;
  reportNameCode?: string;
  actionType: ACTION_TYPE;
  setCall?: any;
};

// validation...........
const formSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  select: Yup.string().required("Please select"),
});

const PricingModuleData = (props: Props) => {
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  //selector


  //state
  const [getByIdData, setGetByIdData]: any = useState();
  const [selectData, setSelectData]: any = useState();
  const [subModuleData, setSubModuleData]: any = useState();
  const [featuresData, setFeaturesData]: any = useState();
  const [selectedFeatures, setSelectedFeatures]: any = useState([]);

  //getData
  const allModulesData: any = useAppSelector(getAllModuleDetails);
  const allSubModulesData: any = useAppSelector(getAllSubModuleDetails);
  const allPackagesData: any = useAppSelector(allPackagesDetails);
  const allFeaturesData: any = useAppSelector(getAllFeatureDetails);

  //getAll apis
  const [getAllModules, modulesResponse] = useGetAllModulesMutation();
  const [getAllSubModules, subModulesResponse] = useGetAllSubModulesMutation();
  const [getAllPackages, packagesResponse] = useFindAllPackagesMutation();// useGetAllPackagesMutation();
  const [getAllFeatures, featuresResponse] = useGetAllFeaturesMutation();

  //save
  const [savePackage, savePackageResponse] = useSavePackageMutation();
  const [saveModule, saveModuleResponse] = useSaveModuleMutation();
  const [saveSubModule, saveSubModuleResponse] = useSaveSubModuleMutation();
  const [saveFeature, saveFeatureResponse] = useSaveFeatureMutation();
  const [savePackageFeatureMapping, savePackageFeatureMappingResponse] = useSavePackageFeatureMappingMutation();

  //update
  const [updatePackage, updatePackageResponse] = useUpdatePackageMutation();
  const [updateModule, updateModuleResponse] = useUpdateModuleMutation();
  const [updateSubModule, updateSubModuleResponse] = useUpdateSubModuleMutation();
  const [updateFeature, updateFeatureResponse] = useUpdateFeatureMutation();
  const [updatePackageFeatureMapping, updatePackageFeatureMappingResponse] = useUpdatePackageFeatureMappingMutation();


  //getById mutation

  const [getPackageById, packageByIdResponse] = useGetPackageByIdMutation();
  const [getModuleById, moduleByIdResponse] = useGetModuleByIdMutation();
  const [getSubModuleById, subModuleByIdResponse] = useGetSubModuleByIdMutation();
  const [getFeatureById, featureByIdResponse] = useGetFeatureByIdMutation();
  const [getPackageFeatureMappingById, packageFeatureMappingByIdResponse] = useGetByPackageFeatureMappingIdMutation();

  useEffect(() => {
    if (props.reportNameCode === REPORT_NAME.SUB_MODULE ||
      props.reportNameCode === REPORT_NAME.FEATURE) {
      getAllModules({
        token: token,
        size: 100,
        page: 0,
        orderBy: "asc"
      });
    }
    if (props.reportNameCode === REPORT_NAME.FEATURE) {
      getAllSubModules({
        token: token,
        size: 200,
        page: 0,
        orderBy: "asc"
      });
    }
    if (props.reportNameCode === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
      getAllPackages({
        // token: token,
        // size: 200,
        // page: 0,
        // orderBy: "asc"
      })
      getAllFeatures({
        token: token,
        size: 200,
        page: 0,
        orderBy: "asc"
      })
    }
  }, [])

  //get allmodules.......
  useEffect(() => {
    if (modulesResponse.isSuccess) {
      dispatch(setModule(modulesResponse));
    } else if (modulesResponse.isError) {
      alertMessage("Error while fetching modules", "error");
    }
  }, [modulesResponse.isSuccess, modulesResponse.isError]);

  //get allsubmodules.......
  useEffect(() => {
    if (subModulesResponse.isSuccess) {
      dispatch(setSubModule(subModulesResponse));
    } else if (subModulesResponse.isError) {
      alertMessage("Error while fetching sub modules", "error");
    }
  }, [subModulesResponse.isSuccess, subModulesResponse.isError]);


  //get allpackages.......
  useEffect(() => {
    if (packagesResponse.isSuccess) {
      dispatch(setAllPackages(packagesResponse));
      setSelectData(packagesResponse?.data);
    } else if (packagesResponse.isError) {
      alertMessage("Error while fetching packages", "error");
    }
  }, [packagesResponse.isSuccess, packagesResponse.isError]);

  //get allFeatures....
  useEffect(() => {
    if (featuresResponse.isSuccess) {
      dispatch(setFeature(featuresResponse));
    } else if (featuresResponse.isError) {
      alertMessage("Error while fetching features", "error");
    }
  }, [featuresResponse.isSuccess, featuresResponse.isError]);

  useEffect(() => {
    if (allModulesData && props.reportNameCode === REPORT_NAME.SUB_MODULE
      || props.reportNameCode === REPORT_NAME.FEATURE) {
      setSelectData(allModulesData);
    }

    if (allSubModulesData && props.reportNameCode === REPORT_NAME.FEATURE) {
      setSubModuleData(allSubModulesData);
    }

    if (allPackagesData && props.reportNameCode === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
      setSelectData(allPackagesData);
    }

    if (allFeaturesData && props.reportNameCode === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
      setFeaturesData(allFeaturesData);
    }

  }, [allModulesData, allSubModulesData, allPackagesData, allFeaturesData]);

  // handleSubmit...........
  const handleSubmit = (values: any) => {
    if (props.actionType === ACTION_TYPE.ADD_ACTION) {
      if (props.reportNameCode === REPORT_NAME.PACKAGE) {
        savePackage({
          token: token,
          payload: {
            defaultCurrency: values.defaultCurrency,
            id: 0,
            noOfUsers: values.noOfUsers,
            recommendedUsers: values.recommendedUsers,
            packageDescription: values.packageDesc,
            name: values.name,
            pricingPerHalfYear: parseInt(values.pricingPerHalfYear),
            pricingPerMonth: parseInt(values.pricingPerMonth),
            pricingPerQuarter: parseInt(values.pricingPerQuarter),
            pricingPerYear: parseInt(values.pricingPerYear),
            status: true
          }
        });
      } else if (props.reportNameCode === REPORT_NAME.MODULE) {
        saveModule({
          token: token,
          payload: {
            id: 0,
            name: values.name,
            status: true
          }
        })
      } else if (props.reportNameCode === REPORT_NAME.SUB_MODULE) {
        saveSubModule({
          token: token,
          payload: {
            id: 0,
            name: values.name,
            moduleId: parseInt(values.selectModule),
            status: true
          }
        })
      } else if (props.reportNameCode === REPORT_NAME.FEATURE) {
        saveFeature({
          token: token,
          payload: {
            featureLimit: values.featureLimit,
            id: 0,
            moduleId: parseInt(values.selectModule),
            name: values.name,
            status: true,
            subModuleId: parseInt(values.selectSubModule),
          }
        })
      } else if (props.reportNameCode === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
        savePackageFeatureMapping({
          token: token,
          payload: {
            id: 0,
            featureId: parseInt(values.selectedFeature),
            packageId: parseInt(values.selectedPackage),
            dataLimit: values.dataLimit,
            unlimited: values.isUnlimited,
            status: true,
            featureIds: selectedFeatures,
          }
        })
      }
    } else if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
      if (props.reportNameCode === REPORT_NAME.PACKAGE) {
        updatePackage({
          token: token,
          id: props?.data?.id,
          payload: {
            id: props?.data?.id,
            defaultCurrency: values.defaultCurrency,
            noOfUsers: values.noOfUsers,
            recommendedUsers: values.recommendedUsers,
            packageDescription: values.packageDesc,
            name: values.name,
            pricingPerHalfYear: parseInt(values.pricingPerHalfYear),
            pricingPerMonth: parseInt(values.pricingPerMonth),
            pricingPerQuarter: parseInt(values.pricingPerQuarter),
            pricingPerYear: parseInt(values.pricingPerYear),
            status: props?.data?.status
          },
        });
      } else if (props.reportNameCode === REPORT_NAME.MODULE) {
        updateModule({
          token: token,
          id: props?.data?.id,
          payload: {
            id: props?.data?.id,
            name: values.name,
            status: props?.data?.status
          },
        });
      } else if (props.reportNameCode === REPORT_NAME.SUB_MODULE) {
        updateSubModule({
          token: token,
          id: props?.data?.id,
          payload: {
            id: props?.data?.id,
            name: values.name,
            status: props?.data?.status,
            moduleId: parseInt(values.selectModule),
          },
        });
      } else if (props.reportNameCode === REPORT_NAME.FEATURE) {
        updateFeature({
          token: token,
          id: props?.data?.id,
          payload: {
            id: props?.data?.id,
            name: values.name,
            status: props?.data?.status,
            moduleId: parseInt(values.selectModule),
            subModuleId: parseInt(values.selectSubModule)
          },
        });
      } else if (props.reportNameCode === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
        updatePackageFeatureMapping({
          token: token,
          id: props?.data?.id,
          payload: {
            id: props?.data?.id,
            status: props?.data?.status,
            // featureId: parseInt(values.selectedFeature), 
            packageId: parseInt(values.selectedPackage),
            dataLimit: values.dataLimit,
            unlimited: values.isUnlimited,
            featureIds: selectedFeatures,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
      if (props.reportNameCode === REPORT_NAME.PACKAGE) {
        getPackageById({
          token: token,
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === REPORT_NAME.MODULE) {
        getModuleById({
          token: token,
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === REPORT_NAME.SUB_MODULE) {
        getSubModuleById({
          token: token,
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === REPORT_NAME.FEATURE) {
        getFeatureById({
          token: token,
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
        getPackageFeatureMappingById({
          token: token,
          id: props?.data?.id,
        })
      }
    }
  }, []);

  const handleClose = () => props.setShow(false);

  const handleChangeValue = (values: any) => {
    // setSelectedPermissions(values);
  };

  //getById response useffects
  useEffect(() => {
    if (packageByIdResponse.isSuccess) {
      setGetByIdData(packageByIdResponse.data);
      // setSelectedValue(packageByIdResponse?.data?.categoryId)
    } else if (packageByIdResponse.isError) {
      let response: any = packageByIdResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while fetching data", "error");
    }
  }, [packageByIdResponse.isSuccess, packageByIdResponse.isError]);

  useEffect(() => {
    if (moduleByIdResponse.isSuccess) {
      setGetByIdData(moduleByIdResponse.data);
      // setSelectedValue(moduleByIdResponse?.data?.categoryId)
    } else if (moduleByIdResponse.isError) {
      let response: any = moduleByIdResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while fetching data", "error");
    }
  }, [moduleByIdResponse.isSuccess, moduleByIdResponse.isError]);

  useEffect(() => {
    if (subModuleByIdResponse.isSuccess) {
      setGetByIdData(subModuleByIdResponse.data);
      // setSelectedValue(subModuleByIdResponse?.data?.categoryId)
    } else if (subModuleByIdResponse.isError) {
      let response: any = subModuleByIdResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while fetching data", "error");
    }
  }, [subModuleByIdResponse.isSuccess, subModuleByIdResponse.isError]);

  useEffect(() => {
    if (featureByIdResponse.isSuccess) {
      setGetByIdData(featureByIdResponse.data);
      // setSelectedValue(featureByIdResponse?.data?.categoryId)
    } else if (featureByIdResponse.isError) {
      let response: any = featureByIdResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while fetching data", "error");
    }
  }, [featureByIdResponse.isSuccess, featureByIdResponse.isError]);

  useEffect(() => {
    if (packageFeatureMappingByIdResponse.isSuccess) {
      setGetByIdData(packageFeatureMappingByIdResponse.data);
      setSelectedFeatures(packageFeatureMappingByIdResponse?.data?.featureIds)
      // setSelectedValue(packageFeatureMappingByIdResponse?.data?.categoryId)
    } else if (packageFeatureMappingByIdResponse.isError) {
      let response: any = packageFeatureMappingByIdResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while fetching data", "error");
    }
  }, [packageFeatureMappingByIdResponse.isSuccess, packageFeatureMappingByIdResponse.isError]);

  //save responses useEffect

  useEffect(() => {
    if (savePackageResponse.isSuccess) {
      alertMessage("Package saved successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (savePackageResponse.isError) {
      let response: any = savePackageResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while saving package", "error");
    }
  }, [savePackageResponse.isSuccess, savePackageResponse.isError]);

  useEffect(() => {
    if (saveModuleResponse.isSuccess) {
      alertMessage("Module saved successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveModuleResponse.isError) {
      let response: any = saveModuleResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while saving module", "error");
    }
  }, [saveModuleResponse.isSuccess, saveModuleResponse.isError]);

  useEffect(() => {
    if (saveSubModuleResponse.isSuccess) {
      alertMessage("Sub module saved successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveSubModuleResponse.isError) {
      let response: any = saveSubModuleResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while saving sub module", "error");
    }
  }, [saveSubModuleResponse.isSuccess, saveSubModuleResponse.isError]);

  useEffect(() => {
    if (saveFeatureResponse.isSuccess) {
      alertMessage("Feature saved successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveFeatureResponse.isError) {
      let response: any = saveFeatureResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while saving module", "error");
    }
  }, [saveFeatureResponse.isSuccess, saveFeatureResponse.isError]);

  useEffect(() => {
    if (savePackageFeatureMappingResponse.isSuccess) {
      alertMessage("Package Feature Mapping saved successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (savePackageFeatureMappingResponse.isError) {
      let response: any = savePackageFeatureMappingResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while saving Package feature mapping", "error");
    }
  }, [savePackageFeatureMappingResponse.isSuccess, savePackageFeatureMappingResponse.isError]);

  //update responses useeffects
  useEffect(() => {
    if (updatePackageResponse.isSuccess) {
      alertMessage("Package updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updatePackageResponse.isError) {
      let response: any = updatePackageResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while updating package", "error");
    }
  }, [updatePackageResponse.isSuccess, updatePackageResponse.isError]);

  useEffect(() => {
    if (updateModuleResponse.isSuccess) {
      alertMessage("Package updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateModuleResponse.isError) {
      let response: any = updateModuleResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while updating module", "error");
    }
  }, [updateModuleResponse.isSuccess, updateModuleResponse.isError]);

  useEffect(() => {
    if (updateSubModuleResponse.isSuccess) {
      alertMessage("Package updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateSubModuleResponse.isError) {
      let response: any = updateSubModuleResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while updating sub module", "error");
    }
  }, [updateSubModuleResponse.isSuccess, updateSubModuleResponse.isError]);

  useEffect(() => {
    if (updateFeatureResponse.isSuccess) {
      alertMessage("Package updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateFeatureResponse.isError) {
      let response: any = updateFeatureResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while updating feature", "error");
    }
  }, [updateFeatureResponse.isSuccess, updateFeatureResponse.isError]);

  useEffect(() => {
    if (updatePackageFeatureMappingResponse.isSuccess) {
      alertMessage("Package feature mapping updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updatePackageFeatureMappingResponse.isError) {
      let response: any = updatePackageFeatureMappingResponse?.error;
      alertMessage(response?.data?.serviceErrors[0] ? response?.data?.serviceErrors[0]?.msg : "Error while updating package feature mapping", "error");
    }
  }, [updatePackageFeatureMappingResponse.isSuccess, updatePackageFeatureMappingResponse.isError]);


  const handleFeatureChange = (values: any) => {
    setSelectedFeatures(values);
  };

  return (
    <div>
      {/* <!-- Modal --> */}
      <Modal
        show={props.show}
        onHide={handleClose}
        className=""
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          <Modal.Title
            className="fw-bold text-primary"
            style={{ fontSize: "1rem" }}
          >
            {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "}
            {props.reportName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {savePackageResponse.isLoading ||
            saveModuleResponse.isLoading ||
            saveSubModuleResponse.isLoading ||
            saveFeatureResponse.isLoading ||
            packageByIdResponse.isLoading ||
            updatePackageResponse.isLoading ||
            updateModuleResponse.isLoading ||
            updateSubModuleResponse.isLoading ||
            updateFeatureResponse.isLoading ||
            moduleByIdResponse.isLoading ||
            subModuleByIdResponse.isLoading ||
            featureByIdResponse.isLoading ||
            modulesResponse.isLoading ||
            subModulesResponse.isLoading ||
            savePackageFeatureMappingResponse.isLoading ||
            updatePackageFeatureMappingResponse.isLoading ||
            packageFeatureMappingByIdResponse.isLoading
            ? (
              <Loading />
            ) : (
              ""
            )}
          <Formik
            // validationSchema={formSchema}
            enableReinitialize={true}
            initialValues={
              props.reportNameCode === REPORT_NAME.PACKAGE
                ? {
                  name: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.name : "",
                  packageDesc: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.packageDescription : "",
                  defaultCurrency: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.defaultCurrency : "",
                  pricingPerMonth: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.pricingPerMonth : "",
                  pricingPerQuarter: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.pricingPerQuarter : "",
                  pricingPerHalfYear: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.pricingPerHalfYear : "",
                  pricingPerYear: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.pricingPerYear : "",
                  noOfUsers: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.noOfUsers : "",
                  recommendedUsers: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.recommendedUsers : "",
                }
                : props.reportNameCode === REPORT_NAME.MODULE
                  ? {
                    selectedPackage: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.name : "",
                  }
                  : props.reportNameCode === REPORT_NAME.PACKAGE_FEATURE_MAPPING
                    ? {

                      name: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.name : "",
                      selectModule:
                        props.actionType === ACTION_TYPE.UPDATE_ACTION
                          ? getByIdData && getByIdData.moduleId
                          : "",
                      selectedPackage:
                        props.actionType === ACTION_TYPE.UPDATE_ACTION
                          ? getByIdData && getByIdData.packageId
                          : "",
                      selectedFeature:
                        props.actionType === ACTION_TYPE.UPDATE_ACTION
                          ? getByIdData && getByIdData.featureIds
                          : "",
                      isUnlimited:
                        props.actionType === ACTION_TYPE.UPDATE_ACTION
                          ? getByIdData && getByIdData.unlimited
                          : false,
                    }
                    : props.reportNameCode === REPORT_NAME.SUB_MODULE || props.reportNameCode === REPORT_NAME.FEATURE
                      ? {
                        name: props.actionType === ACTION_TYPE.UPDATE_ACTION ? getByIdData && getByIdData.name : "",
                        selectModule:
                          props.actionType === ACTION_TYPE.UPDATE_ACTION
                            ? getByIdData && getByIdData.moduleId
                            : "",
                        selectSubModule:
                          props.actionType === ACTION_TYPE.UPDATE_ACTION
                            ? getByIdData && getByIdData.subModuleId
                            : "",
                      }
                      : {
                        selectedPackage:
                          props.actionType === ACTION_TYPE.UPDATE_ACTION
                            ? getByIdData && getByIdData.packageId
                            : "",
                        selectedFeature:
                          props.actionType === ACTION_TYPE.UPDATE_ACTION
                            ? getByIdData && getByIdData.featureIds
                            : "",
                        dataLimit:
                          props.actionType === ACTION_TYPE.UPDATE_ACTION
                            ? getByIdData && getByIdData.dataLimit
                            : "",
                        isUnlimited:
                          props.actionType === ACTION_TYPE.UPDATE_ACTION
                            ? getByIdData && getByIdData.unlimited
                            : false,
                      }
            }
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                {props.reportNameCode === REPORT_NAME.PACKAGE ? (
                  <>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Name</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Description</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.packageDesc}
                        name="packageDesc"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>No. of users</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.noOfUsers}
                        name="noOfUsers"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Recommended users</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.recommendedUsers}
                        name="recommendedUsers"
                        placeholder="e.g Small business..."
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Pricing per year</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.pricingPerYear}
                        name="pricingPerYear"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Pricing per half year</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.pricingPerHalfYear}
                        name="pricingPerHalfYear"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Pricing per quarter</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.pricingPerQuarter}
                        name="pricingPerQuarter"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Pricing per month</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.pricingPerMonth}
                        name="pricingPerMonth"
                        required
                      />
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Default currency</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.defaultCurrency}
                        name="defaultCurrency"
                        required
                      />
                    </p>
                  </>
                ) : (
                  <>
                    {props.reportNameCode !== REPORT_NAME.PACKAGE_FEATURE_MAPPING && props.actionType === ACTION_TYPE.UPDATE_ACTION ?
                      <p>
                        <div className="d-flex">
                          <div> {props.reportName} Feature Name</div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <input
                          className="form-control form-control-sm w-100"
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.name}
                          name="name"
                          required
                        />
                      </p> : null}
                    {props.reportNameCode === REPORT_NAME.SUB_MODULE ||
                      props.reportNameCode === REPORT_NAME.FEATURE ? (
                      <p>
                        <div className="w-100">
                          <div className="d-flex">
                            <div>
                              {" "}
                              Select Module
                            </div>
                            <div style={{ color: "red" }}>*</div>
                          </div>
                          <div className="pe-2">
                            <select
                              className="form-select form-select-sm w-100"
                              aria-label=".form-select-sm example"
                              // onChange={handleModuleSelectChange}
                              onChange={handleChange}
                              name="selectModule"
                              // value={selectedModuleValue}
                              value={values.selectModule}
                              required
                            >
                              <option value="">
                                Select Module
                              </option>
                              {selectData &&
                                selectData.map((itemType: any, index: any) => {
                                  return (
                                    <option
                                      key={index.toString()}
                                      value={itemType.id}
                                    >
                                      {itemType.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </p>
                    ) : null}
                    {props.reportNameCode === REPORT_NAME.FEATURE ? (
                      <>
                        <p>
                          <div className="w-100">
                            <div className="d-flex">
                              <div>
                                {" "}
                                Select Sub Module
                              </div>
                              <div style={{ color: "red" }}>*</div>
                            </div>
                            <div className="pe-2">
                              <select
                                className="form-select form-select-sm w-100"
                                aria-label=".form-select-sm example"
                                // onChange={handleSubModuleSelectChange}
                                onChange={handleChange}
                                name="selectSubModule"
                                // value={selectedSubModuleValue}
                                value={values.selectSubModule}
                                required
                              >
                                <option value="">
                                  Select Sub Module
                                </option>
                                {subModuleData &&
                                  subModuleData.map((itemType: any, index: any) => {
                                    return (
                                      <option
                                        key={index.toString()}
                                        value={itemType.id}
                                      >
                                        {itemType.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </p>
                        <p>
                          <div className="d-flex">
                            <div> {props.reportName} Name</div>
                            <div style={{ color: "red" }}>*</div>
                          </div>
                          <input
                            className="form-control form-control-sm w-100"
                            type="text"
                            aria-label=".form-control-sm example"
                            onChange={handleChange}
                            value={values.name}
                            name="name"
                            required />
                        </p>
                      </>
                    ) : null}
                    {props.reportNameCode === REPORT_NAME.PACKAGE_FEATURE_MAPPING ? (
                      <>
                        <p>
                          <div className="w-100">
                            <div className="d-flex">
                              <div>
                                {" "}
                                Select Package
                              </div>
                              <div style={{ color: "red" }}>*</div>
                            </div>
                            <div className="pe-2">
                              <select
                                className="form-select form-select-sm w-100"
                                aria-label=".form-select-sm example"
                                // onChange={handleChange}
                                onChange={(event) => {
                                  handleChange(event); // Let Formik handle the change
                                  const selectedPackageId = (event.target.value);
                                  const selectedPackage = selectData.find(
                                    (obj: any) => (obj.id).toString() === selectedPackageId
                                  );
                                  if (selectedPackage) {
                                    setSelectedFeatures(selectedPackage.featureIds);  // Update selectedFeature based on the selected package
                                  }
                                }}
                                name="selectedPackage"
                                value={values.selectedPackage}
                                required
                              >
                                <option value="">
                                  Select Package
                                </option>
                                {selectData &&
                                  selectData.map((itemType: any, index: any) => {
                                    return (
                                      <option
                                        key={index.toString()}
                                        value={itemType.id}
                                      >
                                        {itemType.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </p>
                        <p>
                          <div className="w-100">
                            <div className="d-flex">
                              <div>
                                {" "}
                                Select Feature
                              </div>
                              <div style={{ color: "red" }}>*</div>
                            </div>
                            <div className="pe-2">
                              <Select
                                mode="multiple"
                                placeholder="Select features"
                                value={selectedFeatures}
                                onChange={handleFeatureChange}
                                style={{ width: '100%' }}
                                showSearch={false}
                              // options={filteredOptions.map((item: any) => ({
                              // value: item,
                              // label: item,
                              // }))}
                              >
                                {/* Render options with checkboxes */}
                                {featuresData && featuresData.map((feature: any) => {
                                  return (
                                    <Option key={feature.id} value={feature.id}>
                                      <Checkbox checked={selectedFeatures.includes(feature.id)} />
                                      {" "}{feature.name}
                                    </Option>
                                  )
                                })}
                              </Select>
                              {/* <select
                                className="form-select form-select-sm w-100"
                                aria-label=".form-select-sm example"
                                onChange={handleChange}
                                name="selectedFeature"
                                value={values.selectedFeature}
                                required
                              >
                                <option value="">
                                  Select Feature
                                </option>
                                {featuresData &&
                                  featuresData.map((itemType: any, index: any) => {
                                    return (
                                      <option
                                        key={index.toString()}
                                        value={itemType.id}
                                      >
                                        {itemType.name}
                                      </option>
                                    );
                                  })}
                              </select> */}
                            </div>
                          </div>
                        </p>
                        <p className="d-flex">
                          <div className="d-flex">
                            <div>Is Unlimited</div>
                            <div style={{ color: "red" }}>*</div>
                          </div>
                          <div className="d-flex ms-2">
                            <input
                              type="checkbox"
                              id="isUnlimitedYes"
                              name="isUnlimited"
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.checked && values.isUnlimited === false) {
                                  handleChange({
                                    target: {
                                      name: "isUnlimited",
                                      value: true
                                    }
                                  });
                                  setFieldValue("dataLimit", null);
                                }
                              }}
                              value={true as any}
                              checked={values.isUnlimited === true}
                            />
                            <label className="ms-1">Yes</label>
                          </div>
                          <div className="d-flex ms-2">
                            <input
                              type="checkbox"
                              id="isUnlimitedNo"
                              name="isUnlimited"
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.checked && values.isUnlimited === true) {
                                  handleChange({
                                    target: {
                                      name: "isUnlimited",
                                      value: false
                                    }
                                  });
                                }
                              }}
                              value={false as any}
                              checked={values.isUnlimited === false}
                            />
                            <label className="ms-1">No</label>
                          </div>
                        </p>
                        {values.isUnlimited === false && (
                          <p>
                            <div className="d-flex">
                              <div>Data Limit</div>
                              <div style={{ color: "red" }}>*</div>
                            </div>
                            <input
                              className="form-control form-control-sm w-100"
                              type="number"
                              aria-label=".form-control-sm example"
                              onChange={handleChange}
                              value={values.dataLimit}
                              name="dataLimit"
                              required
                            />
                          </p>
                        )}

                      </>
                    ) : null}
                  </>
                )}
                <div className="d-flex flex-row justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                  >
                    {props.actionType === ACTION_TYPE.ADD_ACTION
                      ? "Save"
                      : "Update"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PricingModuleData;
