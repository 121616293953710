import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getLocalStorageData } from "../utils/LocalStorageService";
import { Button, Form } from "react-bootstrap";
import { BiPlus, BiSearch } from "react-icons/bi";
import { Switch, Table } from "antd";
import { useEffect, useState } from "react";
import { ACTION_TYPE, REPORT_NAME } from "../constant/reportType";
import { FAQS_COLUMN, USER_ROLE } from "../constant/AppConstant";
import { Formik } from "formik";
import { useAllModulesMutation } from "../redux-toolkit/services/moduleService";
import { useAllSubModulesMutation } from "../redux-toolkit/services/subModuleService";
import { allModules, setAllModules } from "../redux-toolkit/slices/moduleSlice";
import { alertMessage } from "../utils/AlertService";
import { allSubModules, setAllSubModules } from "../redux-toolkit/slices/subModuleSlice";
import { useAppSelector } from "../redux-toolkit/hooks";
import FAQMasterData from "../pages/reports/FAQMasterData";
import editIcon from "../assets/icons/editicon.svg";
import { useDeleteFAQMutation, useGetAllFAQsMutation, useGetAllFAQsWithSearchMutation } from "../redux-toolkit/services/faqsService";
import { getAllFAQsDetails, getFAQsTotalRecords, setFAQs } from "../redux-toolkit/slices/faqsSlice";
import Loading from "./Loading";
import { useAllFeaturesMutation } from "../redux-toolkit/services/featureService";
import { allFeaturesDetails, setAllFeatures } from "../redux-toolkit/slices/featureSlice";
import ChatBoat from "../pages/chatBot/Collapsewindow";
import { SiProbot } from "react-icons/si";

type Props = {};
const FAQMaster = (props: Props) => {
    // States
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token: any = getLocalStorageData("localUser").token;
    const localUserObj = getLocalStorageData("localUser");

    //states
    const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
    const [reportName, setReportName]: any = useState();
    const [datasource, setDatasource]: any = useState([]);
    const [data, setData]: any = useState();
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState([]);
    const [columData, setColumnData]: any = useState(FAQS_COLUMN);
    const [call, setCall] = useState("N");
    const [currentpage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const currentUrl = window.location.href.substring((window.location.href.lastIndexOf('/')) + 1);

    //get mutation
    const [getAllModules, modulesResponse] = useAllModulesMutation();
    const [getAllSubModules, subModulesResponse] = useAllSubModulesMutation();
    const [getAllFeatures, featuresResponse] = useAllFeaturesMutation();
    const [getAllFAQs, getAllFAQsResponse] = useGetAllFAQsMutation();


    //getData
    const allModulesData: any = useAppSelector(allModules);
    const allSubModulesData: any = useAppSelector(allSubModules);
    const allFeaturesData: any = useAppSelector(allFeaturesDetails);
    const allFAQsTotalRecords: any = useAppSelector(getFAQsTotalRecords);
    const allFAQsDetails: any = useAppSelector(getAllFAQsDetails);

    //delete
    const [deleteFAQ, deleteFAQResponse] = useDeleteFAQMutation();

    //search
    const [getFAQBySearch, getFAQBySearchResponse] = useGetAllFAQsWithSearchMutation();

    useEffect(() => {
        getAllModules({
            token: token,
        });
        getAllSubModules({
            token: token,
        });
        getAllFeatures({
            token: token,
        })
        getAllFAQs({
            token: token,
            size: offset,
            page: currentpage - 1,
            orderBy: "asc",
        })
    }, []);

    //get allmodules.......
    useEffect(() => {
        if (modulesResponse.isSuccess) {
            dispatch(setAllModules(modulesResponse));
        } else if (modulesResponse.isError) {
            alertMessage("Error while fetching modules", "error");
        }
    }, [modulesResponse.isSuccess, modulesResponse.isError]);

    //get allsubmodules.......
    useEffect(() => {
        if (subModulesResponse.isSuccess) {
            dispatch(setAllSubModules(subModulesResponse));
        } else if (subModulesResponse.isError) {
            alertMessage("Error while fetching sub modules", "error");
        }
    }, [subModulesResponse.isSuccess, subModulesResponse.isError]);

    //get allFeatures....
    useEffect(() => {
        if (featuresResponse.isSuccess) {
            dispatch(setAllFeatures(featuresResponse));
        } else if (featuresResponse.isError) {
            alertMessage("Error while fetching features", "error");
        }
    }, [featuresResponse.isSuccess, featuresResponse.isError]);
    //get allFAQs.......
    useEffect(() => {
        if (getAllFAQsResponse.isSuccess) {
            dispatch(setFAQs(getAllFAQsResponse));
        } else if (getAllFAQsResponse.isError) {
            alertMessage("Error while fetching FAQs", "error");
        }
    }, [getAllFAQsResponse.isSuccess, getAllFAQsResponse.isError]);

    useEffect(() => {
        if (allFAQsDetails) {
            setDatasource(allFAQsDetails)
        }
    }, [allFAQsDetails])


    useEffect(() => {
        if (currentUrl === "faqs") {
            setReportName(REPORT_NAME.FAQS);
        }
    }, []);

    // handleSearch...........
    const handleSubmit = (values: any) => {
        console.log(values)
        getFAQBySearch({
            token: token,
            payload: {
                offset: 200,
                pageNumber: 0,
                searchBy: values.selectedSubModule !== "" ? values.selectedSubModule : (values.selectedModule !== "" ? values.selectedModule : (values.selectedFeature !== "" ? values.selectedFeature : "")),
            }
        })
    };

    //get allFAQsBySearch.......
    useEffect(() => {
        if (getFAQBySearchResponse.isSuccess) {
            dispatch(setFAQs(getFAQBySearchResponse));
        } else if (getFAQBySearchResponse.isError) {
            alertMessage("Error while fetching FAQs", "error");
        }
    }, [getFAQBySearchResponse.isSuccess, getFAQBySearchResponse.isError]);

    const handleShow = () => setShow(true);

    const handleOpenForm = (record: any) => {
        setShow(true);
        setActionType(ACTION_TYPE.UPDATE_ACTION);
        setSelectedRow(record);
    };

    const paginationChange = (page: number, offset: number) => {
        if (reportName === REPORT_NAME.FAQS) {
            getAllFAQs({
                token: token,
                page: page - 1,
                size: offset,
                orderBy: "asc",
            });
        }
        setCurrentPage(page);
        setOffset(offset);
    };

    //delete..........
    const onDeleteData = (record: any) => {
        if (reportName === REPORT_NAME.FAQS) {
            deleteFAQ({
                id: record.id,
                token: token
            });
        }
    };

    //deleteFAQs.......
    useEffect(() => {
        if (deleteFAQResponse.isSuccess) {
            alertMessage("FAQ status updated successfully", "success");
            setCall("Y");
        } else if (deleteFAQResponse.isError) {
            alertMessage("Error while updating FAQ status", "error");
        }
    }, [deleteFAQResponse.isSuccess, deleteFAQResponse.isError]);

    useEffect(() => {
        if (call === "Y") {
            if (reportName === REPORT_NAME.FAQS) {
                setReportName(REPORT_NAME.FAQS);
                getAllFAQs({
                    token: token,
                    page: currentpage - 1,
                    size: offset,
                    orderBy: "asc",
                });
                setCall("N");
            }
        }
    }, [call]);

    //logic to add action column in table
    useEffect(() => {
        if (localUserObj?.userType === USER_ROLE.ADMIN) {
            const temp: any = [];
            const tempAction: any = {
                title: "Action",
                dataIndex: "action",
                key: "name",
                fixed: "right",
                width: 120,
                render: (index: any, record: any) => {
                    return (
                        // for delete switch button
                        <div className="d-flex">
                            <div className="px-2 pe-auto">
                                <Switch
                                    checked={record.status === true ? true : false}
                                    onClick={() => {
                                        onDeleteData(record);
                                    }}
                                />
                            </div>
                            {record.status === true ? (
                                <div
                                    className="px-2 pe-auto"
                                    onClick={() => {
                                        handleOpenForm(record);
                                    }}
                                >
                                    <img src={editIcon} alt="update" />
                                </div>
                            ) : null}
                        </div>
                    );
                },
            };

            if (reportName === REPORT_NAME.FAQS && FAQS_COLUMN) {
                FAQS_COLUMN.map((item: any) => {
                    temp.push(item);
                });
                temp.push(tempAction);
                setColumnData(temp);
            }
        }
    }, [reportName]);

    const [showColl, setShowColl] = useState(false);

    // Function to toggle visibility of Coll component
    const toggleColl = () => {
        setShowColl(!showColl);
    };

    return (
        <div className="mt-2 overflow-y-auto">
            {/* navigate */}
            <div className="d-flex align-items-center justify-content-between  flex-column mb-2">
                <div className="d-flex align-items-center w-100 px-2 justify-content-between">
                    <h5
                        className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
                        style={{ fontWeight: "700" }}
                    >
                        {reportName === REPORT_NAME.FAQS ? "Frequently Asked Questions" : ""}
                    </h5>
                    <div className="d-flex">
                        {localUserObj?.userType === USER_ROLE.ADMIN ? (
                            <div className={`d-flex px-2 align-items-center`}>
                                <div className="d-flex align-items-center px-3" onClick={handleShow}>
                                    <Button
                                        variant="primary"
                                        className="d-flex align-items-center me-0 text-nowrap"
                                        onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
                                    >
                                        <BiPlus />
                                        {reportName === "dataRecord" ? "Web Search" : "Add"}
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                        <Button
                            variant="primary"
                            className="d-flex align-items-center me-0 text-nowrap"
                            onClick={toggleColl}
                        // onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
                        >
                            <SiProbot className="me-1"/>
                            Bot
                        </Button>
                    </div>
                    {showColl &&
                        <ChatBoat
                            show={showColl}
                            setShow={setShowColl}
                        />
                    }
                </div>
                <div className="d-flex align-items-center cursor-pointer w-100 justify-content-start justify-content-sm-end px-2 mt-3">
                    <div className="d-flex flex-column">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                selectedModule: "",
                                selectedSubModule: "",
                                selectedFeature: "",
                            }}
                            onSubmit={handleSubmit}
                        >
                            {({ values, handleChange, handleSubmit, setFieldValue }) => (
                                <Form onSubmit={handleSubmit} className="d-flex">
                                    <div className="d-flex">
                                        <p className="w-100">
                                            <div className="d-flex">
                                                <div>Module</div>
                                            </div>
                                            <div className="pe-2">
                                                <select
                                                    className="form-select form-select-sm w-100"
                                                    aria-label=".form-select-sm example"
                                                    name="selectedModule"
                                                    value={values.selectedModule}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        // Clear sub-module when module changes
                                                        setFieldValue("selectedSubModule", "");
                                                    }}
                                                >
                                                    <option value="">Select Module</option>
                                                    {allModulesData &&
                                                        allModulesData.map((itemType: any, index: any) => {
                                                            return (
                                                                <option
                                                                    key={index.toString()}
                                                                    value={itemType.name}
                                                                >
                                                                    {itemType.name}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </p>
                                        <p className="w-100">
                                            <div className="d-flex">
                                                <div>Sub Module</div>
                                            </div>
                                            <div className="pe-2">
                                                <select
                                                    className="form-select form-select-sm w-100"
                                                    aria-label=".form-select-sm example"
                                                    name="selectedSubModule"
                                                    value={values.selectedSubModule}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        // Clear module when sub-module changes
                                                        setFieldValue("selectedModule", "");
                                                    }}
                                                >
                                                    <option value="">Select Sub module</option>
                                                    {allSubModulesData &&
                                                        allSubModulesData.map((itemType: any, index: any) => {
                                                            return (
                                                                <option
                                                                    key={index.toString()}
                                                                    value={itemType.name}
                                                                >
                                                                    {itemType.name}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </p>
                                        <p className="w-100">
                                            <div className="d-flex">
                                                <div>Sub Module</div>
                                            </div>
                                            <div className="pe-2">
                                                <select
                                                    className="form-select form-select-sm w-100"
                                                    aria-label=".form-select-sm example"
                                                    name="selectedFeature"
                                                    value={values.selectedFeature}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        // Clear module and sub-module when feature changes
                                                        setFieldValue("selectedModule", "");
                                                        setFieldValue("selectedSubModule", "");
                                                    }}
                                                >
                                                    <option value="">Select feature</option>
                                                    {allFeaturesData &&
                                                        allFeaturesData.map((itemType: any, index: any) => {
                                                            return (
                                                                <option
                                                                    key={index.toString()}
                                                                    value={itemType.name}
                                                                >
                                                                    {itemType.name}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </p>
                                        <div className="d-flex align-items-center px-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-block d-flex align-items-center"
                                            // className="d-flex align-items-center me-0 text-nowrap"
                                            // onClick={(event) => {
                                            //     event.preventDefault();
                                            //     handleSubmit(event)
                                            // }}
                                            >
                                                <BiSearch className="mx-1" />
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>

            </div>

            {show && (
                <FAQMasterData
                    show={show}
                    setShow={setShow}
                    reportName={params.reportType}
                    reportNameCode={reportName}
                    data={selectedRow}
                    actionType={actionType}
                    setCall={setCall}
                />
            )}

            {/* <div className="my-2 w-100 overflow-x-auto rounded-3 mt-5 " style={{ height: "50vh" }}>
                <Menu className="bg-cm-light-primary1" triggerSubMenuAction="click" items={data && data} style={{ width: "fit-content" }} />
            </div> */}
            {modulesResponse.isLoading ||
                subModulesResponse.isLoading ||
                getAllFAQsResponse.isLoading ||
                deleteFAQResponse.isLoading ||
                getFAQBySearchResponse.isLoading
                ? (
                    <Loading />
                ) : (
                    ""
                )}
            <div className="my-2 mt-2 w-100 overflow-x-auto rounded-3">
                <Table
                    bordered={true}
                    rowKey={"id"}
                    pagination={
                        reportName === REPORT_NAME.FAQS
                            ? {
                                total: allFAQsTotalRecords && allFAQsTotalRecords,
                                responsive: true,
                                current: currentpage,
                                showSizeChanger: true,
                                pageSizeOptions: [10, 20, 30],
                                onChange: (page, offset) => {
                                    paginationChange(page, offset);
                                },
                                pageSize: offset,
                            } : {
                                responsive: true
                            }
                    }
                    columns={
                        reportName === REPORT_NAME.FAQS
                            ? columData
                            : ""
                    }
                    dataSource={
                        reportName === REPORT_NAME.FAQS
                            ? datasource
                            : ""
                    }
                />
            </div>
        </div>
    );
};
export default FAQMaster;