import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManagement, userManagementBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const subModuleService = createApi({
    reducerPath: 'subModuleService',
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ['SubModule'],
    endpoints: (builder) => ({
        getAllSubModules: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.subModuleController.getAllSubModules}/${body.page}/${body.size}?orderBy=${body.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getSubModuleById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.subModuleController.getBySubModuleId}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        saveSubModule: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.subModuleController.saveSubModule}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateSubModule: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.subModuleController.updateSubModule}/${body.payload.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteSubModule: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.subModuleController.deleteSubModule}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllSubModulesWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.subModuleController.getAllSubModulesWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        allSubModules: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.subModuleController.allSubModules}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
    })
  });
  export const {
    useGetAllSubModulesMutation,
    useSaveSubModuleMutation,
    useUpdateSubModuleMutation,
    useDeleteSubModuleMutation,
    useGetSubModuleByIdMutation,
    useGetAllSubModulesWithSearchMutation,
    useAllSubModulesMutation,
  } = subModuleService;