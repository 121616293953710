import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface SubModuleState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}
const initialState: SubModuleState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const subModuleSlice = createSlice({
  name: 'subModule',
  initialState,
  reducers: {
    setSubModule: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllSubModules: (state, action) => {
      state.data = action.payload.data;
    },
    setAllSubModulesBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setSubModule, setAllSubModulesBySearch, setAllSubModules, reset } = subModuleSlice.actions;
export const getAllSubModuleDetails = (state: RootState) => state.subModule.data;
export const getSubModuleTotalRecords = (state:RootState) => state.subModule.totalRecords;
export const getAllSubModulesBySearchDetails = (state: RootState) => state.subModule.data;
export const allSubModules = (state: RootState) => state.subModule.data;
export default subModuleSlice.reducer;