import { useLocation } from "react-router-dom";
import { REPORT_TYPE } from "../../constant/reportType";
import DataGovernance from "../../component/DataGovernance";
import MatchMakerManagementMaster from "../../component/MatchMakerManagementMaster";

type Props = {};

const AllMatchMakerReports = (props: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const report = searchParams.get("report") !== null ? searchParams.get("report") : localStorage.getItem("selectedReport")

  if (report == REPORT_TYPE.MATCH_MAKER_MANAGMENT) {
    return (
      <MatchMakerManagementMaster />
    )
  } else {
    return (
      <div>Something went wrong</div>
    )
  }

};
export default AllMatchMakerReports;
