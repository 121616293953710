import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import { REPORT_NAME } from "../../constant/reportType";
import { useDispatch } from "react-redux";
import { alertMessage } from "../../utils/AlertService";
import { useCodeAIBiasAnalysisMutation, useCodeCyberSecVulAnalysisMutation, useCodeReviewReportMutation } from "../../redux-toolkit/services/vulnerabiltyService";
import { setAiAlgAccReliabilityTest, setCodeAIBiasAnalysis, setCodeReviewData, setFeatureDominance, setVulnerabilty } from "../../redux-toolkit/slices/vulnerabiltySlice";
import Loading from "../../component/Loading";
import { useAiAlgAccReliabilityTestMutation, useFeatureDominanceMutation } from "../../redux-toolkit/services/fastApiService";

type Props = {
  setShow: any;
  show: any;
  reportName?: string;
  reportNameCode?: string;
  setCodeAiBiasAnalysisDataSource?: any;
  setdatasource?: any;
  setAiAlgAccReliabilityTestDataSource?: any;
  setFileName: any;
  setShowRiskBtn: any;
};

const CyberSecAiSheildData = (props: Props) => {
  const selectedReportNameL: any = localStorage?.getItem('selectedReportName');
  const dispatch = useDispatch();

  //state
  const [file, setFile]: any = useState<File | null>(null);

  //mutation
  const [uploadCCSVulnerabilityAnalysis, uploadCCSVulnerabilityAnalysisResponse] = useCodeCyberSecVulAnalysisMutation();
  const [uploadCodeAIBiasAnalysis, uploadCodeAIBiasAnalysisResponse] = useCodeAIBiasAnalysisMutation();
  const [uploadAiAlgAccReliabilityTest, uploadAiAlgAccReliabilityTestResponse] = useAiAlgAccReliabilityTestMutation();
  const [uploadFeatureDominance, uploadFeatureDominanceResponse] = useFeatureDominanceMutation();
  const [uploadCodeReview, uploadCodeReviewResponse] = useCodeReviewReportMutation();

  // handleSubmit...........
  const handleSubmit = async (values: any) => {

    // Create a new FormData object.
    const formData = new FormData();

    // Append the file to the FormData object.
    formData.append('file', file);

    const fileName = file.name;
    setFileName(fileName)
    const extension = fileName.lastIndexOf(".") + 1;
    const extFile = fileName.substr(extension, fileName.length).toLowerCase();

    if (extFile == "csv") {
      if (props.reportNameCode === REPORT_NAME.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST) {
        uploadAiAlgAccReliabilityTest({
          file: formData
        })
      } else if (props.reportNameCode === REPORT_NAME.FEATURE_DOMINANCE) {
        uploadFeatureDominance({
          file: formData
        })
      } else {
        alertMessage("Please select proper format of file!", "error");
      }
    } else if (extFile) {
      if (props.reportNameCode === REPORT_NAME.CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS) {
        uploadCCSVulnerabilityAnalysis({
          file: formData
        })
      } else if (props.reportNameCode === REPORT_NAME.CODE_AI_BIAS_ANALYSIS) {
        uploadCodeAIBiasAnalysis({
          file: formData
        })
      } else if (props.reportNameCode === REPORT_NAME.LEGISLATIVE_AI_RISK) {
        uploadCodeReview({
          file: formData
        })
      }
    } else {
      alertMessage("Please select proper format of file!", "error");
    }
  };

  const handleClose = () => props.setShow(false);

  const [fileName, setFileName] = useState("");

  // upload file...............................
  const fileHandler = (e: any) => {
    setFile(e.target.files[0]);
    if (props.reportNameCode === REPORT_NAME.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST) {
      props.setFileName(e.target.files[0].name);
    }
  };

  //DataPrivacyCompTestResponse.......
  useEffect(() => {
    if (uploadCCSVulnerabilityAnalysisResponse.isSuccess) {
      dispatch(setVulnerabilty(uploadCCSVulnerabilityAnalysisResponse));
      // props.setdatasource(uploadCCSVulnerabilityAnalysisResponse.data.data[0]);
      props.setShow(false);
      let selectedReportName = [];
      if (selectedReportNameL !== null) {
        selectedReportName = JSON.parse(selectedReportNameL);
      }
      selectedReportName.push(REPORT_NAME.CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS);
      const jsonString = JSON.stringify(selectedReportName);
      localStorage.setItem('selectedReportName', jsonString);

      // localStorage.setItem("selectedReportName", REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST);
    } else if (uploadCCSVulnerabilityAnalysisResponse.isError) {
      alertMessage("error while fetching Data", "error");
      props.setShow(false);
    }
  }, [uploadCCSVulnerabilityAnalysisResponse.isSuccess, uploadCCSVulnerabilityAnalysisResponse.isError]);

  //AIRegulationCompTestResponse.......
  useEffect(() => {
    if (uploadCodeAIBiasAnalysisResponse.isSuccess) {
      // dispatch(setCodeAIBiasAnalysis(uploadCodeAIBiasAnalysisResponse));

      if (uploadCodeAIBiasAnalysisResponse.data.data !== null) {
        props.setShowRiskBtn(true);
        dispatch(setCodeAIBiasAnalysis(uploadCodeAIBiasAnalysisResponse));

        // props.setCodeAiBiasAnalysisDataSource(uploadCodeAIBiasAnalysisResponse.data.data[0]);
        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.CODE_AI_BIAS_ANALYSIS);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = uploadCodeAIBiasAnalysisResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }

    } else if (uploadCodeAIBiasAnalysisResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }
  }, [uploadCodeAIBiasAnalysisResponse.isSuccess, uploadCodeAIBiasAnalysisResponse.isError]);

  useEffect(() => {
    if (uploadAiAlgAccReliabilityTestResponse.isSuccess) {
      if (uploadAiAlgAccReliabilityTestResponse.data !== null) {
        dispatch(setAiAlgAccReliabilityTest(uploadAiAlgAccReliabilityTestResponse));

        // let dataSetBiasTestArr = Object.entries(uploadAiAlgAccReliabilityTestResponse.data.result);
        // buildAiAlgAccReliabilityTest(uploadAiAlgAccReliabilityTestResponse.data.results);
        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = uploadAiAlgAccReliabilityTestResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }
    } else if (uploadAiAlgAccReliabilityTestResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }

  }, [uploadAiAlgAccReliabilityTestResponse.isSuccess, uploadAiAlgAccReliabilityTestResponse.isError])

  useEffect(() => {
    if (uploadFeatureDominanceResponse.isSuccess) {
      if (uploadFeatureDominanceResponse.data !== null) {
        dispatch(setFeatureDominance(uploadFeatureDominanceResponse));

        // let dataSetBiasTestArr = Object.entries(uploadFeatureDominanceResponse.data.result);
        // buildAiAlgAccReliabilityTest(uploadFeatureDominanceResponse.data.results);
        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.FEATURE_DOMINANCE);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = uploadFeatureDominanceResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }
    } else if (uploadFeatureDominanceResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }

  }, [uploadFeatureDominanceResponse.isSuccess, uploadFeatureDominanceResponse.isError])

  useEffect(() => {
    if (uploadCodeReviewResponse.isSuccess) {
      if (uploadCodeReviewResponse.data !== null) {
        dispatch(setCodeReviewData(uploadCodeReviewResponse.data));

        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.LEGISLATIVE_AI_RISK);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = uploadCodeReviewResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }
    } else if (uploadCodeReviewResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }

  }, [uploadCodeReviewResponse.isSuccess, uploadCodeReviewResponse.isError])

  return (
    <div>
      {/* <!-- Modal --> */}
      <Modal
        show={props.show}
        onHide={handleClose}
        className=""
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          <Modal.Title
            className="fw-bold text-primary"
            style={{ fontSize: "1rem" }}
          >
            {/* {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "} */}
            {props.reportName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            // validationSchema={formSchema}
            enableReinitialize={true}
            initialValues={{}}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => (
              <Form>
                <div className="text-center">
                  {(props.reportNameCode === REPORT_NAME.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST ||
                    props.reportNameCode === REPORT_NAME.FEATURE_DOMINANCE) ? (
                    <p>Allowed file format - CSV</p>
                  ) : null}
                  <input type="file" id="fileUpload" onChange={fileHandler} />
                </div>
                <hr />
                <div className="d-flex flex-row justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                  >
                    {/* {props.actionType === ACTION_TYPE.ADD_ACTION
                      ? "Save"
                      : "Update"} */}
                    Submit
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  {uploadCCSVulnerabilityAnalysisResponse.isLoading ||
                    uploadCodeAIBiasAnalysisResponse.isLoading ||
                    uploadAiAlgAccReliabilityTestResponse.isLoading ||
                    uploadFeatureDominanceResponse.isLoading ||
                    uploadCodeReviewResponse.isLoading ?
                    (
                      <Loading />
                    ) : (
                      ""
                    )}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default CyberSecAiSheildData;
