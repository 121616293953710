import Modal from "react-bootstrap/Modal";
import ReactApexChart from "react-apexcharts";

type Props = {
   setShow: any;
   show: any;
   reportName?: string;
   chartSeries: any;
   chartLables: any;
   series: any;
};

const BarGraphModal = (props: Props) => {

   const handleClose = () => props.setShow(false);

   const series = [
      {
         data:
            props.chartSeries &&
            props.chartSeries.map((item: any) => {
               return Number(item);
            })
         // valueChainCategoryCountDetails &&
         // valueChainCategoryCountDetails.map((item: any) => {
         //    return Number(item.count);
         // })
      }
   ];


   const chartOptions = {
      chart: {
         height: 350,
         // type: "bar",
         // events: {
         //    click: function (chart: any, w: any, e: any) {
         //       // console.log(chart, w, e)
         //    }
         // }
      },
      // colors: colors,
      plotOptions: {
         bar: {
            columnWidth: "45%",
            // distributed: true
         }
      },
      tooltip: {
         enabled: true,
         y: {
             formatter: undefined,
             title: {
                 formatter: (seriesName: any) => {
                  // console.log(seriesName)
                  return "Value:"
                 },
             },
         },
      },
      dataLabels: {
         enabled: false
      },
      legend: {
         show: false
      },
      xaxis: {
         categories: props.chartLables && props.chartLables
      },
      yaxis: {
         labels: {}
      }
   };

   return (
      <div>
         {/* <!-- Modal --> */}
         <Modal
            show={props.show}
            onHide={handleClose}
            className=""
            backdrop="static"
            keyboard={false}
            // dialogClassName="modal-90w"
            size="xl"
         >
            <Modal.Header closeButton>
               <Modal.Title
                  className="fw-bold text-primary"
                  style={{ fontSize: "1rem" }}
               >
                  {props.reportName}
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="overflow-scroll">
                  {/* <p className="chart-title">{props.reportName}</p> */}
                  <ReactApexChart
                     type="bar"
                     series={series}
                     options={chartOptions}
                     height={700}
                     width="100%"
                  />
               </div>
            </Modal.Body>
         </Modal>
      </div>
   );
};
export default BarGraphModal;
