import { useLocation } from "react-router-dom";
import { REPORT_TYPE } from "../../constant/reportType";
import DataGovernance from "../../component/DataGovernance";

type Props = {};

const AllAIGovernanceReports = (props: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const report = searchParams.get("report") !== null ? searchParams.get("report") : localStorage.getItem("selectedReport")

  if (report == REPORT_TYPE.DATA_GOVERNANCE) {
    return (
      <DataGovernance />
    )
  } else if (report == REPORT_TYPE.ASSESSMENT_AND_AUDITS) {
    return (
      <div>{REPORT_TYPE.ASSESSMENT_AND_AUDITS}</div>
      // <CyberSecAIShield />
    )
  } else if (report == REPORT_TYPE.RISK_MANAGEMENT) {
    return (
      // <BiasMitigatePro />
      <div>{REPORT_TYPE.RISK_MANAGEMENT}</div>
    )
  } else {
    return (
      <div>Something went wrong</div>
    )
  }

};
export default AllAIGovernanceReports;
