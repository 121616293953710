import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { cascadBaseUrl, userManagement, userManagementBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const faqsService = createApi({
    reducerPath: 'faqsService',
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ['FAQ'],
    endpoints: (builder) => ({
        getAllFAQs: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.faqsController.getAllFAQs}/${body.page}/${body.size}?orderBy=${body.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getFAQById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.faqsController.getFAQById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        saveFAQ: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.faqsController.saveFAQ}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateFAQ: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.faqsController.updateFAQ}/${body.payload.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteFAQ: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.faqsController.deleteFAQ}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllFAQsWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.faqsController.getAllFAQsWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        saveMemberShipDetail: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.membershiDetailController.saveMemberShipDetail}`,
                    method: 'POST',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
    })
  });
  export const {
    useGetAllFAQsMutation,
    useGetFAQByIdMutation,
    useSaveFAQMutation,
    useUpdateFAQMutation,
    useDeleteFAQMutation,
    useGetAllFAQsWithSearchMutation,
    useSaveMemberShipDetailMutation,
  } = faqsService;