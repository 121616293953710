export enum COLUMN_REPORTYPE {

  /** Below constants for Database reports **/

  SQL_VMS = "RTDSQLVMS",
  SQL_NAMES = "RTDSQLN",
  ALL_SQL_SERVERS_RG = "RTDASQLSRG",
  AL_SQL_SERVERS_LOCATIONS = "RTDASQLSLOC",
  ALL_DB = "RTDADB",
  ALL_DB_STATUS = "RTDADBS",
  ALL_DB_PRICING_TIER = "RTDADBPT",
  DB_CREATED_DETETED = "RTDDBCD",
  ALL_DB_BACKUP = "RTDADBB",
  ALL_DB_ELASTIC_POOL = "RTDADBEP",
  ALL_DB_BACKUP_ZONE = "RTDADBBZ",

  /** Below constants for DataSourceManagement reports **/
  VALUE_CHAIN_CATEGORY = "DSMVCC",
  VALUE_CHAIN = "DSMVC",
  DATA_PIPELINE_CATEGORY = "DSMDPC",
  DATE_PIPELINE = "DSMDP",
  DATA_SOURCE = "DSMDS",
  TERM_SET = "DSMTS",
  TERMS = "DSMT",
  VALUE_CHAIN_PARAGRAPH_EXTRACT = "DSVCPE",
  DATA_RECORDS = "DSDR",

  /** Below constants for Use Master reports **/
  USER_GROUP = "UMUG",
  USER_ROLE = "UMUR",
  USER = "USER",
  STATE = "STATE",
  COUNTRY = "COUNTRY",

  MODULE = "PMM",
  SUB_MODULE = "PMSM",
  FEATURE = "PMF",
  PACKAGE = "PMP"
}


export enum ACTION_TYPE {
  ADD_ACTION = "ADD_ACTION",
  UPDATE_ACTION = "UPDATE_ACTION"
}

export enum REPORT_TYPE {

  DATA_SOURCE_MANAGEMENT = "Data Source Management",
  USER_MASTER = "User Master",
  COMMON = "Common",
  AI_GOVERNANCE = "AI Governance",
  DATA_GOVERNANCE = "Data Governance",
  PRIVACYGUARD_PRO = "PrivacyGuard Pro",
  CYBERSECAI_SHIELD = "CyberSecAI Shield",
  BIASMITIGATE_PRO = "BiasMitigate Pro",
  PREDICTION_MODELS = "Prediction Models",
  ASSESSMENT_AND_AUDITS = "Assessment and Audits",
  RISK_MANAGEMENT = "Risk Management",
  PRICING_MODULE = "Pricing Module",
  MATCH_MAKER_MANAGMENT = "Match Maker Management",
  DOCUMENT_MANAGEMENT = "Document Management"

  // WVD = "WVD",
  // APP_SERVICE = "App service ",
  // NEW_SERVICE = "New Service ",
}

export enum REPORT_NAME {
  // Data Governance report names
  DATA_LINEAGE = "Data Lineage",
  
  // Assesment and audits report names
  ASSESSMENT_AND_AUDITS = 'Assessment and Audits',
 
  // Risk Management report names
  RISK_MANAGEMENT = 'Risk Management',
 
  // PrivacyGuard Pro report names
  DATA_PRIVACY_COMPLIANCE_TEST = "Data Privacy Compliance Test",
  DATA_PRIVACY_PENALTY_PREDICTION = "Data Privacy Penalty Prediction",
  SENSITIVE_AND_BIAS_DATA_IDENTIFICATION = "Sensitive and Bias Data Identification",
  AI_REGULATION_COMPLIANCE_TEST = "AI Regulation Compliance Test",
  EMPLOYMENT_BIAS = "Employment Bias",

  // CyberSecAI Shield report names
  CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS = "Code Cyber Security Vulnerability Analysis",
  AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST = "AI Algorithm Accuracy and Reliability Test",
  CODE_AI_BIAS_ANALYSIS = "Code AI Bias Analysis",
  FEATURE_DOMINANCE = "Feature Dominance",
  LEGISLATIVE_AI_RISK = "Legislative AI Risk",

  // BiasMitigate Pro report names
  UNSTRUCTURED_DATA_BIAS_TEST = "Unstructured Data Bias Test",
  PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY = "Project Code Bias and Vulnerability Summary",
  FAIRNESS_METRICS_FOR_MACHINE_LEARNING = "Fairness Metrics for Machine Learning Prediction",
  DATA_SET_BIAS_TEST = "Data Set Bias Test",
  TERM_COMPARISON_ANALYSIS = 'Term Comparison Analysis',
  TRUSTED_AI_DIMENSION_ANALYSIS = "Trusted AI Dimension Analysis",

  //Prediction Models report names
  REGULATION_PREDICTION = "Regulation Prediction",

  //For dashboard report
  REGULATORY_INSIGHT_DASHBOARD = 'Regulatory Insights Dashboard',

  // Data Source Management report names
  VALUE_CHAIN_CATEGORY = 'Value Chain Category',
  VALUE_CHAIN = 'Value Chain',
  DATA_PIPELINE_CATEGORY = 'Data Pipeline Category',
  DATA_PIPELINE = 'Data Pipeline',
  TERM_SET = 'Term Set',
  TERM = 'Term',
  DATA_RECORD = 'Data Record',
  VALUE_CHAIN_PARAGRAPH_EXTRACT = 'Value Chain Paragraph Extract',

  // User Master report names
  USER_GROUP = 'User Group',
  SUBSCRIBER = 'Subscriber',
  USERS = 'Users',
  USER_ACTIVITY = "User Activity",
 
  // Common report names
  COUNTRY = 'Country',
  STATE = 'State',
  
  //pricing module master report names
  MODULE = "Module",
  SUB_MODULE = "Sub Module",
  FEATURE = "Feature",
  PACKAGE = "Package",
  PACKAGE_FEATURE_MAPPING = "Package Feature Mapping",

  //FAQ
  FAQS = "FAQs",

  //Match Maker
  INDUSTRY = "Industry",
  SUB_INDUSTRY = "Sub Industry",
  ORGANIZATION_SEARCH = "Organization Search",
  DETAIL_ORGANIZATION_SEARCH = "Detail Organization Search",

  //Document
  SECTION_MASTER = "Section Master",
  DOCUMENT_CATEGORY = "Document Category",
  DOCUMENT_MASTER = "Document Master",
  DOCUMENT_CREATION = "Document Creation",
  PARAMETER = "Parameter",
  PARAMETER_CATEGORY = "Parameter Category",

  //button names
  KEYWORD_GROUPING = "Keyword grouping",
  CONSOLIDATED_KEYWORD_GROUPING = "Consolidated keyword grouping",
  DRIFT_AND_AUTO_ASSESSMENT = "Drift and Auto assessments",
  GLOBAL_DATA_PRIVACY_AI_REG_ACTS = "Global Data Privacy and AI Regulation Acts",
}

export enum ACT_NAME {
  // Global Data Privacy
  SINGAPORE = "Singapore Personal Data Protection Act",
  SINGAPORE_LINK = "/singapore",
  EUROPE = "Europe Data Privacy Act",
  EUROPE_LINK = "/europe",
  UK = "UK Data Privacy Act",
  UK_LINK = "/uk",
  CANADA = "CANADA Data Privacy Act",
  CANADA_LINK = "/canada",
  USA = "USA Data Privacy Act",
  USA_LINK = "/usa",
  GDPR = "EU GDPR Terms",
  GDPR_LINK= "/gdpr",
  // AI Regulation Acts
  EU_AI_ACT = "EU AI Act",
  EU_AI_ACT_LINK = "/eu-ai-act",
  SINGAPORE_AI_REG_ACT = "Singapore's AI Governance Framework Model",
  SINGAPORE_AI_REG_ACT_LINK = "/singapore-ai-act",
  TRUSTED_AI = "Trusted AI",
  TRUSTED_AI_LINK = "/trusted-ai-act",
  NIST_AI_RISK = "NIST AI Risk Management Framework Playbook",
  NIST_AI_RISK_LINK = "/nist-ai-risk",
  // AI Regulations
  COMPANION_OF_AI_GOV_FRAMEWORK = "Companion of AI Governance Framework",
  COMPANION_OF_AI_GOV_FRAMEWORK_LINK = "/companion-ai-gov-framework",
  MICRO_RES_AI_IMAPCT_ASS_GUIDE = "Microsoft Responsible AI Impact Assessment Guide",
  MICRO_RES_AI_IMAPCT_ASS_GUIDE_LINK = "/microsoft-res-ai-guide",
  MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2 = "Microsoft Responsible AI Impact Assessment Template 2",
  MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2_LINK = "/microsoft-res-ai-template",
  MICRO_RES_AI_STD = "Microsoft Responsible AI Standard",
  MICRO_RES_AI_STD_LINK = "/microsoft-res-ai-standard",
  MODEL_AI_GOV_FRAMEWORK_SEC_EDITION = "MODEL ARTIFICIAL INTELLIGENCE GOVERNANCE FRAMEWORK SECOND EDITION",
  MODEL_AI_GOV_FRAMEWORK_SEC_EDITION_LINK = "/model-ai-gov-framework",
  TRUSTWORTHY_AI_PLAYBOOK = "Trustworthy AI (TAI) Playbook",
  TRUSTWORTHY_AI_PLAYBOOK_LINK = "/tai-playbook",
  //AI Governance Framework
  SINGAPORE_AI_GOVERNANCE_FRAMEWORK = "Singapore AI Govenrance Framework",
  SINGAPORE_AI_GOVERNANCE_FRAMEWORK_LINK = "/singapore-ai-gov-framework",
  USA_AI_GOVERNANCE_FRAMEWORK = "USA AI Govenrance Framework",
  USA_AI_GOVERNANCE_FRAMEWORK_LINK = "/usa-ai-gov-framework",
  EUORPE_AI_REGULATIONS_ACT = "Europe AI Regulations Act",
  EUORPE_AI_REGULATIONS_ACT_LINK = "/europe-ai-regu-act",
  // Ai Regulations
  SINGAPORE_AI_REGULATION = "Singapore AI Regulations",
  SINGAPORE_AI_REGULATION_LINK = "/singapore-ai-regulations",
  SINGAPORE_PDPC_ACT = "Singapore PDPC Act",
  SINGAPORE_PDPC_ACT_LINK = "/singapore-pdpc-act",
}