import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface ResponsibilityState {
  data: [] | null | any;
  centerData: [] | null | any;
  objectData: [] | null | any;
  objectTypeData: [] | null | any;
  parameterCategoryData: [] | null | any;
  parameterData: [] | null | any;
}
const initialState: ResponsibilityState = {
  data: null,
  centerData: null,
  objectData: null,
  objectTypeData: null,
  parameterCategoryData: null,
  parameterData: null
};
export const responsibilitySlice = createSlice({
  name: 'responsibility',
  initialState,
  reducers: {
    setResponsibilityGroup: (state, action) => {
      state.data = action.payload.data;
    },
    setResponsibilityCenter: (state, action) => {
      state.centerData = action.payload.data;
    },
    setObjectData: (state, action) => {
      state.objectData = action.payload.data;
    },
    setObjectTypeData: (state, action) => {
      state.objectTypeData = action.payload.data;
    },
    setParameterCategoryData: (state, action) => {
      state.parameterCategoryData = action.payload;
    },
    setParameterData: (state, action) => {
      state.parameterData = action.payload;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setResponsibilityGroup, setResponsibilityCenter, setObjectData, 
  setObjectTypeData, setParameterCategoryData, setParameterData, reset } = responsibilitySlice.actions;
export const getAllResponsibilityGroupDetails = (state: RootState) => state.responsibility.data;
export const getAllResponsibilityCenterDetails = (state: RootState) => state.responsibility.centerData;
export const getAllObjectDetails = (state: RootState) => state.responsibility.objectData;
export const getAllObjectTypeDetails = (state: RootState) => state.responsibility.objectTypeData;
export const getAllParameterCategoryDetails = (state: RootState) => state.responsibility.parameterCategoryData;
export const getAllParameterDetails = (state: RootState) => state.responsibility.parameterData;
export default responsibilitySlice.reducer;