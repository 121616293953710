import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Table } from "antd";
import { REPORT_NAME } from "../../constant/reportType";
import { useSendEmailforExportMutation } from "../../redux-toolkit/services/sendEmailService";
import { alertMessage } from "../../utils/AlertService";
import { Button, Form } from "react-bootstrap";
import { BsDownload, BsEnvelope } from "react-icons/bs";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { Formik } from "formik";
import Loading from "../../component/Loading";
import { Excel } from "antd-table-saveas-excel";

type Props = {
    setShowReportModal: any;
    showReportModal: any;
    showKeyWordGroupingBtn?: any;
    filteredTermComparison: any;
    summReportName: any;
    reportName: string;
};

const TermComparisonSummaryReport = (props: Props) => {

    const localUserObj: any = getLocalStorageData("localUser");

    //state
    const [tableData, setTableData]: any = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedRecords, setSelectedRecords]: any = useState([]);
    const [showConfirmEmail, setShowConfirmEmail] = useState(false);

    const [sendEmail, sendEmailResponse] = useSendEmailforExportMutation();


    const handleClose = () => props.setShowReportModal(false);

    const handleEmailClose = () => setShowConfirmEmail(false)

    useEffect(() => {
        if (props.filteredTermComparison && props.filteredTermComparison.length > 0) {
            if (props.summReportName === REPORT_NAME.KEYWORD_GROUPING) {
                // Extract unique key terms
                const uniqueKeyTerms = Array.from(new Set(props.filteredTermComparison.map((item: any) => item.keyTerm)));

                // Generate columns dynamically for each key term
                const newColumns: any = [
                    {
                        title: 'Serial No.',
                        dataIndex: 'serialNo',
                        key: 'serialNo',
                    },
                    {
                        title: 'File Name',
                        dataIndex: 'fileName',
                        key: 'fileName',
                        render: (text: any) => {
                            const fileName = text.split('/').pop();
                            return <span>{fileName}</span>;
                        },
                    },
                    ...uniqueKeyTerms.map(keyTerm => ({
                        title: keyTerm,
                        dataIndex: keyTerm,
                        key: keyTerm,
                        width: 200
                    })),
                ];

                // Initialize table data with proper structure
                const newData = (props.filteredTermComparison).reduce((acc: any, cur: any, index: number) => {
                    const newRow: any = { fileName: cur.fileName, serialNo: index + 1 };
                    newRow[cur.keyTerm] = cur.frequency;
                    acc.push(newRow);
                    return acc;
                }, []);

                setColumns(newColumns);
                setTableData(newData);
            }

            if (props.summReportName === REPORT_NAME.CONSOLIDATED_KEYWORD_GROUPING) {

                let serialNo = 1;
                // Extract unique key terms
                const uniqueKeyTerms = Array.from(new Set(props.filteredTermComparison.map((item: any) => item.keyTerm)));

                // Initialize an object to store table data
                const tableDataMap: any = {};

                // Iterate over the filtered term comparison data to populate table data
                props.filteredTermComparison.forEach((item: any, index: number) => {
                    const { fileName, keyTerm, frequency }: any = item;
                    // If file name already exists, update its frequency for the respective key term
                    if (tableDataMap[fileName]) {
                        tableDataMap[fileName][keyTerm] = frequency;
                    } else {
                        // If file name doesn't exist, create a new entry with the file name and frequency for the respective key term
                        tableDataMap[fileName] = { fileName, serialNo: serialNo++ };
                        tableDataMap[fileName][keyTerm] = frequency;
                    }
                });

                // Generate columns dynamically for each key term
                const newColumns: any = [
                    {
                        title: 'Serial No.',
                        dataIndex: 'serialNo',
                        key: 'serialNo',
                    },
                    {
                        title: 'File Name',
                        dataIndex: 'fileName',
                        key: 'fileName',
                        render: (text: any) => {
                            const fileName = text.split('/').pop();
                            return <span>{fileName}</span>;
                        },
                    },
                    ...uniqueKeyTerms.map(keyTerm => ({
                        title: keyTerm,
                        dataIndex: keyTerm,
                        key: keyTerm,
                        width: 200
                    })),
                ];

                // Convert table data map to an array
                const newData: any = Object.values(tableDataMap);

                setColumns(newColumns);
                setTableData(newData);
            }
        }
    }, [props.filteredTermComparison]);

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            if (selectedRows.length > 0) {
                console.log(
                    `selectedRowKeys: ${selectedRowKeys}`,
                    "selectedRows: ",
                    selectedRows
                );
                setSelectedRecords(selectedRows)
            } else {
            }
        },
    };

    function ensureKeysInDataSource(dataSource: any, columns: any) {
        dataSource.forEach((item: any) => {
            columns.forEach((column: any) => {
                if (!(column.dataIndex in item)) {
                    item[column.dataIndex] = "-";
                }
            });
        });
    }

    const handleSendEmail = (values: any) => {
        // let columns1: any;
        let dataSource: any;
        let fileName: any;
        let filePaths: any;

        console.log(localUserObj)
        if (props.reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
            fileName = REPORT_NAME.TERM_COMPARISON_ANALYSIS + props.summReportName;
            // Function to ensure datasource has all keys specified in columns array
            ensureKeysInDataSource(selectedRecords, columns);
            // columns1 = columns;
            dataSource = selectedRecords;
        }
        if (values.sendWithResume != "") {

            filePaths = selectedRecords.map((obj: any) => obj.fileName);
        }

        if (dataSource.length > 0) {
            sendEmail({
                payload: {
                    columns: columns,
                    dataSource: dataSource,
                    fileName: fileName,
                    to: localUserObj.username,
                    filePaths: filePaths,
                },
                token: localUserObj.token
            })
        } else {
            alertMessage("Please make new request first!", "error");
        }
    };

    useEffect(() => {
        if (sendEmailResponse.isSuccess) {
            setShowConfirmEmail(false);
            props.setShowReportModal(false);
            alertMessage("Emaile sent successfully", "success");
        } else if (sendEmailResponse.isError) {
            alertMessage("Error while sending email", "error");
        }
    }, [sendEmailResponse.isSuccess, sendEmailResponse.isError]);


    const handleExportClick = () => {
        let columns1: any;
        let dataSource: any;
        let fileName: any;
        if (props.reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
            fileName = REPORT_NAME.TERM_COMPARISON_ANALYSIS + props.summReportName;
            // Function to ensure datasource has all keys specified in columns array
            // ensureKeysInDataSource(tableData, columns);
            columns1 = columns;
            dataSource = tableData;
        }
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns)
            .addDataSource(dataSource, {
                str2Percent: true
            })
            .saveAs(`${fileName}.xlsx`);
    };

    return (
        <div>
            {/* <!-- Modal --> */}
            <Modal
                show={props.showReportModal}
                onHide={handleClose}
                className=""
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between w-100">
                        <Modal.Title
                            className="fw-bold text-primary "
                            style={{ fontSize: "1rem" }}
                        >
                            {props.summReportName}
                        </Modal.Title>
                        <div className="d-flex">
                            {props.summReportName === REPORT_NAME.CONSOLIDATED_KEYWORD_GROUPING && (
                                <Button
                                    variant="primary"
                                    className="d-flex align-items-center mx-2"
                                    onClick={() => {
                                        if (selectedRecords.length > 0) {
                                            setShowConfirmEmail(true);

                                        } else {
                                            alertMessage("Please select record", "error")
                                        }
                                    }}
                                >
                                    <BsEnvelope size={16} className="me-2" />
                                    Email
                                </Button>
                            )}
                            <Button
                                variant="primary"
                                className="d-flex align-items-center mx-2"
                                onClick={handleExportClick}
                            >
                                <BsDownload size={16} className="me-2" />
                                Export
                            </Button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="overflow-auto">
                    {props.showKeyWordGroupingBtn && (
                        <>
                            <div>
                                <Table
                                    rowKey={"serialNo"}
                                    bordered={true}
                                    dataSource={tableData}
                                    columns={columns}
                                    rowSelection={props.summReportName === REPORT_NAME.CONSOLIDATED_KEYWORD_GROUPING ? rowSelection : undefined}
                                    rowClassName={(record: any, index: any) => {
                                        if (record.serialNo === 1 && props.summReportName === REPORT_NAME.CONSOLIDATED_KEYWORD_GROUPING) {
                                            return 'first-file-name-row';
                                        }
                                        // Default row class if not the first occurrence
                                        return '';
                                    }}
                                    pagination={false}
                                />
                            </div>
                        </>
                    )}
                    <div className="d-flex flex-row justify-content-end">
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            {sendEmailResponse.isLoading ? (
                <Loading />
            ) : (
                ""
            )}
            {showConfirmEmail && (
                <Modal
                    show={showConfirmEmail}
                    onHide={handleEmailClose}
                    className=""
                    backdrop="static"
                    keyboard={false}
                    size="sm"
                // dialogClassName="modal-50w"
                >
                    <Modal.Header closeButton>
                        <Modal.Title
                            className="fw-bold text-primary "
                            style={{ fontSize: "1rem" }}
                        >
                            Send email
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="overflow-auto">
                        <div className="d-flex align-items-center">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    email: "",
                                    sendWithResume: "",
                                }}
                                onSubmit={handleSendEmail} >
                                {({ values, handleChange, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div className="">
                                            <input
                                                className="form-control form-control-sm w-20"
                                                type="email"
                                                placeholder="Enter email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                            />
                                            <div className="mt-2">
                                                <input type="checkbox"
                                                    id="sendWithResume"
                                                    name="sendWithResume"
                                                    value={values.sendWithResume}
                                                    onChange={handleChange}
                                                // className={`form-check-input ${errors.termsAndConditionsCheckbox ? 'is-invalid' : ''}`}
                                                />
                                                <label htmlFor="sendWithResume" className="ms-1">
                                                    Send with resume
                                                </label>
                                            </div>
                                            <div className="d-flex flex-row justify-content-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                                                >
                                                    Send
                                                </button>
                                                {/* <button
                                                    type="button"
                                                    className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </button> */}
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
};
export default TermComparisonSummaryReport;
