import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { authService } from "./services/authService";
import authReducer from "./slices/authSlice";
import usersReducer from "./slices/usersSlice";
import { usersService } from "./services/usersService";
import statesReducer from "./slices/statesSlice";
import { stateService } from "./services/stateService";
import countryReducer from "./slices/countrySlice";
import { countryService } from "./services/countryService";
import cityReducer from "./slices/citySlice";
import { cityService } from "./services/cityService";
import allValueChainCategoriesReducer from "./slices/allValueChainCategorySlice";
import { allValueChainCategoriesService } from "./services/allValueChainCategoriesService";
import { allValueChainsService } from "./services/allValueChainsService";
import allValueChainsReducer from "./slices/allValueChainsSlice";
import { allTermService } from "./services/allTermService";
import allTermReducer from "./slices/allTermSlice";
import { allTermSetService } from "./services/allTermSetService";
import allTermSetReducer from "./slices/allTermSetSlice";
import { allDataPipelinesCategoriesService } from "./services/allDataPipelinesCategoriesService";
import allDataPipelinesCategoriesReducer from "./slices/allDataPipelinesCategoriesSlice";
import { allDataPipelinesService } from "./services/allDataPipelinesService";
import allDataPipelinesReducer from "./slices/allDataPipelinesSlice";
import allUserRoleReducer from "./slices/allUserRoleSlice";
import allUserGroupReducer from "./slices/allUserGroupSlice";
import { allUserRoleService } from "./services/userRoleService";
import { allUserGroupService } from "./services/userGroupService";
import allValueChainParagraphExtractReducer from "./slices/allValueChainParagraphExtractSlice";
import { allValueChainParagraphExtractService } from "./services/allValueChainParagraphExtractService";
import { allDataRecordsService } from "./services/allDataRecordsService";
import allDataRecordsReducer from "./slices/allDataRecordsSlice";
import { allUserService } from "./services/allUserService";
import allUserReducer from "./slices/allUserSlice";
import { webSearchService } from "./services/webSearchService";
import gdprReducer from "./slices/gdprSlice";
import { gdprService } from "./services/gdprService";
import { vulnerabiltyService } from "./services/vulnerabiltyService";
import vulnerabiltyReducer from "./slices/vulnerabiltySlice";
import fastApiReducer from "./slices/fastApiSlice";
import { fastApiService } from "./services/fastApiService";
import auditLogReducer from "./slices/auditLogSlice";
import { auditLogService } from "./services/auditLogService";
import responsibilityReducer from "./slices/responsibilitySlice";
import { responsibilityService } from "./services/responsibilityService";
import companyReducer from "./slices/companySlice"
import { companyService } from "./services/companyService";
import { textMatcherService } from "./services/textMatcherService";
import textMatcherReducer from "./slices/textMatcherSlice";
import { permissionService } from "./services/permissionService";
import permissionReducer from "./slices/permissionSlice";
import packageMasterReducer from "./slices/packageMasterSlice";
import { packageMasterService } from "./services/packageMasterService";
import moduleReducer from "./slices/moduleSlice";
import { moduleService } from "./services/moduleService";
import subModuleReducer from "./slices/subModuleSlice";
import { subModuleService } from "./services/subModuleService";
import { featureService } from "./services/featureService";
import featureReducer from "./slices/featureSlice";
import { packageFeatureMappingService } from "./services/packageFeatureMappingService";
import packageFeatureMappingReducer from "./slices/packageFeatureMappingSlice";
import faqsReducer from "./slices/faqsSlice";
import { faqsService } from "./services/faqsService";
import industryMasterReducer from "./slices/industryMasterSlice";
import { industryMasterService} from "./services/industryMasterService";
import subIndustryReducer from "./slices/subIndustrySlice";
import { subIndustryService } from "./services/subIndustryService";
import webSearchReducer from "./slices/webSearchSlice";
import { sendEmailService } from "./services/sendEmailService";
import sectionMasterReducer from "./slices/sectionMasterSlice";
import { sectionMasterService } from "./services/sectionMasterService";
import documentCategoryReducer from './slices/documentCategorySlice';
import { documentCategoryService } from "./services/documentCategoryService";
import documentMasterReducer from './slices/documentMasterSlice';
import { documentMasterService } from "./services/documentMasterService";
import { documentCreationService } from "./services/documentCreationService";
import documentCreationReducer from "./slices/documentCreationSlice";

export const store = configureStore({
  reducer: {
    // Slices
    auth: authReducer,
    users: usersReducer,
    states: statesReducer,
    country: countryReducer,
    city: cityReducer,
    allValueChainCategories: allValueChainCategoriesReducer,
    allValueChains: allValueChainsReducer,
    allTerm: allTermReducer,
    allTermSet: allTermSetReducer,
    allDataPipelinesCategories: allDataPipelinesCategoriesReducer,
    allDataPipelines: allDataPipelinesReducer,
    allUserRole: allUserRoleReducer,
    allUserGroup: allUserGroupReducer,
    allUser: allUserReducer,
    allValueChainParagraphExtract: allValueChainParagraphExtractReducer,
    allDataRecords: allDataRecordsReducer,
    gdpr: gdprReducer,
    vulnerabilty: vulnerabiltyReducer,
    fastApi: fastApiReducer,
    auditLog: auditLogReducer,
    responsibility: responsibilityReducer,
    company: companyReducer,
    textMatcher: textMatcherReducer,
    permission: permissionReducer,
    packageMaster: packageMasterReducer,
    module: moduleReducer,
    subModule: subModuleReducer,
    feature: featureReducer,
    packageFeatureMapping: packageFeatureMappingReducer,
    faqs: faqsReducer,
    industryMaster: industryMasterReducer,
    subIndustry: subIndustryReducer,
    webSearch: webSearchReducer,
    sectionMaster: sectionMasterReducer,
    documentCategory: documentCategoryReducer,
    documentMaster: documentMasterReducer,
    documentCreation: documentCreationReducer,

    // Services
    [authService.reducerPath]: authService.reducer,
    [usersService.reducerPath]: usersService.reducer,
    [stateService.reducerPath]: stateService.reducer,
    [countryService.reducerPath]: countryService.reducer,
    [cityService.reducerPath]: cityService.reducer,
    [allValueChainCategoriesService.reducerPath]: allValueChainCategoriesService.reducer,
    [allValueChainsService.reducerPath]: allValueChainsService.reducer,
    [allTermService.reducerPath]: allTermService.reducer,
    [allTermSetService.reducerPath]: allTermSetService.reducer,
    [allDataPipelinesCategoriesService.reducerPath]:
      allDataPipelinesCategoriesService.reducer,
    [allDataPipelinesService.reducerPath]: allDataPipelinesService.reducer,
    [allUserRoleService.reducerPath]: allUserRoleService.reducer,
    [allUserGroupService.reducerPath]: allUserGroupService.reducer,
    [allValueChainParagraphExtractService.reducerPath]:
      allValueChainParagraphExtractService.reducer,
    [allDataRecordsService.reducerPath]: allDataRecordsService.reducer,
    [allUserService.reducerPath]: allUserService.reducer,
    [webSearchService.reducerPath]: webSearchService.reducer,
    [gdprService.reducerPath]: gdprService.reducer,
    [vulnerabiltyService.reducerPath]: vulnerabiltyService.reducer,
    [fastApiService.reducerPath]: fastApiService.reducer,
    [auditLogService.reducerPath]: auditLogService.reducer,
    [responsibilityService.reducerPath]: responsibilityService.reducer,
    [companyService.reducerPath]: companyService.reducer,
    [textMatcherService.reducerPath]: textMatcherService.reducer,
    [permissionService.reducerPath]: permissionService.reducer,
    [packageMasterService.reducerPath]: packageMasterService.reducer,
    [moduleService.reducerPath]: moduleService.reducer,
    [subModuleService.reducerPath]: subModuleService.reducer,
    [featureService.reducerPath]: featureService.reducer,
    [packageFeatureMappingService.reducerPath]: packageFeatureMappingService.reducer,
    [faqsService.reducerPath]: faqsService.reducer,
    [industryMasterService.reducerPath]: industryMasterService.reducer,
    [subIndustryService.reducerPath]: subIndustryService.reducer,
    [sendEmailService.reducerPath]: sendEmailService.reducer,
    [sectionMasterService.reducerPath]: sectionMasterService.reducer,
    [documentCategoryService.reducerPath]: documentCategoryService.reducer,
    [documentMasterService.reducerPath]: documentMasterService.reducer,
    [documentCreationService.reducerPath]: documentCreationService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authService.middleware)
      .concat(usersService.middleware)
      .concat(stateService.middleware)
      .concat(countryService.middleware)
      .concat(cityService.middleware)
      .concat(allValueChainCategoriesService.middleware)
      .concat(allValueChainsService.middleware)
      .concat(allTermService.middleware)
      .concat(allTermSetService.middleware)
      .concat(allDataPipelinesCategoriesService.middleware)
      .concat(allDataPipelinesService.middleware)
      .concat(allUserRoleService.middleware)
      .concat(allUserGroupService.middleware)
      .concat(allValueChainParagraphExtractService.middleware)
      .concat(allDataRecordsService.middleware)
      .concat(allUserService.middleware)
      .concat(webSearchService.middleware)
      .concat(gdprService.middleware)
      .concat(vulnerabiltyService.middleware)
      .concat(fastApiService.middleware)
      .concat(auditLogService.middleware)
      .concat(responsibilityService.middleware)
      .concat(companyService.middleware)
      .concat(textMatcherService.middleware)
      .concat(permissionService.middleware)
      .concat(packageMasterService.middleware)
      .concat(moduleService.middleware)
      .concat(subModuleService.middleware)
      .concat(featureService.middleware)
      .concat(packageFeatureMappingService.middleware)
      .concat(faqsService.middleware)
      .concat(industryMasterService.middleware)
      .concat(subIndustryService.middleware)
      .concat(sendEmailService.middleware)
      .concat(sectionMasterService.middleware)
      .concat(documentCategoryService.middleware)
      .concat(documentMasterService.middleware)
      .concat(documentCreationService.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
