import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';

type Props = {};

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const date = new Date();
  const dayOfDate = format(new Date(date), 'dd');
  const monthName = format(date, 'LLL');

  const customerName = new URLSearchParams(window.location.search).get('name');
  const customerAddress = new URLSearchParams(window.location.search).get('address');
  const orgName = new URLSearchParams(window.location.search).get('orgName');

  useEffect(() => {
    localStorage.setItem("routeTo", "terms-and-conditions");
  }, []);

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
          <div className="container-fluid">
            <a className="navbar-brand" onClick={() => navigate("/home")}>
              <img
                src={require("../../assets/logo-blue.png")}
                alt="Bootstrap"
                width="100"
                height="40"
              />
            </a>
          </div>
        </nav>
      </header>

      <div className="px-5">
        <div className="row pb-5 align-items-center">
          <div className="flex-lg-row main align-items-center d-flex w-100">
            <div className="bg-cm-light pt-5 col-lg-12 pr-lg-5 mb-5 mb-md-0">
              {/* about us */}
              <div className="about">
                <h1 className="text-cm-primary1 fw-bold">
                  BETA TEST AGREEMENT
                </h1>
                <p className="">
                  THIS BETA AGREEMENT (this “Agreement”) is made and entered into as of the
                  <span className="text-cm-primary1"> {dayOfDate}</span> day of
                  <span className="text-cm-primary1"> {monthName}</span> ,
                  2023 (“Effective Date”) between Capitol Tunnel Inc, having office at 11490,
                  Commerce Park Drive, Reston, Viriginia - 2019 and the user. By accessing and using this website or signing up for our services, you acknowledge and agree
                  to this 'Beta Test Agreement'. This agreement is for beta user experiences and testing and does
                  not construe ownership or license of the product. If you have any questions or concerns,
                  please contact us at info@capitoltunnels.ai
                  {/* (“Company”) a Delaware corporation,
                  and <span className="text-cm-primary1"> {customerName !== "undefined" ? customerName : "____________ "}</span> 
                  (“Beta Customer”), a <span className="text-cm-primary1"> {customerAddress !== "undefined" ? customerAddress : "____________"}</span> corporation, located at 
                  <span className="text-cm-primary1"> {orgName !== "undefined" ? orgName : "____________"}</span>. 
                  Beta Customer agrees with Company as follows:
                  */}
                  
                </p>
                <p>
                  The Company has developed certain product and want the Beta Customer to test the product.
                </p>
                <p className="para-size text-cm-primary1 fw-bold">
                  DEFINITIONS
                </p>
                <p>
                  1) COMPANY: Capitol Tunnels AI, Inc., a Delaware Corporation having an office at 11490 Commerce Park Drive, Reston, Virginia, 20191
                </p>
                <p>
                  2) Beta Customer (or, Customer): Person, Individual Representative, Organization, or Corporation signing up to use and evaluate the Capitol Tunnels AI, Inc. Reguvista beta product
                </p>
                <p className="para-size text-cm-primary1 fw-bold">
                  NOW THE PARTIES AGREE AS FOLLOWS:
                </p>
                <p>
                  <span className="text-cm-primary1 fw-bold">1. BETA PRODUCT : </span>
                  Company will provide to Beta Customer the development version of its Reguvista
                  (the "Product") and supporting components for development and evaluation purposes only.
                  The Product will be provided to the Beta Customer for a minimum of 30 days following the
                  Operational Date and thereafter may be removed at the option of Company.
                  Beta Customer agrees to meet or speak with Company on a regular basis to provide Company with the
                  feedback, including suggestions for improving on the Product and to identify problems encountered.
                  Beta Customer will keep a log of the Product use and note comments there.
                  Beta Customer will also fill out reasonable survey forms on a weekly  basis and email those to a
                  designated contact at the Company.
                </p>
                <p>
                  <span className="text-cm-primary1 fw-bold">2. OWNERSHIP : </span>
                  All title, interest, and ownership rights in and to the Beta Product and associated documentation,
                  including any improvements, modifications, and enhancements made thereto, are and shall remain in
                  Company or Company’s partners. Except for those rights expressly granted herein, no other rights
                  are granted, either express or implied, to Beta Customer.
                </p>
                <p>
                  <span className="text-cm-primary1 fw-bold">3. CONFIDENTIALITY : </span>
                  <span className="fw-bold">a)</span> Beta Customer agrees not to use (except for Company’s benefit)
                  or divulge to anyone either during the term of this Agreement or thereafter any of Company’s
                  trade secrets or other proprietary data or information of any kind whatsoever acquired by
                  Beta Customer in connection with use of the Product. Beta Customer agrees to clearly inform all
                  potential users of the Product of the need for confidentiality. Beta Customer further agrees that
                  upon completion or termination of this Agreement, Beta Customer will turn over to the Company any
                  notebook, log files, surveys, data, computer files, information or other material acquired or
                  compiled by the Beta Customer in carrying out the terms of the Agreement. All such material and the
                  information contained therein shall be the sole property of Company. However, the Beta
                  Customer may keep one copy of such material for archival purposes. Beta Customer agrees
                  not to discuss with competitors or potential competitors of Company and to keep confidential
                  any of the designs, inventions, ideas, and know-how related to the Product acquired by
                  Beta Customer during the course of the development relationship, all of which shall be the
                  sole property of Company.  Beta Customer agrees to exercise its best efforts in not allowing
                  competitors and potential competitors to gain access to the sites where Company equipment is
                  being tested during the course of the development relationship.
                  <span className="fw-bold"> b)</span> Beta Customer represents that its performance of the terms of
                  this Agreement does not and will not conflict with the terms of any agreement to keep in confidence
                  proprietary information and trade secrets acquired in confidence or in trust from a third party prior
                  to entering into this development relationship with Company. Beta Customer will not disclose to Company,
                  or induce Company to use, any confidential or proprietary information or material belonging to any third
                  party.
                </p>
                <p>
                  <span className="text-cm-primary1 fw-bold">4. NON COMPETE : </span>
                  Beta Customer represents that it is not presently working with any entity that manufactures or sells
                  products competitive with those of Company.  During the course of the 12 months beginning with the
                  execution of this agreement, Beta Customer agrees not to evaluate or develop a competitive Product.
                </p>
                <p>
                  <span className="text-cm-primary1 fw-bold">5. TESTING PROCESS : </span>
                  Beta Customer agrees to use any software provided by Company relating to the operation,
                  information storage and retrieval, record keeping and communication of the Product only in
                  the manner described in the written materials accompanying the Product. Beta Customer agrees not to
                  use it in any other way. Beta Customer agrees that the design, structure, and organization of the
                  Product is a valuable trade secret and agrees to protect it as it would other copyrighted material.
                  Company owns the Product and any software provided with it.
                </p>
                <p>
                  <span className="text-cm-primary1 fw-bold">6. LICENSE TO USE TEST RESULTS : </span>
                  Beta Customer hereby grants to Company the royalty-free right and license to utilize the Product
                  to collect information (the "Information") with regard to the use of the Product provided that
                  Company shall have no right to use any personal identifying information (such as name, address,
                  telephone number, or social security number). All Information shall be owned by Company and Company
                  shall have the right to use the Information for any purpose. Company agrees to keep confidential
                  the name of Beta Customer in connection with the Information unless otherwise agreed. Company has
                  the  right to publish use verbatims, product usage data, and client testimonials in our PR and
                  marketing materials.  Client agrees to waive media rights and ownership associated with usage
                  data and that we are free to publish this material. We can also state that any material that
                  identifies affiliation or organization will be subject to approval by Customer.
                </p>
                <p>
                  <span className="text-cm-primary1 fw-bold">7. </span>
                  THE BETA PRODUCT MAY CONTAIN ERRORS AND IS PROVIDED FOR LIMITED EVALUATION ONLY.
                  THE BETA PRODUCT IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED,
                  STATUTORY, OR OTHERWISE. CAPITOLTUNNELS SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY,
                  NONINFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.
                </p>
                <p>
                  <span className="text-cm-primary1 fw-bold">8. JURISDICTION : </span>
                  This agreement is governed by the laws of the United States of America, and Courts in Reston,
                  Virginia shall have Jurisdiction on all matters arising out of this agreement.
                </p>
                <p>
                  <span className="text-cm-primary1 fw-bold">9. NO ASSIGNMENT : </span>
                  Beta Customer shall not assign this agreement to any other party under any circumstances whatsoever.
                </p>
                <p>
                  <span className="text-cm-primary1 fw-bold">10. ENTIRE AGREEMENT : </span>
                  This is the entire agreement between the parties relating to the subject matter hereof.
                  No waiver or modification of this Agreement shall be valid unless in writing signed by each party.
                  If any provision of this Agreement is held by a court of competent jurisdiction to be contrary to law,
                  the remaining provisions of this Agreement shall remain in full force and effect
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="text-align-center">
        <p className="text-cm-dark fs-6">
          Capitol Tunnels.ai &#169; 2023
        </p>
      </footer>
    </>
  );
};
export default TermsAndConditions;
