import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import { ACTION_TYPE, REPORT_NAME } from "../../constant/reportType";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { alertMessage } from "../../utils/AlertService";
import { useAppSelector } from "../../redux-toolkit/hooks";
import { useGetDetailSearchMutation, useSearchForCompanyMutation } from "../../redux-toolkit/services/webSearchService";
import { getAllcountryDetail, setcountry } from "../../redux-toolkit/slices/countrySlice";
import { getFindCityByStateIdDetail } from "../../redux-toolkit/slices/citySlice";
import Loading from "../../component/Loading";
import { Select } from "antd";
import { getFindStateByCountryNameDetail, setStates } from "../../redux-toolkit/slices/statesSlice";
import { useGetAllCountryMutation } from "../../redux-toolkit/services/countryService";
import { useGetStateMutation } from "../../redux-toolkit/services/stateService";
import { useGetAllCitiesMutation } from "../../redux-toolkit/services/cityService";
import { useGetAllIndustryMutation, useGetIndustryByIdMutation, useSaveIndustryMutation, useUpdateIndustryMutation } from "../../redux-toolkit/services/industryMasterService";
import { useFindAllSubIndustryMutation, useGetSubIndustryByIdMutation, useSaveSubIndustryMutation, useUpdateSubIndustryMutation } from "../../redux-toolkit/services/subIndustryService";
import { getAllIndustryDetails, setAllIndustry } from "../../redux-toolkit/slices/industryMasterSlice";
import { getAllSubIndustryDetails, setAllSubIndustry } from "../../redux-toolkit/slices/subIndustrySlice";
import { setDetailOrganizationSearch, setSearchCompany } from "../../redux-toolkit/slices/webSearchSlice";
import { useTermComparisonAnalysisMutation } from "../../redux-toolkit/services/textMatcherService";
import { setTermComparisonAnalysis, setTermComparisonAnalysisWithTermFile } from "../../redux-toolkit/slices/textMatcherSlice";

const { Option } = Select;

type Props = {
  setShow: any;
  show: any;
  actionType: ACTION_TYPE;
  data?: any;
  setReportData?: any;
  getAllReportAsync: () => void;
  updateAllReportAsync: (values: any) => void;
  reportType?: any;
  reportName?: string;
  isCategory?: boolean;
  reportNameCode?: string;
  setCall?: any;
  call?: any;
  setFileName: any;
};

const MatchMakerManagementData = (props: Props) => {
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  const selectedReportNameL: any = localStorage?.getItem('selectedReportName');

  //selector
  const allCountries: any = useAppSelector(getAllcountryDetail);
  const allCities: any = useAppSelector(getFindCityByStateIdDetail);
  const allStates: any = useAppSelector(getFindStateByCountryNameDetail);
  const allIndustryData: any = useAppSelector(getAllIndustryDetails);
  const allSubIndustryData: any = useAppSelector(getAllSubIndustryDetails);

  //state
  const [selectData, setSelectData]: any = useState();
  const [getByIdData, setGetByIdData]: any = useState();
  const [country, setCountry]: any = useState();
  const [city, setCity]: any = useState();
  const [state, setState]: any = useState();
  const [industry, setIndustry]: any = useState();
  const [subIndustry, setSubIndustry]: any = useState();
  const [selectedCountry, setSelectedCountry]: any = useState();
  const [selectedState, setSelectedState]: any = useState();
  const [file, setFile]: any = useState<File | null>(null);
  const [termFile, setTermFile]: any = useState<File | null>(null);
  const [isTerm, setIsTerm] = useState(false);

  //getData
  const [getCountry, getCountryResponse] = useGetAllCountryMutation();
  const [getState, getStateResponse] = useGetStateMutation();
  const [getCities, getCitiesResponse] = useGetAllCitiesMutation();
  const [getAllIndustry, industryResponse] = useGetAllIndustryMutation();
  const [getAllSubIndustry, subIndustryResponse] = useFindAllSubIndustryMutation();
  const [searchForCompany, searchForCompanyResponse]: any = useSearchForCompanyMutation();
  const [detailOrganizationSearch, detailOrganizationSearchResponse]: any = useGetDetailSearchMutation();

  //save
  const [saveIndustry, saveIndustryResponse] = useSaveIndustryMutation();
  const [saveSubIndustry, saveSubIndustryResponse] = useSaveSubIndustryMutation();

  //update
  const [updateIndustry, updateIndustryResponse] = useUpdateIndustryMutation();
  const [updateSubIndustry, updateSubIndustryResponse] = useUpdateSubIndustryMutation();

  //getById mutation
  const [getIndustryById, getIndustryByIdResponse] = useGetIndustryByIdMutation();
  const [getSubIndustryById, getSubIndustryByIdResponse] = useGetSubIndustryByIdMutation();
  const [uploadTermComparisonAnalysis, uploadTermComparisonAnalysisResponse] = useTermComparisonAnalysisMutation();

  const [selectedValue, setSelectedValue] = useState("");

  const [selectedCountryId, setSelectedCountryId] = useState(
    (props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "user") ||
      props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH
      ? props.data.countryId
      : ""
  );

  const [selectedStateId, setSelectedStateId] = useState(
    (props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "user") ||
      props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH
      ? props.data.stateId
      : ""
  );

  const [selectedIndustry, setSelectedIndustry] = useState(
    props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH
      ? props.data.industryId
      : ""
  );

  const [industryName, setIndustryName] = useState();

  useEffect(() => {
    if (props.reportNameCode === REPORT_NAME.SUB_INDUSTRY ||
      props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH ||
      props.reportNameCode === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) {
      getAllIndustry({
        page: 0,
        size: 200,
        sort: "id",
      });
    }
  }, [])

  useEffect(() => {
    if (props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH ||
      props.reportNameCode === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH
    ) {
      getCountry({ page: 0, size: 200, sort: "id" });
      getAllSubIndustry({});
    }
  }, []);

  useEffect(() => {
    if (getCountryResponse.isSuccess) {
      dispatch(setcountry(getCountryResponse));
    } else if (getCountryResponse.isError) {
      let response: any = getCountryResponse.error;
      alertMessage(response, "error");
    }
  }, [getCountryResponse.isSuccess, getCountryResponse.isError]);

  useEffect(() => {
    if (getStateResponse.isSuccess) {
      dispatch(setStates(getStateResponse));
    } else if (getStateResponse.isError) {
      let response: any = getStateResponse.error;
      alertMessage(response, "error");
    }
  }, [getStateResponse.isSuccess, getStateResponse.isError]);

  useEffect(() => {
    if (getCitiesResponse.isSuccess) {
      dispatch(setCity(getCitiesResponse));
    } else if (getCitiesResponse.isError) {
      let response: any = getCitiesResponse.error;
      alertMessage(response, "error");
    }
  }, [getCitiesResponse.isSuccess, getCitiesResponse.isError]);

  //get allindustry.......
  useEffect(() => {
    if (industryResponse.isSuccess) {
      dispatch(setAllIndustry(industryResponse));
    } else if (industryResponse.isError) {
      alertMessage("Error while fetching industry", "error");
    }
  }, [industryResponse.isSuccess, industryResponse.isError]);

  useEffect(() => {
    if (subIndustryResponse.isSuccess) {
      dispatch(setAllSubIndustry(subIndustryResponse));
    } else if (subIndustryResponse.isError) {
      alertMessage("Error while fetching sub industry", "error");
    }
  }, [subIndustryResponse.isSuccess, subIndustryResponse.isError]);

  useEffect(() => {
    if (props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH ||
      props.reportNameCode === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH
    ) {
      if (allIndustryData) {
        const allIndustryDataAsc = [...allIndustryData].sort((a, b) => a.id - b.id);
        setIndustry(allIndustryDataAsc);
      }
      if (allSubIndustryData) {
        const allSubIndustryDataAsc = [...allSubIndustryData].sort((a, b) => a.id - b.id);
        setSubIndustry(allSubIndustryDataAsc);
      }
    }
  }, [allIndustryData, allSubIndustryData]);

  useEffect(() => {
    if (props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH ||
      props.reportNameCode === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH
    ) {
      if (allCountries) {
        setCountry(allCountries);
      }
      if (allStates) {
        setState(allStates);
      }
      if (allCities) {
        setCity(allCities);
      }
    }
  }, [allCountries, allStates, allCities]);

  // handleSubmit...........
  const handleSubmit = (values: any) => {
    if (props.actionType === ACTION_TYPE.ADD_ACTION) {
      if (props.reportNameCode === REPORT_NAME.INDUSTRY) {
        saveIndustry({
          payload: {
            isActive: "Y",
            id: 0,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === REPORT_NAME.SUB_INDUSTRY) {
        saveSubIndustry({
          payload: {
            status: true,
            id: 0,
            name: values.name,
            industryId: selectedValue,
            industryName: industryName,
          },
        });
      } else if (props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH) {

        const fileName = file?.name;
        const extension = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(extension, fileName.length).toLowerCase();

        const termFileName = termFile?.name;
        const termFileExtension = termFileName.lastIndexOf(".") + 1;
        const extTermFile = termFileName.substr(termFileExtension, termFileName.length).toLowerCase();

        const industry = allIndustryData.find((object: any) => object.id === parseInt(values.industryId));
        const subIndustry = allSubIndustryData.find((object: any) => object.id === parseInt(values.subIndustryId));

        if (extFile === "csv" && extTermFile === "csv") {

          const formData = new FormData();
          formData.append('company_file', file);
          formData.append('term_file', termFile)

          searchForCompany({
            payload: {
              file: formData,
              industry: industry?.name,
              subIndustry: subIndustry?.name,
              country: selectedCountry,
              state: selectedState,
              city: values.city,
            }
          })
        } else {
          alertMessage("Please select proper format of file!", "error");
        }


        // getResultByTerms({
        //   payload: {
        //     file: formData,
        //     datapipelineId: 1,
        //     termsetId: parseInt(selectedTermSet),
        //     country: selectedCountry,
        //     state: selectedState,
        //     district: values.district,
        //     city: values.city,
        //     pincode: values.pinCode,
        //     valueChainId: parseInt(selectedValueChain),
        //     valueChainName: valueChainName,
        //     valueChainCategoryId: parseInt(selectedValueChainCat),
        //     valueChainCategoryName: valueChainCatName,
        //     // termSetName: termSetName,
        //   },
        // });
      } else if (props.reportNameCode === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) {

        console.log(values)
        const industry = allIndustryData.find((object: any) => object.id === parseInt(values.industryId));
        const subIndustry = allSubIndustryData.find((object: any) => object.id === parseInt(values.subIndustryId));

        detailOrganizationSearch({
          payload: {
            industry: industry?.name,
            subIndustry: subIndustry?.name,
            country: selectedCountry,
            state: selectedState,
            city: values.city,
            position: values.position,
            platform: values.platform
          }
        })
      } else if (props.reportNameCode === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
        const formData = new FormData();

        if (file != null) formData.append('file', file);
        if (termFile != null) formData.append('term_file', termFile);

        let extFile: any;
        if (file?.name) {
          const fileName = file?.name;
          props.setFileName(fileName)
          const extension = fileName.lastIndexOf(".") + 1;
          extFile = fileName.substr(extension, fileName.length).toLowerCase();
        }

        if (extFile === 'pdf' ||
          extFile === 'zip' ||
          extFile === 'docx' ||
          extFile === 'xlsx' ||
          extFile === 'pptx' ||
          extFile === 'json' ||
          extFile === 'xml') {
          if (props.reportNameCode === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
            uploadTermComparisonAnalysis({
              file: formData,
            })
          }
        } else if (values.url !== "") {
          formData.append('type', values.urlType);
          formData.append('url', values.url);
          uploadTermComparisonAnalysis({
            file: formData,
          })
        }
        else {
          alertMessage("Please select proper format of file!", "error");
        }
      }
    } else if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
      if (props.reportNameCode === REPORT_NAME.INDUSTRY) {
        updateIndustry({
          id: props?.data?.id,
          payload: {
            isActive: props.data.isActive,
            id: props?.data?.id,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === REPORT_NAME.SUB_INDUSTRY) {
        updateSubIndustry({
          payload: {
            status: props.data.status,
            id: props.data.id,
            industryId: selectedValue,
            industryName: selectedValue,
            name: values.name,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (saveIndustryResponse.isSuccess) {
      alertMessage("Industry added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveIndustryResponse.isError) {
      let response: any = saveIndustryResponse?.error;
      alertMessage(response, "error");
    }
  }, [saveIndustryResponse.isSuccess, saveIndustryResponse.isError]);

  useEffect(() => {
    if (saveSubIndustryResponse.isSuccess) {
      alertMessage("Sub Industry added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveSubIndustryResponse.isError) {
      let response: any = saveSubIndustryResponse?.error;
      alertMessage(response, "error");
    }
  }, [saveSubIndustryResponse.isSuccess, saveSubIndustryResponse.isError]);

  useEffect(() => {
    if (updateIndustryResponse.isSuccess) {
      alertMessage("Industry updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateIndustryResponse.isError) {
      let response: any = updateIndustryResponse?.error;
      alertMessage(response, "error");
    }
  }, [updateIndustryResponse.isSuccess, updateIndustryResponse.isError]);

  useEffect(() => {
    if (updateSubIndustryResponse.isSuccess) {
      alertMessage("Sub Industry updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateSubIndustryResponse.isError) {
      let response: any = updateSubIndustryResponse?.error;
      alertMessage(response, "error");
    }
  }, [updateSubIndustryResponse.isSuccess, updateSubIndustryResponse.isError]);

  useEffect(() => {
    if (searchForCompanyResponse.isSuccess) {
      // alertMessage("Sub Industry updated successfully", "success");
      // Add status property to each object
      const dataWithStatus = searchForCompanyResponse.data.map((item: any) => ({
        ...item,
        status: "pending" // You can set the initial status value here
      }));
      dispatch(setSearchCompany(dataWithStatus));
      // props.setCall("Y");
      handleClose();
    } else if (searchForCompanyResponse.isError) {
      let response: any = searchForCompanyResponse?.error;
      alertMessage(response, "error");
    }
  }, [searchForCompanyResponse.isSuccess, searchForCompanyResponse.isError]);

  useEffect(() => {
    if (detailOrganizationSearchResponse.isSuccess) {
      dispatch(setDetailOrganizationSearch(detailOrganizationSearchResponse.data));
      handleClose();
    } else if (detailOrganizationSearchResponse.isError) {
      let response: any = detailOrganizationSearchResponse?.error;
      alertMessage(response ? response : "Error while searching", "error");
    }
  }, [detailOrganizationSearchResponse.isSuccess, detailOrganizationSearchResponse.isError]);

  useEffect(() => {
    if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
      if (props.reportNameCode === REPORT_NAME.INDUSTRY) {
        getIndustryById({
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === REPORT_NAME.SUB_INDUSTRY) {
        getSubIndustryById({
          id: props?.data?.id,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (getIndustryByIdResponse.isSuccess) {
      setGetByIdData(getIndustryByIdResponse.data);
    } else if (getIndustryByIdResponse.isError) {
      let response: any = getIndustryByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getIndustryByIdResponse.isSuccess, getIndustryByIdResponse.isError]);

  useEffect(() => {
    if (getSubIndustryByIdResponse.isSuccess) {
      setGetByIdData(getSubIndustryByIdResponse.data);
      setSelectedValue(getSubIndustryByIdResponse?.data?.industryId)
    } else if (getSubIndustryByIdResponse.isError) {
      let response: any = getSubIndustryByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getSubIndustryByIdResponse.isSuccess, getSubIndustryByIdResponse.isError]);

  //uploadTermComparisonAnalysisResponse.......
  useEffect(() => {
    if (uploadTermComparisonAnalysisResponse.isSuccess) {
      if (uploadTermComparisonAnalysisResponse.data !== null) {
        // dispatch(setTermComparisonAnalysis(uploadTermComparisonAnalysisResponse));

        if (isTerm) {
          dispatch(setTermComparisonAnalysisWithTermFile(uploadTermComparisonAnalysisResponse))
        } else {
          dispatch(setTermComparisonAnalysis(uploadTermComparisonAnalysisResponse));
        }

        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.TERM_COMPARISON_ANALYSIS);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {

        alertMessage("Error while fetching data", 'error');
        props.setShow(false);
      }
    } else if (uploadTermComparisonAnalysisResponse.isError) {
      alertMessage("Error while fetching Data", "error");
    }
  }, [uploadTermComparisonAnalysisResponse.isSuccess, uploadTermComparisonAnalysisResponse.isError]);

  const handleClose = () => props.setShow(false);

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
    let object: any = selectData?.find((value: any) => value.id === parseInt(event.target.value));
    setIndustryName(object?.name);
  };


  const handleSelectIndustry = (event: any) => {
    setSelectedIndustry(event.target.value);
    let object: any = industry?.find((value: any) => value.id === parseInt(event.target.value));
    // setValueChainName(object?.name);
  };

  const handleSelectCountry = (event: any) => {
    setSelectedCountryId(event.target.value);
    if (event) {
      getState({
        countryId: event.target.value,
      });
    }
    const countryName = allCountries.filter(
      (country: any) => country.id == event.target.value
    );
    setSelectedCountry(countryName[0].name);
  };

  const handleSelectState = (event: any) => {
    setSelectedStateId(event.target.value);
    const stateName = allStates.filter(
      (state: any) => state.id == event.target.value
    );
    setSelectedState(stateName[0].name);
  };

  useEffect(() => {
    if (allIndustryData && props.reportNameCode === REPORT_NAME.SUB_INDUSTRY) {
      const allIndustryDataAsc = [...allIndustryData].sort((a, b) => a.id - b.id);
      setSelectData(allIndustryDataAsc);
    }
  }, [allIndustryData]);

  const fileHandler = (e: any) => {
    setFile(e.target.files[0]);
  };

  const termFileHandler = (e: any) => {
    setTermFile(e.target.files[0]);
  }

  const imageHandler = (e: any) => {
    setFile(e.target.files[0]);
    props.setFileName(e.target.files[0].name)
  };


  return (
    <div>
      {/* <!-- Modal --> */}
      <Modal
        show={props.show}
        onHide={handleClose}
        className=""
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          {(props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH ||
            props.reportNameCode === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH
          ) ? (
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              Web Search
            </Modal.Title>
          ) : (
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "}
              {props.reportName}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {saveIndustryResponse.isLoading ||
            updateIndustryResponse.isLoading ||
            getIndustryByIdResponse.isLoading ||
            saveSubIndustryResponse.isLoading ||
            updateSubIndustryResponse.isLoading ||
            getSubIndustryByIdResponse.isLoading ||
            searchForCompanyResponse.isLoading ||
            industryResponse.isLoading ||
            subIndustryResponse.isLoading ||
            detailOrganizationSearchResponse.isLoading ||
            uploadTermComparisonAnalysisResponse.isLoading ? (
            <Loading />
          ) : (
            ""
          )}
          <Formik
            // validationSchema={formSchema}
            enableReinitialize={true}
            initialValues={
              (props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH ||
                props.reportNameCode === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) ? {
                industryId: "",
                subIndustryId: "",
                countryId: "",
                stateId: "",
                city: "",
                district: "",
                remark: "",
                position: "",
                platform: ""
              } : props.reportNameCode === REPORT_NAME.TERM_COMPARISON_ANALYSIS ?
                {
                  urlType: "",
                  url: ""
                } :
                {
                  name:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.name
                      : "",
                  select:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION &&
                      props.reportNameCode === REPORT_NAME.SUB_INDUSTRY
                      ? getByIdData && getByIdData.industryId
                      : "",
                }
            }
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => (
              <Form>
                {/* <div className="col-md-12 d-flex flex-col"> */}
                {props.reportNameCode === REPORT_NAME.TERM_COMPARISON_ANALYSIS ? (
                  <>
                    <p>Allowed file format - pdf, zip, docx, pptx, xlsx, json, xml</p>
                    <input type="file" id="fileUpload" onChange={imageHandler} />
                    <hr />
                    <p className="mt-3 fw-bold">OR</p>
                    <hr />
                    <p>
                      Url Type
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.urlType}
                        name="urlType"
                        placeholder="e.g: gdrive"
                      />
                    </p>
                    <p>
                      Url
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.url}
                        name="url"
                      />
                    </p>
                    <p className="mt-2">Allowed file format - CSV</p>
                    <input type="file" id="fileUpload1" onChange={termFileHandler} />
                    <hr />
                  </>
                ) : (
                  <div className=" p-2">
                    {" "}
                    <p>
                      <div className="d-flex">
                        <div>Industry</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <div className="w-100">
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleChange}
                            name="industryId"
                            value={values.industryId}
                            required
                          >
                            <option value="">
                              Select Industry
                            </option>
                            {industry &&
                              industry.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.id}
                                  >
                                    {itemType.id} - {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Sub Industry</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <div className="w-100">
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleChange}
                            name="subIndustryId"
                            value={values.subIndustryId}
                            required
                          >
                            <option value="">Select Sub Industry</option>
                            {subIndustry &&
                              subIndustry.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.id}
                                  >
                                    {itemType.id} - {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </p>
                    {props.reportNameCode === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH && (
                      <>
                        <p>
                          Position
                          <input
                            className="form-control form-control-sm w-100"
                            type="text"
                            aria-label=".form-control-sm example"
                            onChange={handleChange}
                            value={values.position}
                            name="position" />
                        </p><p>
                          Platform
                          <input
                            className="form-control form-control-sm w-100"
                            type="text"
                            aria-label=".form-control-sm example"
                            onChange={handleChange}
                            value={values.platform}
                            name="platform" />
                        </p>
                      </>
                    )}
                    <p>
                      Country
                      <div className="pe-2">
                        <select
                          className="form-select form-select-sm w-100"
                          aria-label=".form-select-sm example"
                          onChange={handleSelectCountry}
                          name="countryId"
                          value={selectedCountryId}
                        >
                          <option value="">Select Country</option>
                          {country &&
                            country.map((itemType: any, index: any) => {
                              return (
                                <option
                                  key={index.toString()}
                                  value={itemType.id}
                                >
                                  {itemType.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </p>
                    <p>
                      State{" "}
                      <div className="pe-2">
                        <select
                          className="form-select form-select-sm w-100"
                          aria-label=".form-select-sm example"
                          onChange={handleSelectState}
                          name="stateId"
                          value={selectedStateId}
                        >
                          <option value="">Select State</option>
                          {state &&
                            state.map((itemType: any, index: any) => {
                              // setSelectedStateId(itemType.id);
                              return (
                                <option
                                  key={index.toString()}
                                  value={itemType.id}
                                >
                                  {itemType.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </p>
                    <p>
                      City
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.city}
                        name="city"
                      // required
                      />
                    </p>
                    {/* <p>
                      Remark
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.remark}
                        name="remark"
                      // required
                      />
                    </p> */}
                    {props.reportNameCode !== REPORT_NAME.DETAIL_ORGANIZATION_SEARCH && (
                      <div>
                        <p className="fw-bold">Company File</p>
                        <p>Allowed file format - CSV</p>
                        <input type="file" id="fileUpload" onChange={fileHandler} />
                        <hr />
                        <p className="mt-2 fw-bold">Term File</p>
                        <p className="mt-2">Allowed file format - CSV</p>
                        <input type="file" id="fileUpload1" onChange={termFileHandler} />
                      </div>
                    )}
                  </div>
                )}

                <div className="d-flex flex-row justify-content-center">
                  {props.reportNameCode === REPORT_NAME.ORGANIZATION_SEARCH ||
                    props.reportNameCode === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH ? (
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                    >
                      Search
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                    >
                      {props.actionType === ACTION_TYPE.ADD_ACTION
                        ? "Save"
                        : "Update"}
                    </button>
                  )}

                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  );
};
export default MatchMakerManagementData;
