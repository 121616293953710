import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataServiceBaseUrl, dataService } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const industryMasterService = createApi({
  reducerPath: "industryMasterService",
  baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
  tagTypes: ["industryMasterService"],
  endpoints: (builder) => ({
    getAllIndustry: builder.mutation({
      query(body) {
        return {
          url: `${dataService.industryMasterController.getAllIndustry}?page=${body.page}&size=${body.size}&sort=${body.sort}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    getAllIndustryWithSearch: builder.mutation({
      query(body) {
        return {
          url: `${dataService.industryMasterController.getAllIndustryWithSearch}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    updateIndustry: builder.mutation({
      query(body) {
        return {
          url: `${dataService.industryMasterController.updateIndustry}/${body.id}`,
          method: "PUT",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    deleteIndustry: builder.mutation({
      query(body) {
        return {
          url: `${dataService.industryMasterController.deleteIndustryById}/${body.id}`,
          method: "DELETE",
        };
      },
    }),
    getIndustryById: builder.mutation({
      query(body) {
        return {
          url: `${dataService.industryMasterController.getIndustryById}/${body.id}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
        };
      },
    }),
    saveIndustry: builder.mutation({
      query(body) {
        return {
          url: `${dataService.industryMasterController.saveIndustry}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
  }),
});
export const {
 useGetAllIndustryMutation,
 useGetAllIndustryWithSearchMutation,
 useUpdateIndustryMutation,
 useDeleteIndustryMutation,
 useGetIndustryByIdMutation,
 useSaveIndustryMutation
} = industryMasterService;
