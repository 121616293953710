import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface ModuleState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}
const initialState: ModuleState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    setModule: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllModules: (state, action) => {
      state.data = action.payload.data;
    },
    setAllModulesBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setModule, setAllModulesBySearch, setAllModules,reset } = moduleSlice.actions;
export const getAllModuleDetails = (state: RootState) => state.module.data;
export const getModuleTotalRecords = (state:RootState) => state.module.totalRecords;
export const getAllModulesBySearch = (state: RootState) => state.module.data;
export const allModules = (state: RootState) => state.module.data;
export default moduleSlice.reducer;