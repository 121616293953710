import { useLocation } from "react-router-dom";
import { REPORT_TYPE } from "../../constant/reportType";
import DocumentManagementMaster from "../../component/DocumentManagementMaster";

type Props = {};

const AllDocumentReports = (props: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const report = searchParams.get("report") !== null ? searchParams.get("report") : localStorage.getItem("selectedReport")

  if (report == REPORT_TYPE.DOCUMENT_MANAGEMENT) {
    return (
      <DocumentManagementMaster />
    )
  } else {
    return (
      <div>Something went wrong</div>
    )
  }

};
export default AllDocumentReports;
