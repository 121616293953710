import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Select, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { REPORT_NAME } from "../constant/reportType";
import { DATA_PRIVACY_COMPLIANCE_TEST, AI_REGULATION_COMPLIANCE_TEST, SENSITIVE_AND_BIAS_DATA_IDENTIFICATION_COLUMN, DATA_PRIVACY_PENALTY_PREDICTION_COLUMN, EMPLOYMENT_BIAS_COLUMN } from "../constant/AppConstant";
import { getAllStatesTotalRecords } from "../redux-toolkit/slices/statesSlice";
import { getLocalStorageData } from "../utils/LocalStorageService";
import PrivacyGuardProData from "../pages/reports/PrivacyGuardProData";
import { getAiRegComplianceTestDetails, getDataPrivacyPenaltyPredictionDetails, getGdprDetails, getSensitiveBiasDataIdentificationDetails } from "../redux-toolkit/slices/gdprSlice";
import { getEmploymentBiasDetails } from "../redux-toolkit/slices/fastApiSlice";
import BiasAndVulnerabiltySummary from "./BiasAndVulnerabiltySummary";
import { Excel } from "antd-table-saveas-excel";
import { BsDownload, BsEnvelope } from "react-icons/bs";
import { AIGovernanceFrameworkData, AI_REGULATIONS, aiRegulationActsData, aiRegulationsData, dataPrivacyActsData } from "../constant/mockData";
import { Link } from "react-router-dom";
import { useSendEmailforExportMutation } from "../redux-toolkit/services/sendEmailService";
import { alertMessage } from "../utils/AlertService";
import Loading from "./Loading";
import Tree from "react-d3-tree";

const { Option } = Select;
type Props = {};

type Orientation = 'horizontal' | 'vertical';

const PrivacyGuardPro = (props: Props) => {
  const params = useParams();
  const localUserObj = getLocalStorageData("localUser");
  const navigate = useNavigate();
  // States
  const [show, setShow] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [reportName, setReportName]: any = useState();
  const [fileName, setFileName]: any = useState();
  const [dataPrivacyPieData, setdDtaPrivacyPieData]: any = useState([]);
  const [aiRegulationPieData, setAiRegulationPieData]: any = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [showMindMapBtn, setShowMindMapBtn] = useState(false);
  const [treeData, setTreeData] = useState();

  //Mutation
  const [sendEmail, sendEmailResponse] = useSendEmailforExportMutation();

  //columns
  const [dataPrivacyCompTestColumn, setDataPrivacyCompTestColumn]: any = useState(DATA_PRIVACY_COMPLIANCE_TEST);
  const [aiRegCompliancecTestColumn, setAiRegCompliancecTestColumn]: any = useState(AI_REGULATION_COMPLIANCE_TEST);
  const [sensitiveBiasDataIdenColumn, setSensitiveBiasDataIdenColumn]: any = useState(SENSITIVE_AND_BIAS_DATA_IDENTIFICATION_COLUMN);
  const [dataPrivacyPenaltyPredColumn, setDataPrivacyPenaltyPredColumn]: any = useState(DATA_PRIVACY_PENALTY_PREDICTION_COLUMN);
  const [employmentBiasColumn, setEmploymentBiasColumn]: any = useState(EMPLOYMENT_BIAS_COLUMN);

  //datasource
  const [datasource, setDatasource]: any = useState([]);
  const [AIRegComplianceTestDatasource, setAIRegComplianceTestDatasource]: any = useState([]);
  const [sensitiveBiasDataIdenDatasource, setSensitiveBiasDataIdenDatasource]: any = useState([]);
  const [dataPrivacyPenaltyPredDatasource, setDataPrivacyPenaltyPredDatasource]: any = useState([]);
  const [employmentBiasDataSource, setEmploymentBiasDataSource]: any = useState([]);

  //selector
  const allStatesTotalRecords: any = useAppSelector(getAllStatesTotalRecords);
  const getGdprDetailsData: any = useAppSelector(getGdprDetails);
  const getAIRegComplianceTestDetails: any = useAppSelector(getAiRegComplianceTestDetails);
  const getDataPrivacyPenaltyPrediction: any = useAppSelector(getDataPrivacyPenaltyPredictionDetails);
  const getSensitiveBiasDataIdentification: any = useAppSelector(getSensitiveBiasDataIdentificationDetails);
  const getEmploymentBias: any = useAppSelector(getEmploymentBiasDetails);

  useEffect(() => {

    switch (params.reportType) {
      case REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST:
        setReportName(REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST);
        break;
      case REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION:
        setReportName(REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION);
        break;
      case REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION:
        setReportName(REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION);
        break;
      case REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST:
        setReportName(REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST);
        break;
      case REPORT_NAME.CODE_AI_BIAS_ANALYSIS:
        setReportName(REPORT_NAME.CODE_AI_BIAS_ANALYSIS);
        break;
      case REPORT_NAME.EMPLOYMENT_BIAS:
        setReportName(REPORT_NAME.EMPLOYMENT_BIAS);
        break;
      case REPORT_NAME.GLOBAL_DATA_PRIVACY_AI_REG_ACTS:
        setReportName(REPORT_NAME.GLOBAL_DATA_PRIVACY_AI_REG_ACTS);
        break;
      default:
    }
  }, []);

  const paginationChange = (page: number, offset: number) => {
    if (reportName === "state") {

    }
    setCurrentPage(page);
    setOffset(offset);
  };

  const handleShow = () => setShow(true);

  // navigate............
  const handleBack = () => {
    setShowMindMapBtn(false);
    setTreeData(undefined);
    navigate("/complianceReports");
  };

  const transformDataForComplianceTest = (gdprData: any) => {
    // Initialize categories object with empty arrays
    const categories = gdprData.reduce(
      (acc: any, item: any) => {
        // Determine category based on status
        const status = item["status"];

        if (status === "Pass") {
          acc.strength.push(item);
        } else if (status === "Fail") {
          acc.opportunities.push(item);
        }

        return acc;
      },
      { strength: [], opportunities: [] }
    );

    return {
      name: "EU GDPR Compliance Knowledge Map",
      children: [
        {
          name: "Strength",
          children: categories.strength.map((item: any) => ({
            name: extractNumber(item["article_no"]), // Displaying terms
            articleNo: item["article_no"], // Displaying article number
            file: item["file_name"], // Displaying file name
          })),
        },
        {
          name: "Opportunities",
          children: categories.opportunities.map((item: any) => ({
            name: extractNumber(item["article_no"]), // Displaying terms
            articleNo: item["article_no"], // Displaying article number
            file: item["file_name"], // Displaying file name
            // attributes: {
            //     Terms: item.Terms,
            //     Data: item.Data,}
          })),
        },
      ],
    };
  };

  // Function to extract article number from the full URL
  const extractNumber = (url: any) => {
    if (!url) return ""; // Handle case where URL is empty or undefined
    const articleMatch = url.match(/\/art-(\d+)/); // Regex to match article number
    const recitalMatch = url.match(/\/recitals\/no-(\d+)/); // Regex to match recital number

    if (articleMatch) {
      return `GDPR Article ${articleMatch[1]}`; // Extracted article number
    } else if (recitalMatch) {
      return `GDPR Recital ${recitalMatch[1]}`; // Extracted recital number
    } else {
      return ""; // Return empty string if neither match
    }
  };

  const transformDataForAiRegulation = (gdprData: any) => {
    const categories = gdprData.reduce(
      (acc: any, item: any) => {
        const status = item["status"].trim().toLowerCase(); // Trim and convert to lowercase for consistency
  
        if (status === "pass") {
          acc.strength.push(item);
        } else if (status === "fail") {
          acc.opportunities.push(item);
        }
  
        return acc;
      },
      { strength: [], opportunities: [] }
    );
  
    return {
      name: "EU GDPR Compliance Knowledge Map",
      children: [
        {
          name: "Strength",
          children: categories.strength.map((item: any) => ({
            name: item["checklist_points"],
          })),
        },
        {
          name: "Opportunities",
          children: categories.opportunities.map((item: any) => ({
            name: item["checklist_points"],
          })),
        },
      ],
    };
  };


  //data privacy compliance test
  useEffect(() => {
    if (getGdprDetailsData) {
      setDatasource(getGdprDetailsData);

      const data: any = [];
      getGdprDetailsData.map((element: any) => {
        const parts = (element.file_name).split("//");
        const splittedfileName = parts[parts.length - 1];
        data.push({
          fileName: splittedfileName,
          status: element.status
        })
      })

      const results: any = [];

      for (const file of data) {
        const fileName = file.fileName;
        const status = file.status;

        let found = false;
        for (const result of results) {
          if (result.fileName === fileName) {
            found = true;
            result.count[status === "Pass" ? 0 : 1]++;
            result.status = ["Pass", "Fail"];
            break;
          }
        }

        if (!found) {
          results.push({
            fileName,
            count: [status === "Pass" ? 1 : 0, status === "Fail" ? 1 : 0],
            status: ["Pass", "Fail"],
          });
        }
      }

      setdDtaPrivacyPieData(results);
      let tempMapData: any = transformDataForComplianceTest(getGdprDetailsData);
      setTreeData(tempMapData);
      // setShowMindMapBtn(true);
    }
  }, [getGdprDetailsData]);

  useEffect(() => {
    if (getAIRegComplianceTestDetails) {
      setAIRegComplianceTestDatasource(getAIRegComplianceTestDetails);

      const data: any = [];
      getAIRegComplianceTestDetails.map((element: any) => {
        const parts = (element.file_name).split("//");
        const splittedfileName = parts[parts.length - 1];
        data.push({
          fileName: splittedfileName,
          status: element.status
        })
      })

      const results: any = [];

      for (const file of data) {
        const fileName = file.fileName;
        const status = file.status;

        let found = false;
        for (const result of results) {
          if (result.fileName === fileName) {
            found = true;
            result.count[status === "Pass" ? 0 : 1]++;
            result.status = ["Pass", "Fail"];
            break;
          }
        }

        if (!found) {
          results.push({
            fileName,
            count: [status === "Pass" ? 1 : 0, status === "Fail" ? 1 : 0],
            status: ["Pass", "Fail"],
          });
        }
      }

      setAiRegulationPieData(results);
      let tempMapData: any = transformDataForAiRegulation(getAIRegComplianceTestDetails);
      setTreeData(tempMapData);
      // setShowMindMapBtn(true);
    }
  }, [getAIRegComplianceTestDetails]);

  useEffect(() => {
    if (getDataPrivacyPenaltyPrediction) {
      setDataPrivacyPenaltyPredDatasource(getDataPrivacyPenaltyPrediction);
    }
  }, [getDataPrivacyPenaltyPrediction]);

  useEffect(() => {
    if (getSensitiveBiasDataIdentification) {
      let sensitiveAndBiasDataIdentificationArr = Object.entries(getSensitiveBiasDataIdentification);

      let temp: any = [];
      sensitiveAndBiasDataIdentificationArr?.forEach((element: any, i: any) => {
        if (element[0] === "Recommendations") {

          let recommendationsData = Object.keys(element[1]).map(key => {
            return (
              <div>
                <p><span className="fw-bold">{key}:</span> {element[1][key]} </p>
                {/* `${key}:${element[1][key]}`).join("."); */}
              </div>
            )
          })

          temp.push({
            category: element[0],
            recommendations: recommendationsData
          })
        } else if (element[0] !== "Recommendations") {
          temp.push({
            category: element[0],
            recommendations: Array.isArray(element[1]) ? element[1].join(",") : ""
          })
        }
      });
      // props.setSensitiveBiasDataIdenDatasource(temp);
      setSensitiveBiasDataIdenDatasource(temp);
    }
  }, [getSensitiveBiasDataIdentification]);

  useEffect(() => {
    if (getEmploymentBias) {
      let employmentBiasArr = Object.entries(getEmploymentBias);
      let employmentBiasDataSource: any = [];

      employmentBiasArr?.forEach((element: any) => {
        const category = element[0];
        const subData = element[1];

        Object.keys(subData).forEach((category1: string) => {
          let values: string | JSX.Element[] | number = subData[category1];

          if (typeof values === "object" && !Array.isArray(values)) {
            values = Object.entries(values).map(([key, value]: any) => (
              <div key={key}>
                <p><span className="fw-bold">{key}:</span> {(typeof value === 'number') ? value.toFixed(2) : value} </p>
              </div>
            ));
          } else if (typeof values === "number") {
            values = values.toFixed(2);
          }

          employmentBiasDataSource.push({ category, category1, values });
        });
      });

      setEmploymentBiasDataSource(employmentBiasDataSource);
    }
  }, [getEmploymentBias]);

  const handleExportClick = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST) {
      fileName = REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST;
      columns = dataPrivacyCompTestColumn;
      dataSource = datasource;
    } else if (reportName === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST) {
      fileName = REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST
      columns = aiRegCompliancecTestColumn;
      dataSource = AIRegComplianceTestDatasource;
    } else if (reportName === REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION) {
      fileName = REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION
      columns = sensitiveBiasDataIdenColumn;
      dataSource = sensitiveBiasDataIdenDatasource;
    } else if (reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION) {
      fileName = REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION
      columns = dataPrivacyPenaltyPredColumn;
      dataSource = dataPrivacyPenaltyPredDatasource
    } else if (reportName === REPORT_NAME.EMPLOYMENT_BIAS) {
      fileName = REPORT_NAME.EMPLOYMENT_BIAS;
      columns = employmentBiasColumn;
      dataSource = employmentBiasDataSource;
    }

    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true
      })
      .saveAs(`${fileName}.xlsx`);
  };

  const handleSendEmail = () => {
    let columns: any = [];
    let dataSource: any = [];
    let fileName: any;
    if (reportName === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST) {
      fileName = REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST;
      columns = dataPrivacyCompTestColumn;

      if (datasource.length > 0) {
        // Remove unwanted part from the URL
        const dataSourceWithLink = datasource.map((record: any) => ({
          ...record,
          article_no: record.article_no.split('/$')[0] // Extract everything before '/$'
        }));
        dataSource = dataSourceWithLink;
      } else {
        alertMessage("Please make new request first!", "error");
      }

    } else if (reportName === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST) {
      fileName = REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST
      columns = aiRegCompliancecTestColumn;
      dataSource = AIRegComplianceTestDatasource;
    } else if (reportName === REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION) {
      fileName = REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION
      columns = sensitiveBiasDataIdenColumn;

      if (getSensitiveBiasDataIdentification) {

        let sensitiveAndBiasDataIdentificationArr = Object.entries(getSensitiveBiasDataIdentification);

        let manipulatedDataSource: any = [];
        sensitiveAndBiasDataIdentificationArr?.forEach((element: any, i: any) => {

          manipulatedDataSource.push({
            category: element[0],
            recommendations: Array.isArray(element[1]) ? element[1].join(",") : Object.entries(element[1]).map(([key, value]) => `${key}: ${value}`).join(",")
          })

        });
        dataSource = manipulatedDataSource;

      } else {
        alertMessage("Please make new request first!", "error");
      }
    } else if (reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION) {
      fileName = REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION
      columns = dataPrivacyPenaltyPredColumn;
      dataSource = dataPrivacyPenaltyPredDatasource
    } else if (reportName === REPORT_NAME.EMPLOYMENT_BIAS) {
      fileName = REPORT_NAME.EMPLOYMENT_BIAS;
      columns = employmentBiasColumn;

      if (getEmploymentBias) {
        let employmentBiasArr = Object.entries(getEmploymentBias);
        let employmentBiasDataSource: any = [];

        employmentBiasArr?.forEach((element: any) => {
          const category = element[0];
          const subData = element[1];

          Object.keys(subData).forEach((category1: string) => {
            let values: string | JSX.Element[] | number = subData[category1];

            if (typeof values === "object" && !Array.isArray(values)) {
              // Convert object values to formatted string
              values = Object.entries(values).map(([key, value]: any) => `${key}: ${typeof value === 'number' ? value.toFixed(2) : value}`).join(', ');
            } else if (typeof values === "number") {
              values = values.toFixed(2);
            }

            employmentBiasDataSource.push({ category, category1, values });
          });
        });
        dataSource = employmentBiasDataSource;
      } else {
        alertMessage("Please make new request first!", "error");
      }

    }

    if (dataSource.length > 0) {
      sendEmail({
        payload: {
          columns: columns,
          dataSource: dataSource,
          fileName: fileName,
          to: localUserObj.username
        },
        token: localUserObj.token
      })
    } else {
      alertMessage("Please make new request first!", "error");
    }
  };

  useEffect(() => {
    if (sendEmailResponse.isSuccess) {
      alertMessage("Emaile sent successfully", "success");
    } else if (sendEmailResponse.isError) {
      alertMessage("error while sending email", "error");
    }
  }, [sendEmailResponse.isSuccess, sendEmailResponse.isError]);

  const treeProps = {
    data: treeData,
    orientation: "horizontal" as Orientation,
    translate: { x: 50, y: 50 },
  };

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      {reportName === REPORT_NAME.GLOBAL_DATA_PRIVACY_AI_REG_ACTS ? (
        <div>
          <div className="d-flex align-items-center justify-content-between w-100 px-2">
            <h5
              className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
              style={{ fontWeight: "700" }}
            >
              {params.reportType}
            </h5>
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
          <div className="mt-5">
            <h5
              className="m-0 px-0 d-flex align-items-center text-cm-dark-grey"
              style={{ fontWeight: "700" }}
            >
              Data Privacy
            </h5>
            <ul className="mb-4">
              {dataPrivacyActsData.map((act, index) => (
                <li key={index} className="mt-2">
                  <Link
                    to={act.link}
                    target="_blank"
                  >
                    {act.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-5">
            <h5
              className="m-0 px-0 d-flex align-items-center text-cm-dark-grey"
              style={{ fontWeight: "700" }}
            >
              AI Regulations
            </h5>
            <ul className="mb-4">
              {aiRegulationsData.map((act, index) => (
                <li key={index} className="mt-2">
                  <Link
                    to={act.link}
                    target="_blank"
                  >
                    {act.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-5">
            <h5
              className="m-0 px-0 d-flex align-items-center text-cm-dark-grey"
              style={{ fontWeight: "700" }}
            >
              AI Governance Framework
            </h5>
            <ul className="mb-4">
              {AIGovernanceFrameworkData.map((act, index) => (
                <li key={index} className="mt-2">
                  <Link
                    to={act.link}
                    target="_blank"
                  >
                    {act.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-5">
            <h5
              className="m-0 px-0 d-flex align-items-center text-cm-dark-grey"
              style={{ fontWeight: "700" }}
            >
              AI Regulation Acts
            </h5>
            <ul className="mb-4">
              {aiRegulationActsData.map((act, index) => (
                <li key={index} className="mt-2">
                  <Link
                    to={act.link}
                    target="_blank"
                  >
                    {act.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-5">
            <h5
              className="m-0 px-0 d-flex align-items-center text-cm-dark-grey"
              style={{ fontWeight: "700" }}
            >
              AI Regulations
            </h5>
            <ul className="mb-4">
              {AI_REGULATIONS.map((act, index) => (
                <li key={index} className="mt-2">
                  <Link
                    to={act.link}
                    target="_blank"
                  >
                    {act.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
            <div className="d-flex align-items-center w-100 px-2">
              <h5
                className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
                style={{ fontWeight: "700" }}
              >
                {params.reportType}
              </h5>
            </div>
            <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
              <div onClick={handleShow} className="d-flex px-2 align-items-center mt-1">
                <Button
                  variant="primary"
                  className="d-flex align-items-center me-0 text-nowrap"
                // onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
                >
                  {/* <BiPlus /> */}
                  New Request
                </Button>
              </div>
              {showMindMapBtn && (
                <div onClick={() => setShowMap(true)} className="d-flex px-2 align-items-center mt-1">
                  <Button
                    variant="primary"
                    className="d-flex align-items-center me-0 text-nowrap"
                  >
                    {/* mind map */}
                    Knowledge Map
                  </Button>
                </div>
              )}
              {show && (
                <PrivacyGuardProData
                  show={show}
                  setShow={setShow}
                  reportName={params.reportType}
                  reportNameCode={reportName}
                  setDatasource={setDatasource}
                  setAIRegComplianceTestDatasource={setAIRegComplianceTestDatasource}
                  setSensitiveBiasDataIdenDatasource={setSensitiveBiasDataIdenDatasource}
                  setDataPrivacyPenaltyPredDatasource={setDataPrivacyPenaltyPredDatasource}
                  setEmploymentBiasDataSource={setEmploymentBiasDataSource}
                  setFileName={setFileName}
                  setShowMindMapBtn={setShowMindMapBtn}
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
              <Button
                variant="primary"
                className="d-flex align-items-center me-0"
                onClick={handleBack}
              >
                Back
              </Button>
              <div className="primary mt-2 mt-md-0 d-flex justify-content-end w-100 mx-2">
                <Button
                  variant="primary"
                  className="d-flex align-items-center me-0"
                  onClick={handleExportClick}
                >
                  <BsDownload size={16} className="me-2" />
                  Export
                </Button>
                <Button
                  variant="primary"
                  className="d-flex align-items-center mx-2"
                  onClick={handleSendEmail}
                >
                  <BsEnvelope size={16} className="me-2" />
                  Email
                </Button>
              </div>
            </div>
            {fileName && (
              <p className="mt-2 mx-2 fw-bold">{fileName}</p>
            )
            }
          </div>
          {sendEmailResponse.isLoading ?
            (
              <Loading />
            ) : (
              ""
            )}
          <div className="my-2 w-100 overflow-x-auto rounded-3">
            <Table
              bordered={true}
              rowKey={(obj: any, i: any) => {
                return i
              }}
              // rowKey={"file_name"}
              pagination={
                reportName === "state"
                  ? {
                    total: allStatesTotalRecords && allStatesTotalRecords,
                    responsive: true,
                    current: currentpage,
                    showSizeChanger: true,
                    pageSizeOptions: [15, 20, 30],
                    onChange: (page, offset) => {
                      paginationChange(page, offset);
                    },
                    pageSize: offset,
                  }
                  : {
                    responsive: true,
                  }
              }
              columns={
                reportName === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST
                  ? dataPrivacyCompTestColumn
                  : reportName === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST
                    ? aiRegCompliancecTestColumn
                    : reportName === REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION
                      ? sensitiveBiasDataIdenColumn
                      : reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION
                        ? dataPrivacyPenaltyPredColumn
                        : reportName === REPORT_NAME.EMPLOYMENT_BIAS
                          ? employmentBiasColumn
                          : ""
              }
              dataSource={
                reportName === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST
                  ? datasource
                  : reportName === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST
                    ? AIRegComplianceTestDatasource
                    : reportName === REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION
                      ? sensitiveBiasDataIdenDatasource
                      : reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION
                        ? dataPrivacyPenaltyPredDatasource
                        : reportName === REPORT_NAME.EMPLOYMENT_BIAS
                          ? employmentBiasDataSource
                          : ""
              }
            />
          </div>
        </>
      )}
      {reportName === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST ? (

        (dataPrivacyPieData.map((element: any) => {
          return (
            <div className="">
              {/* <p className="fw-bolder text-cm-active-primary1">{REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY}</p> */}
              <div className="shadow-lg p-3 mb-5 bg-body rounded overflow-scroll">
                <BiasAndVulnerabiltySummary title={element.fileName} chartSerires={element.count} chartLables={element.status} />
              </div>
            </div>
          )
        }))
      ) : null}
      {reportName === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST ? (
        (aiRegulationPieData.map((element: any) => {
          return (
            <div className="">
              {/* <p className="fw-bolder text-cm-active-primary1">{REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY}</p> */}
              <div className="shadow-lg p-3 mb-5 bg-body rounded overflow-scroll">
                <BiasAndVulnerabiltySummary title={element.fileName} chartSerires={element.count} chartLables={element.status} />
              </div>
            </div>
          )
        }))
      ) : null}
      {showMap && (
        <div style={{ width: "100%", height: "calc(100vh - 100px)" }}>
          <Tree {...treeProps} />
        </div>
      )}
    </div>
  );
};
export default PrivacyGuardPro;
