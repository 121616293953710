import React, { useState, useEffect, useRef, } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoSync } from "react-icons/go";
import { Modal } from 'react-bootstrap';
import Loading from '../../component/Loading';
import { assessmentAndAudits, assessmentAndAuditsBaseUrl, cascadBaseUrl } from '../../redux-toolkit/endpoints';
import axios from 'axios';
import { Table } from 'antd';
import { BsFileEarmarkText, BsUpload } from 'react-icons/bs';
import { alertMessage } from "../../utils/AlertService";

const ChatBot = ({ show, setShow }) => {

    const [minimized, setMinimized] = useState(false);
    const [inputText, setInputText] = useState('');
    const [searchedText, setSearchedText] = useState('');
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [paragraphsWithTables, setParagraphsWithTables] = useState(false);
    const [file, setFile] = useState(null);
    const [isFileUploaded, setIsFileUploaded] = useState(false);

    const base_url = "https://quantileapibeta.online";
    const api_key = "quant-3rzCLlkmjyamQWB4oW1jF";

    console.log(file)
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleInputChange = (e) => {
        setInputText(e.target.value);
        setSearchedText(e.target.value)
    };

    // upload image...............................
    const fileUploadHandler = async (e) => {
        setFile(e.target.files[0]);
        const fileName = e.target.files[0].name;

        const extension = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(extension, fileName.length).toLowerCase();

        if (extFile === "pdf") {
            const url = `${process.env.REACT_APP_CASCAD_URL}/rag_data_upload`;

            const formData = new FormData();
            // formData.append("db_name", db_name);
            formData.append("pdf_file", e.target.files[0]);
            setLoading(true);
            try {
                const response = await axios.post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "quant-api-key": process.env.REACT_APP_API_KEY_QUANT1,
                    },
                    params: {
                        db_name: "pdf-data",
                        pdf_file: formData.pdf_file,
                        chunk_size: 100,
                        chunk_overlap: 10,
                        embedding_model: "text-embedding-3-small",
                    },
                });
                setLoading(false);
                console.log(response.data);
            } catch (error) {
                setLoading(false);
                console.error("Error uploading file:", error);
            }
        } else {
            alertMessage("Only Pdf file is allowed!", "error");
            setFile(null);
        }

    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (file !== null) {

            const url = `${process.env.REACT_APP_CASCAD_URL}/rag_assistant`;

            const params = {
                db_name: "pdf-data",
                description: "professional response", //description
                question: inputText,//question
                embedding_model: "text-embedding-3-small",
                inference_model: "gpt-3.5-turbo-0125",
                temperature: 0,
            };

            try {
                const response = await axios.get(url, {
                    headers: {
                        "quant-api-key": process.env.REACT_APP_API_KEY_QUANT1,
                    },
                    params: params,
                });
                setLoading(false);
                console.log(response)
                setResponse(response.data);

                const formattedContent = [];

                formattedContent.push(<p key={formattedContent.length}>{response?.data}</p>);

                setParagraphsWithTables(formattedContent)

                // After setting state, add user message to messages state
                if (inputText.trim() !== '') {
                    setMessages([...messages, { text: inputText, sender: "user" }]);
                    setInputText('');
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching chat response:", error);
            }
        } else {
            const url = `${cascadBaseUrl}/call_cascading?prompt=${encodeURIComponent(
                inputText
            )}&max_tokens=800`;

            try {
                const res = await fetch(url, {
                    method: "GET",
                    headers: {
                        accept: "application/json",
                        "quant-api-key": process.env.REACT_APP_API_KEY_QUANT,
                    },
                });
                const data = await res.json();
                setResponse(data);
                setLoading(false);

                let paragraphs;
                if (data?.choices) {

                    paragraphs = data?.choices[0].message.content.split('\n\n');
                }
                if (data?.content) {
                    paragraphs = data?.content[0].text.split('\n\n');
                }

                // Parse the response data into paragraphs
                const formattedContent = [];

                // Iterate through each paragraph
                paragraphs.forEach(paragraph => {
                    if (paragraph.includes('1.') || paragraph.includes('•')) {
                        // If the paragraph contains bullet points, create a table
                        formattedContent.push(createTableFromBulletPoints(paragraph));
                    } else {
                        // If the paragraph does not contain bullet points, add it as a simple paragraph
                        formattedContent.push(<p key={formattedContent.length}>{paragraph}</p>);
                    }
                });

                setParagraphsWithTables(formattedContent)

                // After setting state, add user message to messages state
                if (inputText.trim() !== '') {
                    setMessages([...messages, { text: inputText, sender: "user" }]);
                    setInputText('');
                }
                setFile(null)

            } catch (error) {
                setLoading(false);
                console.error("Error:", error);
            }
        }
    };

    const createTableFromBulletPoints = (data) => {
        const lines = data.split('\n');
        const tableData = lines.map(line => {
            const parts = line.split('. ');
            if (parts.length >= 2) {
                const index = parts.shift().trim();
                const description = parts.join('. ').trim();
                return { index, description };
            } else {
                console.error('Invalid data line:', line);
                return null;
            }
        }).filter(item => item !== null); // Filter out any null values

        if (tableData.length === 0) {
            console.warn('No valid data found:', data);
            return null;
        }

        const columns = Object.keys(tableData[0]).map(key => ({
            title: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
            dataIndex: key,
            key: key
        }));

        return (
            <Table
                dataSource={tableData}
                columns={columns}
                bordered
                pagination={false}
            />
        );
    };

    const handleButtonClick = (buttonText) => {
        if (buttonText === "Awareness") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary">Awareness</button></div>
                            <div className='pt-2'><button onClick={() => handleButtonClick("Data Privacy")} className="px-2 py-1 btn btn-primary">Data Privacy</button></div>
                            <div className='pt-2'><button onClick={() => handleButtonClick("Cyber Security")} className="px-2 py-1 btn btn-primary">Cyber Security</button></div>
                            <div><button onClick={() => handleButtonClick("AI Regulations")} className="mt-2 px-2 py-1 ml-2 btn btn-primary">AI Regulations</button></div>

                        </div>
                    ),
                    sender: "bot"
                }
            ]);
        } else if (buttonText === "Data Privacy") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary">Data Privacy</button></div>
                            <div> <a href="https://staging.reguvista.valuevalidator.com/singapore">Singapore Personal Data Protection Act</a> </div>
                            <div> <a href="https://staging.reguvista.valuevalidator.com/europe">Europe Data Privacy Act</a> </div>
                            <div> <a href="https://staging.reguvista.valuevalidator.com/uk">UK Data Privacy Act</a> </div>
                            <div> <a href="https://staging.reguvista.valuevalidator.com/canada">Canada Data Privacy Act</a> </div>
                            <div> <a href="https://staging.reguvista.valuevalidator.com/usa">USA Data Privacy Act</a> </div>
                            <div><a href="https://staging.reguvista.valuevalidator.com/gdpr">EU GDPR Terms</a></div>

                        </div>

                    ),
                    sender: "bot"
                }
            ]);

        } else if (buttonText === "AI Regulations") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary">AI Regulations</button></div>
                            <a href="https://staging.reguvista.valuevalidator.com/nist-ai-risk">NIST AI Risk Management Framework Playbook</a>
                            <br />
                            {/* <a href="https://staging.reguvista.valuevalidator.com/gdpr">EU GDPR Terms</a> */}
                            <a href="https://staging.reguvista.valuevalidator.com/eu-ai-act">EU AI Act</a>
                            <br />
                            <a href="https://staging.reguvista.valuevalidator.com/singapore-ai-act">Singapore's AI Governance Framework Model</a>
                            <br />
                            <a href="https://staging.reguvista.valuevalidator.com/trusted-ai-act">Trusted AI</a>
                        </div>
                    ),
                    sender: "bot"
                }
            ]);

        } else if (buttonText === "FAQs") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary">FAQ's</button></div>
                            <a href="https://staging.reguvista.valuevalidator.com/faqs">FrequentlyAskedQuestions</a>
                        </div>
                    ),
                    sender: "bot"
                }
            ]);

        } else if (buttonText === "Regulatory Insights") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary">Regulatory Insights</button></div>

                            <div className='pt-2'><button onClick={() => handleButtonClick("USA")} className="px-2 py-1 btn btn-primary">USA</button></div>
                            <div><button onClick={() => handleButtonClick("Global Insights")} className="mt-2 px-2 py-1 ml-2 btn btn-primary">Global Insights</button></div>
                        </div>
                    ),
                    sender: "bot"
                }
            ]);

        } else if (buttonText === "USA") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary">USA</button></div>

                            <p className="text-gray-500">Here are all related links of USA:</p>

                            <div> <a href="https://lookerstudio.google.com/reporting/65a85092-ab87-408a-9149-5f0c8a3a3a2b/page/3QyZD">USA - AI, Cyber Security and Data Privacy Legislative Insights</a></div>
                            <div> <a href="https://lookerstudio.google.com/reporting/e0d78101-9b3b-4f09-88c6-aa9858de321a/page/tnmZD">GDPR Penalty Dashboard</a></div>
                            <div> <a href="https://lookerstudio.google.com/u/0/reporting/53f75a39-1379-4dc9-b643-6034ddf8c8b0/page/qxObD">Global AI Legislation Focus Area</a></div>
                            <div><a href="https://lookerstudio.google.com/reporting/5d4b1a7d-9300-42e0-939b-aee7829f6ad9/page/JCTbD">A.I. Legislation Bill Status Updates</a></div>
                        </div>
                    ),
                    sender: "bot"
                }
            ]);

        } else if (buttonText === "AI Regulations") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <a href="https://docs.google.com/document/d/1NSEnwIpGcHk7By6u0oBaHp79G5tx7dCZOWCf3NarHfE/edit?usp=drive_link">NIST AI Risk Management Framework Playbook</a>
                            <a href="https://staging.reguvista.valuevalidator.com/gdpr">EU GDPR Terms</a>
                        </div>
                    ),
                    sender: "bot"
                }
            ]);

        } else if (buttonText === "Compliance Test") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary">Compliance Test</button></div>
                            <div className='pt-4'><a href="https://staging.reguvista.valuevalidator.com/complianceReports">CamplianceReoprts</a></div>
                        </div>
                    ),
                    sender: "bot"
                },
            ]);

        } else if (buttonText === "AI Governance") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary"> Governance Report</button></div>
                            <div className='pt-4'><a href="https://staging.reguvista.valuevalidator.com/aiGovernanceReports">GovernanceReport</a></div>
                        </div>
                    ),
                    sender: "bot"
                },
            ]);


        } else if (buttonText === "Global Insights") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary">Global Insights</button></div>
                            <p className="text-gray-500">Here are all related links - Global Insights:</p>

                            <div><a href="https://lookerstudio.google.com/s/pvzlkb7Tkss">Global AI Legislation, Data Privacy, Cyber Security Latest Updates</a></div>
                            <div><a href="https://lookerstudio.google.com/s/jRVrFsRjVpI">Global Legislative AI, Data Privacy and Cyber Security PDF Data Library</a></div>
                            <div><a href="https://lookerstudio.google.com/s/tuiatHYXZtQ">USA - AI, Cyber Security and Data Privacy Legislative Insights</a></div>
                            <div><a href="https://lookerstudio.google.com/s/okJ-PX_NbH4">GDPR Penalty Dashboard</a></div>
                            <div><a href="https://lookerstudio.google.com/s/qY9HFEbfKxI">Global AI Legislation Focus area</a></div>
                            <div><a href="https://lookerstudio.google.com/s/o0UGgLltwFw">A.I. Legislation Bill Status Updates</a></div>
                            <div><a href="https://lookerstudio.google.com/s/thPdZ_qbbt0">Web Scrap Data Analysis</a></div>
                            <div><a href="https://lookerstudio.google.com/s/lcyB_gZZxUo">Committees Insights</a></div>
                            <div><a href="https://lookerstudio.google.com/s/n1DyKPZezvE">Congress</a></div>
                            <div><a href="https://lookerstudio.google.com/s/iy1prZJncWU">EU Legislative Title Insights</a></div>
                            <div><a href="https://lookerstudio.google.com/s/nRgdKLRa5E">EU Legislative Dashboard</a></div>
                            <div><a href="https://lookerstudio.google.com/s/jqpxx6XPAr0">Top 10 Sponsors Dashboard</a></div>
                            <div><a href="https://lookerstudio.google.com/s/vEmgQ8wOjuY">USA Legislative Data Wordcloud Analytics</a></div>
                        </div>
                    ),
                    sender: "bot"
                }
            ]);

        } else if (buttonText === "Network Security Testing") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary">Network Security Testing</button>
                            </div>
                            <div>
                                <div className='pt-2'><button onClick={() => handleButtonClick("Ports")} className="btn btn-primary px-2 py-1 mr-2">Ports</button></div>
                            </div>
                        </>
                    ),
                    sender: "bot"
                }
            ]);

        } else if (buttonText === "Ports") {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: buttonText, sender: "user" },
                {
                    text: (
                        <div>
                            <div className='pt-2'><button onClick={() => handleButtonClick("Single IP")} className="btn btn-primary px-2 py-1 mr-2">Single IP</button></div>
                            <div className='pt-2'><button onClick={() => handleButtonClick("IP Range")} className="btn btn-primary px-2 py-1 mr-2">IP Range</button></div>
                        </div>
                    ),
                    sender: "bot"
                }
            ]);
            const handleButtonClick = (buttonText) => {
                if (buttonText === "Single IP") {
                    setMessages(prevMessages => [
                        ...prevMessages,
                        { text: buttonText, sender: "user" },
                        {
                            text: (
                                <div>
                                    <div className=''>
                                        <div className='pt-2'>
                                            <input
                                                className="form-control form-control-sm"
                                                id="ipAddressInput"
                                                type="text"
                                                placeholder="IP Address"
                                            />
                                        </div>
                                        <div className='pt-2'>
                                            <button
                                                onClick={() => handleButtonClick("IP check")}
                                                className="btn btn-primary px-2 py-1 mr-2"
                                            >
                                                IP check
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ),
                            sender: "bot"
                        }
                    ]);
                } else if (buttonText === "IP Range") {
                    setMessages(prevMessages => [
                        ...prevMessages,
                        { text: buttonText, sender: "user" },
                        {
                            text: (
                                <div>
                                    <div>
                                        <p className="text-gray-500">IP Range:</p>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Start IP"
                                            className="form-control form-control-sm"
                                        />
                                    </div>
                                    <div className='pt-2'>
                                        <input
                                            type="text"
                                            placeholder="End IP"
                                            className="form-control form-control-sm"
                                        />
                                    </div>
                                    <div className='pt-2'>
                                        <button
                                            onClick={() => handleButtonClick("IP check")}
                                            className="btn btn-primary px-2 py-1 mr-2"
                                        >
                                            IP check
                                        </button>
                                    </div>
                                    <div className='pt-2'>
                                        <input
                                            type="text"
                                            placeholder="outputline"
                                            className="form-control form-control-sm"
                                        />
                                    </div>
                                </div>
                            ),
                            sender: "bot"
                        }
                    ]);
                } else if (buttonText === "IP check") {
                    // Perform IP check and display output
                    const ip = document.getElementById("ipAddressInput").value;
                    if (ip) {
                        // setMessages(prevMessages => [
                        //     ...prevMessages,
                        //     { text: "Loading...", sender: "bot" } // Display loading message
                        // ]);
                        runNmap(ip)
                            .then((openPorts) => {
                                if (result && result.openPorts) {
                                    const openPortsList = result.join(", ");
                                } else {
                                    throw new Error("Invalid response received from the server");
                                }
                            })
                            .catch((error) => {
                                // Handle error if runNmap fails
                                setMessages((prevMessages) => [
                                    ...prevMessages.filter((msg) => msg.text !== "Loading..."), // Remove loading message
                                ]);
                            });
                    } else {
                        // Handle case where no IP address is provided
                        alert("Please enter an IP address.");
                    }
                }
            };
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        // Initial bot message with buttons
        setMessages([
            {
                text:
                    // "Hi, I am here to help you. How can I assist you today?"
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-auto bg-primary text-white p-3 rounded">
                                <p className="m-0">Hi, I am here to help you. How can I assist you today?</p>
                            </div>
                        </div>
                    </div>
                , sender: "bot"
            },
            {
                text: (
                    <div>
                        <button onClick={() => handleButtonClick("Regulatory Insights")} className="mt-2 px-2 py-1 mr-2 btn btn-primary me-1">Regulatory Insights</button>
                        <button onClick={() => handleButtonClick("Compliance Test")} className="mt-2 px-2 py-1 mr-2 btn btn-primary me-1">Compliance Test</button>
                        <button onClick={() => handleButtonClick("AI Governance")} className="mt-2 px-2 py-1 mr-2 btn btn-primary me-1">AI Governance</button>
                        <button onClick={() => handleButtonClick("Awareness")} className="mt-2 px-2 py-1 ml-2 btn btn-primary me-1">Awareness</button>
                        <button onClick={() => handleButtonClick("FAQs")} className="mt-2 px-2 py-1 ml-2 btn btn-primary me-1">FAQs</button>
                        <button onClick={() => handleButtonClick("Network Security Testing")} className="mt-2 px-2 py-1 ml-2 btn btn-primary me-1">Network Security Testing</button>
                    </div>
                ),
                sender: "bot"
            }
        ]);
    }, []);

    const initialBotMessage = () => {
        setMessages([
            { text: "Hi, I am here to help you. How can I assist you today?", sender: "bot" },
            {
                text: (
                    <div>
                        <button onClick={() => handleButtonClick("Regulatory Insights")} className="mt-2 px-2 py-1 btn btn-primary me-1">Regulatory Insights</button>
                        <button onClick={() => handleButtonClick("Compliance Test")} className="mt-2 px-2 py-1 btn btn-primary me-1">Compliance Test</button>
                        <button onClick={() => handleButtonClick("AI Governance")} className="mt-2 px-2 py-1 btn btn-primary me-1">AI Governance</button>
                        <button onClick={() => handleButtonClick("Awareness")} className="mt-2 px-2 py-1 ml-2 btn btn-primary me-1">Awareness</button>
                        <button onClick={() => handleButtonClick("FAQs")} className="mt-2 px-2 py-1 ml-2 btn btn-primary me-1">FAQs</button>
                        <button onClick={() => handleButtonClick("Network Security Testing")} className="mt-2 px-2 py-1 ml-2 btn btn-primary me-1">Network Security Testing</button>
                    </div>
                ),
                sender: "bot"
            }
        ]);
    };

    useEffect(() => {
        if (messages.length > 0 && messages[messages.length - 1].sender === "user") {
            // Simulate bot response after 1 second
            setTimeout(() => {
                setMessages([...messages, {
                    text: (
                        <div>
                            <div className='d-flex justify-content-end mb-2'><button className="btn btn-primary">{searchedText}</button></div>
                            <div>
                                {paragraphsWithTables.map((paragraph, index) => (
                                    <div key={index}>
                                        {paragraph}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ),
                    sender: "bot"
                }]);
            }, 1000);
        }
    }, [messages]);

    const handleRefresh = () => {
        // Add your refresh logic here
        setMessages([]);
        initialBotMessage();
        setFile(null)
    };
    const navigate = useNavigate(); // Initialize useHistory hook

    const handleClose = () => {
        navigate("/home"); // Navigate to the home page
    };

    const handleCloseModal = () => setShow(false);

    const runNmap = async (ip) => {
        try {
            setLoading(true);
            const response = await axios.post(assessmentAndAuditsBaseUrl + assessmentAndAudits.nMapScan, { ip: ip });
            const openPorts = response.data.openPorts;
            const outputLine = `Open ports for IP address ${ip}: ${openPorts.join(
                ", "
            )}`;
            setMessages((prevMessages) => [
                ...prevMessages.filter((msg) => msg.text !== "Loading..."), // Remove loading message
                { text: outputLine, sender: "bot" },
            ]);
            setError(null); // Reset error state if successful
            setLoading(false);
        } catch (error) {
            // Handle errors
            setError("Error fetching data: " + error.message);
            setResult(null); // Reset result state
            setLoading(false);
        }
    };

    return (
        <div>

            <Modal
                show={show}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                // dialogClassName="modal-90w"
                size='lg'
            >
                <Modal.Header closeButton>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <Modal.Title
                            className="fw-bold text-primary"
                            style={{ fontSize: "1rem" }}
                        >
                            AI GOVERNANCE BOT
                        </Modal.Title>
                        <div className="pr-2 rounded-md disabled:pointer-events-none px-2 py-2 " onClick={handleRefresh} >
                            <GoSync size={24} style={{ color: "#939090" }} />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <Loading />
                    ) : (
                        ""
                    )}
                    <>
                        <div>
                            {showPopup && (<div></div>)}
                            <div className={`fixed top-4 right-4  ${minimized ? 'hidden' : ''}`}>
                                <div className="bg-white p-6 rounded-lg border border-[#e5e7eb] w-[440px] h-[634px] overflow-y-auto">
                                    <div className="flex flex-col space-y-1.5 pb-6 pt-[30px]">
                                        <h2 className="font-bold text-lg tracking-tight bg-blue-800 p-2 rounded ">Virtual Assistant(ChatBot)</h2>
                                        <p className="text-sm text-[#6b7280] leading-3 bg-grey-200 p-2 rounded">WELCOME TO AI GOVERNANCE BOT</p>
                                    </div>

                                    <div className="pr-4">
                                        {/* Display messages */}
                                        {messages.map((message, index) => (
                                            <div key={index} className={`flex text-gray-600 text-sm ${message.sender === "user" ? "justify-end" : ""}`}>
                                                <div className={`py-2 px-4 rounded-lg ${message.sender === "user" ? "bg-blue-800" : "bg-gray-100 text-gray-700"}`}>
                                                    {message.text}
                                                </div>
                                            </div>
                                        ))}
                                        <div ref={messagesEndRef} />
                                    </div>
                                    <form onSubmit={handleSendMessage} className="w-full d-flex align-items-center justify-content-center mb-2">
                                        <input
                                            className="flex h-10 w-full rounded-md border px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                                            placeholder="Type your message"
                                            value={inputText}
                                            onChange={handleInputChange}
                                        />
                                        {/* <div className='mx-2'> */}
                                        <input
                                            type="file"
                                            id="fileUpload"
                                            style={{ display: 'none' }}
                                            onChange={fileUploadHandler}
                                        />
                                        <label className='px-3 py-2 btn btn-primary ms-1' htmlFor="fileUpload" style={{ cursor: 'pointer' }}>
                                            {file ? (
                                                <>
                                                    <BsFileEarmarkText />
                                                </>
                                            ) : (
                                                <>
                                                    <BsUpload />
                                                </>
                                            )}
                                        </label>
                                        {/* </div> */}
                                        <button
                                            type="submit"
                                            className="px-4 py-2 btn btn-primary ms-1"
                                        >
                                            Send
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>

                </Modal.Body>
            </Modal>
        </div>
    );
};
export default ChatBot;
