import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { Radio, RadioChangeEvent, Select, Switch, Table } from "antd";
import ScheduleReport from "../pages/reports/ScheduleReport";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import { ACTION_TYPE, COLUMN_REPORTYPE, REPORT_NAME } from "../constant/reportType";
import { getLocalStorageData } from "../utils/LocalStorageService";
import {
  DATA_PIPELINE_COLUMN,
  DATA_RECORDS_COLUMN,
  DATA_RECORDS_REVISED_COLUMN,
  TERM_COLUMN,
  TERM_SET_COLUMN,
  USER_ROLE,
  VALUE_CHAIN_CATEGORY_COLUMN,
  VALUE_CHAIN_COLUMN,
  VALUE_CHAIN_PARAGRAPH_EXTRACT_COLULMN,
} from "../constant/AppConstant";
import { BsDownload, BsSearch } from "react-icons/bs";
import editIcon from "../assets/icons/editicon.svg";
import {
  useDeleteValueChainByIdMutation,
  useGetAllValueChainsMutation,
  useGetAllValueChainsWithSearchMutation,
  useGetValueChainCategoryCountNameMutation,
} from "../redux-toolkit/services/allValueChainsService";
import {
  getAllValueChainsDetails,
  getAllValueChainsDetailsBySearch,
  getValueChainCategoryCountDetails,
  getValueChainTotalRecords,
  setAllValueChains,
  setAllValueChainsBySearch,
  setValueChainCatCount,
} from "../redux-toolkit/slices/allValueChainsSlice";
import {
  getAllValueChainCategoriesBySearchDetails,
  getAllValueChainCategoriesDetails,
  getValueChainCategoriesTotalRecords,
  setAllValueChainCategories,
  setAllValueChainCategoriesBySearch,
} from "../redux-toolkit/slices/allValueChainCategorySlice";
import {
  useDeleteValueChainCategoryByIdMutation,
  useGetAllValueChainCategoriesMutation,
  useGetAllValueChainCategoriesWithSearchMutation,
} from "../redux-toolkit/services/allValueChainCategoriesService";
import {
  useDeleteTermByIdMutation,
  useGetAllTermMutation,
  useGetAllTermWithSearchMutation,
  useGetCountAndTermSetNameMutation,
  useGetTermsStringlistTermsetIdByIdMutation,
  useGetTermsTermsetIdByIdMutation,
} from "../redux-toolkit/services/allTermService";
import {
  getAllTermDetails,
  getAllTermDetailsBySearch,
  getTermSetsCountDetails,
  getTermTotalRecords,
  setAllTerm,
  setAllTermBySearch,
  setTermSetsCountData,
} from "../redux-toolkit/slices/allTermSlice";
import {
  getAllTermSetDetails,
  getAllTermSetDetailsBySearch,
  getTermSetTotalRecords,
  setAllTermSet,
  setAllTermSetBySearch,
} from "../redux-toolkit/slices/allTermSetSlice";
import {
  useDeleteTermSetByIdMutation,
  useGetAllTermSetMutation,
  useGetAllTermSetWithSearchMutation,
} from "../redux-toolkit/services/allTermSetService";
import { alertMessage } from "../utils/AlertService";
import { DATA_PIPELINE_CATEGORY_COLUMN } from "../constant/AppConstant";
import {
  useDeleteDataPipelineCategoryByIdMutation,
  useGetAllDataPipelinesCategoriesMutation,
  useGetAllDataPipelinesCategoriesWithSearchMutation,
} from "../redux-toolkit/services/allDataPipelinesCategoriesService";
import {
  getAllDataPipelinesCategoriesDetails,
  getAllDataPipelinesCategoriesDetailsBySearch,
  getDataPipelineCategoryTotalRecords,
  setAllDataPipelinesCategories,
  setAllDataPipelinesCategoriesBySearch,
} from "../redux-toolkit/slices/allDataPipelinesCategoriesSlice";
import {
  useDeleteDataPipelineByIdMutation,
  useGetAllDataPipelinesMutation,
  useGetAllDataPipelinesWithSearchMutation,
  useGetCountAndPipelineCategoryNameMutation,
} from "../redux-toolkit/services/allDataPipelinesService";
import {
  getDataPipelineCategoryCountDetails,
  getDataPipelineTotalRecords,
  getDataPipelinesDetails,
  getDataPipelinesDetailsBySearch,
  setAllDataPipelines,
  setAllDataPipelinesBySearch,
  setDataPipelineCatCountData,
} from "../redux-toolkit/slices/allDataPipelinesSlice";
import {
  useGetAllValueChainParagraphExtractWithPaginationMutation,
  useGetAllValueChainParagraphExtractWithSearchMutation,
} from "../redux-toolkit/services/allValueChainParagraphExtractService";
import {
  getValueChainParagraphExtractDetailsBySearch,
  getValueChainParagraphExtractTotalRecords,
  getValueChainParagraphExtractWithPaginationDetails,
  setAllValueChainParagraphExtractBySearch,
  setAllValueChainParagraphExtractWithPagination,
} from "../redux-toolkit/slices/allValueChainParagraphExtractSlice";
import {
  useDeleteDataRecordByIdMutation,
  useGetAllDataRecordsWithPaginationMutation,
  useGetAllDataRecordsWithSearchMutation,
} from "../redux-toolkit/services/allDataRecordsService";
import {
  getAllDataRecordsDetailsBySearch,
  getAllDataRecordsTotalRecords,
  getAllDataRecordsWithPaginationDetails,
  setAllDataRecordsBySearch,
  setAllDataRecordsWithPagination,
} from "../redux-toolkit/slices/allDataRecordsSlice";
import { fDateTimeSuffix } from "./utils";
import Loading from "./Loading";
import BarGraphModal from "./BarGraphModal";
import { Excel } from "antd-table-saveas-excel";

const { Option } = Select;

type Props = {};
const DataSourceManagementMaster = (props: Props) => {
  // States
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token: any = getLocalStorageData("localUser").token;
  const localUserObj = getLocalStorageData("localUser");

  //states
  const [currentpage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [reportType, setReportType] = useState(params.reportType) as any;
  const [reportName, setReportName]: any = useState();
  const [call, setCall] = useState("N");
  const [showAdd, setShowAdd] = useState(true);
  const [dataRecord, setDataRecord] = useState([]);
  const [valueChainParagraphExtract, setValueChainParagraphExtract] = useState([]);
  const [valueChain, setValueChain] = useState([]);
  const [dataPipeline, setDataPipeline] = useState([]);
  const [terms, setTerms] = useState([]);
  const [searchValue, setSearchValue] = useState(1);
  const [filterTableForDataRecord, setFilterTableForDataRecord] = useState([]);
  const [series, setSeries]: any = useState();
  const [graphSeries, setGraphSeries]: any = useState();
  const [graphLables, setGraphLables]: any = useState();
  const [sortOrder, setSortOrder]: any = useState(null);
  const [sortedColumn, setSortedColumn]: any = useState("id");
  const [exportData, setExportData]: any = useState([]);

  //columns
  const [valueChainCategoryColumn, setValueChainCategoryColumn]: any = useState(VALUE_CHAIN_CATEGORY_COLUMN);
  const [valueChainColumn, setValueChainColumn]: any = useState(VALUE_CHAIN_COLUMN);
  const [termsColumn, setTermsColumn]: any = useState(TERM_COLUMN);
  const [termSetColumn, setTermSetColumn]: any = useState(TERM_SET_COLUMN);
  const [dataPipelineCategoryColumn, setDataPipelineCategoryColumn]: any = useState(DATA_PIPELINE_CATEGORY_COLUMN);
  const [dataPipelineColumn, setDataPipelineColumn]: any = useState(DATA_PIPELINE_COLUMN);
  const [valueChainParagraphExtractColumn, setValueChainParagraphExtractColumn]: any = useState(VALUE_CHAIN_PARAGRAPH_EXTRACT_COLULMN);
  const [dataRecordsColumn, setDataRecordsColumn]: any = useState(DATA_RECORDS_COLUMN);

  //datasource
  const [valueChainCategoryDatasource, setValueChainCategoryDatasource]: any = useState([]);
  const [valueChainDatasource, setValueChainDatasource]: any = useState([]);
  const [termDataSource, setTermDataSource]: any = useState([]);
  const [termSetDataSource, setTermSetDataSource]: any = useState([]);
  const [dataPipelineCategoryDatasource, setDataPipelineCategoryDatasource]: any = useState([]);
  const [dataPipelineDatasource, setDataPipelineDatasource]: any = useState([]);
  const [valueChainParagraphExtractDataSource, setValueChainParagraphExtractDataSource]: any = useState([]);
  const [dataRecordsDataSource, setDataRecordsDataSource]: any = useState([]);

  //states
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [add, setAdd] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [isTermSet, setIsTermSet] = useState(false);
  const [show, setShow] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState([]);

  //get mutation
  const [getAllValueChains, valueChainsResponse] = useGetAllValueChainsMutation();
  const [getAllValueChainCategories, valueChainCategoriesResponse] = useGetAllValueChainCategoriesMutation();
  const [getAllTerm, getAllTermResponse] = useGetAllTermMutation();
  const [getAllTermSet, getTermSetResponse] = useGetAllTermSetMutation();
  const [getTermsTermsetIdById, getTermsTermsetIdByIdResponse] = useGetTermsTermsetIdByIdMutation();
  const [getTermsStringlistTermsetIdById, getTermsStringlistTermsetIdByIdResponse] = useGetTermsStringlistTermsetIdByIdMutation();
  const [getAllDataPipelinesCategories, dataPipelinesCategoiresResponse] = useGetAllDataPipelinesCategoriesMutation();
  const [getAllDataPipelines, dataPipelinesResponse] = useGetAllDataPipelinesMutation();
  const [getAllValueChainParagraphExtract, valueChainParagraphExtractResponse] = useGetAllValueChainParagraphExtractWithPaginationMutation();
  const [getAllDataRecords, dataRecordsResponse]: any = useGetAllDataRecordsWithPaginationMutation();
  const [getValueChainCategoryCountName, valueChainCategoryCountNameResponse] = useGetValueChainCategoryCountNameMutation();
  const [getDataPiplelineCategoryCountName, dataPiplelineCategoryCountNameResponse] = useGetCountAndPipelineCategoryNameMutation();
  const [getTermSetsCountName, termSetsCountNameResponse] = useGetCountAndTermSetNameMutation();

  //delete mutation
  const [deleteValueChainById, deleteValueChainByIdResponse] = useDeleteValueChainByIdMutation();
  const [deleteTerm, deleteTermResponse] = useDeleteTermByIdMutation();
  const [deleteValueChainCategoryById, deleteValueChainCategoryByIdResponse] = useDeleteValueChainCategoryByIdMutation();
  const [deleteTermSet, deleteTermSetResponse] = useDeleteTermSetByIdMutation();
  const [deleteDataPipelineCategoryById, deleteDataPipelineCategoryByIdResponse] = useDeleteDataPipelineCategoryByIdMutation();
  const [deleteDataPipelineById, deleteDataPipelineByIdResponse] = useDeleteDataPipelineByIdMutation();
  const [deleteDataRecord, deleteDataRecordResponse] = useDeleteDataRecordByIdMutation();

  //search
  const [getAllDataPipelineWithSearch, getAllDataPipelineWithSearchResponse] = useGetAllDataPipelinesWithSearchMutation();
  const [getAllDataPipelineCategoryWithSearch, getAllDataPipelineCategoryWithSearchResponse] = useGetAllDataPipelinesCategoriesWithSearchMutation();
  const [getAllValueChainWithSearch, getAllValueChainWithSearchResponse] = useGetAllValueChainsWithSearchMutation();
  const [getAllValueChainCategoryWithSearch, getAllValueChainCategoryWithSearchResponse] = useGetAllValueChainCategoriesWithSearchMutation();
  const [getAllTermSetWithSearch, getAllTermSetWithSearchResponse] = useGetAllTermSetWithSearchMutation();
  const [getAllTermWithSearch, getAllTermWithSearchResponse] = useGetAllTermWithSearchMutation();
  const [getAllDataRecordWithSearch, getAllDataRecordWithSearchResponse] = useGetAllDataRecordsWithSearchMutation();
  const [getAllValueChainParagraphExtractWithSearch, getAllValueChainParagraphExtractWithSearchResponse] = useGetAllValueChainParagraphExtractWithSearchMutation();

  //selector
  const allValueChains: any = useAppSelector(getAllValueChainsDetails);
  const allValueChainsBySearch: any = useAppSelector(getAllValueChainsDetailsBySearch);
  const allValueChainCategories: any = useAppSelector(getAllValueChainCategoriesDetails);
  const allValueChainCategoriesBySearch: any = useAppSelector(getAllValueChainCategoriesBySearchDetails);
  const allTerm: any = useAppSelector(getAllTermDetails);
  const allTermBySearch: any = useAppSelector(getAllTermDetailsBySearch);
  const allTermSet: any = useAppSelector(getAllTermSetDetails);
  const allTermSetBySearch: any = useAppSelector(getAllTermSetDetailsBySearch);
  const allDataPipelineCategories: any = useAppSelector(getAllDataPipelinesCategoriesDetails);
  const allDataPipelineCategoriesBySearch: any = useAppSelector(getAllDataPipelinesCategoriesDetailsBySearch);
  const allDataPipeline: any = useAppSelector(getDataPipelinesDetails);
  const allDataPipelineBySearch: any = useAppSelector(getDataPipelinesDetailsBySearch);
  const allValueChainParagraphExtract: any = useAppSelector(getValueChainParagraphExtractWithPaginationDetails);
  const allValueChainParagraphExtractBySearch: any = useAppSelector(getValueChainParagraphExtractDetailsBySearch);
  const allDataRecords: any = useAppSelector(getAllDataRecordsWithPaginationDetails);
  const allDataRecordsBySearch: any = useAppSelector(getAllDataRecordsDetailsBySearch);
  const valueChainCategoryCountDetails: any = useAppSelector(getValueChainCategoryCountDetails);
  const dataPipelineCategoryCountDetails: any = useAppSelector(getDataPipelineCategoryCountDetails);
  const termSetsCountDetails: any = useAppSelector(getTermSetsCountDetails);

  //totalRecords
  const totalRecordsValueChainParagraphExtract: any = useAppSelector(
    getValueChainParagraphExtractTotalRecords
  );
  const totalRecordsDataRecords: any = useAppSelector(
    getAllDataRecordsTotalRecords
  );
  const totalRecordsTermSet: any = useAppSelector(getTermSetTotalRecords);
  const totalRecordsTerm: any = useAppSelector(getTermTotalRecords);
  const totalRecordsDataPipeline: any = useAppSelector(
    getDataPipelineTotalRecords
  );
  const totalRecordsDataPipelineCategory: any = useAppSelector(
    getDataPipelineCategoryTotalRecords
  );
  const totalRecordsValueChain: any = useAppSelector(getValueChainTotalRecords);
  const totalRecordsValueChainCategory: any = useAppSelector(
    getValueChainCategoriesTotalRecords
  );

  useEffect(() => {
    if (params.reportType === "Value Chain Category") {
      setReportName("valueChainCategory");
      setIsCategory(true);
      // setShowAdd(true);
      getAllValueChainCategories({
        page: currentpage - 1,
        size: pageSize,
        sort: "id",
      });
      getValueChainCategoryCountName({});
    } else if (params.reportType === "Value Chain") {
      setReportName("valueChain");
      setIsCategory(false);
      // setShowAdd(true);
      getAllValueChains({ page: currentpage - 1, size: pageSize, sort: "id" });
      getAllValueChainCategories({
        page: currentpage - 1,
        size: 100,
        sort: "id",
      });
    } else if (params.reportType === "Data Pipeline Category") {
      setReportName("dataPipelineCategory");
      setIsCategory(true);
      // setShowAdd(true);
      getAllDataPipelinesCategories({
        page: currentpage - 1,
        size: pageSize,
        sort: "id",
      });
      getDataPiplelineCategoryCountName({});
    } else if (params.reportType === "Data Pipeline") {
      setReportName("dataPipeline");
      setIsCategory(false);
      // setShowAdd(true);
      getAllDataPipelines({
        page: currentpage - 1,
        size: pageSize,
        sort: "id",
      });
      getAllDataPipelinesCategories({
        page: currentpage - 1,
        size: 100,
        sort: "id",
      });
    } else if (params.reportType === "Term") {
      setReportName("term");
      setIsCategory(false);
      // setShowAdd(true);
      getAllTerm({ page: currentpage - 1, size: pageSize, sort: "id" });
      getAllTermSet({ page: currentpage - 1, size: 200, sort: "id" });
    } else if (params.reportType === "Term Set") {
      setReportName("termSet");
      setIsCategory(true);
      // setShowAdd(true);
      getAllTermSet({ page: currentpage - 1, size: pageSize, sort: "id" });
      getTermSetsCountName({});
    } else if (params.reportType === "Value Chain Paragraph Extract") {
      setReportName("valueChainParagraphExtract");
      setPageSize(10);
      setShowAdd(false);
      getAllValueChainParagraphExtract({
        page: currentpage - 1,
        size: 10,
        sort: "id",
      });
    } else if (params.reportType === "Data Record") {
      setReportName("dataRecord");
      setPageSize(10);
      // setShowAdd(false);
      getAllDataRecords({
        page: currentpage - 1,
        size: 10,
        sort: "id",
      });
    }
  }, []);

  useEffect(() => {
    if (call === "Y") {
      if (params.reportType === "Value Chain Category") {
        setReportName("valueChainCategory");
        setIsCategory(true);
        getAllValueChainCategories({
          page: currentpage - 1,
          size: pageSize,
          sort: "id",
        });
        setCall("N");
      } else if (params.reportType === "Value Chain") {
        setReportName("valueChain");
        setIsCategory(false);
        getAllValueChains({
          page: currentpage - 1,
          size: pageSize,
          sort: "id",
        });
        setCall("N");
      } else if (params.reportType === "Data Pipeline Category") {
        setReportName("dataPipelineCategory");
        setIsCategory(true);
        getAllDataPipelinesCategories({
          page: currentpage - 1,
          size: pageSize,
          sort: "id",
        });
        setCall("N");
      } else if (params.reportType === "Data Pipeline") {
        setReportName("dataPipeline");
        setIsCategory(false);
        getAllDataPipelines({
          page: currentpage - 1,
          size: pageSize,
          sort: "id",
        });
        setCall("N");
      } else if (params.reportType === "Term") {
        setReportName("term");
        setIsCategory(false);
        getAllTerm({ page: currentpage - 1, size: pageSize, sort: "id" });
        setCall("N");
        // getAllTermSet({});
      } else if (params.reportType === "Term Set") {
        setReportName("termSet");
        setIsCategory(true);
        getAllTermSet({ page: currentpage - 1, size: pageSize, sort: "id" });
        setCall("N");
      } else if (params.reportType === "Data Record") {
        setReportName("dataRecord");
        setPageSize(10);
        setIsCategory(true);
        getAllDataRecords({
          page: currentpage - 1,
          size: 10,
          sort: "id",
        });
        setCall("N");
      }
    }
  }, [call]);

  // useEffect(() => {
  //   if (allValueChains) {
  //     setAllValueChainsData(allValueChains);
  //   }
  // }, [allValueChains]);

  //Set DataSource useEffects

  useEffect(() => {
    if (allValueChains) {
      setValueChainDatasource(allValueChains);
    }
  }, [allValueChains]);

  useEffect(() => {
    if (allValueChainCategories) {
      setValueChainCategoryDatasource(allValueChainCategories);
    }
  }, [allValueChainCategories]);

  useEffect(() => {
    if (allTerm) {
      setTermDataSource(allTerm);
    }
  }, [allTerm]);

  useEffect(() => {
    if (allTermSet) {
      setTermSetDataSource(allTermSet);
    }
  }, [allTermSet]);

  useEffect(() => {
    if (allDataPipelineCategories) {
      setDataPipelineCategoryDatasource(allDataPipelineCategories);
    }
  }, [allDataPipelineCategories]);

  useEffect(() => {
    if (allDataPipeline) {
      setDataPipelineDatasource(allDataPipeline);
    }
  }, [allDataPipeline]);

  useEffect(() => {
    if (allValueChainParagraphExtract) {
      setValueChainParagraphExtractDataSource(allValueChainParagraphExtract);
    }
  }, [allValueChainParagraphExtract]);

  useEffect(() => {
    if (allDataRecords) {
      setDataRecordsDataSource(allDataRecords);
    }
  }, [allDataRecords]);

  //----------Set DataSource useEffects End--------------

  //allvaluechaincategories.......
  useEffect(() => {
    if (valueChainCategoriesResponse.isSuccess) {
      dispatch(setAllValueChainCategories(valueChainCategoriesResponse));
    } else if (valueChainCategoriesResponse.isError) {
      let response: any = valueChainCategoriesResponse.error;
      alertMessage(response, "error");
    }
  }, [valueChainCategoriesResponse.isSuccess, valueChainCategoriesResponse.isError]);

  //allvaluechaincategories By search......
  useEffect(() => {
    if (getAllValueChainCategoryWithSearchResponse.isSuccess) {
      dispatch(setAllValueChainCategoriesBySearch(getAllValueChainCategoryWithSearchResponse));
    } else if (getAllValueChainCategoryWithSearchResponse.isError) {
      let response: any = getAllValueChainCategoryWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllValueChainCategoryWithSearchResponse.isSuccess, getAllValueChainCategoryWithSearchResponse.isError]);

  //get allvalue chains.......
  useEffect(() => {
    if (valueChainsResponse.isSuccess) {
      dispatch(setAllValueChains(valueChainsResponse));
    } else if (valueChainsResponse.isError) {
      let response: any = valueChainsResponse.error;
      alertMessage(response, "error");
    }
  }, [valueChainsResponse.isSuccess, valueChainsResponse.isError]);

  //get all terms
  useEffect(() => {
    if (getAllTermResponse.isSuccess) {
      dispatch(setAllTerm(getAllTermResponse));
    } else if (getAllTermResponse.isError) {
      let response: any = getAllTermResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllTermResponse.isSuccess, getAllTermResponse.isError]);

  //get all termset
  useEffect(() => {
    if (getTermSetResponse.isSuccess) {
      dispatch(setAllTermSet(getTermSetResponse));
    } else if (getTermSetResponse.isError) {
      let response: any = getTermSetResponse.error;
      alertMessage(response, "error");
    }
  }, [getTermSetResponse.isSuccess, getTermSetResponse.isError]);

  //get all datapipeline categories
  useEffect(() => {
    if (dataPipelinesCategoiresResponse.isSuccess) {
      dispatch(setAllDataPipelinesCategories(dataPipelinesCategoiresResponse));
    } else if (dataPipelinesCategoiresResponse.isError) {
      alertMessage("Error while fetching data", "error");
    }
  }, [
    dataPipelinesCategoiresResponse.isSuccess,
    dataPipelinesCategoiresResponse.isError,
  ]);

  //get all datapipelines
  useEffect(() => {
    if (dataPipelinesResponse.isSuccess) {
      dispatch(setAllDataPipelines(dataPipelinesResponse));
    } else if (dataPipelinesResponse.isError) {
      alertMessage("Error while fetching data", "error");
    }
  }, [dataPipelinesResponse.isSuccess, dataPipelinesResponse.isError]);

  //get all value chain paragraph extract
  useEffect(() => {
    if (valueChainParagraphExtractResponse.isSuccess) {
      dispatch(
        setAllValueChainParagraphExtractWithPagination(
          valueChainParagraphExtractResponse
        )
      );
    } else if (valueChainParagraphExtractResponse.isError) {
      alertMessage("Error while fetching data", "error");
    }
  }, [
    valueChainParagraphExtractResponse.isSuccess,
    valueChainParagraphExtractResponse.isError,
  ]);

  //get all data records
  useEffect(() => {
    if (dataRecordsResponse.isSuccess) {
      if (allDataRecords) {
        const newerData = findNewRecords(allDataRecords && allDataRecords, dataRecordsResponse?.data?.content);
        if (newerData.length > 0) {
          setExportData(newerData)
        } else {
          setExportData(dataRecordsResponse?.data?.content)
        }
      }

      dispatch(setAllDataRecordsWithPagination(dataRecordsResponse));

    } else if (dataRecordsResponse.isError) {
      alertMessage("Error while fetching data", "error");
    }
  }, [dataRecordsResponse.isSuccess, dataRecordsResponse.isError]);

  const findNewRecords = (data1: any, data2: any) => {
    const newData = [];

    for (const item2 of data2) {
      let isNew = true;
      for (const item1 of data1) {
        if (item1.id === item2.id) {
          isNew = false;
          break;
        }
      }
      if (isNew) {
        newData.push(item2);
      }
    }

    return newData;
  };

  const handleExportClick = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    console.log(exportData)
    if (reportName === "dataRecord") {
      fileName = REPORT_NAME.DATA_RECORD;
      columns = DATA_RECORDS_REVISED_COLUMN;
      dataSource = exportData;
    }
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true
      })
      .saveAs(`${fileName}.xlsx`);
  };

  //delete..........
  const onDeleteData = (record: any) => {
    if (reportName === "valueChainCategory") {
      deleteValueChainCategoryById({
        id: record.id,
      });
    } else if (reportName === "valueChain") {
      deleteValueChainById({
        id: record.id,
      });
    } else if (reportName === "term") {
      deleteTerm({
        id: record.id,
      });
    } else if (reportName === "termSet") {
      deleteTermSet({
        id: record.id,
      });
    } else if (reportName === "dataPipelineCategory") {
      deleteDataPipelineCategoryById({
        id: record.id,
      });
    } else if (reportName === "dataPipeline") {
      deleteDataPipelineById({
        id: record.id,
      });
    } else if (reportName === "dataRecord") {
      deleteDataRecord({
        id: record.id,
      });
    }
  };

  useEffect(() => {
    if (deleteValueChainCategoryByIdResponse.isSuccess) {
      if (deleteValueChainCategoryByIdResponse.data?.isActive === "Y") {
        alertMessage("Value Chain Category enable successfully", "success");
      } else {
        alertMessage("Value Chain Category deleted successfully", "success");
      }
      setCall("Y");
    } else if (deleteValueChainCategoryByIdResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [
    deleteValueChainCategoryByIdResponse.isSuccess,
    deleteValueChainCategoryByIdResponse.isError,
  ]);

  useEffect(() => {
    if (deleteValueChainByIdResponse.isSuccess) {
      if (deleteValueChainByIdResponse.data?.isActive === "Y") {
        alertMessage("Value Chain enable successfully", "success");
      } else {
        alertMessage("Value Chain deleted successfully", "success");
      }
      setCall("Y");
    } else if (deleteValueChainByIdResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [
    deleteValueChainByIdResponse.isSuccess,
    deleteValueChainByIdResponse.isError,
  ]);

  useEffect(() => {
    if (deleteTermResponse.isSuccess) {
      if (deleteTermResponse.data?.isActive === "Y") {
        alertMessage("Term enable successfully", "success");
      } else {
        alertMessage("Term deleted successfully", "success");
      }
      setCall("Y");
    } else if (deleteTermResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteTermResponse.isSuccess, deleteTermResponse.isError]);

  useEffect(() => {
    if (deleteTermSetResponse.isSuccess) {
      if (deleteTermSetResponse.data?.isActive === "Y") {
        alertMessage("Term Set enable successfully", "success");
      } else {
        alertMessage("Term Set deleted successfully", "success");
      }
      setCall("Y");
    } else if (deleteTermSetResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteTermSetResponse.isSuccess, deleteTermSetResponse.isError]);

  useEffect(() => {
    if (deleteDataPipelineCategoryByIdResponse.isSuccess) {
      if (deleteDataPipelineCategoryByIdResponse.data?.isActive === "Y") {
        alertMessage("Data Pipeline Category enable successfully", "success");
      } else {
        alertMessage("Data Pipeline Category deleted successfully", "success");
      }
      setCall("Y");
    } else if (deleteDataPipelineCategoryByIdResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [
    deleteDataPipelineCategoryByIdResponse.isSuccess,
    deleteDataPipelineCategoryByIdResponse.isError,
  ]);

  useEffect(() => {
    if (deleteDataPipelineByIdResponse.isSuccess) {
      if (deleteDataPipelineByIdResponse.data?.isActive === "Y") {
        alertMessage("Data Pipeline enable successfully", "success");
      } else {
        alertMessage("Data Pipeline deleted successfully", "success");
      }
      setCall("Y");
    } else if (deleteDataPipelineByIdResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [
    deleteDataPipelineByIdResponse.isSuccess,
    deleteDataPipelineByIdResponse.isError,
  ]);

  useEffect(() => {
    if (deleteDataRecordResponse.isSuccess) {
      if (deleteDataRecordResponse.data?.isActive === "Y") {
        alertMessage("Data Record enable successfully", "success");
      } else {
        alertMessage("Data Record deleted successfully", "success");
      }
      setCall("Y");
    } else if (deleteDataRecordResponse.isError) {
      alertMessage("Error while updating", "error");
    }
  }, [deleteDataRecordResponse.isSuccess, deleteDataRecordResponse.isError]);

  useEffect(() => {
    const temp: any = [];
    const tempAction: any = {
      title: "Action",
      dataIndex: "action",
      key: "name",
      fixed: "right",
      width: 120,
      render: (index: any, record: any) => {
        return (
          // for delete switch button
          <div className="d-flex">
            <div className="px-2 pe-auto">
              <Switch
                checked={record.isActive === "Y" ? true : false}
                onClick={() => {
                  onDeleteData(record);
                }}
              />
            </div>
            {record.isActive === "Y" && reportName !== "dataRecord" ? (
              <div
                className="px-2 pe-auto"
                onClick={() => {
                  handleOpenForm(record);
                }}
              >
                <img src={editIcon} alt="update" />
              </div>
            ) : null}
          </div>
        );
      },
    };

    if (reportName === "valueChainCategory" && valueChainCategoryColumn) {
      valueChainCategoryColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setValueChainCategoryColumn(temp);
    } else if (reportName === "valueChain" && valueChainColumn) {
      valueChainColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setValueChainColumn(temp);
    } else if (reportName === "term" && termsColumn) {
      termsColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setTermsColumn(temp);
    } else if (reportName === "termSet" && termSetColumn) {
      termSetColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setTermSetColumn(temp);
    } else if (reportName === "dataPipelineCategory") {
      dataPipelineCategoryColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setDataPipelineCategoryColumn(temp);
    } else if (reportName === "dataPipeline") {
      dataPipelineColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setDataPipelineColumn(temp);
    } else if (reportName === "dataRecord" && dataRecordsColumn && localUserObj?.userType === USER_ROLE.ADMIN) {
      dataRecordsColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setDataRecordsColumn(temp);
    }
  }, [reportName]);

  const paginationChange = (page: number, pageSize: number) => {
    if (reportName === "valueChainParagraphExtract") {
      getAllValueChainParagraphExtract({
        page: page - 1,
        size: 10,
        sort: "id",
      });
    } else if (reportName === "dataRecord") {
      getAllDataRecords({
        page: page - 1,
        size: 10,
        sort: "id",
      });
    } else if (reportName === "termSet") {
      getAllTermSet({
        page: page - 1,
        size: pageSize,
        sort: "id",
      });
    } else if (reportName === "term") {
      getAllTerm({
        page: page - 1,
        size: pageSize,
        sort: "id",
      });
    } else if (reportName === "dataPipeline") {
      getAllDataPipelines({
        page: page - 1,
        size: pageSize,
        sort: "id",
      });
    } else if (reportName === "dataPipelineCategory") {
      getAllDataPipelinesCategories({
        page: page - 1,
        size: pageSize,
        sort: "id",
      });
    } else if (reportName === "valueChain") {
      getAllValueChains({
        page: page - 1,
        size: pageSize,
        sort: "id",
      });
    } else if (reportName === "valueChainCategory") {
      getAllValueChainCategories({
        page: page - 1,
        size: pageSize,
        sort: "id",
      });
    }
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    let ob: any = {
      // offset: pageSize,
      // pageNumber: currentpage - 1,
    };
    if (token) {
      let columnReportType = COLUMN_REPORTYPE.VALUE_CHAIN_CATEGORY;
      switch (params.reportType?.toUpperCase()) {
        case "Value Chain Category".toUpperCase():
          columnReportType = COLUMN_REPORTYPE.VALUE_CHAIN_CATEGORY;
          break;
        case "Value Chain".toUpperCase():
          columnReportType = COLUMN_REPORTYPE.VALUE_CHAIN;
          break;
        case "Data Pipeline Category".toUpperCase():
          columnReportType = COLUMN_REPORTYPE.DATA_PIPELINE_CATEGORY;
          break;
        case "Date Pipeline".toUpperCase():
          columnReportType = COLUMN_REPORTYPE.DATE_PIPELINE;
          break;
        case "Term Set".toUpperCase():
          columnReportType = COLUMN_REPORTYPE.TERM_SET;
          break;
        case "Term".toUpperCase():
          columnReportType = COLUMN_REPORTYPE.TERMS;
          break;
        case "Value Chain Paragraph Extract".toUpperCase():
          columnReportType = COLUMN_REPORTYPE.VALUE_CHAIN_PARAGRAPH_EXTRACT;
          break;
        default:
      }
      setReportType(columnReportType);
      ob.reportType = columnReportType;
    }
  }, []);

  // .....................
  const handleAdd = () => {
    setAdd(true);
  };

  const handleShow = () => setShow(true);
  const handleViewGraph = () => setShowGraph(true);

  const handleOpenForm = (record: any) => {
    setShow(true);
    setActionType(ACTION_TYPE.UPDATE_ACTION);
    setSelectedRow(record);
  };

  // navigate............
  const handleBack = () => {
    navigate("/reports");
  };

  const buildDataRecord = (data: any) => {
    let temp: any = [];
    data.forEach((element: any) => {
      // let payload =
      temp.push({
        id: element.id,
        dataContentLink:
          element.dataContentLink === null ? "-" : element.dataContentLink,
        dataContent: element.dataContent === null || element.dataContent === "" ? "-" : element.dataContent,
        dataQualityStatus:
          element.dataQualityStatus === null ? "-" : element.dataQualityStatus,
        country: element.country === null ? "-" : element.country,
        state: element.state === null ? "-" : element.state,
        city: element.city === null || element.city === "" ? "-" : element.city,
        district: element.district === null ? "-" : element.district,
        createdOn:
          element.createdOn === null ? "-" : fDateTimeSuffix(element.createdOn),
        isActive: element.isActive,
        valueChainCategoryName: element.valueChainCategoryName === null ? "-" : element.valueChainCategoryName,
        valueChainName: element.valueChainName === null ? "-" : element.valueChainName,
        termSetName: element.termSetName === null ? "-" : element.termSetName
      });
    });
    setDataRecord(temp);
    setFilterTableForDataRecord(temp);
  };
  useEffect(() => {
    buildDataRecord(dataRecordsDataSource);
  }, [dataRecordsDataSource]);

  const buildValueChainParagraphExtractDataSource = (data: any) => {
    let temp: any = [];
    data.forEach((element: any) => {
      // let payload =
      temp.push({
        id: element?.id,
        valueChain: element.valueChainObj ? element.valueChainObj.name : "-",
        dataRecord: element.dataRecord,
        dataContentLink: element?.dataRecordObj ? element?.dataRecordObj?.dataContentLink : "-",
        paragraphContent: element.paragraphContent,
        isActive: element.isActive,
      });
    });
    setValueChainParagraphExtract(temp);
  };
  useEffect(() => {
    if (valueChainParagraphExtractDataSource) {
      buildValueChainParagraphExtractDataSource(valueChainParagraphExtractDataSource);
    }
  }, [valueChainParagraphExtractDataSource]);

  const buildValueChainDataSource = (data: any) => {
    let temp: any = [];
    data.forEach((element: any) => {
      // let payload =
      temp.push({
        id: element.id,
        categoryName: element?.valueChainCategoryObj ? element?.valueChainCategoryObj?.name : "-",
        name: element.name,
        isActive: element.isActive,
      });
    });
    setValueChain(temp);
  };

  useEffect(() => {
    if (valueChainDatasource) {
      buildValueChainDataSource(valueChainDatasource);
    }
  }, [valueChainDatasource]);

  const builDataPipelineDatasource = (data: any) => {
    let temp: any = [];
    data.forEach((element: any) => {
      // let payload =
      temp.push({
        id: element?.id,
        pipelineCategoryName: element?.dataPipelineCategoryObj ? element?.dataPipelineCategoryObj?.name : "-",
        name: element?.name,
        isActive: element?.isActive,
      });
    });
    setDataPipeline(temp);
  };

  useEffect(() => {
    if (dataPipelineDatasource) {
      builDataPipelineDatasource(dataPipelineDatasource);
    }
  }, [dataPipelineDatasource]);

  const builTermSetDatasource = (data: any) => {
    let temp: any = [];
    data.forEach((element: any) => {
      // let payload =
      temp.push({
        id: element.id,
        termsetName: element?.termSetObj ? element?.termSetObj?.name : "-",
        name: element.name,
        isActive: element.isActive,
      });
    });
    setTerms(temp);
  };

  useEffect(() => {
    if (termDataSource) {
      builTermSetDatasource(termDataSource);
    }
  }, [termDataSource]);

  const handleSearch = (event: any) => {
    // console.log((event.target.value).length)
    // if ((event.target.value).length >= 3) {
    if (reportName === "term") {
      getAllTermWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === "termSet") {
      getAllTermSetWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === "valueChain") {
      getAllValueChainWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === "valueChainCategory") {
      getAllValueChainCategoryWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === "dataPipeline") {
      getAllDataPipelineWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === "dataPipelineCategory") {
      getAllDataPipelineCategoryWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === "dataRecord") {
      if (searchValue === 1) {
        getAllDataRecordWithSearch({
          token: token,
          payload: { offset: 10, pageNumber: 0, searchBy: event.target.value },
        });
      } else if (searchValue === 2) {

        if (event.target.value !== "") {
          const filterTable: any = dataRecord.filter((o: any) =>
            Object.keys(o).some(k =>
              String(o[k])
                .toLowerCase()
                .includes((event.target.value).toLowerCase())
            )
          );
          setFilterTableForDataRecord(filterTable);
        } else {
          setFilterTableForDataRecord(dataRecord)
        }
      }
    } else if (reportName === "valueChainParagraphExtract") {
      getAllValueChainParagraphExtractWithSearch({
        token: token,
        payload: { offset: 10, pageNumber: 0, searchBy: event.target.value },
      });
    }
    // }
  };

  const onSearchChange = (e: RadioChangeEvent) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (getAllTermWithSearchResponse.isSuccess) {
      dispatch(setAllTermBySearch(getAllTermWithSearchResponse));
    } else if (getAllTermWithSearchResponse.isError) {
      let response: any = getAllTermWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllTermWithSearchResponse.isSuccess,
    getAllTermWithSearchResponse.isError,
  ]);

  useEffect(() => {
    if (getAllTermSetWithSearchResponse.isSuccess) {
      dispatch(setAllTermSetBySearch(getAllTermSetWithSearchResponse));
    } else if (getAllTermSetWithSearchResponse.isError) {
      let response: any = getAllTermSetWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllTermSetWithSearchResponse.isSuccess, getAllTermSetWithSearchResponse.isError]);

  useEffect(() => {
    if (getAllValueChainWithSearchResponse.isSuccess) {
      dispatch(setAllValueChainsBySearch(getAllValueChainWithSearchResponse));
    } else if (getAllValueChainWithSearchResponse.isError) {
      let response: any = getAllValueChainWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllValueChainWithSearchResponse.isSuccess,
    getAllValueChainWithSearchResponse.isError,
  ]);

  useEffect(() => {
    if (getAllValueChainCategoryWithSearchResponse.isSuccess) {
      dispatch(
        setAllValueChainCategoriesBySearch(getAllValueChainCategoryWithSearchResponse)
      );
    } else if (getAllValueChainCategoryWithSearchResponse.isError) {
      let response: any = getAllValueChainCategoryWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllValueChainCategoryWithSearchResponse.isSuccess,
    getAllValueChainCategoryWithSearchResponse.isError,
  ]);

  useEffect(() => {
    if (getAllDataPipelineWithSearchResponse.isSuccess) {
      dispatch(setAllDataPipelinesBySearch(getAllDataPipelineWithSearchResponse));
    } else if (getAllDataPipelineWithSearchResponse.isError) {
      let response: any = getAllDataPipelineWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllDataPipelineWithSearchResponse.isSuccess,
    getAllDataPipelineWithSearchResponse.isError,
  ]);

  useEffect(() => {
    if (getAllDataPipelineCategoryWithSearchResponse.isSuccess) {
      dispatch(setAllDataPipelinesCategoriesBySearch(getAllDataPipelineCategoryWithSearchResponse));
    } else if (getAllDataPipelineCategoryWithSearchResponse.isError) {
      let response: any = getAllDataPipelineCategoryWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllDataPipelineCategoryWithSearchResponse.isSuccess,
    getAllDataPipelineCategoryWithSearchResponse.isError,
  ]);

  useEffect(() => {
    if (getAllDataRecordWithSearchResponse.isSuccess) {
      dispatch(setAllDataRecordsBySearch(getAllDataRecordWithSearchResponse));
    } else if (getAllDataRecordWithSearchResponse.isError) {
      let response: any = getAllDataRecordWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllDataRecordWithSearchResponse.isSuccess,
    getAllDataRecordWithSearchResponse.isError,
  ]);

  useEffect(() => {
    if (getAllValueChainParagraphExtractWithSearchResponse.isSuccess) {
      dispatch(
        setAllValueChainParagraphExtractBySearch(
          getAllValueChainParagraphExtractWithSearchResponse
        )
      );
    } else if (getAllValueChainParagraphExtractWithSearchResponse.isError) {
      let response: any =
        getAllValueChainParagraphExtractWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllValueChainParagraphExtractWithSearchResponse.isSuccess,
    getAllValueChainParagraphExtractWithSearchResponse.isError,
  ]);

  useEffect(() => {
    if (allValueChainCategoriesBySearch) {
      setValueChainCategoryDatasource(allValueChainCategoriesBySearch);
    }
  }, [allValueChainCategoriesBySearch]);

  useEffect(() => {
    if (allValueChainsBySearch) {
      setValueChainDatasource(allValueChainsBySearch);
    }
  }, [allValueChainsBySearch]);

  useEffect(() => {
    if (allDataPipelineCategoriesBySearch) {
      setDataPipelineCategoryDatasource(allDataPipelineCategoriesBySearch);
    }
  }, [allDataPipelineCategoriesBySearch]);

  useEffect(() => {
    if (allDataPipelineBySearch) {
      setDataPipelineDatasource(allDataPipelineBySearch);
    }
  }, [allDataPipelineBySearch]);

  useEffect(() => {
    if (allTermSetBySearch) {
      setTermSetDataSource(allTermSetBySearch);
    }
  }, [allTermSetBySearch]);

  useEffect(() => {
    if (allTermBySearch) {
      setTermDataSource(allTermBySearch);
    }
  }, [allTermBySearch]);

  useEffect(() => {
    if (allDataRecordsBySearch) {
      setDataRecordsDataSource(allDataRecordsBySearch);
    }
  }, [allDataRecordsBySearch]);

  useEffect(() => {
    if (allValueChainParagraphExtractBySearch) {
      setValueChainParagraphExtractDataSource(allValueChainParagraphExtractBySearch);
    }
  }, [allValueChainParagraphExtractBySearch]);

  //graphhhh

  useEffect(() => {
    if (valueChainCategoryCountNameResponse.isSuccess) {
      dispatch(setValueChainCatCount(valueChainCategoryCountNameResponse));
    }
  }, [valueChainCategoryCountNameResponse.isSuccess]);

  useEffect(() => {
    if (dataPiplelineCategoryCountNameResponse.isSuccess) {
      dispatch(setDataPipelineCatCountData(dataPiplelineCategoryCountNameResponse));
    }
  }, [dataPiplelineCategoryCountNameResponse.isSuccess]);

  useEffect(() => {
    if (termSetsCountNameResponse.isSuccess) {
      dispatch(setTermSetsCountData(termSetsCountNameResponse));
    }
  }, [termSetsCountNameResponse.isSuccess]);

  useEffect(() => {
    if (valueChainCategoryCountDetails) {
      generateGraphData(valueChainCategoryCountDetails);
    }
  }, [valueChainCategoryCountDetails]);

  useEffect(() => {
    if (dataPipelineCategoryCountDetails) {
      generateGraphData(dataPipelineCategoryCountDetails);
    }
  }, [dataPipelineCategoryCountDetails]);

  useEffect(() => {
    if (termSetsCountDetails) {
      generateGraphData(termSetsCountDetails);
    }
  }, [termSetsCountDetails]);

  const generateGraphData = (data: any) => {
    let tempLables: any = [];
    let tempSeries: any = [];

    data?.map((item: any) => {
      tempLables.push(item.name);
      tempSeries.push(item.count);
    })

    setGraphLables(tempLables);
    setGraphSeries(tempSeries);
  }

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div className="d-flex flex-column">
            <div className="input-group px-2 mt-1">
              <Form.Control
                type="search"
                placeholder="Search"
                className="border-0 bg-cm-light-primary1"
                aria-label="Search"
                onChange={handleSearch}
              />
              <div className="input-group-append bg-cm-light-primary1 p-1 rounded-end">
                <span className="input-group-text bg-cm-light-primary1 border-0">
                  {/* <img src={gear} alt="" /> */}
                  <BsSearch className=" bg-cm-light-primary1 border-cm-light-primary1" />
                </span>
              </div>
            </div>
            {reportName === "dataRecord" ? (
              <Radio.Group onChange={onSearchChange} value={searchValue} className="mt-1 px-2">
                <Radio value={1}>Comprehensive Search</Radio>
                <Radio value={2}>Web Search</Radio>
              </Radio.Group>
            ) : null}
          </div>
          {showAdd && (
            <div
              onClick={handleShow}
              className={`d-flex px-2 align-items-center mt-1`}
            >
              {/* <h6 className="m-0 px-0">
                  Schedule Reports
                  <AiOutlineClockCircle className="ms-2" />
              </h6> */}
              <div
                className="d-flex align-items-center px-3"
                onClick={handleAdd}
              >
                <Button
                  variant="primary"
                  className="d-flex align-items-center me-0 text-nowrap"
                  onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
                >
                  <BiPlus />
                  {reportName === "dataRecord" ? "Web Search" : "Add"}
                </Button>
              </div>
            </div>
          )}
          {show && (
            <ScheduleReport
              show={show}
              setShow={setShow}
              // actionType={ACTION_TYPE.ADD_ACTION}
              actionType={actionType}
              getAllReportAsync={() => null}
              updateAllReportAsync={({ }) => null}
              reportType={reportType}
              reportName={params.reportType}
              reportNameCode={reportName}
              isCategory={isCategory}
              isTermSet={isTermSet}
              data={selectedRow}
              call={call}
              setCall={setCall}
            />
          )}
        </div>
      </div>
      <div className="d-flex mb-2">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-between w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
          {reportName === "dataRecord" && (
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleExportClick}
            >
              <BsDownload size={16} className="me-2" />
              Export
            </Button>
          )}
        </div>
        {reportName === "dataPipelineCategory" || reportName === "valueChainCategory" || reportName === "termSet" ? (
          <div className="primary mt-2 mt-md-0 d-flex justify-content-md-end w-100 mx-2">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleViewGraph}
            >
              View Graph
            </Button>
          </div>
        ) : null}

        {showGraph && (
          <BarGraphModal
            show={showGraph}
            setShow={setShowGraph}
            reportName={params.reportType}
            chartSeries={graphSeries}
            chartLables={graphLables}
            series={series}
          />
        )}
        {dataRecordsResponse.isLoading ||
          valueChainCategoriesResponse.isLoading ||
          valueChainsResponse.isLoading ||
          getAllTermResponse.isLoading ||
          getTermSetResponse.isLoading ||
          dataPipelinesCategoiresResponse.isLoading ||
          dataPipelinesResponse.isLoading ||
          valueChainParagraphExtractResponse.isLoading ? (
          <Loading />
        ) : (
          ""
        )}
      </div>

      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          rowKey={"id"}
          // scroll={{
          //   x: 1500,
          //   // y: 300
          // }}
          // pagination={{
          //   responsive: true,
          // }}
          pagination={
            reportName === "valueChainParagraphExtract" ||
              reportName === "dataRecord" ||
              reportName === "termSet" ||
              reportName === "term" ||
              reportName === "dataPipeline" ||
              reportName === "dataPipelineCategory" ||
              reportName === "valueChain" ||
              reportName === "valueChainCategory"
              ? {
                total:
                  reportName === "valueChainParagraphExtract"
                    ? totalRecordsValueChainParagraphExtract &&
                    totalRecordsValueChainParagraphExtract
                    : reportName === "dataRecord"
                      ? totalRecordsDataRecords && totalRecordsDataRecords
                      : reportName === "termSet"
                        ? totalRecordsTermSet && totalRecordsTermSet
                        : reportName === "term"
                          ? totalRecordsTerm && totalRecordsTerm
                          : reportName === "dataPipeline"
                            ? totalRecordsDataPipeline && totalRecordsDataPipeline
                            : reportName === "dataPipelineCategory"
                              ? totalRecordsDataPipelineCategory &&
                              totalRecordsDataPipelineCategory
                              : reportName === "valueChain"
                                ? totalRecordsValueChain && totalRecordsValueChain
                                : reportName === "valueChainCategory"
                                  ? totalRecordsValueChainCategory &&
                                  totalRecordsValueChainCategory
                                  : "",
                responsive: true,
                current: currentpage,
                showSizeChanger: true,
                pageSizeOptions: reportName === "dataRecord" ? [10, 20, 30] : [15, 20, 30],
                onChange: (page, pageSize) => {
                  paginationChange(page, pageSize);
                },
                pageSize: pageSize,
              }
              : {
                responsive: true,
              }
          }
          columns={
            reportName === "valueChainCategory"
              ? valueChainCategoryColumn
              : reportName === "valueChain"
                ? valueChainColumn
                : reportName === "term"
                  ? termsColumn
                  : reportName === "termSet"
                    ? termSetColumn
                    : reportName === "dataPipelineCategory"
                      ? dataPipelineCategoryColumn
                      : reportName === "dataPipeline"
                        ? dataPipelineColumn
                        : reportName === "valueChainParagraphExtract"
                          ? valueChainParagraphExtractColumn
                          : reportName === "dataRecord"
                            ? dataRecordsColumn
                            : ""
          }
          dataSource={
            reportName === "valueChainCategory"
              ? valueChainCategoryDatasource
              : reportName === "valueChain"
                ? valueChain
                : reportName === "term"
                  ? terms
                  : reportName === "termSet"
                    ? termSetDataSource
                    : reportName === "dataPipelineCategory"
                      ? dataPipelineCategoryDatasource
                      : reportName === "dataPipeline"
                        ? dataPipeline
                        : reportName === "valueChainParagraphExtract"
                          ? valueChainParagraphExtract
                          : reportName === "dataRecord"
                            ? filterTableForDataRecord
                            : ""
          }
        />
      </div>
    </div>
  );
};
export default DataSourceManagementMaster;
