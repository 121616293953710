import "../dashboard.scss";
import committes from '../../../assets/dashboardImage/CommitteesInsights.png';
import webScrap from '../../../assets/dashboardImage/webScrap.png';
import congress from '../../../assets/dashboardImage/CongressDashboard.png';
import EuLegislative from '../../../assets/dashboardImage/EULegislativeTitleInsights.png';
import GlobalAILegislationPrivacy from '../../../assets/dashboardImage/globalAILDataPrivacy.png';
import GlobalLegislativePDFDataLibrary from '../../../assets/dashboardImage/pdfDatalibrary.png';
import USAAICyberSecurityDataPrivacy from '../../../assets/dashboardImage/USAAICyberSecurityDataPrivacy.png';
import GDPRPenalty from '../../../assets/dashboardImage/GDPRPenalty.png'
import EuLegislativeDashboard from '../../../assets/dashboardImage/EULegislativeDashboard.png';
import Top10Sponsors from '../../../assets/dashboardImage/Top10SponsorsDashboard.png';
import USADataWordcloud from '../../../assets/dashboardImage/USALegislativeDataWordcloudAnalytics.png';
import globalAILFoucusAreas from '../../../assets/dashboardImage/globalAILFoucusAreas.png';
import aiLegislationBillStatusUpdates from '../../../assets/dashboardImage/aiLegislationBillStatusUpdates.png'
import { corporateBigObject } from '../../../constant/mockData';
import { corporateSmallObject } from '../../../constant/mockData'
import { USER_ROLE } from "../../../constant/AppConstant";
import { useEffect, useState } from "react";
import { getLocalStorageData } from "../../../utils/LocalStorageService";

type Props = {
    title?: any;
};

const DashboardCorporateUser = (props: Props) => {
    const [bigObject, setBigObject]: any = useState();
    const [smallObject, setSmallObject]: any = useState();
    const [title, setTitle]: any = useState(USER_ROLE.CORPORATE)

    // useEffect(() => {
    //     const bigTemp: any = [];
    //     const smallTemp: any = [];
    //     if (userBasedRoute === USER_ROLE.CORPORATE) {
    //         corporateBigObject.map((item: any) => {
    //             const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //             bigTemp.push(newItem)
    //         })
    //         corporateSmallObject.map((item: any) => {
    //             const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //             smallTemp.push(newItem)
    //         })
    //         setBigObject(bigTemp)
    //         setSmallObject(smallTemp)
    //     } else if (userBasedRoute === USER_ROLE.GOVERNMENT) {
    //         govBigObject.map((item: any) => {
    //             const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //             bigTemp.push(newItem)
    //         })
    //         govSmallObject.map((item: any) => {
    //             const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //             smallTemp.push(newItem)
    //         })
    //         setBigObject(bigTemp)
    //         setSmallObject(smallTemp)
    //     } else if (userBasedRoute === USER_ROLE.INNOVATOR) {
    //         corporateBigObject.map((item: any) => {
    //             const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //             bigTemp.push(newItem)
    //         })
    //         corporateSmallObject.map((item: any) => {
    //             const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //             smallTemp.push(newItem)
    //         })
    //         setBigObject(bigTemp)
    //         setSmallObject(smallTemp)
    //     } else if (userBasedRoute === USER_ROLE.NGO) {
    //         corporateBigObject.map((item: any) => {
    //             const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //             bigTemp.push(newItem)
    //         })
    //         corporateSmallObject.map((item: any) => {
    //             const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //             smallTemp.push(newItem)
    //         })
    //         setBigObject(bigTemp)
    //         setSmallObject(smallTemp)
    //     }
    // }, [])

    // useEffect(() => {
    //     if (userBasedRoute === USER_ROLE.ADMIN || userBasedRoute === USER_ROLE.BETA_TEST_USER) {
    //         const bigTemp: any = [];
    //         const smallTemp: any = [];
    //         if (props.title === USER_ROLE.CORPORATE) {

    //             corporateBigObject.map((item: any) => {
    //                 const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //                 bigTemp.push(newItem)
    //             })
    //             corporateSmallObject.map((item: any) => {
    //                 const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //                 smallTemp.push(newItem)
    //             })
    //             setBigObject(bigTemp)
    //             setSmallObject(smallTemp)
    //         } else if (props.title === USER_ROLE.GOVERNMENT) {
    //             govBigObject.map((item: any) => {
    //                 const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //                 bigTemp.push(newItem)
    //             })
    //             govSmallObject.map((item: any) => {
    //                 const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //                 smallTemp.push(newItem)
    //             })
    //             setBigObject(bigTemp)
    //             setSmallObject(smallTemp)
    //         } else if (props.title === USER_ROLE.INNOVATOR) {
    //             corporateBigObject.map((item: any) => {
    //                 const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //                 bigTemp.push(newItem)
    //             })
    //             govSmallObject.map((item: any) => {
    //                 const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //                 smallTemp.push(newItem)
    //             })
    //             setBigObject(bigTemp)
    //             setSmallObject(smallTemp)
    //         } else if (props.title === USER_ROLE.NGO) {
    //             corporateBigObject.map((item: any) => {
    //                 const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //                 bigTemp.push(newItem)
    //             })
    //             corporateSmallObject.map((item: any) => {
    //                 const newItem = { ...item, disabled: localUserObj?.permissions ? checkPermissions(item.titleCode, localUserObj?.permissions) : false };
    //                 smallTemp.push(newItem)
    //             })
    //             setBigObject(bigTemp)
    //             setSmallObject(smallTemp)
    //         }
    //     }
    // }, [])
    const localUserObj = getLocalStorageData("localUser");

    useEffect(() => {
        if (props.title) {
            setTitle(props.title);
        }
    }, [title])


    return (
        <>
            <div className="mt-4">
                {/* Inlet */}
                {/* {userBasedRoute !== USER_ROLE.ADMIN ? (
                    <div className="d-flex justify-content-between flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row">
                        <h4 className="title-d">{userBasedRoute && userBasedRoute} Dashboard</h4>
                        <div className="d-flex align-items-center justify-content-start justify-content-md-end"></div>
                    </div>
                ) : null} */}
                {/* Content */}
                <div className="d-flex row px-3 cursor-pointer justify-content-around col-lg-12">
                    {corporateBigObject && corporateBigObject.map((item: any, index: any) => {

                        return (
                            <div key={index.toString()} className="d-flex flex-column col-lg-5 col-md-4 col-sm-12 bg-cm-light-primary1 rounded-3 mx-2 px-3 mt-3"
                                onClick={() => {
                                    // if (item.disabled) {
                                        window.open(item.link, '_blank');
                                    // }
                                }}>
                                <p className="chart-title text-dark text-start mt-2">
                                    {/* {!item.disabled ? (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="overlay-example" {...props}>
                                                    If you want to access this please contact info@capitoltunnels.ai
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                                {" "}
                                                {item.title}
                                            </p>
                                        </OverlayTrigger>
                                    ) : ( */}
                                        <span className={`text-dark ${!item.disabled ? 'disabled' : null} fw-bold fs-6`}>
                                            {" "}
                                            {item.title}
                                        </span>
                                    {/* )} */}
                                </p>
                                <img
                                    src={item.image === "GlobalAILegislationPrivacy" ? GlobalAILegislationPrivacy
                                        : item.image === "GlobalLegislativePDFDataLibrary" ? GlobalLegislativePDFDataLibrary
                                            : item.image === "USAAICyberSecurityDataPrivacy" ? USAAICyberSecurityDataPrivacy
                                                : item.image === "GDPRPenalty" ? GDPRPenalty
                                                    : item.image === "globalAILFoucusAreas" ? globalAILFoucusAreas
                                                        : item.image === "aiLegislationBillStatusUpdates" ? aiLegislationBillStatusUpdates
                                                            : ""}
                                    className="mb-3 rounded px-3"
                                />
                            </div>
                        )
                    })}
                </div>
                <div className="d-flex row px-3 cursor-pointer justify-content-around col-lg-12">
                    {/* flex-column flex-sm-row */}
                    {corporateSmallObject && corporateSmallObject.map((item: any, index: any) => {
                        return (
                            <div key={index.toString()} className="d-flex flex-column col-lg-3 col-md-3 col-sm-12 bg-cm-light-primary1 rounded-3 mx-2 px-3 mt-3"
                                onClick={() => {
                                    // if (item.disabled) {
                                        window.open(item.link, '_blank');
                                    // }
                                }}>
                                <p className="chart-title text-dark text-start mt-2">
                                    {/* {!item.disabled ? (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="overlay-example" {...props}>
                                                    If you want to access this please contact info@capitoltunnels.ai
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                                {" "}
                                                {item.title}
                                            </p>
                                        </OverlayTrigger>
                                    ) : ( */}
                                        <p className={`text-dark ${!item.disabled ? 'disabled' : null}`}>
                                            {" "}
                                            {item.title}
                                        </p>
                                    {/* )}s */}
                                </p>
                                <img
                                    src={item.image === "webScrap" ? webScrap
                                        : item.image === "committes" ? committes
                                            : item.image === "congress" ? congress
                                                : item.image === "EuLegislative" ? EuLegislative
                                                    : item.image === "EuLegislativeDashboard" ? EuLegislativeDashboard
                                                        : item.image === "Top10Sponsors" ? Top10Sponsors
                                                            : item.image === "USADataWordcloud" ? USADataWordcloud
                                                                : ""}
                                    className="mb-3 rounded px-3"
                                />
                            </div>
                        )
                    })}
                </div>
                <footer
                    style={{
                        padding: "20px",
                        textAlign: "center",
                        borderTop: "2px solid #EEF3FF",
                        // marginTop: "5%",
                    }}
                >
                    <p style={{ fontSize: "1.0rem", color: "#4D4D4D" }}>
                        Capitol Tunnels.ai &#169; 2023
                    </p>
                </footer>
            </div>
        </>
    );
};
export default DashboardCorporateUser;
