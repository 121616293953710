import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface VulnerabiltyState {
  data: [] | null | any;
  codeAiBiasAnalysisData: [] | null | any;
  fileName: string | null;
  filePath: string | null;
  aiAlgAccReliabilityTest: [] | null | any;
  featureDominanceData: [] | null | any;
  codeReviewData: [] | null | any;
}
const initialState: VulnerabiltyState = {
  data: null,
  codeAiBiasAnalysisData: null,
  fileName: null,
  aiAlgAccReliabilityTest: null,
  featureDominanceData: null,
  filePath: null,
  codeReviewData: null
};
export const   vulnerabiltySlice = createSlice({
  name: 'vulnerabilty',
  initialState,
  reducers: {
    setVulnerabilty: (state, action) => {
      state.data = action.payload.data.data;
      // state.fileName = action.payload.data.data[0].fileName;
    },
    setCodeAIBiasAnalysis: (state, action) => {
      state.codeAiBiasAnalysisData = action.payload.data.data;
      state.filePath = action.payload.data.path;
    },
    setAiAlgAccReliabilityTest: (state, action) => {
      state.aiAlgAccReliabilityTest = action.payload.data.results;
      // state.fileName = action.payload.data.data[0].fileName;
    },
    setFeatureDominance: (state, action) => {
      state.featureDominanceData = action.payload.data.Feature_Importances;
      // state.fileName = action.payload.data.data[0].fileName;
    },
    setCodeReviewData: (state, action) => {
      state.codeReviewData = action.payload.data;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setVulnerabilty, setCodeAIBiasAnalysis, setAiAlgAccReliabilityTest, setFeatureDominance, setCodeReviewData, reset } = vulnerabiltySlice.actions;
export const getVulnerabiltyDetails = (state: RootState) => state.vulnerabilty.data;
export const getCodeAIBiasAnalysisDetails = (state: RootState) => state.vulnerabilty.codeAiBiasAnalysisData;
export const getCodeAIBiasAnalysisFilePath = (state: RootState) => state.vulnerabilty.filePath
export const getAiAlgAccReliabilityTestDetails = (state: RootState) => state.vulnerabilty.aiAlgAccReliabilityTest;
export const getFeatureDominanceDetails = (state: RootState) => state.vulnerabilty.featureDominanceData;
export const getCodeReviewDetails = (state: RootState) => state.vulnerabilty.codeReviewData;
// export const getVulnerabiltyFileName = (state: RootState) => state.vulnerabilty.fileName;
export default vulnerabiltySlice.reducer;