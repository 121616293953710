import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManagement, userManagementBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const sectionMasterService = createApi({
    reducerPath: 'sectionMasterService',
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ['SectionMaster'],
    endpoints: (builder) => ({
        getAllSectionMaster: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.sectionMasterController.getAllSectionMaster}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        getSectionMasterById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.sectionMasterController.getSectionMasterById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        saveSectionMaster: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.sectionMasterController.saveSectionMaster}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateSectionMaster: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.sectionMasterController.updateSectionMaster}/${body.payload.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteSectionMaster: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.sectionMasterController.deleteSectionMaster}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllSectionMasterWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.sectionMasterController.getAllSectionMasterWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        findAllSectionMaster: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.sectionMasterController.findAllSectionMaster}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
    })
  });
  export const {
    useGetAllSectionMasterMutation,
    useSaveSectionMasterMutation,
    useUpdateSectionMasterMutation,
    useDeleteSectionMasterMutation,
    useGetSectionMasterByIdMutation,
    useGetAllSectionMasterWithSearchMutation,
    useFindAllSectionMasterMutation,
  } = sectionMasterService;