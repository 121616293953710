import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface CompanyState {
  data: [] | null | any;
  projectData: [] | null | any;
  evidenceData: [] | null | any;
  assessmentData: [] | null | any;
  dataLineageData: [] | null | any;
}
const initialState: CompanyState = {
  data: null,
  projectData: null,
  evidenceData: null,
  assessmentData: null,
  dataLineageData: null,
};
export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.data = action.payload.data;
    },
    setProjects: (state, action) => {
      state.projectData = action.payload.data;
    },
    setEvidences: (state, action) => {
      state.evidenceData = action.payload.data;
    },
    setAssessment: (state, action) => {
      state.assessmentData = action.payload.data;
    },
    setDataLineage: (state, action) => {
      state.dataLineageData = action.payload.data;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setCompany, setProjects, setDataLineage, reset } = companySlice.actions;
export const getAllCompaniesDetails = (state: RootState) => state.company.data;
export const getAllProjectDetails = (state: RootState) => state.company.projectData;
export const getEvidenceDetails = (state: RootState) => state.company.evidenceData;
export const getAssessmentDetails = (state: RootState) => state.company.assessmentData;
export const getDataLineageDetails = (state: RootState) => state.company.dataLineageData;
export default companySlice.reducer;