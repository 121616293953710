import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { Select, Switch, Table } from "antd";
import ScheduleReport from "../pages/reports/ScheduleReport";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import { ACTION_TYPE, REPORT_NAME, REPORT_TYPE } from "../constant/reportType";
import { format } from "date-fns";
import { getLocalStorageData } from "../utils/LocalStorageService";
import { BsSearch } from "react-icons/bs";
import {
  USER_ACTIVITY_COLUMN,
  USER_COLUMN,
  USER_GROUP_COLUMN,
  USER_ROLE_COLUMN,
} from "../constant/AppConstant";
import allUserGroupSlice, {
  getAllUserGroupDetails,
  setAllUserGroup,
} from "../redux-toolkit/slices/allUserGroupSlice";
import {
  useDeleteUserRoleByIdMutation,
  useGetAllUserRoleMutation,
} from "../redux-toolkit/services/userRoleService";
import {
  useDeleteUserGroupByIdMutation,
  useGetAllUserGroupMutation,
} from "../redux-toolkit/services/userGroupService";
import {
  getAllUserRoleDetails,
  setAllUserRole,
} from "../redux-toolkit/slices/allUserRoleSlice";
import { alertMessage } from "../utils/AlertService";
import editIcon from "../assets/icons/editicon.svg";
import {
  useDeleteUserByIdMutation,
  useGetAllUserMutation,
  useGetAllUserWithSearchMutation,
  useGetUserByIdMutation,
  useUpdateUserMutation,
} from "../redux-toolkit/services/allUserService";
import {
  getAllUsersTotalRecords,
  getAllUsersWithPaginationDetails,
  setAllUserWithPagination,
  setFindUserByIdUser,
} from "../redux-toolkit/slices/allUserSlice";
import Loading from "./Loading";
import { useGetAuditLogMutation } from "../redux-toolkit/services/auditLogService";
import { getAuditLogDetails, getAuditLogTotalRecords, setAuditLog } from "../redux-toolkit/slices/auditLogSlice";

const { Option } = Select;
type Props = {};
const UserMaster = (props: Props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token: any = getLocalStorageData("localUser").token;
  const localUserObj = getLocalStorageData("localUser");

  // States
  const [show, setShow] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [currentpage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(params.reportType === REPORT_NAME.USER_ACTIVITY ? 10 : 15);
  const [reportType, setReportType] = useState(params.reportType) as any;
  const [reportName, setReportName]: any = useState();
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [selectedRow, setSelectedRow]: any = useState([]);
  const [isCategory, setIsCategory] = useState(false);
  const [call, setCall] = useState("N");
  const [userData, setUserData] = useState([]);

  //columns
  const [userRoleColumn, setUserRoleColumn]: any = useState(USER_ROLE_COLUMN);
  const [userGroupColumn, setUserGroupColumn]: any =
    useState(USER_GROUP_COLUMN);
  const [userColumn, setUserColumn]: any = useState(USER_COLUMN);
  const [userActivityColumn, setUserActivityColumn]: any = useState(USER_ACTIVITY_COLUMN);

  //datasource
  const [userRoleDataSource, setUserRoleDataSource]: any = useState([]);
  const [userGroupDataSource, serUserGroupDataSource]: any = useState([]);
  const [userDataSource, setUserDataSource]: any = useState([]);
  const [userActivityDataSource, setUserActivityDataSource]: any = useState([]);

  //get mutation
  const [getAllUserRole, getAllUserRoleResponse] = useGetAllUserRoleMutation();
  const [getAllUserGroup, getAllUserGroupResponse] =
    useGetAllUserGroupMutation();
  const [getAllUser, getAllUserResponse] = useGetAllUserMutation();
  const [getAllUserActivity, getAllUserActivityResponse] = useGetAuditLogMutation();
  const [findUserById, findUserByIdResponse] = useGetUserByIdMutation();

  //delete mutation
  const [deleteUserRole, deleteUserRoleResponse] =
    useDeleteUserRoleByIdMutation();
  const [deleteUserGroup, deleteUserGroupResponse] =
    useDeleteUserGroupByIdMutation();
  const [deleteUser, deleteUserResponse] = useDeleteUserByIdMutation();

  const [updateUser, updateUserResponse] = useUpdateUserMutation();

  //search
  const [getAllUsersWithSearch, getAllUsersWithSearchResponse] =
    useGetAllUserWithSearchMutation();

  //selector
  const allUserRole: any = useAppSelector(getAllUserRoleDetails);
  const allUserGroup: any = useAppSelector(getAllUserGroupDetails);
  const allUser: any = useAppSelector(getAllUsersWithPaginationDetails);
  const allUsersTotalRecords: any = useAppSelector(getAllUsersTotalRecords);
  const allUserActivityData: any = useAppSelector(getAuditLogDetails);
  const allUserActivityTotalRecords: any = useAppSelector(getAuditLogTotalRecords);

  useEffect(() => {
    if (params.reportType === "User Group") {
      setReportName("userGroup");
      setIsCategory(true);
      getAllUserGroup({ token: token });
    } else if (params.reportType === "Subscriber") {
      setReportName("userRole");
      setIsCategory(true);
      getAllUserRole({ token: token });
    } else if (params.reportType === "Users") {
      setReportName("user");
      setIsCategory(true);
      getAllUser({
        token: token,
        offset: offset,
        page: currentpage - 1,
        orderBy: "asc",
      });
    } else if (params.reportType === REPORT_NAME.USER_ACTIVITY) {
      setReportName(REPORT_NAME.USER_ACTIVITY)
      getAllUserActivity({
        token: token,
        payload: {
          offset: offset,
          pageNumber: currentpage - 1,
          // searchValue: "",
        }
      })
    }
  }, []);

  useEffect(() => {
    if (call === "Y") {
      if (params.reportType === "User Group") {
        setReportName("userGroup");
        setIsCategory(true);
        getAllUserGroup({ token: token });
        setCall("N");
      } else if (params.reportType === "Subscriber") {
        setReportName("userRole");
        setIsCategory(true);
        getAllUserRole({ token: token });
        setCall("N");
      } else if (params.reportType === "Users") {
        setReportName("user");
        setIsCategory(true);
        getAllUser({
          token: token,
          offset: offset,
          page: currentpage - 1,
          orderBy: "asc",
        });
        findUserById({
          id: localUserObj.id,
          token: localUserObj.token
        });
        setCall("N");
      }
    }
  }, [call]);

  useEffect(()=> {
    findUserById({
      id: localUserObj.id,
      token: localUserObj.token
    });
  },[])

  useEffect(() => {
    if (findUserByIdResponse.isSuccess) {
      localStorage.setItem("paymentStatus", findUserByIdResponse?.data?.paymentStatus ? findUserByIdResponse?.data?.paymentStatus : "false");
      localStorage.setItem("localUser", JSON.stringify(localUserObj));
      dispatch(setFindUserByIdUser(findUserByIdResponse));
    }
  }, [findUserByIdResponse.isSuccess]);

  useEffect(() => {
    if (allUserRole) {
      setUserRoleDataSource(allUserRole);
    }
  }, [allUserRole]);

  useEffect(() => {
    if (allUserGroup) {
      serUserGroupDataSource(allUserGroup);
    }
  }, [allUserGroup]);

  useEffect(() => {
    if (allUser) {
      setUserDataSource(allUser);
    }
  }, [allUser]);

  useEffect(() => {
    if (allUserActivityData) {
      setUserActivityDataSource(allUserActivityData);
    }
  }, [allUserActivityData]);

  useEffect(() => {
    if (getAllUserRoleResponse.isSuccess) {
      dispatch(setAllUserRole(getAllUserRoleResponse));
    } else if (getAllUserRoleResponse.isError) {
      let response: any = getAllUserRoleResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllUserRoleResponse.isSuccess, getAllUserRoleResponse.isError]);

  useEffect(() => {
    if (getAllUserGroupResponse.isSuccess) {
      dispatch(setAllUserGroup(getAllUserGroupResponse));
    } else if (getAllUserGroupResponse.isError) {
      let response: any = getAllUserGroupResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllUserGroupResponse.isSuccess, getAllUserGroupResponse.isError]);

  useEffect(() => {
    if (getAllUserResponse.isSuccess) {
      dispatch(setAllUserWithPagination(getAllUserResponse));
    } else if (getAllUserResponse.isError) {
      let response: any = getAllUserResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllUserResponse.isSuccess, getAllUserResponse.isError]);

  useEffect(() => {
    if (getAllUserActivityResponse.isSuccess) {
      dispatch(setAuditLog(getAllUserActivityResponse));
    } else if (getAllUserActivityResponse.isError) {
      let response: any = getAllUserActivityResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllUserActivityResponse.isSuccess, getAllUserActivityResponse.isError]);


  //delete..........
  const onDeleteData = (record: any) => {
    if (reportName === "userRole") {
      deleteUserRole({
        token: token,
        id: record.id,
      });
    } else if (reportName === "userGroup") {
      deleteUserGroup({
        token: token,
        id: record.id,
      });
    } else if (reportName === "user") {
      deleteUser({
        token: token,
        id: record.id,
        // payload: {
        //   // id:0,
        //   isEnabled: record.isEnabled === true ? false : true,
        //   enabled: record.enabled === true ? false : true,
        //   isActive:record.enabled === true ? false : true,
        //   address: record.address,
        //   briefProfile: record.briefProfile,
        //   cityId: record.cityId,
        //   committeeName: record.committeeName,
        //   countryId: record.countryId,
        //   domain: record.domain,
        //   email: record.email,
        //   firstName: record.firstName,
        //   lastName: record.lastName,
        //   linkedinRef: record.linkedinRef,
        //   organizationName: record.organizationName,
        //   password: "12345",
        //   phoneNumber: record.phoneNumber,
        //   photo: record.photo,
        //   pinCode: record.pinCode,
        //   skills: record.skills,
        //   stateId: record.stateId,
        //   userRoleId: record.userRoleId,
        //   userGroupId: record.userGroupId,
        //   username: record.email,
        // },
      });
    }
  };

  useEffect(() => {
    if (updateUserResponse.isSuccess) {
      alertMessage("User updated successfully", "success");
      setCall("Y");
    } else if (updateUserResponse.isError) {
      let response: any = updateUserResponse?.error;
      alertMessage(response, "error");
    }
  }, [updateUserResponse.isSuccess, updateUserResponse.isError]);

  useEffect(() => {
    if (deleteUserRoleResponse.isSuccess) {
      alertMessage("User role status updated successfully", "success");
      setCall("Y");
    } else if (deleteUserRoleResponse.isError) {
      let response: any = deleteUserRoleResponse.error;
      alertMessage(response, "error");
    }
  }, [deleteUserRoleResponse.isSuccess, deleteUserRoleResponse.isError]);

  useEffect(() => {
    if (deleteUserGroupResponse.isSuccess) {
      alertMessage("User group status updated successfully", "success");
      setCall("Y");
    } else if (deleteUserGroupResponse.isError) {
      let response: any = deleteUserGroupResponse.error;
      alertMessage(response, "error");
    }
  }, [deleteUserGroupResponse.isSuccess, deleteUserGroupResponse.isError]);

  useEffect(() => {
    if (deleteUserResponse.isSuccess) {
      alertMessage("User status updated successfully", "success");
      setCall("Y");
    } else if (deleteUserResponse.isError) {
      let response: any = deleteUserResponse.error;
      alertMessage(response, "error");
    }
  }, [deleteUserResponse.isSuccess, deleteUserResponse.isError]);

  const handleOpenForm = (record: any) => {
    setShow(true);
    setActionType(ACTION_TYPE.UPDATE_ACTION);
    setSelectedRow(record);
  };
  useEffect(() => {
    const temp: any = [];
    const tempAction: any = {
      title: "Action",
      dataIndex: "action",
      key: "name",
      fixed: "right",
      render: (index: any, record: any) => {
        return (
          // for delete switch button
          <div className="d-flex">
            <div className="px-2 pe-auto">
              <Switch
                checked={
                  record.status === true ||
                    record.enabled === true ||
                    record.isEnabled === true
                    ? true
                    : false
                }
                onClick={() => {
                  onDeleteData(record);
                }}
              />
            </div>
            {record.status === true ||
              record.enabled === true ||
              record.isEnabled === true ? (
              <div
                className="px-2 pe-auto"
                onClick={() => {
                  handleOpenForm(record);
                }}
              >
                <img src={editIcon} alt="update" />
              </div>
            ) : null}
          </div>
        );
      },
    };
    if (reportName === "userGroup" && userGroupColumn) {
      userGroupColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setUserGroupColumn(temp);
    } else if (reportName === "userRole" && userRoleColumn) {
      userRoleColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setUserRoleColumn(temp);
    } else if (reportName === "user" && userColumn) {
      userColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setUserColumn(temp);
    }
  }, [reportName]);

  const paginationChange = (page: number, offset: number) => {
    if (reportName === "user") {
      getAllUser({
        page: page - 1,
        offset: offset,
        orderBy: "asc",
      });
    } else if (reportName === REPORT_NAME.USER_ACTIVITY) {
      getAllUserActivity({
        token: token,
        payload: {
          offset: offset,
          pageNumber: page - 1,
          // searchValue: "",
        }
      })
    }
    setCurrentPage(page);
    setOffset(offset);
  };

  // useEffect(() => {
  //   let ob: any = {
  //     offset: offset,
  //     pageNumber: currentpage - 1,
  //   };
  //   if (token) {
  //     let columnReportType = COLUMN_REPORTYPE.USER_GROUP;
  //     let resourceIdForReport = RESOURCE_ID.SQL_VMS;
  //     switch (params.reportType?.toUpperCase()) {
  //       case "User Group".toUpperCase():
  //         columnReportType = COLUMN_REPORTYPE.USER_GROUP;
  //         resourceIdForReport = RESOURCE_ID.SQL_VMS;
  //         break;
  //       case "User Role".toUpperCase():
  //         columnReportType = COLUMN_REPORTYPE.USER_ROLE;
  //         resourceIdForReport = RESOURCE_ID.SQL_VMS;
  //         break;
  //       case "Users".toUpperCase():
  //         columnReportType = COLUMN_REPORTYPE.USER;
  //         resourceIdForReport = RESOURCE_ID.SQL_VMS;
  //         break;
  //       default:
  //     }
  //     setReportType(columnReportType);
  //     ob.reportType = columnReportType;
  //   }
  // }, []);

  const handleShow = () => setShow(true);

  // navigate............
  const handleBack = () => {
    navigate("/reports");
  };

  const buildUserData = (data: any) => {
    let temp: any = [];
    data.forEach((element: any) => {
      // let payload =
      temp.push({
        id: element.id,
        name:
          (element.firstName === null ? "" : element.firstName) +
          " " +
          (element.lastName === null ? "" : element.lastName),
        phoneNumber: (element.phoneNumber === null || element.phoneNumber === "") ? "-" : element.phoneNumber,
        email: element.email === null ? "-" : element.email,
        userRole: element.userRole.name === null ? "-" : element.userRole.name,
        userRoleId: element.userRole.id === null ? "-" : element.userRole.id,
        userGroup:
          element.userGroup.name === null ? "-" : element.userGroup.name,
        userGroupId: element.userGroup.id === null ? "-" : element.userGroup.id,
        countryId: element.countryId === null ? "-" : element.countryId,
        stateId: element.stateId === null ? "-" : element.stateId,
        enabled: element.enabled,
      });
    });
    setUserData(temp);
  };

  useEffect(() => {
    if (userDataSource) {
      buildUserData(userDataSource);
    }
  }, [userDataSource]);

  const handleSearch = (event: any) => {
    // if ((event.target.value).length >= 3 || event.target.value == 0) {
    if (reportName === "user") {
      getAllUsersWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === REPORT_NAME.USER_ACTIVITY) {
      getAllUserActivity({
        token: token,
        payload: {
          offset: 100,
          pageNumber: 0,
          searchValue: event.target.value
        }
      })
    }
    // }
  };

  useEffect(() => {
    if (getAllUsersWithSearchResponse.isSuccess) {
      dispatch(setAllUserWithPagination(getAllUsersWithSearchResponse));
    } else if (getAllUsersWithSearchResponse.isError) {
      let response: any = getAllUsersWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllUsersWithSearchResponse.isSuccess,
    getAllUsersWithSearchResponse.isError,
  ]);

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">

          {reportName === "user" || reportName === REPORT_NAME.USER_ACTIVITY  ? (
            <div className="input-group px-2 mt-1">
              <Form.Control
                type="search"
                placeholder="Search"
                className="border-0  bg-cm-light-primary1"
                aria-label="Search"
                onChange={handleSearch}
              />
              <div className="input-group-append bg-cm-light-primary1 p-1 rounded-end">
                <span className="input-group-text bg-cm-light-primary1 border-0">
                  {/* <img src={gear} alt="" /> */}
                  <BsSearch className=" bg-cm-light-primary1 border-cm-light-primary1" />

                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          {params.reportType === "User Activity" ? null : (
            <div onClick={handleShow} className="d-flex px-2 align-items-center">
              <Button
                variant="primary"
                className="d-flex align-items-center me-0"
                onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
              >
                <BiPlus />
                Add
              </Button>
            </div>
          )}
          {/* <div onClick={handleShow} className="d-flex px-2 align-items-center">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
            >
              <BiPlus />
              {params.reportType === "User Activity" ? "View Map" : "Add"}
            </Button>
          </div> */}
          {show && (
            <ScheduleReport
              show={show}
              setShow={setShow}
              actionType={actionType}
              getAllReportAsync={() => null}
              updateAllReportAsync={({ }) => null}
              reportType={reportType}
              reportName={params.reportType}
              reportNameCode={reportName}
              isCategory={isCategory}
              data={selectedRow}
              call={call}
              setCall={setCall}
            />
          )}
        </div>
      </div>
      {/* <div className="d-flex align-items-center justify-content-between  mb-3">
            <div className="input-group px-2 w-50">
              <Form.Control
                type="search"
                placeholder="Search"
                className="border-0"
                aria-label="Search"
                // onChange={handleSearch}
              />
              <span className="input-group-text bg-white border-white">
                {" "}
                <BsSearch className=" bg-white border-white" />
              </span>
            </div>
          </div> */}
      <div className="d-flex ">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {getAllUserRoleResponse.isLoading ||
          getAllUserGroupResponse.isLoading ||
          getAllUserResponse.isLoading ||
          getAllUsersWithSearchResponse.isLoading ||
          deleteUserRoleResponse.isLoading ||
          deleteUserGroupResponse.isLoading ||
          deleteUserResponse.isLoading ||
          getAllUserActivityResponse.isLoading ? (
          <Loading />
        ) : (
          ""
        )}
      </div>
      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          rowKey={"id"}
          scroll={{
            x: "max-content",
            // y: 300
          }}
          pagination={
            reportName === "user"
              ? {
                total: allUsersTotalRecords && allUsersTotalRecords,
                responsive: true,
                current: currentpage,
                showSizeChanger: true,
                pageSizeOptions: [15, 20, 30],
                onChange: (page, offset) => {
                  paginationChange(page, offset);
                },
                pageSize: offset,
              }
              : reportName === REPORT_NAME.USER_ACTIVITY
                ? {
                  total: allUserActivityTotalRecords && allUserActivityTotalRecords,
                  responsive: true,
                  current: currentpage,
                  showSizeChanger: true,
                  pageSizeOptions: [10, 20, 30],
                  onChange: (page, offset) => {
                    paginationChange(page, offset);
                  },
                  pageSize: offset,
                }
                : {
                  responsive: true,
                }
          }
          columns={
            reportName === "userGroup"
              ? userGroupColumn
              : reportName === "userRole"
                ? userRoleColumn
                : reportName === "user"
                  ? userColumn
                  : reportName === REPORT_NAME.USER_ACTIVITY
                    ? userActivityColumn
                    : ""
          }
          dataSource={
            reportName === "userGroup"
              ? userGroupDataSource
              : reportName === "userRole"
                ? userRoleDataSource
                : reportName === "user"
                  ? userData
                  : reportName === REPORT_NAME.USER_ACTIVITY
                    ? userActivityDataSource
                    : ""
          }
        />
      </div>
    </div>
  );
};
export default UserMaster;
