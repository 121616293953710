import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
// import "./auth.scss";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import SignUp from "../auth/SignUp";
import { BiCheck, BiX } from "react-icons/bi";
import { useFindAllPackagesMutation } from "../../redux-toolkit/services/packageMasterService";
import { useDispatch } from "react-redux";
import { allPackagesDetails, setAllPackages } from "../../redux-toolkit/slices/packageMasterSlice";
import { alertMessage } from "../../utils/AlertService";
import { useAppSelector } from "../../redux-toolkit/hooks";
import Loading from "../../component/Loading";
import "./pricing.scss";
import { useFindAllPackageFeatureMappingMutation } from "../../redux-toolkit/services/packageFeatureMappingService";
import { allPackageFeatureMappingDetails, setAllPackageFeatureMapping } from "../../redux-toolkit/slices/packageFeatureMappingSlice";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { USER_ROLE } from "../../constant/AppConstant";
import { pricingStrategiesData } from "../../constant/mockData";


type Props = {};
const { Link } = Typography;

const PricingPlans = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const localUserObj = getLocalStorageData("localUser");
    const userBasedRoute = localStorage.getItem("userBasedRoute");

    const [show, setShow] = useState(false);
    const [comparePlanData, setComparePlanData] = useState([]);
    const [selectedPackageId, setSelectedPackageId] = useState();
    const [billingFrequency, setBillingFrequency] = useState('monthly');

    //get mutation
    const [findAllPackages, packagesResponse] = useFindAllPackagesMutation();
    const [findAllPackageFeatureMappings, packageFeatureMappingsResponse] = useFindAllPackageFeatureMappingMutation();

    // getData
    const allPackagesData: any = useAppSelector(allPackagesDetails);
    const allPackageFeatureMapping: any = useAppSelector(allPackageFeatureMappingDetails);

    const handleShow = () => setShow(true);

    const handleLoginClick = () => {
        localStorage.setItem("routeTo", "login");
        navigate("/login")
    };

    const handleHomeClick = () => {
        localStorage.setItem("routeTo", "home");
        navigate("/home")
    };

    const handleDashboardClick = () => {
        if (userBasedRoute === USER_ROLE.ADMIN || userBasedRoute === USER_ROLE.BETA_TEST_USER) {
            navigate("/dashboard");
        } else {
            navigate("/corporate-user-dashboard");
        }
    }

    const handleLogOut = () => {
        navigate("/logout");
    };

    const handleAbout = () => {
        navigate("/about");
    }


    useEffect(() => {
        findAllPackages({});
        findAllPackageFeatureMappings({});
    }, [])

    //get allpackages.......
    useEffect(() => {
        if (packagesResponse.isSuccess) {
            dispatch(setAllPackages(packagesResponse));
        } else if (packagesResponse.isError) {
            alertMessage("Error while fetching packages", "error");
        }
    }, [packagesResponse.isSuccess, packagesResponse.isError]);

    //get allpackagefeature mappings.......
    useEffect(() => {
        if (packageFeatureMappingsResponse.isSuccess) {
            dispatch(setAllPackageFeatureMapping(packageFeatureMappingsResponse));
        } else if (packageFeatureMappingsResponse.isError) {
            alertMessage("Error while fetching package feature mapping", "error");
        }
    }, [packageFeatureMappingsResponse.isSuccess, packageFeatureMappingsResponse.isError]);

    function convertDataToFeatures(data: any[], packageData: any[]) {
        const featuresMap = new Map();

        // Initialize features map with feature names
        data.forEach((obj: any) => {
            const subModuleName = obj.featuresObj.subModule.name;
            const featureName = obj.featuresObj.name;

            if (!featuresMap.has(subModuleName)) {
                featuresMap.set(subModuleName, {
                    id: featuresMap.size,
                    name: subModuleName,
                    data: []
                });
            }

            const featureData = featuresMap.get(subModuleName).data;
            const existingFeature = featureData.find((feature: any) => feature.name === featureName);

            if (!existingFeature) {
                featureData.push({
                    id: featureData.length + 1,
                    name: featureName,
                    checks: []
                });
            }
        });

        // Add checks for each package to feature data
        data.forEach((obj: any) => {
            const subModuleName = obj.featuresObj.subModule.name;
            const featureName = obj.featuresObj.name;

            const featureData = featuresMap.get(subModuleName).data;
            const existingFeature = featureData.find((feature: any) => feature.name === featureName);

            if (existingFeature) {
                packageData.forEach((pkg: any) => {
                    const packageName = pkg.name;

                    // Check if the package is already present in checks array
                    const packageExists = existingFeature.checks.some((check: any) => check.package === packageName);

                    if (!packageExists) {
                        // Generate unique ID for each check item
                        const uniqueId = `${subModuleName}_${featureName}_${packageName}_${pkg.id}`;

                        // If the package is present in the original data, mark its check as true
                        const isChecked = obj.packageMasterObj.name === packageName;

                        existingFeature.checks.push({
                            id: uniqueId,
                            packageId: pkg.id,
                            package: packageName,
                            check: isChecked
                        });
                    }
                });
            }
        });

        // Convert features map to array
        const features = Array.from(featuresMap.values());
        return features;
    }

    useEffect(() => {
        if (allPackageFeatureMapping && allPackagesData) {
            const comparePlanData: any = convertDataToFeatures(allPackageFeatureMapping, allPackagesData);
            setComparePlanData(comparePlanData);
        }
    }, [allPackageFeatureMapping, allPackagesData])

    const packageDetails = [
        {
            id: 1,
            name: "Bronze Package",
            defaultCurrency: "$",
            pricingPerQuarter: "0",
            pricingPerMonth: "0",
            pricingPerHalfYear: "0",
            pricingPerYear: "0",
            noOfUsers: 5,
            l1: "3 Features included",
            btn: "Sign up for free"
        },
        {
            id: 2,
            name: "Silver Package",
            defaultCurrency: "$",
            pricingPerQuarter: "200",
            pricingPerMonth: "300",
            pricingPerHalfYear: "600",
            pricingPerYear: "1000",
            noOfUsers: 10,
            l1: "6 Features included",
            btn: "Sign up"
        },
        {
            id: 3,
            name: "Gold Package",
            defaultCurrency: "$",
            pricingPerQuarter: "300",
            pricingPerMonth: "500",
            pricingPerHalfYear: "800",
            pricingPerYear: "1200",
            noOfUsers: 17,
            l1: "12 Features included",
            btn: "Sign up"
        },
        {
            id: 4,
            name: "Platinum Package",
            defaultCurrency: "$",
            pricingPerQuarter: "400",
            pricingPerMonth: "700",
            pricingPerHalfYear: "1000",
            pricingPerYear: "1500",
            noOfUsers: 25,
            l1: "All Features included",
            btn: "Sign up"
        },
    ]

    const billingOptions = [
        { id: 'yearly', label: 'Yearly', value: 'yearly' },
        { id: 'halfyear', label: 'Half-Year', value: 'halfyear' },
        { id: 'quarterly', label: 'Quarterly', value: 'quarterly' },
        { id: 'monthly', label: 'Monthly', value: 'monthly' }
    ];

    const handleBillingChange = (event: any) => {
        setBillingFrequency(event.target.value);
    };

    return (
        <>
            <header className="header">
                <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" onClick={() => navigate("/home")}>
                            <img
                                src={require("../../assets/logo-blue.png")}
                                alt="Bootstrap"
                                width="100"
                                height="40"
                            />
                        </a>

                        <button
                            type="button"
                            className="navbar-toggler"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav ms-auto">
                                <a
                                    // href="#"
                                    className="nav-item nav-link menu-font text-secondadry-darker px-4 cursor-pointer"
                                    onClick={handleAbout}
                                >
                                    About us
                                </a>

                                <li className="nav-item dropdown  text-secondadry-darker px-4">
                                    <a className="nav-link dropdown-toggle menu-font" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        ReguVista
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item cursor-pointer" onClick={handleHomeClick}>Home</a></li>
                                        {localUserObj !== null ? (
                                            <>
                                                <li><a className="dropdown-item cursor-pointer" onClick={handleDashboardClick}>Dashboard</a></li>
                                                <li><a className="dropdown-item cursor-pointer" onClick={handleLogOut}>Sign out</a></li>
                                            </>
                                        ) : (
                                            <li><a className="dropdown-item cursor-pointer" onClick={handleLoginClick}>Login</a></li>
                                        )}
                                    </ul>
                                </li>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            {packagesResponse.isLoading ||
                packageFeatureMappingsResponse.isLoading
                ? (
                    <Loading />
                ) : (
                    ""
                )}
            <div className="container">
                <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                    <h1 className="display-5 fw-normal text-cm-active-primary1">Compare plans and pricing</h1>
                    {/* <p className="fs-5 text-muted">Quickly build an effective pricing table for your potential customers with this Bootstrap example. It’s built with default Bootstrap components and utilities with little customization.</p> */}
                </div>
                {localUserObj === null ? (
                    <div className="row">
                        <div className="col text-center">
                            <h4>How often would you like to be billed?</h4>
                        </div>
                        <div className="col d-flex flex-wrap">
                            {/* Map over the billingOptions array to render radio buttons */}
                            {billingOptions.map(option => (
                                <div className="form-check me-3" key={option.id}>
                                    <input className="form-check-input border-primary" type="radio" name="billingFrequency" id={option.id} value={option.value} checked={billingFrequency === option.value} onChange={handleBillingChange} />
                                    <label className="form-check-label ms-1 fw-semibold" htmlFor={option.id}>
                                        {option.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}

                <main>
                    <div className={`row row-cols-1 row-cols-md-${allPackagesData && allPackagesData.length} mb-3 text-center`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {allPackagesData && allPackagesData.map((packageDetail: any) => {
                            return (
                                <div className="col" key={packageDetail.name} style={{ flex: '1 0 25%', marginBottom: '1rem' }}>
                                    <div className={`card h-100 mb-4 rounded-3 shadow-lg ${packageDetail.name === "Platinum Package" ? "border-primary" : ""}`} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className={`card-header header-height py-3 ${packageDetail.name === "Platinum Package" ? "text-bg-primary border-primary" : ""}`}>
                                            <h4 className="my-0 fw-normal">{packageDetail.name}</h4>
                                        </div>
                                        <div className="card-body" style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                            {localUserObj !== null ? (
                                                <>
                                                    <h2 className="card-title pricing-card-title">{packageDetail.pricingPerMonth}{packageDetail.defaultCurrency}<small className="text-muted fw-light">/month</small></h2>
                                                    <h5 className="card-title pricing-card-title">{packageDetail.pricingPerQuarter}{packageDetail.defaultCurrency}<small className="text-muted fw-light">/quarter</small></h5>
                                                    <h5 className="card-title pricing-card-title">{packageDetail.pricingPerHalfYear}{packageDetail.defaultCurrency}<small className="text-muted fw-light">/half year</small></h5>
                                                    <h5 className="card-title pricing-card-title">{packageDetail.pricingPerYear}{packageDetail.defaultCurrency}<small className="text-muted fw-light">/year</small></h5>
                                                </>
                                            ) : (
                                                billingFrequency === 'monthly' ? (
                                                    <h2 className="card-title pricing-card-title">{packageDetail.pricingPerMonth}{packageDetail.defaultCurrency}<small className="text-muted fw-light">/month</small></h2>
                                                ) : billingFrequency === 'quarterly' ? (
                                                    <h2 className="card-title pricing-card-title">{packageDetail.pricingPerQuarter}{packageDetail.defaultCurrency}<small className="text-muted fw-light">/quarter</small></h2>
                                                ) : billingFrequency === 'halfyear' ? (
                                                    <h2 className="card-title pricing-card-title">{packageDetail.pricingPerHalfYear}{packageDetail.defaultCurrency}<small className="text-muted fw-light">/half year</small></h2>
                                                ) : (
                                                    <h2 className="card-title pricing-card-title">{packageDetail.pricingPerYear}{packageDetail.defaultCurrency}<small className="text-muted fw-light">/year</small></h2>
                                                )
                                            )}
                                            <ul className="list-unstyled mt-3 mb-4">
                                                <li className="text-primary">{packageDetail.noOfUsers} users included</li>
                                                <li>Recommended for:</li>
                                                <li className="text-primary">{packageDetail.recommendedUsers}</li>
                                            </ul>
                                            {localUserObj === null ? (
                                                <button
                                                    type="button"
                                                    className={`w-100 btn btn-lg ${packageDetail.name === "Platinum Package"
                                                        || packageDetail.name === "Gold Package" ? "btn-primary" : "btn-outline-primary"}`}
                                                    onClick=
                                                    {() => {
                                                        handleShow();
                                                        setSelectedPackageId(packageDetail.id)
                                                    }}>
                                                    Sign up
                                                </button>
                                            ) : null}
                                            <div className="border-gradient border-gradient-blue"></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h2 className="display-6 text-center mb-4 text-cm-active-primary1">Compare features</h2>

                    <div className="table-responsive">
                        <table className="table text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "40%" }}>Features</th>
                                    {allPackagesData && allPackagesData.map((packageDetail: any) => {
                                        return (
                                            <th key={packageDetail.name} style={{ width: "15%" }}>{packageDetail.name}</th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {comparePlanData && comparePlanData.map((planData: any) => {
                                    return (
                                        <React.Fragment key={planData.name}>
                                            <tr>
                                                <td scope="row" className="text-start fw-bold" colSpan={allPackagesData.length + 1}>{planData.name}:</td>
                                            </tr>
                                            {planData.data.map((dataItem: any) => {
                                                return (
                                                    <tr key={dataItem.name}>
                                                        <td scope="row" className="text-start fw-normal">{dataItem.name}</td>
                                                        {dataItem.checks.map((checkItem: any) => {
                                                            return (
                                                                <td key={checkItem.id} id={checkItem.package}>
                                                                    {checkItem.check && <BiCheck size={24} />}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="table-responsive">
                        <table className="table text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "40%" }} className="align-top">Pricing Strategies</th>
                                    {allPackagesData && allPackagesData.map((packageDetail: any) => {
                                        return (
                                            <th key={packageDetail.name} style={{ width: "15%" }} className="align-top">{packageDetail.name}</th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {pricingStrategiesData && pricingStrategiesData.map((pricingStrategy: any) => (
                                    <tr key={pricingStrategy.id}>
                                        <td scope="row" className="text-start">{pricingStrategy.name}</td>
                                        {allPackagesData && allPackagesData.map((packageDetail: any) => {
                                            const foundPackage = pricingStrategy.data.find((dataItem: any) => dataItem.package === packageDetail.name);
                                            return (
                                                <td key={packageDetail.name}>
                                                    {foundPackage ? foundPackage.info : '-'}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </main >

            </div >
            <footer className="text-align-center ">
                <p className="text-cm-dark fs-6">
                    Capitol Tunnels.ai &#169; 2023
                </p>
            </footer>
            {
                show && (
                    <SignUp
                        show={show}
                        setShow={setShow}
                        selectedPackageId={selectedPackageId}
                        billingFrequency={billingFrequency}
                    />
                )
            }
        </>
    );
};
export default PricingPlans;
