import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import { REPORT_NAME } from "../../constant/reportType";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { alertMessage } from "../../utils/AlertService";
import { useDataPrivacyPenaltyPredictionMutation, useEmploymentBiasMutation, useSensitiveAndBiasDataIdentificationMutation } from "../../redux-toolkit/services/fastApiService";
import { useAiRegulationComplianceTestMutation, useDataPrivacyComplianceTestMutation } from "../../redux-toolkit/services/gdprService";
import { getGdprDetails, setAiRegComplianceTest, setDataPrivacyPenaltyPrediction, setGdpr, setSensitiveBiasDataIdentification } from "../../redux-toolkit/slices/gdprSlice";
import { useAppSelector } from "../../redux-toolkit/hooks";
import Loading from "../../component/Loading";
import * as Yup from "yup";
import { setEmploymentBias } from "../../redux-toolkit/slices/fastApiSlice";

type Props = {
  setShow: any;
  show: any;
  reportName?: string;
  reportNameCode?: string;
  setDatasource?: any;
  setAIRegComplianceTestDatasource?: any;
  setSensitiveBiasDataIdenDatasource?: any;
  setDataPrivacyPenaltyPredDatasource?: any;
  setEmploymentBiasDataSource?: any;
  setFileName: any;
  setShowMindMapBtn: any
};

const PrivacyGuardProData = (props: Props) => {
  // validation...........
  const formSchema = Yup.object().shape({
    annualUSD: Yup.string().required("Please enter Annual USD!"),
    numViolations: Yup.string().required("Please enter Num Violations!"),

  });
  const token: any = getLocalStorageData("localUser").token;
  const selectedReportNameL: any = localStorage?.getItem('selectedReportName');
  const dispatch = useDispatch();

  //selector
  const getDataPrivacyComplianceTestDetails = useAppSelector(getGdprDetails);

  //state
  const [file, setFile]: any = useState<File | null>(null);
  const [dataPrivacyCompTest, setDataPrivacyCompTest]: any = useState();

  //mutation
  const [uploadDataPrivacyCompTest, uploadDataPrivacyCompTestResponse] = useDataPrivacyComplianceTestMutation();
  const [uploadAIRegulationCompTest, uploadAIRegulationCompTestResponse] = useAiRegulationComplianceTestMutation();
  const [getDataPrivacyPenaltyPrediction, dataPrivacyPenaltyPredictionResponse] = useDataPrivacyPenaltyPredictionMutation();
  const [sensitiveAndBiasDataIdentification, sensitiveAndBiasDataIdentificationResponse] = useSensitiveAndBiasDataIdentificationMutation();
  const [employmentBias, employmentBiasResponse] = useEmploymentBiasMutation();
  
  // const [aiRegulationComplianceTest, aiRegulationComplianceTestResponse] = useAiRegulationComplianceTestMutation();

  //DataSource
  const [dataPrivacyComplianceTestDatasource, setDataPrivacyComplianceTestDatasource]: any = useState([]);

  // handleSubmit...........
  const handleSubmit = async (values: any) => {

    if (props.reportNameCode !== REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION) {
      const formData = new FormData();
      formData.append('file', file);

      const fileName = file.name;
      setFileName(fileName)
      const extension = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(extension, fileName.length).toLowerCase();

      if (extFile == "csv") {
        if (props.reportNameCode === REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION) {
          sensitiveAndBiasDataIdentification({
            file: formData,
          })
        } else if(props.reportNameCode === REPORT_NAME.EMPLOYMENT_BIAS) {
          employmentBias({
            file: formData
          })
        }
      } else if (extFile === "pdf" || extFile === "zip") {
        if (props.reportNameCode === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST) {
          uploadDataPrivacyCompTest({
            file: formData
          })
        } else if (props.reportNameCode === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST) {
          uploadAIRegulationCompTest({
            file: formData
          })
        }
      } else {
        alertMessage("Please select proper format of file!", "error");
      }
    } else {
      // Object.keys(values).length !== 0
      if (props.reportNameCode === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION) {

        const formData = new FormData();
        formData.append('annual_usd', values.annualUSD);
        formData.append('num_violations', values.numViolations);

        getDataPrivacyPenaltyPrediction({
          payload: formData
        })
      }
    }

  };

  const handleClose = () => props.setShow(false);

  const [fileName, setFileName] = useState("");

  // upload image...............................
  const imageHandler = (e: any) => {
    setFile(e.target.files[0]);
    props.setFileName(e.target.files[0].name);

    // const fileName = e.target.files[0].name;
    // setFileName(fileName)
    // const extension = fileName.lastIndexOf(".") + 1;
    // const extFile = fileName.substr(extension, fileName.length).toLowerCase();

    // if (extFile == "csv") {
    //   const reader: any = new FileReader();
    //   reader.onload = () => {
    //     if (reader.readyState === 2) {
    //       const formData = new FormData();
    //       formData.append('file', (e.target.files[0]));
    //       uploadFile({
    //         file: formData,
    //       })
    //       reader.abort();
    //     }
    //   };
    //   reader.readAsDataURL(e.target.files[0])
    // } else {
    //   alertMessage("Only CSV files are allowed!", "error");
    // }

  };

  //DataPrivacyCompTestResponse.......
  useEffect(() => {
    if (uploadDataPrivacyCompTestResponse.isSuccess) {
      if (uploadDataPrivacyCompTestResponse.data.data !== null) {
        dispatch(setGdpr(uploadDataPrivacyCompTestResponse));
        // props.setDatasource(uploadDataPrivacyCompTestResponse.data.data[0])
        props.setShow(false);
        props.setShowMindMapBtn(true);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = uploadDataPrivacyCompTestResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }

      // localStorage.setItem("selectedReportName", REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST);
    } else if (uploadDataPrivacyCompTestResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }
  }, [uploadDataPrivacyCompTestResponse.isSuccess, uploadDataPrivacyCompTestResponse.isError]);

  //AIRegulationCompTestResponse.......
  useEffect(() => {
    if (uploadAIRegulationCompTestResponse.isSuccess) {
      if (uploadAIRegulationCompTestResponse.data.data !== null) {
        dispatch(setAiRegComplianceTest(uploadAIRegulationCompTestResponse));
        // props.setAIRegComplianceTestDatasource(uploadAIRegulationCompTestResponse.data.data[0]);
        props.setShow(false);
        props.setShowMindMapBtn(true);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = uploadAIRegulationCompTestResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }

    } else if (uploadAIRegulationCompTestResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }
  }, [uploadAIRegulationCompTestResponse.isSuccess, uploadAIRegulationCompTestResponse.isError]);

  //DataPrivacyPenaltyPredictionResponse.......
  useEffect(() => {
    if (dataPrivacyPenaltyPredictionResponse.isSuccess) {
      if (dataPrivacyPenaltyPredictionResponse.data !== null) {
        dispatch(setDataPrivacyPenaltyPrediction(dataPrivacyPenaltyPredictionResponse));
        // props.setDataPrivacyPenaltyPredDatasource(dataPrivacyPenaltyPredictionResponse.data.Countries);
        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = dataPrivacyPenaltyPredictionResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }
    } else if (dataPrivacyPenaltyPredictionResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }
  }, [dataPrivacyPenaltyPredictionResponse.isSuccess, dataPrivacyPenaltyPredictionResponse.isError]);

  //sensitiveAndBiasDataIdentificationResponse.......
  useEffect(() => {
    if (sensitiveAndBiasDataIdentificationResponse.isSuccess) {
      if (sensitiveAndBiasDataIdentificationResponse.data !== null) {
        dispatch(setSensitiveBiasDataIdentification(sensitiveAndBiasDataIdentificationResponse));

        // let sensitiveAndBiasDataIdentificationArr = Object.entries(sensitiveAndBiasDataIdentificationResponse.data);
        // buildSensitiveAndBiasDataIdentification(sensitiveAndBiasDataIdentificationArr);

        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = sensitiveAndBiasDataIdentificationResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }
    } else if (sensitiveAndBiasDataIdentificationResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }
  }, [sensitiveAndBiasDataIdentificationResponse.isSuccess, sensitiveAndBiasDataIdentificationResponse.isError]);

    //emplymentBiasResponse.......
    useEffect(() => {
      if (employmentBiasResponse.isSuccess) {
        if (employmentBiasResponse.data !== null) {
          dispatch(setEmploymentBias(employmentBiasResponse));
  
          props.setShow(false);
          let selectedReportName = [];
          if (selectedReportNameL !== null) {
            selectedReportName = JSON.parse(selectedReportNameL);
          }
          selectedReportName.push(REPORT_NAME.EMPLOYMENT_BIAS);
          const jsonString = JSON.stringify(selectedReportName);
          localStorage.setItem('selectedReportName', jsonString);
        } else {
          const errorMsg = employmentBiasResponse.data.message;
          alertMessage(errorMsg, 'error');
          props.setShow(false);
        }
      } else if (employmentBiasResponse.isError) {
        alertMessage("error while fetching Data", "error");
      }
    }, [employmentBiasResponse.isSuccess, employmentBiasResponse.isError]);
  
  return (
    <div>
      {/* <!-- Modal --> */}
      <Modal
        show={props.show}
        onHide={handleClose}
        className=""
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          {props.reportNameCode === "dataRecord" ? (
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              Web Search
            </Modal.Title>
          ) : (
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              {/* {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "} */}
              {props.reportName}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Formik
            // validationSchema={formSchema}
            enableReinitialize={true}
            initialValues={{
              annualUSD: "",
              numViolations: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => (
              <Form>
                {props.reportNameCode === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION ? (
                  <div>
                    <p>
                      <div className="d-flex">
                        <div>Annual USD</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <div className="input-group mb-3">
                        <input
                          className="form-control form-control-sm "
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.annualUSD}
                          name="annualUSD"
                          required
                        />
                        <span className="input-group-text">M$</span>
                      </div>

                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Num Violations</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.numViolations}
                        name="numViolations"
                        required
                      />
                    </p>
                  </div>
                ) : (
                  <div className="text-center">
                    {props.reportNameCode === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST ||
                      props.reportNameCode === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST ? (
                      <p>Allowed file format - PDF, ZIP</p>
                    ) : <p>Allowed file format - CSV</p>}
                    <input type="file" id="fileUpload" onChange={imageHandler} />
                    <hr />
                  </div>
                )}
                <div className="d-flex flex-row justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                  >
                    {/* {props.actionType === ACTION_TYPE.ADD_ACTION
                      ? "Save"
                      : "Update"} */}
                    Submit
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  {uploadDataPrivacyCompTestResponse.isLoading ||
                    dataPrivacyPenaltyPredictionResponse.isLoading ||
                    sensitiveAndBiasDataIdentificationResponse.isLoading ||
                    uploadAIRegulationCompTestResponse.isLoading ||
                    employmentBiasResponse.isLoading ?
                    (
                      <Loading />
                    ) : (
                      ""
                    )}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PrivacyGuardProData;
