import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IndustryMasterState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}

const initialState: IndustryMasterState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};

export const industryMasterSlice = createSlice({
  name: 'industryMaster',
  initialState,
  reducers: {
    setAllIndustry: (state, action) => {
      state.data = action.payload.data.content;
      state.totalRecords = action.payload.data.totalElements;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.number;
    },
    setAllIndustryBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllIndustry,setAllIndustryBySearch,reset } = industryMasterSlice.actions;
export const getAllIndustryDetails = (state: RootState) => state.industryMaster.data;
export const getIndustryBySearchDetails= (state: RootState) => state.industryMaster.data;
export const getIndustryTotalRecords=(state:RootState)=>state.industryMaster.totalRecords;
export default industryMasterSlice.reducer;