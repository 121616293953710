import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface FeatureState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}
const initialState: FeatureState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    setFeature: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllFeatures: (state, action) => {
      state.data = action.payload.data;
    },
    setAllFeaturesBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setFeature, setAllFeaturesBySearch, setAllFeatures,reset } = featureSlice.actions;
export const getAllFeatureDetails = (state: RootState) => state.feature.data;
export const getFeatureTotalRecords = (state:RootState) => state.feature.totalRecords;
export const getAllFeatureBySearchDetails = (state: RootState) => state.feature.data;
export const allFeaturesDetails = (state: RootState) => state.feature.data;
export default featureSlice.reducer;