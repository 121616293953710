import React, { useEffect, useState } from "react";
import avatar from "../../../assets/icons/avatar.svg";
import { Switch, Table } from "antd";
import { useDispatch } from "react-redux";
import {
  useDeleteUserMutation,
  useGetUsersMutation,
  useUpdateUserMutation
} from "../../../redux-toolkit/services/usersService";
import { useAppSelector } from "../../../redux-toolkit/hooks";
import {
  getUpdateUserDetails,
  getUserDetails,
  setUsers
} from "../../../redux-toolkit/slices/usersSlice";
import { getLocalStorageData } from "../../../utils/LocalStorageService";
import { alertMessage } from "../../../utils/AlertService";
import { Button, Form } from "react-bootstrap";
import { BsFunnel, BsSearch } from "react-icons/bs";

type Props = {};

const rowSelection = {
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    // console.log(
    //   `selectedRowKeys: ${selectedRowKeys}`,
    //   "selectedRows: ",
    //   selectedRows
    // );
  },
  getCheckboxProps: (record: { name: string }) => ({
    disabled: record.name === "Disabled User",
    name: record.name,
    className: "checkbox-red"
  })
};

const columns = (onDeleteData: any) => {
  return [
    {
      title: "Member Name",
      dataIndex: "firstName",
      key: "1",
      render: (record: any) => {
        return (
          <div className="d-flex align-items-baseline" >
            <div className="px-2">
              <img src={avatar} alt="" />
            </div>
            <div>
              <p>{record}</p>
            </div>
          </div>
        );
      }
    },
    // {
    //   title: "Date Added",
    //   dataIndex: "createdDate",
    //   key: "2"
    // },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "3"
    },

    {
      title: "Status",
      dataIndex: "enabled",
      key: "4",
      render: (record: any) => {
        return (
          <p className={`${record === true ? "Active" : "Inactive"} px-1 m-0 square rounded-2`}>
            {record === true ? "Active" : "Inactive"}
          </p>
        );
      }
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "5",
      render: (index: any, record: any) => {
        return (
          <div className="px-3">
            <Switch
              checked={record.enabled === true ? true : false}
              onClick={() => {
                onDeleteData(record);
              }}
            />
          </div>
        );
      }
    }
  ];
};

const DataPipeline = (props: Props) => {
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  // Mutation------------
  const [getUsers, usersResponse] = useGetUsersMutation();
  const [updateUser, updateUserResponse] = useUpdateUserMutation();
  const [deleteUser, deleteUserResponse] = useDeleteUserMutation();

  //UseAppSelector-------------
  const userDetail = useAppSelector(getUserDetails);
  const updateUserDetail = useAppSelector(getUpdateUserDetails);

  // useState ------------------
  const [usersData, setUsersData] = useState([]) as any;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [updateUserData, setUpdateUserData] = useState();

  const onDeleteData = (record: any) => {
    deleteUser({
      token: token,
      payload: { id: record.id }
    });
  };

  const paginationChange = (page: number, pageSize: number) => {
    getUsers({
      token: token,
      payload: {
        // offset: 10,
        offset: pageSize,
        pageNumber: page - 1
        // pageNumber: 0,
      }
    });
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (token) {
      getUsers({
        token: token,
        payload: {
          pageNumber: currentPage - 1,
          offset: pageSize
        }
      });
    }
  }, []);

  useEffect(() => {
    if (usersResponse.isSuccess) {
      dispatch(setUsers(usersResponse));
    }
  }, [usersResponse.isSuccess]);

  useEffect(() => {
    if (userDetail) {
      setUsersData(userDetail);
    }
  }, [userDetail]);

  //UpdateUser -----------

  // useEffect(() => {
  //   if (updateUserResponse.isSuccess) {
  //     getUsers({
  //       token: token,
  //       payload: {
  //         pageNumber: currentPage - 1,
  //         offset: pageSize,
  //       },
  //     });
  //   }
  // }, [updateUserResponse.isSuccess]);

  // useEffect(() => {
  //   if (updateUserDetail) {
  //     setUpdateUserData(updateUserDetail);
  //   }
  // }, [updateUserDetail]);

  //Delete----------
  useEffect(() => {
    if (deleteUserResponse.isSuccess) {
      getUsers({
        token: token,
        payload: {
          pageNumber: currentPage - 1,
          offset: pageSize
        }
      });
      alertMessage("Updated Successfully", "success");
    }
  }, [deleteUserResponse.isSuccess]);

  return (
    <div className=" overflow-y-auto  p-5">
      {/* Inlet */}
      <div className="d-flex align-items-center justify-content-between">
        {/* < title="Data Quality" /> */}
        {/* <AppOsDiskUtilization
            title="Pipeline Status"
            chartData={[
              { label: "Running", value: 75 },
              { label: "Failed", value: 50 },
              { label: "Completed", value: 25 }
            ]}
          /> */}
      </div>
      <div className="rounded-5 bg-secondary-light">
        <div className="d-flex align-items-center mt-4 px-5 pt-5">
          <h3 style={{ fontSize: "1rem", fontWeight: "700" }}>Users</h3>
          {/* <h6 className="added p-2 mx-4 ">6 out of 12 users added</h6> */}
        </div>
        <div className=" px-5 d-flex flex-row">
          <div className="">
            <Button
              className="d-flex bg-primary align-items-center flex-sm-start me-0 input-height p-1 px-2 export-margin"
            >
              {/* <img src={filter} alt="" className="mx-2 text-white " />  */}
              <BsFunnel size={20} />
              <p className="px-2 m-0">Filter</p>
            </Button>
          </div>
          <div className="input-group px-4">
             <Form.Control
              type="search"
              placeholder="Search"
              className="border-0"
              aria-label="Search"
            // onChange={handleSearch}
            />
            <span className="input-group-text bg-white border-white"> <BsSearch className=" bg-white border-white" /></span>
          </div>
        </div>

        <div className="my-4 w-100 overflow-x-auto rounded-3 px-5">
          <Table
            className="bg-cm-light-primary1"
            // style={{fontWeight:'700'}}
            rowKey={({ name }) => name}
            // rowSelection={rowSelection}
            pagination={{
              total: usersData.totalRecords,
              responsive: true,
              current: currentPage,
              showSizeChanger: true,
              pageSizeOptions: [1, 5, 10, 15],
              onChange: (page, pageSize) => {
                paginationChange(page, pageSize);
              },
              pageSize: pageSize
            }}
            columns={columns(onDeleteData)}
            dataSource={usersData?.data}
          />
        </div>
      </div>
    </div>
  );
};

export default DataPipeline;
