import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { Switch, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import { ACTION_TYPE, REPORT_NAME } from "../constant/reportType";
import { DETAIL_ORGANIZATION_SEARCH_COLUMN1, DOCUMENT_CATEGORY_COLUMN, DOCUMENT_MASTER_COLUMN, DOCUMENT_RECORD_COLUMN, SECTION_MASTER_COLUMN } from "../constant/AppConstant";
import editIcon from "../assets/icons/editicon.svg";
import Loading from "./Loading";
import { Excel } from "antd-table-saveas-excel";
import { alertMessage } from "../utils/AlertService";
import { getDetailOrganizationSearch, getSearchCompanyData } from "../redux-toolkit/slices/webSearchSlice";
import "../pages/reports/reports.scss"
import { useDeleteSectionMasterMutation, useGetAllSectionMasterMutation, useGetAllSectionMasterWithSearchMutation } from "../redux-toolkit/services/sectionMasterService";
import { getAllSectionMasterBySearch, getAllSectionMasterDetails, getSectionMasterTotalRecords, setAllSectionMasterBySearch, setSectionMaster } from "../redux-toolkit/slices/sectionMasterSlice";
import { getLocalStorageData } from "../utils/LocalStorageService";
import { useDeleteDocumentCategoryMutation, useGetAllDocumentCategoryMutation, useGetAllDocumentCategoryWithSearchMutation } from "../redux-toolkit/services/documentCategoryService";
import { getAlldocumentCategoryBySearch, getAlldocumentCategoryDetails, getdocumentCategoryTotalRecords, setAllDocumentCategoryBySearch, setDocumentCategory } from "../redux-toolkit/slices/documentCategorySlice";
import { useDeleteDocumentMasterMutation, useGetAllDocumentMasterMutation, useGetAllDocumentMasterWithSearchMutation } from "../redux-toolkit/services/documentMasterService";
import { getAlldocumentMasterBySearch, getAlldocumentMasterDetails, getdocumentMasterTotalRecords, setAllDocumentMasterBySearch, setDocumentMaster } from "../redux-toolkit/slices/documentMasterSlice";
import DocumentManagementData from "../pages/reports/DocumentManagementData";
import { BsDownload, BsFileEarmarkText, BsSearch } from "react-icons/bs";
import { useDeleteDocumentRecordMutation, useGetAllDocumentRecordsMutation, useGetDocumentRecordWithSearchMutation } from "../redux-toolkit/services/documentCreationService";
import { getAllDocumentRecordBySearch, getAllDocumentRecordsDetails, getDocumentRecordsTotalRecords, pdfBlob, pdfURL, setAllDocumentRecordWithSearch, setAllDocumentRecords, setPdfBlob, setPdfURL } from "../redux-toolkit/slices/documentCreationSlice";
import { Formik } from "formik";

type Props = {};
const DocumentManagementMaster = (props: Props) => {
  // States
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token: any = getLocalStorageData("localUser").token;

  //states
  const [currentpage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [reportType, setReportType] = useState(params.reportType) as any;
  const [reportName, setReportName]: any = useState();
  const [call, setCall] = useState("N");
  const [showAdd, setShowAdd] = useState(true);
  const [loading, setLoading] = useState(false);

  //columns
  const [columData, setColumnData]: any = useState();

  //datasource
  const [dataSource, setDatasource]: any = useState([]);
  const [filteredRecords, setFilteredRecords]: any = useState([]);

  //states
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [isCategory, setIsCategory] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState([]);
  const [fileName, setFileName] = useState("File");
  const [fileFormat, setFileFormat] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //get mutation
  const [getAllSectionMaster, sectionMasterResponse] = useGetAllSectionMasterMutation();
  const [getAllDocumentCategory, documentCategoryResponse] = useGetAllDocumentCategoryMutation();
  const [getAllDocumentMaster, documentMasterResponse] = useGetAllDocumentMasterMutation();
  const [getAllDocumentRecords, documentRecordsResponse] = useGetAllDocumentRecordsMutation();

  //get by search
  const [getAllSectionMasterWithSearch, sectionMasterWithSearchResponse] = useGetAllSectionMasterWithSearchMutation();
  const [getAllDocumentCategoryWithSearch, documentCategoryWithSearchResponse] = useGetAllDocumentCategoryWithSearchMutation();
  const [getAllDocumentMasterWithSearch, documentMasterWithSearchResponse] = useGetAllDocumentMasterWithSearchMutation();
  const [getAllDocumentRecordsWithSearch, documentRecordsWithSearchResponse] = useGetDocumentRecordWithSearchMutation();

  //delete mutation
  const [deleteSectionMaster, deleteSectionMasterResponse] = useDeleteSectionMasterMutation();
  const [deleteDocumentCategory, deleteDocumentCategoryRsponse] = useDeleteDocumentCategoryMutation();
  const [deleteDocumentMaster, deleteDocumentMasterRsponse] = useDeleteDocumentMasterMutation();
  const [deleteDocumentRecord, deleteDocumentRecordResponse] = useDeleteDocumentRecordMutation();

  //search

  //selector
  const allSectionMasterData: any = useAppSelector(getAllSectionMasterDetails);
  const allSearchCompanyData: any = useAppSelector(getSearchCompanyData);
  const allDetailOrganizationSearchData: any = useAppSelector(getDetailOrganizationSearch);
  const allDocumentCategoryData: any = useAppSelector(getAlldocumentCategoryDetails);
  const allDocumentMasterData: any = useAppSelector(getAlldocumentMasterDetails);
  const getPdfURL: any = useAppSelector(pdfURL);
  const getPdfBlob: any = useAppSelector(pdfBlob);
  const allDocumentRecordsData: any = useAppSelector(getAllDocumentRecordsDetails);

  const allSectionMasterSearchData: any = useAppSelector(getAllSectionMasterBySearch);
  const allDocumentCategorySearchData: any = useAppSelector(getAlldocumentCategoryBySearch);
  const allDocumentMasterSearchData: any = useAppSelector(getAlldocumentMasterBySearch);
  const allDocumentRecordsSearchData: any = useAppSelector(getAllDocumentRecordBySearch);

  //totalRecords
  const totalSectionMasterRecords: any = useAppSelector(getSectionMasterTotalRecords);
  const totalDocumentCategoryRecords: any = useAppSelector(getdocumentCategoryTotalRecords);
  const totalDocumentMasterRecords: any = useAppSelector(getdocumentMasterTotalRecords);
  const totalDocumentRecords: any = useAppSelector(getDocumentRecordsTotalRecords);

  useEffect(() => {
    if (params.reportType === REPORT_NAME.SECTION_MASTER) {
      setReportName(REPORT_NAME.SECTION_MASTER);
      getAllSectionMaster({
        token: token,
        payload: {
          size: pageSize,
          page: currentpage - 1,
          orderBy: "asc"
        }
      });
    } else if (params.reportType === REPORT_NAME.DOCUMENT_CATEGORY) {
      setReportName(REPORT_NAME.DOCUMENT_CATEGORY);
      getAllDocumentCategory({
        token: token,
        payload: {
          size: pageSize,
          page: currentpage - 1,
          orderBy: "asc"
        }
      });
    } else if (params.reportType === REPORT_NAME.DOCUMENT_MASTER) {
      setReportName(REPORT_NAME.DOCUMENT_MASTER);
      getAllDocumentMaster({
        token: token,
        payload: {
          size: pageSize,
          page: currentpage - 1,
          orderBy: "asc"
        }
      });
    } else if (params.reportType === REPORT_NAME.DOCUMENT_CREATION) {
      setReportName(REPORT_NAME.DOCUMENT_CREATION);
      getAllDocumentRecords({
        token: token,
        payload: {
          size: pageSize,
          page: currentpage - 1,
          orderBy: "asc"
        }
      });
    }
  }, []);

  useEffect(() => {
    if (call === "Y") {
      if (params.reportType === REPORT_NAME.SECTION_MASTER) {
        setReportName(REPORT_NAME.SECTION_MASTER);
        setIsCategory(false);
        getAllSectionMaster({
          token: token,
          payload: {
            size: pageSize,
            page: currentpage - 1,
            orderBy: "asc"
          }
        });
        setCall("N");
      } else if (params.reportType === REPORT_NAME.DOCUMENT_CATEGORY) {
        setReportName(REPORT_NAME.DOCUMENT_CATEGORY);
        getAllDocumentCategory({
          token: token,
          payload: {
            size: pageSize,
            page: currentpage - 1,
            orderBy: "asc"
          }
        });
        setCall("N");
      } else if (params.reportType === REPORT_NAME.DOCUMENT_MASTER) {
        setReportName(REPORT_NAME.DOCUMENT_MASTER);
        getAllDocumentMaster({
          token: token,
          payload: {
            size: pageSize,
            page: currentpage - 1,
            orderBy: "asc"
          }
        });
        setCall("N");
      } else if (params.reportType === REPORT_NAME.DOCUMENT_CREATION) {
        setReportName(REPORT_NAME.DOCUMENT_CREATION);
        getAllDocumentRecords({
          token: token,
          payload: {
            size: pageSize,
            page: currentpage - 1,
            orderBy: "asc"
          }
        });
        setCall("N");
      }
    }
  }, [call]);

  //get allSectionMaster.......
  useEffect(() => {
    if (sectionMasterResponse.isSuccess) {
      dispatch(setSectionMaster(sectionMasterResponse));
    } else if (sectionMasterResponse.isError) {
      alertMessage("Error while fetching section master", "error");
    }
  }, [sectionMasterResponse.isSuccess, sectionMasterResponse.isError]);

  //get alldocumentCategory.......
  useEffect(() => {
    if (documentCategoryResponse.isSuccess) {
      dispatch(setDocumentCategory(documentCategoryResponse.data));
    } else if (documentCategoryResponse.isError) {
      alertMessage("Error while fetching document category", "error");
    }
  }, [documentCategoryResponse.isSuccess, documentCategoryResponse.isError]);

  //get alldocumentMaster.......
  useEffect(() => {
    if (documentMasterResponse.isSuccess) {
      dispatch(setDocumentMaster(documentMasterResponse.data));
    } else if (documentMasterResponse.isError) {
      alertMessage("Error while fetching document master", "error");
    }
  }, [documentMasterResponse.isSuccess, documentMasterResponse.isError]);

  //get alldocumentRecords response.......
  useEffect(() => {
    if (documentRecordsResponse.isSuccess) {
      dispatch(setAllDocumentRecords(documentRecordsResponse.data));
    } else if (documentRecordsResponse.isError) {
      alertMessage("Error while fetching document records", "error");
    }
  }, [documentRecordsResponse.isSuccess, documentRecordsResponse.isError]);

  useEffect(() => {
    if (allSectionMasterData && reportName === REPORT_NAME.SECTION_MASTER) {
      setDatasource(allSectionMasterData);
      setFilteredRecords(allSectionMasterData);
    }
  }, [allSectionMasterData]);

  useEffect(() => {
    if (allDocumentCategoryData && reportName === REPORT_NAME.DOCUMENT_CATEGORY) {
      setDatasource(allDocumentCategoryData);
      setFilteredRecords(allDocumentCategoryData);
    }
  }, [allDocumentCategoryData]);

  useEffect(() => {
    if (allDocumentMasterData && reportName === REPORT_NAME.DOCUMENT_MASTER) {
      setDatasource(allDocumentMasterData);
      setFilteredRecords(allDocumentMasterData);
    }
  }, [allDocumentMasterData]);

  useEffect(() => {
    if (allDocumentRecordsData && reportName === REPORT_NAME.DOCUMENT_CREATION) {
      setDatasource(allDocumentRecordsData);
      setFilteredRecords(allDocumentRecordsData);
    }
  }, [allDocumentRecordsData]);

  const handleExportClick = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) {
      fileName = REPORT_NAME.DETAIL_ORGANIZATION_SEARCH;
      columns = DETAIL_ORGANIZATION_SEARCH_COLUMN1;
      dataSource = allDetailOrganizationSearchData;
    }
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true
      })
      .saveAs(`${fileName}.xlsx`);
  };

  //delete..........
  const onDeleteData = (record: any) => {
    if (reportName === REPORT_NAME.SECTION_MASTER) {
      deleteSectionMaster({
        id: record.id,
        token: token
      })
    } else if (reportName === REPORT_NAME.DOCUMENT_CATEGORY) {
      deleteDocumentCategory({
        id: record.id,
        token: token
      })
    } else if (reportName === REPORT_NAME.DOCUMENT_MASTER) {
      deleteDocumentMaster({
        id: record.id,
        token: token
      })
    } else if (reportName === REPORT_NAME.DOCUMENT_CREATION) {
      if (record.status === false) {
        deleteDocumentRecord({
          id: record.id,
          isPermanent: false,
          token: token
        })
      } else {
        setSelectedRow(record);
        setShowDeleteModal(true);
      }
    }
  };

  //Delete response useEffects
  useEffect(() => {
    if (deleteSectionMasterResponse.isSuccess) {
      alertMessage("Section master updated successfully", "success");
      setCall("Y");
    } else if (deleteSectionMasterResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteSectionMasterResponse.isSuccess, deleteSectionMasterResponse.isError]);

  useEffect(() => {
    if (deleteDocumentCategoryRsponse.isSuccess) {
      alertMessage("Document category updated successfully", "success");
      setCall("Y");
    } else if (deleteDocumentCategoryRsponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteDocumentCategoryRsponse.isSuccess, deleteDocumentCategoryRsponse.isError]);

  useEffect(() => {
    if (deleteDocumentMasterRsponse.isSuccess) {
      alertMessage("Document master updated successfully", "success");
      setCall("Y");
    } else if (deleteDocumentMasterRsponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteDocumentMasterRsponse.isSuccess, deleteDocumentMasterRsponse.isError]);

  useEffect(() => {
    if (deleteDocumentRecordResponse.isSuccess) {
      alertMessage("Document record deleted successfully", "success");
      setShowDeleteModal(false);
      setCall("Y");
    } else if (deleteDocumentRecordResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteDocumentRecordResponse.isSuccess, deleteDocumentRecordResponse.isError]);

  const handleOpenForm = (record: any) => {
    setShow(true);
    setActionType(ACTION_TYPE.UPDATE_ACTION);
    setSelectedRow(record);
  };

  useEffect(() => {
    const temp: any = [];
    const tempAction: any = {
      title: "Action",
      dataIndex: "action",
      key: "name",
      fixed: "right",
      width: reportName === REPORT_NAME.DOCUMENT_CREATION ? 180 : 120,
      render: (index: any, record: any) => {

        const handleViewDocument = () => {
          const byteCharacters = atob(record.documentData); // Decode Base64 string
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers); // Convert to byte array
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          // setDataForPdf(url);
          setFileFormat(record.documentType);
          dispatch(setPdfURL(url));
          dispatch(setPdfBlob(byteArray));
        }

        return (
          // for delete switch button
          <div className="d-flex justify-content-between">
            <div className="px-2 pe-auto">
              <Switch
                checked={record.status === true ? true : false}
                onClick={() => {
                  onDeleteData(record);
                }}
              />
            </div>
            {record.status === true ? (
              <div
                className="px-2 pe-auto"
                onClick={() => {
                  handleOpenForm(record);
                }}
              >
                <img src={editIcon} alt="update" />
              </div>
            ) : null}
            {reportName === REPORT_NAME.DOCUMENT_CREATION && (
              <div>
                <Button
                  variant="primary"
                  className="d-flex align-items-center mx-2"
                  onClick={handleViewDocument}
                >
                  <BsFileEarmarkText size={16} onClick={handleViewDocument} />
                </Button>
              </div>
            )}
          </div>
        );
      },
    };

    if (reportName === REPORT_NAME.SECTION_MASTER && SECTION_MASTER_COLUMN) {
      SECTION_MASTER_COLUMN.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setColumnData(temp);
    } else if (reportName === REPORT_NAME.DOCUMENT_MASTER && DOCUMENT_MASTER_COLUMN) {
      DOCUMENT_MASTER_COLUMN.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setColumnData(temp);
    } else if (reportName === REPORT_NAME.DOCUMENT_CATEGORY && DOCUMENT_CATEGORY_COLUMN) {
      DOCUMENT_CATEGORY_COLUMN.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setColumnData(temp);
    } else if (reportName === REPORT_NAME.DOCUMENT_CREATION && DOCUMENT_RECORD_COLUMN) {
      DOCUMENT_RECORD_COLUMN.map((item: any) => {
        temp.push(item);
      });
      // temp.push(documentViewColumn);
      temp.push(tempAction);
      setColumnData(temp);
    }
  }, [reportName]);

  const paginationChange = (page: number, pageSize: number) => {
    if (reportName === REPORT_NAME.SECTION_MASTER) {
      getAllSectionMaster({
        token: token,
        payload: {
          size: pageSize,
          page: page - 1,
          orderBy: "asc"
        }
      });
    } else if (reportName === REPORT_NAME.DOCUMENT_CATEGORY) {
      getAllDocumentCategory({
        token: token,
        payload: {
          size: pageSize,
          page: page - 1,
          orderBy: "asc"
        }
      });
    } else if (reportName === REPORT_NAME.DOCUMENT_MASTER) {
      getAllDocumentMaster({
        token: token,
        payload: {
          size: pageSize,
          page: page - 1,
          orderBy: "asc"
        }
      });
    } else if (reportName === REPORT_NAME.DOCUMENT_CREATION) {
      getAllDocumentRecords({
        token: token,
        payload: {
          size: pageSize,
          page: page - 1,
          orderBy: "asc"
        }
      });
    }
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleShow = () => setShow(true);

  // navigate............
  const handleBack = () => {
    dispatch(setPdfURL(''));
    dispatch(setPdfBlob(''));
    navigate("/documentReports");
  };

  const handleSearch = (event: any) => {
    if (reportName === REPORT_NAME.SECTION_MASTER) {
      getAllSectionMasterWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === REPORT_NAME.DOCUMENT_CATEGORY) {
      getAllDocumentCategoryWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === REPORT_NAME.DOCUMENT_MASTER) {
      getAllDocumentMasterWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === REPORT_NAME.DOCUMENT_CREATION) {
      getAllDocumentRecordsWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else {
      setFilteredRecords(dataSource);
    }
  };

  useEffect(() => {
    if (sectionMasterWithSearchResponse.isSuccess) {
      dispatch(setAllSectionMasterBySearch(sectionMasterWithSearchResponse));
    } else if (sectionMasterWithSearchResponse.isError) {
      let response: any = sectionMasterWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [sectionMasterWithSearchResponse.isSuccess, sectionMasterWithSearchResponse.isError]);

  useEffect(() => {
    if (documentCategoryWithSearchResponse.isSuccess) {
      dispatch(setAllDocumentCategoryBySearch(documentCategoryWithSearchResponse));
    } else if (documentCategoryWithSearchResponse.isError) {
      let response: any = documentCategoryWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [documentCategoryWithSearchResponse.isSuccess, documentCategoryWithSearchResponse.isError]);

  useEffect(() => {
    if (documentMasterWithSearchResponse.isSuccess) {
      dispatch(setAllDocumentMasterBySearch(documentMasterWithSearchResponse));
    } else if (documentMasterWithSearchResponse.isError) {
      let response: any = documentMasterWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [documentMasterWithSearchResponse.isSuccess, documentMasterWithSearchResponse.isError]);

  useEffect(() => {
    if (documentRecordsWithSearchResponse.isSuccess) {
      dispatch(setAllDocumentRecordWithSearch(documentRecordsWithSearchResponse));
    } else if (documentRecordsWithSearchResponse.isError) {
      let response: any = documentRecordsWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [documentRecordsWithSearchResponse.isSuccess, documentRecordsWithSearchResponse.isError]);


  useEffect(() => {
    if (allSectionMasterSearchData) {
      setFilteredRecords(allSectionMasterSearchData);
    }
  }, [allSectionMasterSearchData]);


  useEffect(() => {
    if (allDocumentCategorySearchData) {
      setFilteredRecords(allDocumentCategorySearchData);
    }
  }, [allDocumentCategorySearchData]);


  useEffect(() => {
    if (allDocumentMasterSearchData) {
      setFilteredRecords(allDocumentMasterSearchData);
    }
  }, [allDocumentMasterSearchData]);


  useEffect(() => {
    if (allDocumentRecordsSearchData) {
      setFilteredRecords(allDocumentRecordsSearchData);
    }
  }, [allDocumentRecordsSearchData]);

  const handleDownload = () => {
    if (getPdfBlob) {
      const link = document.createElement('a');
      link.href = getPdfURL;
      link.setAttribute('download', `${fileName}.${fileFormat}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDeleteRecord = (values: any) => {
    console.log(selectedRow.id)
    deleteDocumentRecord({
      id: selectedRow.id,
      isPermanent: values.isPermanent,
      token: token
    })
  }

  const handleDeleteClose = () => setShowDeleteModal(false);

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div className="input-group px-2 mt-1">
            <Form.Control
              type="search"
              placeholder="Search"
              className="border-0 bg-cm-light-primary1"
              aria-label="Search"
              onChange={handleSearch}
            />
            <div className="input-group-append bg-cm-light-primary1 p-1 rounded-end">
              <span className="input-group-text bg-cm-light-primary1 border-0">
                {/* <img src={gear} alt="" /> */}
                <BsSearch className=" bg-cm-light-primary1 border-cm-light-primary1" />
              </span>
            </div>
          </div>
          {showAdd && reportName !== REPORT_NAME.DOCUMENT_CREATION && (
            <div
              onClick={handleShow}
              className={`d-flex align-items-center mt-1`}
            >
              {/* <div
                className="d-flex align-items-center px-3"
                onClick={handleAdd}
              > */}
              <Button
                variant="primary"
                className="d-flex align-items-center me-0 text-nowrap"
                onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
              >
                <BiPlus />
                Add
              </Button>
              {/* </div> */}
            </div>
          )}
          {show && (
            <DocumentManagementData
              show={show}
              setShow={setShow}
              actionType={actionType}
              getAllReportAsync={() => null}
              updateAllReportAsync={({ }) => null}
              reportType={reportType}
              reportName={params.reportType}
              reportNameCode={reportName}
              isCategory={isCategory}
              data={selectedRow}
              call={call}
              setCall={setCall}
              setFileName={setFileName}
              setFileFormat={setFileFormat}
            />
          )}
        </div>
      </div>
      <div className="d-flex mb-2">
        <div className="primary mt-4 d-flex justify-content-between w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
          {showAdd && reportName === REPORT_NAME.DOCUMENT_CREATION && (
            <div className="d-flex align-items-center">
              <div
                // onClick={handleShow}
                className={`d-flex align-items-center`}
              >
                {/* <div
                  className="d-flex align-items-center px-3"
                  onClick={handleAdd}
                > */}
                <Button
                  variant="primary"
                  className="d-flex align-items-center me-0 text-nowrap"
                  onClick={() => {
                    dispatch(setPdfURL(''));
                    dispatch(setPdfBlob(''));
                    setActionType(ACTION_TYPE.ADD_ACTION)
                    setShow(true);
                  }}
                >
                  <BsFileEarmarkText className="me-1" />
                  Create Document
                </Button>
                {/* </div> */}
              </div>
              {getPdfURL && (
                <div
                  className="d-flex align-items-center px-3"
                  onClick={handleDownload}
                >
                  <Button
                    variant="primary"
                    className="d-flex align-items-center me-0 text-nowrap"
                  >
                    <BsDownload className="me-1" />
                    Download
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        {
          sectionMasterResponse.isLoading ||
            documentCategoryResponse.isLoading ||
            deleteDocumentCategoryRsponse.isLoading ||
            documentMasterResponse.isLoading ||
            documentRecordsResponse.isLoading ||
            deleteSectionMasterResponse.isLoading ||
            deleteDocumentMasterRsponse.isLoading ||
            deleteDocumentRecordResponse.isLoading ||
            sectionMasterWithSearchResponse.isLoading ||
            documentCategoryWithSearchResponse.isLoading ||
            documentMasterWithSearchResponse.isLoading ||
            documentRecordsWithSearchResponse.isLoading ? (
            <Loading />
          ) : (
            ""
          )}
        {loading ? (
          <Loading />
        ) : (
          ""
        )}
      </div>
      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          scroll={{ y: 'calc(100vh - 400px)' }}  // Set the height of the table body to enable vertical scrolling
          sticky // Enable sticky headers
          rowKey={"id"}
          // pagination
          pagination={{
            total: (() => {
              switch (reportName) {
                case REPORT_NAME.SECTION_MASTER:
                  return totalSectionMasterRecords;
                case REPORT_NAME.DOCUMENT_CATEGORY:
                  return totalDocumentCategoryRecords;
                case REPORT_NAME.DOCUMENT_MASTER:
                  return totalDocumentMasterRecords;
                case REPORT_NAME.DOCUMENT_CREATION:
                  return totalDocumentRecords;
                default:
                  return filteredRecords.length;
              }
            })(),
            responsive: true,
            current: currentpage,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30],
            onChange: (page, pageSize) => {
              paginationChange(page, pageSize);
            },
            pageSize: pageSize,
          }}
          columns={columData}
          dataSource={filteredRecords}
          className="custom-table"
        />
      </div>
      {reportName === REPORT_NAME.DOCUMENT_CREATION && getPdfURL && (
        (fileFormat === "pdf" ? (
          <div className="mt-3">
            <iframe src={getPdfURL} width="100%" height="600px" title="PDF Viewer" />
          </div>
        ) : (
          null
        ))
      )}
      {showDeleteModal && (
        <Modal
          show={showDeleteModal}
          onHide={handleDeleteClose}
          className=""
          backdrop="static"
          keyboard={false}
          size="sm"
        // dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title
              className="fw-bold text-primary "
              style={{ fontSize: "1rem" }}
            >
              Delete record
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="overflow-auto">
            <div className="d-flex align-items-center">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  isPermanent: "false",
                }}
                onSubmit={handleDeleteRecord} >
                {({ values, handleChange, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="">
                      <div>
                        <input
                          type="checkbox"
                          id="isPermanent"
                          name="isPermanent"
                          value={values.isPermanent}
                          onChange={handleChange}
                        />
                        <label htmlFor="isPermanent" className="ms-1">
                          Delete record permanently
                        </label>
                        {/* <div className="invalid-feedback">{errors.privacyPolicyCheckbox}</div> */}
                      </div>
                      <div className="d-flex flex-row justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};
export default DocumentManagementMaster;
