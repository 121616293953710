import React, { useEffect, useState } from 'react';
import { CANADA_DATA_PRIVACY_ACT, COMPANION_OF_AI_GOV_FRAMEWORK_DATA, EUROPE_DATA_PRIVACY_ACT, EU_AI_ACT_DATA, GDPR_CLAUSE_TERMS, MICRO_RES_AI_IMAPCT_ASS_GUIDE_DATA, MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2_DATA, MICRO_RES_AI_STD_DATA, MODEL_AI_GOV_FRAMEWORK_SEC_EDITION_DATA, NIST_AI_RISK_DATA, SINGAPORE_AI_REGULATIONS_DATA, SINGAPORE_AI_REG_ACT_DATA, SINGAPORE_DATA_PRIVACY_ACT, SINGAPORE_PDPC_ACT_DATA, TRUSTED_AI_DATA, TRUSTWORTHY_AI_PLAYBOOK_DATA, UK_DATA_PRIVACY_ACT, USA_DATA_PRIVACY_ACT } from '../../constant/mockData';
import { Table } from 'antd';
import { ACTS_COLUMN, EU_AI_ACT_COLUMN, GDPR_COLUMN, NIST_AI_RISK_COLUMN, SINGAPORE_ACTS_COLUMN, SINGAPORE_AI_REG_ACT_COLUMN, TRUSTED_AI_COLUMN, USA_ACTS_COLUMN } from '../../constant/AppConstant';
import { ACT_NAME } from '../../constant/reportType';
import { Button, Form } from 'react-bootstrap';
import { BsDownload, BsSearch } from 'react-icons/bs';
import { Excel } from 'antd-table-saveas-excel';

function AllActDataPrivacyRegulations() {

  const act: any = localStorage.getItem("act");
  const [reportName, setReportName]: any = useState();
  const [columns, setColumns]: any = useState();
  const [dataSource, setDataSource]: any = useState();
  const [filteredRecords, setFilteredRecords]: any = useState();

  const currentUrl = window.location.href;
  const result = currentUrl.match(/\/([^\/]+)$/);
  const extractedText = result ? result[0] : null;


  const handleExportClick = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === ACT_NAME.SINGAPORE) {
      fileName = ACT_NAME.SINGAPORE;
      columns = SINGAPORE_ACTS_COLUMN;
      dataSource = SINGAPORE_DATA_PRIVACY_ACT;
    } else if (reportName === ACT_NAME.EUROPE) {
      fileName = ACT_NAME.EUROPE
      columns = ACTS_COLUMN;
      dataSource = EUROPE_DATA_PRIVACY_ACT;
    } else if (reportName === ACT_NAME.UK) {
      fileName = ACT_NAME.UK
      columns = ACTS_COLUMN;
      dataSource = UK_DATA_PRIVACY_ACT;
    } else if (reportName === ACT_NAME.CANADA) {
      fileName = ACT_NAME.CANADA
      columns = ACTS_COLUMN;
      dataSource = CANADA_DATA_PRIVACY_ACT;
    } else if (reportName === ACT_NAME.USA) {
      fileName = ACT_NAME.USA
      columns = USA_ACTS_COLUMN;
      dataSource = USA_DATA_PRIVACY_ACT;
    } else if (reportName === ACT_NAME.GDPR) {
      fileName = ACT_NAME.GDPR
      columns = GDPR_COLUMN;
      dataSource = GDPR_CLAUSE_TERMS;
    }

    else if (reportName === ACT_NAME.COMPANION_OF_AI_GOV_FRAMEWORK) {
      fileName = ACT_NAME.COMPANION_OF_AI_GOV_FRAMEWORK
      columns = NIST_AI_RISK_COLUMN;
      dataSource = COMPANION_OF_AI_GOV_FRAMEWORK_DATA;
    } else if (reportName === ACT_NAME.MICRO_RES_AI_IMAPCT_ASS_GUIDE) {
      fileName = ACT_NAME.MICRO_RES_AI_IMAPCT_ASS_GUIDE
      columns = NIST_AI_RISK_COLUMN;
      dataSource = MICRO_RES_AI_IMAPCT_ASS_GUIDE_DATA;
    } else if (reportName === ACT_NAME.MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2) {
      fileName = ACT_NAME.MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2
      columns = NIST_AI_RISK_COLUMN;
      dataSource = MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2_DATA;
    } else if (reportName === ACT_NAME.MICRO_RES_AI_STD) {
      fileName = ACT_NAME.MICRO_RES_AI_STD
      columns = NIST_AI_RISK_COLUMN;
      dataSource = MICRO_RES_AI_STD_DATA;
    } else if (reportName === ACT_NAME.MODEL_AI_GOV_FRAMEWORK_SEC_EDITION) {
      fileName = ACT_NAME.MODEL_AI_GOV_FRAMEWORK_SEC_EDITION
      columns = NIST_AI_RISK_COLUMN;
      dataSource = MODEL_AI_GOV_FRAMEWORK_SEC_EDITION_DATA;
    } else if (reportName === ACT_NAME.TRUSTWORTHY_AI_PLAYBOOK) {
      fileName = ACT_NAME.TRUSTWORTHY_AI_PLAYBOOK
      columns = NIST_AI_RISK_COLUMN;
      dataSource = TRUSTWORTHY_AI_PLAYBOOK_DATA;
    }

    else if (reportName === ACT_NAME.EU_AI_ACT) {
      fileName = ACT_NAME.EU_AI_ACT
      columns = EU_AI_ACT_COLUMN;
      dataSource = EU_AI_ACT_DATA;
    } else if (reportName === ACT_NAME.SINGAPORE_AI_REG_ACT) {
      fileName = ACT_NAME.SINGAPORE_AI_REG_ACT
      columns = SINGAPORE_AI_REG_ACT_COLUMN;
      dataSource = SINGAPORE_AI_REG_ACT_DATA;
    } else if (reportName === ACT_NAME.TRUSTED_AI) {
      fileName = ACT_NAME.TRUSTED_AI
      columns = TRUSTED_AI_COLUMN;
      dataSource = TRUSTED_AI_DATA;
    } else if (reportName === ACT_NAME.NIST_AI_RISK) {
      fileName = ACT_NAME.NIST_AI_RISK
      columns = NIST_AI_RISK_COLUMN;
      dataSource = NIST_AI_RISK_DATA;
    }

    else if (reportName === ACT_NAME.SINGAPORE_AI_REGULATION) {
      fileName = ACT_NAME.SINGAPORE_AI_REGULATION
      columns = SINGAPORE_AI_REG_ACT_COLUMN;
      dataSource = SINGAPORE_AI_REGULATIONS_DATA;
    } else if (reportName === ACT_NAME.SINGAPORE_PDPC_ACT) {
      fileName = ACT_NAME.SINGAPORE_PDPC_ACT
      columns = SINGAPORE_AI_REG_ACT_COLUMN;
      dataSource = SINGAPORE_PDPC_ACT_DATA;
    }
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true
      })
      .saveAs(`${fileName}.xlsx`);
  };

  useEffect(() => {
    switch (extractedText) {
      case ACT_NAME.SINGAPORE_LINK:
        setReportName(ACT_NAME.SINGAPORE);
        setColumns(SINGAPORE_ACTS_COLUMN);
        setFilteredRecords(SINGAPORE_DATA_PRIVACY_ACT)
        setDataSource(SINGAPORE_DATA_PRIVACY_ACT);
        break;
      case ACT_NAME.EUROPE_LINK:
        setReportName(ACT_NAME.EUROPE);
        setColumns(ACTS_COLUMN);
        setFilteredRecords(EUROPE_DATA_PRIVACY_ACT)
        setDataSource(EUROPE_DATA_PRIVACY_ACT);
        break;
      case ACT_NAME.UK_LINK:
        setReportName(ACT_NAME.UK);
        setColumns(ACTS_COLUMN);
        setFilteredRecords(UK_DATA_PRIVACY_ACT)
        setDataSource(UK_DATA_PRIVACY_ACT);
        break;
      case ACT_NAME.CANADA_LINK:
        setReportName(ACT_NAME.CANADA);
        setColumns(ACTS_COLUMN);
        setFilteredRecords(CANADA_DATA_PRIVACY_ACT)
        setDataSource(CANADA_DATA_PRIVACY_ACT);
        break;
      case ACT_NAME.USA_LINK:
        setReportName(ACT_NAME.USA);
        setColumns(USA_ACTS_COLUMN);
        setFilteredRecords(USA_DATA_PRIVACY_ACT)
        setDataSource(USA_DATA_PRIVACY_ACT);
        break;
      case ACT_NAME.GDPR_LINK:
        setReportName(ACT_NAME.GDPR);
        setColumns(GDPR_COLUMN);
        setFilteredRecords(GDPR_CLAUSE_TERMS)
        setDataSource(GDPR_CLAUSE_TERMS);
        break;
      case ACT_NAME.COMPANION_OF_AI_GOV_FRAMEWORK_LINK:
        setReportName(ACT_NAME.COMPANION_OF_AI_GOV_FRAMEWORK);
        setColumns(NIST_AI_RISK_COLUMN);
        setFilteredRecords(COMPANION_OF_AI_GOV_FRAMEWORK_DATA)
        setDataSource(COMPANION_OF_AI_GOV_FRAMEWORK_DATA);
        break;
      case ACT_NAME.MICRO_RES_AI_IMAPCT_ASS_GUIDE_LINK:
        setReportName(ACT_NAME.MICRO_RES_AI_IMAPCT_ASS_GUIDE);
        setColumns(NIST_AI_RISK_COLUMN);
        setFilteredRecords(MICRO_RES_AI_IMAPCT_ASS_GUIDE_DATA)
        setDataSource(MICRO_RES_AI_IMAPCT_ASS_GUIDE_DATA);
        break;
      case ACT_NAME.MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2_LINK:
        setReportName(ACT_NAME.MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2);
        setColumns(NIST_AI_RISK_COLUMN);
        setFilteredRecords(MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2_DATA)
        setDataSource(MICRO_RES_AI_IMAPCT_ASS_TEMPLATE_2_DATA);
        break;
      case ACT_NAME.MICRO_RES_AI_STD_LINK:
        setReportName(ACT_NAME.MICRO_RES_AI_STD);
        setColumns(NIST_AI_RISK_COLUMN);
        setFilteredRecords(MICRO_RES_AI_STD_DATA)
        setDataSource(MICRO_RES_AI_STD_DATA);
        break;
      case ACT_NAME.MODEL_AI_GOV_FRAMEWORK_SEC_EDITION_LINK:
        setReportName(ACT_NAME.MODEL_AI_GOV_FRAMEWORK_SEC_EDITION);
        setColumns(NIST_AI_RISK_COLUMN);
        setFilteredRecords(MODEL_AI_GOV_FRAMEWORK_SEC_EDITION_DATA)
        setDataSource(MODEL_AI_GOV_FRAMEWORK_SEC_EDITION_DATA);
        break;
      case ACT_NAME.TRUSTWORTHY_AI_PLAYBOOK_LINK:
        setReportName(ACT_NAME.TRUSTWORTHY_AI_PLAYBOOK);
        setColumns(NIST_AI_RISK_COLUMN);
        setFilteredRecords(TRUSTWORTHY_AI_PLAYBOOK_DATA)
        setDataSource(TRUSTWORTHY_AI_PLAYBOOK_DATA);
        break;
      case ACT_NAME.EU_AI_ACT_LINK:
        setReportName(ACT_NAME.EU_AI_ACT);
        setColumns(EU_AI_ACT_COLUMN);
        setFilteredRecords(EU_AI_ACT_DATA)
        setDataSource(EU_AI_ACT_DATA);
        break;
      case ACT_NAME.SINGAPORE_AI_REG_ACT_LINK:
        setReportName(ACT_NAME.SINGAPORE_AI_REG_ACT);
        setColumns(SINGAPORE_AI_REG_ACT_COLUMN);
        setFilteredRecords(SINGAPORE_AI_REG_ACT_DATA)
        setDataSource(SINGAPORE_AI_REG_ACT_DATA);
        break;
      case ACT_NAME.TRUSTED_AI_LINK:
        setReportName(ACT_NAME.TRUSTED_AI);
        setColumns(TRUSTED_AI_COLUMN);
        setFilteredRecords(TRUSTED_AI_DATA)
        setDataSource(TRUSTED_AI_DATA);
        break;
      case ACT_NAME.NIST_AI_RISK_LINK:
        setReportName(ACT_NAME.NIST_AI_RISK);
        setColumns(NIST_AI_RISK_COLUMN);
        setFilteredRecords(NIST_AI_RISK_DATA)
        setDataSource(NIST_AI_RISK_DATA);
        break;
      case ACT_NAME.SINGAPORE_AI_REGULATION_LINK:
        setReportName(ACT_NAME.SINGAPORE_AI_REGULATION);
        setColumns(SINGAPORE_AI_REG_ACT_COLUMN);
        setFilteredRecords(SINGAPORE_AI_REGULATIONS_DATA)
        setDataSource(SINGAPORE_AI_REGULATIONS_DATA);
        break;
      case ACT_NAME.SINGAPORE_PDPC_ACT_LINK:
        setReportName(ACT_NAME.SINGAPORE_PDPC_ACT);
        setColumns(SINGAPORE_AI_REG_ACT_COLUMN);
        setFilteredRecords(SINGAPORE_PDPC_ACT_DATA)
        setDataSource(SINGAPORE_PDPC_ACT_DATA);
        break;
      default:
    }
  }, [extractedText])

  const handleSearch = (event: any) => {
    // console.log((event.target.value).length)
    if (event.target.value !== "") {
      const filterTable: any = dataSource?.filter((o: any) =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes((event.target.value).toLowerCase())
        )
      );
      setFilteredRecords(filterTable);
    } else {
      setFilteredRecords(dataSource)
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2 mt-3">
        <div className="d-flex justify-content-between align-items-center w-100 px-4">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-dark-grey"
            style={{ fontWeight: "700" }}
          >
            {reportName}
          </h5>
          <div className='d-flex'>
            <div className="input-group px-2 mt-1">
              <Form.Control
                type="search"
                placeholder="Search"
                className="border-0 bg-cm-light-primary1"
                aria-label="Search"
                onChange={handleSearch}
              />
              <div className="input-group-append bg-cm-light-primary1 p-1 rounded-end">
                <span className="input-group-text bg-cm-light-primary1 border-0">
                  {/* <img src={gear} alt="" /> */}
                  <BsSearch className=" bg-cm-light-primary1 border-cm-light-primary1" />
                </span>
              </div>
            </div>
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleExportClick}
            >
              <BsDownload size={16} className="me-2" />
              Export
            </Button>
          </div>
        </div>

      </div>
      <div className="mt-2 overflow-y-auto mx-3">
        <div className="my-2 w-100 overflow-x-auto rounded-3">
          <Table
            bordered={true}
            rowKey={(obj: any, i: any) => {
              return i
            }}
            pagination={false}
            columns={columns}
            dataSource={filteredRecords}
            scroll={{ y: 'calc(100vh - 145px)' }}  // Set the height of the table body to enable vertical scrolling
            sticky // Enable sticky headers
          />
        </div>
      </div>
      <footer className="text-align-center">
        <p className="text-cm-dark fs-6">
          Capitol Tunnels.ai &#169; 2023
        </p>
      </footer>
    </>
  );
}

export default AllActDataPrivacyRegulations;