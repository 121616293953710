import { Checkbox, Input, Table, TableColumnsType, Tooltip } from "antd";
import { HashLink } from "react-router-hash-link";
import { fDateTimeSuffix, fTime } from "../component/utils";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { BsArrowDown, BsArrowUp, BsFileEarmarkText, BsFilter, BsFunnel } from "react-icons/bs";
import { format } from "date-fns";
import { useState } from "react";
import { Field } from "formik";
import TextArea from "antd/es/input/TextArea";

export enum USER_ROLE {
  ADMIN = "Admin",
  CORPORATE = "Corporate",
  USER = "User",
  ADMINISTRATOR = "Administrator",
  GOVERNMENT = "Government",
  ACADEMICIAN = "Academician",
  NGO = "NGO",
  INNOVATOR = "Innovator",
  STARTUP = "Startup",
  STUDENT = "Student",
  BETA_TEST_USER = "Beta Test User"
}

export enum PAGE_TITLE {
  FORGOT_PASSWORD = "Forgot Password",
  RESET_PASSWORD = "Reset Password",
  DASHBOARD = "Dashboard",
  MASTER = "Master",
  COMPLIANCE_TEST = "Compliance Test",
  AI_GOVERNANCE_TITLE = "AI Governance",
  FAQ = "FAQs",
  MATCH_MAKER = "Match Maker",
  DOCUMENT = "Document"
}

export const HEADER_ITEMS = [
  {
    type: "link",
    title: (
      <HashLink smooth to="/#About-Us">
        About Us
      </HashLink>
    ),
    link: "/About-Us",
  },
  {
    type: "link",
    title: (
      <HashLink smooth to="/#Value -Proposition">
        Value Proposition
      </HashLink>
    ),
    link: "/Value -Proposition",
  },
  {
    type: "link",
    title: (
      <HashLink smooth to="/#ReguVista">
        ReguVista
      </HashLink>
    ),
    link: "/ReguVista",
  },
];

export const ALL_SIDE_NAV_LIST = [
  {
    type: "link",
    title: (
      <HashLink smooth to="/#About-Us">
        About Us
      </HashLink>
    ),
    link: "/About-Us",
  },
  {
    type: "link",
    title: (
      <HashLink smooth to="/#Value -Proposition">
        Value Proposition
      </HashLink>
    ),
    link: "/Value -Proposition",
  },
  {
    type: "link",
    title: (
      <HashLink smooth to="/#ReguVista">
        ReguVista
      </HashLink>
    ),
    link: "/ReguVista",
  },
];

export const VALUE_CHAIN_CATEGORY_COLUMN = [
  {
    // key: "categoryId",
    dataIndex: "id",
    title: "Category Id",
  },
  {
    // key: "categoryName",
    dataIndex: "name",
    title: "Category Name",
  },
];

export const VALUE_CHAIN_CATEGORY_DATASOURCE = [
  {
    // key: 1,
    categoryId: 1,
    categoryName: "xzyz",
  },
  {
    // key: 2,
    categoryId: 2,
    categoryName: "abcd",
  },
];

export const VALUE_CHAIN_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Value Chain Id",
  },
  {
    key: "categoryName",
    dataIndex: "categoryName",
    title: "Value Chain Category Name",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Value Chain Name",
  },
];

export const TERM_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Term Id",
  },
  {
    key: "termSetName",
    dataIndex: "termsetName",
    title: "Term Set Name",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Term Name",
  },
];

export const TERM_SET_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Term Set Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Term Set Name",
  },
];

export const VALUE_CHAIN_DATASOURCE = [
  {
    key: 1,
    valueChainId: 1,
    valueChainName: "xzyz",
  },
  {
    key: 2,
    valueChainId: 2,
    valueChainName: "abcd",
  },
];

export const DATA_PIPELINE_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Data Pipeline Id",
  },
  {
    key: "pipelineCategoryName",
    dataIndex: "pipelineCategoryName",
    title: "Data Pipeline Category Name",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Data Pipeline Name",
  },
];

export const DATA_PIPELINE_CATEGORY_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Data Pipeline Category Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Data Pipeline Category Name",
  },
];

export const USER_ROLE_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Subscriber Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Subscriber Name",
  },
];
export const USER_GROUP_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "User Group Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "User Group Name",
  },
];

export const USER_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "User Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Name",
  },
  {
    key: "mobileNo",
    dataIndex: "phoneNumber",
    title: "Mobile Number",
  },
  {
    key: "email",
    dataIndex: "email",
    title: "Email",
  },
  {
    key: "role",
    dataIndex: "userRole",
    title: "Subscriber",
  },
  {
    key: "group",
    dataIndex: "userGroup",
    title: "User Group",
  },
];

export const VALUE_CHAIN_PARAGRAPH_EXTRACT_COLULMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Id",
    width: 80,
  },
  {
    key: "valueChain",
    dataIndex: "valueChain",
    title: "Value Chain",
    width: 80,
  },
  {
    key: "dataRecord",
    dataIndex: "dataRecord",
    title: "Data Record",
    width: 80,
  },
  {
    key: "dataContentLink",
    dataIndex: "dataContentLink",
    title: "Data Content Link",
    // ellipsis: true,
    width: 300,
    render: (index: any, record: any) => {
      return (
        <a className="link-primary text-decoration-underline" onClick={() => {
          window.open(record.dataContentLink, '_blank')
        }}>
          {record.dataContentLink}
        </a>
      );
    },
  },
  {
    key: "paragraphContent",
    dataIndex: "paragraphContent",
    title: "Paragraph Content",
    ellipsis: true,
    width: 400,
    // render: (text: any) => {
    //   return (
    //     <>
    //       <p>{text.split("@")[0]}</p>
    //       <p>{text.split("@")[1]}</p>
    //     </>)
    // }
  },
];

export const DATA_RECORDS_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Id",
    width: 80,
    // sorter: true,
    sorter: (a: any, b: any) => a.id.toString().localeCompare(b.id.toString()),
  },
  {
    key: "dataContentLink",
    dataIndex: "dataContentLink",
    title: "Data Content Link",
    // ellipsis: true,
    sorter: (a: any, b: any) => ((a.dataContentLink).toLowerCase()).localeCompare((b.dataContentLink).toLowerCase()),
    width: 300,
    render: (index: any, record: any) => {
      return (
        <a className="link-primary text-decoration-underline" onClick={() => {
          window.open(record.dataContentLink, '_blank')
        }}>
          {record.dataContentLink}
        </a>
      );
    },
  },
  {
    key: "dataContent",
    dataIndex: "dataContent",
    title: "Data Content",
    ellipsis: true,
    width: 500,
    // ellipsis: {
    //   showTitle: false,
    // },
    // render: (dataContent: any) => (
    //   <Tooltip placement="topLeft" title={dataContent}>
    //     {dataContent}
    //   </Tooltip>
    // ),
  },
  {
    key: "valueChainCategoryName",
    dataIndex: "valueChainCategoryName",
    title: "Value Chain Category Name",
    width: 350,
    sorter: (a: any, b: any) => ((a.valueChainCategoryName).toLowerCase()).localeCompare((b.valueChainCategoryName).toLowerCase()),
  },
  {
    key: "valueChainName",
    dataIndex: "valueChainName",
    title: "Value Chain Name",
    width: 350,
    sorter: (a: any, b: any) => ((a.valueChainName).toLowerCase()).localeCompare((b.valueChainName).toLowerCase()),
  },
  {
    key: "termSetName",
    dataIndex: "termSetName",
    title: "Term Set Name",
    width: 350,
    sorter: (a: any, b: any) => ((a.termSetName).toLowerCase()).localeCompare((b.termSetName).toLowerCase()),
  },
  {
    key: "country",
    dataIndex: "country",
    title: "Country",
    width: 150,
    sorter: (a: any, b: any) => ((a.country).toLowerCase()).localeCompare((b.country).toLowerCase()),
  },
  {
    key: "state",
    dataIndex: "state",
    title: "State",
    width: 150,
  },
  {
    key: "city",
    dataIndex: "city",
    title: "City",
    width: 150,
  },
  {
    key: "createdOn",
    dataIndex: "createdOn",
    title: "Date Extracted",
    width: 180,
  },
  {
    key: "dataQualityStatus",
    dataIndex: "dataQualityStatus",
    title: "Data Quality Status",
    width: 80,
  },
  {
    key: "sourcecategoryid",
    dataIndex: "sourcecategoryid",
    title: "Source Category Id",
    width: 80,
  },
];

export const COUNTRY_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Country Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Country Name",
  },
];

export const STATE_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "State Id",
  },
  {
    key: "name",
    dataIndex: "countryName",
    title: "Country Name",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "State Name",
  },
];

export const SENSITIVE_BIAS_DATA_IDENTIFICATION_COLUMN = [
  {
    key: "dataType",
    dataIndex: "dataType",
    title: "Data Type",
  },
  {
    key: "columnsIdentified",
    dataIndex: "columnsIdentified",
    title: "Columns Identified",
  },
  {
    key: "recommendations ",
    dataIndex: "recommendations ",
    title: "Recommendations ",
  },
]

export const DATA_PRIVACY_COMPLIANCE_TEST = [
  {
    key: "file_name",
    dataIndex: "file_name",
    title: "File name",
    render: (index: any, record: any) => {
      const parts = (record.file_name).split("//");
      const splittedfileName = parts[parts.length - 1];
      return (
        <p>
          {splittedfileName}
        </p>
      );
    },
  },
  {
    key: "article_no",
    dataIndex: "article_no",
    title: "Article No",
    render: (index: any, record: any) => {
      const parts = (record.article_no).split("$");
      const articleNo = parts[parts.length - 1];

      const articleLink = record.article_no.substring(0, record.article_no.indexOf('$'));

      return (
        <a className="link-primary text-decoration-underline" onClick={() => {
          window.open(articleLink, '_blank')
        }}>
          {articleNo}
        </a>
      );
    },
  },
  {
    key: "terms",
    dataIndex: "terms",
    title: "Terms",
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status ",
    render: (index: any, record: any) => {
      const statusRemark = record.status === "Pass" ? "Strength" : "Opportunities"
      return (
        <p>{statusRemark}</p>
      );
    },
  },
  {
    key: "data",
    dataIndex: "data",
    title: "Data",
    // ellipsis: true,
    // width: 500,
  },
]

function removeLastComma(strng: any) {
  var n = strng.lastIndexOf(",");
  var a = strng.substring(0, n)
  return a;
}

const termClicked = (term: any) => {

  // const terms = removeLastComma(term)
  localStorage.setItem("terms", term)
}

export const AI_REGULATION_COMPLIANCE_TEST = [
  {
    key: "file_name",
    dataIndex: "file_name",
    title: "File name",
    render: (index: any, record: any) => {
      const parts = (record.file_name).split("//");
      const splittedfileName = parts[parts.length - 1];
      return (
        <p>
          {splittedfileName}
        </p>
      );
    },
  },
  {
    key: "checklist_points",
    dataIndex: "checklist_points",
    title: "Checklist points",
  },
  {
    key: "terms",
    dataIndex: "terms",
    title: "Terms",
    render: (index: any, record: any) => {

      return (
        // <a className="link-primary text-decoration-underline" onClick={() => {
        //   window.open(`/ai-governance?terms=${record.terms}`)
        // }}>
        //   {record.terms}
        // </a>
        <Link to={'/ai-governance'}
          onClick={() => termClicked(record.terms)}
          target="_blank"
        >
          {record.terms}
        </Link>

      );
    },
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status",
    render: (index: any, record: any) => {
      const statusRemark = record.status === "Pass" ? "Strength" : "Opportunities"
      return (
        <p>{statusRemark}</p>
      );
    },
  },
  {
    key: "description",
    dataIndex: "description",
    title: "Description",
    // ellipsis: true,
    // width: 500,
  },
  {
    key: "data",
    dataIndex: "data",
    title: "Data",
  },
]

export const DATA_SET_BIAS_TEST_COLUMN = [
  {
    key: "column",
    dataIndex: "column",
    title: "Column",
  },
  {
    key: "biasAnalysis",
    dataIndex: "biasAnalysis",
    title: "Bias Analysis",
  },
  {
    key: "maxPercentage",
    dataIndex: "maxPercentage",
    title: "Max Percentage",
  },
  {
    key: "maxValue",
    dataIndex: "maxValue",
    title: "Max Value",
  },
  {
    key: "fairnessScore",
    dataIndex: "fairnessScore",
    title: "Fairness Score",
  },
  {
    key: "explicitFairnessFormula",
    dataIndex: "explicitFairnessFormula",
    title: "Explicit Fairness Formula",
  },
]

export const AI_ALGORITHM_ACCURACY_RELIABILITY_TEST_COLUMN = [
  {
    key: "algorithmName",
    dataIndex: "algorithmName",
    title: "Algorithm Name",
  },
  {
    key: "dataset",
    dataIndex: "dataset",
    title: "Dataset",
  },
  {
    key: "variable",
    dataIndex: "variable",
    title: "Variable",
  },
  {
    key: "accuracy",
    dataIndex: "accuracy",
    title: "Accuracy",
  },
  {
    key: "reliability",
    dataIndex: "reliability",
    title: "Reliability",
  },
]

export const DATA_PRIVACY_PENALTY_PREDICTION_COLUMN = [
  // {
  //   key: "srNo",
  //   dataIndex: "srNo",
  //   title: "Sr No",
  // },
  {
    key: "Country",
    dataIndex: "Country",
    title: "Country",
  },
  {
    key: "Data Privacy Regulation",
    dataIndex: "Data Privacy Regulation",
    title: "Data Privacy Regulation"
  },
  {
    key: "Penalty",
    dataIndex: "Penalty",
    title: "Penalty",
  },
  {
    key: "Currency",
    dataIndex: "Currency",
    title: "Currency",
  },
]

// export const FAIRNESS_METRICS_FOR_MACHINE_LEARNING_COLUMN = [
//   {
//     key: "metric",
//     dataIndex: "metric",
//     title: "Metric",
//   },
//   {
//     key: "value",
//     dataIndex: "value",
//     title: "Value",
//     render: (index: any, record: any) => {
//       let valueData = Object.keys(record["value"]).map(key => {
//         return (
//           <div>
//             <p>{key}:{record["value"][key]} </p>
//             {/* `${key}:${element[1][key]}`).join("."); */}
//           </div>
//         )
//       })
//       return (
//         <div>
//           {valueData}
//         </div>
//       );
//     },
//   },
// ]

export const FAIRNESS_METRICS_FOR_MACHINE_LEARNING_COLUMN = [
  // {
  //   key: "model",
  //   dataIndex: "model",
  //   title: "Model",
  // },
  {
    key: "metric",
    dataIndex: "metric",
    title: "Metric",
  },
  {
    key: "FDR",
    dataIndex: "FDR",
    title: "False Discovery Rate (FDR)",
  },
  {
    key: "FDRAnalysis",
    dataIndex: "FDRAnalysis",
    title: "False Discovery Rate Analysis",
  },
  {
    key: "FNR",
    dataIndex: "FNR",
    title: "False Negative Rate (FNR)",
  },
  {
    key: "FNRAnalysis",
    dataIndex: "FNRAnalysis",
    title: "False Negative Rate Analysis",
  },
  {
    key: "FPR",
    dataIndex: "FPR",
    title: "False Positive Rate",
  },
  {
    key: "FPRAnalysis",
    dataIndex: "FPRAnalysis",
    title: "False Positive Rate Analysis",
  },
  {
    key: "NPV",
    dataIndex: "NPV",
    title: "Negative Predictive Value (NPV)",
  },
  {
    key: "NPVAnalysis",
    dataIndex: "NPVAnalysis",
    title: "Negative Predictive Value Analysis",
  },
  {
    key: "PPV",
    dataIndex: "PPV",
    title: "Positive Predictive Value (PPV)",
  },
  {
    key: "PPVAnalysis",
    dataIndex: "PPVAnalysis",
    title: "Positive Predictive Value Analysis",
  },
  {
    key: "TPR",
    dataIndex: "TPR",
    title: "True Positive Rate (TPR)",
  },
  {
    key: "TPRAnalysis",
    dataIndex: "TPRAnalysis",
    title: "True Positive Rate Analysis",
  },
]

export const CODE_CYBER_SEC_VUL_ANALYSIS_COLUMN = [
  {
    key: "file_name",
    dataIndex: "file_name",
    title: "File name",
    render: (index: any, record: any) => {
      const parts = (record.file_name).split("//");
      const splittedfileName = parts[parts.length - 1];
      return (
        <p>
          {splittedfileName}
        </p>
      );
    },
  },
  {
    // key: "pattern_type",
    // dataIndex: "pattern_type",
    key: "bias_type",
    dataIndex: "bias_type",
    title: "Patterntype",
  },
  // {
  //   key: "line_no",
  //   dataIndex: "line_no",
  //   title: "Line no.",
  // },
  {
    // key: "vulnerability",
    // dataIndex: "vulnerability",
    key: "potential_bias",
    dataIndex: "potential_bias",
    title: "Vulnerability",
  },
  {
    key: "algorithm_package_term",
    dataIndex: "algorithm_package_term",
    title: "Algorithm package term",
  },
  // {
  //   key: "implications_and_remedial_actions",
  //   dataIndex: "implications_and_remedial_actions",
  //   title: "Implications and remedial actions",
  // },
]

export const CODE_AI_BIAS_ANALYSIS_COLUMN = [
  {
    key: "file_name",
    dataIndex: "file_name",
    title: "File name",
    render: (index: any, record: any) => {
      const parts = (record.file_name).split("//");
      const splittedfileName = parts[parts.length - 1];
      return (
        <p>
          {splittedfileName}
        </p>
      );
    },
  },
  {
    key: "line_no",
    dataIndex: "line_no",
    title: "Line no.",
  },
  {
    key: "bias_type",
    dataIndex: "bias_type",
    title: "Bias type ",
  },
  {
    key: "potential_bias",
    dataIndex: "potential_bias",
    title: "Potential bias",
  },
  {
    key: "algorithm_package_term",
    dataIndex: "algorithm_package_term",
    title: "Algorithm package term",
    // ellipsis: true,
    // width: 500,
  },
]

export const SENSITIVE_AND_BIAS_DATA_IDENTIFICATION_COLUMN = [
  {
    key: "category",
    dataIndex: "category",
    title: "Category"
  },
  {
    key: "recommendations",
    dataIndex: "recommendations",
    title: "Recommendations",
    // render: (index: any, record: any) => {
    //   return (
    //     <a className="link-primary text-decoration-underline" onClick={() => {
    //       window.open(record.dataContentLink, '_blank')
    //     }}>
    //       {record.dataContentLink}
    //     </a>
    //   );
    // },
  }
]

export const FEATURE_DOMINANCE_COLUMN = [
  {
    key: "feature",
    dataIndex: "feature",
    title: "Feature"
  },
  {
    key: "value",
    dataIndex: "value",
    title: "Value"
  },
]

export const PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_COLUMN = [
  {
    key: "fileName",
    dataIndex: "fileName",
    title: "FileName"
  },
  {
    key: "type",
    dataIndex: "type",
    title: "Type"
  },
  {
    key: "sensitivity_score",
    dataIndex: "sensitivity_score",
    title: "Sensitivity Score"
  },
  {
    key: "count",
    dataIndex: "count",
    title: "Count"
  },
]

export const PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_DATSOURCE = [
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "0"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "0"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "1.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "2"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "1"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "1"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "0"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "1"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "1"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "43"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "0"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "2"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "2"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "6"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "3"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "2"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "1"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "2"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "0"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "2"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "1"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "2"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "12"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "0"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "1"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "3"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "1"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "0"
  }
]

export const USER_ACTIVITY_COLUMN = [
  {
    key: "userId",
    dataIndex: "userId",
    title: "User Id"
  },
  {
    key: "username",
    dataIndex: "username",
    title: "User Name"
  },
  {
    key: "url",
    dataIndex: "url",
    title: "Url Visited"
  },
  {
    key: "eventName",
    dataIndex: "eventName",
    title: "Event"
  },
  {
    key: "duration",
    dataIndex: "duration",
    title: "Duration",
    render: (index: any, record: any) => {

      return (
        <p>
          {`${record?.duration} mins`}
        </p>
      );
    },
  },
  {
    key: "startTime",
    dataIndex: "startTime",
    title: "Date and Time",
    render: (index: any, record: any) => {
      const dateTime = fDateTimeSuffix(record?.startTime)
      return (
        <p>
          {dateTime}
        </p>
      );
    },
  },
]

export const EMPLOYMENT_BIAS_COLUMN = [
  {
    key: "category",
    dataIndex: "category",
    title: "Category"
  },
  {
    key: "category1",
    dataIndex: "category1",
    title: "Category1"
  },
  {
    key: "values",
    dataIndex: "values",
    title: "Values"
  },
]

export const AI_GOVERNANCE_EXPECTATIONS_COLUMN = [
  {
    key: "keyControls",
    dataIndex: "keyControls",
    title: "Key Controls"
  },
  {
    key: "mappedGovernanceSections",
    dataIndex: "mappedGovernanceSections",
    title: "Mapped Governance Sections"
  },
  {
    key: "euAiActReference",
    dataIndex: "euAiActReference",
    title: "EU AI Act Reference"
  },
  {
    key: "euAiActExpectations",
    dataIndex: "euAiActExpectations",
    title: "EU AI Act Expectations"
  },
  {
    key: "NISTGovernanceExpectation",
    dataIndex: "NISTGovernanceExpectation",
    title: "NIST Governance Expectation"
  },
  {
    key: "governanceExpectationExplanation",
    dataIndex: "governanceExpectationExplanation",
    title: "Governance Expectation Explanation"
  },
]

export const TERM_COMPARISON_ANALYSIS_COLUMN = [
  {
    key: "fileName",
    dataIndex: "fileName",
    title: "FileName",
    render: (text: any) => {
      const fileName = text.split('/').pop();
      return <span>{fileName}</span>;
    }
  },
  {
    key: "keyGroup",
    dataIndex: "keyGroup",
    title: "Key Group",
    render: (index: any, record: any) => {
      if (!record.keyGroup) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.keyGroup}
          </p>
        );
      }
    },
  },
  {
    key: "keyTerm",
    dataIndex: "keyTerm",
    title: "Key Term"
  },
  {
    key: "frequency",
    dataIndex: "frequency",
    title: "Frequency"
  },
]

export const AI_GOVERNANCE_EXPECTATIONS_DATA = [
  {
    keyControls: "External Audits and Third-Party Assessments",
    mappedGovernanceSections: "External, Audits, Third-Party, Assessments",
    NISTGovernanceExpectation: "Auditing and Documentation (GOVERN 6.3)",
    governanceExpectationExplanation: "External audits and third-party assessments are expected to be conducted to ensure compliance with AI governance standards and document the findings for transparency and accountability.",
    euAiActReference: "Article 61, Article 62, Article 63, Article 64, Article 65, Article 66, Article 67, Article 68",
    euAiActExpectations: "Post-market monitoring, Information Sharing, Market Surveillance, Enforcement",
  },
  {
    keyControls: "Transparency and Data Disclosure",
    mappedGovernanceSections: "Transparency, Data, Disclosure, AI Driven Decision Making Process",
    NISTGovernanceExpectation: "Transparency and Data Disclosure (GOVERN 2.1)",
    governanceExpectationExplanation: "AI-driven decision-making processes should be transparent, and data disclosure practices should be in place to provide insight into how AI systems make decisions and use data.",
    euAiActReference: "Article 52",
    euAiActExpectations: "Transparency obligations for certain AI systems",
  },
  {
    keyControls: "Secure Data Storage and Encryption",
    mappedGovernanceSections: "Secure, Data, Storage, Encryption",
    NISTGovernanceExpectation: "Data Security and Storage (GOVERN 1.2)",
    governanceExpectationExplanation: "AI systems should ensure secure data storage and encryption to protect sensitive information and maintain data integrity.",
    euAiActReference: "Article 10",
    euAiActExpectations: "Data and data governance",
  },
  {
    keyControls: "Right to Explanation and Appeal",
    mappedGovernanceSections: "Right, Explanation, Appeal",
    NISTGovernanceExpectation: "User Rights and Consent (GOVERN 4.2)",
    governanceExpectationExplanation: "Users should have the right to receive an explanation of AI-driven decisions and the ability to appeal decisions that affect them adversely.",
    euAiActReference: "Article 16",
    euAiActExpectations: "Obligations of providers of high-risk AI systems",
  },
  {
    keyControls: "Right to Access and Erasure",
    mappedGovernanceSections: "Right, Access, Erasure, AI Systems",
    NISTGovernanceExpectation: "Data Protection and Privacy (GOVERN 2.3)",
    governanceExpectationExplanation: "Users should have the right to access their data held by AI systems and request the erasure of their data, aligning with data protection and privacy regulations.",
    euAiActReference: "Article 16, Article 29",
    euAiActExpectations: "Obligations of providers and users of high-risk AI systems",
  },
  {
    keyControls: "Monitoring and Feedback Mechanisms",
    mappedGovernanceSections: "Monitoring, Feedback, Mechanisms",
    NISTGovernanceExpectation: "Continuous Improvement (GOVERN 7.1)",
    governanceExpectationExplanation: "Monitoring and feedback mechanisms are expected to be in place to continuously improve AI systems and address issues and concerns proactively.",
    euAiActReference: "Article 61",
    euAiActExpectations: "Post-market monitoring by providers and post-market monitoring plan",
  },
  {
    keyControls: "Bias and Fairness Mitigation",
    mappedGovernanceSections: "Bias, Fairness, Mitigation, AI Systems",
    NISTGovernanceExpectation: "Ethical and Fair Use (GOVERN 4.2)",
    governanceExpectationExplanation: "Efforts should be made to identify and mitigate bias and promote fairness in AI systems to ensure equitable outcomes.",
    euAiActReference: "Article 15",
    euAiActExpectations: "Accuracy, robustness and cybersecurity",
  },
  {
    keyControls: "Risk Assessment and Mitigation",
    mappedGovernanceSections: "Risk, Assessment, Mitigation, Identify, Classify, AI systems, Implement Measures",
    NISTGovernanceExpectation: "High-Risk AI Systems (GOVERN 1.1)",
    governanceExpectationExplanation: "Risk assessment and mitigation strategies are expected to be applied, particularly for high-risk AI systems, by identifying, classifying, and implementing appropriate risk mitigation measures.",
    euAiActReference: "Article 9",
    euAiActExpectations: "Risk management system",
  },
  {
    keyControls: "User Awareness and Consent",
    mappedGovernanceSections: "User, Awareness, Consent",
    NISTGovernanceExpectation: "User Rights and Consent (GOVERN 4.1)",
    governanceExpectationExplanation: "Users should be made aware of how AI systems operate, and their consent should be obtained for data usage and AI-driven decisions.",
    euAiActReference: "Article 16",
    euAiActExpectations: "Obligations of providers of high-risk AI systems",
  },
  {
    keyControls: "Responsible AI Development Policies",
    mappedGovernanceSections: "Responsible, AI, Development, Policies",
    NISTGovernanceExpectation: "Accountability and Governance (GOVERN 3.1)",
    governanceExpectationExplanation: "Organizations should establish responsible AI development policies to ensure ethical AI outcomes and responsible behavior throughout the AI lifecycle.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
  {
    keyControls: "Governance Framework and Oversight",
    mappedGovernanceSections: "Governance, Framework, Oversight",
    NISTGovernanceExpectation: "Accountability and Governance (GOVERN 3.2)",
    governanceExpectationExplanation: "An effective governance framework and oversight mechanisms are expected to be in place to manage AI-related risks and ensure accountability.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
  {
    keyControls: "Impact Assessments for AI Systems",
    mappedGovernanceSections: "Impact, Assessments, AI, Systems",
    NISTGovernanceExpectation: "Accountability and Governance (GOVERN 3.3)",
    governanceExpectationExplanation: "Organizations should conduct impact assessments for AI systems to understand their effects and implications, contributing to overall accountability and governance.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
  {
    keyControls: "Transparency in Decision-Making",
    mappedGovernanceSections: "Transparency, Decision-Making",
    NISTGovernanceExpectation: "Ethical and Fair Use (GOVERN 4.3)",
    governanceExpectationExplanation: "Transparency in decision-making processes is crucial to ensure ethical and fair use of AI systems and to build trust with stakeholders.",
    euAiActReference: "Article 52",
    euAiActExpectations: "Transparency obligations for certain AI systems",
  },
  {
    keyControls: "Regular Security Audits and Updates",
    mappedGovernanceSections: "Regular, Security, Audits, Updates",
    NISTGovernanceExpectation: "Data Security and Storage (GOVERN 1.1)",
    governanceExpectationExplanation: "Regular security audits and updates are necessary to maintain the integrity and confidentiality of AI system data and protect against vulnerabilities.",
    euAiActReference: "Article 61",
    euAiActExpectations: "Post-market monitoring by providers and post-market monitoring plan",
  },
  {
    keyControls: "Documentation of AI Systems",
    mappedGovernanceSections: "Documentation, AI, Systems",
    NISTGovernanceExpectation: "Auditing and Documentation (GOVERN 6.1)",
    governanceExpectationExplanation: "Comprehensive documentation of AI systems should be maintained to facilitate auditing and accountability processes.",
    euAiActReference: "Article 11",
    euAiActExpectations: "Technical documentation",
  },
  {
    keyControls: "Adaptation to Regulatory Changes",
    mappedGovernanceSections: "Adaptation, Regulatory, Changes",
    NISTGovernanceExpectation: "Continuous Improvement (GOVERN 7.2)",
    governanceExpectationExplanation: "Organizations should be adaptable to regulatory changes and continuously improve AI governance practices to stay compliant and effective.",
    euAiActReference: "Article 84",
    euAiActExpectations: "Commission assessment of AI Act annually",
  },
  {
    keyControls: "Data Collection and Usage Consent",
    mappedGovernanceSections: "Data, Collection, Usage, Consent, AI Training",
    NISTGovernanceExpectation: "Data Protection and Privacy (GOVERN 1.6)",
    governanceExpectationExplanation: "Data collection and usage consent should align with AI training practices, respecting data protection and privacy regulations.",
    euAiActReference: "Article 10, Article 16",
    euAiActExpectations: "Data and data governance, Obligations of providers of high-risk AI systems",
  },
  {
    keyControls: "Regular Internal Audits and Review",
    mappedGovernanceSections: "Regular, Internal, Audits, Review",
    NISTGovernanceExpectation: "Auditing and Documentation (GOVERN 6.2)",
    governanceExpectationExplanation: "Regular internal audits and reviews of AI systems are essential to identify and rectify issues and maintain governance standards.",
    euAiActReference: "Article 61",
    euAiActExpectations: "Post-market monitoring by providers and post-market monitoring plan",
  },
  {
    keyControls: "Human Oversight and Intervention",
    mappedGovernanceSections: "Human, Oversight, Intervention",
    NISTGovernanceExpectation: "High-Risk AI Systems (GOVERN 1.3)",
    governanceExpectationExplanation: "High-risk AI systems should include mechanisms for human oversight and intervention to ensure safe and responsible operation.",
    euAiActReference: "Article 14",
    euAiActExpectations: "Human oversight",
  },
  {
    keyControls: "Ethical Use and Accountability",
    mappedGovernanceSections: "Ethical, Use, Accountability, Guidelines, AI outcomes, Responsible Behavior, Accountability",
    NISTGovernanceExpectation: "Ethical and Fair Use (GOVERN 4.1)",
    governanceExpectationExplanation: "Ethical use and accountability guidelines should be established to guide AI outcomes and promote responsible behavior and accountability.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
  {
    keyControls: "International Data Transfer Compliance",
    mappedGovernanceSections: "International, Data, Transfer, Compliance",
    NISTGovernanceExpectation: "Regulatory Compliance (GOVERN 5.2)",
    governanceExpectationExplanation: "Compliance with international data transfer regulations is essential for organizations handling global data and AI systems.",
    euAiActReference: "Article 6, Article 84",
    euAiActExpectations: "Classification rules for high-risk AI systems, Commission assessment of AI Act annually",
  },
  {
    keyControls: "Compliance with Sector-Specific Regulations",
    mappedGovernanceSections: "Compliance, Sector-Specific, Regulations",
    NISTGovernanceExpectation: "Compliance with Sector-Specific Regulations (GOVERN 2.1)",
    governanceExpectationExplanation: "Organizations must ensure compliance with sector-specific regulations that govern AI applications in specific industries.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
  {
    keyControls: "Data Minimization and Purpose Limitation",
    mappedGovernanceSections: "Data, Minimization, Purpose, Limitation",
    NISTGovernanceExpectation: "Data Protection and Privacy (GOVERN 1.5)",
    governanceExpectationExplanation: "Data minimization and purpose limitation principles should guide data handling and usage to protect data privacy and maintain compliance.",
    euAiActReference: "Article 10",
    euAiActExpectations: "Data and data governance",
  },
  {
    keyControls: "Compliance with National AI Regulations",
    mappedGovernanceSections: "Compliance, National, AI, Regulations",
    NISTGovernanceExpectation: "Regulatory Compliance (GOVERN 5.1)",
    governanceExpectationExplanation: "Organizations must ensure compliance with national AI regulations to operate AI systems within legal boundaries.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
];

export const PACKAGE_COLUMN = [
  {
    // key: "categoryId",
    dataIndex: "id",
    title: "Package Id",
  },
  {
    // key: "categoryName",
    dataIndex: "name",
    title: "Package Name",
  },
  {
    // key: "categoryName",
    dataIndex: "packageDescription",
    title: "Package Description",
  },
  {
    // key: "categoryName",
    dataIndex: "defaultCurrency",
    title: "Default Currency",
  },
  {
    // key: "categoryName",
    dataIndex: "pricingPerYear",
    title: "Pricing Per Year",
  },
  {
    // key: "categoryName",
    dataIndex: "pricingPerHalfYear",
    title: "Pricing Per HalfYear",
  },
  {
    // key: "categoryName",
    dataIndex: "pricingPerQuarter",
    title: "Pricing Per Quarter",
  },
  {
    // key: "categoryName",
    dataIndex: "pricingPerMonth",
    title: "Pricing Per Month",
  },
  {
    // key: "categoryName",
    dataIndex: "noOfUsers",
    title: "No of Users",
  },
  {
    dataIndex: "recommendedUsers",
    title: "Recommended Users",
  }
];

export const PRICING_COLUMN = [
  {
    dataIndex: "id",
    title: "Id",
  },
  {
    dataIndex: "name",
    title: "Name",
  },
];

export const SUB_MODULE_COLUMN = [
  {
    dataIndex: "id",
    title: "Id",
  },
  {
    dataIndex: "name",
    title: "Sub Module Name",
  },
  {
    dataIndex: "moduleName",
    title: "Module Name",
    render: (index: any, record: any) => {
      return (
        <p>
          {record?.module ? record?.module?.name : "-"}
        </p>
      )
    },
  },
];

export const FEATURE_COLUMN = [
  {
    dataIndex: "id",
    title: "Id",
  },
  {
    dataIndex: "name",
    title: "Feature Name",
  },
  {
    dataIndex: "moduleName",
    title: "Module Name",
    render: (index: any, record: any) => {
      return (
        <p>
          {record?.module ? record?.module?.name : "-"}
        </p>
      )
    },
  },
  {
    dataIndex: "subModuleName",
    title: "Sub Module Name",
    render: (index: any, record: any) => {
      return (
        <p>
          {record?.subModule ? record?.subModule?.name : "-"}
        </p>
      )
    },
  },
];

export const PACKAGE_FEATURE_MAPPING_COLUMN = [
  {
    dataIndex: "id",
    title: "Id",
  },
  {
    dataIndex: "featureName",
    title: "Feature Name",
    render: (index: any, record: any) => {
      return (
        <p>
          {record?.featuresObj ? record?.featuresObj?.name : "-"}
        </p>
      )
    },
  },
  {
    dataIndex: "packageName",
    title: "Package Name",
    render: (index: any, record: any) => {
      return (
        <p>
          {record?.packageMasterObj ? record?.packageMasterObj?.name : "-"}
        </p>
      )
    },
  },
  {
    dataIndex: "dataLimit",
    title: "Data Limit",
    render: (index: any, record: any) => {
      return (
        <p>
          {record?.dataLimit !== null ? record?.dataLimit : "-"}
        </p>
      )
    },
  },
  {
    dataIndex: "unlimited",
    title: "Is Unlimited",
    render: (index: any, record: any) => {
      return (
        <p>
          {record?.unlimited === true ? "Unlimited" : (record?.unlimited === false ? "Not unlimited" : "-")}
        </p>
      )
    },
  },
]

export const DATA_LINEAGE_COLUMN = [
  {
    dataIndex: "datalineageid",
    title: "Datalineage id",
  },
  {
    dataIndex: "EvidenceID",
    title: "Evidence ID",
  },
  {
    dataIndex: "evidencereferencelink",
    title: "Evidence Link",
  },
  {
    dataIndex: "evidenceremark",
    title: "Evidence Remark",
  },
  {
    dataIndex: "evidencestatus",
    title: "Evidence status",
  },
  {
    dataIndex: "evidenceupload",
    title: "Evidence upload filename",
  },
  {
    dataIndex: "controlname",
    title: "Control",
  },
  {
    dataIndex: "subcontrolname",
    title: "Sub control",
  },
  {
    dataIndex: "thrustarea",
    title: "Thrust",
  },
  {
    dataIndex: "Assessmentdate",
    title: "Assessment date",
  },
  {
    dataIndex: "Assessment Remark",
    title: "Assessment remark",
  },
  {
    dataIndex: "AuditDate",
    title: "Audit date",
  },
  {
    dataIndex: "AuditRemark",
    title: "Audit remark",
  },
  {
    dataIndex: "assessmentscore",
    title: "Assessment score",
  },
  {
    dataIndex: "auditscore",
    title: "Audit score",
  },
  {
    dataIndex: "framework",
    title: "Framework",
  },
]

export const FAQS_COLUMN = [
  {
    dataIndex: "id",
    title: "Id",
    width: 80,
  },
  {
    dataIndex: "module",
    title: "Module",
    width: 120,
    render: (index: any, record: any) => {
      return (
        <p>
          {record?.module ? record?.module?.name : "-"}
        </p>
      )
    },
  },
  {
    dataIndex: "subModule",
    title: "Sub Module",
    width: 120,
    render: (index: any, record: any) => {
      return (
        <p>
          {record?.subModule ? record?.subModule?.name : "-"}
        </p>
      )
    },
  },
  {
    dataIndex: "features",
    title: "Feature",
    width: 150,
    render: (index: any, record: any) => {
      return (
        <p>
          {record?.features ? record?.features?.name : "-"}
        </p>
      )
    },
  },
  {
    dataIndex: "description",
    title: "Description",
    width: 500,
    render: (index: any, record: any) => {
      return (
        <>
          <p>
            {record.description}
          </p>
          <a className="link-primary text-decoration-underline" onClick={() => {
            window.open(`${record.url}`);
          }}>
            {record.url}
          </a>
        </>
      );
    },
  },
]

export const DATA_RECORDS_REVISED_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Id",
  },
  {
    key: "dataContentLink",
    dataIndex: "dataContentLink",
    title: "Data Content Link",
  },
  {
    key: "dataContent",
    dataIndex: "dataContent",
    title: "Data Content",
  },
  {
    key: "valueChainCategoryName",
    dataIndex: "valueChainCategoryName",
    title: "Value Chain Category Name",
  },
  {
    key: "valueChainName",
    dataIndex: "valueChainName",
    title: "Value Chain Name",
  },
  {
    key: "termSetName",
    dataIndex: "termSetName",
    title: "Term Set Name",
  },
  {
    key: "country",
    dataIndex: "country",
    title: "Country",
  },
  {
    key: "state",
    dataIndex: "state",
    title: "State",
  },
  {
    key: "city",
    dataIndex: "city",
    title: "City",
  },
  {
    key: "createdOn",
    dataIndex: "createdOn",
    title: "Data Extracted",
  },
  {
    key: "dataQualityStatus",
    dataIndex: "dataQualityStatus",
    title: "Data Quality Status",
  },
  {
    key: "sourcecategoryid",
    dataIndex: "sourcecategoryid",
    title: "Source Category Id",
  },
];

export const INDUSTRY_COLUMN = [
  {
    dataIndex: "id",
    title: "Industry Id",
  },
  {
    dataIndex: "name",
    title: "Industry Name",
  },
];

export const SUB_INDUSTRY_COLUMN = [
  {
    dataIndex: "id",
    title: "Sub Industry Id",
  },
  {
    dataIndex: "name",
    title: "Sub Industry Name",
  },
  {
    dataIndex: "industryName",
    title: "Industry Name",
  },
];

export const SECTION_MASTER_COLUMN = [
  {
    dataIndex: "id",
    title: "Section Master Id",
  },
  {
    dataIndex: "sectionMasterName",
    title: "Section Master Name",
  },
  {
    dataIndex: "sectionMasterCode",
    title: "Section Master Code",
  },
  {
    dataIndex: "organizationName",
    title: "Organization",
  },
  {
    dataIndex: "parameterCategoryName",
    title: "Parameter Category",
  },
  {
    dataIndex: "parameterName",
    title: "Parameter",
  },
];

export const DOCUMENT_CATEGORY_COLUMN = [
  {
    dataIndex: "id",
    title: "Id",
  },
  {
    dataIndex: "name",
    title: "Document Category Name",
  },
  {
    dataIndex: "code",
    title: "Document Category Code",
  },
];

export const DOCUMENT_MASTER_COLUMN = [
  {
    dataIndex: "id",
    title: "Id",
  },
  {
    dataIndex: "name",
    title: "Document Master Name",
  },
  {
    dataIndex: "code",
    title: "Document Master Code",
  },
  {
    dataIndex: "documentCategoryName",
    title: "Document Category Name",
  },
];

export const DOCUMENT_RECORD_COLUMN = [
  {
    dataIndex: "id",
    title: "Id",
  },
  {
    dataIndex: "documentName",
    title: "Document Name",
  },
  {
    dataIndex: "documentType",
    title: "Document Format",
  }
];


export const SINGAPORE_ACTS_COLUMN = [
  {
    title: 'Data Protection Act',
    dataIndex: 'dataPrivacyAct',
    key: 'dataPrivacyAct',
  },
  {
    title: 'Clause Name',
    dataIndex: 'clauseName',
    key: 'clauseName',
  },
  {
    title: 'Link',
    dataIndex: 'link',
    key: 'link',
    render: (text: any, record: any) => (
      <a className="link-primary text-decoration-underline" onClick={() => {
        window.open(`${record.link}`);
      }}>
        {record.link}
      </a>
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Expected Evidence',
    dataIndex: 'expectedEvidence',
    key: 'expectedEvidence',
  },
  {
    title: 'Implications',
    dataIndex: 'implications',
    key: 'implications',
  },
  {
    title: 'Recommendations',
    dataIndex: 'recommendations',
    key: 'recommendations',
  },
];

export const ACTS_COLUMN = [
  {
    title: 'Data Privacy Act',
    dataIndex: 'dataPrivacyAct',
    key: 'dataPrivacyAct',
  },
  {
    title: 'Clause Name',
    dataIndex: 'clauseName',
    key: 'clauseName',
  },
  {
    title: 'Link',
    dataIndex: 'link',
    key: 'link',
    render: (text: any, record: any) => (
      <a className="link-primary text-decoration-underline" onClick={() => {
        window.open(`${record.link}`);
      }}>
        {record.link}
      </a>
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Expected Evidence',
    dataIndex: 'expectedEvidence',
    key: 'expectedEvidence',
  },
  {
    title: 'Implications',
    dataIndex: 'implications',
    key: 'implications',
  },
  {
    title: 'Recommendations',
    dataIndex: 'recommendations',
    key: 'recommendations',
  },
];

export const USA_ACTS_COLUMN = [
  {
    title: 'Data Privacy Act',
    dataIndex: 'dataPrivacyAct',
    key: 'dataPrivacyAct',
  },
  {
    title: 'Clause Name',
    dataIndex: 'clauseName',
    key: 'clauseName',
  },
  {
    title: 'Link',
    dataIndex: 'link',
    key: 'link',
    render: (text: any, record: any) => (
      <a className="link-primary text-decoration-underline" onClick={() => {
        window.open(`${record.link}`);
      }}>
        {record.link}
      </a>
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Expected Evidence',
    dataIndex: 'expectedEvidence',
    key: 'expectedEvidence',
  },
  {
    title: 'Implications',
    dataIndex: 'implications',
    key: 'implications',
  },
  {
    title: 'Recommendations',
    dataIndex: 'recommendations',
    key: 'recommendations',
  },
  {
    title: 'States',
    dataIndex: 'states',
    key: 'states',
  },
];

export const GDPR_COLUMN = [
  {
    title: 'GDPR Clause/Term',
    dataIndex: 'clauseTerm',
    key: 'clauseTerm',
  },
  {
    title: 'Description/Context',
    dataIndex: 'descriptionContext',
    key: 'descriptionContext',
  },
  {
    title: 'Implications',
    dataIndex: 'implications',
    key: 'implications',
  },
  {
    title: 'Recommendations',
    dataIndex: 'recommendations',
    key: 'recommendations',
  },
];

export const EU_AI_ACT_COLUMN = [
  {
    title: 'EU AI Act Clauses',
    dataIndex: 'euAIActClauses',
    key: 'euAIActClauses',
  },
  {
    title: 'Terms',
    dataIndex: 'terms',
    key: 'terms',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Expected Evidence',
    dataIndex: 'expectedEvidence',
    key: 'expectedEvidence',
  },
  {
    title: 'Implications',
    dataIndex: 'implications',
    key: 'implications',
  },
]

export const SINGAPORE_AI_REG_ACT_COLUMN = [
  // {
  //   title: 'MODEL AI GOVERNANCE FRAMEWORK SECOND EDITION',
  //   dataIndex: 'modelAIGovFrameworkSecEdi',
  //   key: 'modelAIGovFrameworkSecEdi',
  // },
  {
    title: 'Clause Section',
    dataIndex: 'clauseSection',
    key: 'clauseSection',
    width: 150,
  },
  {
    title: 'Section Description',
    dataIndex: 'sectionDescription',
    key: 'sectionDescription',
  },
  {
    title: 'Expected Evidences',
    dataIndex: 'expectedEvidences',
    key: 'expectedEvidences',
  },
]

export const TRUSTED_AI_COLUMN = [
  {
    title: 'Control Group Name (Function)',
    dataIndex: 'thrustArea',
    key: 'thrustArea',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Control Group ID (Category ID)',
    dataIndex: 'controlID',
    key: 'controlID',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Control Area (Category Objective)',
    dataIndex: 'controlName',
    key: 'controlName',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Control ID (Subcategory ID)',
    dataIndex: 'subControlID',
    key: 'subControlID',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Control Specification (Subcategory)',
    dataIndex: 'subControlName',
    key: 'subControlName',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Control Question',
    dataIndex: 'evidence',
    key: 'evidence',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Recommendation',
    dataIndex: 'evidence1',
    key: 'evidence1',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Top 20 Control',
    dataIndex: 'column8',
    key: 'column8',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column8) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column8}
          </p>
        );
      }
    },
  },
  {
    title: 'PEOPLE',
    dataIndex: 'column9',
    key: 'column9',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column9) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column9}
          </p>
        );
      }
    },
  },
  {
    title: 'PROCESS',
    dataIndex: 'column10',
    key: 'column10',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column10) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column10}
          </p>
        );
      }
    },
  },
  {
    title: 'DATA',
    dataIndex: 'column11',
    key: 'column11',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column11) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column11}
          </p>
        );
      }
    },
  },
  {
    title: 'TECHNOLOGY',
    dataIndex: 'column12',
    key: 'column12',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column12) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column12}
          </p>
        );
      }
    },
  },
  {
    title: 'ISO/IEC TR 24028:2020 Information technology - Artificial intelligence - Overview of trustworthiness in artificial intelligence',
    dataIndex: 'column13',
    key: 'column13',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column13) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column13}
          </p>
        );
      }
    },
  },
  {
    title: 'BS ISO/IEC 23053:2022 Framework for Artificial Intelligence (AI) Systems Using Machine Learning (ML) (British Standard)',
    dataIndex: 'column14',
    key: 'column14',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column14) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column14}
          </p>
        );
      }
    },
  },
  {
    title: 'ISO/IEC 23894:2023 Information technology - Artificial intelligence - Guidance on risk management',
    dataIndex: 'column15',
    key: 'column15',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column15) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column15}
          </p>
        );
      }
    },
  },
  {
    title: 'ISO/IEC DIS 5338:2022 Information technology - Artificial intelligence - AI system life cycle processes',
    dataIndex: 'column16',
    key: 'column16',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column16) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column16}
          </p>
        );
      }
    },
  },
  {
    title: 'ISO/IEC 38507:2022 Information technology - Governance of IT - Governance implications of the use of artificial intelligence by organizations',
    dataIndex: 'column17',
    key: 'column17',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column17) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column17}
          </p>
        );
      }
    },
  },
  {
    title: 'ISO/IEC 22989:2022 Information technology - Artificial intelligence - Artificial intelligence concepts and terminology',
    dataIndex: 'column18',
    key: 'column18',
    ellipsis: true,
    width: 200,
    render: (index: any, record: any) => {
      if (!record.column18) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.column18}
          </p>
        );
      }
    },
  },
]

export const NIST_AI_RISK_COLUMN = [
  {
    title: 'Activity Group',
    dataIndex: 'activityGroup',
    key: 'activityGroup',
  },
  {
    title: 'Activity',
    dataIndex: 'activity',
    key: 'activity',
  },
  {
    title: 'Expected Evidence',
    dataIndex: 'expectedEvidence',
    key: 'expectedEvidence',
  },
]

export const ORGANIZATION_SEARCH_COLUMN = [
  {
    key: "name",
    dataIndex: "key_group",
    title: "Key Group",
    width: 250,
  },
  {
    key: "key_word",
    dataIndex: "key_word",
    title: "Key Word",
    width: 250,
  },
  {
    key: "company_name",
    dataIndex: "company_name",
    title: "Company Name",
    width: 250,
  },
  {
    key: "web_link",
    dataIndex: "web_link",
    title: "Web Link",
    width: 300,
  },
  {
    key: "extracted_text",
    dataIndex: "extracted_text",
    title: "Extracted Text",
    ellipsis: true,
    width: 500,
  },
  {
    key: "industry",
    dataIndex: "industry",
    title: "Industry",
    width: 350,
  },
  {
    key: "subIndustry",
    dataIndex: "subIndustry",
    title: "Sub Industry",
    width: 350,
  },
  {
    key: "country",
    dataIndex: "country",
    title: "Country",
    width: 150,
  },
  {
    key: "state",
    dataIndex: "state",
    title: "State",
    width: 150,
  },
  {
    key: "city",
    dataIndex: "city",
    title: "City",
    width: 150,
  },
  {
    key: "extraction_date_time",
    dataIndex: "extraction_date_time",
    title: "Date Extracted",
    width: 230,
  },
  {
    title: 'Key Info',
    children: [
      {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date',
        width: 150,
      },
      {
        title: 'Location',
        dataIndex: 'Location',
        key: 'Location',
        width: 150,
      },
      {
        title: 'Organization',
        dataIndex: 'Organization',
        key: 'Organization',
        width: 300,
      },
      {
        title: 'Person',
        dataIndex: 'Person',
        key: 'Person',
        width: 150,
      },
    ],
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status",
    width: 150,
    fixed: "right",
  },
];

export const ORGANIZATION_SEARCH_COLUMN1: TableColumnsType<any> = [
  Table.EXPAND_COLUMN,
  {
    key: "name",
    dataIndex: "key_group",
    title: "Key Group",
    width: 250,
    render: (index: any, record: any) => {
      return (
        <p className="d-flex align-items-start">{record.key_group}</p>
      );
    },
    sorter: (a: any, b: any) => ((a.key_group).toLowerCase()).localeCompare((b.key_group).toLowerCase()),
  },
  {
    key: "key_word",
    dataIndex: "key_word",
    title: "Key Word",
    width: 250,
    render: (index: any, record: any) => {
      return (
        <p className="d-flex align-items-start">{record.key_word}</p>
      );
    },
    sorter: (a: any, b: any) => ((a.key_word).toLowerCase()).localeCompare((b.key_word).toLowerCase()),
  },
  {
    key: "company_name",
    dataIndex: "company_name",
    title: "Company Name",
    width: 250,
    render: (index: any, record: any) => {
      return (
        <p className="d-flex align-items-start" key={index.toString()}>{record.company_name}</p>
      );
    },
    sorter: (a: any, b: any) => ((a.company_name).toLowerCase()).localeCompare((b.company_name).toLowerCase()),
  },
  {
    key: "web_link",
    dataIndex: "web_link",
    title: "Web Link",
    width: 300,
    // ellipsis: true,
    render: (index: any, record: any) => {
      return (
        <Tooltip title={record.web_link}>
          <a className="link-primary text-decoration-underline" style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }} onClick={() => {
            window.open(record.web_link, '_blank')
          }}>
            {record.web_link}
          </a>
        </Tooltip>
      );
    },
    sorter: (a: any, b: any) => ((a.web_link).toLowerCase()).localeCompare((b.web_link).toLowerCase()),
  },
  {
    key: "extracted_text",
    dataIndex: "extracted_text",
    title: "Extracted Text",
    ellipsis: true,
    width: 500,
  },
  {
    key: "industry",
    dataIndex: "industry",
    title: "Industry",
    width: 350,
    render: (index: any, record: any) => {
      return (
        <p className="d-flex align-items-start">{record.industry}</p>
      );
    },
  },
  {
    key: "subIndustry",
    dataIndex: "subIndustry",
    title: "Sub Industry",
    width: 350,
    render: (index: any, record: any) => {
      return (
        <p className="d-flex align-items-start">{record.subIndustry}</p>
      );
    },
  },
  {
    key: "country",
    dataIndex: "country",
    title: "Country",
    width: 150,
    render: (index: any, record: any) => {
      return (
        <p className="d-flex align-items-start">{record.country !== "undefined" ? record.country : "-"}</p>
      );
    },
  },
  {
    key: "state",
    dataIndex: "state",
    title: "State",
    width: 150,
    render: (index: any, record: any) => {
      return (
        <p className="d-flex align-items-start">{record.state !== "undefined" ? record.state : "-"}</p>
      );
    },
  },
  {
    key: "city",
    dataIndex: "city",
    title: "City",
    width: 150,
    render: (index: any, record: any) => {
      return (
        <p className="d-flex align-items-start">{record.city}</p>
      );
    },
  },
  {
    key: "extraction_date_time",
    dataIndex: "extraction_date_time",
    title: "Date Extracted",
    width: 230,
    render: (index: any, record: any) => {
      let formattedDate;
      if (record.extraction_date_time !== undefined) {
        const inputDate = new Date(record?.extraction_date_time);
        formattedDate = format(inputDate, 'MM/dd/yyyy hh:mm a');
      } else {
        formattedDate = "-"
      }

      return (
        <p className="d-flex align-items-start">
          {formattedDate}
        </p>
      );
    },
  },
  {
    title: 'Key Info',
    children: [
      {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date',
        width: 150,
        render: (index: any, record: any) => {
          return (
            <p className="d-flex align-items-start">{record.key_information !== null ? record.key_information.Date : "-"}</p>
          );
        },
      },
      {
        title: 'Location',
        dataIndex: 'Location',
        key: 'Location',
        width: 150,
        render: (index: any, record: any) => {
          return (
            <p className="d-flex align-items-start">{record.key_information !== null ? record.key_information.Location : "-"}</p>
          );
        },
      },
      {
        title: 'Organization',
        dataIndex: 'Organization',
        key: 'Organization',
        width: 300,
        // ellipsis: true,
        render: (text: any, record: any) => {
          return (
            // <p className="d-flex align-items-start">{record.key_information.Organization}</p>
            <Tooltip title={record.key_information && record.key_information.Organization}>
              <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', margin: 0 }}>
                {record.key_information !== null ? record.key_information.Organization : "-"}
              </p>
            </Tooltip>
          );
        },
      },
      {
        title: 'Person',
        dataIndex: 'Person',
        key: 'Person',
        width: 150,
        render: (index: any, record: any) => {
          return (
            <p className="d-flex align-items-start">{record.key_information !== null ? record.key_information.Person : "-"}</p>
          );
        },
      },
    ],
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status",
    width: 150,
    fixed: "right",
  },
];

export const DETAIL_ORGANIZATION_SEARCH_COLUMN1 = [
  {
    key: "id",
    dataIndex: "id",
    title: "Id",
    width: 80,
  },
  {
    key: "web_link",
    dataIndex: "web_link",
    title: "Web Link",
    width: 300,
  },
  {
    key: "extracted_text",
    dataIndex: "extracted_text",
    title: "Extracted Text",
    ellipsis: true,
    width: 500,
  },
  {
    key: "industry",
    dataIndex: "industry",
    title: "Industry",
    width: 350,
  },
  {
    key: "subIndustry",
    dataIndex: "subIndustry",
    title: "Sub Industry",
    width: 350,
  },
  {
    key: "country",
    dataIndex: "country",
    title: "Country",
    width: 150,
  },
  {
    key: "state",
    dataIndex: "state",
    title: "State",
    width: 150,
  },
  {
    key: "city",
    dataIndex: "city",
    title: "City",
    width: 150,
  },
  {
    key: "extraction_date_time",
    dataIndex: "extraction_date_time",
    title: "Date Extracted",
    width: 230,
  },
]

export const DETAIL_ORGANIZATION_SEARCH_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Id",
    width: 80,
    sorter: (a: any, b: any) => a.id - b.id,
    defaultSortOrder: 'ascend',
  },
  {
    key: "web_link",
    dataIndex: "web_link",
    title: "Web Link",
    width: 300,
    // ellipsis: true,
    render: (index: any, record: any) => {
      return (
        <Tooltip title={record.web_link}>
          <a className="link-primary text-decoration-underline" style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }} onClick={() => {
            window.open(record.web_link, '_blank')
          }}>
            {record.web_link}
          </a>
        </Tooltip>
      );
    },
    sorter: (a: any, b: any) => ((a.web_link).toLowerCase()).localeCompare((b.web_link).toLowerCase()),
  },
  {
    key: "extracted_text",
    dataIndex: "extracted_text",
    title: "Extracted Text",
    ellipsis: true,
    width: 500,
  },
  {
    key: "industry",
    dataIndex: "industry",
    title: "Industry",
    width: 350,
  },
  {
    key: "subIndustry",
    dataIndex: "subIndustry",
    title: "Sub Industry",
    width: 350,
  },
  {
    key: "country",
    dataIndex: "country",
    title: "Country",
    width: 150,
  },
  {
    key: "state",
    dataIndex: "state",
    title: "State",
    width: 150,
  },
  {
    key: "city",
    dataIndex: "city",
    title: "City",
    width: 150,
  },
  {
    key: "extraction_date_time",
    dataIndex: "extraction_date_time",
    title: "Date Extracted",
    width: 230,
    render: (index: any, record: any) => {
      let formattedDate;
      if (record.extraction_date_time !== undefined && record.extraction_date_time !== null) {
        const inputDate = new Date(record?.extraction_date_time);
        formattedDate = format(inputDate, 'MM/dd/yyyy hh:mm a');
      } else {
        formattedDate = "-"
      }

      return (
        <p className="d-flex align-items-start">
          {formattedDate}
        </p>
      );
    },
  },
]

export const TRUSTED_AI_DIMENSION_ANALYSIS_COLUMN = [
  {
    key: "dimension",
    dataIndex: "dimension",
    title: "Dimension",
  },
  {
    key: "dimensionExplanation",
    dataIndex: "dimensionExplanation",
    title: "Dimension Explanation",
  },
  {
    key: "thresholdLimit",
    dataIndex: "thresholdLimit",
    title: "Threshold Limit",
  },
  {
    key: "actualValue",
    dataIndex: "actualValue",
    title: "Actual Value",
  },
  {
    key: "passFail",
    dataIndex: "passFail",
    title: "Pass/Fail",
  },
  {
    key: "inferences",
    dataIndex: "inferences",
    title: "Inferences",
  },
  {
    key: "mitigationRecommendation",
    dataIndex: "mitigationRecommendation",
    title: "Mitigation Recommendation",
  },
]

export const DOCUMENT_CREATION_COLUMN1 = [
  {
    title: 'Section Master Name',
    dataIndex: 'sectionMasterName',
    key: 'sectionMasterName',
  },
  {
    title: 'Parameter Category',
    dataIndex: 'parameterCategoryName',
    key: 'parameterCategoryName',
  },
  {
    title: 'Parameter',
    dataIndex: 'parameterName',
    key: 'parameterName',
  },
  {
    title: 'Description',
    key: 'description',
    render: (_: any, record: any) => (
      <Field name={record.descriptionKey}>
        {({ field }: any) => <TextArea {...field} />}
      </Field>
    ),
  },
]

export const LEGISLATIVE_AI_RISK_COLUMN = [
  {
    key: "FileName",
    dataIndex: "FileName",
    title: "File name",
    render: (index: any, record: any) => {
      const lastSlashIndex = (record.FileName).lastIndexOf('/');
      const filename = (record.FileName).substring(lastSlashIndex + 1);
     
      return (
        <p>
          {filename}
        </p>
      );
    },
  },
  {
    key: "Implications",
    dataIndex: "Implications",
    title: "Implications",
  },
  {
    key: "Legislation",
    dataIndex: "Legislation",
    title: "Legislation",
  },
  {
    key: "Line No",
    dataIndex: "Line No",
    title: "Line text",
  },
  {
    key: "Non Complaiance Implications",
    dataIndex: "Non Complaiance Implications",
    title: "Non Complaiance Implications",
  },
  {
    key: "Pattern Type",
    dataIndex: "Pattern Type",
    title: "Pattern Type",
  },
  {
    key: "Remedial Actions",
    dataIndex: "Remedial Actions",
    title: "Remedial Actions",
  },
  {
    key: "RiskSeverity",
    dataIndex: "RiskSeverity",
    title: "RiskSeverity",
  },
  {
    key: "Vulnerability",
    dataIndex: "Vulnerability",
    title: "Vulnerability",
  },
]