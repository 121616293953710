import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManagement, userManagementBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
export const documentCreationService = createApi({
    reducerPath: 'documentCreationService',
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ['DocumentCreation'],
    endpoints: (builder) => ({
        createDocument: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.createDoucmentController.createDocument}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}` },
                    body: body.payload,
                    responseHandler: (response) => response.blob(),
                    // responseHandler: ((response: any) => response.blob()
                    //     .then((blob: any) => {
                    //         const fileName = body.fileName ? body.fileName : "File";
                    //         const fileFormat = body.fileFormat ? body.fileFormat : "pdf";
                    //         // 2. Create blob link to download
                    //         const url = window.URL.createObjectURL(new Blob([blob]));
                    //         const link: any = document.createElement('a');
                    //         link.href = url;
                    //         link.setAttribute('download', `${fileName}.${body.fileFormat}`);
                    //         // 3. Append to html page
                    //         document.body.appendChild(link);
                    //         // 4. Force download
                    //         link.click();
                    //         // 5. Clean up and remove the link
                    //         link.parentNode.removeChild(link);
                    //     })),
                }
            }
        }),
        getAllDocumentRecords: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.createDoucmentController.getAllDocumentRecords}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}` },
                }
            }
        }),
        getDocumentRecordById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.createDoucmentController.getDocumentRecordById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}` },
                }
            }
        }),
        updateDocumentRecord: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.createDoucmentController.updateDocumentRecord}/${body.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}` },
                    body: body.payload,
                    responseHandler: (response) => response.blob() 
                }
            }
        }),
        deleteDocumentRecord: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.createDoucmentController.deleteDocumentRecord}/${body.id}?isPermanent=${body.isPermanent}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}` },
                }
            }
        }),
        getDocumentRecordWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.createDoucmentController.getDocumentRecordWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}` },
                    body: body.payload,
                };
            },
        }),
    })
});
export const {
    useCreateDocumentMutation,
    useGetAllDocumentRecordsMutation,
    useGetDocumentRecordByIdMutation,
    useUpdateDocumentRecordMutation,
    useDeleteDocumentRecordMutation,
    useGetDocumentRecordWithSearchMutation
} = documentCreationService;