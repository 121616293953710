import { useLocation } from "react-router-dom";
import { REPORT_TYPE } from "../../constant/reportType";
import PrivacyGuardPro from "../../component/PrivacyGuardPro";
import CyberSecAIShield from "../../component/CyberSecAIShield";
import BiasMitigatePro from "../../component/BiasMitigatePro";
import PredictionModels from "../../component/PredictionModels";

type Props = {};

const AllComplianceTestReports = (props: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const report = searchParams.get("report") !== null ? searchParams.get("report") : localStorage.getItem("selectedReport")

  if (report == REPORT_TYPE.PRIVACYGUARD_PRO) {
    return (
      <PrivacyGuardPro />
    )
  } else if (report == REPORT_TYPE.CYBERSECAI_SHIELD) {
    return (
      <CyberSecAIShield />
    )
  } else if (report == REPORT_TYPE.BIASMITIGATE_PRO) {
    return (
      <BiasMitigatePro />
    )
  }  else if (report == REPORT_TYPE.PREDICTION_MODELS) {
    return (
      <PredictionModels />
    )
  }
  else {
    return (
      <div>Something went wrong</div>
    )
  }

};
export default AllComplianceTestReports;
