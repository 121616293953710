import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { REPORT_NAME, REPORT_TYPE } from "../../constant/reportType";
import "./reports.scss";
import { useSaveAuditLogMutation } from "../../redux-toolkit/services/auditLogService";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PERMISSION_CODE } from "../../constant/userPermission";
import { checkPermissions } from "../../component/utils";
import { USER_ROLE } from "../../constant/AppConstant";

type Props = {};

const Reports = (props: Props) => {
  const navigate = useNavigate();
  const startTime = new Date();
  const [saveAuditLog, saveAuditLogResponse] = useSaveAuditLogMutation();
  const localUserObj = getLocalStorageData("localUser");
  const paymentStatus = getLocalStorageData("paymentStatus");

  const [reportTypes, setReportTypes] = useState([
    {
      id: 1,
      name: "Data Source Management",
      isActive:
        localStorage.getItem("selectedReport") ===
          REPORT_TYPE.DATA_SOURCE_MANAGEMENT
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "Data Source Management",
          reports: [
            {
              id: "1",
              name: "Value Chain Category",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.VALUE_CHAIN_CATEGORY, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.VALUE_CHAIN_CATEGORY))
              ),
              route: "/reports/Value Chain Category",
            },
            {
              id: "2",
              name: "Value Chain",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.VALUE_CHAIN, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.VALUE_CHAIN))
            ),
              route: "/reports/Value Chain",
            },
            {
              id: "3",
              name: "Data Pipeline Category",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.DATA_PIPELINE_CATEGORY, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.DATA_PIPELINE_CATEGORY))
            ),
              route: "/reports/Data Pipeline Category",
            },
            {
              id: "4",
              name: "Data Pipeline",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.DATA_PIPELINE, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.DATA_PIPELINE))
            ),
              route: "/reports/Data Pipeline",
            },
            {
              id: "6",
              name: "Term Set",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.TERM_SET, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.TERM_SET))
            ),
              route: "/reports/Term Set",
            },
            {
              id: "7",
              name: "Term",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.TERM, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.TERM))
            ),
              route: "/reports/Term",
            },
            {
              id: "8",
              name: "Data Record",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.DATA_RECORDS, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.DATA_RECORD))
            ),
              route: "/reports/Data Record",
            },
            {
              id: "9",
              name: "Value Chain Paragraph Extract",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.VALUE_CHAIN_PARAGRAPH_EXTRACT, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.VALUE_CHAIN_PARAGRAPH_EXTRACT))
            ),
              route: "/reports/Value Chain Paragraph Extract",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "User Master",
      isActive:
        localStorage.getItem("selectedReport") === REPORT_TYPE.USER_MASTER
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "User Master",
          reports: [
            {
              id: "0",
              name: "User Group",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.USER_GROUP, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.USER_GROUP))
            ),
              route: "/reports/User Group",
            },
            {
              id: "1",
              name: "Subscriber",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.SUBSCRIBER, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.SUBSCRIBER))
            ),
              route: "/reports/Subscriber",
            },
            {
              id: "2",
              name: "Users",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.USER, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.USERS))
            ),
              route: "/reports/Users",
            },
            {
              id: "3",
              name: "User Activity",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.USER_ACTIVITY, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.USER_ACTIVITY))
            ),
              route: "/reports/User Activity",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "Common",
      isActive:
        localStorage.getItem("selectedReport") === REPORT_TYPE.COMMON
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "Common",
          reports: [
            {
              id: "0",
              name: "Country",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.COUNTRY, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.COUNTRY))
            ),
              route: "/reports/Country",
            },
            {
              id: "1",
              name: "State",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.STATE, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.STATE))
            ),
              route: "/reports/State",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      name: "Pricing Module",
      isActive:
        localStorage.getItem("selectedReport") === REPORT_TYPE.PRICING_MODULE
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "Pricing Module",
          reports: [
            {
              id: "1",
              name: "Package",
              route: "/reports/Package",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.PACKAGE, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.PACKAGE))
            ),
            },
            {
              id: "2",
              name: "Module",
              route: "/reports/Module",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.MODULE, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.MODULE))
            ),
            },
            {
              id: "3",
              name: "Sub Module",
              route: "/reports/Sub Module",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.SUB_MODULE, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.SUB_MODULE))
            ),
            },
            {
              id: "4",
              name: "Feature",
              route: "/reports/Feature",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.FEATURE, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.FEATURE))
            ),
            },
            {
              id: "5",
              name: "Package Feature Mapping",
              route: "/reports/Package Feature Mapping",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.PACKAGE_FEATURE_MAPPING, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.PACKAGE_FEATURE_MAPPING))
            ),
            },
          ],
        },
      ],
    },
  ]);

  // Methods
  const handleReportTypeClick = (reportType: any) => {
    localStorage.setItem("selectedReport", reportType.name);

    let reportTypesCpy = [...reportTypes];

    reportTypesCpy.forEach((report, i) => {
      if (report.id === reportType.id) {
        report.isActive = true;
      } else {
        report.isActive = false;
      }
    });

    setReportTypes(reportTypesCpy);
  };

  return (
    <div className="cm-reports pb-4">
      {/* Inlet */}
      {/* <h4 className="title-d">Master</h4> */}
      {/* Content */}
      <div className="d-flex mt-4 bg-white rounded-3 overflow-x-auto shadow-sm">
        <div className="report-list bg-cm-primary d-flex flex-column my-5">
          {reportTypes.map((reportType, i) => (
            <button
              className="btn text-start ps-4 cm-btn border-0 p-3 text-white border-top rounded-0 border-white position-relative"
              onClick={() => {
                handleReportTypeClick(reportType);
              }}
              key={i}
            >
              <span>{reportType.name}</span>
              {reportType.isActive && (
                <div className="cm-active-polygon position-absolute"></div>
              )}
            </button>
          ))}
        </div>
        <div className="report-content  ms-4 container-fluid">
          {reportTypes.map(
            (reportItem, i) =>
              reportItem.isActive &&
              reportItem?.data?.map((report, ri) => {
                return (
                  <div className="my-4" key={Math.random()}>
                    <h5 className="fw-bold fs-4 text-cm-active-primary1 mb-3  ">
                      {report.name}
                    </h5>
                    {/* <div className="border-top  border-primary"></div> */}
                    <div className="row">
                      {report?.reports?.map((rep: any, ri: any) => (
                        <div
                          className="col-md-4 cursor-pointer"
                          key={ri}
                          onClick={() => {
                            if (rep.disabled && (paymentStatus || localUserObj.userType === USER_ROLE.ADMIN)) {
                              navigate(rep.route + `?report=${reportItem.name}`);
                              saveAuditLog({
                                payload: {
                                  startTime: startTime.toISOString(),
                                  // endTime: startTime.toISOString(),
                                  // request: {},
                                  // response: {},
                                  // serviceName: "login",
                                  eventName: rep.route,
                                  url: rep.route,
                                  userId: localUserObj?.id
                                }
                              })
                            }
                          }}
                        >
                          <ul className="fs-6">
                            {/* {!rep.disabled ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="overlay-example" {...props}>
                                    If you want to access this please contact info@capitoltunnels.ai
                                  </Tooltip>
                                }
                              >
                                <li className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                  {" "}
                                  {rep.name}
                                </li>
                              </OverlayTrigger>
                            ) : (
                              !rep.payment ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="overlay-example" {...props}>
                                      If you want to access this please contact info@capitoltunnels.ai
                                    </Tooltip>
                                  }
                                >
                                  <li className="text-cm-active-primary1 list-disc  text-decoration-underline disabled-link">
                                    {" "}
                                    {rep.name}
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <li className={`text-cm-light-text  text-decoration-underline ${!rep.disabled ? 'disabled' : null}`}>
                                  {" "}
                                  {rep.name}
                                </li>

                              ))} */}
                            {!rep.disabled ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="overlay-example" {...props}>
                                    If you want to access this please contact info@capitoltunnels.ai
                                  </Tooltip>
                                }
                              >
                                <li className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                  {" "}
                                  {rep.name}
                                </li>
                              </OverlayTrigger>
                            ) : (
                              (paymentStatus === true || localUserObj.userType === USER_ROLE.ADMIN ? (
                                <div className="d-flex">
                                  <li className={`text-cm-light-text  text-decoration-underline ${!rep.disabled ? 'disabled' : null}`}>
                                    {" "}
                                    {rep.name}
                                  </li>
                                </div>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="overlay-example" {...props}>
                                      Please proceed to payment to avail this feature or contact info@capitoltunnels.ai
                                    </Tooltip>
                                  }
                                >
                                  <li className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                    {" "}
                                    {rep.name}
                                  </li>
                                </OverlayTrigger>
                              ))
                            )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
