import React, { useState, useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import Layout from "./layout/Layout";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import Dashboard from "./pages/dashboard/Dashboard";
import AllReports from "./pages/reports/AllReports";
import Reports from "./pages/reports/Reports";
import Settings from "./pages/settings/Settings";
import { useAppDispatch, useAppSelector } from "./redux-toolkit/hooks";
import { getAuthData } from "./redux-toolkit/slices/authSlice";
import { setFindUserByIdUser } from "./redux-toolkit/slices/allUserSlice";
import { getLocalStorageData } from "./utils/LocalStorageService";
import { REPORT_TYPE } from "./constant/reportType";
import SignUp from "./pages/auth/SignUp";
import { USER_ROLE } from "./constant/AppConstant";
import DashboardCorporateUser from "./pages/dashboard/CorporateUserDashboard/DashboardCorporateUser";
import UnAuthorizedLayout from "./layout/UnAuthorizedLayout";
import { useGetUserByIdMutation } from "./redux-toolkit/services/allUserService";
import CompliantReports from "./pages/reports/CompliantReports";
import AllComplianceTestReports from "./pages/reports/AllComplianceTestReports ";
import Home from "./pages/auth/Home";
import AIGovernanceExpectations from "./component/AIGovernanceExpectations";
import AIGovernanceReports from "./pages/reports/AIGovernanceReports";
import AllAIGovernanceReports from "./pages/reports/AllAIGovernanceReports";
import AboutUs from "./pages/auth/AboutUs";
import TermsAndConditions from "./pages/auth/TermsAndConditions";
import PrivacyPolicy from "./pages/auth/PrivacyPolicy";
import Verify from "./pages/auth/Verify";
import LandingPage from "./pages/auth/LandingPage";
import PricingPlans from "./pages/pricing/PricingPlans";
import FAQMaster from "./component/FAQMaster";
import AllMatchMakerReports from "./pages/reports/AllMatchMakerReports";
import MatchMakerReports from "./pages/reports/MatchMakerReports";
import AllActDataPrivacyRegulations from "./pages/actsPages/AllActDataPrivacyRegulations";
import { AIGovernanceFrameworkData, AI_REGULATIONS, aiRegulationActsData, aiRegulationsData, dataPrivacyActsData } from "./constant/mockData";
import CascadingCallComponent from "./pages/pages/CascadingCallComponent";
import Nmap from "./pages/pages/Nmap";
import DocumentReports from "./pages/reports/DocumentReports";
import AllDocumentReports from "./pages/reports/AllDocumentReports";

function App() {
  const localUserObj = getLocalStorageData("localUser");
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [findUserById, findUserByIdResponse] = useGetUserByIdMutation();

  const authUserData = useAppSelector(getAuthData);

  const userStatus: any = localUserObj && localUserObj.userRoleStatus

  const userBasedRoute: any = localStorage.getItem("userBasedRoute");
  const routeTo: any = localStorage.getItem("routeTo");
  // this is for first time start getting the url for checking the route
  const currentUrl = window.location.href;
  const lastSlashIndex = currentUrl.lastIndexOf("/");
  const valueAfterLastSlash = currentUrl.substring(lastSlashIndex + 1);
  const selectedReport = localStorage.getItem("selectedReport")

  useEffect(() => {
    if (localUserObj) {
      if (localUserObj.userType === "UTSA") {
        localStorage.setItem("userBasedRoute", "admins")
      } else if (localUserObj.userType === USER_ROLE.ADMIN) {
        localStorage.setItem("userBasedRoute", USER_ROLE.ADMIN)
        localStorage.setItem("selectedReport", selectedReport !== null ? selectedReport : REPORT_TYPE.DATA_SOURCE_MANAGEMENT)
      } else if (localUserObj.userType === USER_ROLE.CORPORATE) {
        localStorage.setItem("userBasedRoute", USER_ROLE.CORPORATE)
      } else if (localUserObj.userType === USER_ROLE.NGO) {
        localStorage.setItem("userBasedRoute", USER_ROLE.NGO)
      } else if (localUserObj.userType === USER_ROLE.INNOVATOR) {
        localStorage.setItem("userBasedRoute", USER_ROLE.INNOVATOR)
      } else if (localUserObj.userType === USER_ROLE.GOVERNMENT) {
        localStorage.setItem("userBasedRoute", USER_ROLE.GOVERNMENT)
      } else if (localUserObj.userType === USER_ROLE.BETA_TEST_USER) {
        localStorage.setItem("userBasedRoute", USER_ROLE.BETA_TEST_USER)
      }
      findUserById({
        id: localUserObj.id,
        token: localUserObj.token
      });
    }
  }, [authUserData]);
  useEffect(() => {
    if (findUserByIdResponse.isSuccess) {
      localStorage.setItem("routeTo", "dashboard")
      dispatch(setFindUserByIdUser(findUserByIdResponse));
    }
  }, [findUserByIdResponse.isSuccess]);

  useEffect(() => {
    const { from } = location.state || { from: { pathname: '/dashboard' } };
  }, [location])

  useEffect(() => {
    if (localUserObj && userBasedRoute && userStatus) {
      if (findUserByIdResponse.isSuccess && userBasedRoute === "admins") {
        navigate("/settings");
      } else if (findUserByIdResponse.isSuccess && (userBasedRoute === USER_ROLE.ADMIN || userBasedRoute === USER_ROLE.BETA_TEST_USER)) {
        if (location.pathname === "/login") {
          navigate("/dashboard");

        } else {
          navigate(location.pathname);
        }
      } else if (findUserByIdResponse.isSuccess && userBasedRoute === USER_ROLE.CORPORATE) {
        if (location.pathname === "/login") {
          navigate("/corporate-user-dashboard");

        } else {
          navigate(location.pathname);
        }
      } else if (findUserByIdResponse.isSuccess && userBasedRoute === USER_ROLE.GOVERNMENT) {
        if (location.pathname === "/login") {
          navigate("/corporate-user-dashboard");

        } else {
          navigate(location.pathname);
        }
      } else if (findUserByIdResponse.isSuccess && userBasedRoute === USER_ROLE.NGO) {
        if (location.pathname === "/login") {
          navigate("/corporate-user-dashboard");

        } else {
          navigate(location.pathname);
        }
      } else if (findUserByIdResponse.isSuccess && userBasedRoute === USER_ROLE.INNOVATOR) {
        if (location.pathname === "/login") {
          navigate("/corporate-user-dashboard");

        } else {
          navigate(location.pathname);
        }
      } else if (findUserByIdResponse.isSuccess && userBasedRoute === USER_ROLE.GOVERNMENT) {
        if (location.pathname === "/login") {
          navigate("/corporate-user-dashboard");
        } else {
          navigate(location.pathname);
        }
      } else if (findUserByIdResponse.isSuccess && userBasedRoute === USER_ROLE.NGO) {
        if (location.pathname === "/login") {
          navigate("/corporate-user-dashboard");
        } else {
          navigate(location.pathname);
        }
      } else if (findUserByIdResponse.isSuccess && userBasedRoute === USER_ROLE.INNOVATOR) {
        if (location.pathname === "/login") {
          navigate("/corporate-user-dashboard");
        } else {
          navigate(location.pathname);
        }
      }
    } else if (userStatus === false) {
      if (localUserObj !== null) {
        navigate("/un-authorized")
      }
    } else if (routeTo === "login") {
      navigate("/login", { replace: true });
    } else if (valueAfterLastSlash === "") {
      navigate("/home", { replace: true });
    } else if (routeTo === null) {
      navigate("/login", { replace: true })
    }
    // else if ( routeTo === "dashboard") {
    //   navigate("/dashboard")
    // }
    // else if (routeTo === "about") {
    //   navigate("about", { replace: true });
    // } else if (routeTo === "terms-and-conditions") {
    //   navigate("terms-and-conditions", { replace: true });
    // } else if (routeTo === "privacy-policy") {
    //   navigate("privacy-policy", { replace: true });
    // }
    // else {
    //   navigate("/home", { replace: true });
    // }
  }, [userBasedRoute, findUserByIdResponse.isSuccess, authUserData, userStatus]);

  return (
    <div className="App">
      <Routes>
        {/* {userBasedRoute === "admin" && (
          <Route path="/" element={<AdminLayout />}>
            <Route index element={<Navigate to="/settings" />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        )} */}
        {(userBasedRoute === USER_ROLE.ADMIN || userBasedRoute === USER_ROLE.BETA_TEST_USER) && (
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="reports" element={<Outlet />}>
              <Route index element={<Reports />} />
              <Route path=":reportType" element={<AllReports />} />
            </Route>
            <Route path="complianceReports" element={<Outlet />}>
              <Route index element={<CompliantReports />} />
              <Route path=":reportType" element={<AllComplianceTestReports />} />
            </Route>
            <Route path="aiGovernanceReports" element={<Outlet />}>
              <Route index element={<AIGovernanceReports />} />
              <Route path=":reportType" element={<AllAIGovernanceReports />} />
            </Route>
            <Route path="matchMakerReports" element={<Outlet />}>
              <Route index element={<MatchMakerReports />} />
              <Route path=":reportType" element={<AllMatchMakerReports />} />
            </Route>
            <Route path="documentReports" element={<Outlet />}>
              <Route index element={<DocumentReports />} />
              <Route path=":reportType" element={<AllDocumentReports />} />
            </Route>
            <Route path="faqs" element={<FAQMaster />} />
            <Route index element={<Navigate to="/settings" />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        )}
        {(userBasedRoute === USER_ROLE.CORPORATE
          || userBasedRoute === USER_ROLE.NGO
          || userBasedRoute === USER_ROLE.GOVERNMENT
          || userBasedRoute === USER_ROLE.INNOVATOR) && (
            <Route path="/" element={<Layout />}>
              <Route path="corporate-user-dashboard" element={<DashboardCorporateUser />} />
            </Route>
          )}
        {!userStatus && (
          <Route path="un-authorized" element={<UnAuthorizedLayout />} />
        )}
        <Route path="logout" element={<Logout />} />
        <Route path="login" element={<Login />} />
        <Route path="home" element={<Home />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="/ai-governance" element={<AIGovernanceExpectations />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="verify" element={<Verify />} />
        <Route path="page" element={<LandingPage />} />
        <Route path="pricing-plans" element={<PricingPlans />} />
        {dataPrivacyActsData.map((route, index) => (
          <Route key={index} path={route.link} element={<AllActDataPrivacyRegulations />} />
        ))}
        {aiRegulationActsData.map((route, index) => (
          <Route key={index} path={route.link} element={<AllActDataPrivacyRegulations />} />
        ))}
        {aiRegulationsData.map((route, index) => (
          <Route key={index} path={route.link} element={<AllActDataPrivacyRegulations />} />
        ))}
        {AIGovernanceFrameworkData.map((route, index) => (
          <Route key={index} path={route.link} element={<AllActDataPrivacyRegulations />} />
        ))}
        {AI_REGULATIONS.map((route, index) => (
          <Route key={index} path={route.link} element={<AllActDataPrivacyRegulations />} />
        ))}
         <Route path="cascad" element={<CascadingCallComponent />} />
         <Route path="nmap" element={<Nmap />} />
      </Routes>
    </div>
  );
}
export default App;














