import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dataService, dataServiceBaseUrl, userManagement } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const subIndustryService = createApi({
    reducerPath: 'subIndustryService',
    baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
    tagTypes: ['SubIndustry'],
    endpoints: (builder) => ({
        findAllSubIndustry: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.subIndustryController.findAllSubIndustry}`,
                    method: 'GET',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getAllSubIndustry: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.subIndustryController.getAllSubIndustry}/${body.page}/${body.size}?orderBy=${body.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getSubIndustryById: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.subIndustryController.getSubIndustryById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        saveSubIndustry: builder.mutation({
            query(body){
                return {
                    url: `${dataService.subIndustryController.saveSubIndustry}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateSubIndustry: builder.mutation({
            query(body){
                return {
                    url: `${dataService.subIndustryController.updateSubIndustry}/${body.payload.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteSubIndustry: builder.mutation({
            query(body){
                return {
                    url: `${dataService.subIndustryController.deleteSubIndustry}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllSubIndustryWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${dataService.subIndustryController.getAllSubIndustryWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
    })
  });
  export const {
    useFindAllSubIndustryMutation,
    useGetAllSubIndustryMutation,
    useGetAllSubIndustryWithSearchMutation,
    useGetSubIndustryByIdMutation,
    useSaveSubIndustryMutation,
    useUpdateSubIndustryMutation,
    useDeleteSubIndustryMutation
  } = subIndustryService;