import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface WebSearchState {
  data: [] | null | any;
  detailSearchData: [] | null | any;
}

const initialState: WebSearchState = {
  data: null,
  detailSearchData: null,
};

export const webSearchSlice = createSlice({
  name: 'webSearch',
  initialState,
  reducers: {
    setSearchCompany: (state, action) => {
      state.data = action.payload;
    },
    setDetailOrganizationSearch: (state, action) => {
      state.detailSearchData = action.payload;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSearchCompany, setDetailOrganizationSearch, reset } = webSearchSlice.actions;
export const getSearchCompanyData = (state: RootState) => state.webSearch.data;
export const getDetailOrganizationSearch = (state: RootState) => state.webSearch.detailSearchData;
export default webSearchSlice.reducer;