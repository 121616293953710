import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { webUI, webUIBaseUrl } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const vulnerabiltyService = createApi({
  reducerPath: "vulnerabiltyService",
  baseQuery: fetchBaseQuery({ baseUrl: webUIBaseUrl }),
  tagTypes: ["vulnerabilty"],
  endpoints: (builder) => ({
    codeCyberSecVulAnalysis: builder.mutation({
      query(body) {
        return {
          // url: `${webUI.vulnerabiltyController.codeCyberSecVulAnalysis}`,
          url: `${webUI.aiBiasController.codeVulnerability}`,
          method: 'POST',
          // headers: { 'Content-type': "application/json"},
          body: body.file,
        }
      }
    }),
    codeAIBiasAnalysis: builder.mutation({
      query(body) {
        return {
          url: `${webUI.aiBiasController.codeAiBiasAnalysis}`,
          method: 'POST',
          // headers: { 'Content-type': "application/json"},
          body: body.file,
        }
      }
    }),
    codeReviewReport: builder.mutation({
      query(body) {
        return {
          url: `${webUI.codeReviewController.codeReviewReport}`,
          method: 'POST',
          // headers: { 'Content-type': "application/json"},
          body: body.file,
        }
      }
    }),
  }),
});
export const {
 useCodeCyberSecVulAnalysisMutation,
 useCodeAIBiasAnalysisMutation,
 useCodeReviewReportMutation,
} = vulnerabiltyService;
