import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface DocumentCategoryState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  allDocumentCategory: [] | null | any;
}
const initialState: DocumentCategoryState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  allDocumentCategory: null
};
export const documentCategorySlice = createSlice({
  name: 'documentCategory',
  initialState,
  reducers: {
    setAllDocumentCategory: (state, action) => {
      state.allDocumentCategory = action.payload.data;
    },
    setDocumentCategory: (state, action) => {
      state.data = action.payload.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllDocumentCategoryBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllDocumentCategory, setDocumentCategory, setAllDocumentCategoryBySearch,reset } = documentCategorySlice.actions;
export const alldocumentCategoryDetails = (state: RootState) => state.documentCategory.allDocumentCategory;
export const getAlldocumentCategoryDetails = (state: RootState) => state.documentCategory.data;
export const getdocumentCategoryTotalRecords=(state:RootState)=>state.documentCategory.totalRecords;
export const getAlldocumentCategoryBySearch = (state: RootState) => state.documentCategory.data;
export default documentCategorySlice.reducer;