import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface DocumentCreationState {
  pdfURL: any;
  pdfBlob: any;
  documentRecordData: [] | null | any;
  documentRecordCurrentPage: number | null;
  documentRecordTotalRecords: number | null;
  documentRecordTotalPages: number | null;
  data: [] | null | any;
}
const initialState: DocumentCreationState = {
  pdfURL: null,
  pdfBlob: null,
  documentRecordData: null,
  documentRecordCurrentPage: null,
  documentRecordTotalRecords: null,
  documentRecordTotalPages: null,
  data: null,
};
export const documentCreationSlice = createSlice({
  name: 'documentCreation',
  initialState,
  reducers: {
    setPdfURL: (state, action) => {
      state.pdfURL = action.payload
    },
    setPdfBlob: (state, action) => {
      state.pdfBlob = action.payload
    },
    setAllDocumentRecords: (state, action) => {
      state.documentRecordData = action.payload.data;
      state.documentRecordTotalRecords = action.payload.totalRecords;
      state.documentRecordTotalPages = action.payload.totalPage;
      state.documentRecordCurrentPage = action.payload.currentPage;
    },
    setAllDocumentRecordWithSearch: (state, action) => {
      state.documentRecordData = action.payload.data.data;
      state.documentRecordTotalRecords = action.payload.data.totalRecords;
      state.documentRecordTotalPages = action.payload.data.totalPage;
      state.documentRecordCurrentPage = action.payload.data.currentPage;
    },
    setDocumentById: (state, action) => {
      state.data = action.payload;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setPdfURL, setPdfBlob, setAllDocumentRecords, setAllDocumentRecordWithSearch, setDocumentById, reset } = documentCreationSlice.actions;
export const pdfURL = (state: RootState) => state.documentCreation.pdfURL;
export const pdfBlob = (state: RootState) => state.documentCreation.pdfBlob;
export const getAllDocumentRecordsDetails = (state: RootState) => state.documentCreation.documentRecordData;
export const getDocumentRecordsTotalRecords = (state: RootState) => state.documentCreation.documentRecordTotalRecords;
export const getAllDocumentRecordBySearch = (state: RootState) => state.documentCreation.documentRecordData;
export const getDocumentRecordByIdData = (state: RootState) => state.documentCreation.data;

export default documentCreationSlice.reducer;