import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManagement, userManagementBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const packageMasterService = createApi({
    reducerPath: 'packageMasterService',
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ['PackageMaster'],
    endpoints: (builder) => ({
        findAllPackages: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageMasterController.findAllPackages}`,
                    method: 'GET',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getAllPackages: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageMasterController.getAllPackages}/${body.page}/${body.size}?orderBy=${body.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getPackageById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageMasterController.getPakcageById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        savePackage: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.packageMasterController.savePackage}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updatePackage: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.packageMasterController.updatePackage}/${body.payload.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deletePackage: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.packageMasterController.deletePackage}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllPackagesWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageMasterController.getAllPackagesWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
    })
  });
  export const {
    useFindAllPackagesMutation,
    useGetAllPackagesMutation,
    useSavePackageMutation,
    useUpdatePackageMutation,
    useDeletePackageMutation,
    useGetPackageByIdMutation,
    useGetAllPackagesWithSearchMutation
  } = packageMasterService;