import React, { useState } from "react";

const CascadingCallComponent = () => {

    const [prompt, setPrompt] = useState("");
    const [maxTokens, setMaxTokens] = useState("");
    const [response, setResponse]: any = useState(null);

    const handleSubmit = async (e: any) => {
        // const url1 = "https://quantileapibeta.online/call_cascading?prompt=US%20ai%20vs%20EU%20ai%20.&max_tokens=800"
        // const apiKey = "quant-3rzCLlkmjyamQWB4oW1jF";
        e.preventDefault();
        const url = `https://quantileapibeta.online/call_cascading?prompt=${encodeURIComponent(
            prompt
        )}&max_tokens=800`;
        const apiKey = "quant-3rzCLlkmjyamQWB4oW1jF";
        try {
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    accept: "application/json",
                    "quant-api-key": apiKey,
                },
            });
            const data = await res.json();
            setResponse(data);
        } catch (error) {
            console.error("Error:", error);
        }
    };
    return (
        <div>
            <h1>Cascading Call</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Prompt:
                    <input
                        type="text"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Max Tokens:
                    <input
                        type="number"
                        value={maxTokens}
                        onChange={(e) => setMaxTokens(e.target.value)}
                    />
                </label>
                <br />
                <button type="submit">Submit</button>
            </form>
            {response && (
                <div>
                    <h2>Response:</h2>
                    <p>{response.choices[0].message.content}</p>
                </div>
            )}
        </div>
    );
};
export default CascadingCallComponent;