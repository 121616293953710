import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManagement, userManagementBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const featureService = createApi({
    reducerPath: 'featureService',
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ['Feature'],
    endpoints: (builder) => ({
        getAllFeatures: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.featureController.getAllFeatures}/${body.page}/${body.size}?orderBy=${body.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getFeatureById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.featureController.getByFeatureId}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        saveFeature: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.featureController.saveFeature}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateFeature: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.featureController.updateFeature}/${body.payload.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteFeature: builder.mutation({
            query(body){
                return {
                    url: `${userManagement.featureController.deleteFeature}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllFeaturesWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.featureController.getAllFeaturesWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        allFeatures: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.featureController.allFeatures}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
    })
  });
  export const {
   useGetAllFeaturesMutation,
   useSaveFeatureMutation,
   useUpdateFeatureMutation,
   useDeleteFeatureMutation,
   useGetFeatureByIdMutation,
   useGetAllFeaturesWithSearchMutation,
   useAllFeaturesMutation,
  } = featureService;