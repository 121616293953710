import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataServiceBaseUrl, dataService, assessmentAndAuditsBaseUrl, assessmentAndAudits } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const companyService = createApi({
  reducerPath: "companyService",
  // baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
  baseQuery: fetchBaseQuery({ baseUrl: assessmentAndAuditsBaseUrl }),
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    getAllCompanies: builder.mutation({
      query(body) {
        return {
          // url: `${dataService.companyController.getAllCompanies}`,
          url: `${assessmentAndAudits.getAllCompany}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    getAllProjects: builder.mutation({
      query(body) {
        return {
          url: `${assessmentAndAudits.getAllProjects}`,
          method: "GET",
          body: body.payload,
        };
      },
    }),
    getEvidences: builder.mutation({
      query(body) {
        return {
          url: `${assessmentAndAudits.getEvidences}/${body.projectId}/${body.companyId}`,
          method: "GET",
          body: body.payload,
        };
      },
    }),
    getAssessment: builder.mutation({
      query(body) {
        return {
          url: `${assessmentAndAudits.getAssessment}/${body.projectId}`,
          method: "GET",
          body: body.payload,
        };
      },
    }),
    getDataLineage: builder.mutation({
      query(body) {
        const params = {
          companyname: body.companyName || "",
          projectname: body.projectName || "",
          responsibilitygroup: body.responsibilityGroup || "",
          responsibilitycenter: body.responsibilityCenter || "",
          objecttype: body.objectType || "",
          objectcode: body.objectCode || ""
        };
    
        // Convert the params object to URL query parameters
        const queryParams = new URLSearchParams(params).toString();

        return {
          url: `${assessmentAndAudits.getDataLineage}?${queryParams}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAllCompaniesMutation,
  useGetAllProjectsMutation,
  useGetEvidencesMutation,
  useGetAssessmentMutation,
  useGetDataLineageMutation,
} = companyService;