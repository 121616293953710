import { useState } from "react";
import "./dashboard.scss";
import { Checkbox, Select, Tabs } from "antd";

import DashboardCorporateUser from "./CorporateUserDashboard/DashboardCorporateUser";
import { USER_ROLE } from "../../constant/AppConstant";
import { checkPermissions } from "../../component/utils";
import { PERMISSION, PERMISSION_CODE } from "../../constant/userPermission";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { REPORT_NAME } from "../../constant/reportType";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

type Props = {};

const Dashboard = (props: Props) => {

  const [title, setTitle] = useState(USER_ROLE.CORPORATE);

  const localUserObj = getLocalStorageData("localUser");
  const paymentStatus = localStorage.getItem("paymentStatus");

  const items = [
    {
      key: USER_ROLE.CORPORATE,
      label: "Corporate User",
      children: <DashboardCorporateUser title={title} />
    },
    {
      key: USER_ROLE.GOVERNMENT,
      label: "Government User",
      children: <DashboardCorporateUser title={title} />
    },
    {
      key: USER_ROLE.INNOVATOR,
      label: "Innovator User",
      children: <DashboardCorporateUser title={title} />
    },
    {
      key: USER_ROLE.NGO,
      label: "NGO User",
      children: <DashboardCorporateUser title={title} />
    }
  ]

  const handleTabClick = (event: any) => {
    setTitle(event);
  }

  return (
    <div>
      {localUserObj?.userType === USER_ROLE.ADMIN ? (
        // Render this div if user type is ADMIN
        <div className="overflow-y-auto">
          {/* Inlet */}
          <div className="Navs w-400" style={{ fontWeight: "700" }}>
            <Tabs defaultActiveKey="1" items={items} onTabClick={handleTabClick}>
            </Tabs>
          </div>
        </div>
      ) : (
        // Render based on permissions, features, and payment
        (localUserObj?.permissions &&
          checkPermissions(PERMISSION_CODE.DASHBOARD, localUserObj.permissions)) ||
          localUserObj?.features.includes(REPORT_NAME.REGULATORY_INSIGHT_DASHBOARD) === true ? (
          // Check if payment is false
          paymentStatus === "false" ? (
            // Render this div if payment is false
            <div className="fw-bold fs-4 mt-5 text-center">
              Please proceed to payment to avail this feature or contact info@capitoltunnels.ai.
            </div>
          ) : (
            // Render this div if all other conditions are met
            <div className="overflow-y-auto">
              {/* Inlet */}
              <div className="Navs w-400" style={{ fontWeight: "700" }}>
                <Tabs defaultActiveKey="1" items={items} onTabClick={handleTabClick}>
                </Tabs>
              </div>
            </div>
          )
        ) : (
          // Render this div if all conditions are false
          <div className="fw-bold fs-4 mt-5 text-center">
            If you want to access this please contact info@capitoltunnels.ai
          </div>
        )
      )}
    </div>
  );
};

export default Dashboard;
