import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface PackageMasterState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}
const initialState: PackageMasterState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const packageMasterSlice = createSlice({
  name: 'packageMaster',
  initialState,
  reducers: {
    setAllPackages: (state, action) => {
      state.data = action.payload.data;
    },
    setPackageMaster: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllPackagesBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllPackages, setPackageMaster, setAllPackagesBySearch,reset } = packageMasterSlice.actions;
export const allPackagesDetails = (state: RootState) => state.packageMaster.data;
export const getAllPackagesDetails = (state: RootState) => state.packageMaster.data;
export const getPackagesTotalRecords=(state:RootState)=>state.packageMaster.totalRecords;
export const getAllPakcagesBySearch = (state: RootState) => state.packageMaster.data;
export default packageMasterSlice.reducer;