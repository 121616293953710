import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { REPORT_NAME, REPORT_TYPE } from "../../constant/reportType";
import "./reports.scss";
import { BiCheckCircle } from "react-icons/bi";
import { useSaveAuditLogMutation } from "../../redux-toolkit/services/auditLogService";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { checkPermissions } from "../../component/utils";
import { PERMISSION_CODE } from "../../constant/userPermission";
import { assessmentAndAuditsUrl, riskManagementUrl } from "../../redux-toolkit/endpoints";
import { USER_ROLE } from "../../constant/AppConstant";

type Props = {};

const AIGovernanceReports = (props: Props) => {
  const navigate = useNavigate();
  const selectedReportName: any = localStorage.getItem("selectedReportName");
  const tab: any = localStorage.getItem("tab");

  const [selectedReport, setSelectedReport]: any = useState(localStorage.getItem("selectedReport"));
  const startTime = new Date();
  const [saveAuditLog, saveAuditLogResponse] = useSaveAuditLogMutation();
  const localUserObj = getLocalStorageData("localUser");
  const paymentStatus = getLocalStorageData("paymentStatus");

  useEffect(() => {
    const selectedReportTab: any = localStorage.getItem("selectedReport");
    setSelectedReport(selectedReportTab);
    if (selectedReport &&
      (selectedReport !== REPORT_TYPE.DATA_GOVERNANCE &&
        selectedReport !== REPORT_TYPE.ASSESSMENT_AND_AUDITS &&
        selectedReport !== REPORT_TYPE.RISK_MANAGEMENT)) {
      localStorage.removeItem("selectedReportName");
    }
  }, [selectedReport])

  const [reportTypes, setReportTypes] = useState([
    {
      id: 1,
      name: "Data Governance",
      isActive:
        localStorage.getItem("selectedReport") === REPORT_TYPE.DATA_GOVERNANCE
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "Data Governance",
          reports: [
            {
              id: "1",
              name: "Data Lineage",
              // disabled: userBasedRoute === USER_ROLE.BETA_TEST_USER ? true : false,
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.DATA_LINEAGE, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.DATA_LINEAGE))
              ),
              route: "/aiGovernanceReports/Data Lineage",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Assessment and Audits",
      isActive:
        localStorage.getItem("selectedReport") === REPORT_TYPE.ASSESSMENT_AND_AUDITS
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "Assessment and Audits",
          reports: [
            {
              id: "0",
              name: "Assessment and Audits",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.ASSESSMENT_AND_AUDITS, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.ASSESSMENT_AND_AUDITS))
              ),
              route: "/aiGovernanceReports/Assessment and Audits",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "Risk Management",
      isActive:
        localStorage.getItem("selectedReport") === REPORT_TYPE.RISK_MANAGEMENT
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "Risk Management",
          reports: [
            {
              id: "0",
              name: "Risk Management",
              // disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : localUserObj.userType === USER_ROLE.ADMIN ? true : localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.RISK_MANAGEMENT_R, localUserObj?.permissions)
              //   && localUserObj?.features?.includes(REPORT_NAME.RISK_MANAGEMENT) : false,
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : (
                (localUserObj?.permissions && checkPermissions(PERMISSION_CODE.RISK_MANAGEMENT, localUserObj.permissions)) ||
                (localUserObj?.features && localUserObj.features.includes(REPORT_NAME.RISK_MANAGEMENT))
              ),
              route: "/aiGovernanceReports/Risk Management",
            },
          ],
        },
      ],
    },
  ]);

  // Methods
  const handleReportTypeClick = (reportType: any) => {
    localStorage.setItem("selectedReport", reportType.name);

    let reportTypesCpy = [...reportTypes];

    reportTypesCpy.forEach((report, i) => {
      if (report.id === reportType.id) {
        report.isActive = true;
      } else {
        report.isActive = false;
      }
    });

    setReportTypes(reportTypesCpy);
  };

  return (
    <div className="cm-reports pb-4">
      {/* Inlet */}
      {/* <h4 className="title-d">Compliant Test</h4> */}
      {/* Content */}
      <div className="d-flex mt-4 bg-white rounded-3 overflow-x-auto shadow-sm">
        <div className="report-list bg-cm-primary d-flex flex-column my-5">
          {reportTypes.map((reportType, i) => (
            <button
              className="btn text-start ps-4 cm-btn border-0 p-3 text-white border-top rounded-0 border-white position-relative"
              onClick={() => {
                handleReportTypeClick(reportType);
              }}
              key={i}
            >
              <span>{reportType.name}</span>
              {reportType.isActive && (
                <div className="cm-active-polygon position-absolute"></div>
              )}
            </button>
          ))}
        </div>
        <div className="report-content  ms-4 container-fluid">
          {reportTypes.map(
            (reportItem, i) =>
              reportItem.isActive &&
              reportItem?.data?.map((report, ri) => {
                return (
                  <div className="my-4" key={Math.random()}>
                    <h5 className="fw-bold fs-4 text-cm-active-primary1 mb-3  ">
                      {report.name}
                    </h5>
                    {/* <div className="border-top  border-primary"></div> */}
                    <div className="row">
                      {report?.reports?.map((rep: any, ri: any) => (
                        <div
                          className="col-md-4 cursor-pointer"
                          key={ri}
                          onClick={() => {
                            if (rep.disabled && (paymentStatus || localUserObj.userType === USER_ROLE.ADMIN)) {
                              const userId = localUserObj.id ? localUserObj.id : null;
                              if (reportItem.name === REPORT_TYPE.ASSESSMENT_AND_AUDITS) {

                                const link = `${assessmentAndAuditsUrl}`;
                                window.open(link + "?user=" + userId, "_blank"); // Append state variable as a query parameter

                                // window.open(assessmentAndAuditsUrl, "_blank")
                              } else if (reportItem.name === REPORT_TYPE.RISK_MANAGEMENT) {
                                const link = `${riskManagementUrl}`;
                                window.open(link + "?user=" + userId, "_blank")
                              } else {
                                navigate(rep.route + `?report=${reportItem.name}`);
                                saveAuditLog({
                                  payload: {
                                    startTime: startTime.toISOString(),
                                    // endTime: startTime.toISOString(),
                                    // request: {},
                                    // response: {},
                                    // serviceName: "login",
                                    eventName: rep.route,
                                    url: rep.route,
                                    userId: localUserObj?.id
                                  }
                                })
                              }
                            }
                          }}
                        >
                          <ul className="fs-6">
                            {/* {!rep.disabled ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="overlay-example" {...props}>
                                    If you want to access this please contact info@capitoltunnels.ai
                                  </Tooltip>
                                }
                              >
                                <li className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                  {" "}
                                  {rep.name}
                                </li>
                              </OverlayTrigger>
                            ) : (
                              <div className="d-flex">
                                <li className={`text-cm-light-text  text-decoration-underline ${!rep.disabled ? 'disabled' : null}`}>
                                  {" "}
                                  {rep.name}
                                </li>
                                {selectedReportName && selectedReportName.includes(rep.name) ? (
                                  <p className="ms-2">
                                    <BiCheckCircle className="text-success" />
                                  </p>
                                ) : null}
                              </div>

                            )} */}
                            {!rep.disabled ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="overlay-example" {...props}>
                                    If you want to access this please contact info@capitoltunnels.ai
                                  </Tooltip>
                                }
                              >
                                <li className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                  {" "}
                                  {rep.name}
                                </li>
                              </OverlayTrigger>
                            ) : (
                              (paymentStatus === true || localUserObj.userType === USER_ROLE.ADMIN ? (
                                <div className="d-flex">
                                  <li className={`text-cm-light-text  text-decoration-underline ${!rep.disabled ? 'disabled' : null}`}>
                                    {" "}
                                    {rep.name}
                                  </li>
                                  {selectedReportName && selectedReportName.includes(rep.name) ? (
                                    <p className="ms-2">
                                      <BiCheckCircle className="text-success" />
                                    </p>
                                  ) : null}
                                </div>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="overlay-example" {...props}>
                                      Please proceed to payment to avail this feature or contact info@capitoltunnels.ai
                                    </Tooltip>
                                  }
                                >
                                  <li className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                    {" "}
                                    {rep.name}
                                  </li>
                                </OverlayTrigger>
                              ))
                            )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    </div>
  );
};

export default AIGovernanceReports;
