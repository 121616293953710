import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import "./auth.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { alertMessage } from "../../utils/AlertService";
// import "./style.css";
import "../../assets/icons/mission.png";
import "../../assets/icons/vision.png";
import "../../assets/logo-blue.png";
import * as Yup from "yup";
import { useGetAllUserGroupMutation } from "../../redux-toolkit/services/userGroupService";
import { getAllUserGroupDetails, setAllUserGroup } from "../../redux-toolkit/slices/allUserGroupSlice";
import { useAppSelector } from "../../redux-toolkit/hooks";
import { useGetAllUserRoleMutation } from "../../redux-toolkit/services/userRoleService";
import { getAllUserRoleDetails, setAllUserRole } from "../../redux-toolkit/slices/allUserRoleSlice";
import { setAllUser } from "../../redux-toolkit/slices/allUserSlice";
import { useSaveUserMutation } from "../../redux-toolkit/services/allUserService";
import { Modal } from "react-bootstrap";
import { useGetAllCountryMutation } from "../../redux-toolkit/services/countryService";
import { useGetStateMutation } from "../../redux-toolkit/services/stateService";
import { getFindCityByStateIdDetail } from "../../redux-toolkit/slices/citySlice";
import { getFindStateByCountryNameDetail, setStates } from "../../redux-toolkit/slices/statesSlice";
import { getAllcountryDetail, setcountry } from "../../redux-toolkit/slices/countrySlice";
import Loading from "../../component/Loading";
import { Link } from "react-router-dom";
import { useFindAllPackagesMutation } from "../../redux-toolkit/services/packageMasterService";
import { allPackagesDetails, setAllPackages } from "../../redux-toolkit/slices/packageMasterSlice";

type Props = {
  setShow?: any;
  show?: any;
  selectedPackageId?: any;
  billingFrequency?: any;
};

const SignUp = (props: Props) => {
  // validation...........
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter First name!"),
    lastName: Yup.string().required("Please enter Last name!"),
    phoneNumber: Yup.string()
      // .required('Field is required')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Phone number is not valid'
      )
      .min(10, 'Contact Number must be 10 digits')
      .max(10, 'Contact Number must be 10 digits')
      .trim(),

    briefProfile: Yup.string()
      // .required("Please enter Description!")
      .min(10, "Description is Too Short!")
      .max(100, "Description Too Long!"),

    email: Yup.string().required("Please enter Email Address!"),
    selectedPackage: Yup.string().required("Please select package to proceed!"),
    //To do uncomment these below 2 validation if showing role and group dropodown instead of input field
    // group: Yup.string().required("Please select group"),
    // role: Yup.string().required("Please select role"),

    // termsAndConditionsCheckbox: Yup.string().required("Please agree to the Terms and Conditions"),
    // privacyPolicyCheckbox: Yup.string().required("Please agree to the Privacy Policy"),
  });

  //selector
  const allCountries: any = useAppSelector(getAllcountryDetail);
  const allCities: any = useAppSelector(getFindCityByStateIdDetail);
  const allStates: any = useAppSelector(getFindStateByCountryNameDetail);

  //Mutation
  const [signUpUser, signUpResponse] = useSaveUserMutation();
  const [getAllUserGroup, allUserGroupResponse] = useGetAllUserGroupMutation();
  const [getAllUserRole, allUserRoleResponse] = useGetAllUserRoleMutation();
  const [getCountry, getCountryResponse] = useGetAllCountryMutation();
  const [getState, getStateResponse] = useGetStateMutation();
  const [findAllPackages, packagesResponse] = useFindAllPackagesMutation();

  //selector
  const allUserGroup: any = useAppSelector(getAllUserGroupDetails);
  const allUserRole: any = useAppSelector(getAllUserRoleDetails);
  const allPackagesData: any = useAppSelector(allPackagesDetails);

  const role = [
    {
      id: 9,
      name: "Beta Test User",
      status: true
    }
  ]

  //states
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry]: any = useState();
  const [name, setName]: any = useState();
  const [address, setAddress]: any = useState();
  const [orgName, setOrgName]: any = useState();

  useEffect(() => {
    getCountry({});
    findAllPackages({});
  }, []);

  useEffect(() => {
    localStorage.setItem("routeTo", "signup")
  }, []);


  useEffect(() => {
    if (getCountryResponse.isSuccess) {
      dispatch(setcountry(getCountryResponse));
    } else if (getCountryResponse.isError) {
      let response: any = getCountryResponse.error;
      alertMessage(response, "error");
    }
  }, [getCountryResponse.isSuccess, getCountryResponse.isError]);

  const handleSelectCountry = (event: any) => {
    setSelectedCountry(event.target.value);
    if (event) {
      getState({
        countryId: event.target.value,
      });
    }
  };

  useEffect(() => {
    if (getStateResponse.isSuccess) {
      dispatch(setStates(getStateResponse));
    } else if (getStateResponse.isError) {
      let response: any = getStateResponse.error;
      alertMessage(response, "error");
    }
  }, [getStateResponse.isSuccess, getStateResponse.isError]);

  //get allpackages.......
  useEffect(() => {
    if (packagesResponse.isSuccess) {
      dispatch(setAllPackages(packagesResponse));
    } else if (packagesResponse.isError) {
      alertMessage("Error while fetching packages", "error");
    }
  }, [packagesResponse.isSuccess, packagesResponse.isError]);


  const handleSignUpSubmit = async (values: any) => {
    const payload = {
      address: values.address,
      briefProfile: values.briefProfile,
      cityId: values.city,
      countryId: parseInt(selectedCountry), //values.country,
      email: values.email,
      firstName: values.firstName,
      // id: 0,
      lastName: values.lastName,
      linkedinRef: values.linkedinRef,
      organizationName: values.organizationName,
      password: values.email,
      phoneNumber: values.phoneNumber,
      postalCode: values.pinCode,
      stateId: parseInt(values.state),
      userGroupId: 5, // parseInt(values.group), uncomment this when using group dropdown this value is only for corporate group-id
      userRoleId: 9, // parseInt(values.role), uncomment this when using role dropdown this value is only for Beta Test User role-id
      roleIds: [9],// parseInt(values.role), uncomment this when using role dropdown this value is only for Beta Test User role-id
      username: values.email,
      termsAndCondition: values.termsAndConditionsCheckbox,
      privacyPolicy: values.privacyPolicyCheckbox,
      packageId: parseInt(values.selectedPackage),
      isUserSubscribed: true,
    }

    signUpUser({
      payload: payload
    })
  };

  useEffect(() => {
    if (signUpResponse.isSuccess) {
      dispatch(setAllUser(signUpResponse));
      // alertMessage("Your account has been successfully created", "success");
      alertMessage("An email sent to given email address for verification, please verify for account activation", "success")
      props.setShow(false);
      // navigate("/login", { replace: true });

    } else if (signUpResponse.isError) {
      let response: any = signUpResponse?.error;
      alertMessage(response.data.serviceErrors[0].msg ? response.data.serviceErrors[0].msg : "Error while creating account", "error");
    }
  }, [signUpResponse.isSuccess, signUpResponse.isError])


  //get all usergroup
  useEffect(() => {
    getAllUserGroup({});
  }, [])

  useEffect(() => {
    if (allUserGroupResponse.isSuccess) {
      dispatch(setAllUserGroup(allUserGroupResponse));

    } else if (allUserGroupResponse.isError) {
      let response: any = allUserGroupResponse?.error;
      alertMessage("Error while fetching User group", "error");
    }
  }, [allUserGroupResponse.isSuccess, allUserGroupResponse.isError]);

  //get all userrole
  useEffect(() => {
    getAllUserRole({});
  }, []);

  useEffect(() => {
    if (allUserRoleResponse.isSuccess) {
      dispatch(setAllUserRole(allUserRoleResponse));

    } else if (allUserRoleResponse.isError) {
      let response: any = allUserRoleResponse?.error;
      alertMessage("Error while fetching User role", "error");
    }
  }, [allUserRoleResponse.isSuccess, allUserRoleResponse.isError]);

  const menu = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          Admin
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          Super Admin
        </a>
      ),
      // icon: <SmileOutlined />,
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          User
        </a>
      ),
    },
  ];

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isShown, setIsSHown] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleClose = () => props.setShow(false);

  return (
    <>
      {signUpResponse.isLoading ? <Loading /> : ""}
      <Modal
        size="lg"
        aria-labelledby="signup-modal"
        show={props.show}
        onHide={handleClose}
        className="login"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="text-align-center text-cm-primary1 fw-bolder fs-1 mb-0">Sign Up</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              state: "",
              city: "",
              country: "",
              password: "",
              pinCode: "",
              linkedinRef: "",
              organizationName: "",
              briefProfile: "",
              address: "",
              group: "",
              role: "",
              termsAndConditionsCheckbox: "",
              privacyPolicyCheckbox: "",
              selectedPackage: props.selectedPackageId ? props.selectedPackageId : "",
            }}
            validationSchema={SignupSchema}
            onSubmit={handleSignUpSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="d-flex flex-column rounded-3 overflow-hidden p-md-4 p-3">
                  {/* <img
                        src={logo}
                        className="cloud-mapper mb-3 rounded mx-auto d-block "
                      /> */}
                  {/* <h2 className="text-align-center">Sign Up</h2> */}
                  <div className="d-flex">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <input
                          type="text"
                          value={values.firstName}
                          name="firstName"
                          onChange={(e) => {
                            handleChange(e)
                            setName(e.target.value)
                          }}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.firstName ? 'is-invalid' : ''}`}
                          id="firstName"
                          aria-describedby="firstName"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="email"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.email ? 'is-invalid' : ''}`}
                          id="email"
                          aria-describedby="email"
                          placeholder="Email"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          value={values.organizationName}
                          name="organizationName"
                          onChange={(e) => {
                            handleChange(e)
                            setAddress(e.target.value)
                          }}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.organizationName ? 'is-invalid' : ''}`}
                          id="organizationName"
                          aria-describedby="organizationName"
                          placeholder="Organization Name"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          className="form-control form-textbox bg-secondary-light border-secondary-light"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.group}
                          name="group"
                          placeholder="Corporate"
                          disabled
                        />
                        {/* <select
                          className={`form-select form-textbox ${errors.group ? 'is-invalid' : ''}`}
                          aria-label="selectColumn"
                          onChange={handleChange}
                          placeholder="Group"
                          value={values.group}
                          name="group"
                        >
                          <option>Group</option>
                          {allUserGroup && allUserGroup.map((userGroup: any, index: any) => {
                            return (
                              <option key={index} value={userGroup.id}>
                                {userGroup.name}
                              </option>
                            );
                          })}
                        </select> */}
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          value={values.address}
                          name="address"
                          onChange={(e) => {
                            handleChange(e)
                            setOrgName(e.target.value)
                          }}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.address ? 'is-invalid' : ''}`}
                          id="address"
                          aria-describedby="address"
                          placeholder="Address"
                        />
                      </div>
                      <div className="mb-3">
                        <select
                          className="form-select form-textbox"
                          // ${errors.country ? 'is-invalid' : ''}
                          aria-label="selectColumn"
                          onChange={handleSelectCountry}
                          placeholder="Country"
                          name="country"
                          value={selectedCountry}
                        >
                          <option>Country</option>
                          {allCountries && allCountries.map((country: any, index: any) => {
                            return (
                              <option key={index} value={country.id}>
                                {country.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="mb-3">
                        <select
                          className="form-select form-textbox bg-secondary-light border-secondary-light"
                          // ${errors.state ? 'is-invalid' : ''}
                          aria-label="selectColumn"
                          onChange={handleChange}
                          placeholder="State"
                          name="state"
                          value={values.state}
                        >
                          <option>State</option>
                          {allStates && allStates.map((state: any, index: any) => {
                            return (
                              <option key={index} value={state.id}>
                                {state.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 ms-3">
                      <div className="mb-3">
                        <input
                          type="text"
                          value={values.lastName}
                          name="lastName"
                          onChange={handleChange}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.lastName ? 'is-invalid' : ''}`}
                          id="lastName"
                          aria-describedby="lastName"
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="number"
                          value={values.phoneNumber}
                          name="phoneNumber"
                          onChange={handleChange}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.phoneNumber ? 'is-invalid' : ''}`}
                          id="phoneNumber"
                          aria-describedby="phoneNumber"
                          placeholder="Mobile Number"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          value={values.linkedinRef}
                          name="linkedinRef"
                          onChange={handleChange}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.linkedinRef ? 'is-invalid' : ''}`}
                          id="linkedinRef"
                          aria-describedby="linkedinRef"
                          placeholder="Linkedin Ref"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          className="form-control form-textbox bg-secondary-light border-secondary-light"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.role}
                          name="role"
                          placeholder="Beta Test User"
                          disabled
                        />
                        {/* <select
                          className={`form-select form-textbox ${errors.role ? 'is-invalid' : ''}`}
                          aria-label="selectColumn"
                          onChange={handleChange}
                          placeholder="Role"
                          value={values.role}
                          name="role"
                        >
                          <option>Role</option>
                          {allUserRole && allUserRole.map((role: any, index: any) => {
                            return (
                              <option key={index} value={role.id}>
                                {role.name}
                              </option>
                            );
                          })}
                        </select> */}
                      </div>
                      <div className="mb-3">
                        <select
                          className={`form-select form-textbox ${errors.selectedPackage ? 'is-invalid' : ''}`}
                          aria-label="selectColumn"
                          onChange={handleChange}
                          placeholder="Package"
                          value={values.selectedPackage}
                          name="selectedPackage"
                        >
                          <option>Pricing Plan</option>
                          {allPackagesData && allPackagesData.map((packageDetail: any, index: any) => {
                            return (
                              <option key={index} value={packageDetail.id}>
                                {packageDetail.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <div className="mb-3">
                        <TextArea
                          autoSize
                          onChange={handleChange}
                          value={values.briefProfile}
                          name="briefProfile"
                          placeholder="Profile Description"
                          className={`form-control ${errors.briefProfile ? 'is-invalid' : ''} `}
                          style={{
                            borderRadius: "5px",
                            height: "37px !important",
                          }}
                        />
                      </div> */}
                      <div className="mb-3">
                        <input
                          className="form-control form-textbox"
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.city}
                          name="city"
                          placeholder="City"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          value={values.pinCode}
                          name="pinCode"
                          onChange={handleChange}
                          className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.pinCode ? 'is-invalid' : ''}`}
                          id="pinCode"
                          aria-describedby="pinCode"
                          placeholder="Remark"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div>
                      <input type="checkbox"
                        id="termsAndConditionsCheckbox"
                        name="termsAndConditionsCheckbox"
                        value={values.termsAndConditionsCheckbox}
                        onChange={handleChange}
                        required
                      // className={`form-check-input ${errors.termsAndConditionsCheckbox ? 'is-invalid' : ''}`}
                      />
                      <label htmlFor="termsAndConditionsCheckbox" className="ms-1">I agree to the {" "}
                        <Link to={`/terms-and-conditions?name=${name}&address=${address}&orgName=${orgName}`}
                          onClick={() => localStorage.setItem("routeTo", "terms-and-conditions")}
                          target="_blank"
                        >
                          Terms and Conditions.
                        </Link>
                      </label>
                      <div className="invalid-feedback">{errors.termsAndConditionsCheckbox}</div>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="privacyPolicyCheckbox"
                        name="privacyPolicyCheckbox"
                        value={values.privacyPolicyCheckbox}
                        onChange={handleChange}
                        required
                      // className={`form-check-input ${errors.privacyPolicyCheckbox ? 'is-invalid' : ''}`}
                      />
                      <label htmlFor="privacyPolicyCheckbox" className="ms-1">I have read and agree to the {" "}
                        <Link to={'/privacy-policy'}
                          onClick={() => localStorage.setItem("routeTo", "privacy-policy")}
                          target="_blank"
                        >
                          Privacy Policy.
                        </Link>
                      </label>
                      {/* <div className="invalid-feedback">{errors.privacyPolicyCheckbox}</div> */}
                    </div>
                  </div>
                  <div className="d-grid gap-2">
                    <button
                      type="submit"
                      className="btn btn-primary mt-3 mx-2 "
                    >
                      Sign Up
                    </button>
                  </div>
                  <p className="mt-3 text-center">
                    Already have an account?{" "}
                    <Link to={'/login'} onClick={handleClose}>
                      Click here to login
                    </Link>
                  </p>
                </div>

              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SignUp;