import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManagement, userManagementBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
export const packageFeatureMappingService = createApi({
    reducerPath: 'packageFeatureMappingService',
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ['Module'],
    endpoints: (builder) => ({
        getAllPackageFeatureMapping: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageFeatureMappingController.getAllPackageFeatureMapping}/${body.page}/${body.size}?orderBy=${body.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        getByPackageFeatureMappingId: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageFeatureMappingController.getByPackageFeatureMappingId}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                }
            }
        }),
        savePackageFeatureMapping: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageFeatureMappingController.savePackageFeatureMapping}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updatePackageFeatureMapping: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageFeatureMappingController.updatePackageFeatureMapping}/${body.payload.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deletePackageFeatureMapping: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageFeatureMappingController.deletePackageFeatureMapping}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllPackageFeatureMappingWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageFeatureMappingController.getAllPackageFeatureMappingWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        findAllPackageFeatureMapping: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.packageFeatureMappingController.findAllPackageFeatureMappings}`,
                    method: 'GET',
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
    })
});
export const {
    useGetAllPackageFeatureMappingMutation,
    useSavePackageFeatureMappingMutation,
    useUpdatePackageFeatureMappingMutation,
    useDeletePackageFeatureMappingMutation,
    useGetByPackageFeatureMappingIdMutation,
    useGetAllPackageFeatureMappingWithSearchMutation,
    useFindAllPackageFeatureMappingMutation
} = packageFeatureMappingService;