import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface DocumentMasterState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  allDocumentMaster: [] | null | any;
}
const initialState: DocumentMasterState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  allDocumentMaster: null
};
export const documentMasterSlice = createSlice({
  name: 'documentMaster',
  initialState,
  reducers: {
    setAllDocumentMaster: (state, action) => {
      state.allDocumentMaster = action.payload.data;
    },
    setDocumentMaster: (state, action) => {
      state.data = action.payload.data;
      state.totalRecords = action.payload.totalRecords;
      state.totalPages = action.payload.totalPage;
      state.currentPage = action.payload.currentPage;
    },
    setAllDocumentMasterBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.totalRecords;
      state.totalPages = action.payload.totalPage;
      state.currentPage = action.payload.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllDocumentMaster, setDocumentMaster, setAllDocumentMasterBySearch,reset } = documentMasterSlice.actions;
export const alldocumentMasterDetails = (state: RootState) => state.documentMaster.allDocumentMaster;
export const getAlldocumentMasterDetails = (state: RootState) => state.documentMaster.data;
export const getdocumentMasterTotalRecords=(state:RootState)=> state.documentMaster.totalRecords;
export const getAlldocumentMasterBySearch = (state: RootState) => state.documentMaster.data;
export default documentMasterSlice.reducer;