import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { REPORT_NAME, REPORT_TYPE } from "../../constant/reportType";
import "./reports.scss";
import { BiCheckCircle } from "react-icons/bi";
import { useSaveAuditLogMutation } from "../../redux-toolkit/services/auditLogService";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { USER_ROLE } from "../../constant/AppConstant";
import { documentManagementUrls } from "../../redux-toolkit/endpoints";

type Props = {};

const DocumentReports = (props: Props) => {
  const navigate = useNavigate();
  const selectedReportName: any = localStorage.getItem("selectedReportName");
  const tab: any = localStorage.getItem("tab");

  const [selectedReport, setSelectedReport]: any = useState(localStorage.getItem("selectedReport"));
  const startTime = new Date();
  const [saveAuditLog, saveAuditLogResponse] = useSaveAuditLogMutation();
  const localUserObj = getLocalStorageData("localUser");
  const paymentStatus = getLocalStorageData("paymentStatus");
  // const gdprFileName: any = useAppSelector(getGdprFileName);

  useEffect(() => {
    const selectedReportTab: any = localStorage.getItem("selectedReport");
    setSelectedReport(selectedReportTab);
    if (selectedReport &&
      (selectedReport !== REPORT_TYPE.DOCUMENT_MANAGEMENT)) {
      localStorage.removeItem("selectedReportName");
    }
  }, [selectedReport])

  const [reportTypes, setReportTypes] = useState([
    {
      id: 1,
      name: "Document Management",
      isActive:
        localStorage.getItem("selectedReport") ===
          REPORT_TYPE.DOCUMENT_MANAGEMENT
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "Document Management",
          reports: [
            {
              id: "1",
              name: "Section Master",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : false,
              route: "/documentReports/Section Master",
            },
            {
              id: "2",
              name: "Document Category",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : false,
              route: "/documentReports/Document Category",
            },
            {
              id: "3",
              name: "Document Master",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : false,
              route: "/documentReports/Document Master",
            },
            {
              id: "4",
              name: "Document Creation",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : false,
              route: "/documentReports/Document Creation",
            },
            {
              id: "5",
              name: "Parameter",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : false,
              route: "/documentReports/Parameter",
            },
            {
              id: "6",
              name: "Parameter Category",
              disabled: localUserObj.userType === USER_ROLE.ADMIN ? true : false,
              route: "/documentReports/Parameter Category",
            },
          ],
        },
      ],
    },
  ]);

  // Methods
  const handleReportTypeClick = (reportType: any) => {
    localStorage.setItem("selectedReport", reportType.name);

    let reportTypesCpy = [...reportTypes];

    reportTypesCpy.forEach((report, i) => {
      if (report.id === reportType.id) {
        report.isActive = true;
      } else {
        report.isActive = false;
      }
    });

    setReportTypes(reportTypesCpy);
  };

  return (
    <div className="cm-reports pb-4">
      {/* Inlet */}
      {/* <h4 className="title-d">Compliant Test</h4> */}
      {/* Content */}
      <div className="d-flex mt-4 bg-white rounded-3 overflow-x-auto shadow-sm">
        <div className="report-list bg-cm-primary d-flex flex-column my-5">
          {reportTypes.map((reportType, i) => (
            <button
              className="btn text-start ps-4 cm-btn border-0 p-3 text-white border-top rounded-0 border-white position-relative"
              onClick={() => {
                handleReportTypeClick(reportType);
              }}
              key={i}
            >
              <span>{reportType.name}</span>
              {reportType.isActive && (
                <div className="cm-active-polygon position-absolute"></div>
              )}
            </button>
          ))}
        </div>
        <div className="report-content  ms-4 container-fluid">
          {reportTypes.map(
            (reportItem, i) =>
              reportItem.isActive &&
              reportItem?.data?.map((report, ri) => {
                return (
                  <div className="my-4" key={Math.random()}>
                    <h5 className="fw-bold fs-4 text-cm-active-primary1 mb-3  ">
                      {report.name}
                    </h5>
                    {/* <div className="border-top  border-primary"></div> */}
                    <div className="row">
                      {report?.reports?.map((rep: any, ri: any) => (
                        <div
                          className="col-md-4 cursor-pointer"
                          key={ri}
                          onClick={() => {
                            if (rep.disabled && (paymentStatus || localUserObj.userType === USER_ROLE.ADMIN)) {
                              if (rep.name === REPORT_NAME.PARAMETER) {

                                const link = documentManagementUrls.parameter;
                                window.open(link , "_blank"); 

                              } else if (rep.name === REPORT_NAME.PARAMETER_CATEGORY) {

                                const link = documentManagementUrls.parameterCategory;
                                window.open(link , "_blank"); 

                              } else {
                                navigate(rep.route + `?report=${reportItem.name}`);
                                saveAuditLog({
                                  payload: {
                                    startTime: startTime.toISOString(),
                                    eventName: rep.route,
                                    url: rep.route,
                                    userId: localUserObj?.id
                                  }
                                })
                              }
                            }
                          }}
                        >
                          <ul className="fs-6">

                            {!rep.disabled ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="overlay-example" {...props}>
                                    If you want to access this please contact info@capitoltunnels.ai
                                  </Tooltip>
                                }
                              >
                                <li className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                  {" "}
                                  {rep.name}
                                </li>
                              </OverlayTrigger>
                            ) : (
                              (paymentStatus === true || localUserObj.userType === USER_ROLE.ADMIN ? (
                                <div className="d-flex">
                                  <li className={`text-cm-light-text  text-decoration-underline ${!rep.disabled ? 'disabled' : null}`}>
                                    {" "}
                                    {rep.name}
                                  </li>
                                  {selectedReportName && selectedReportName.includes(rep.name) ? (
                                    <p className="ms-2">
                                      <BiCheckCircle className="text-success" />
                                    </p>
                                  ) : null}
                                </div>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="overlay-example" {...props}>
                                      Please proceed to payment to avail this feature or contact info@capitoltunnels.ai
                                    </Tooltip>
                                  }
                                >
                                  <li className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                    {" "}
                                    {rep.name}
                                  </li>
                                </OverlayTrigger>
                              ))
                            )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentReports;
