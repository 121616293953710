import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { webUI, webUIBaseUrl } from "../endpoints";

// Define a service using a base URL and expected endpoints

export const webSearchService = createApi({
  reducerPath: "webUIService",
  baseQuery: fetchBaseQuery({ baseUrl: webUIBaseUrl }),
  tagTypes: ["webUIService"],
  endpoints: (builder) => ({
    getResultByTerms: builder.mutation({
      query(body) {
        return {
          url: `${webUI.webSearchController.getResultByTerms}`,
          method: "POST",
          //   headers: { Authorization: `Bearer ${body.token}` }
          body: body.payload,
        };
      },
    }),
    searchForCompany: builder.mutation({
      query(body) {
        return {
          url: `${webUI.webSearchController.searchForCompany}?industry=${body.payload.industry}
          &sub_industry=${body.payload.subIndustry}&country=${body.payload.country}&state=${body.payload.state}&city=${body.payload.city}`,
          method: "POST",
          body: body.payload.file,
        };
      },
    }),
    getDetailSearch: builder.mutation({
      query(body) {
        return {
          url: `${webUI.webSearchController.getDetailSearch}`,
          method: "POST",
          body: body.payload,
        };
      },
    }),
  }),
});

export const {
  useGetResultByTermsMutation,
  useSearchForCompanyMutation,
  useGetDetailSearchMutation
} = webSearchService;
