import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import "./auth.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import "./style.css";
import "../../assets/icons/mission.png";
import "../../assets/icons/vision.png";
import "../../assets/logo-blue.png";
import * as Yup from "yup";
import { useSaveUserMutation } from "../../redux-toolkit/services/allUserService";
import { Modal } from "react-bootstrap";
import Loading from "../../component/Loading";
import TextArea from "antd/es/input/TextArea";
import { Radio } from "antd";
import { useSaveMemberShipDetailMutation } from "../../redux-toolkit/services/faqsService";
import { setAllMemberShipDetail } from "../../redux-toolkit/slices/faqsSlice";
import { alertMessage } from "../../utils/AlertService";

type Props = {
    setShow?: any;
    show?: any;
};

const MembershipForm = (props: Props) => {
    // validation...........
    const SignupSchema = Yup.object().shape({
        industryName: Yup.string().required("Please enter Industry name!"),
        subIndustryName: Yup.string().required("Please enter Last name!"),
        websiteURL: Yup.string()
            .required('Field is required'),
        // .matches(
        //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        //     'Phone number is not valid'
        // )
        // .min(10, 'Contact Number must be 10 digits')
        // .max(10, 'Contact Number must be 10 digits')
        // .trim(),

        yearOfIncorporation: Yup.string()
            .required("Please enter Description!"),

        domainOfCompany: Yup.string().required("Please enter Domain of organization Address!"),
        noOfEmployees: Yup.string().required("Please select no. of employees to proceed!"),

        //    .min(10, "Description is Too Short!")
        //     .max(100, "Description Too Long!"),
        //To do uncomment these below 2 validation if showing role and group dropodown instead of input field
        // group: Yup.string().required("Please select group"),
        // role: Yup.string().required("Please select role"),

        // termsAndConditionsCheckbox: Yup.string().required("Please agree to the Terms and Conditions"),
        // privacyPolicyCheckbox: Yup.string().required("Please agree to the Privacy Policy"),
    });

    //selector

    //Mutation
    const [saveMemberShipDetail, saveMemberShipDetailResponse] = useSaveMemberShipDetailMutation();

    const noOfEmployees = [
        {
            id: 1,
            title: "1-10",
        },
        {
            id: 2,
            title: "11-50",
        },
        {
            id: 3,
            title: "51-100",
        },
        {
            id: 4,
            title: "101-150",
        },
        {
            id: 5,
            title: "151-200",
        },
        {
            id: 6,
            title: "Above 200",
        },
    ]

    const interestAreas = [
        {
            id: 1,
            title: "1. Connect with a potential investor"
        },
        {
            id: 2,
            title: "2. Connect with potential technology partner"
        },
        {
            id: 3,
            title: "3. Connect with prospective partner for promotion and presence in US"
        },
        {
            id: 4,
            title: "4. Connect with a mentor for guidance on business development in US"
        },
        {
            id: 5,
            title: "Other"
        },
    ]

    //states
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedCountry, setSelectedCountry]: any = useState();
    const [showNext, setShowNext] = useState(false);

    const handleSubmit = async (values: any) => {
        console.log(values)
        const payload = {
            domainOfCompany: values.domainOfCompany,
            email: values.email,
            id: 0,
            industryName: values.industryName,
            interestAreas: values.interestAreas,
            keyPeople: values.keyPeople,
            keyProducts: values.keyProducts,
            noOfEmployees: values.noOfEmployees,
            subIndustryName: values.subIndustryName,
            turnOver: values.turnOver,
            usaPresenceDesc: values.usaPresenceDesc,
            websiteURL: values.websiteURL,
            yearOfIncorporation: values.yearOfIncorporation
        }

        saveMemberShipDetail({
            payload: payload
        })
    };

    const handleClose = () => props.setShow(false);

    const validateForm = (values: any) => {
        const errors: any = {};
        Object.keys(values).forEach((key) => {
            if (!values[key]) {
                errors[key] = 'This field is required';
            }
        });
        return errors;
    };

    //saveMemberShipDetail.......
    useEffect(() => {
        if (saveMemberShipDetailResponse.isSuccess) {
            // dispatch(setAllMemberShipDetail(saveMemberShipDetailResponse));
            handleClose();
        } else if (saveMemberShipDetailResponse.isError) {
            let response: any = saveMemberShipDetailResponse.error;
            alertMessage(response, "error");
        }
    }, [saveMemberShipDetailResponse.isSuccess, saveMemberShipDetailResponse.isError]);

    return (
        <>
            {saveMemberShipDetailResponse.isLoading ? <Loading /> : ""}
            <Modal
                aria-labelledby="signup-modal"
                show={props.show}
                onHide={handleClose}
                className="login"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <p className="text-align-center text-cm-primary1 fw-bolder fs-4 mb-0">Company  Information Form</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Formik
                        initialValues={{
                            email: "",
                            industryName: "",
                            subIndustryName: "",
                            websiteURL: "",
                            yearOfIncorporation: "",
                            keyPeople: "",
                            domainOfCompany: "",
                            noOfEmployees: "",
                            turnOver: "",
                            keyProducts: "",
                            usaPresenceDesc: "",
                            interestAreas: "",
                        }}
                        // validationSchema={SignupSchema}
                        onSubmit={handleSubmit}
                        validate={validateForm}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                {showNext ? (
                                    <div className="p-2">
                                        <p>
                                            <button
                                                type="button"
                                                className="btn btn-primary mb-2 w-100 text-start"

                                            >
                                                Kindly Note:
                                            </button>
                                            <p className="fs-6 mt-1">
                                                This information sheet is only to help us identify potential members, who will be shortlisted as per the requirements of the project agenda.
                                            </p>
                                            <p className="fs-6 mt-1">
                                                By filling this form, no individual, Company, Non profit etc can claim automatic membership with Capitol tunnels.ai
                                            </p>
                                            <p className="fs-6 mt-1">
                                                Once any individual, Company, non-profit etc is shortlisted to become a member, a fee as per the below schedule needs to be paid in favour of Capitol tunnels.ai. The options for fee and the bank details are given  in the annexure.
                                            </p>
                                            <p className="fs-6 mt-1">
                                                Once the fee is paid, a formal agreement will be signed off between Capitol tunnels.ai and the proposed member which will in detail  explain the goals of the non profit, membership advantages, schedule of payments with goals etc.
                                            </p>
                                        </p>
                                        <div className="d-flex justify-content-end">
                                            <button
                                                type="button"
                                                className="btn btn-primary mt-3 mx-2 "
                                                onClick={() => setShowNext(false)}
                                            >
                                                Back
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary mt-3 mx-2 "
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="p-2">
                                        <div className="mb-3">
                                            Email
                                            <input
                                                type="email"
                                                value={values.email}
                                                name="email"
                                                onChange={handleChange}
                                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.email ? 'is-invalid' : ''}`}
                                                id="email"
                                                aria-describedby="email"
                                                placeholder="Your email"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            Name of your Industry/Organization
                                            <input
                                                type="text"
                                                value={values.industryName}
                                                name="industryName"
                                                onChange={handleChange}
                                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.industryName ? 'is-invalid' : ''}`}
                                                placeholder="Your answer"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            Sub Industry Name
                                            <input
                                                type="text"
                                                value={values.subIndustryName}
                                                name="subIndustryName"
                                                onChange={handleChange}
                                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.subIndustryName ? 'is-invalid' : ''}`}
                                                placeholder="Your answer"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            Website URL
                                            <input
                                                type="text"
                                                value={values.websiteURL}
                                                name="websiteURL"
                                                onChange={handleChange}
                                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.subIndustryName ? 'is-invalid' : ''}`}
                                                placeholder="Your answer"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            Year Of Incorporation
                                            <input
                                                type="date"
                                                value={values.yearOfIncorporation}
                                                name="yearOfIncorporation"
                                                onChange={handleChange}
                                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.subIndustryName ? 'is-invalid' : ''}`}
                                                placeholder="Your answer"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            Key people in your organization:
                                            <input
                                                type="text"
                                                value={values.keyPeople}
                                                name="keyPeople"
                                                onChange={handleChange}
                                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.subIndustryName ? 'is-invalid' : ''}`}
                                                placeholder="Your answer"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            Domain of your company
                                            <input
                                                type="text"
                                                value={values.domainOfCompany}
                                                name="domainOfCompany"
                                                onChange={handleChange}
                                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.subIndustryName ? 'is-invalid' : ''}`}
                                                placeholder="Your answer"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            Number of employees in your organization
                                            <select
                                                className={`form-select form-textbox ${errors.noOfEmployees ? 'is-invalid' : ''}`}
                                                aria-label="selectColumn"
                                                onChange={handleChange}
                                                placeholder="Choose"
                                                value={values.noOfEmployees}
                                                name="noOfEmployees"
                                                required
                                            >
                                                <option disabled>Choose</option>
                                                {noOfEmployees && noOfEmployees.map((desc: any, index: any) => {
                                                    return (
                                                        <option key={index} value={desc.title}>
                                                            {desc.title}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            Turnover of your company of last three years
                                            <input
                                                type="text"
                                                value={values.turnOver}
                                                name="turnOver"
                                                onChange={handleChange}
                                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.subIndustryName ? 'is-invalid' : ''}`}
                                                placeholder="Your answer"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            Key products  / activities of your company
                                            <TextArea
                                                size='small'
                                                onChange={handleChange}
                                                value={values.keyProducts}
                                                name="keyProducts"
                                                placeholder="Your answer"
                                                className={`form-control ${errors.keyProducts ? 'is-invalid' : ''} `}
                                                style={{
                                                    borderRadius: "5px",
                                                    height: "37px !important",
                                                }}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            Do you already have presence in US? If yes, please describe
                                            <TextArea
                                                size='small'
                                                onChange={handleChange}
                                                value={values.usaPresenceDesc}
                                                name="usaPresenceDesc"
                                                placeholder="Your answer"
                                                className={`form-control ${errors.usaPresenceDesc ? 'is-invalid' : ''} `}
                                                style={{
                                                    borderRadius: "5px",
                                                    height: "37px !important",
                                                }}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            What are your interest areas:
                                            <Radio.Group
                                                onChange={handleChange}
                                                value={values.interestAreas}
                                                className="mt-1 px-2"
                                                name="interestAreas"
                                            // required
                                            >
                                                {interestAreas.map((interest: any) => {
                                                    return (
                                                        <Radio value={interest.title} key={interest.id} className="">{interest.title}</Radio>
                                                    )
                                                })}
                                            </Radio.Group>
                                        </div>
                                        {/* {Object.keys(errors).length !== 0 && ( */}
                                        <div className="d-grid gap-2">
                                            <button
                                                type="button"
                                                className={`btn mt-3 mx-2 ${Object.keys(errors).length == 0 ? 'btn-primary' : 'btn-secondary'}`}
                                                onClick={() => {
                                                    if (Object.keys(errors).length == 0) {
                                                        setShowNext(true)
                                                    }
                                                }}
                                                disabled={Object.keys(errors).length !== 0}
                                            >
                                                Next
                                            </button>
                                        </div>
                                        {/* )} */}
                                        {/* <div className="d-grid gap-2">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mt-3 mx-2 "
                                        >
                                            Submit
                                        </button>
                                    </div> */}
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default MembershipForm;