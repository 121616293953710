import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { RadioChangeEvent, Select, Switch, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import { ACTION_TYPE, REPORT_NAME } from "../constant/reportType";
import { getLocalStorageData } from "../utils/LocalStorageService";
import {
  FEATURE_COLUMN,
  PACKAGE_COLUMN,
  PACKAGE_FEATURE_MAPPING_COLUMN,
  PRICING_COLUMN,
  SUB_MODULE_COLUMN,
  VALUE_CHAIN_COLUMN,
} from "../constant/AppConstant";
import { BsSearch } from "react-icons/bs";
import editIcon from "../assets/icons/editicon.svg";

import {
  useDeleteValueChainCategoryByIdMutation,
} from "../redux-toolkit/services/allValueChainCategoriesService";
import { alertMessage } from "../utils/AlertService";
import {
  useGetAllValueChainParagraphExtractWithPaginationMutation,
} from "../redux-toolkit/services/allValueChainParagraphExtractService";
import PricingModuleData from "../pages/reports/PricingModuleData";
import { useDeletePackageMutation, useGetAllPackagesMutation, useGetAllPackagesWithSearchMutation } from "../redux-toolkit/services/packageMasterService";
import { getAllPackagesDetails, getAllPakcagesBySearch, getPackagesTotalRecords, setAllPackagesBySearch, setPackageMaster } from "../redux-toolkit/slices/packageMasterSlice";
import { useDeleteModuleMutation, useGetAllModulesMutation, useGetAllModulesWithSearchMutation } from "../redux-toolkit/services/moduleService";
import { useDeleteSubModuleMutation, useGetAllSubModulesMutation, useGetAllSubModulesWithSearchMutation } from "../redux-toolkit/services/subModuleService";
import { useDeleteFeatureMutation, useGetAllFeaturesMutation, useGetAllFeaturesWithSearchMutation } from "../redux-toolkit/services/featureService";
import { getAllModuleDetails, getAllModulesBySearch, getModuleTotalRecords, setAllModulesBySearch, setModule } from "../redux-toolkit/slices/moduleSlice";
import { getAllSubModuleDetails, getAllSubModulesBySearchDetails, getSubModuleTotalRecords, setAllSubModulesBySearch, setSubModule } from "../redux-toolkit/slices/subModuleSlice";
import { getAllFeatureBySearchDetails, getAllFeatureDetails, getFeatureTotalRecords, setAllFeaturesBySearch, setFeature } from "../redux-toolkit/slices/featureSlice";
import Loading from "./Loading";
import { useDeletePackageFeatureMappingMutation, useGetAllPackageFeatureMappingMutation, useGetAllPackageFeatureMappingWithSearchMutation } from "../redux-toolkit/services/packageFeatureMappingService";
import { getAllPackageFeatureMappingBySearch, getAllPackageFeatureMappingDetails, getPackageFeatureMappingTotalRecords, setAllPackageFeatureMappingBySearch, setPackageFeatureMapping } from "../redux-toolkit/slices/packageFeatureMappingSlice";

const { Option } = Select;

type Props = {};
const PricingModule = (props: Props) => {

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token: any = getLocalStorageData("localUser").token;

  //states
  const [currentpage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [reportType, setReportType] = useState(params.reportType) as any;
  const [reportName, setReportName]: any = useState();
  const [showAdd, setShowAdd] = useState(true);
  const [call, setCall] = useState("N");

  const [searchValue, setSearchValue] = useState(1);

  //columns
  const [columData, setColumnData]: any = useState();
  const [packageColumn, setPackageColumn]: any = useState(PACKAGE_COLUMN);

  //datasource
  const [dataSource, setDatasource]: any = useState([]);

  //states
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [add, setAdd] = useState(false);
  const [show, setShow] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState([]);

  //get mutation  
  const [getAllPackages, packagesResponse] = useGetAllPackagesMutation();
  const [getAllModules, modulesResponse] = useGetAllModulesMutation();
  const [getAllSubModules, subModulesResponse] = useGetAllSubModulesMutation();
  const [getAllFeatures, featuresResponse] = useGetAllFeaturesMutation();
  const [getAllPackageFeatureMapping, packageFeatureMappingResponse] = useGetAllPackageFeatureMappingMutation();


  //delete mutation
  const [deletePackage, deletePackageResponse] = useDeletePackageMutation();
  const [deleteModule, deleteModuleResponse] = useDeleteModuleMutation();
  const [deleteSubModule, deleteSubModuleResponse] = useDeleteSubModuleMutation();
  const [deleteFeature, deleteFeatureResponse] = useDeleteFeatureMutation();
  const [deletePackageFeatureMapping, deletePackageFeatureMappingResponse] = useDeletePackageFeatureMappingMutation();

  //search
  const [getAllPackagesWithSearch, getAllPackagesWithSearchResponse] = useGetAllPackagesWithSearchMutation();
  const [getAllModulesWithSearch, getAllModulesWithSearchResponse] = useGetAllModulesWithSearchMutation();
  const [getAllSubModulesWithSearch, getAllSubModulesWithSearchResponse] = useGetAllSubModulesWithSearchMutation();
  const [getAllFeaturesWithSearch, getAllFeaturesWithSearchResponse] = useGetAllFeaturesWithSearchMutation();
  const [getAllPackageFeatureMappingWithSearch, getAllPackageFeatureMappingWithSearchResponse] = useGetAllPackageFeatureMappingWithSearchMutation();

  //selector
  const allPackagesData: any = useAppSelector(getAllPackagesDetails);
  const allModulesData: any = useAppSelector(getAllModuleDetails);
  const allSubModulesData: any = useAppSelector(getAllSubModuleDetails);
  const allFeaturesData: any = useAppSelector(getAllFeatureDetails);
  const allPackagesSearchData: any = useAppSelector(getAllPakcagesBySearch);
  const allModulesSearchData: any = useAppSelector(getAllModulesBySearch);
  const allSubModulesSearchData: any = useAppSelector(getAllSubModulesBySearchDetails);
  const allFeaturesSearchData: any = useAppSelector(getAllFeatureBySearchDetails);
  const allPackageFeatureMappingData: any = useAppSelector(getAllPackageFeatureMappingDetails);
  const allPackageFeatureMappingSearchData: any = useAppSelector(getAllPackageFeatureMappingBySearch);

  //totalRecords
  const totalPackages: any = useAppSelector(getPackagesTotalRecords);
  const totalModules: any = useAppSelector(getModuleTotalRecords);
  const totalSubModules: any = useAppSelector(getSubModuleTotalRecords);
  const totalFeatures: any = useAppSelector(getFeatureTotalRecords);
  const totalPackageFeatureMappings: any = useAppSelector(getPackageFeatureMappingTotalRecords);

  useEffect(() => {
    if (params.reportType === REPORT_NAME.PACKAGE) {
      setReportName(REPORT_NAME.PACKAGE);
      getAllPackages({
        token: token,
        size: pageSize,
        page: currentpage - 1,
        orderBy: "asc"
      });
    } else if (params.reportType === REPORT_NAME.MODULE) {
      setReportName(REPORT_NAME.MODULE);
      getAllModules({
        token: token,
        size: pageSize,
        page: currentpage - 1,
        orderBy: "asc"
      });
    } else if (params.reportType === REPORT_NAME.SUB_MODULE) {
      setReportName(REPORT_NAME.SUB_MODULE);
      getAllSubModules({
        token: token,
        size: pageSize,
        page: currentpage - 1,
        orderBy: "asc"
      });
    } else if (params.reportType === REPORT_NAME.FEATURE) {
      setReportName(REPORT_NAME.FEATURE)
      getAllFeatures({
        token: token,
        size: pageSize,
        page: currentpage - 1,
        orderBy: "asc"
      });
    } else if (params.reportType === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
      setReportName(REPORT_NAME.PACKAGE_FEATURE_MAPPING)
      getAllPackageFeatureMapping({
        token: token,
        size: pageSize,
        page: currentpage - 1,
        orderBy: "asc"
      });
    }
  }, []);

  useEffect(() => {
    if (call === "Y") {
      if (params.reportType === REPORT_NAME.PACKAGE) {
        setReportName(REPORT_NAME.PACKAGE);
        getAllPackages({
          token: token,
          size: pageSize,
          page: currentpage - 1,
          orderBy: "asc"
        });
        setCall("N");
      } else if (params.reportType === REPORT_NAME.MODULE) {
        setReportName(REPORT_NAME.MODULE);
        getAllModules({
          token: token,
          size: pageSize,
          page: currentpage - 1,
          orderBy: "asc"
        })
        setCall("N");
      } else if (params.reportType === REPORT_NAME.SUB_MODULE) {
        setReportName(REPORT_NAME.SUB_MODULE);
        getAllSubModules({
          token: token,
          size: pageSize,
          page: currentpage - 1,
          orderBy: "asc"
        });
        setCall("N");
      } else if (params.reportType === REPORT_NAME.FEATURE) {
        setReportName(REPORT_NAME.FEATURE);
        getAllFeatures({
          token: token,
          size: pageSize,
          page: currentpage - 1,
          orderBy: "asc"
        });
        setCall("N");
      } else if (params.reportType === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
        setReportName(REPORT_NAME.PACKAGE_FEATURE_MAPPING)
        getAllPackageFeatureMapping({
          token: token,
          size: pageSize,
          page: currentpage - 1,
          orderBy: "asc"
        });
        setCall("N");
      }
    }
  }, [call]);


  //get allpackages.......
  useEffect(() => {
    if (packagesResponse.isSuccess) {
      dispatch(setPackageMaster(packagesResponse));
    } else if (packagesResponse.isError) {
      alertMessage("Error while fetching packages", "error");
    }
  }, [packagesResponse.isSuccess, packagesResponse.isError]);

  //get allmodules.......
  useEffect(() => {
    if (modulesResponse.isSuccess) {
      dispatch(setModule(modulesResponse));
    } else if (modulesResponse.isError) {
      alertMessage("Error while fetching modules", "error");
    }
  }, [modulesResponse.isSuccess, modulesResponse.isError]);

  //get allsubmodules.......
  useEffect(() => {
    if (subModulesResponse.isSuccess) {
      dispatch(setSubModule(subModulesResponse));
    } else if (subModulesResponse.isError) {
      alertMessage("Error while fetching sub modules", "error");
    }
  }, [subModulesResponse.isSuccess, subModulesResponse.isError]);

  //get allfeatures.......
  useEffect(() => {
    if (featuresResponse.isSuccess) {
      dispatch(setFeature(featuresResponse));
    } else if (featuresResponse.isError) {
      alertMessage("Error while fetching features", "error");
    }
  }, [featuresResponse.isSuccess, featuresResponse.isError]);

  //get allPackageFeatureMapping.......
  useEffect(() => {
    if (packageFeatureMappingResponse.isSuccess) {
      dispatch(setPackageFeatureMapping(packageFeatureMappingResponse));
    } else if (packageFeatureMappingResponse.isError) {
      alertMessage("Error while fetching package feature mapping", "error");
    }
  }, [packageFeatureMappingResponse.isSuccess, packageFeatureMappingResponse.isError]);

  //Set DataSource useEffects

  useEffect(() => {
    if (allPackagesData && reportName === REPORT_NAME.PACKAGE) {
      setDatasource(allPackagesData);
    }
  }, [allPackagesData]);

  useEffect(() => {
    if (allModulesData && reportName === REPORT_NAME.MODULE) {
      setDatasource(allModulesData);
    }
  }, [allModulesData]);

  useEffect(() => {
    if (allSubModulesData && reportName === REPORT_NAME.SUB_MODULE) {
      setDatasource(allSubModulesData);
    }
  }, [allSubModulesData, reportName]);

  useEffect(() => {
    if (allFeaturesData && reportName === REPORT_NAME.FEATURE) {
      setDatasource(allFeaturesData);
    }
  }, [allFeaturesData]);

  useEffect(() => {
    if (allPackageFeatureMappingData && reportName === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
      setDatasource(allPackageFeatureMappingData);
    }
  }, [allPackageFeatureMappingData]);


  //delete..........
  const onDeleteData = (record: any) => {
    if (reportName === REPORT_NAME.PACKAGE) {
      deletePackage({
        id: record.id,
        token: token,
      });
    } else if (reportName === REPORT_NAME.MODULE) {
      deleteModule({
        id: record.id,
        token: token,
      });
    } else if (reportName === REPORT_NAME.SUB_MODULE) {
      deleteSubModule({
        id: record.id,
        token: token,
      });
    } else if (reportName === REPORT_NAME.FEATURE) {
      deleteFeature({
        id: record.id,
        token: token,
      });
    } else if (reportName === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
      deletePackageFeatureMapping({
        id: record.id,
        token: token,
      });
    }
  };

  //Delete response useEffects

  useEffect(() => {
    if (deletePackageResponse.isSuccess) {
      alertMessage("Package updated successfully", "success");
      setCall("Y");
    } else if (deletePackageResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deletePackageResponse.isSuccess, deletePackageResponse.isError]);

  useEffect(() => {
    if (deleteModuleResponse.isSuccess) {
      alertMessage("Module updated successfully", "success");
      setCall("Y");
    } else if (deleteModuleResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteModuleResponse.isSuccess, deleteModuleResponse.isError]);


  useEffect(() => {
    if (deleteSubModuleResponse.isSuccess) {
      alertMessage("Sub module updated successfully", "success");
      setCall("Y");
    } else if (deleteSubModuleResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteSubModuleResponse.isSuccess, deleteSubModuleResponse.isError]);


  useEffect(() => {
    if (deleteFeatureResponse.isSuccess) {
      alertMessage("Feature updated successfully", "success");
      setCall("Y");
    } else if (deleteFeatureResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteFeatureResponse.isSuccess, deleteFeatureResponse.isError]);

  useEffect(() => {
    if (deletePackageFeatureMappingResponse.isSuccess) {
      alertMessage("Package Feature Mapping updated successfully", "success");
      setCall("Y");
    } else if (deletePackageFeatureMappingResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deletePackageFeatureMappingResponse.isSuccess, deletePackageFeatureMappingResponse.isError]);

  //logic to add action column in table
  useEffect(() => {
    const temp: any = [];
    const tempAction: any = {
      title: "Action",
      dataIndex: "action",
      key: "name",
      fixed: "right",
      width: 120,
      render: (index: any, record: any) => {
        return (
          // for delete switch button
          <div className="d-flex">
            <div className="px-2 pe-auto">
              <Switch
                checked={record.status === true ? true : false}
                onClick={() => {
                  onDeleteData(record);
                }}
              />
            </div>
            {record.status === true ? (
              <div
                className="px-2 pe-auto"
                onClick={() => {
                  handleOpenForm(record);
                }}
              >
                <img src={editIcon} alt="update" />
              </div>
            ) : null}
          </div>
        );
      },
    };

    if (reportName === REPORT_NAME.PACKAGE && packageColumn) {
      packageColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setColumnData(temp);
      setPackageColumn(temp);
    } else if (reportName === REPORT_NAME.MODULE && PRICING_COLUMN) {
      PRICING_COLUMN.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setColumnData(temp);
    } else if (reportName === REPORT_NAME.SUB_MODULE && SUB_MODULE_COLUMN) {
      SUB_MODULE_COLUMN.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setColumnData(temp);
    } else if (reportName === REPORT_NAME.FEATURE && FEATURE_COLUMN) {
      FEATURE_COLUMN.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setColumnData(temp);
    } else if (reportName === REPORT_NAME.PACKAGE_FEATURE_MAPPING && PACKAGE_FEATURE_MAPPING_COLUMN) {
      PACKAGE_FEATURE_MAPPING_COLUMN.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setColumnData(temp);
    }
  }, [reportName]);

  const paginationChange = (page: number, pageSize: number) => {
    if (params.reportType === REPORT_NAME.PACKAGE) {
      getAllPackages({
        token: token,
        page: page - 1,
        size: pageSize,
        orderBy: "asc"
      });
    } else if (params.reportType === REPORT_NAME.MODULE) {
      getAllModules({
        token: token,
        page: page - 1,
        size: pageSize,
        orderBy: "asc"
      });
    } else if (params.reportType === REPORT_NAME.SUB_MODULE) {
      getAllSubModules({
        token: token,
        page: page - 1,
        size: pageSize,
        orderBy: "asc"
      });
    } else if (params.reportType === REPORT_NAME.FEATURE) {
      getAllFeatures({
        token: token,
        page: page - 1,
        size: pageSize,
        orderBy: "asc"
      });
    } else if (params.reportType === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
      getAllPackageFeatureMapping({
        token: token,
        page: page - 1,
        size: pageSize,
        orderBy: "asc"
      });
    }
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // .....................
  const handleAdd = () => {
    setAdd(true);
  };

  const handleShow = () => setShow(true);
  const handleViewGraph = () => setShowGraph(true);

  const handleOpenForm = (record: any) => {
    setShow(true);
    setActionType(ACTION_TYPE.UPDATE_ACTION);
    setSelectedRow(record);
  };

  // navigate............
  const handleBack = () => {
    navigate("/reports");
  };

  const handleSearch = (event: any) => {
    // if ((event.target.value).length >= 3) {
    if (reportName === REPORT_NAME.PACKAGE) {
      getAllPackagesWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === REPORT_NAME.MODULE) {
      getAllModulesWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === REPORT_NAME.SUB_MODULE) {
      getAllSubModulesWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === REPORT_NAME.FEATURE) {
      getAllFeaturesWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    } else if (reportName === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
      getAllPackageFeatureMappingWithSearch({
        token: token,
        payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
      });
    }
    // }
  };

  //search response useEffects

  useEffect(() => {
    if (getAllPackagesWithSearchResponse.isSuccess) {
      dispatch(setAllPackagesBySearch(getAllPackagesWithSearchResponse));
    } else if (getAllPackagesWithSearchResponse.isError) {
      let response: any = getAllPackagesWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllPackagesWithSearchResponse.isSuccess, getAllPackagesWithSearchResponse.isError]);

  useEffect(() => {
    if (getAllModulesWithSearchResponse.isSuccess) {
      dispatch(setAllModulesBySearch(getAllModulesWithSearchResponse));
    } else if (getAllModulesWithSearchResponse.isError) {
      let response: any = getAllModulesWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllModulesWithSearchResponse.isSuccess, getAllModulesWithSearchResponse.isError]);

  useEffect(() => {
    if (getAllSubModulesWithSearchResponse.isSuccess) {
      dispatch(setAllSubModulesBySearch(getAllSubModulesWithSearchResponse));
    } else if (getAllSubModulesWithSearchResponse.isError) {
      let response: any = getAllSubModulesWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllSubModulesWithSearchResponse.isSuccess, getAllSubModulesWithSearchResponse.isError]);

  useEffect(() => {
    if (getAllFeaturesWithSearchResponse.isSuccess) {
      dispatch(setAllFeaturesBySearch(getAllFeaturesWithSearchResponse));
    } else if (getAllFeaturesWithSearchResponse.isError) {
      let response: any = getAllFeaturesWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllFeaturesWithSearchResponse.isSuccess, getAllFeaturesWithSearchResponse.isError]);

  useEffect(() => {
    if (getAllPackageFeatureMappingWithSearchResponse.isSuccess) {
      dispatch(setAllPackageFeatureMappingBySearch(getAllPackageFeatureMappingWithSearchResponse));
    } else if (getAllPackageFeatureMappingWithSearchResponse.isError) {
      let response: any = getAllPackageFeatureMappingWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllPackageFeatureMappingWithSearchResponse.isSuccess, getAllPackageFeatureMappingWithSearchResponse.isError]);

  useEffect(() => {
    if (allPackagesSearchData && reportName === REPORT_NAME.PACKAGE) {
      setDatasource(allPackagesSearchData);
    }
  }, [allPackagesSearchData]);

  useEffect(() => {
    if (allModulesSearchData && reportName === REPORT_NAME.MODULE) {
      setDatasource(allModulesSearchData);
    }
  }, [allModulesSearchData]);

  useEffect(() => {
    if (allSubModulesSearchData && reportName === REPORT_NAME.SUB_MODULE) {
      // buildSubModuleDataSource(allSubModulesSearchData);
      setDatasource(allSubModulesSearchData);
    }
  }, [allSubModulesSearchData]);

  useEffect(() => {
    if (allFeaturesSearchData && reportName === REPORT_NAME.FEATURE) {
      setDatasource(allFeaturesSearchData);
    }
  }, [allFeaturesSearchData]);

  useEffect(() => {
    if (allPackageFeatureMappingSearchData && reportName === REPORT_NAME.PACKAGE_FEATURE_MAPPING) {
      setDatasource(allPackageFeatureMappingSearchData);
    }
  }, [allPackageFeatureMappingSearchData]);

  const onSearchChange = (e: RadioChangeEvent) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div className="d-flex flex-column">
            <div className="input-group px-2 mt-1">
              <Form.Control
                type="search"
                placeholder="Search"
                className="border-0 bg-cm-light-primary1"
                aria-label="Search"
                onChange={handleSearch}
              />
              <div className="input-group-append bg-cm-light-primary1 p-1 rounded-end">
                <span className="input-group-text bg-cm-light-primary1 border-0">
                  {/* <img src={gear} alt="" /> */}
                  <BsSearch className=" bg-cm-light-primary1 border-cm-light-primary1" />
                </span>
              </div>
            </div>
          </div>
          {showAdd && (
            <div
              onClick={handleShow}
              className={`d-flex px-2 align-items-center mt-1`}
            >
              {/* <h6 className="m-0 px-0">
                  Schedule Reports
                  <AiOutlineClockCircle className="ms-2" />
              </h6> */}
              <div
                className="d-flex align-items-center px-3"
                onClick={handleAdd}
              >
                <Button
                  variant="primary"
                  className="d-flex align-items-center me-0 text-nowrap"
                  onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
                >
                  <BiPlus />
                  {reportName === "dataRecord" ? "Web Search" : "Add"}
                </Button>
              </div>
            </div>
          )}
          {show && (
            <PricingModuleData
              show={show}
              setShow={setShow}
              reportName={params.reportType}
              reportNameCode={reportName}
              data={selectedRow}
              actionType={actionType}
              setCall={setCall}
            />
          )}
        </div>
      </div>
      <div className="d-flex mb-2">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {reportName === "dataPipelineCategory" || reportName === "valueChainCategory" || reportName === "termSet" ? (
          <div className="primary mt-2 mt-md-0 d-flex justify-content-md-end w-100 mx-2">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleViewGraph}
            >
              View Graph
            </Button>
          </div>
        ) : null}
        {packagesResponse.isLoading ||
          modulesResponse.isLoading ||
          subModulesResponse.isLoading ||
          featuresResponse.isLoading ||
          deletePackageResponse.isLoading ||
          deleteModuleResponse.isLoading ||
          deleteSubModuleResponse.isLoading ||
          deleteFeatureResponse.isLoading ||
          deletePackageFeatureMappingResponse.isLoading ||
          packageFeatureMappingResponse.isLoading
          ? (
            <Loading />
          ) : (
            ""
          )}
      </div>

      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          rowKey={"id"}
          // scroll={{
          //   x: 1500,
          //   // y: 300
          // }}
          // pagination={{
          //   responsive: true,
          // }}
          pagination={
            reportName === REPORT_NAME.PACKAGE ||
              reportName === REPORT_NAME.MODULE ||
              reportName === REPORT_NAME.SUB_MODULE ||
              reportName === REPORT_NAME.FEATURE ||
              reportName === REPORT_NAME.PACKAGE_FEATURE_MAPPING
              ? {
                total:
                  reportName === REPORT_NAME.PACKAGE
                    ? totalPackages && totalPackages
                    : reportName === REPORT_NAME.MODULE
                      ? totalModules && totalModules
                      : reportName === REPORT_NAME.SUB_MODULE
                        ? totalSubModules && totalSubModules
                        : reportName === REPORT_NAME.FEATURE
                          ? totalFeatures && totalFeatures
                          : reportName === REPORT_NAME.PACKAGE_FEATURE_MAPPING
                            ? totalPackageFeatureMappings && totalPackageFeatureMappings
                            : "",
                responsive: true,
                current: currentpage,
                showSizeChanger: true,
                pageSizeOptions: [10, 20, 30],
                onChange: (page, pageSize) => {
                  paginationChange(page, pageSize);
                },
                pageSize: pageSize,
              }
              : {
                responsive: true,
              }
          }
          columns={columData
            // reportName === REPORT_NAME.PACKAGE
            //   ? packageColumn
            //   : ""
          }
          dataSource={dataSource
            // reportName === "valueChainCategory"
            //   ? valueChainCategoryDatasource
            //   : ""
          }
        />
      </div>
    </div>
  );
};
export default PricingModule;
