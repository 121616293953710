import React, { useEffect, useRef, useState } from "react";
import { BiPlus, BiSearch } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { RadioChangeEvent, Select, Switch, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import { ACTION_TYPE, REPORT_NAME } from "../constant/reportType";
import { DETAIL_ORGANIZATION_SEARCH_COLUMN, DETAIL_ORGANIZATION_SEARCH_COLUMN1, INDUSTRY_COLUMN, ORGANIZATION_SEARCH_COLUMN, ORGANIZATION_SEARCH_COLUMN1, SUB_INDUSTRY_COLUMN, TERM_COMPARISON_ANALYSIS_COLUMN } from "../constant/AppConstant";
import { BsDownload, BsEnvelope, BsSearch } from "react-icons/bs";
import editIcon from "../assets/icons/editicon.svg";
import Loading from "./Loading";
import { Excel } from "antd-table-saveas-excel";
import { useDeleteIndustryMutation, useGetAllIndustryMutation, useGetAllIndustryWithSearchMutation } from "../redux-toolkit/services/industryMasterService";
import { useDeleteSubIndustryMutation, useGetAllSubIndustryMutation, useGetAllSubIndustryWithSearchMutation } from "../redux-toolkit/services/subIndustryService";
import { getAllIndustryDetails, getIndustryBySearchDetails, getIndustryTotalRecords, setAllIndustry, setAllIndustryBySearch } from "../redux-toolkit/slices/industryMasterSlice";
import { getAllSubIndustryBySearch, getAllSubIndustryDetails, getSubIndustryTotalRecords, setAllSubIndustryBySearch, setSubIndustry } from "../redux-toolkit/slices/subIndustrySlice";
import { alertMessage } from "../utils/AlertService";
import MatchMakerManagementData from "../pages/reports/MatchMakerManagementData";
import { getDetailOrganizationSearch, getSearchCompanyData, setSearchCompany } from "../redux-toolkit/slices/webSearchSlice";
import "../pages/reports/reports.scss"
import { Formik } from "formik";
import { getLocalStorageData } from "../utils/LocalStorageService";
import { useSendEmailforExportMutation } from "../redux-toolkit/services/sendEmailService";
import { getTermComaprisonAnalysisDetails, getTermComaprisonAnalysisWithTermFileDetails } from "../redux-toolkit/slices/textMatcherSlice";
import TermComparisonSummaryReport from "../pages/reports/TermComparisonSummaryReport";
import BiasAndVulnerabiltySummary from "./BiasAndVulnerabiltySummary";

const { Option } = Select;

type Props = {};
const MatchMakerManagementMaster = (props: Props) => {
  // States
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localUserObj: any = getLocalStorageData("localUser");
  const frequencyInputRef: any = useRef(null);
  const token: any = getLocalStorageData("localUser").token;

  //states
  const [currentpage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [reportType, setReportType] = useState(params.reportType) as any;
  const [reportName, setReportName]: any = useState();
  const [call, setCall] = useState("N");
  const [showAdd, setShowAdd] = useState(true);
  const [searchValue, setSearchValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filteredTermComparison, setFilteredTermComparison]: any = useState([]);
  const [termPieGraphData, setTermPieGraphData]: any = useState();
  const [dataForGraph, setDataForGraph]: any = useState();
  const [showKeyWordGroupingBtn, setShowKeyWordGroupingBtn]: any = useState(false);
  const [showConsolidatedKeywrdGrp, setShowConsolidatedKeywrdGrp]: any = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [summReportName, setSummReportName]: any = useState("");
  const [fileName, setFileName]: any = useState();

  //columns
  const [columData, setColumnData]: any = useState();

  //datasource
  const [dataSource, setDatasource]: any = useState([]);
  const [filteredRecords, setFilteredRecords]: any = useState([]);
  const [termComparisonAnalysisDataSource, setTermComparisonAnalysisDataSource]: any = useState([]);

  //states
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [add, setAdd] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [show, setShow] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState([]);

  //get mutation
  const [getAllIndustry, industryResponse] = useGetAllIndustryMutation();
  const [getAllSubIndustry, subIndustryResponse] = useGetAllSubIndustryMutation();
  const [sendEmail, sendEmailResponse] = useSendEmailforExportMutation();

  //delete mutation
  const [deleteIndustry, deleteIndustryResponse] = useDeleteIndustryMutation();
  const [deleteSubIndustry, deleteSubIndustryResponse] = useDeleteSubIndustryMutation();

  //search
  const [getAllIndustryWithSearch, getAllIndustryWithSearchResponse] = useGetAllIndustryWithSearchMutation();
  const [getAllSubIndustryWithSearch, getAllSubIndustryWithSearchResponse] = useGetAllSubIndustryWithSearchMutation();

  //selector
  const allIndustryData: any = useAppSelector(getAllIndustryDetails);
  const allSubIndustryData: any = useAppSelector(getAllSubIndustryDetails);
  const allIndustryBySearchData: any = useAppSelector(getIndustryBySearchDetails);
  const allSubIndustryBySearchData: any = useAppSelector(getAllSubIndustryBySearch);
  const allSearchCompanyData: any = useAppSelector(getSearchCompanyData);
  const allDetailOrganizationSearchData: any = useAppSelector(getDetailOrganizationSearch);
  const getTermComaprisonAnalysisData: any = useAppSelector(getTermComaprisonAnalysisDetails);
  const getTermComaprisonAnalysisDatawithTermFile: any = useAppSelector(getTermComaprisonAnalysisWithTermFileDetails);

  //totalRecords
  const totalIndustryRecords: any = useAppSelector(getIndustryTotalRecords);
  const totalSubIndustryRecords: any = useAppSelector(getSubIndustryTotalRecords);

  useEffect(() => {
    if (params.reportType === REPORT_NAME.INDUSTRY) {
      setReportName(REPORT_NAME.INDUSTRY);
      setIsCategory(true);
      setShowAdd(true);
      getAllIndustry({
        page: currentpage - 1,
        size: pageSize,
        sort: "id",
      });
    } else if (params.reportType === REPORT_NAME.SUB_INDUSTRY) {
      setReportName(REPORT_NAME.SUB_INDUSTRY);
      getAllSubIndustry({
        size: pageSize,
        page: currentpage - 1,
        orderBy: "asc"
      });
    } else if (params.reportType === REPORT_NAME.ORGANIZATION_SEARCH) {
      setReportName(REPORT_NAME.ORGANIZATION_SEARCH);
    } else if (params.reportType === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) {
      setReportName(REPORT_NAME.DETAIL_ORGANIZATION_SEARCH);
    } else if (params.reportType === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
      setReportName(REPORT_NAME.TERM_COMPARISON_ANALYSIS);
    }
  }, []);

  useEffect(() => {
    if (call === "Y") {
      if (params.reportType === REPORT_NAME.INDUSTRY) {
        setReportName(REPORT_NAME.INDUSTRY);
        setIsCategory(true);
        getAllIndustry({
          page: currentpage - 1,
          size: pageSize,
          sort: "id",
        });
        setCall("N");
      } else if (params.reportType === REPORT_NAME.SUB_INDUSTRY) {
        setReportName(REPORT_NAME.SUB_INDUSTRY);
        setIsCategory(false);
        getAllSubIndustry({
          size: pageSize,
          page: currentpage - 1,
          orderBy: "asc"
        });
        setCall("N");
      }
    }
  }, [call]);

  useEffect(() => {
    if (getTermComaprisonAnalysisData) {
      let termComparisonAnalysisArr = Object.entries(getTermComaprisonAnalysisData);

      const resultArray = [];

      for (const item of termComparisonAnalysisArr) {
        const fileName = item[0];
        const topicsObject: any = item[1];

        for (const [keyTerm, frequency] of Object.entries(topicsObject)) {
          resultArray.push({
            fileName,
            keyTerm,
            frequency
          });
        }
      }

      let sortedArray: any = [];
      sortedArray = [...resultArray].sort(
        (a, b) => a.keyTerm > b.keyTerm ? 1 : -1
      );

      setTermComparisonAnalysisDataSource(sortedArray);
      setFilteredTermComparison(sortedArray)
    }
  }, [getTermComaprisonAnalysisData]);

  useEffect(() => {
    if (getTermComaprisonAnalysisDatawithTermFile) {
      const outputList = [];

      for (const fileName in getTermComaprisonAnalysisDatawithTermFile) {
        const fileData = getTermComaprisonAnalysisDatawithTermFile[fileName];

        for (const keyGroup in fileData) {
          const keyTerms = fileData[keyGroup];

          for (const keyTerm in keyTerms) {
            const frequency = keyTerms[keyTerm];

            const outputItem = {
              fileName,
              keyGroup,
              keyTerm,
              frequency
            };

            outputList.push(outputItem);
          }
        }
      }

      let sortedArray = [...outputList].sort((a, b) => {
        if (a.keyGroup !== b.keyGroup) {
          return a.keyGroup.localeCompare(b.keyGroup); // Sort by keyGroup first
        } else {
          return a.keyTerm.localeCompare(b.keyTerm); // If keyGroup is the same, then sort by keyTerm
        }
      });

      setTermComparisonAnalysisDataSource(sortedArray);
      setFilteredTermComparison(sortedArray);

      let termComparisonAnalysisArr = Object.entries(getTermComaprisonAnalysisDatawithTermFile);

      const resultArray = [];

      for (const item of termComparisonAnalysisArr) {
        const fileName = item[0];
        const topicsObject: any = item[1];

        for (const [keyGroup, frequency] of Object.entries(topicsObject)) {
          resultArray.push({
            fileName,
            keyGroup,
            frequency
          });
        }
      }

      // For Pie graph ----

      const pirGraphData = sortedArray.reduce((result: any, item: any) => {
        const existingItem = result.find((entry: any) => entry.fileName === item.fileName);

        if (existingItem) {
          const keyGroupIndex = existingItem.keyGroup.indexOf(item.keyGroup);
          if (keyGroupIndex !== -1) {
            existingItem.count[keyGroupIndex] += item.frequency;
          } else {
            existingItem.keyGroup.push(item.keyGroup);
            existingItem.count.push(item.frequency);
          }
        } else {
          result.push({
            fileName: item.fileName,
            keyGroup: [item.keyGroup],
            count: [item.frequency]
          });
        }

        return result;
      }, []);

      setTermPieGraphData(pirGraphData);
      // For Bar graph

      const dataForGraph: any = [];

      resultArray.forEach((entry: any) => {
        const existingEntry = dataForGraph.find((item: any) => item.fileName === entry.fileName && item.keyGroup === entry.keyGroup);

        if (existingEntry) {
          // Entry already exists, add new chartData
          existingEntry.chartData.push({
            name: "frequency", // You can customize this name based on your needs
            value: entry.frequency.frequency, // Assuming the property name is "frequency"
          });
        } else {
          // Entry doesn't exist, create a new entry
          const newEntry = {
            fileName: entry.fileName,
            keyGroup: entry.keyGroup,
            chartData: Object.entries(entry.frequency).map(([name, value]) => ({ name, value })),
          };

          dataForGraph.push(newEntry);
        }
      });

      setDataForGraph(dataForGraph);
      setShowGraph(true);

    }
  }, [getTermComaprisonAnalysisDatawithTermFile]);

  //get allindustry.......
  useEffect(() => {
    if (industryResponse.isSuccess) {
      dispatch(setAllIndustry(industryResponse));
    } else if (industryResponse.isError) {
      alertMessage("Error while fetching industry", "error");
    }
  }, [industryResponse.isSuccess, industryResponse.isError]);

  //get allsubindustry.......
  useEffect(() => {
    if (subIndustryResponse.isSuccess) {
      dispatch(setSubIndustry(subIndustryResponse));
    } else if (subIndustryResponse.isError) {
      alertMessage("Error while fetching sub industry", "error");
    }
  }, [subIndustryResponse.isSuccess, subIndustryResponse.isError]);

  // Set DataSource useEffects
  useEffect(() => {
    if (allIndustryData && reportName === REPORT_NAME.INDUSTRY) {
      setDatasource(allIndustryData);
      setFilteredRecords(allIndustryData);
    }
  }, [allIndustryData]);

  useEffect(() => {
    if (allSubIndustryData && reportName === REPORT_NAME.SUB_INDUSTRY) {
      setDatasource(allSubIndustryData);
      setFilteredRecords(allSubIndustryData);
    }
  }, [allSubIndustryData]);

  const handleExportClick = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) {
      fileName = REPORT_NAME.DETAIL_ORGANIZATION_SEARCH;
      columns = DETAIL_ORGANIZATION_SEARCH_COLUMN1;
      dataSource = allDetailOrganizationSearchData;
    } else if (reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
      fileName = REPORT_NAME.TERM_COMPARISON_ANALYSIS;
      columns = TERM_COMPARISON_ANALYSIS_COLUMN;
      dataSource = termComparisonAnalysisDataSource;
    } else if (reportName === REPORT_NAME.ORGANIZATION_SEARCH) {
      fileName = REPORT_NAME.ORGANIZATION_SEARCH;
      columns = ORGANIZATION_SEARCH_COLUMN;
      dataSource = allSearchCompanyData;
    }

    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true
      })
      .saveAs(`${fileName}.xlsx`);
  };

  const handleSendEmail = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
      fileName = REPORT_NAME.TERM_COMPARISON_ANALYSIS;
      columns = TERM_COMPARISON_ANALYSIS_COLUMN;
      dataSource = termComparisonAnalysisDataSource;
    }

    if (dataSource.length > 0) {
      sendEmail({
        payload: {
          columns: columns,
          dataSource: dataSource,
          fileName: fileName,
          to: localUserObj.username
        },
        token: localUserObj.token
      })
    } else {
      alertMessage("Please make new request first!", "error");
    }
  };

  useEffect(() => {
    if (sendEmailResponse.isSuccess) {
      alertMessage("Emaile sent successfully", "success");
    } else if (sendEmailResponse.isError) {
      alertMessage("Error while sending email", "error");
    }
  }, [sendEmailResponse.isSuccess, sendEmailResponse.isError]);

  //delete..........
  const onDeleteData = (record: any) => {
    if (reportName === REPORT_NAME.INDUSTRY) {
      deleteIndustry({
        id: record.id,
      });
    } else if (reportName === REPORT_NAME.SUB_INDUSTRY) {
      deleteSubIndustry({
        id: record.id,
      });
    }
  };

  //Delete response useEffects

  useEffect(() => {
    if (deleteIndustryResponse.isSuccess) {
      alertMessage("Industry updated successfully", "success");
      setCall("Y");
    } else if (deleteIndustryResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteIndustryResponse.isSuccess, deleteIndustryResponse.isError]);

  useEffect(() => {
    if (deleteSubIndustryResponse.isSuccess) {
      alertMessage("Sub Industry updated successfully", "success");
      setCall("Y");
    } else if (deleteSubIndustryResponse.isError) {
      alertMessage("Error while deleting", "error");
    }
  }, [deleteSubIndustryResponse.isSuccess, deleteSubIndustryResponse.isError]);

  useEffect(() => {
    const temp: any = [];
    const tempAction: any = {
      title: "Action",
      dataIndex: "action",
      key: "name",
      fixed: "right",
      width: 120,
      render: (index: any, record: any) => {
        return (
          // for delete switch button
          <div className="d-flex">
            <div className="px-2 pe-auto">
              <Switch
                checked={reportName === REPORT_NAME.INDUSTRY ? record.isActive === "Y" ? true : false
                  : record.status === true ? true : false}
                onClick={() => {
                  onDeleteData(record);
                }}
              />
            </div>
            {(record.isActive === "Y" || record.status) && reportName !== "dataRecord" ? (
              <div
                className="px-2 pe-auto"
                onClick={() => {
                  handleOpenForm(record);
                }}
              >
                <img src={editIcon} alt="update" />
              </div>
            ) : null}
          </div>
        );
      },
    };

    if (reportName === REPORT_NAME.INDUSTRY && INDUSTRY_COLUMN) {
      INDUSTRY_COLUMN.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setColumnData(temp);
    } else if (reportName === REPORT_NAME.SUB_INDUSTRY && SUB_INDUSTRY_COLUMN) {
      SUB_INDUSTRY_COLUMN.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setColumnData(temp);
    }
  }, [reportName]);

  const paginationChange = (page: number, pageSize: number) => {
    if (reportName === REPORT_NAME.INDUSTRY) {
      getAllIndustry({
        page: currentpage - 1,
        size: pageSize,
        sort: "id",
      });
    } else if (reportName === REPORT_NAME.SUB_INDUSTRY) {
      getAllSubIndustry({
        page: page - 1,
        size: pageSize,
        sort: "id",
      });
    } else if (reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) {
      // paginateData(page, pageSize);
    }
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // .....................
  const handleAdd = () => {
    setAdd(true);
  };

  const handleShow = () => setShow(true);

  const handleOpenForm = (record: any) => {
    setShow(true);
    setActionType(ACTION_TYPE.UPDATE_ACTION);
    setSelectedRow(record);
  };

  // navigate............
  const handleBack = () => {
    navigate("/matchMakerReports");
  };

  useEffect(() => {
    if (getAllIndustryWithSearchResponse.isSuccess) {
      dispatch(setAllIndustryBySearch(getAllIndustryWithSearchResponse));
    } else if (getAllIndustryWithSearchResponse.isError) {
      let response: any = getAllIndustryWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllIndustryWithSearchResponse.isSuccess, getAllIndustryWithSearchResponse.isError]);

  useEffect(() => {
    if (allIndustryBySearchData && reportName === REPORT_NAME.INDUSTRY) {
      setDatasource(allIndustryBySearchData);
      setFilteredRecords(allIndustryBySearchData)
    }
  }, [allIndustryBySearchData]);

  useEffect(() => {
    if (getAllSubIndustryWithSearchResponse.isSuccess) {
      dispatch(setAllSubIndustryBySearch(getAllSubIndustryWithSearchResponse));
    } else if (getAllSubIndustryWithSearchResponse.isError) {
      let response: any = getAllSubIndustryWithSearchResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllSubIndustryWithSearchResponse.isSuccess, getAllSubIndustryWithSearchResponse.isError]);

  useEffect(() => {
    if (allSubIndustryBySearchData && reportName === REPORT_NAME.SUB_INDUSTRY) {
      setDatasource(allSubIndustryBySearchData);
      setFilteredRecords(allSubIndustryBySearchData);
    }
  }, [allSubIndustryBySearchData]);

  useEffect(() => {
    if (allSearchCompanyData && reportName === REPORT_NAME.ORGANIZATION_SEARCH) {
      setDatasource(allSearchCompanyData);
      setFilteredRecords(allSearchCompanyData);
    }
  }, [allSearchCompanyData]);

  useEffect(() => {
    if (allDetailOrganizationSearchData && reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) {
      const allDetailOrganizationSearchAsc = [...allDetailOrganizationSearchData].sort((a, b) => a.id - b.id);

      setDatasource(allDetailOrganizationSearchAsc);
      setFilteredRecords(allDetailOrganizationSearchAsc);
    }
  }, [allDetailOrganizationSearchData]);

  const onSearchChange = (e: RadioChangeEvent) => {
    setSearchValue(e.target.value);
  };

  const onSaveClick = (values: any, record: any) => {
    if (reportName === REPORT_NAME.ORGANIZATION_SEARCH) {
      setLoading(true);
      const newData = dataSource.map((item: any) => {
        if (item.web_link === record.web_link) {
          return { ...item, status: values.statusInput };
        }
        return item;
      });

      // Perform any action to save newData to your data source
      // For example:
      dispatch(setSearchCompany(newData));
      setDatasource(newData);
      setLoading(false);
    }
  };

  const handleSearch = (event: any) => {
    if (reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
      if (event.target.value !== "") {
        if (termComparisonAnalysisDataSource.length > 0) {
          const filteredArray = termComparisonAnalysisDataSource.filter((item: any) => item.frequency >= event.target.value);
          if (filteredArray.length > 0) {
            setShowKeyWordGroupingBtn(true);
            setShowConsolidatedKeywrdGrp(true);
          }
          setFilteredTermComparison(filteredArray)
        } else {
          alertMessage("Please make new request first!", "error");
        }
      } else {
        setFilteredTermComparison(termComparisonAnalysisDataSource);
        setShowKeyWordGroupingBtn(false);
        setShowConsolidatedKeywrdGrp(false);
      }
    } else if (reportName === REPORT_NAME.INDUSTRY || reportName === REPORT_NAME.SUB_INDUSTRY) {
      if (reportName === REPORT_NAME.INDUSTRY) {
        getAllIndustryWithSearch({
          token: token,
          payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
        });
      } else if (reportName === REPORT_NAME.SUB_INDUSTRY) {
        getAllSubIndustryWithSearch({
          token: token,
          payload: { offset: 100, pageNumber: 0, searchBy: event.target.value },
        });
      }
    } else if (reportName === REPORT_NAME.ORGANIZATION_SEARCH || reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) {
      const filterTable = dataSource?.filter((o: any) =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes((event.target.value).toLowerCase())
        )
      );
      setFilteredRecords(filterTable);
    } else {
      setFilteredRecords(dataSource);
    }
  };

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div className="d-flex flex-column pe-2">
            {reportName !== REPORT_NAME.TERM_COMPARISON_ANALYSIS && (
              <div className="d-flex align-items-center">
                <div className="input-group px-2 mt-1">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="border-0 bg-cm-light-primary1"
                    aria-label="Search"
                    onChange={handleSearch} />
                  <div className="input-group-append bg-cm-light-primary1 p-1 rounded-end">
                    <span className="input-group-text bg-cm-light-primary1 border-0">
                      {/* <img src={gear} alt="" /> */}
                      <BsSearch className=" bg-cm-light-primary1 border-cm-light-primary1" />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          {(reportName === REPORT_NAME.ORGANIZATION_SEARCH || reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) && (
            <div
              className={`d-flex align-items-center mt-1`}
            >
              <Button
                variant="primary"
                className="d-flex align-items-center me-0"
                onClick={handleExportClick}
              >
                <BsDownload size={16} className="me-2" />
                Export
              </Button>
            </div>
          )}
          {showAdd && (
            <div
              onClick={handleShow}
              className={`d-flex align-items-center mt-1`}
            >
              <div
                className="d-flex align-items-center px-3"
                onClick={handleAdd}
              >
                <Button
                  variant="primary"
                  className="d-flex align-items-center me-0 text-nowrap"
                  onClick={() => {
                    setActionType(ACTION_TYPE.ADD_ACTION)
                    if (reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
                      // Clear frequency input field and make btns invisible
                      if (frequencyInputRef.current) {
                        frequencyInputRef.current.value = "";
                        setShowKeyWordGroupingBtn(false);
                        setShowConsolidatedKeywrdGrp(false);
                        setFilteredTermComparison(termComparisonAnalysisDataSource)
                      }
                    }
                  }}
                >
                  {(reportName === REPORT_NAME.ORGANIZATION_SEARCH ||
                    reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) ? <BiSearch /> : <BiPlus />}
                  {(reportName === REPORT_NAME.ORGANIZATION_SEARCH ||
                    reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) ? "Search" :
                    reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS ? "New Request" : "Add"}
                </Button>
              </div>
            </div>
          )}

          {show && (
            <MatchMakerManagementData
              show={show}
              setShow={setShow}
              actionType={actionType}
              getAllReportAsync={() => null}
              updateAllReportAsync={({ }) => null}
              reportType={reportType}
              reportName={params.reportType}
              reportNameCode={reportName}
              isCategory={isCategory}
              data={selectedRow}
              call={call}
              setCall={setCall}
              setFileName={setFileName}
            />
          )}
          {showReportModal && (
            <TermComparisonSummaryReport
              showReportModal={showReportModal}
              setShowReportModal={setShowReportModal}
              showKeyWordGroupingBtn={showKeyWordGroupingBtn}
              filteredTermComparison={filteredTermComparison}
              summReportName={summReportName}
              reportName={reportName}

            />
          )}
        </div>
      </div>
      <div className="d-flex mb-2">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-between w-100 mx-2">
          {reportName !== REPORT_NAME.TERM_COMPARISON_ANALYSIS && (
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleBack}
            >
              Back
            </Button>
          )}
        </div>
        {industryResponse.isLoading ||
          subIndustryResponse.isLoading ||
          deleteIndustryResponse.isLoading ||
          deleteSubIndustryResponse.isLoading ||
          sendEmailResponse.isLoading ||
          getAllIndustryWithSearchResponse.isLoading ||
          getAllSubIndustryWithSearchResponse.isLoading ? (
          <Loading />
        ) : (
          ""
        )}
        {loading ? (
          <Loading />
        ) : (
          ""
        )}
      </div>
      {reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS ? (
        <div className="d-flex flex-column">
          <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={() => {
                handleBack();
                setFilteredTermComparison([]);
              }}
            >
              Back
            </Button>
            <div className="primary mt-2 mt-md-0 d-flex justify-content-end w-100 mx-2">
              <div className="input-group px-2 mt-1 w-25">
                <Form.Control
                  type="search"
                  placeholder="filter frequency"
                  className="border-0 bg-cm-light-primary1"
                  aria-label="Search"
                  onChange={handleSearch}
                  ref={frequencyInputRef}
                />
                <div className="input-group-append bg-cm-light-primary1 p-1 rounded-end">
                  <span className="input-group-text bg-cm-light-primary1 border-0">
                    {/* <img src={gear} alt="" /> */}
                    <BsSearch className=" bg-cm-light-primary1 border-cm-light-primary1" />
                  </span>
                </div>
              </div>
              {showKeyWordGroupingBtn && (
                <Button
                  variant="primary"
                  className="d-flex align-items-center me-2"
                  onClick={() => {
                    setShowReportModal(true);
                    setSummReportName(REPORT_NAME.KEYWORD_GROUPING);
                  }}
                >
                  {REPORT_NAME.KEYWORD_GROUPING}
                </Button>
              )}
              {showConsolidatedKeywrdGrp && (
                <Button
                  variant="primary"
                  className="d-flex align-items-center me-2"
                  onClick={() => {
                    setShowReportModal(true);
                    setSummReportName(REPORT_NAME.CONSOLIDATED_KEYWORD_GROUPING)
                  }}
                >
                  {REPORT_NAME.CONSOLIDATED_KEYWORD_GROUPING}
                </Button>
              )}
              <Button
                variant="primary"
                className="d-flex align-items-center me-0"
                onClick={handleExportClick}
              >
                <BsDownload size={16} className="me-2" />
                Export
              </Button>
              <Button
                variant="primary"
                className="d-flex align-items-center mx-2"
                onClick={handleSendEmail}
              >
                <BsEnvelope size={16} className="me-2" />
                Email
              </Button>
            </div>
          </div>
          {sendEmailResponse.isLoading ?
            (
              <Loading />
            ) : (
              ""
            )}
          {fileName && (
            <p className="mt-2 mx-2 fw-bold">{fileName}</p>
          )
          }
        </div>
      ) : null}

      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          scroll={{ y: 'calc(100vh - 400px)' }}  // Set the height of the table body to enable vertical scrolling
          sticky // Enable sticky headers
          rowKey={(reportName === REPORT_NAME.ORGANIZATION_SEARCH
            || reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH) ? "web_link" : "id"}
          // pagination
          pagination={reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH ? false : {
            total: reportName === REPORT_NAME.INDUSTRY
              ? totalIndustryRecords
              : reportName === REPORT_NAME.SUB_INDUSTRY
                ? totalSubIndustryRecords
                : filteredRecords.length,
            responsive: true,
            current: currentpage,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30],
            onChange: (page, pageSize) => {
              paginationChange(page, pageSize);
            },
            pageSize: pageSize,
          }}
          columns={
            reportName === REPORT_NAME.INDUSTRY || reportName === REPORT_NAME.SUB_INDUSTRY
              ? columData
              : reportName === REPORT_NAME.ORGANIZATION_SEARCH
                ? ORGANIZATION_SEARCH_COLUMN1
                : reportName === REPORT_NAME.DETAIL_ORGANIZATION_SEARCH
                  ? DETAIL_ORGANIZATION_SEARCH_COLUMN
                  : reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS
                    ? TERM_COMPARISON_ANALYSIS_COLUMN
                    : ""
          }
          dataSource={reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS ? filteredTermComparison : filteredRecords}
          expandable={
            reportName === REPORT_NAME.ORGANIZATION_SEARCH
              ? {
                expandedRowRender: (record: any) => {
                  return <div className="d-flex align-items-center">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        statusInput: record.status ? record.status : "",
                      }}
                      onSubmit={(values) => onSaveClick(values, record)} >
                      {({ values, handleChange, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="d-flex align-items-center">
                            <input
                              className="form-control form-control-sm w-20"
                              type="text"
                              placeholder="Enter status"
                              name="statusInput"
                              value={values.statusInput}
                              onChange={handleChange}
                            />
                            <button
                              type="submit"
                              className="btn btn-primary mx-1"
                            >
                              Save
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                },
              }
              : undefined
          }
          className="custom-table"
        />
      </div>
      {showGraph && (
        termPieGraphData.map((element: any) => {
          const parts = (element.fileName).split("/");
          const splittedfileName = parts[parts.length - 1];
          return (
            <div className="">
              {/* <p className="fw-bolder text-cm-active-primary1">{REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY}</p> */}
              <div className="shadow-lg p-3 mb-5 bg-body rounded overflow-scroll">
                <BiasAndVulnerabiltySummary title={splittedfileName} chartSerires={element.count} chartLables={element.keyGroup} />
              </div>
            </div>
          )
        })
      )}
    </div>
  );
};
export default MatchMakerManagementMaster;
