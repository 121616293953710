import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface PackageFeatureMappingState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}
const initialState: PackageFeatureMappingState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const packageFeatureMappingSlice = createSlice({
  name: 'packageFeatureMapping',
  initialState,
  reducers: {
    setAllPackageFeatureMapping: (state, action) => {
      state.data = action.payload.data;
    },
    setPackageFeatureMapping: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllPackageFeatureMappingBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllPackageFeatureMapping, setPackageFeatureMapping, setAllPackageFeatureMappingBySearch,reset } = packageFeatureMappingSlice.actions;
export const allPackageFeatureMappingDetails = (state: RootState) => state.packageFeatureMapping.data;
export const getAllPackageFeatureMappingDetails = (state: RootState) => state.packageFeatureMapping.data;
export const getPackageFeatureMappingTotalRecords = (state:RootState) => state.packageFeatureMapping.totalRecords;
export const getAllPackageFeatureMappingBySearch = (state: RootState) => state.packageFeatureMapping.data;
export default packageFeatureMappingSlice.reducer;