import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useLogoutUserQuery } from "../../redux-toolkit/services/authService";
import { useAppDispatch } from "../../redux-toolkit/hooks";
import { resetAuthState } from "../../redux-toolkit/slices/authSlice";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { useSaveAuditLogMutation } from "../../redux-toolkit/services/auditLogService";
import { setAuditLog } from "../../redux-toolkit/slices/auditLogSlice";
type Props = {};
const Logout = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isSuccess, isError } = useLogoutUserQuery("");
  const uniqueId: any = localStorage.getItem("uniqueId")
  const localUserObj = getLocalStorageData("localUser");

  const [saveAuditLog, saveAuditLogResponse] = useSaveAuditLogMutation();
  const startTime = new Date();

  useEffect(() => {
    // localStorage.removeItem("localUser");
    // localStorage.removeItem("localAccountId");
    // localStorage.removeItem("name");
    // localStorage.removeItem("tenantId");
    // localStorage.removeItem("uniqueId");
    // localStorage.removeItem("username");
    // localStorage.removeItem("name");
    if (uniqueId) {

    } else {
      localStorage.clear();
    }
    if (isSuccess) {
      dispatch(resetAuthState());
      saveAuditLog({
        payload: {
          startTime: startTime.toISOString(),
          // endTime: endTime.toISOString(),
          serviceName: "logout",
          eventName: "logout",
          url: "/logout",
          userId: localUserObj?.id
        }
      })
      navigate("/login");
      toast.success("Logout Successful");
    } else if (isError) {
      saveAuditLog({
        payload: {
          startTime: startTime.toISOString(),
          // endTime: endTime.toISOString(),
          serviceName: "logout",
          eventName: "logout",
          url: "/logout",
          userId: localUserObj?.id
        }
      })
      navigate("/login");
    } else {
      saveAuditLog({
        payload: {
          startTime: startTime.toISOString(),
          // endTime: endTime.toISOString(),
          serviceName: "logout",
          eventName: "logout",
          url: "/logout",
          userId: localUserObj?.id
        }
      })
    }
  }, [isSuccess]);

  useEffect(() => {
    if (saveAuditLogResponse.isSuccess) {
      // dispatch(setAuditLog(saveAuditLogResponse))

    } else if (saveAuditLogResponse.isError) {
      console.log("error while saving data");
      // alertMessage("error while saving data", "error");
    }
  }, [saveAuditLogResponse.isSuccess, saveAuditLogResponse.isError]);


  return <div></div>;
};
export default Logout;
