import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface SubIndustryState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
}
const initialState: SubIndustryState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};
export const subIndustrySlice = createSlice({
  name: 'subIndustry',
  initialState,
  reducers: {
    setAllSubIndustry: (state, action) => {
      state.data = action.payload.data;
    },
    setSubIndustry: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllSubIndustryBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllSubIndustry, setSubIndustry, setAllSubIndustryBySearch,reset } = subIndustrySlice.actions;
export const allSubIndustryDetails = (state: RootState) => state.subIndustry.data;
export const getAllSubIndustryDetails = (state: RootState) => state.subIndustry.data;
export const getSubIndustryTotalRecords=(state:RootState)=>state.subIndustry.totalRecords;
export const getAllSubIndustryBySearch = (state: RootState) => state.subIndustry.data;
export default subIndustrySlice.reducer;