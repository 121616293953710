export const userManagementBaseUrl = process.env.REACT_APP_USER_MANAGEMENT_BASE_URL

export const dataServiceBaseUrl = process.env.REACT_APP_DATA_SERVICE_BASE_URL

export const webUIBaseUrl = process.env.REACT_APP_WEB_UI_BASE_URL

export const stagingBaseUrl = process.env.REACT_APP_STAGING_BASE

export const fastApiBaseUrl = process.env.REACT_APP_FAST_API_BASE_URL

export const assessmentAndAuditsUrl = process.env.REACT_APP_ASSESSMENT_AND_AUDITS_URL
export const riskManagementUrl = process.env.REACT_APP_RISK_MANAGEMENT_URL
export const driftAndAutoAssessment = process.env.REACT_APP_DRIFT_AND_AUTO_ASSESSMENT

export const assessmentAndAuditsBaseUrl = process.env.REACT_APP_ASSESSMENT_AND_AUDITS_BASE__URL
export const riskManagementBaseUrl = process.env.REACT_APP_RISK_MANAGEMENT_BASE_URL

export const evidenceNavigateUrl = process.env.REACT_APP_EVIDENCE_NAVIGATE_URL

export const cascadBaseUrl = process.env.REACT_APP_CASCAD_URL

export const shapLimeBaseUrl = process.env.REACT_APP_SHAPLIME_URL

export const documentManagementUrls = {
  parameter: `${riskManagementUrl}parameter`,
  parameterCategory: `${riskManagementUrl}par`
}

export const assessmentAndAudits = {
  getAllCompany: "companyget/api",
  getAllProjects: "projectgetapi",
  getEvidences: "getEvidences",
  getAssessment: "getassessment",
  getDataLineage: "getdatalineage",
  nMapScan: "/api/nmap-scan"
}

export const riskManagement = {
  getAllResponsibilityCenter: "api/adding",
  getAllResponsibilityGroup: "api/in",
  getAllObjects: "api/add",
  getAllObjectType: "api/get",
  getAllParameterCategory: "api/parameteradd",
  getAllParameter: "api/parget"
}

export const fastApi = {
  calculatePenalty: "ai-api/calculate_penalty/",
  algorithmAccuracy: "ai-api/analyze_accuracy/",
  calculateFairnessMetrics: "ai-api/calculate_fairness_metrics/",
  identifySensitiveColumns: "v/identify_sensitive_columns/",
  dataSetBiasTest: "ai-api/analyze/",
  featureDominance: "ai-api/analyze-csv/",
  generate_graph: "ai-api/generate_graph",
  employmentbias: "ai-api/employmentbias/",
  unstructuredDataBiasTest: "ai-api/process-files",
  generatePdf: "ai-api/generate-pdf",
  trustedAIDimensionAnalysis: "shap-lime/evaluate_fairness",
}

export const dataService = {

  valueChainCategoryController: {
    getAllValueChainCategory: "/valuechaincategories/with-pagination",
    updateValueChainCategory: "/valuechaincategories",
    deleteValueChainCategoryById: "/valuechaincategories",
    getValueChainCategoryById: "/valuechaincategories",
    saveValueChainCategory: "/valuechaincategories",
    getAllValueChainCategoryWithSearch: "/valuechaincategories/getAllWithSearch"
  },

  valueChainsController: {
    getValueChainsById: "/valuechains",
    updateValueChain: "/valuechains",
    deleteValueChainById: "/valuechains",
    getAllValueChains: "/valuechains/with-pagination",
    saveValueChain: "/valuechains",
    getAllValueChainsWithSearch: "/valuechains/getAllWithSearch",
    getValueChainCategoryCountName: "/valuechains/getCountAndValueChainCategoryName"
  },

  termController: {
    getTermById: "/terms",
    updateTerm: "/terms",
    deleteTermById: "/terms",
    getAllTerm: "/terms/with-pagination",
    saveTerm: "/terms",
    getTermsTermsetIdById: "/terms/termsetid",
    getTermsStringlistTermsetIdById: "/terms/stringlist/termsetid",
    getAllTermWithSearch: "/terms/getAllWithSearch",
    getCountAndTermSetName: "/terms/getCountAndTermSetName",
  },

  termSetController: {
    getTermSetById: "/termsets",
    updateTermSet: "/termsets",
    deleteTermSetById: "/termsets",
    getAllTermSet: "/termsets/with-pagination",
    saveTermSet: "/termsets",
    getAllTermSetWithSearch: "/termsets/getAllWithSearch"
  },

  dataPipelineController: {
    getDataPipelineById: "/datapipelines",
    updateDataPipeline: "/datapipelines",
    deleteDataPipelineById: "/datapipelines",
    getAllDataPipelines: "/datapipelines/with-pagination",
    saveDataPipeline: "/datapipelines",
    getAllDataPipelinesWithSearch: "/datapipelines/getAllWithSearch",
    getCountAndPipelineCategoryName: "datapipelines/getCountAndPipelineCategoryName",
  },

  dataPipelineCategoryController: {
    getDataPipelineCategoryById: "/datapipelinecategories",
    updateDataPipelineCategory: "/datapipelinecategories",
    deleteDataPipelineCategoryById: "/datapipelinecategories",
    getAllDataPipelinesCategories: "/datapipelinecategories/with-pagination",
    saveDataPipelineCategory: "/datapipelinecategories",
    getAllDataPipelinesCategoriesWithSearch: "/datapipelinecategories/getAllWithSearch"
  },

  valueChainParagraphExtractController: {
    getValueChainParagraphExtractById: "/valuechainparagraphextract",
    updateValueChainParagraphExtract: "/valuechainparagraphextract",
    deleteValueChainParagraphExtractById: "/valuechainparagraphextract",
    getAllValueChainParagraphExtract: "/valuechainparagraphextract",
    saveValueChainParagraphExtract: "/valuechainparagraphextract",
    getAllValueChainParagraphExtractWithPagination:
      "/valuechainparagraphextract/with-pagination",
    getAllValueChainParagraphExtractWithSearch: "/valuechainparagraphextract/getAllWithSearch"
  },

  dataRecordController: {
    getAllDataRecords: "/datarecords",
    updateDataRecord: "/datarecords",
    deleteDataRecordById: "/datarecords",
    getAllDataRecord: "/datarecords",
    saveDataRecord: "/datarecords",
    getDataRecordById: "/datarecords",
    getDataRecordsWithPagination: "/datarecords/with-pagination",
    getAllDataRecordsWithSearch: "/datarecords/getAllWithSearch"
  },

  responsibilityGroupController: {
    getAllResponsibilityGroup: "/responsibilityGroup/allResponsibilityGroup",
  },

  responsibilityCenterController: {
    getAllResponsibilityCenter: "/responsibilityCenter/allResponsibilityCenter"
  },

  companyController: {
    getAllCompanies: "/companies/all"
  },

  industryMasterController: {
    getAllIndustry: "/industry/with-pagination",
    updateIndustry: "/industry/updateIndustry",
    deleteIndustryById: "/industry/deleteIndustry",
    getIndustryById: "/industry/getIndustryById",
    saveIndustry: "/industry/createIndustry",
    getAllIndustryWithSearch: "/industry/getAllWithSearch"
  },

  subIndustryController: {
    findAllSubIndustry: "/subIndustry/findAllSubIndustry",
    getAllSubIndustry: "/subIndustry",
    saveSubIndustry: "/subIndustry/saveSubIndustry",
    updateSubIndustry: "/subIndustry/updateSubIndustry",
    deleteSubIndustry: "/subIndustry/deleteSubIndustry",
    getSubIndustryById: "/subIndustry",
    getAllSubIndustryWithSearch: "/subIndustry/getAllSubIndustryWithSearch"
  }
};

export const webUI = {
  webSearchController: {
    getResultByTerms: "/getResultByTerms",
    searchForCompany: "/searchforcompany",
    getDetailSearch: "/getDetailSearch"
  },
  gdprController: {
    dataPrivacyComplianceTest: "/process/gdpr/data-policy/check",
    aiRegulationComplianceTest: "/process/ai/regulation/check",
    // aiRegulationComplianceTest: "/process/ai/bias/check",
  },
  vulnerabiltyController: {
    codeCyberSecVulAnalysis: "/process/vulnerability/check",
  },
  aiBiasController: {
    codeAiBiasAnalysis: "/process/ai/bias/check",
    codeVulnerability: "/process/ai/vulnerability/check"
  },
  textMatcherController: {
    termComparisonAnalysis: "/process/ai/text-matcher/frequencey",
  },
  codeReviewController: {
    codeReviewReport: "/codeReviewReport",
  }
};

export const userManagement = {
  enumController: {
    getEnumData: "/enums",
    getEnumDataFromCode: "/enums/",
    saveEnumData: "/enums/enums",
    updateEnumData: "/enums/enums/",
  },
  loginController: {
    login: "/login",
    logout: "/logout",
  },
  permissionController: {
    getAllPermissions: "/permissions/findAllPermissions",
  },
  roleController: {
    getRoles: "/roles",
    addRole: "/roles",
    findRoleById: "/roles/",
    updateRole: "/roles/",
    deleteRole: "/roles/",
  },
  userController: {
    addUser: "/users",
    findUserById: "/users",
    updateUser: "/users",
    deleteUser: "/users",
    getUsers: "/users",
    forgotPassword: "/users/forgotPassword",
    resetPassword: "/users/resetPassword",
    uploadUsers: "/users/upload",
    findUsername: "/users/username",
    uploadProfileImage: "/users/uploadProfileImage",
    downloadProfileImage: "/users/downloadProfileImage",
    // uploadMerchantImage:"/users/uploadMerchantImage",
    // downloadMerchantImage:"/users/downloadMerchantImage",
    downloadMerchantImage: "/users/merchantDetails",
    saveMerchant: "/users/saveMerchant",
  },
  userRoleController: {
    getUserRoleById: "/roles",
    updateUserRole: "/roles/updateUserRole",
    deleteUserRoleById: "/roles/deleteUserRole",
    getAllUserRole: "/roles/findAllUserRole",
    saveUserRole: "/roles",
  },
  userGroupController: {
    getUserGroupById: "/userGroup/findUserGroup",
    updateUserGroup: "/userGroup/updateUserGroup",
    deleteUserGroupById: "/userGroup/deleteUserGroup",
    getAllUserGroup: "/userGroup/findAllUserGroup",
    saveUserGroup: "/userGroup/saveUserGroup",
  },
  usersController: {
    getUserById: "/users",
    updateUser: "/users",
    deleteUserById: "/users",
    getAllUser: "/users",
    saveUser: "/users",
    resetPassword: "/users/resetPassword",
    getAllUsersWithSearch: "/users/getAllUsersWithSearch",
  },
  countryController: {
    getAllCountries: "/country/getAllCountries",
    saveCountry: "/country/saveCountry",
    updateCountry: "/country/updateCountry",
    deleteCountry: "/country/deleteCountry",
    getCountryById: "/country/getCountryById",
    getAllCountriesWithSearch: "/country/getAllCountriesWithSearch",
  },
  cityController: {
    getAllCities: "/city/getAllCities",
  },
  stateController: {
    getState: "/state/getState",
    saveState: "/state/saveState",
    updateState: "/state/updateState",
    deleteState: "/state/deleteState",
    getStateById: "/state/getStateById",
    getAllStates: "/state/getAllStates",
    getAllStatesWithSearch: "/state/getAllStatesWithSearch",
  },
  auditLogController: {
    saveAuditLog: "auditLog/save",
    getAuditLog: "auditLog/find"
  },
  packageMasterController: {
    findAllPackages: "/package/getAllPackages",
    getAllPackages: "/package",
    savePackage: "/package/savePackage",
    updatePackage: "/package/updatePackage",
    deletePackage: "/package/deletePackage",
    getPakcageById: "/package",
    getAllPackagesWithSearch: "/package/getAllPackagesWithSearch"
  },
  moduleController: {
    getAllModules: "/module",
    saveModule: "/module/saveModule",
    updateModule: "/module/updateModule",
    deleteModule: "/module/deleteModule",
    getByModuleId: "/module",
    getAllModuleWithSearch: "/module/getAllModulesWithSearch",
    allModules: "/module/getAllModules",
  },
  subModuleController: {
    getAllSubModules: "/subModule",
    saveSubModule: "/subModule/saveSubModule",
    updateSubModule: "/subModule/updateSubModule",
    deleteSubModule: "/subModule/deleteSubModule",
    getBySubModuleId: "/subModule",
    getAllSubModulesWithSearch: "/subModule/getAllSubModulesWithSearch",
    allSubModules: "/subModule/getAllSubModules",
  },
  featureController: {
    getAllFeatures: "/features",
    saveFeature: "/features/saveFeature",
    updateFeature: "/features/updateFeature",
    deleteFeature: "/features/deleteFeature",
    getByFeatureId: "/features",
    getAllFeaturesWithSearch: "/features/getAllFeaturesWithSearch",
    allFeatures: "/features/getAllFeatures",
  },
  packageFeatureMappingController: {
    getAllPackageFeatureMapping: "/packageFeatureMapping",
    savePackageFeatureMapping: "/packageFeatureMapping/savePackageFeatureMapping",
    updatePackageFeatureMapping: "/packageFeatureMapping/updatePackageFeatureMapping",
    deletePackageFeatureMapping: "/packageFeatureMapping/deletePackageFeatureMapping",
    getByPackageFeatureMappingId: "/packageFeatureMapping",
    getAllPackageFeatureMappingWithSearch: "/packageFeatureMapping/getAllPackageFeatureMappingWithSearch",
    findAllPackageFeatureMappings: "/packageFeatureMapping/getAllPackageFeatureMappings"
  },
  faqsController: {
    getAllFAQs: "/faq",
    saveFAQ: "/faq/saveFAQ",
    updateFAQ: "/faq/updateFAQ",
    deleteFAQ: "/faq/deleteFAQ",
    getFAQById: "/faq",
    getAllFAQsWithSearch: "/faq/getAllFAQsWithSearch",
  },
  membershiDetailController: {
    saveMemberShipDetail: "/membershipDetail/saveMemberShipDetail",
  },
  emailController: {
    sendEmailForXlsxfile: "/email/sendEmailForExport"
  },
  sectionMasterController: {
    getAllSectionMaster: "/sectionMaster",
    saveSectionMaster: "/sectionMaster/saveSectionMaster",
    updateSectionMaster: "/sectionMaster/updateSectionMaster",
    deleteSectionMaster: "/sectionMaster/deleteSectionMaster",
    getSectionMasterById: "/sectionMaster",
    getAllSectionMasterWithSearch: "/sectionMaster/getAllSectionMasterWithSearch",
    findAllSectionMaster: "/sectionMaster/findAllSectionMaster"
  },
  documentCategoryController: {
    getAllDocumentCategory: "/documentCategory",
    saveDocumentCategory: "/documentCategory/saveDocumentCategory",
    updateDocumentCategory: "/documentCategory/updateDocumentCategory",
    deleteDocumentCategory: "/documentCategory/deleteDocumentCategory",
    getDocumentCategoryById: "/documentCategory",
    getAllDocumentCategoryWithSearch: "/documentCategory/getAllDocumentCategoryWithSearch",
    findAllDocumentCategory: "/documentCategory/findAllDocumentCategory"
  },
  documentMasterController: {
    getAllDocumentMaster: "/documentMaster",
    saveDocumentMaster: "/documentMaster/saveDocumentMaster",
    updateDocumentMaster: "/documentMaster/updateDocumentMaster",
    deleteDocumentMaster: "/documentMaster/deleteDocumentMaster",
    getDocumentMasterById: "/documentMaster",
    getAllDocumentMasterWithSearch: "/documentMaster/getAllDocumentMasterWithSearch",
    findAllDocumentMaster: "/documentMaster/findAllDocumentMaster"
  },
  createDoucmentController: {
    createDocument: "createDocument/generate",
    getAllDocumentRecords: "createDocument",
    getDocumentRecordById: "createDocument/findDocumentRecordById",
    updateDocumentRecord: "createDocument/updateDocumentRecord",
    deleteDocumentRecord: "/createDocument/deleteDocumentRecord",
    getDocumentRecordWithSearch: "/createDocument/getAllDocumentRecordWithSearch",
  }
};