import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManagement, userManagementBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
export const documentMasterService = createApi({
    reducerPath: 'documentMasterService',
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ['DocumentMaster'],
    endpoints: (builder) => ({
        getAllDocumentMaster: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.documentMasterController.getAllDocumentMaster}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}` },
                    // body: body.payload,
                }
            }
        }),
        getDocumentMasterById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.documentMasterController.getDocumentMasterById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}` },
                    // body: body.payload,
                }
            }
        }),
        saveDocumentMaster: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.documentMasterController.saveDocumentMaster}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}` },
                    body: body.payload,
                }
            }
        }),
        updateDocumentMaster: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.documentMasterController.updateDocumentMaster}/${body.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}` },
                    body: body.payload,
                }
            }
        }),
        deleteDocumentMaster: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.documentMasterController.deleteDocumentMaster}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}` },
                }
            }
        }),
        getAllDocumentMasterWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.documentMasterController.getAllDocumentMasterWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}` },
                    body: body.payload,
                };
            },
        }),
        findAllDocumentMaster: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.documentMasterController.findAllDocumentMaster}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}` },
                }
            }
        }),
    })
});
export const {
    useGetAllDocumentMasterMutation,
    useSaveDocumentMasterMutation,
    useUpdateDocumentMasterMutation,
    useDeleteDocumentMasterMutation,
    useGetDocumentMasterByIdMutation,
    useGetAllDocumentMasterWithSearchMutation,
    useFindAllDocumentMasterMutation,
} = documentMasterService;