import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import { ACTION_TYPE, REPORT_NAME } from "../../constant/reportType";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { alertMessage } from "../../utils/AlertService";
import Loading from "../../component/Loading";
import { Select } from "antd";
import { getAllModuleDetails } from "../../redux-toolkit/slices/moduleSlice";
import { useAppSelector } from "../../redux-toolkit/hooks";
import { getAllSubModuleDetails } from "../../redux-toolkit/slices/subModuleSlice";
import { allFeaturesDetails } from "../../redux-toolkit/slices/featureSlice";
import { useGetFAQByIdMutation, useSaveFAQMutation, useUpdateFAQMutation } from "../../redux-toolkit/services/faqsService";


const { Option } = Select;

type Props = {
    setShow: any;
    show: any;
    data?: any;
    reportName?: string;
    reportNameCode?: string;
    actionType: ACTION_TYPE;
    setCall?: any;
};

const FAQMasterData = (props: Props) => {
    const token: any = getLocalStorageData("localUser").token;
    const dispatch = useDispatch();

    //state
    const [getByIdData, setGetByIdData]: any = useState();

    //getData
    const allModulesData: any = useAppSelector(getAllModuleDetails);
    const allSubModulesData: any = useAppSelector(getAllSubModuleDetails);
    const allFeaturesData: any = useAppSelector(allFeaturesDetails);

    //getAll apis

    //save
    const [saveFAQ, saveFAQResponse] = useSaveFAQMutation();

    //update
    const [updateFAQ, updateFAQResponse] = useUpdateFAQMutation();

    //getById mutation
    const [getFAQById, getFAQByIdResponse] = useGetFAQByIdMutation();

   

    // handleSubmit...........
    const handleSubmit = (values: any) => {
        console.log(values)
        if (props.actionType === ACTION_TYPE.ADD_ACTION) {
            if (props.reportNameCode === REPORT_NAME.FAQS) {
                saveFAQ({
                    token: token,
                    payload: {
                        moduleId: parseInt(values.selectedModule),
                        subModuleId: parseInt(values.selectedSubModule),
                        featureId: parseInt(values.selectedFeature),
                        url: values.url,
                        description: values.description,
                        status: true,
                    },
                });
            }
        } else if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
            if (props.reportNameCode === REPORT_NAME.FAQS) {
                updateFAQ({
                    token: token,
                    id: props?.data?.id,
                    payload: {
                        moduleId: parseInt(values.selectedModule),
                        subModuleId: parseInt(values.selectedSubModule),
                        featureId: parseInt(values.selectedFeature),
                        url: values.url,
                        description: values.description,
                        id: props?.data?.id,
                        status: true,
                    },
                });
            }
        }
    };

    useEffect(() => {
        if (saveFAQResponse.isSuccess) {
            alertMessage("FAQ added successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (saveFAQResponse.isError) {
            alertMessage("Error while saving FAQ", "error");
        }
    }, [saveFAQResponse.isSuccess, saveFAQResponse.isError]);

    useEffect(() => {
        if (updateFAQResponse.isSuccess) {
            alertMessage("FAQ updated successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (updateFAQResponse.isError) {
            alertMessage("Error while updating FAQ", "error");
        }
    }, [updateFAQResponse.isSuccess, updateFAQResponse.isError]);

    useEffect(() => {
        if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
            if (props.reportNameCode === REPORT_NAME.FAQS) {
                getFAQById({
                    token: token,
                    id: props?.data?.id,
                });
            }
        }
    }, []);

    useEffect(() => {
        if (getFAQByIdResponse.isSuccess) {
            setGetByIdData(getFAQByIdResponse.data);
        } else if (getFAQByIdResponse.isError) {
            alertMessage("Error while fetching FAQ data", "error");
        }
    }, [getFAQByIdResponse.isSuccess, getFAQByIdResponse.isError]);

    const handleClose = () => props.setShow(false);

    return (
        <div>
            {/* <!-- Modal --> */}
            <Modal
                show={props.show}
                onHide={handleClose}
                className=""
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-90w"
            >
                <Modal.Header>
                    <Modal.Title
                        className="fw-bold text-primary"
                        style={{ fontSize: "1rem" }}
                    >
                        {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "}
                        {props.reportName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {saveFAQResponse.isLoading ||
                        updateFAQResponse.isLoading ||
                        getFAQByIdResponse.isLoading 
                        ? (
                            <Loading />
                        ) : (
                            ""
                        )}
                    <Formik
                        // validationSchema={formSchema}
                        enableReinitialize={true}
                        initialValues={{
                            selectedModule:
                                props.actionType === ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.moduleId :
                                    "",
                            selectedSubModule:
                                props.actionType === ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.subModuleId :
                                    "",
                            selectedFeature:
                                props.actionType === ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.featureId :
                                    "",
                            description:
                                props.actionType === ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.description :
                                    "",
                            url:
                                props.actionType === ACTION_TYPE.UPDATE_ACTION
                                    ? getByIdData && getByIdData.url :
                                    "",
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ values, handleChange, setFieldValue }) => (
                            <Form>
                                <>
                                    <p>
                                        <div className="w-100">
                                            <div className="d-flex">
                                                <div>
                                                    {" "}
                                                    Select Module
                                                </div>
                                                <div style={{ color: "red" }}>*</div>
                                            </div>
                                            <div className="pe-2">
                                                <select
                                                    className="form-select form-select-sm w-100"
                                                    aria-label=".form-select-sm example"
                                                    onChange={handleChange}
                                                    name="selectedModule"
                                                    value={values.selectedModule}
                                                    required
                                                >
                                                    <option value="">
                                                        Select Module
                                                    </option>
                                                    {allModulesData &&
                                                        allModulesData.map((itemType: any, index: any) => {
                                                            return (
                                                                <option
                                                                    key={index.toString()}
                                                                    value={itemType.id}
                                                                >
                                                                    {itemType.name}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                    </p>
                                    <p>
                                        <div className="w-100">
                                            <div className="d-flex">
                                                <div>
                                                    {" "}
                                                    Select Sub module
                                                </div>
                                                <div style={{ color: "red" }}>*</div>
                                            </div>
                                            <div className="pe-2">
                                                <select
                                                    className="form-select form-select-sm w-100"
                                                    aria-label=".form-select-sm example"
                                                    onChange={handleChange}
                                                    name="selectedSubModule"
                                                    value={values.selectedSubModule}
                                                    required
                                                >
                                                    <option value="">
                                                        Select Sub module
                                                    </option>
                                                    {allSubModulesData &&
                                                        allSubModulesData.map((itemType: any, index: any) => {
                                                            return (
                                                                <option
                                                                    key={index.toString()}
                                                                    value={itemType.id}
                                                                >
                                                                    {itemType.name}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                    </p>
                                    <p>
                                        <div className="w-100">
                                            <div className="d-flex">
                                                <div>
                                                    {" "}
                                                    Select Feature
                                                </div>
                                                <div style={{ color: "red" }}>*</div>
                                            </div>
                                            <div className="pe-2">
                                                <select
                                                    className="form-select form-select-sm w-100"
                                                    aria-label=".form-select-sm example"
                                                    onChange={handleChange}
                                                    name="selectedFeature"
                                                    value={values.selectedFeature}
                                                    required
                                                >
                                                    <option value="">
                                                        Select Feature
                                                    </option>
                                                    {allFeaturesData &&
                                                        allFeaturesData.map((itemType: any, index: any) => {
                                                            return (
                                                                <option
                                                                    key={index.toString()}
                                                                    value={itemType.id}
                                                                >
                                                                    {itemType.name}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                    </p>
                                    <p>
                                        Description
                                        <textarea
                                            className="form-control form-control-sm w-100"
                                            rows={2}
                                            aria-label=".form-control-sm example"
                                            onChange={handleChange}
                                            value={values.description}
                                            name="description"
                                            required
                                        />
                                    </p>
                                    <p>
                                        Url
                                        <input
                                            className="form-control form-control-sm w-100"
                                            type="text"
                                            aria-label=".form-control-sm example"
                                            onChange={handleChange}
                                            value={values.url}
                                            name="url"
                                            required
                                        />
                                    </p>
                                </>
                                <div className="d-flex flex-row justify-content-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                                    >
                                        {props.actionType === ACTION_TYPE.ADD_ACTION
                                            ? "Save"
                                            : "Update"}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default FAQMasterData;
