import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface SectionMasterState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  allSectionMaster: [] | null | any;
}
const initialState: SectionMasterState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  allSectionMaster: null
};
export const sectionMasterSlice = createSlice({
  name: 'sectionMaster',
  initialState,
  reducers: {
    setAllSectionMaster: (state, action) => {
      state.allSectionMaster = action.payload.data;
    },
    setSectionMaster: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllSectionMasterBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllSectionMaster, setSectionMaster, setAllSectionMasterBySearch,reset } = sectionMasterSlice.actions;
export const allSectionMasterDetails = (state: RootState) => state.sectionMaster.allSectionMaster;
export const getAllSectionMasterDetails = (state: RootState) => state.sectionMaster.data;
export const getSectionMasterTotalRecords=(state:RootState)=>state.sectionMaster.totalRecords;
export const getAllSectionMasterBySearch = (state: RootState) => state.sectionMaster.data;
export default sectionMasterSlice.reducer;