import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userManagementBaseUrl, userManagement} from "../endpoints";
// Define a service using a base URL and expected endpoints
export const sendEmailService = createApi({
    reducerPath: "sendEmailService",
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ["sendEmail"],
    endpoints: (builder) => ({
        sendEmailforExport: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.emailController.sendEmailForXlsxfile}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),     
    }),
});
export const {
    useSendEmailforExportMutation,
} = sendEmailService;